import React from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { FormattedMessage } from 'react-intl';

import {
  routePaths,
  ContainerHeader,
  Card,
  CardSection,
  EmptyState,
  HospitalSelection
} from '../..';
import { FiltersContainer } from '../../../containers';

const noHospital = props => {
  const pathNameArr = props.history.location.pathname.split('/').slice(1);
  const basePathParam = pathNameArr[0];
  const pathObj = Object.values(routePaths).find(
    path => path.path === basePathParam
  );
  return (
    <>
      <ContainerHeader
        headerTitle={
          (pathObj && pathObj.label) || (
            <FormattedMessage
              defaultMessage="No hospital"
              id="common.noHospital"
            />
          )
        }
      >
        <FiltersContainer />
      </ContainerHeader>
      <div className="row">
        <div className="col-12 col-md-12">
          <Card>
            <CardSection
              title={
                <FormattedMessage
                  id="common.selectHospitalInstruction"
                  defaultMessage="Please select hospital to see data"
                />
              }
            >
              <EmptyState />
            </CardSection>
          </Card>
        </div>
      </div>
      <HospitalSelection />
    </>
  );
};
// export default noHospital;
export default compose(withRouter)(noHospital);
