import React from 'react';
import { FormattedMessage } from 'react-intl';
import { deviceStatuses } from '../../';
import { isFlowFamily, isServoFamily } from './deviceHelper';

const deviceAlertMapper = (deviceType = '', status) => {
  const { msgId, defaultMessage } = mapDeviceAlert(deviceType, status);
  const name =
    (msgId && defaultMessage && (
      <FormattedMessage id={msgId} defaultMessage={defaultMessage} />
    )) ||
    '';
  switch (status) {
    case deviceStatuses.error:
    case deviceStatuses.warning:
      return name && <strong>{name}</strong>;
    default:
      return name;
  }
};

export const mapDeviceAlert = (deviceType = '', status) => {
  let msgId, defaultMessage;

  switch (status) {
    case deviceStatuses.unknown:
      msgId = 'deviceStatus.neverConnected';
      defaultMessage = 'Never connected';
      break;
    case deviceStatuses.connection:
      if (isFlowFamily(deviceType)) {
        msgId = 'deviceStatus.dataTransfer72h';
        defaultMessage = 'Data transfer > 72h';
      }
      break;
    case deviceStatuses.error:
      if (isFlowFamily(deviceType)) {
        msgId = 'deviceStatus.failedSystemCheckout';
        defaultMessage = 'Failed system checkout';
      } else if (isServoFamily(deviceType)) {
        msgId = 'deviceStatus.failedPreuseCheck';
        defaultMessage = 'Failed pre-use check';
      }
      break;
    case deviceStatuses.warning:
      if (isFlowFamily(deviceType)) {
        msgId = 'deviceStatus.proactiveWatch';
        defaultMessage = 'Proactive watch';
      }
      break;
    default:
      break;
  }

  return {
    msgId: msgId,
    defaultMessage: defaultMessage
  };
};

export default deviceAlertMapper;
