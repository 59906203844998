import {
  SET_TEST,
  SET_PROACTIVE_TEST,
  FETCH_TEST,
  FETCH_PROACTIVE_TEST
} from '../actions/test';

const initState = {
  test: null,
  proactive: null
};

export const testReducer = (state = initState, action) => {
  const { type } = action;

  switch (type) {
    case FETCH_TEST:
      return {
        ...state,
        test: null
      };
    case FETCH_PROACTIVE_TEST:
      return {
        ...state,
        proactive: null
      };
    case SET_TEST:
      return {
        ...state,
        test: action.payload
      };
    case SET_PROACTIVE_TEST:
      return {
        ...state,
        proactive: action.payload
      };
    default:
      return state;
  }
};
