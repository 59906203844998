// Add column headers and define column keys and widths
export const deviceTotalGasConsumptionColumns = [
  { header: 'Hospital', key: 'hospital', width: 40 },
  { header: 'Department', key: 'departmentName', width: 40 },
  { header: 'Location', key: 'location', width: 40 },
  { header: 'Device', key: 'deviceType', width: 40 },
  { header: 'Serial no', key: 'serial', width: 20 },
  { header: 'Customer device no', key: 'customerID', width: 20 },
  { header: 'From', key: 'from', width: 15 },
  { header: 'To', key: 'to', width: 15 },
  { header: 'Agent', key: 'agent', width: 40 },
  { header: 'Total gas consumption', key: 'total', width: 10 },
  { header: 'Difference from previous period', key: 'difference', width: 10 }
];

// Define filename
export const deviceTotalGasConsumptionFileName =
  'Device_details_Total_gas_consumption.xlsx';
