import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { WithLoadingSpinner } from '../../hoc';
import {
  Card,
  CardSection,
  List,
  ListItem,
  Icon,
  routePaths,
  PathJoin,
  EmptyState,
  Scrollable
} from '..';
import { FormattedMessage } from 'react-intl';
import { entity } from '../../redux/actions/logTypes.js';
import { FilterLogTypesByDeviceType } from '../../containers/Logs/DeviceLogOverview';
import get from 'lodash.get';
import { Capitalize } from '..';

const DeviceLogListWidget = ({ device, logTypes, getOpenModalForLogType }) => {
  const filteredLogTypes =
    (logTypes &&
      FilterLogTypesByDeviceType(
        logTypes.map(lt => {
          lt.slug = lt.type;
          return lt;
        }),
        get(device, 'deviceType')
      )) ||
    [];

  return (
    <Card dataCy="cardLogs">
      <WithLoadingSpinner when={entity}>
        <CardSection
          title={<FormattedMessage id="common.logs" defaultMessage="Logs" />}
        >
          {(filteredLogTypes && filteredLogTypes.length > 0 && (
            <>
              <Scrollable minElementHeight={100} maxElementHeight={50 * 6}>
                <List dataCy="listLogs" minListItems="6" minListItemHeight={50}>
                  {filteredLogTypes.map(p => (
                    <ListItem
                      key={p.type}
                      className="px-3 py-2"
                      minListItemHeight={50}
                    >
                      <label>{Capitalize(p.name.toLowerCase())}</label>
                      <span>
                        <Link
                          data-cy="viewLogsLink"
                          className="mr-5"
                          to={PathJoin([
                            routePaths.DeviceOverview.path,
                            device.id,
                            routePaths.DeviceLogOverview.path,
                            p.type
                          ])}
                        >
                          <FormattedMessage
                            id="common.view"
                            defaultMessage="View"
                          />
                          <Icon name="arrow-right-full" size="sm" />
                        </Link>
                        <Link
                          data-cy="downloadLogsLink"
                          onClick={getOpenModalForLogType(p.type)}
                          to="#"
                        >
                          <FormattedMessage
                            id="common.download"
                            defaultMessage="Download"
                          />
                          <Icon name="download" size="sm" />
                        </Link>
                      </span>
                    </ListItem>
                  ))}
                </List>
              </Scrollable>
              <div className="mt-2">
                <Link
                  data-cy="downloadAllLogsLink"
                  onClick={getOpenModalForLogType('all')}
                  to="#"
                >
                  <FormattedMessage
                    id="common.downloadAll"
                    defaultMessage="Download all"
                  />
                  <Icon name="download" size="sm" />
                </Link>
              </div>
            </>
          )) || <EmptyState />}
        </CardSection>
      </WithLoadingSpinner>
    </Card>
  );
};

DeviceLogListWidget.propTypes = {
  device: PropTypes.shape(),
  logTypes: PropTypes.arrayOf(PropTypes.shape()),
  getOpenModalForLogType: PropTypes.func.isRequired
};

export default DeviceLogListWidget;
