import React from 'react';
import { FormattedMessage } from 'react-intl';
import { CardSection, Card, Icon } from '../../components';
import { Link } from 'react-router-dom';
import './withSubscriptionInvitation.scss';
import propTypes from 'prop-types';
import { Camelize } from '../../components/utils/StringConverters/StringConverters';
import { subscriptionsShape } from '../../utils/proptypes/subscriptionsShape';
import { connect } from 'react-redux';
import { compose } from 'redux';
import get from 'lodash.get';
import { userInfoShape } from '../../utils/proptypes/userInfoShape';

/**
 * Show an invitation to purchase premium membership
 */

const getBadgeComponents = (subscriptions, trigger) => {
  subscriptions = subscriptions || [];
  trigger = trigger || [];

  return (
    trigger.map(subscriptionType => {
      if (
        !subscriptions.find(
          subscription =>
            subscriptionType === subscription.level && subscription.count > 0
        )
      ) {
        return (
          <div
            className={`gtg-badge-label gtg-badge-label-${subscriptionType}`}
            key={`subscription-invitation-key-${subscriptionType}`}
          ></div>
        );
      }
    }) || <></>
  );
};

const SubscriptionInvitation = props => {
  const {
    subscriptions,
    accessWidget,
    trigger,
    customTitleForInvitation,
    customSubtitleForInvitation,
    showSubTitle
  } = props;
  const camelizedAccessWidget = Camelize(accessWidget);

  const shouldShowSubTitle =
    typeof showSubTitle === 'undefined' ? true : showSubTitle;

  return (
    <Card dataCy="cardSubscriptionInvitation">
      <CardSection>
        <div className="gtg-center-align-content">
          <div className="gtg-subscription-invitation-badge-label-component">
            {getBadgeComponents(subscriptions, trigger)}
          </div>
          <div className="gtg-bold-text gtg-margin-bottom-10">
            <FormattedMessage
              id={
                customTitleForInvitation
                  ? customTitleForInvitation
                  : `${camelizedAccessWidget}.accessWidgetAboutYourDevices`
              }
              defaultMessage={`Access ${accessWidget} about your devices`}
            />
          </div>
          {shouldShowSubTitle && (
            <div className="gtg-small-font-size gtg-margin-bottom-10">
              <FormattedMessage
                id={
                  customSubtitleForInvitation
                    ? customSubtitleForInvitation
                    : `${camelizedAccessWidget}.analizeWidgetForDetails`
                }
                defaultMessage={`Analyze ${accessWidget} to uncover valuable insights and keep agent cost on track.`}
              />
            </div>
          )}
          <Link
            className="card-link mt-auto pt-3 gtg-small-font-size"
            to={{ pathname: 'https://www.getinge.com/int/contact/' }}
            target="_blank"
          >
            <FormattedMessage
              id={
                trigger.length === 1
                  ? 'subscriptionInvitation.tryPremiumFeatures'
                  : 'subscriptionInvitation.tryAdvancedAndPremiumFeatures'
              }
              defaultMessage="Try Advanced & Premium features"
            />
            <Icon name="arrow-right-full" size="sm" />
          </Link>
          <div id="subscription-invitation-widget-graph-image"></div>
        </div>
      </CardSection>
    </Card>
  );
};

const WithSubscriptionInvitation = ({
  children = <></>,
  subscriptions,
  accessWidget,
  trigger,
  userinfo,
  config,
  customTitleForInvitation,
  customSubtitleForInvitation,
  showSubTitle
}) => {
  subscriptions = subscriptions || [];
  trigger = trigger || [];

  const sum = subscriptions.reduce((sum, subscription) => {
    if (trigger.find(trigger => trigger === subscription.level)) {
      sum = sum + subscription.count;
    }
    return sum;
  }, 0);
  const enableSubscriptions = get(config, 'enableSubscriptions', false);

  return sum === 0 &&
    userinfo &&
    !userinfo.isInternalUser &&
    enableSubscriptions ? (
    <SubscriptionInvitation
      subscriptions={subscriptions}
      accessWidget={accessWidget}
      trigger={trigger}
      customTitleForInvitation={customTitleForInvitation}
      customSubtitleForInvitation={customSubtitleForInvitation}
      showSubTitle={showSubTitle}
    />
  ) : (
    children
  );
};

const mapStateToProps = state => ({
  userinfo: get(state, 'authorization.userinfo'),
  config: state.config
});

WithSubscriptionInvitation.propTypes = {
  subscriptions: subscriptionsShape,
  trigger: propTypes.arrayOf(propTypes.string),
  userinfo: userInfoShape
};

export default compose(connect(mapStateToProps))(WithSubscriptionInvitation);
