export const filterMapper = (url, parameter, values) => {
  if (!values || values == 'undefined' || !Array.isArray(values)) return '';
  const filterParameters = values
    .map(({ id }) => {
      return id;
    })
    .join(',');

  return filterParameters
    ? `${url.includes('?') ? '&' : '?'}${parameter}=${filterParameters}`
    : '';
};

export const filterValueMapper = (url, parameter, value) => {
  if (!value || value == 'undefined' || value.length === 0) return '';
  return `${url.includes('?') ? '&' : '?'}${parameter}=${value}`;
};
