//
// Handle mapping of software options for display purposes
//
import softwareOptions from './softwareOptions';

function SoftwareOptionsMapper(deviceType, options) {
  const deviceSoftwareOptions =
    deviceType &&
    softwareOptions.find(deviceOptions =>
      deviceOptions.devices.includes(deviceType)
    );
  return (
    deviceSoftwareOptions &&
    options
      .map(option => {
        const optionLabel = deviceSoftwareOptions.options[option.name];
        option.label =
          (optionLabel === undefined && option.name) || optionLabel;
        return option;
      })
      .filter(o => o.label)
  );
}

export default SoftwareOptionsMapper;
