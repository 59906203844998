import { SET_PREVENTIVE_MAINTENANCE } from '../actions/preventiveMaintenance';

const initState = {
  preventiveMaintenance: []
};

export const preventiveMaintenanceReducer = (state = initState, action) => {
  const { payload, type } = action;

  switch (type) {
    case SET_PREVENTIVE_MAINTENANCE:
      return {
        ...state,
        preventiveMaintenance: payload
      };
    default:
      return state;
  }
};
