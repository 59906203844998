import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { CaseTimelineChart, EmptyState, Icon } from '..';
import propTypes from 'prop-types';
import get from 'lodash.get';
import { Link } from 'react-router-dom';
import './CaseTimelineWidget.scss';
import {
  createDocument,
  DEVICE_CASE_TIMELINE,
  deviceCaseTimelineFileName
} from '../../utils/export';

const CaseTimelineWidget = ({
  caseTimelineData,
  filtersData,
  intlData,
  devicesData
}) => {
  const exportInstalledBaseData = useCallback(() => {
    const exportingData = {
      ...caseTimelineData,
      ...devicesData
    };
    createDocument(
      DEVICE_CASE_TIMELINE,
      exportingData || [],
      filtersData,
      deviceCaseTimelineFileName,
      null,
      intlData
    );
  }, [devicesData, filtersData, intlData, caseTimelineData]);

  return (
    <>
      <div id="gtg-device-case-timeline-header-row">
        <h5 className="mb-3">
          <FormattedMessage
            id="case.caseTimeline"
            defaultMessage="Case timeline"
          />
        </h5>
        <span id="gtg-device-case-timeline-export">
          <Link onClick={exportInstalledBaseData} to="#">
            <Icon name="download-excel" size="sm" />
          </Link>
        </span>
      </div>
      {(caseTimelineData &&
        // display chart if at least 2 timeline points are available for either agent or one of the timeline metrics
        ((get(caseTimelineData, 'agentUsage') || []).length > 1 ||
          (get(caseTimelineData, 'timeline') || []).some(
            metric => (get(metric, 'values') || []).length > 1
          )) && <CaseTimelineChart caseTimelineData={caseTimelineData} />) || (
        <EmptyState />
      )}
    </>
  );
};

CaseTimelineWidget.propTypes = {
  caseTimelineData: propTypes.shape({
    agentUsage: propTypes.arrayOf(
      propTypes.shape({
        agent: propTypes.oneOf(['Iso', 'Sev', 'Des']),
        timestamp: propTypes.string,
        value: propTypes.number
      })
    ),
    timeline: propTypes.arrayOf(
      propTypes.shape({
        key: propTypes.oneOf(['MacY', 'MacBrain', 'FreshGasFlow']),
        unit: propTypes.string,
        values: propTypes.arrayOf(
          propTypes.shape({
            timestamp: propTypes.string,
            value: propTypes.number
          })
        )
      })
    )
  })
};

export default CaseTimelineWidget;
