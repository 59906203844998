export const entity = '[Preuse check]';
export const proactiveEntity = '[Proactive check]';
export const FETCH_TEST = `${entity} Fetch`;
export const FETCH_PROACTIVE_TEST = `${proactiveEntity} Fetch`;
export const SET_TEST = `${entity} Set`;
export const SET_PROACTIVE_TEST = `${proactiveEntity} Set`;
export const TRANSFORM_TEST = `${entity} Transform`;
export const TRANSFORM_PROACTIVE_TEST = `${proactiveEntity} Transform`;

export const fetchTest = (id, deviceId) => ({
  type: FETCH_TEST,
  payload: {
    id: id,
    deviceId: deviceId
  }
});

export const fetchProactiveTest = (id, deviceId) => ({
  type: FETCH_PROACTIVE_TEST,
  payload: {
    id: id,
    deviceId: deviceId
  }
});

export const setTest = data => ({
  type: SET_TEST,
  payload: data
});

export const setProactiveTest = data => ({
  type: SET_PROACTIVE_TEST,
  payload: data
});

export const transformTest = data => ({
  type: TRANSFORM_TEST,
  payload: data
});

export const transformProactiveTest = data => ({
  type: TRANSFORM_PROACTIVE_TEST,
  payload: data
});
