import React, { useState, useCallback } from 'react';
import propTypes from 'prop-types';
import DeviceInformationEditForm from './DeviceInformationEditForm';

const DeviceInformationCustomerID = props => {
  const [customerDeviceID, setCustomerDeviceID] = useState(
    props.customerDeviceID
  );

  const handleCustomerIdSubmit = useCallback(
    id => {
      // Do an optimistic update for now, expecting the call be successful
      setCustomerDeviceID(id);
      props.onCustomerIdSubmit(id);
    },
    [props]
  );

  return (
    <>
      {props && (
        <DeviceInformationEditForm
          defaultValue={customerDeviceID}
          handleSubmit={handleCustomerIdSubmit}
        />
      )}
    </>
  );
};

DeviceInformationCustomerID.propTypes = {
  customerDeviceID: propTypes.string,
  onCustomerIdSubmit: propTypes.func
};

export default DeviceInformationCustomerID;
