import React, { useCallback, useState, useMemo, useRef } from 'react';
import { compose } from 'redux';
import classnames from 'classnames';
import {
  FormattedDate,
  FormattedMessage,
  injectIntl,
  intlShape
} from 'react-intl';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import {
  EmptyState,
  Formatted24hTime,
  Scrollable,
  getDeviceTypeDisplayName,
  DataTableFixedColumnHeader
} from '../..';
import { PrettyJSON } from '../PrettyJSON';

const subComponent = row => {
  return <PrettyJSON data={row.original} />;
};

const AdminDeviceList = ({ devices, intl, triggerElementRef, selectRow }) => {
  const devicesContainer = useRef(null);
  const tableData = useMemo(() => {
    return devices || [];
  }, [devices]);

  const columns = [
    {
      Header: (
        <FormattedMessage id="column.type" defaultMessage="Device type" />
      ),
      accessor: 'deviceType',
      sortable: false,
      Cell: row =>
        (row.value.length && getDeviceTypeDisplayName(row.value)) || '',
      width: 120
    },
    {
      Header: (
        <FormattedMessage id="column.serialNo" defaultMessage="Serial no." />
      ),
      accessor: 'serialNumber',
      sortable: false
    },
    {
      Header: (
        <FormattedMessage id="column.hospital" defaultMessage="Hospital" />
      ),
      accessor: 'account.name',
      sortable: false
    },
    {
      Header: (
        <FormattedMessage id="column.SWVersion" defaultMessage="SW Version" />
      ),
      accessor: 'systemSoftwareVersion',
      sortable: false
    },
    {
      Header: (
        <FormattedMessage
          id="column.lastConnected"
          defaultMessage="Last Connected"
        />
      ),
      Cell: ({ original }) =>
        (original.lastLog && (
          <React.Fragment>
            {original.lastLog && (
              <>
                <FormattedDate value={original.lastLog} />{' '}
                <Formatted24hTime value={original.lastLog} />{' '}
              </>
            )}
          </React.Fragment>
        )) || (
          <FormattedMessage id="common.notAvailableAbbr" defaultMessage="N/A" />
        ),
      accessor: 'lastConnected',
      sortable: false
    },
    {
      // Additional column that attaches a ref on the n - 1 element in the list. When user only has 10 items remaining
      // to scroll through, the element will be brought into view and trigger a fetch. It could be attached to any existing
      // cell, but is separated for clarity.
      Cell: row => {
        if (row.index === tableData.length - 1) {
          return <div ref={triggerElementRef} />;
        }

        return <div />;
      },
      width: 1,
      noOverflowTooltip: true
    }
  ];

  let table = <EmptyState />;
  if (tableData.length > 0) {
    table = (
      <Scrollable
        minElementHeight={window.innerHeight * 0.75}
        ref={devicesContainer}
        options={{
          scrollbars: {
            visibility: 'hidden'
          }
        }}
      >
        <DataTableFixedColumnHeader
          handleRowClick={selectRow}
          pageSize={[100000]}
          data={tableData || []}
          className={classnames('mb-4', 'gtg-admin-devices-list')}
          columns={columns}
          SubComponent={subComponent}
          withOverflow
        />
      </Scrollable>
    );
  }

  return <React.Fragment>{table}</React.Fragment>;
};

AdminDeviceList.propTypes = {
  intl: intlShape,
  devices: PropTypes.arrayOf(
    PropTypes.shape({
      deviceType: PropTypes.string,
      serialNumber: PropTypes.string,
      account: PropTypes.shape({
        name: PropTypes.string
      }),
      systemSoftwareVersion: PropTypes.string,
      lastConnected: PropTypes.instanceOf(Date)
    })
  )
};

export default compose(withRouter)(injectIntl(AdminDeviceList));
