import { SET_CONNECTED_ACCESSORIES } from '../actions/connectedAccessories';

const initState = {
  connectedAccessories: []
};

export const connectedAccessoriesReducer = (state = initState, action) => {
  const { payload, type } = action;

  switch (type) {
    case SET_CONNECTED_ACCESSORIES:
      return {
        ...state,
        connectedAccessories: payload
      };
    default:
      return state;
  }
};
