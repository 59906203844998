export const entity = '[Log types]';
export const FETCH_LOG_TYPES = `${entity} Fetch`;
export const SET_LOG_TYPES = `${entity} Set`;
export const TRANSFORM_LOG_TYPES = `${entity} Transform`;

export const fetchLogTypes = deviceId => ({
  type: FETCH_LOG_TYPES,
  payload: {
    deviceId: deviceId
  }
});

export const setLogTypes = data => ({
  type: SET_LOG_TYPES,
  payload: data
});

export const transformLogTypes = data => ({
  type: TRANSFORM_LOG_TYPES,
  payload: data
});
