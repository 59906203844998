import React from 'react';
import { FiltersContainer } from '..';

import {
  routePaths,
  ContainerHeader,
  RunningHoursWidget
} from '../../components';

const RunningHoursOverview = () => (
  <>
    <ContainerHeader headerTitle={routePaths.RunningHoursOverview.label}>
      <FiltersContainer />
    </ContainerHeader>
    <div className="row">
      <div className="col-12 col-md-12">
        <RunningHoursWidget />
      </div>
    </div>
  </>
);

export default RunningHoursOverview;
