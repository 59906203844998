export const entity = '[Cases]';
export const FETCH_CASES = `${entity} Fetch`;
export const SET_CASES = `${entity} Set`;

/**
 * Trigger fetch of cases
 */
export const fetchCases = (deviceId, paginationSettings, sortingSettings) => ({
  type: FETCH_CASES,
  pagination: paginationSettings,
  sorting: sortingSettings,
  payload: {
    deviceId: deviceId
  }
});

/**
 * Set data as new cases
 * @param {array} data New cases
 */
export const setCases = data => ({
  type: SET_CASES,
  payload: data
});
