import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Link } from 'react-router-dom';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import get from 'lodash.get';

import {
  routePaths,
  ContainerHeader,
  Card,
  CardSection,
  CardTabbed,
  CardTab,
  DeviceListWidget,
  DevicesStatusOverviewWidget,
  ServiceOrderListWidget,
  UsageStatisticsWidget,
  UsageStatisticsServoWidget,
  PreventiveMaintenanceListWidget,
  uiConfig,
  userRoles,
  RunningHoursWidget,
  deviceTypes,
  Icon,
  isServoFamily,
  isFlowFamily
} from '../../components';
import { WithLoadingSpinner } from '../../hoc';
import { FiltersContainer } from '..';

import { fetchDevices } from '../../redux/actions/devices';
import { fetchDevicesOverview } from '../../redux/actions/devicesOverview';
import { fetchServiceOrders } from '../../redux/actions/serviceOrders';
import { fetchServiceOrdersOverview } from '../../redux/actions/serviceOrdersOverview';
import { fetchPreventiveMaintenance } from '../../redux/actions/preventiveMaintenance';
import {
  createDocument,
  SERVICE_ORDERS_LIST,
  PREVENTIVE_MAINTENANCE_LIST,
  INSTALLED_BASE_LIST,
  serviceOrdersListFileName,
  preventiveMaintenanceListFileName,
  installedBaseListFileName
} from '../../utils/export';
import { userInfoShape } from '../../utils/proptypes/userInfoShape';
import { subscriptionsShape } from '../../utils/proptypes/subscriptionsShape';

const Dashboard = props => {
  const {
    devices,
    devicesStatusOverview,
    serviceOrders,
    serviceOrdersOverview,
    getDevicesStatusOverview,
    fetchDevices,
    fetchServiceOrders,
    fetchServiceOrdersOverview,
    filters,
    fetchPreventiveMaintenance,
    preventiveMaintenance,
    userinfo,
    availableDeviceTypes,
    intl,
    config
  } = props;
  const serviceOrdersData = useMemo(() => {
    return serviceOrders;
  }, [serviceOrders]);
  const [filteredServiceOrdersData, setFilteredServiceOrdersData] = useState(
    []
  );
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    fetchDevices(
      {
        page: 0,
        pageSize: 5
      },
      '-statusWrong, -lastLog, serialID'
    );
    getDevicesStatusOverview();
    fetchServiceOrders(
      undefined,
      {
        page: 0,
        pageSize: 5
      },
      '-created'
    );
    fetchServiceOrdersOverview();
    fetchPreventiveMaintenance(undefined, {
      page: 0,
      pageSize: 5
    });
  }, [filters]);
  /* eslint-enable react-hooks/exhaustive-deps */

  // We only capture usage statistics for flow-i devices, therefore the usage statistics can be
  // hidden if there are no flow-i devices in the currently selected account
  const deviceTypesFilterApplied = get(filters, 'deviceTypes').length > 0;
  const flowiDeviceTypeAvailable = (availableDeviceTypes || []).some(t =>
    isFlowFamily(t['key'])
  );
  const flowiInFilteredDeviceTypes = get(filters, 'deviceTypes').some(t =>
    isFlowFamily(t['key'])
  );

  const servoDeviceTypeAvailable = (availableDeviceTypes || []).some(t =>
    isServoFamily(t['key'])
  );
  const servoInFilteredDeviceTypes = get(filters, 'deviceTypes').some(t =>
    isServoFamily(t['key'])
  );

  const enableServoUsageStatistics = get(
    config,
    'enableServoUsageStatistics',
    false
  );

  const displayFlowUsageStatistics =
    (!deviceTypesFilterApplied && flowiDeviceTypeAvailable) ||
    (deviceTypesFilterApplied && flowiInFilteredDeviceTypes);

  const displayServoUsageStatistics =
    (!deviceTypesFilterApplied && servoDeviceTypeAvailable) ||
    (deviceTypesFilterApplied && servoInFilteredDeviceTypes);

  const getDashboardConfig = () => {
    const userRole =
      (userinfo && userinfo.portal_role) || userRoles.GetingeOnline_Clinician;
    const widgetConfig = uiConfig.dashboardWidgetsConfig.find(config => {
      return config.userRoles.find(role => role.userRole === userRole);
    });
    return widgetConfig && widgetConfig.config;
  };

  const exportServiceOrdersData = useCallback(() => {
    createDocument(
      SERVICE_ORDERS_LIST,
      filteredServiceOrdersData || [],
      filters,
      serviceOrdersListFileName,
      null,
      intl
    );
  }, [filteredServiceOrdersData, filters, intl]);

  const ServiceOrdersCardRight = useCallback(
    () => (
      <Link onClick={exportServiceOrdersData} to="#">
        <Icon name="download-excel" size="sm" />
      </Link>
    ),
    [exportServiceOrdersData]
  );

  const exportPreventiveMaintenanceData = useCallback(() => {
    createDocument(
      PREVENTIVE_MAINTENANCE_LIST,
      preventiveMaintenance || [],
      filters,
      preventiveMaintenanceListFileName,
      null,
      intl
    );
  }, [intl, preventiveMaintenance, filters]);

  const PreventiveMaintenanceCardRight = useCallback(
    () => (
      <Link onClick={exportPreventiveMaintenanceData} to="#">
        <Icon name="download-excel" size="sm" />
      </Link>
    ),
    [exportPreventiveMaintenanceData]
  );

  const exportInstalledBaseData = useCallback(() => {
    createDocument(
      INSTALLED_BASE_LIST,
      devices || [],
      filters,
      installedBaseListFileName,
      null,
      intl
    );
  }, [devices, filters, intl]);

  const InstalledBaseCardRight = useCallback(
    () => (
      <Link onClick={exportInstalledBaseData} to="#">
        <Icon name="download-excel" size="sm" />
      </Link>
    ),
    [exportInstalledBaseData]
  );

  let usageStatisticsWidget = (
    <>
      <UsageStatisticsWidget withViewDetails />
    </>
  );

  let usageStatisticsServoWidget = (
    <>
      <UsageStatisticsServoWidget
        shouldShowCategoryList={false}
        shouldShowBadges
        withViewDetails
        shouldShowy2y={false}
      />
    </>
  );

  const dashboardUsageStatistics = () => {
    if (
      enableServoUsageStatistics &&
      displayFlowUsageStatistics &&
      displayServoUsageStatistics
    ) {
      return (
        <div className="gtg-usage-statistics-tabs-dashboard gtg-usage-statistics-tabs">
          <div className="row">
            <div className="col-12 col-md-12">
              <CardTabbed
                dataCy="cardInstalledBase"
                id="gtg-usage-statistics-card-tab"
                customClass="gtg-usage-statistics-card-margin-zero"
              >
                <CardTab
                  title={
                    <div id="flow">
                      <Icon name="flow" size="md" />
                      <span>Flow</span>
                    </div>
                  }
                >
                  {usageStatisticsWidget}
                </CardTab>
                <CardTab
                  title={
                    <div id="servo">
                      <Icon name="servo" size="md" />
                      <span>Servo</span>
                    </div>
                  }
                >
                  {usageStatisticsServoWidget}
                </CardTab>
              </CardTabbed>
            </div>
          </div>
        </div>
      );
    }

    if (displayFlowUsageStatistics) {
      return <Card>{usageStatisticsWidget}</Card>;
    }

    if (enableServoUsageStatistics && displayServoUsageStatistics) {
      return <Card>{usageStatisticsServoWidget}</Card>;
    }
  };

  const dashboardWidgets = {
    devicesStatusOverview: (
      <DevicesStatusOverviewWidget
        devicesStatusOverview={devicesStatusOverview}
        serviceOrdersOverview={serviceOrdersOverview}
      />
    ),
    deviceList: (
      <Card dataCy="cardInstalledBase">
        <CardSection
          title={
            <FormattedMessage
              id="common.installedBaseStatus"
              defaultMessage="Installed base"
            />
          }
          CardHeaderRight={InstalledBaseCardRight}
        >
          <WithLoadingSpinner when="devices">
            <DeviceListWidget devices={devices || []} />
          </WithLoadingSpinner>
        </CardSection>
      </Card>
    ),
    serviceOrderList: (
      <Card>
        <CardSection
          title={
            <FormattedMessage
              id="common.serviceOrdersOverview"
              defaultMessage="Service orders overview"
            />
          }
          CardHeaderRight={ServiceOrdersCardRight}
        >
          <WithLoadingSpinner when="serviceOrders">
            <ServiceOrderListWidget
              serviceOrders={serviceOrdersData}
              setData={setFilteredServiceOrdersData}
            />
          </WithLoadingSpinner>
        </CardSection>
      </Card>
    ),
    preventiveMaintenanceList: (
      <Card>
        <CardSection
          title={routePaths.PreventiveMaintenanceOverview.label}
          CardHeaderRight={PreventiveMaintenanceCardRight}
        >
          <WithLoadingSpinner when="preventiveMaintenance">
            <PreventiveMaintenanceListWidget
              preventiveMaintenance={preventiveMaintenance || []}
            />
          </WithLoadingSpinner>
        </CardSection>
      </Card>
    ),
    runningHoursList: displayFlowUsageStatistics && (
      <RunningHoursWidget smallMode />
    ),
    usageStatistics: dashboardUsageStatistics()
  };

  return (
    <>
      <ContainerHeader headerTitle={routePaths.Dashboard.label}>
        <FiltersContainer />
      </ContainerHeader>
      <div className="row">
        {getDashboardConfig().map(widget => {
          return (
            <div
              key={widget.widgetName}
              className={classnames('col-12', !widget.fullWidth && 'col-md-6')}
            >
              {dashboardWidgets[widget.widgetName]}
            </div>
          );
        })}
      </div>
    </>
  );
};

const mapStateToProps = state => ({
  devices: get(state, 'devices.devices.items'),
  devicesStatusOverview: get(
    state,
    'devicesStatusOverview.devicesStatusOverview'
  ),
  subscriptions: get(
    state,
    'devicesStatusOverview.devicesStatusOverview.subscriptions'
  ),
  serviceOrders: get(state, 'serviceOrders.serviceOrders.items'),
  serviceOrdersOverview: get(
    state,
    'serviceOrdersOverview.serviceOrdersOverview'
  ),
  preventiveMaintenance: get(
    state,
    'preventiveMaintenance.preventiveMaintenance.items'
  ),
  filters: get(state, 'filters'),
  userinfo: get(state, 'authorization.userinfo'),
  availableDeviceTypes: get(state, 'deviceFilters.deviceTypes'),
  config: get(state, 'config')
});

const mapDispatchToProps = {
  fetchDevices,
  fetchServiceOrders,
  fetchServiceOrdersOverview,
  getDevicesStatusOverview: fetchDevicesOverview,
  fetchPreventiveMaintenance
};

Dashboard.propTypes = {
  devices: PropTypes.arrayOf(PropTypes.shape({})),
  devicesStatusOverview: PropTypes.shape({}).isRequired,
  subscriptions: PropTypes.shape({
    count: PropTypes.number,
    level: PropTypes.string,
    priority: PropTypes.number
  }),
  serviceOrdersOverview: PropTypes.shape({
    all: PropTypes.number,
    closed: PropTypes.number,
    opened: PropTypes.number
  }).isRequired,
  userinfo: userInfoShape,
  intl: intlShape
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(Dashboard);
