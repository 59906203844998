import {
  SET_TESTS,
  FETCH_TESTS,
  SET_DEVICE_TEST_NOT_PERFORMED_ALARM
} from '../actions/tests';

export function testsReducer(
  state = {
    tests: null,
    testNotPerformedAlarm: false
  },
  action
) {
  switch (action.type) {
    case FETCH_TESTS:
      return {
        ...state,
        tests: null
      };
    case SET_TESTS:
      return {
        ...state,
        tests: action.payload
      };
    case SET_DEVICE_TEST_NOT_PERFORMED_ALARM:
      return {
        ...state,
        testNotPerformedAlarm: action.payload
      };

    default:
      return state;
  }
}
