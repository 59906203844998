export const entity = '[Admin]';
export const FETCH_FILTER_ACCOUNTS = `${entity} Fetch filter accounts`;
export const SET_FILTER_ACCOUNTS = `${entity} Set filter accounts`;
export const FETCH_FILTER_DEVICE_TYPES = `${entity} Fetch filter device types`;
export const SET_FILTER_DEVICE_TYPES = `${entity} Set filter device types`;
export const FETCH_DEVICES_BY_SERIAL = `${entity} Fetch devices by serial`;
export const SET_DEVICES_BY_SERIAL = `${entity} Set devices by serial`;
export const UPLOAD_CERTIFICATIONS = `${entity} Upload certifications`;
export const SET_UPLOAD_CERTIFICATIONS_RESULT = `${entity} Set upload certifications result`;
export const FETCH_DEVICE_SYSTEM_LOGS = `${entity} Fetch device system logs`;
export const SET_DEVICE_SYSTEM_LOGS = `${entity} Set device system logs`;
export const FETCH_CN_SYSTEM_LOGS = `${entity} Fetch connectivity node system logs`;
export const SET_CN_SYSTEM_LOGS = `${entity} Set connectivity node system logs`;
export const UPLOAD_SOFTWARE = `${entity} Upload software`;
export const SET_UPLOAD_SOFTWARE_RESULT = `${entity} Set upload software result`;

/**
 * Trigger filter actions
 */
export const fetchFilterAccounts = searchStr => ({
  type: FETCH_FILTER_ACCOUNTS,
  searchStr: searchStr
});

export const setFilterAccounts = data => ({
  type: SET_FILTER_ACCOUNTS,
  payload: data
});

export const fetchFilterDeviceTypes = accountId => {
  return {
    type: FETCH_FILTER_DEVICE_TYPES,
    id: accountId
  };
};

export const setFilterDeviceTypes = data => ({
  type: SET_FILTER_DEVICE_TYPES,
  payload: data
});

/**
 * Trigger search by parameters
 */
export const fetchDevicesBySerial = (
  { serialNumber, softwareVersion, accountIDs, deviceTypes },
  devicesCursor,
  connectivityNodesCursor
) => {
  return {
    type: FETCH_DEVICES_BY_SERIAL,
    serialNumber: serialNumber,
    softwareVersion: softwareVersion,
    accountIDs: accountIDs,
    deviceTypes: deviceTypes,
    devicesCursor: devicesCursor,
    connectivityNodesCursor: connectivityNodesCursor
  };
};

/**
 * Set found devices
 * @param {array} data Found devices
 */
export const setSearchDevicesBySerial = data => ({
  type: SET_DEVICES_BY_SERIAL,
  payload: data
});

/**
 * Upload certifications file
 */
export const uploadCertifications = data => ({
  type: UPLOAD_CERTIFICATIONS,
  payload: data
});

/**
 * Set upload certifications result
 */
export const setUploadCertificationsResult = data => ({
  type: SET_UPLOAD_CERTIFICATIONS_RESULT,
  payload: data
});

/**
 * Trigger admin logs search by device uuid /
 */
export const fetchDeviceSystemLogs = (uuid, cursor) => ({
  type: FETCH_DEVICE_SYSTEM_LOGS,
  uuid: uuid,
  cursor: cursor
});

/**
 * Set found admin logs
 * @param {array} data Found admin logs
 */
export const setDeviceSystemLogs = data => ({
  type: SET_DEVICE_SYSTEM_LOGS,
  payload: data
});

/**
 * Trigger admin logs search by connectivity node uuid /
 */
export const fetchCNSystemLogs = (uuid, cursor) => ({
  type: FETCH_CN_SYSTEM_LOGS,
  uuid: uuid,
  cursor: cursor
});

/**
 * Set found admin logs for connectivity node
 * @param {array} data Found admin logs
 */
export const setCNSystemLogs = data => ({
  type: SET_CN_SYSTEM_LOGS,
  payload: data
});

/**
 * Upload software file
 */
export const uploadSoftware = (data, cnType) => ({
  type: UPLOAD_SOFTWARE,
  payload: data,
  cnType: cnType
});

/**
 * Set upload software result
 */
export const setUploadSoftwareResult = data => ({
  type: SET_UPLOAD_SOFTWARE_RESULT,
  payload: data
});
