export const API_URLS = {
  Api: {
    url: `/api/v1`,
    filter: false
  },
  Accounts: {
    url: '/api/v1/accounts',
    filter: false
  },
  Currency: {
    url: '/api/v1/currencies',
    filter: false
  },
  Config: {
    url: '/config/generalConfig.json',
    filter: false
  },
  Devices: {
    url: `/api/v1/devices`,
    filter: true
  },
  ConnectedAccessories: {
    url: `/api/v1/devices/connected-accessories`,
    filter: true
  },
  AdminSoftwareVersions: {
    url: `/api/v1/admin/software-versions`,
    filter: true
  },
  DevicesOverview: {
    url: `/api/v1/devices/overview`,
    filter: true
  },
  DevicesRunningHours: {
    url: `/api/v1/running-hours`,
    filter: true
  },
  Documentation: {
    url: `/api/v1/documentation`,
    filter: false
  },
  Logs: (deviceId, logType) => ({
    url: `/api/v1/devices/${deviceId}/logs/${logType}`,
    filter: false
  }),
  LogTypes: deviceId => ({
    url: `/api/v1/devices/${deviceId}/log-types`,
    filter: false
  }),
  LogDownload: (deviceId, logType) => ({
    url: `/api/v1/devices/${deviceId}/logs/${logType}/download`,
    filter: false
  }),
  Metrics: {
    url: `/api/v1/metrics`,
    filter: true
  },
  MetricsDetailed: {
    url: `/api/v1/metrics/detailed`,
    filter: true
  },
  MetricsUsage: {
    url: `/api/v1/metrics/usage`,
    filter: true
  },
  MetricsDetailedUsagePerDevice: {
    url: `/api/v1/metrics/device-usage`,
    filter: true
  },
  MetricsTrends: {
    url: `/api/v1/metrics/trends`,
    filter: true
  },
  PreUseChecks: {
    url: `/api/v1/pre-use-checks`,
    filter: false
  },
  PreUseCheck: (deviceId, id) => ({
    url: `/api/v1/devices/${deviceId}/pre-use-checks/${id}`,
    filter: false
  }),
  ProactiveWatch: (deviceId, id) => ({
    url: `/api/v1/devices/${deviceId}/proactive-watches/${id}`,
    filter: false
  }),
  ServiceOrders: {
    url: `/api/v1/service-orders`,
    filter: true
  },
  ServiceOrdersOverview: {
    url: `/api/v1/service-orders/overview`,
    filter: true
  },
  TroubleshootingGuidance: deviceId => ({
    url: `/api/v1/devices/${deviceId}/troubleshooting-guidance`,
    filter: false
  }),
  PreventiveMaintenance: {
    url: `/api/v1/preventive-maintenance`,
    filter: true
  },
  DevicePreventiveMaintenance: deviceId => ({
    url: `/api/v1/devices/${deviceId}/preventive-maintenance`,
    filter: true
  }),
  LegalNoticeApproval: {
    url: `/api/v1/user/disclaimer`,
    filter: false
  },
  Userinfo: {
    url: `/api/v1/user/userinfo`,
    filter: false
  },
  Profile: {
    url: `/api/v1/user/profile`,
    filter: false
  },
  DeviceTypes: accountId => ({
    url: `/api/v1/accounts/${accountId}/device-types`,
    filter: false
  }),
  Cases: deviceId => ({
    url: `/api/v1/devices/${deviceId}/cases`,
    filter: true
  }),
  UsageStatistics: {
    url: `/api/v1/usage-statistics`,
    filter: true
  },
  UsageStatisticsUtilization: {
    url: `/api/v1/usage-statistics/utilization`,
    filter: true
  },
  UsageStatisticsDetailed: {
    url: `/api/v1/usage-statistics/detailed`,
    filter: true
  },
  JWT: {
    url: `/api/v1/token`,
    filter: false
  },
  AdminSearchBySerial: {
    url: `/api/v1/admin/devices`,
    filter: false
  },
  AdminUploadCertifications: {
    url: `/api/v1/users/certifications`
  },
  AdminDeviceLogsSearch: {
    url: `/api/v1/admin/devices`
  },
  AdminCNLogsSearch: {
    url: `/api/v1/admin/cn`
  },
  AdminUploadSoftware: {
    url: `/api/v1/admin/software`
  }
};
