import { SET_PW_AVAILABLE_DEVICE_TYPES } from '../actions/accessInfo';

const initialState = {
  pwDeviceTypes: []
};

export function accessInfoReducer(state = initialState, action) {
  switch (action.type) {
    case SET_PW_AVAILABLE_DEVICE_TYPES:
      return {
        ...state,
        pwDeviceTypes: action.payload
      };
    default:
      return state;
  }
}
