// Add column headers and define column keys and widths
export const deviceCaseTimelineListColumns = [
  { header: 'Hospital', key: 'hospital', width: 35 },
  { header: 'Department', key: 'departmentName', width: 20 },
  { header: 'Location', key: 'location', width: 20 },
  { header: 'Device', key: 'deviceType', width: 15 },
  { header: 'Serial no', key: 'serial', width: 15 },
  { header: 'Customer device no', key: 'customerDeviceNum', width: 20 },
  { header: 'Start case', key: 'startCase', width: 20 },
  { header: 'End case', key: 'endCase', width: 20 },
  { header: 'Timestamp', key: 'timestamp', width: 20 },
  { header: 'SEV [%]', key: 'sev', width: 15 },
  { header: 'DES [%]', key: 'des', width: 15 },
  { header: 'ISO [%]', key: 'iso', width: 15 },
  { header: 'FGF [L/min]', key: 'fgf', width: 15 },
  { header: 'MAC Y', key: 'macy', width: 15 },
  { header: 'MAC Brain', key: 'macBrain', width: 15 }
];

export const deviceCaseTimelineFileName = 'Case_details_timeline.xlsx';
