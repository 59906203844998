// maps the incoming object values to interpret boolean true false and
// strings 'true' and 'false' correctly, and returns an object with only
// boolean values
export function parseAsBoolValues(obj) {
  return Object.keys(obj).reduce((prev, key) => {
    let val;

    if (key.startsWith('enable') || key.startsWith('disable')) {
      // looks like a feature flag - set to false by default
      switch (obj[key]) {
        case true:
        case 'true':
        case 'True':
          val = true;
          break;
        case false:
        case 'false':
        case 'False':
          val = false;
          break;
        default:
          // if the value is not in the expected boolean forms, use the actual value
          val = false;
          break;
      }
    } else {
      val = obj[key];
    }

    return {
      [key]: val,
      ...prev
    };
  }, {});
}
