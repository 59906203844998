import React, { useCallback, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  ContainerHeader,
  Card,
  CardSection,
  CaseVentilationModesWidget,
  CaseTimelineWidget,
  CaseGasConsumptionWidget,
  CaseAveragesWidget,
  DeviceSettingsWidget,
  AgentCostWidget,
  EmptyState,
  subscriptionTypes,
  Icon
} from '../../components';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import propTypes from 'prop-types';
import { WithLoadingSpinner, WithSubscriptionInvitation } from '../../hoc';
import { fetchCase } from '../../redux/actions/case';
import { caseShape, CaseDetailTitle } from './caseHelpers';

import { FormattedMessage, intlShape, injectIntl } from 'react-intl';
import {
  CASE_DETAILS,
  caseDetailsFileName,
  createDocument
} from '../../utils/export';
import './Case.scss';

export const Case = ({
  match,
  caseInfo,
  fetchCase,
  device,
  filters,
  costs,
  intl
}) => {
  const caseId = match.params.caseId;
  const deviceId = match.params.deviceId;
  let subscriptionArray = [];
  if (device?.subscription === subscriptionTypes.premium) {
    subscriptionArray = [
      { count: 1, level: subscriptionTypes.premium, priority: 1 }
    ];
  }

  useEffect(() => {
    fetchCase(deviceId, caseId);
  }, [deviceId, caseId, fetchCase]);

  const exportCaseDetails = useCallback(() => {
    createDocument(CASE_DETAILS, caseInfo || [], filters, caseDetailsFileName, {
      device: device,
      costs: costs
    });
  }, [filters, caseInfo, device, costs]);

  const caseComponent = (
    <WithLoadingSpinner when={'case'}>
      {caseInfo && (
        <>
          <ContainerHeader
            headerTitle={
              <CaseDetailTitle start={caseInfo.start} end={caseInfo.end} />
            }
          />
          <div className="row">
            <div className="col-md-12 col-12">
              <Card>
                <CardSection dataCy="caseDetails">
                  {caseInfo.hasDetails ? (
                    <>
                      <div className="row mb-4">
                        <div className="col-md-9 col-9">
                          <CaseTimelineWidget
                            caseTimelineData={{
                              agentUsage: caseInfo.agentUsage,
                              timeline: caseInfo.timeline,
                              start: caseInfo.start,
                              end: caseInfo.end
                            }}
                            filtersData={filters}
                            intlData={intl}
                            devicesData={device}
                          />
                        </div>
                        <div className="col-md-3 col-3">
                          <CaseGasConsumptionWidget
                            metrics={caseInfo.metrics}
                            device={device}
                            caseStart={caseInfo?.start}
                            caseEnd={caseInfo?.end}
                            filters={filters}
                          />
                        </div>
                      </div>
                      <div className="row mb-4">
                        <div className="col-md-6 col-6">
                          <h5 className="mb-3 d-inline-block">
                            <FormattedMessage
                              id="case.caseDetails"
                              defaultMessage="Case details"
                            />
                          </h5>
                        </div>
                        <div className="col-md-6 col-6">
                          <div className="float-right">
                            <Link onClick={exportCaseDetails} to="#">
                              <Icon name="download-excel" size="sm" />
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-4 col-4">
                          <AgentCostWidget costs={costs} />
                          <DeviceSettingsWidget
                            recruitmentManeuver={caseInfo.recruitmentManeuver}
                            caseAgc={caseInfo.agc}
                          />
                        </div>
                        <div className="col-md-4 col-4">
                          <CaseVentilationModesWidget
                            caseVentilationData={caseInfo.ventilation}
                          />
                        </div>
                        <div className="col-md-4 col-4">
                          <CaseAveragesWidget averages={caseInfo.averages} />
                        </div>
                      </div>
                    </>
                  ) : (
                    <EmptyState
                      msg={
                        <FormattedMessage
                          id="case.insufficientData"
                          defaultMessage="There is insufficient data to display meaningful case details."
                        />
                      }
                    />
                  )}
                </CardSection>
              </Card>
            </div>
          </div>
        </>
      )}
    </WithLoadingSpinner>
  );

  return (
    <div id="gtg-device-case-details">
      <WithSubscriptionInvitation
        accessWidget={'cases'}
        trigger={[subscriptionTypes.premium]}
        customTitleForInvitation={'case.subscriptionInvitationTitle'}
        customSubtitleForInvitation={'case.subscriptionInvitationSubtitle'}
        subscriptions={subscriptionArray}
      >
        {caseComponent}
      </WithSubscriptionInvitation>
    </div>
  );
};

const mapStateToProps = state => ({
  caseInfo: state.case.case,
  costs: state.costs.costs,
  filters: state.filters
});

const mapDispatchToProps = {
  fetchCase: fetchCase
};

Case.propTypes = {
  fetchCase: propTypes.func.isRequired,
  caseInfo: propTypes.shape(caseShape),
  match: propTypes.shape({
    params: propTypes.shape({
      caseId: propTypes.string
    })
  }).isRequired,
  device: propTypes.shape({
    deviceType: propTypes.string,
    serialID: propTypes.string,
    department: propTypes.shape({
      name: propTypes.string
    }),
    customerDeviceID: propTypes.string,
    subscriptionExpirationDate: propTypes.string
  }),
  intl: intlShape
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  injectIntl
)(Case);
