import { isServoFamily, isRotaflowFamily } from './deviceHelper';

const deviceSwVersionMapper = (deviceType = '', swVersion) => {
  if (
    (isServoFamily(deviceType) || isRotaflowFamily(deviceType)) &&
    swVersion
  ) {
    // change format from X.X.X.X to X.X.X
    swVersion = swVersion
      .split('.')
      .slice(0, 3)
      .join('.');
  }
  return swVersion;
};

export default deviceSwVersionMapper;
