import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { WithLoadingSpinner } from '../../hoc';
import { Card, CardSection, DataTable, EmptyState, ProgressIcon } from '..';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { fetchDocument } from '../../redux/actions/document';
import { fetchBlob } from '../../redux/actions/blobDownload';

import './DeviceDocumentationWidget.scss';

function documentationMapper(data) {
  return data.map(documentationItem => {
    documentationItem.info = {
      id: documentationItem.id,
      name: documentationItem.name ? documentationItem.name : '',
      description: documentationItem.description
        ? documentationItem.description
        : ''
    };
    return documentationItem;
  });
}

const DeviceDocumentationWidget = props => {
  const { fetchDocument, documents, files } = props;
  const [downloadingDocuments, setDownloadingDocuments] = useState([]);

  // Keep a list of documents in progress, based on files
  useEffect(() => {
    setDownloadingDocuments(
      files
        .map(f => {
          const regex = /\/(?<base>.*)\/(?<documentId>.*)\/download/;
          const a = f.url.match(regex);
          return a && a[2] ? { ...f, id: a[2] } : undefined;
        })
        .filter(a => a)
    );
  }, [files]);

  const DocumentIcon = documentId => {
    const doc = downloadingDocuments.find(d => d.id === documentId);
    const status =
      (doc &&
        ((doc.failed === true && 'error') ||
          (doc.failed === false && 'success') ||
          'inprogress')) ||
      'download';
    return (
      <ProgressIcon status={status} progress={(doc && doc.progress) || 0} />
    );
  };

  const tableColumns = [
    {
      Header: (
        <FormattedMessage
          id="documentation.documentationForThisDevice"
          defaultMessage="Documentation for this device"
        />
      ),
      accessor: 'info',
      noOverflowTooltip: true,
      width: 270,
      Cell: row => {
        return (
          <React.Fragment>
            {DocumentIcon(row.value.id)}
            <div>
              <div>
                <strong>{row.value.name}</strong>
              </div>
              <span>{row.value.description}</span>
            </div>
          </React.Fragment>
        );
      }
    },
    {
      Header: <FormattedMessage id="column.date" defaultMessage="Date" />,
      accessor: 'created',
      Cell: row => (
        <React.Fragment>
          <FormattedDate value={row.value} />
        </React.Fragment>
      )
    },
    {
      Header: (
        <FormattedMessage
          id="documentation.language"
          defaultMessage="Language"
        />
      ),
      accessor: 'language'
    }
  ];

  /* eslint-disable react-hooks/exhaustive-deps */
  const handleRowClick = useCallback((state, rowInfo) => {
    return {
      onClick: (e, t) => {
        if (!downloadingDocuments.find(d => d.id === rowInfo.original.id)) {
          fetchDocument(rowInfo.original.id);
        }
      }
    };
  });
  /* eslint-enable react-hooks/exhaustive-deps */

  return (
    <Card dataCy="cardDocumentation">
      <CardSection
        title={
          <FormattedMessage
            id="navbar.documentation"
            defaultMessage="Documentation"
          />
        }
      >
        <WithLoadingSpinner when="documents">
          {(documents && documents.length > 0 && (
            <DataTable
              getTrProps={handleRowClick}
              showPagination={false}
              sortable={false}
              resizable={false}
              data={documentationMapper(documents)}
              className="gtg-list-table gtg-documentation-widget mb-3"
              columns={tableColumns}
              maxHeight={300}
            />
          )) || <EmptyState />}
        </WithLoadingSpinner>
      </CardSection>
    </Card>
  );
};

const mapDispatchToProps = {
  fetchBlob,
  fetchDocument
};

const mapStateToProps = state => ({
  files: state.blobDownload.files
});

DeviceDocumentationWidget.propTypes = {
  documents: PropTypes.arrayOf(PropTypes.shape())
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  DeviceDocumentationWidget
);
