export const entity = '[Metrics Usage]';
export const FETCH_METRICS_USAGE = `${entity} Fetch`;
export const SET_METRICS_USAGE = `${entity} Set`;

/**
 * Trigger fetch of metrics usage
 */
export const fetchMetricsUsage = (timeSpan, count, deviceIds) => ({
  type: FETCH_METRICS_USAGE,
  payload: {
    deviceIds,
    timeSpan,
    count
  }
});

/**
 * Set data as new metrics usage
 * @param {array} data New metrics usage
 */
export const setMetricsUsage = data => ({
  type: SET_METRICS_USAGE,
  payload: data
});
