import React from 'react';
import PropTypes from 'prop-types';

import OverlayScrollbars from 'overlayscrollbars';
import './Scrollable.scss';

class Scrollable extends React.Component {
  constructor(props) {
    super(props);
    this.osTargetRef = React.createRef();
  }

  componentDidMount() {
    this.osInstance = OverlayScrollbars(
      this.osTargetRef.current,
      this.props.options || {},
      this.props.extensions
    );
  }

  componentWillUnmount() {
    if (this.osInstance && this.osInstance.destroy) this.osInstance.destroy();
  }

  render() {
    const {
      minElementHeight,
      maxElementHeight,
      minElementWidth,
      maxElementWidth,
      ...rest
    } = this.props;
    return (
      <div
        className="scrollable-container"
        style={{
          minHeight: minElementHeight ? minElementHeight : 'auto',
          maxHeight: maxElementHeight ? maxElementHeight : '100%',
          minWidth: minElementWidth ? minElementWidth : 'auto',
          maxWidth: maxElementWidth ? maxElementWidth : '100%'
        }}
        {...rest}
        ref={this.osTargetRef}
      >
        {this.props.children}
      </div>
    );
  }
}

Scrollable.propTypes = {
  minElementHeight: PropTypes.number,
  maxElementHeight: PropTypes.number,
  minElementWidth: PropTypes.number,
  maxElementWidth: PropTypes.number,
  options: PropTypes.shape(),
  extensions: PropTypes.shape()
};

export default Scrollable;
