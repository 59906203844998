import React, { Component } from 'react';
import propTypes from 'prop-types';
import get from 'lodash.get';
import {
  FlexibleWidthXYPlot,
  XAxis,
  YAxis,
  VerticalGridLines,
  HorizontalGridLines,
  VerticalBarSeries,
  VerticalBarSeriesCanvas,
  LineMarkSeries,
  ChartLabel,
  Hint
} from 'react-vis';
import {
  Tooltip,
  uuidv4,
  AgentKeyMapper,
  AgentColorByKey,
  NodeHintDataMapper,
  MetricsConfig
} from '..';

import './AgentUsageChart.scss';

// Used to prefix the X axis label when it is a weekend
export const WEEKEND_PREFIX = '%WEEKEND%';

const axisStyles = {
  line: { stroke: '#565959', strokeWidth: 1 },
  ticks: { stroke: '#565959' },
  text: { stroke: 'none', fill: '#6b6b76', fontWeight: 600 },
  weekendTextOverride: { fill: '#a6a6ac' }
};

const gridLinesStyles = {
  stroke: 'rgba(86,89,89,.2)',
  strokeWidth: 1
};

function CustomHint(node, data) {
  return (
    <Hint
      value={{
        x: node.x,
        y: node.y
      }}
    >
      <Tooltip content={TooltipContent(data)} />
    </Hint>
  );
}

function getMaxStackedBarValue(data) {
  let a = [];
  for (let i = 0; i < data.length; i++) {
    if (data[i].agent.toLowerCase() === 'cases') {
      continue;
    }
    const q = data[i].previousYear ? 1 : 0;
    if (!a[q]) {
      a[q] = [];
    }
    a[q].push(data[i]);
  }
  return (
    (a.length > 0 &&
      Math.max(
        ...[].concat.apply(
          [],
          ...a.map(subArr =>
            subArr
              .map(d => d.data.map(e => e.y))
              .reduce((acc, row) => {
                for (let k = 0; k < row.length; k++) {
                  acc[k] = acc[k] + row[k];
                }
                return acc;
              })
          )
        )
      )) ||
    50
  );
}

function scaleFgfData(lineData, scaleFactor) {
  return JSON.parse(JSON.stringify(lineData)).map(p => {
    p.y = p.y * scaleFactor;
    return p;
  });
}

const TooltipContent = data => {
  const years = [...new Set(data.map(a => a.year))];
  return years.map(c => (
    <React.Fragment key={uuidv4()}>
      {years.length > 1 && <span>{c}</span>}
      {data
        .filter(a => a.year === c)
        .map(a => (
          <dl key={uuidv4()}>
            <dt>
              {a.icon} {a.name}
            </dt>
            <dd>{a.value}</dd>
          </dl>
        ))}
    </React.Fragment>
  ));
};

const AgentUsageChartLegend = ({ items, label = 'short' }) => {
  return (
    <div className="gtg-agent-usage-chart-legend">
      {items.map(a => (
        <span className={'agent-' + a} key={uuidv4()}>
          {AgentKeyMapper(a).icon}{' '}
          {(label === 'short' && AgentKeyMapper(a).labelShort) ||
            AgentKeyMapper(a).label}
        </span>
      ))}
    </div>
  );
};

export default class AgentUsageChart extends Component {
  state = {
    useCanvas: false,
    hoveredBarNode: null,
    hoveredLineNode: null
  };

  TimeAxisTickFormatter = t => {
    // Apply a different style to X axis label if it is a weekend
    const isWeekend = t.startsWith(WEEKEND_PREFIX);
    // Remove the prefix
    t = t.replace(WEEKEND_PREFIX, '');

    if (get(this, 'props.xAxisSettings.displayMonth', false)) {
      t = t.slice(0, 3);
    }

    const dy = get(this, 'props.xAxisSettings.yPadding', '1.3em');
    return (
      <tspan style={isWeekend ? axisStyles.weekendTextOverride : {}}>
        <tspan x="0" dy={dy}>
          {t}
        </tspan>
      </tspan>
    );
  };

  setHoverState = (hoveredNodeType, node) => {
    this.setState({
      hoveredBarNode:
        hoveredNodeType && hoveredNodeType === 'hoveredBarNode' && node,
      hoveredLineNode:
        hoveredNodeType && hoveredNodeType === 'hoveredLineNode' && node
    });
  };

  setHoveredBarNodeState = node => {
    this.setHoverState('hoveredBarNode', node);
  };

  setHoveredLineNodeState = node => {
    this.setHoverState('hoveredLineNode', node);
  };

  render() {
    const { useCanvas, hoveredBarNode, hoveredLineNode } = this.state;
    const BarSeries = useCanvas ? VerticalBarSeriesCanvas : VerticalBarSeries;

    const BarNodeHint = hoveredBarNode => {
      const tooltipDataArr = NodeHintDataMapper(
        hoveredBarNode,
        this.props.metricsData
      );
      return CustomHint(hoveredBarNode, tooltipDataArr);
    };

    const LineNodeHint = hoveredLineNode => {
      const tooltipDataArr = NodeHintDataMapper(
        hoveredLineNode,
        this.props.freshGasFlowData
      );
      return CustomHint(hoveredLineNode, tooltipDataArr);
    };

    const AgentUsageChartLegendContainer = () => {
      return (
        <div className="gtg-agent-usage-chart-legend-container">
          {this.props.metricsData && (
            <AgentUsageChartLegend
              items={this.props.metrics.filter(metric => metric !== 'Cases')}
            />
          )}
          {this.props.freshGasFlowData && (
            <AgentUsageChartLegend items={['FreshGasFlow']} label="long" />
          )}
        </div>
      );
    };

    // Get max FGF value from all years
    const maxFgfValue =
      (this.props.freshGasFlowData || []).reduce((prevYear, currYear) => {
        const maxThisYear = (currYear.data || []).reduce(
          // The Y property holds the value, compare it the previous max value
          (prev, curr) => Math.max(prev, curr.y),
          0
        );

        return Math.max(prevYear, maxThisYear);
      }, 0) ||
      // or use the default if there is no data
      MetricsConfig.maxFgfValue;

    const fgfScaleFactor =
      getMaxStackedBarValue(this.props.metricsData) / (maxFgfValue * 1.02);

    const FgfAxisTickFormatter = v => {
      return Math.round((v / fgfScaleFactor) * 10) / 10;
    };

    return (
      <div className="gtg-agent-usage-chart">
        <FlexibleWidthXYPlot
          xType="ordinal"
          stackBy="y"
          onMouseLeave={this.setHoverState}
          height={370}
          align={{ vertical: 'top', horizontal: 'auto' }}
          margin={{ left: 70, right: 70, top: 30, bottom: 80 }}
        >
          <AgentUsageChartLegendContainer />
          <VerticalGridLines />
          <HorizontalGridLines style={gridLinesStyles} />
          {this.props.metricsData &&
            this.props.metricsData
              .filter(series => series.agent !== 'Cases')
              .map(bar => (
                <BarSeries
                  key={uuidv4()}
                  stack
                  cluster={bar.year.toString()}
                  onValueMouseOver={this.setHoveredBarNodeState}
                  className={
                    (bar.previousYear && 'previous-year') ||
                    (this.props.metricsData.length > 1 && 'current-year') ||
                    ''
                  }
                  color={AgentColorByKey(bar.agent)}
                  data={bar.data}
                  barWidth={this.props.barWidth}
                />
              ))}
          {fgfScaleFactor &&
            this.props.freshGasFlowData &&
            this.props.freshGasFlowData.map(line => {
              return (
                <LineMarkSeries
                  key={uuidv4()}
                  className={
                    (line.previousYear && 'previous-year') ||
                    (this.props.freshGasFlowData.length > 1 &&
                      'current-year') ||
                    ''
                  }
                  onValueMouseOver={this.setHoveredLineNodeState}
                  style={{
                    strokeWidth: '2px'
                  }}
                  lineStyle={{ stroke: '#000' }}
                  markStyle={{ fill: '#fff', stroke: '#000' }}
                  data={scaleFgfData(line.data, fgfScaleFactor)}
                />
              );
            })}
          <XAxis
            style={{
              ...axisStyles,
              fontSize: '12px'
            }}
            {...this.props.xAxisSettings}
            tickFormat={this.TimeAxisTickFormatter}
          />
          <YAxis style={axisStyles} />
          <YAxis
            orientation="right"
            style={axisStyles}
            /* eslint-disable react/jsx-no-bind */
            tickFormat={FgfAxisTickFormatter}
            /* eslint-enable react/jsx-no-bind */
          />
          <ChartLabel
            text="Agent usage in mL"
            className="alt-y-label"
            includeMargin={false}
            xPercent={-0.07}
            yPercent={0.65}
            style={{
              transform: 'rotate(-90)',
              textAnchor: 'middle'
            }}
          />
          <ChartLabel
            text="Average FGF in L/min"
            className="alt-y-label"
            includeMargin={false}
            xPercent={1.07}
            yPercent={0.65}
            style={{
              transform: 'rotate(-90)',
              textAnchor: 'middle'
            }}
          />
          {hoveredBarNode && BarNodeHint(hoveredBarNode)}
          {hoveredLineNode && LineNodeHint(hoveredLineNode)}
        </FlexibleWidthXYPlot>
      </div>
    );
  }
}

AgentUsageChart.propTypes = {
  agents: propTypes.arrayOf(propTypes.string),
  barWidth: propTypes.number,
  xAxisSettings: propTypes.shape({}),
  freshGasFlowData: propTypes.arrayOf(
    propTypes.shape({
      year: propTypes.string,
      agent: propTypes.string,
      previousYear: propTypes.bool,
      data: propTypes.arrayOf(
        propTypes.shape({
          x: propTypes.string,
          y: propTypes.number,
          value: propTypes.oneOfType([propTypes.string, propTypes.number])
        })
      )
    })
  ),
  metricsData: propTypes.arrayOf(
    propTypes.shape({
      year: propTypes.string,
      agent: propTypes.string,
      previousYear: propTypes.bool,
      data: propTypes.arrayOf(
        propTypes.shape({
          x: propTypes.string,
          y: propTypes.number,
          value: propTypes.oneOfType([propTypes.string, propTypes.number])
        })
      )
    })
  )
};
