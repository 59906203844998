import {
  LOAD_PERSISTENT_STATE,
  LOAD_PERSISTENT_STORE,
  SAVE_PERSISTENT_STATE,
  REMOVE_PERSISTENT_STATE
} from '../actions/persistentState';

const initState = {
  persistentState: {}
};

export const persistentStateReducer = (state = initState, action) => {
  const { payload, type } = action;
  switch (type) {
    case LOAD_PERSISTENT_STORE:
      let storedItems = {};
      if (payload || !Array.isArray(payload)) {
        payload.map(key => {
          const item = localStorage.getItem(key);
          if (item) {
            storedItems = { ...storedItems, [key]: JSON.parse(item) };
          }
          return null;
        });
      }
      return {
        ...state,
        persistentState: {
          ...state.persistentState,
          ...storedItems
        }
      };
    case LOAD_PERSISTENT_STATE:
      const value = localStorage.getItem(payload);
      const parsedValue = JSON.parse(value);
      return {
        ...state,
        persistentState: {
          ...state.persistentState,
          [payload]: parsedValue
        }
      };
    case SAVE_PERSISTENT_STATE:
      const { key, value: storeValue } = payload;
      const serializedState =
        typeof storeValue === 'object'
          ? JSON.stringify(storeValue)
          : storeValue;
      localStorage.setItem(key, serializedState);
      return {
        ...state,
        persistentState: {
          ...state.persistentState,
          [key]: storeValue
        }
      };
    case REMOVE_PERSISTENT_STATE:
      localStorage.removeItem(payload);
      return {
        ...state,
        persistentState: {
          ...state.persistentState
        }
      };
    default:
      return state;
  }
};
