import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import {
  caseShape,
  CaseDetailTitle
} from '../../../containers/Case/caseHelpers';
import {
  routePaths,
  PathJoin,
  Capitalize,
  getDeviceTypeDisplayName
} from '../..';

export const Breadcrumbs = ({ intl, device, test, history, caseInfo }) => {
  const pathNameArr = history.location.pathname.split('/').slice(1);

  const pathParamLabel = useMemo(
    () => (pathParam, i) => {
      let label = pathParam;
      const pathObj = Object.values(routePaths).find(
        path => path.path === pathParam
      );

      if (pathObj) {
        label = pathObj.label;
      } else if (i > 0) {
        switch (pathNameArr[i - 1]) {
          case routePaths.DeviceOverview.path:
            // device label
            label = device
              ? [getDeviceTypeDisplayName(device.deviceType), device.serialID]
                  .filter(item => item)
                  .join(' ')
              : 'Installed Base';
            break;
          case routePaths.DeviceTestOverview.path:
            // test label
            label = test
              ? [test.name, intl.formatDate(test.start)].join(' ')
              : 'Test';
            break;
          case routePaths.DeviceCaseOverview.path:
            // case label
            label = caseInfo ? (
              <CaseDetailTitle start={caseInfo.start} end={caseInfo.end} />
            ) : (
              'Case'
            );
            break;
          default:
            label = Capitalize(label);
            break;
        }
      }
      return label;
    },
    [caseInfo, device, intl, pathNameArr, test]
  );

  const breadcrumbsArr = useMemo(() => {
    return pathNameArr.map((pathParam, i) => {
      return {
        text: pathParamLabel(pathParam, i),
        path: PathJoin(pathNameArr.slice(0, pathNameArr.indexOf(pathParam) + 1))
      };
    });
  }, [pathNameArr, pathParamLabel]);

  return (
    <nav aria-label="breadcrumb">
      <ol className="breadcrumb">
        {breadcrumbsArr &&
          breadcrumbsArr.map(route => (
            <li className="breadcrumb-item" key={'breadcrumb__' + route.path}>
              <Link to={route.path}>{route.text}</Link>
            </li>
          ))}
      </ol>
    </nav>
  );
};

const mapStateToProps = state => ({
  device: state.deviceDetails.device,
  test: state.test.test,
  caseInfo: state.case.case
});

Breadcrumbs.propTypes = {
  intl: intlShape,
  device: PropTypes.shape(),
  test: PropTypes.shape(),
  caseInfo: PropTypes.shape(caseShape),
  history: PropTypes.shape()
};

export default compose(
  connect(mapStateToProps),
  withRouter,
  injectIntl
)(Breadcrumbs);
