import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import { FormattedMessage, FormattedDate, injectIntl } from 'react-intl';
import { fetchServiceReport } from '../../redux/actions/serviceReport';
import {
  Icon,
  DataTable,
  EmptyState,
  Capitalize,
  getDeviceTypeDisplayName,
  DataTableFixedColumnHeader
} from '..';
import './ServiceOrderList.scss';
import {
  createDocument,
  SERVICE_ORDERS_LIST,
  serviceOrdersListFileName
} from '../../utils/export';

const ServiceOrderStatus = ({ status }) => {
  return (
    <div>
      {status.toLowerCase() === 'open' && (
        <Icon name="dot" color="warning" size="xs" />
      )}
      {Capitalize(status.toLowerCase())}
    </div>
  );
};

const ServiceReportDownload = ({
  fetchServiceReport,
  reportID,
  serviceOrderId
}) => {
  function handleDownload(e) {
    e.preventDefault();
    fetchServiceReport(serviceOrderId);
  }
  return (
    <div>
      {reportID && (
        // eslint-disable-next-line
        <a href="#" className="card-link" onClick={handleDownload}>
          <FormattedMessage id="common.download" defaultMessage="Download" />
          <Icon name="download" size="sm" />
        </a>
      )}
    </div>
  );
};

const subComponent = row => {
  return (
    <div className="gtg-service-order-detail">
      <dl>
        <dt>
          <FormattedMessage
            id="serviceOrders.description"
            defaultMessage="Description"
          />
        </dt>
        <dd>{row.original.description}</dd>
      </dl>
      <dl>
        <dt>
          <FormattedMessage
            id="serviceOrders.technician"
            defaultMessage="Technician"
          />
        </dt>
        <dd>{row.original.technician}</dd>
      </dl>
      <dl>
        <dt>
          <FormattedMessage
            id="serviceOrders.notificationNumber"
            defaultMessage="Notification no"
          />
        </dt>
        <dd>{row.original.notification}</dd>
      </dl>
    </div>
  );
};

const ServiceOrderList = props => {
  const {
    serviceOrders,
    fetchServiceReport,
    deviceID,
    showPagination,
    manualPaginationSettings,
    setData,
    currentPageExtra,
    filters,
    intl
  } = props;
  const columns = [
    {
      Header: <FormattedMessage id="column.status" defaultMessage="Status" />,
      accessor: 'status',
      Cell: row => <ServiceOrderStatus status={row.value} />,
      width: 85,
      headerClassName: 'pl-0 text-left',
      className: 'text-left'
    },
    {
      Header: <FormattedMessage id="column.device" defaultMessage="Device" />,
      Cell: row => getDeviceTypeDisplayName(row.value),
      accessor: 'deviceType',
      hideOnSingleDeviceView: true,
      headerClassName: 'text-left',
      className: 'text-left'
    },
    {
      Header: (
        <FormattedMessage id="column.department" defaultMessage="Department" />
      ),
      accessor: 'department.name',
      hideOnSingleDeviceView: true,
      headerClassName: 'text-left',
      className: 'text-left'
    },
    {
      Header: (
        <FormattedMessage id="column.serialNo" defaultMessage="Serial no" />
      ),
      accessor: 'serialID',
      hideOnSingleDeviceView: true,
      headerClassName: 'text-right',
      className: 'text-right'
    },
    {
      Header: (
        <FormattedMessage id="serviceOrders.type" defaultMessage="Type" />
      ),
      accessor: 'type',
      headerClassName: 'text-left',
      className: 'text-left'
    },
    {
      Header: (
        <FormattedMessage
          id="serviceOrders.orderCreated"
          defaultMessage="Order created"
        />
      ),
      accessor: 'created',
      Cell: row =>
        (row.value && <FormattedDate value={row.value} />) || (
          <FormattedMessage id="common.notAvailableAbbr" defaultMessage="N/A" />
        )
    },
    {
      Header: (
        <FormattedMessage
          id="serviceOrders.orderNumber"
          defaultMessage="Order no"
        />
      ),
      accessor: 'number',
      headerClassName: 'text-right',
      className: 'text-right'
    },
    {
      Header: (
        <FormattedMessage
          id="serviceOrders.serviceReport"
          defaultMessage="Service report"
        />
      ),
      Cell: row => (
        <ServiceReportDownload
          fetchServiceReport={fetchServiceReport}
          reportID={row.value}
          serviceOrderId={row.original.id}
        />
      ),
      accessor: 'reportID'
    }
  ];

  const serviceOrdersColumns = deviceID
    ? columns.filter(column => column.hideOnSingleDeviceView !== true)
    : columns;

  React.useEffect(() => {
    if (setData) {
      setData(serviceOrders);
    }
  }, [setData, serviceOrders]);

  const exportServiceOrdersData = useCallback(() => {
    createDocument(
      SERVICE_ORDERS_LIST,
      serviceOrders || [],
      filters,
      serviceOrdersListFileName,
      null,
      intl
    );
  }, [intl, filters, serviceOrders]);

  return (
    <React.Fragment>
      {(serviceOrders && serviceOrders.length > 0 && (
        <DataTableFixedColumnHeader
          // defaultPageSize={10}
          manualPaginationSettings={manualPaginationSettings}
          showPagination={showPagination}
          SubComponent={subComponent}
          className={classnames('gtg-service-order-list', 'mb-3')}
          data={serviceOrders}
          columns={serviceOrdersColumns}
          noCustomScrollbars
          withOverflow
          currentPageExtra={
            currentPageExtra || (
              <Link onClick={exportServiceOrdersData} to="#">
                <Icon name="download-excel" size="sm" />
              </Link>
            )
          }
        />
      )) || <EmptyState />}
    </React.Fragment>
  );
};

const mapStateToProps = state => ({
  filters: state.filters
});

const mapDispatchToProps = {
  fetchServiceReport
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(ServiceOrderList);
