import './polyfills.js';
import React from 'react';
import ReactDOM from 'react-dom';
import './scss/index.scss';
import ConnectedApp from './App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import store, { history } from './redux/store';
import { OidcProvider, loadUser, processSilentRenew } from 'redux-oidc';
import { bootstrapUserManager } from './components/Auth/userManager';
import { ConnectedRouter } from 'connected-react-router';
import { WithStartup } from './hoc';
import GA4 from './utils/ganalytics';

const oneTrustCookieBanner = async () => {
  try {
    const c = await fetch(
      `${process.env.PUBLIC_URL}/config/generalConfig.json`
    );
    const cookieBannerScriptID = await c.json();
    let oneTrustCookieDomain = document.createElement('script');
    oneTrustCookieDomain.type = 'text/javascript';
    oneTrustCookieDomain.setAttribute(
      'src',
      'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js'
    );
    oneTrustCookieDomain.setAttribute('charset', 'UTF-8');
    oneTrustCookieDomain.setAttribute(
      'data-domain-script',
      cookieBannerScriptID.onetrustCookiesConsentNotice
    );
    document.head.appendChild(oneTrustCookieDomain);

    let oneTrustCookieCall = document.createElement('script');
    oneTrustCookieCall.type = 'text/javascript';
    oneTrustCookieCall.onload = function OptanonWrapper() {};
    document.head.appendChild(oneTrustCookieCall);
  } catch (err) {
    console.error(
      `${err}. Encountered an error while reading oneTrustCookie banner script ID.`
    );
  }
};

if (window.location.pathname === '/silent_renew') {
  processSilentRenew();
} else {
  oneTrustCookieBanner();

  (async () => {
    try {
      const r = await fetch(
        `${process.env.PUBLIC_URL}/config/oidcProviders.json`
      );
      const oidc = await r.json();
      window.oidcProviders = oidc;
      bootstrapUserManager();
      window.userManager && loadUser(store, window.userManager);
    } catch (err) {
      console.error(`Encountered an error while loading the oidc providers.`);
      console.error(err);
    }

    const app = (
      <Provider store={store}>
        <WithStartup>
          {(window.userManager && (
            <OidcProvider store={store} userManager={window.userManager}>
              <ConnectedRouter history={history}>
                <ConnectedApp />
              </ConnectedRouter>
            </OidcProvider>
          )) || (
            <ConnectedRouter history={history}>
              <ConnectedApp />
            </ConnectedRouter>
          )}
        </WithStartup>
      </Provider>
    );

    history.listen((location, action) => {
      GA4.pageView(location.pathname + location.search);
    });

    ReactDOM.render(app, document.getElementById('root'));
  })();

  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: https://bit.ly/CRA-PWA
  serviceWorker.unregister();
}
