import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { CallbackComponent } from 'redux-oidc';
import { push } from 'connected-react-router';

const LoginCallback = () => {
  const dispatch = useDispatch();
  const handleLoginSuccess = useCallback(() => {
    let redirectPath = '/';
    const pathBeforeLogin = sessionStorage.getItem('pathBeforeLogin');
    if (pathBeforeLogin) {
      redirectPath = pathBeforeLogin;
      sessionStorage.removeItem('pathBeforeLogin');
    }
    dispatch(push(redirectPath));
  }, [dispatch]);

  const handleLoginError = useCallback(
    error => {
      dispatch(push('/'));
    },
    [dispatch]
  );

  return (
    <CallbackComponent
      userManager={window.userManager}
      successCallback={handleLoginSuccess}
      errorCallback={handleLoginError}
    >
      <div>Redirecting...</div>
    </CallbackComponent>
  );
};

export default LoginCallback;
