import { SET_DOCUMENTS, FETCH_DOCUMENTS } from '../actions/documents';

const initState = {
  documents: null
};

export const documentsReducer = (state = initState, action) => {
  const { payload, type } = action;

  switch (type) {
    case SET_DOCUMENTS:
      return {
        ...state,
        documents: payload
      };
    case FETCH_DOCUMENTS:
      return {
        ...state,
        documents: null
      };
    default:
      return state;
  }
};
