/**
 * Calculate running hours status
 */

function RunningHoursStatus(hours, maxHours) {
  if (hours >= maxHours) {
    return 'alert';
  } else if (maxHours - hours <= 500) {
    return 'warning';
  } else {
    return 'ok';
  }
}

export default RunningHoursStatus;
