import React from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { routePaths, ServiceOrderList, Icon } from '..';
import { FormattedMessage } from 'react-intl';

const ServiceOrderListWidget = ({ serviceOrders, setData }) => {
  return (
    <>
      <ServiceOrderList
        serviceOrders={serviceOrders}
        showPagination={false}
        setData={setData}
      />
      {serviceOrders && serviceOrders.length > 0 && (
        <Link
          data-cy="linkShowAllServiceOrders"
          className="card-link"
          to={'/' + routePaths.ServiceOrderOverview.path}
        >
          <FormattedMessage id="common.viewAll" defaultMessage="View all" />{' '}
          <Icon name="arrow-right-full" size="sm" />
        </Link>
      )}
    </>
  );
};

export default withRouter(ServiceOrderListWidget);
