import React, { useState, useCallback } from 'react';
import { formatValueUnit } from '../../components';
import './CaseVentilationModesChart.scss';
import propTypes from 'prop-types';
import { RadialChart } from 'react-vis';
import colorVariables from '../../scss/_variables.module.scss';

const ventilationModes = {
  manual: {
    label: 'Manual',
    color: colorVariables['colors-chart--color-chart-blue-8']
  },
  afgo: {
    label: 'AFGO',
    color: colorVariables['colors-chart--color-chart-blue-12']
  },
  pc: {
    label: 'PC',
    color: colorVariables['colors-chart--color-chart-yellow-1']
  },
  vc: {
    label: 'VC',
    color: colorVariables['colors-chart--color-chart-blue-5']
  },
  prvc: {
    label: 'PRVC',
    color: colorVariables['colors-chart--color-chart-green-1']
  },
  ps: {
    label: 'PS',
    color: colorVariables['colors-chart--color-chart-orange-1']
  },
  psbackup: {
    label: 'PS backup',
    color: colorVariables['colors-chart--color-chart-orange-2']
  },
  pcps: {
    label: 'SIMV (PC) + PS',
    color: colorVariables['colors-chart--color-chart-gray-4']
  },
  vcps: {
    label: 'SIMV (VC) + PS',
    color: colorVariables['colors-chart--color-chart-gray-3']
  }
};

function ventilationModesDataMapper(data, outerRadius, labelRadius) {
  return (
    (data instanceof Object &&
      Object.keys(data).map((key, i) => {
        // calculate midpoint value of current slice
        const currentTotal =
          Object.values(data)
            .slice(0, i)
            .reduce((a, b) => a + b, 0) +
          Object.values(data)[i] / 2;
        // total (represents 100% or 360deg)
        const valuesTotal = Object.values(data).reduce((a, b) => a + b, 0);
        // angle in radians from origin to midpoint of the current slice
        const rad = (currentTotal / valuesTotal) * 2 * Math.PI;
        // current slice as percentage of total
        const slicePercentage = ((data[key] / valuesTotal) * 100).toFixed(2);
        // return mapped label if it exists
        const sliceLabel = ventilationModes[key]
          ? ventilationModes[key].label
          : key;
        return {
          sliceLabel: sliceLabel,
          slicePercentage: slicePercentage,
          angle: data[key],
          color: ventilationModes[key] && ventilationModes[key].color,
          // calculate xy coordinates for label position from chart center
          labelX: Math.sin(rad) * labelRadius,
          labelY: Math.cos(rad) * labelRadius * -1,
          // calculate label arrow dimensions
          labelArrowX: Math.sin(rad) * (labelRadius - outerRadius),
          labelArrowY: Math.cos(rad) * (labelRadius - outerRadius) * -1
        };
      })) ||
    []
  );
}

const CaseVentilationModesChart = props => {
  const {
    ventilationData,
    /**
     * Ventilation chart dimensions (in px)
     */
    chartWidth = 300,
    chartHeight = 300,
    innerRadius = 55,
    outerRadius = 87.5,
    labelRadius = 105,
    arrowWidth = 1,
    // Threshold in percents for labels to be shown (greater than)
    labelPercentageThreshold = 5,
    setData
  } = props;
  const [mappedData, setMappedData] = useState([]);
  const [hoveredValue, setHoveredValue] = useState(false);

  const handleMouseOver = useCallback(
    value => {
      setHoveredValue(value);
    },
    [setHoveredValue]
  );

  const handleMouseOut = useCallback(() => {
    setHoveredValue(false);
  }, [setHoveredValue]);

  React.useEffect(() => {
    const mapped = ventilationModesDataMapper(
      ventilationData,
      outerRadius,
      labelRadius
    );
    if (setData) {
      setData(mapped);
    }
    setMappedData(mapped);
  }, [setData, setMappedData, ventilationData, outerRadius, labelRadius]);

  return (
    <div className="gtg-ventilation-modes-chart">
      <RadialChart
        innerRadius={innerRadius}
        radius={outerRadius}
        data={mappedData}
        width={chartWidth}
        height={chartHeight}
        colorType={'literal'}
        onValueMouseOver={handleMouseOver}
        onSeriesMouseOut={handleMouseOut}
      />
      <div className="gtg-ventilation-modes-labels">
        {mappedData.map(
          dataItem =>
            ((!hoveredValue &&
              dataItem.slicePercentage > labelPercentageThreshold) ||
              hoveredValue.sliceLabel === dataItem.sliceLabel) && (
              <React.Fragment key={dataItem.sliceLabel}>
                <div
                  style={{
                    transform: `translate(${dataItem.labelX}px, ${dataItem.labelY}px)`
                  }}
                >
                  <span
                    // Label arrow is basically a rectangle attached to label position,
                    // translated and given border based on labels position in coordinate system
                    // to form a visual connection between slice midpoint and label
                    className="gtg-label-arrow"
                    style={{
                      width: Math.abs(dataItem.labelArrowX),
                      height: Math.abs(dataItem.labelArrowY),
                      borderWidth: `
              ${dataItem.labelArrowY < 0 ? arrowWidth : 0}px
              ${dataItem.labelArrowX < 0 ? arrowWidth : 0}px
              ${dataItem.labelArrowY >= 0 ? arrowWidth : 0}px
              ${dataItem.labelArrowX >= 0 ? arrowWidth : 0}px
            `,
                      borderColor: dataItem.color,
                      transform: `translate(${
                        dataItem.labelArrowX > 0 ? dataItem.labelArrowX * -1 : 0
                      }px, ${
                        dataItem.labelArrowY > 0 ? dataItem.labelArrowY * -1 : 0
                      }px)`
                    }}
                  ></span>
                  <label
                    style={{
                      left: dataItem.labelX >= 0 ? 5 : 'initial',
                      right: dataItem.labelX < 0 ? 5 : 'initial',
                      textAlign: dataItem.labelX < 0 ? 'right' : 'left'
                    }}
                  >
                    <div>{dataItem.sliceLabel}</div>
                    {formatValueUnit(
                      dataItem.slicePercentage,
                      '%',
                      'ventilationModes'
                    )}
                  </label>
                </div>
              </React.Fragment>
            )
        )}
      </div>
    </div>
  );
};

CaseVentilationModesChart.propTypes = {
  ventilationData: propTypes.shape({
    manual: propTypes.number,
    afgo: propTypes.number,
    pc: propTypes.number,
    vc: propTypes.number,
    prvc: propTypes.number,
    ps: propTypes.number,
    psbackup: propTypes.number,
    pcps: propTypes.number,
    vcps: propTypes.number
  }).isRequired,
  chartWidth: propTypes.number,
  chartHeight: propTypes.number,
  innerRadius: propTypes.number,
  outerRadius: propTypes.number,
  labelRadius: propTypes.number,
  arrowWidth: propTypes.number,
  labelPercentageThreshold: propTypes.number,
  setData: propTypes.func
};

export default CaseVentilationModesChart;
