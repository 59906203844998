import {
  FETCH_BLOB,
  FETCH_BLOB_ERROR,
  FETCH_BLOB_SUCCESS,
  FETCH_BLOB_PROGRESS,
  CLEAR_BLOB
} from '../actions/blobDownload';
import { saveBlob } from '../../utils/blob/saveBlob';

export function blobDownloadReducer(
  state = {
    files: []
  },
  action
) {
  switch (action.type) {
    case FETCH_BLOB:
      return {
        ...state,
        files: [
          ...state.files,
          {
            url: action.payload.url,
            progress: 0
          }
        ]
      };
    case FETCH_BLOB_PROGRESS:
      return {
        ...state,
        files: [
          ...state.files.filter(f => f.url !== action.payload.url),
          { url: action.payload.url, progress: action.payload.progress }
        ]
      };
    case FETCH_BLOB_ERROR:
      return {
        ...state,
        files: [
          ...state.files.filter(f => f.url !== action.payload.url),
          { url: action.payload.url, progress: 100, failed: true }
        ]
      };
    case FETCH_BLOB_SUCCESS:
      saveBlob(action.payload.data, action.payload.filename);

      return {
        ...state,
        files: [
          ...state.files.filter(f => f.url !== action.payload.url),
          { url: action.payload.url, progress: 100, failed: false }
        ]
      };
    case CLEAR_BLOB:
      return {
        ...state,
        files: [...state.files.filter(f => f.url !== action.payload.url)]
      };
    default:
      return state;
  }
}
