// Add column headers and define column keys and widths
export const deviceUsageStatisticsColumns = [
  { header: 'Hospital', key: 'hospital', width: 40 },
  { header: 'Department', key: 'departmentName', width: 40 },
  { header: 'Location', key: 'location', width: 40 },
  { header: 'Device', key: 'deviceType', width: 40 },
  { header: 'Serial no', key: 'serial', width: 20 },
  { header: 'Customer device no', key: 'customerID', width: 20 },
  { header: 'From', key: 'from', width: 20 },
  { header: 'To', key: 'to', width: 20 },
  { header: 'Cases', key: 'cases', width: 10 },
  { header: 'SEV [mL]', key: 'sev', width: 10 },
  { header: 'DES [mL]', key: 'des', width: 10 },
  { header: 'ISO [mL]', key: 'iso', width: 10 },
  { header: 'N2O [L]', key: 'n2o', width: 10 },
  { header: 'Average FGF [L/min]', key: 'fgf', width: 10 },
  { header: 'Department filter selection', key: 'department', width: 40 },
  { header: 'Device filter selection', key: 'device', width: 40 }
];

// Define filename
export const deviceUsageStatisticsFileName = 'Usage_statistics_per_device.xlsx';
