import { API_ERROR, API_SUCCESS, apiRequest } from '../actions/api';
import { API_URLS } from './urls';
import {
  FETCH_SOFTWARE_VERSIONS,
  setSoftwareVersions,
  entity
} from '../actions/softwareVersions';

const URL = API_URLS.AdminSoftwareVersions;

export const softwareVersionsMdl = ({ dispatch }) => next => action => {
  next(action);

  const { type } = action;

  switch (type) {
    case FETCH_SOFTWARE_VERSIONS:
      dispatch(
        apiRequest({
          method: 'GET',
          url: URL.url,
          requestSignature: 'softwareVersions',
          entity: entity
        })
      );
      break;
    case `${entity} GET ${API_SUCCESS}`:
      dispatch(
        setSoftwareVersions([
          ...action.payload.data.devices,
          ...action.payload.data['connectivity-nodes']
        ])
      );
      break;
    case `${entity} GET ${API_ERROR}`:
      dispatch(setSoftwareVersions([]));
      break;
    // no default
  }
};
