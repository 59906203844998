import { SET_LOCALE } from '../actions/ui';
import { getNavigatorLanguage } from '../../utils/browser';

const initialStateUI = {
  locale: getNavigatorLanguage(navigator)
};

export function uiReducer(state = initialStateUI, action) {
  switch (action.type) {
    case SET_LOCALE:
      return { ...state, locale: action.payload };

    default:
      return state;
  }
}
