import {
  ACCOUNT_SELECTED,
  ACCOUNT_INIT,
  ACCOUNT_FETCH_PERSIST,
  accountInit,
  accountSetSelected,
  fetchPersistAccount
} from '../actions/account';
import { fetchPWAvailableDeviceTypes } from '../actions/accessInfo';
import get from 'lodash.get';
import { savePersistentState } from '../actions/persistentState';
import { API_SUCCESS, apiRequest } from '../actions/api';
import { API_URLS } from './urls';
import { transformAccounts } from './../../utils/transform';

const ACCOUNT_URL = id => `${API_URLS.Accounts.url}/${id}`;

export const accountMdl = ({ dispatch, getState }) => next => action => {
  const { type, payload } = action;
  switch (type) {
    case ACCOUNT_SELECTED:
      const selectedID = get(payload, 'account.id', null);
      const fetchNeeded =
        get(payload, 'account.label', null) === null ||
        get(payload, 'account.description', null) === null;

      // Calling account selected with the same id multiple times doesn't cause a re-init
      if (get(getState(), 'account.current.id', null) !== selectedID) {
        next(action);

        // Fetch account is called when account is selected using just an ID, and not the whole
        // entity needed to display it in the filters. After the fetch, the account is persisted
        // automatically. If the whole entity is passed in, we can persist the account right immediately.
        if (fetchNeeded) {
          dispatch(fetchPersistAccount(selectedID));
        } else {
          dispatch(
            savePersistentState('deviceFilterAccount', [payload.account])
          );
        }

        dispatch(accountSetSelected(selectedID));
        dispatch(accountInit(selectedID));
      }
      break;
    case ACCOUNT_INIT:
      next(action);
      dispatch(fetchPWAvailableDeviceTypes(payload.id));
      break;
    case ACCOUNT_FETCH_PERSIST:
      next(action);

      if (payload.id !== null) {
        dispatch(
          apiRequest({
            method: 'GET',
            url: ACCOUNT_URL(payload.id),
            requestSignature: 'account-fetch-persist',
            entity: ACCOUNT_FETCH_PERSIST
          })
        );
      }
      break;
    case `${ACCOUNT_FETCH_PERSIST} GET ${API_SUCCESS}`:
      next(action);

      const [account] = transformAccounts([action.payload.data]);
      dispatch(savePersistentState('deviceFilterAccount', [account]));

      break;
    default:
      next(action);
      break;
  }
};
