import React from 'react';
import { createContext } from 'react';
import { createContextualCan } from '@casl/react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import ruleBuilder from './rule-builder';

// Ability context is a context holding the current ability for the user
const AbilityContext = createContext();

// Can is bound to the current AbilityContext. This means that the user of
// the Can component does not need to provide the ability as a prop to the
// component, as it is added automatically.
const Can = createContextualCan(AbilityContext.Consumer);

// withAbilityContext wraps a component with ability logic. The component is
// dependent on the parsed keycloak token which should contain the role
// and a product_certifications array.
const WithAbilityContext = WrappedComponent => {
  const Wrapper = props => {
    // Rule builder will return an ability that can be used for checking access
    // to a subject based on the users role and product certifications.
    const ability = ruleBuilder(
      (props.userinfo && props.userinfo.portal_role) || null,
      (props.userinfo && props.userinfo.product_certifications) || null
    );

    return (
      <AbilityContext.Provider value={ability}>
        <WrappedComponent {...props} />
      </AbilityContext.Provider>
    );
  };

  const mapStateToProps = state => ({
    userinfo: state.authorization.userinfo
  });

  return compose(connect(mapStateToProps, null))(Wrapper);
};

export { WithAbilityContext, Can, AbilityContext };
