import React from 'react';
import get from 'lodash.get';
import { FormattedDate, FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import { WithLoadingSpinner } from '../../hoc';
import {
  Card,
  CardSection,
  List,
  ListItem,
  EmptyState,
  PreventiveMaintenanceStatusIcon,
  CassetteMembraneRemainingCapacity,
  RunningHoursStatus,
  deviceFamilies,
  getDeviceFamilyName,
  isFunction,
  PreventiveMaintenanceDateStatus,
  formatValueUnit
} from '..';
import './DevicePreventiveMaintenanceListWidget.scss';
import { isRotaflowFamily } from '../utils/Device/deviceHelper';
import { preventiveMaintenanceShape } from '../../utils/proptypes/preventiveMaintenanceShape';

const WidgetTitle = (
  <FormattedMessage
    id="common.preventiveMaintenance"
    defaultMessage="Preventive maintenance"
  />
);

export const getBatteriesData = preventiveMaintenance =>
  (get(preventiveMaintenance, 'batteries') || []).map(item => ({
    id: 'preventiveMaintenance.battery',
    defaultMessage: 'Battery serial no',
    messageAppendix: get(item, 'serialNumber'),
    key: `battery-${get(item, 'serialNumber')}`,
    last: isRotaflowFamily(
      getDeviceFamilyName(get(preventiveMaintenance, 'device.type'))
    )
      ? get(item, 'manufacturingDate')
      : () => {},
    next: get(item, 'nextDate'),
    status: get(item, 'status'),
    hideForDeviceFamily: [deviceFamilies.flow.name]
  }));

export const NotAvailable = () => (
  <FormattedMessage id="common.notAvailableAbbr" defaultMessage="N/A" />
);

export const PmDateDisplay = ({ date }) =>
  (date && <FormattedDate value={date} />) || <NotAvailable />;

export const PmStatusDisplay = ({ status }) =>
  (status && <PreventiveMaintenanceStatusIcon status={status} />) || (
    <NotAvailable />
  );

export const ListItemWrapper = ({
  id,
  defaultMessage,
  messageAppendix,
  last,
  next,
  status
}) => {
  return (
    <ListItem className="p-1" minListItemHeight={50}>
      <section className="gtg-device-pm-row">
        <span className="gtg-device-pm-name">
          <strong>
            <FormattedMessage id={id} defaultMessage={defaultMessage} />
            {messageAppendix && ` ${messageAppendix}`}
          </strong>
        </span>
        <span className="gtg-device-pm-performed">
          {isFunction(last) ? last() : <PmDateDisplay date={last} />}
        </span>
        <span className="gtg-device-pm-due">
          {isFunction(next) ? next() : <PmDateDisplay date={next} />}
        </span>
        <span className="gtg-device-pm-status">
          {isFunction(status) ? status() : <PmStatusDisplay status={status} />}
        </span>
      </section>
    </ListItem>
  );
};

const DevicePreventiveMaintenanceListWidget = ({ preventiveMaintenance }) => {
  const listItems = [
    {
      id: 'preventiveMaintenance.PM',
      defaultMessage: 'PM',
      last: get(preventiveMaintenance, 'last'),
      next: get(preventiveMaintenance, 'next'),
      status: PreventiveMaintenanceDateStatus(
        get(preventiveMaintenance, 'next')
      )
    },
    {
      id: 'preventiveMaintenance.memoryBackupBatteries',
      defaultMessage: 'Memory backup batteries',
      last: get(preventiveMaintenance, 'battery.memoryBackup.last'),
      next: get(preventiveMaintenance, 'battery.memoryBackup.next'),
      status: get(preventiveMaintenance, 'battery.memoryBackup.status'),
      hideForDeviceFamily: [
        deviceFamilies.servo.name,
        deviceFamilies.rotaflow.name
      ]
    },
    {
      id: 'preventiveMaintenance.powerBackupBattery',
      defaultMessage: 'Power backup battery',
      last: get(preventiveMaintenance, 'battery.powerBackup.last'),
      next: get(preventiveMaintenance, 'battery.powerBackup.next'),
      status: get(preventiveMaintenance, 'battery.powerBackup.status'),
      hideForDeviceFamily: [
        deviceFamilies.servo.name,
        deviceFamilies.rotaflow.name
      ]
    },
    {
      id: 'preventiveMaintenance.cassetteMembraneRemainingCapacity',
      defaultMessage: 'Cassette membrane remaining capacity',
      last: get(preventiveMaintenance, 'cassetteMembrane.last'),
      next: () => {
        const strokes = get(preventiveMaintenance, 'cassetteMembrane.strokes');

        return (
          (strokes && CassetteMembraneRemainingCapacity(strokes)) || (
            <NotAvailable />
          )
        );
      },
      status: get(preventiveMaintenance, 'cassetteMembrane.status'),
      hideForDeviceFamily: [deviceFamilies.rotaflow.name]
    },
    {
      id: 'preventiveMaintenance.runningHoursSincePM',
      defaultMessage: 'Running hours since PM',
      last: () => {},
      next: () => {
        const hours = get(preventiveMaintenance, 'runningHoursSince');
        const maxHours = get(preventiveMaintenance, 'maxRunningHours');

        return (
          (hours &&
            maxHours &&
            formatValueUnit(hours + ' / ' + maxHours, 'h')) || <NotAvailable />
        );
      },
      status: () => {
        const hours = get(preventiveMaintenance, 'runningHoursSince');
        const maxHours = get(preventiveMaintenance, 'maxRunningHours');

        return (
          <PmStatusDisplay
            status={
              (hours && maxHours && RunningHoursStatus(hours, maxHours)) || null
            }
          />
        );
      },
      hideForDeviceFamily: [deviceFamilies.rotaflow.name]
    }
  ];

  listItems.push(...getBatteriesData(preventiveMaintenance));

  return (
    <Card dataCy="cardPreventiveMaintenance" className="gtg-device-pm-widget">
      <CardSection title={WidgetTitle}>
        <WithLoadingSpinner when="devicePreventiveMaintenance">
          {(preventiveMaintenance && (
            <List dataCy="listPreventiveMaintenance" minListItemHeight={50}>
              <ListItem className="d-none"></ListItem>
              <ListItem className="p-1 gtg-device-pm-headers">
                <section className="gtg-device-pm-row">
                  <span className="gtg-device-pm-name"></span>
                  <span className="gtg-device-pm-performed">
                    <FormattedMessage
                      id="preventiveMaintenance.performed"
                      defaultMessage="Performed"
                    />
                  </span>
                  <span className="gtg-device-pm-due">
                    <FormattedMessage
                      id="preventiveMaintenance.due"
                      defaultMessage="Due"
                    />
                  </span>
                  <span className="gtg-device-pm-status">
                    <FormattedMessage
                      id="column.status"
                      defaultMessage="Status"
                    />
                  </span>
                </section>
              </ListItem>
              {listItems
                .filter(item =>
                  item.hideForDeviceFamily
                    ? !item.hideForDeviceFamily.includes(
                        getDeviceFamilyName(preventiveMaintenance.device.type)
                      )
                    : true
                )
                .map(item => (
                  <ListItemWrapper key={item.key || item.id} {...item} />
                ))}
            </List>
          )) || <EmptyState />}
        </WithLoadingSpinner>
      </CardSection>
    </Card>
  );
};

DevicePreventiveMaintenanceListWidget.propTypes = {
  preventiveMaintenance: preventiveMaintenanceShape
};

export default DevicePreventiveMaintenanceListWidget;
