import { FETCH_METRICS, setMetrics, entity } from '../actions/metrics';
import { API_SUCCESS, apiRequest } from '../actions/api';
import { Metrics, MetricsTimeParams } from '../../components/';
import { API_URLS } from './urls';
import {
  transformResponse,
  transformResponseForY2yData
} from './../../utils/transform';

const URL = API_URLS.Metrics;

export const metricsMdl = ({ dispatch }) => next => action => {
  next(action);

  const { type } = action;

  switch (type) {
    case FETCH_METRICS:
      setMetrics([]);
      const { deviceIds, timeSpan, count } = action.payload;
      const deviceIdsParams =
        (deviceIds &&
          deviceIds.length > 0 &&
          '&device_ids=' + deviceIds.join(',')) ||
        '';
      const metrics =
        '&metrics=' +
        [
          ...Metrics.filter(agent => agent.type === 'agent').map(
            agent => agent.key
          ),
          'FreshGasFlow',
          'Cases'
        ].join(',');

      dispatch(
        apiRequest({
          method: 'GET',
          url:
            URL.url +
            MetricsTimeParams('metrics', timeSpan, count) +
            metrics +
            deviceIdsParams,
          requestSignature: 'metrics',
          entity: entity,
          filter: deviceIdsParams.length > 0 ? false : URL.filter
        })
      );

      break;
    case `${entity} GET ${API_SUCCESS}`:
      dispatch(
        setMetrics(
          action.payload.data
            ? {
                payloadData: transformResponse(action.payload.data, 'metrics'),
                y2yData: transformResponseForY2yData(
                  action.payload.data,
                  'metrics'
                )
              }
            : []
        )
      );
      break;
    // no default
  }
};
