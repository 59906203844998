import React, { useCallback, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import { WithLoadingSpinner, WithSubscriptionInvitation } from '../../hoc';
import moment from 'moment';
import {
  CardSection,
  TrendsList,
  AgentUsageChart,
  BarWidth,
  EmptyState,
  Metrics,
  TimeFormat,
  routePaths,
  PathJoin,
  Icon,
  DetailedUsageStatisticsList,
  DetailedUsageStatisticsPerDevice,
  AxisSettings,
  DateScroller,
  allDateToggles,
  CarbonFootprint,
  AdoptionMetrics,
  UsageStatisticsVentilationModes,
  Averages,
  WEEKEND_PREFIX,
  getStartAndEndData,
  Capitalize
} from '..';
import { currencyShape } from '../Cases/AgentCostEntryModal/AgentCostEntryModal';
import { savePersistentState } from '../../redux/actions/persistentState';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import propTypes from 'prop-types';

import './UsageStatistics.scss';
import { fetchMetrics } from '../../redux/actions/metrics';
import { fetchMetricsDeviceUsage } from '../../redux/actions/metricsDetailedPerDevice';
import { fetchMetricsDetailed } from '../../redux/actions/metricsDetailed';
import { fetchMetricsUsage } from '../../redux/actions/metricsUsage';
import { fetchTrends } from '../../redux/actions/trends';
import { fetchCosts } from '../../redux/actions/costs';
import { fetchDevicesOverview } from '../../redux/actions/devicesOverview';

import { MetricsConfig } from './Metrics';
import { Toggles } from '..';
import {
  AGENT_USAGE,
  TOTAL_GAS_CONSUMPTION,
  DEVICE_AGENT_USAGE,
  DEVICE_TOTAL_GAS_CONSUMPTION,
  DEVICE_USAGE_STATISTICS,
  DETAILED_USAGE_STATISTICS,
  DETAILED_USAGE_STATISTICS_PER_DEVICE,
  agentUsageFileName,
  totalGasConsumptionFileName,
  deviceAgentUsageFileName,
  deviceTotalGasConsumptionFileName,
  deviceUsageStatisticsFileName,
  detailedUsageStatisticsFileName,
  detailedUsageStatisticsPerDeviceFileName,
  createDocument
} from '../../utils/export';
import { BadgeCountComponent, subscriptionTypes } from '../../components';
import get from 'lodash.get';
import { IsY2Y } from '../utils/IsY2Y/IsY2Y';
import { userInfoShape } from '../../utils/proptypes/userInfoShape';

const UsageStatisticsWidget = ({
  fetchMetrics,
  fetchMetricsDeviceUsage,
  fetchMetricsDetailed,
  fetchTrends,
  fetchMetricsUsage,
  fetchCosts,
  savePersistentState,
  persistentState,
  trends,
  metrics,
  metricsDetailed,
  metricsUsage,
  metricDetailedUsage,
  costs,
  device,
  withViewDetails,
  withPerDeviceMetrics,
  intl,
  filters,
  fetchDevicesOverview,
  subscriptions,
  config,
  shouldShowBadges,
  userinfo,
  metricsY2y
}) => {
  let flowSubscriptions = subscriptions && subscriptions.flow;

  const shouldBadgeBeShown =
    typeof shouldShowBadges === 'undefined' ? true : shouldShowBadges;

  const [filteredVentilationData, setFilteredVentilationData] = useState([]);
  const [filteredAveragesData, setFilteredAveragesData] = useState([]);
  const [metricsTimeSpan, setMetricsTimeSpan] = useState(
    persistentState['usageStatisticsTimeSpan'] || MetricsConfig.initialTimeSpan
  );
  const [timeSpanCount, setTimeSpanCount] = useState(
    persistentState['usageStatisticsTimeSpanCount'] || 0
  );
  const fetchData = useCallback(
    (timeSpan, count) => {
      savePersistentState('usageStatisticsTimeSpan', timeSpan);
      savePersistentState('usageStatisticsTimeSpanCount', count);
      setMetricsTimeSpan(timeSpan);
      setTimeSpanCount(count);

      // If we are on device view, no details are needed
      if (device && device.id) {
        fetchMetrics(timeSpan, count, [device.id]);
        fetchTrends(timeSpan, count, [device.id]);
      } else {
        fetchMetrics(timeSpan, count);
        fetchTrends(timeSpan, count);

        if (withPerDeviceMetrics) {
          fetchMetricsDetailed(timeSpan, count);
          fetchMetricsUsage(timeSpan, count);
          fetchCosts();
          fetchMetricsDeviceUsage(timeSpan, count);
        }
      }
      fetchDevicesOverview();
    },
    [
      savePersistentState,
      device,
      fetchMetrics,
      fetchTrends,
      withPerDeviceMetrics,
      fetchMetricsDetailed,
      fetchMetricsUsage,
      fetchCosts,
      fetchDevicesOverview,
      fetchMetricsDeviceUsage
    ]
  );

  // Trigger data fetch on initial load and filter updates
  useEffect(() => {
    fetchData(metricsTimeSpan, timeSpanCount);
  }, [fetchData, filters, metricsTimeSpan, timeSpanCount]);

  const timeFormattedMetricsData =
    (metrics &&
      metrics.map(metric => {
        metric.data = metric.data.map(dataItem => {
          dataItem.x = '';

          if (
            ['week', 'month'].includes(metricsTimeSpan) &&
            [6, 7].includes(moment(dataItem.xTimeStamp).isoWeekday())
          ) {
            // So that it can be styled differently
            dataItem.x = WEEKEND_PREFIX;
          }

          dataItem.x += intl.formatDate(
            moment(dataItem.xTimeStamp).toDate(),
            TimeFormat(metricsTimeSpan)
          );
          return dataItem;
        });
        return metric;
      })) ||
    [];

  const timeFormattedMetricsY2yData =
    (metricsY2y &&
      metricsY2y.map(metric => {
        metric.data = metric.data.map(dataItem => {
          dataItem.x = '';

          if (
            ['week', 'month'].includes(metricsTimeSpan) &&
            [6, 7].includes(moment(dataItem.xTimeStamp).isoWeekday())
          ) {
            // So that it can be styled differently
            dataItem.x = WEEKEND_PREFIX;
          }

          dataItem.x += intl.formatDate(
            moment(dataItem.xTimeStamp).toDate(),
            TimeFormat(metricsTimeSpan)
          );
          return dataItem;
        });
        return metric;
      })) ||
    [];

  const refetchData = useCallback(toggle => fetchData(toggle.value, 0), [
    fetchData
  ]);

  const TimespanToggles = useCallback(
    () => (
      <Toggles
        data-cy="timespan-toggles"
        toggles={allDateToggles}
        selectedToggle={metricsTimeSpan}
        onSelect={refetchData}
      />
    ),
    [metricsTimeSpan, refetchData]
  );

  const goBack = useCallback(() => {
    fetchData(metricsTimeSpan, timeSpanCount + 1);
  }, [fetchData, metricsTimeSpan, timeSpanCount]);
  const goForward = useCallback(() => {
    fetchData(metricsTimeSpan, timeSpanCount - 1);
  }, [fetchData, metricsTimeSpan, timeSpanCount]);

  const DateScrollerComponent = useCallback(
    () => (
      <DateScroller
        timespan={metricsTimeSpan}
        count={timeSpanCount}
        onBackClick={goBack}
        onForwardClick={goForward}
      />
    ),
    [metricsTimeSpan, timeSpanCount, goBack, goForward]
  );
  const BadgeComponent = flowSubscriptions => {
    if (!flowSubscriptions || flowSubscriptions.length === 0) {
      return <></>;
    }
    const badgeData = flowSubscriptions.sort((a, b) => {
      return a.priority - b.priority;
    });

    return badgeData.map(function(badgeItem) {
      return badgeItem.level === subscriptionTypes.standard ? null : (
        <BadgeCountComponent
          key={`${badgeItem.level}-${badgeItem.count}`}
          subscriptionType={badgeItem.level}
          deviceCount={badgeItem.count}
        />
      );
    });
  };

  const BadgeComponentForDetailedUsageStatisticsCard = flowSubscriptions => {
    return (
      flowSubscriptions &&
      flowSubscriptions.map(function(badgeItem) {
        return badgeItem.level === subscriptionTypes.premium ? (
          <BadgeCountComponent
            key={`${badgeItem.level}-${badgeItem.count}`}
            subscriptionType={badgeItem.level}
            deviceCount={badgeItem.count}
          />
        ) : null;
      })
    );
  };

  const filteredMetricsDetailed = metricsDetailed?.filter(
    device => device.metrics
  ); // Only show devices with metrics
  const { start, end } = getStartAndEndData(
    undefined,
    metricsTimeSpan,
    timeSpanCount
  );
  const exportDeviceUsageStatistics = useCallback(() => {
    createDocument(
      DEVICE_USAGE_STATISTICS,
      filteredMetricsDetailed || [],
      filters,
      deviceUsageStatisticsFileName,
      { from: intl.formatDate(start), to: intl.formatDate(end) }
    );
  }, [filteredMetricsDetailed, filters, intl, start, end]);

  const exportDetailedUsageStatistics = useCallback(() => {
    createDocument(
      DETAILED_USAGE_STATISTICS,
      metricsUsage
        ? {
            ...metricsUsage,
            ventilationModes: filteredVentilationData,
            averages: filteredAveragesData,
            carbonFootprint: IsY2Y(metricsTimeSpan)
              ? `N/A`
              : metricsUsage.carbonFootprint &&
                `${parseFloat(
                  metricsUsage.carbonFootprint.toFixed(2)
                ).toString()} ${intl.formatMessage({
                  id: `usageStatistics.tonsPer${Capitalize(metricsTimeSpan)}`,
                  defaultMessage: `tons / ${metricsTimeSpan}`
                })}`
          }
        : [],
      filters,
      detailedUsageStatisticsFileName,
      { from: intl.formatDate(start), to: intl.formatDate(end) }
    );
  }, [
    metricsUsage,
    filteredVentilationData,
    filteredAveragesData,
    intl,
    metricsTimeSpan,
    filters,
    start,
    end
  ]);

  const exportDetailedUsageStatisticsPerDevice = useCallback(() => {
    createDocument(
      DETAILED_USAGE_STATISTICS_PER_DEVICE,
      metricDetailedUsage ? metricDetailedUsage.items : [],
      filters,
      detailedUsageStatisticsPerDeviceFileName,
      { from: intl.formatDate(start), to: intl.formatDate(end) }
    );
  }, [filters, intl, start, end, metricDetailedUsage]);

  const ExcelExport = useCallback(
    () => (
      <div className={classnames('gtg-usage-statistics-per-device-right')}>
        <Link
          onClick={exportDeviceUsageStatistics}
          to="#"
          className="float-right"
        >
          <Icon name="download-excel" size="sm" />
        </Link>
      </div>
    ),
    [exportDeviceUsageStatistics]
  );

  const UsageStatisticsCardRight = useCallback(
    () => (
      <div className="gtg-usage-statistics-card-right">
        <Link
          onClick={exportDetailedUsageStatistics}
          to="#"
          className="float-right"
        >
          <Icon name="download-excel" size="sm" />
        </Link>
      </div>
    ),
    [exportDetailedUsageStatistics]
  );

  const UsageStatisticsCardRightWithMargin = useCallback(() => {
    return (
      <div className="gtg-usage-statistics-card-right-with-margin">
        <Link
          onClick={exportDetailedUsageStatisticsPerDevice}
          to="#"
          className="float-right"
        >
          <Icon name="download-excel" size="sm" />
        </Link>
      </div>
    );
  }, [exportDetailedUsageStatisticsPerDevice]);

  let usageStatisticsWidget = (
    <CardSection
      dataCy="cardUsageStatisticsOverview"
      sectionClass="gtg-usageStatOverview-section-white-bg"
      title={
        <div id="gtg-usage-statistics-card-title">
          <FormattedMessage
            id="usageStatistics.usageStatisticsOverview"
            defaultMessage="Usage statistics overview"
          />
          {get(config, 'enableSubscriptions', false) &&
          shouldBadgeBeShown &&
          !userinfo.isInternalUser ? (
            <div className="gtg-usage-statistics-card-badges">
              {BadgeComponent(flowSubscriptions)}
            </div>
          ) : null}
        </div>
      }
      CardHeaderRight={TimespanToggles}
      CardHeaderCenter={DateScrollerComponent}
      customClass="gtg-usage-statistics-overview-section-header"
    >
      <UsageStatisticsCardContent
        timeSpan={metricsTimeSpan}
        metricsData={timeFormattedMetricsData}
        trendsData={trends}
        withViewDetails={withViewDetails}
        filters={filters}
        timeSpanCount={timeSpanCount}
        intl={intl}
        device={device}
        metricsY2yData={timeFormattedMetricsY2yData}
      />
    </CardSection>
  );

  usageStatisticsWidget = (
    <WithSubscriptionInvitation
      accessWidget={'usage statistics'}
      trigger={[subscriptionTypes.advanced, subscriptionTypes.premium]}
      subscriptions={
        device
          ? [
              {
                count: 1,
                level: get(device, 'subscription', 'standard'),
                priority: 1
              }
            ]
          : flowSubscriptions
      }
    >
      {usageStatisticsWidget}
    </WithSubscriptionInvitation>
  );

  let detailedUsageStatisticsCard = (
    <>
      <CardSection
        title={
          <div className="gtg-flow-usage-statistics-detailed-title">
            <FormattedMessage
              id="usageStatistics.detailedUsageStatistics"
              defaultMessage="Detailed usage statistics"
            />
            {get(config, 'enableSubscriptions', false) &&
            !userinfo.isInternalUser ? (
              <div className="gtg-usage-statistics-card-badges">
                {BadgeComponentForDetailedUsageStatisticsCard(
                  flowSubscriptions
                )}
              </div>
            ) : null}
          </div>
        }
        CardHeaderRight={UsageStatisticsCardRight}
      >
        <div className="row">
          <Averages
            metricsUsage={metricsUsage}
            costs={costs}
            setData={setFilteredAveragesData}
            mappedAverages={filteredAveragesData}
          />
          <CarbonFootprint
            metricsUsage={metricsUsage}
            timeSpan={metricsTimeSpan}
          />
          <AdoptionMetrics metricsUsage={metricsUsage} />
          <UsageStatisticsVentilationModes
            metricsUsage={metricsUsage}
            setData={setFilteredVentilationData}
          />
        </div>
      </CardSection>
      {get(config, 'enableSubscriptions', false) && (
        <CardSection
          title={
            <div className="gtg-flow-usage-statistics-detailed-title">
              <FormattedMessage
                id="usageStatistics.detailedUsageStatisticsPerDevice"
                defaultMessage="Detailed usage statistics per device"
              />
              {get(config, 'enableSubscriptions', false) &&
              !userinfo.isInternalUser ? (
                <div className="gtg-usage-statistics-card-badges">
                  {BadgeComponentForDetailedUsageStatisticsCard(
                    flowSubscriptions
                  )}
                </div>
              ) : null}
            </div>
          }
          ignoreClickTarget={'icon-download-excel'}
          CardHeaderRight={UsageStatisticsCardRightWithMargin}
          collapsible
        >
          {metricDetailedUsage && (
            <DetailedUsageStatisticsPerDevice
              data={metricDetailedUsage.items}
            />
          )}
        </CardSection>
      )}
    </>
  );

  detailedUsageStatisticsCard = withPerDeviceMetrics && metricsUsage && (
    <WithSubscriptionInvitation
      subscriptions={flowSubscriptions}
      accessWidget={'detailed usage statistics'}
      trigger={[subscriptionTypes.premium]}
    >
      {detailedUsageStatisticsCard}
    </WithSubscriptionInvitation>
  );

  let cardContent = (
    <>
      {usageStatisticsWidget}
      {metricsTimeSpan !== 'y2y' &&
        withPerDeviceMetrics &&
        filteredMetricsDetailed && (
          <CardSection
            dataCy="sectionUsageStatisticsPerDevice"
            title={
              <div id="gtg-usage-statistics-card-title">
                <FormattedMessage
                  id="usageStatistics.usageStatisticsPerDevice"
                  defaultMessage="Usage statistics per device"
                />
                {get(config, 'enableSubscriptions', false) &&
                shouldBadgeBeShown &&
                !userinfo.isInternalUser ? (
                  <div className="gtg-usage-statistics-card-badges">
                    {BadgeComponent(flowSubscriptions)}
                  </div>
                ) : null}
              </div>
            }
            ignoreClickTarget={'icon-download-excel'}
            CardHeaderRight={ExcelExport}
            collapsible
          >
            <DetailedUsageStatisticsList data={filteredMetricsDetailed} />
          </CardSection>
        )}
      {metricsTimeSpan !== 'y2y' ? detailedUsageStatisticsCard : null}
    </>
  );

  cardContent = (
    <WithSubscriptionInvitation
      accessWidget={'usage statistics'}
      trigger={[subscriptionTypes.advanced, subscriptionTypes.premium]}
      subscriptions={
        device
          ? [
              {
                count: 1,
                level: get(device, 'subscription', 'standard'),
                priority: 1
              }
            ]
          : flowSubscriptions
      }
    >
      {cardContent}
    </WithSubscriptionInvitation>
  );

  return <>{cardContent}</>;
};

const chartMetrics = Metrics.filter(metric =>
  ['agent', 'cases'].includes(metric.type)
).map(metric => metric.key);

const UsageStatisticsCardContent = props => {
  const {
    timeSpan,
    metricsData,
    trendsData,
    withViewDetails,
    filters,
    timeSpanCount,
    intl,
    device,
    metricsY2yData
  } = props;
  const { start, end } = getStartAndEndData(undefined, timeSpan, timeSpanCount);
  const exportUsageData = useCallback(() => {
    createDocument(
      AGENT_USAGE,
      metricsY2yData || [],
      filters,
      agentUsageFileName
    );
  }, [filters, metricsY2yData]);

  const exportDeviceUsageData = useCallback(() => {
    createDocument(
      DEVICE_AGENT_USAGE,
      metricsData || [],
      filters,
      deviceAgentUsageFileName,
      device
    );
  }, [device, filters, metricsData]);

  const exportConsumptionData = useCallback(() => {
    createDocument(
      TOTAL_GAS_CONSUMPTION,
      trendsData || [],
      filters,
      totalGasConsumptionFileName,
      { from: intl.formatDate(start), to: intl.formatDate(end) }
    );
  }, [end, filters, intl, start, trendsData]);

  const exportDeviceConsumptionData = useCallback(() => {
    createDocument(
      DEVICE_TOTAL_GAS_CONSUMPTION,
      trendsData || [],
      filters,
      deviceTotalGasConsumptionFileName,
      { ...device, from: intl.formatDate(start), to: intl.formatDate(end) }
    );
  }, [device, end, filters, intl, start, trendsData]);

  return (
    <div className="row">
      <div className="col gtg-trends-list-container">
        <WithLoadingSpinner when="trends">
          {(trendsData && (
            <>
              <TrendsList
                title={
                  <div className="mb-2 gtg-total-gas-consumption-header">
                    <FormattedMessage
                      id="usageStatistics.totalGasConsumption"
                      defaultMessage="Total gas consumption"
                    />
                    <Link
                      className="float-right"
                      onClick={
                        device
                          ? exportDeviceConsumptionData
                          : exportConsumptionData
                      }
                      to="#"
                    >
                      <Icon name="download-excel" size="sm" />
                    </Link>
                  </div>
                }
                data={trendsData}
              />
              {withViewDetails && (
                <Link
                  data-cy="linkShowStatisticsOverviewDetails"
                  className="card-link mt-auto pt-3"
                  to={PathJoin([routePaths.UsageStatisticsOverview.path])}
                >
                  <FormattedMessage
                    id="common.viewDetails"
                    defaultMessage="View details"
                  />
                  <Icon name="arrow-right-full" size="sm" />
                </Link>
              )}
            </>
          )) || <EmptyState />}
        </WithLoadingSpinner>
      </div>
      <div className="col gtg-agent-usage-chart-container">
        <WithLoadingSpinner when="metrics">
          <div className="mb-2">
            <FormattedMessage
              id="usageStatistics.agentUsageOverTime"
              defaultMessage="Agent usage over time"
            />
            <Link
              className="float-right"
              onClick={device ? exportDeviceUsageData : exportUsageData}
              to="#"
            >
              <Icon name="download-excel" size="sm" />
            </Link>
          </div>
          {(metricsData && metricsData.length > 0 && (
            <AgentUsageChart
              metricsData={metricsData.filter(metric =>
                chartMetrics.includes(metric.agent)
              )}
              freshGasFlowData={metricsData.filter(
                metric => metric.agent === 'FreshGasFlow'
              )}
              barWidth={BarWidth(timeSpan)}
              metrics={chartMetrics}
              xAxisSettings={AxisSettings(timeSpan)}
            />
          )) || <EmptyState />}
        </WithLoadingSpinner>
      </div>
    </div>
  );
};

UsageStatisticsWidget.propTypes = {
  trends: propTypes.arrayOf(
    propTypes.shape({
      trend: propTypes.number,
      key: propTypes.string,
      unit: propTypes.string,
      value: propTypes.number
    })
  ),
  metricsData: propTypes.arrayOf(
    propTypes.shape({
      year: propTypes.string,
      agent: propTypes.string,
      previousYear: propTypes.bool,
      data: propTypes.arrayOf(
        propTypes.shape({
          x: propTypes.string,
          y: propTypes.number,
          value: propTypes.oneOfType([propTypes.string, propTypes.number])
        })
      )
    })
  ),
  metricsDetailed: propTypes.arrayOf(
    propTypes.shape({
      subscription: propTypes.string,
      agent: propTypes.string,
      previousYear: propTypes.bool,
      data: propTypes.arrayOf(
        propTypes.shape({
          x: propTypes.string,
          y: propTypes.number,
          value: propTypes.oneOfType([propTypes.string, propTypes.number])
        })
      ),
      customerDeviceID: propTypes.string,
      department: propTypes.shape({
        id: propTypes.string,
        name: propTypes.string
      }),
      deviceType: propTypes.string,
      id: propTypes.string,
      lastLog: propTypes.string,
      lastAlert: propTypes.string,
      nextPreventativeMaintenance: propTypes.string,
      location: propTypes.string,
      metrics: propTypes.arrayOf(
        propTypes.shape({
          metric: propTypes.string,
          unit: propTypes.string,
          value: propTypes.number
        })
      ),
      serialID: propTypes.string,
      softwareVersion: propTypes.string,
      status: propTypes.string
    })
  ),
  metricsUsage: propTypes.shape({
    carbonFootprint: propTypes.number,
    ventilationModes: propTypes.arrayOf(
      propTypes.shape({
        label: propTypes.string,
        value: propTypes.number
      })
    ),
    adoption: propTypes.arrayOf(
      propTypes.shape({
        label: propTypes.string,
        maxValue: propTypes.number,
        value: propTypes.number
      })
    )
  }),
  costs: propTypes.shape({
    currency: currencyShape,
    sev: propTypes.number,
    des: propTypes.number,
    iso: propTypes.number
  }),
  fetchMetrics: propTypes.func.isRequired,
  fetchMetricsDetailed: propTypes.func.isRequired,
  fetchTrends: propTypes.func.isRequired,
  fetchMetricsUsage: propTypes.func.isRequired,
  fetchMetricsDeviceUsage: propTypes.func.isRequired,
  fetchCosts: propTypes.func.isRequired,
  intl: intlShape,
  subscriptions: propTypes.shape({
    count: propTypes.number,
    level: propTypes.string,
    priority: propTypes.number
  }),

  userinfo: userInfoShape
};

const mapStateToProps = state => ({
  persistentState: state.persistentState.persistentState,
  trends: state.trends.trends,
  metrics: state.metrics.metrics,
  metricsDetailed: state.metricsDetailed.metricsDetailed,
  metricsUsage: state.metricsUsage.metricsUsage,
  costs: state.costs.costs,
  filters: state.filters,
  subscriptions:
    state.devicesStatusOverview.devicesStatusOverview.subscriptions,
  config: state.config,
  userinfo: get(state, 'authorization.userinfo'),
  metricDetailedUsage: state.metricsDetailedPerDevice.metricsDeviceUsage,
  metricsY2y: state.metrics.metricsY2y
});

const mapDispatchToProps = {
  savePersistentState,
  fetchMetrics,
  fetchMetricsDetailed,
  fetchTrends,
  fetchMetricsUsage,
  fetchMetricsDeviceUsage,
  fetchCosts,
  fetchDevicesOverview
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(UsageStatisticsWidget);
