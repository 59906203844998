import React, { Component, useCallback, useEffect } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { useDispatch, connect } from 'react-redux';
import { compose } from 'redux';

import { authLogout, fetchJWT } from './redux/actions/authorization';
import {
  loadPersistentStore,
  loadPersistentState,
  savePersistentState,
  removePersistentState
} from './redux/actions/persistentState';
import { fetchProfile } from './redux/actions/profile';

import {
  Dashboard,
  DeviceOverview,
  Device,
  DeviceDashboard,
  DeviceTestOverview,
  DeviceTest,
  DeviceLogOverview,
  ServiceOrderOverview,
  DeviceServiceOrderOverview,
  UsageStatisticsOverview,
  PreventiveMaintenanceOverview,
  DeviceCaseOverview,
  Profile,
  Case,
  RunningHoursOverview,
  Admin,
  AdminDevice,
  AdminConnectivityNode
} from './containers';
import {
  routePaths,
  NotFound,
  Layout,
  PathJoin,
  LoginCallback,
  isFlowFamily,
  DeprecatedNotification
} from './components';
import { withI18n } from './hoc';
import { WithAuthorization } from './hoc';
import axios from 'axios';
import get from 'lodash.get';
import sprite from './assets/icons/sprite.svg';
import { WithAbilityContext } from './hoc/withAbilityContext/withAbilityContext';
const cacheInstance = axios.create();

export const App = props => {
  const dispatch = useDispatch();
  // Cache SVG sprite file
  cacheInstance.get(sprite).then(res => {
    const div = document.createElement('div');
    div.innerHTML = res.data;
    div.style.display = 'none';
    document.body.insertBefore(div, document.body.childNodes[0]);
  });

  // Load persistent store on application load
  useEffect(() => {
    dispatch(loadPersistentStore());
    dispatch(loadPersistentState('roleChange'));
    dispatch(fetchProfile());
  }, [dispatch]);

  let redirect;
  if (!props.user && props.history.location.pathname !== '/login') {
    redirect = <Redirect to="/login" />;
  }

  let layout = (
    <Layout>
      {redirect}
      <Switch>
        <Route path="/login" component={props.login} />
      </Switch>
    </Layout>
  );

  const enableServoUsageStatistics = get(
    props.config,
    'enableServoUsageStatistics',
    false
  );

  const enableSubscriptions = get(props.config, 'enableSubscriptions', false);

  const adminRoute = useCallback(() => {
    if (!props.profile) {
      return <></>;
    }

    let adminRoute = <NotFound />;
    let userHasAdministrativeAccess = get(props.profile, 'adminRole') !== null;

    if (userHasAdministrativeAccess) {
      adminRoute = <Admin />;
    }

    return adminRoute;
  }, [props.profile]);

  if (props.user) {
    layout = (
      <Layout
        onLogout={props.authLogout}
        fetchToken={props.fetchJWT}
        savePersistentState={props.savePersistentState}
        removePersistentState={props.removePersistentState}
        roleChange={props.persistentState['roleChange']}
        user={
          // TODO: move this functionality to a common place handling the authorization
          props.user.profile.name
            ? props.user.profile.name
            : props.user.profile.family_name +
              ' ' +
              props.user.profile.given_name
        }
        canChangeRole={get(props, 'userinfo.can_change_role')}
        hospital={props.persistentState.deviceFilterAccount}
      >
        {redirect}
        <Switch>
          <Route
            path={PathJoin([routePaths.LoginCallback.path])}
            component={LoginCallback}
          />
          <Route path={PathJoin([])} exact>
            <Dashboard />
          </Route>
          <Route path={PathJoin([routePaths.Profile.path])} exact>
            <Profile />
          </Route>
          <Route path={PathJoin([routePaths.DeviceOverview.path])} exact>
            <DeviceOverview />
          </Route>
          <Route
            path={PathJoin([
              routePaths.DeviceOverview.path,
              routePaths.InstalledBaseDevice.path
            ])}
            exact
          >
            <DeviceOverview />
          </Route>
          <Route
            path={PathJoin([
              routePaths.DeviceOverview.path,
              routePaths.InstalledBaseAccessories.path
            ])}
            exact
          >
            {(enableSubscriptions && <DeviceOverview />) || <Redirect to="/" />}
          </Route>
          <Route
            path={PathJoin([
              routePaths.DeviceOverview.path,
              routePaths.InstalledBaseSubscriptions.path
            ])}
            exact
          >
            <DeviceOverview />
          </Route>
          <Route path={PathJoin([routePaths.ServiceOrderOverview.path])} exact>
            <ServiceOrderOverview />
          </Route>
          <Route
            path={PathJoin([routePaths.UsageStatisticsOverview.path])}
            exact
          >
            <UsageStatisticsOverview />
          </Route>
          <Route
            path={PathJoin([
              routePaths.DeviceUsageStatistics.path,
              routePaths.DeviceUsageStatisticsFlow.path
            ])}
            exact
          >
            {(enableServoUsageStatistics && <UsageStatisticsOverview />) || (
              <Redirect to="/" />
            )}
          </Route>
          <Route
            path={PathJoin([
              routePaths.DeviceUsageStatistics.path,
              routePaths.DeviceUsageStatisticsServo.path
            ])}
            exact
          >
            {(enableServoUsageStatistics && <UsageStatisticsOverview />) || (
              <Redirect to="/" />
            )}
          </Route>
          <Route
            path={PathJoin([routePaths.PreventiveMaintenanceOverview.path])}
            exact
          >
            <PreventiveMaintenanceOverview />
          </Route>
          <Route path={PathJoin([routePaths.RunningHoursOverview.path])} exact>
            <RunningHoursOverview />
          </Route>
          <Route path={PathJoin([routePaths.Admin.path])} exact>
            {adminRoute}
          </Route>
          <Route
            path={PathJoin([
              routePaths.Admin.path,
              routePaths.AdminDevices.path,
              ':uuid'
            ])}
            exact
          >
            <AdminDevice />
          </Route>
          <Route
            path={PathJoin([
              routePaths.Admin.path,
              routePaths.AdminCN.path,
              ':uuid'
            ])}
            exact
          >
            <AdminConnectivityNode />
          </Route>
          <Route path={PathJoin([routePaths.DeviceOverview.path, ':deviceId'])}>
            <Device>
              <Switch>
                <Route
                  path={PathJoin([routePaths.DeviceOverview.path, ':deviceId'])}
                  exact
                >
                  <DeviceDashboard />
                </Route>
                <Route
                  path={PathJoin([
                    routePaths.DeviceOverview.path,
                    ':deviceId',
                    routePaths.DeviceTestOverview.path
                  ])}
                  exact
                >
                  <DeviceTestOverview />
                </Route>
                <Route
                  path={PathJoin([
                    routePaths.DeviceOverview.path,
                    ':deviceId',
                    routePaths.DeviceLogOverview.path,
                    ':logType'
                  ])}
                >
                  <DeviceLogOverview />
                </Route>
                <Redirect
                  exact
                  from={PathJoin([
                    routePaths.DeviceOverview.path,
                    ':deviceId',
                    routePaths.DeviceLogOverview.path
                  ])}
                  to={PathJoin([
                    routePaths.DeviceOverview.path,
                    ':deviceId',
                    routePaths.DeviceLogOverview.path,
                    routePaths.DeviceLogOverview.defaultLogType
                  ])}
                />
                <Route
                  path={PathJoin([
                    routePaths.DeviceOverview.path,
                    ':deviceId',
                    routePaths.DeviceTestOverview.path,
                    ':testId'
                  ])}
                  exact
                >
                  <DeviceTest />
                </Route>
                <Route
                  path={PathJoin([
                    routePaths.DeviceOverview.path,
                    ':deviceId',
                    routePaths.DeviceServiceOrderOverview.path
                  ])}
                  exact
                >
                  <DeviceServiceOrderOverview />
                </Route>
                <Route
                  path={PathJoin([
                    routePaths.DeviceOverview.path,
                    ':deviceId',
                    routePaths.DeviceCaseOverview.path
                  ])}
                  exact
                >
                  {(props.device && isFlowFamily(props.device.deviceType) && (
                    <DeviceCaseOverview />
                  )) || <Redirect to="/" />}
                </Route>
                <Route
                  path={PathJoin([
                    routePaths.DeviceOverview.path,
                    ':deviceId',
                    routePaths.DeviceCaseOverview.path,
                    ':caseId'
                  ])}
                  exact
                >
                  {(props.device &&
                    props.device.systemSoftwareVersion > '04.01.00' && (
                      <Case />
                    )) || <Redirect to="/" />}
                </Route>
              </Switch>
            </Device>
          </Route>

          <Route>
            <NotFound />
          </Route>
        </Switch>
      </Layout>
    );
  }

  return (
    <div className="App">
      <DeprecatedNotification />
      {layout}
    </div>
  );
};

const mapStateToProps = state => ({
  user: state.oidc.user,
  persistentState: state.persistentState.persistentState,
  device: state.deviceDetails.device,
  profile: state.profile.profile,
  userinfo: state.authorization.userinfo,
  config: state.config
});

const mapDispatchToProps = {
  authLogout,
  savePersistentState,
  removePersistentState,
  fetchJWT
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  WithAuthorization,
  withI18n,
  WithAbilityContext
)(App);
