import React, { useCallback, useEffect } from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import get from 'lodash.get';
import PropTypes from 'prop-types';
import bgImgSm from '../../assets/images/login-bg,w_650.jpg';
import bgImgMd from '../../assets/images/login-bg,w_1420.jpg';
import bgImgLg from '../../assets/images/login-bg,w_1920.jpg';
import { Icon, uuidv4 } from '../../components';
import './Login.scss';

const Login = ({ login, location }) => {
  // Check if url contains user type and redirect
  // to appropriate OIDC provider.
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const userType = params.get('usertype');

    if (userType !== null) {
      Object.keys(window.oidcProviders).map(providerName => {
        if (
          get(window.oidcProviders[providerName], 'config.userType', null) ===
          userType
        ) {
          login(providerName);
        }
      });
    }
  }, [location, login]);

  const handleButtonClick = useCallback(
    event => {
      const provider = event.target.dataset.provider;
      login(provider);
    },
    [login]
  );

  const loginButtonLabel = str => {
    let btnLabel;
    switch (str) {
      case 'Getinge employee':
        btnLabel = (
          <FormattedMessage id="login.getingeEmployee" defaultMessage={str} />
        );
        break;
      case 'Getinge customer':
        btnLabel = (
          <FormattedMessage id="login.getingeCustomer" defaultMessage={str} />
        );
        break;
      default:
        // We don't translate dev login
        btnLabel = str;
        break;
    }
    return btnLabel;
  };

  return (
    <>
      <img
        className="gtg-login-bg"
        sizes="(max-width: 1920px) 100vw, 1920px"
        srcSet={bgImgSm + ' 650w,' + bgImgMd + ' 1420w,' + bgImgLg + ' 1920w'}
        src={bgImgLg}
        alt=""
      />
      <div className="gtg-login-container">
        <div data-cy="loginContainer" className="gtg-login-card">
          <h2>
            <FormattedMessage id="common.login" defaultMessage="Login" />
          </h2>
          <p className="text-center">
            <FormattedMessage
              id="login.logInAs"
              defaultMessage="Please log in as"
            />
          </p>
          {window.oidcProviders &&
            Object.keys(window.oidcProviders).map(providerName => (
              <button
                type="button"
                className="btn btn-info"
                data-provider={providerName}
                onClick={handleButtonClick}
                key={uuidv4()}
              >
                {loginButtonLabel(
                  get(window.oidcProviders[providerName], 'button.text', '')
                )}
                <Icon name="arrow-right-full" size="xs" />
              </button>
            ))}
          <p className="gtg-login-disclaimer">
            <FormattedMessage
              id="login.disclaimer"
              defaultMessage="Log-in is only allowed for authorized users. If you are not an authorized user, please exit immediately. In accordance with requirements of data protection laws, we hereby inform you that personally identifiable information will be handled in log files for legal, security and costs reasons."
            />
          </p>
          <p className="gtg-login-copyright">
            © Copyright {new Date().getFullYear()} Getinge AB. All rights
            reserved.
          </p>
        </div>
      </div>
    </>
  );
};

Login.propTypes = {
  login: PropTypes.func
};

export default compose(withRouter)(Login);
