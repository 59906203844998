import { FETCH_PROFILE, entity, setProfile } from '../actions/profile';
import { apiRequest, API_SUCCESS } from '../actions/api';
import { API_URLS } from './urls';

const URL = API_URLS.Profile;

export const profileMdl = ({ dispatch }) => next => action => {
  next(action);
  switch (action.type) {
    case FETCH_PROFILE:
      dispatch(
        apiRequest({
          method: 'GET',
          url: `${URL.url}`,
          requestSignature: 'profile',
          entity: entity
        })
      );
      break;
    case `${entity} GET ${API_SUCCESS}`:
      dispatch(setProfile(action.payload.data));
      break;
    default:
      break;
  }
};
