import React from 'react';
import {
  Capitalize,
  EmptyState,
  Formatted24hTime,
  isServoFamily,
  DataTableFixedColumnsAndHeader
} from '..';
import {
  logDefaultCustomColumns,
  logServoCustomColumns,
  isColumnFixed,
  columnWidth,
  columnTextAlignment
} from './DeviceLogListColumns.model';
import { FormattedDate } from 'react-intl';
import propTypes from 'prop-types';

const MappedLogColumns = (logType, columns, customColumns) => {
  const mapToColumn = (name, slug) => {
    return {
      Header: MapHeaderToRows(Capitalize(name)),
      accessor: slug,
      sortable: false,
      fixed: isColumnFixed(logType, slug, customColumns) && 'left',
      width: columnWidth(logType, slug, customColumns),
      className: columnTextAlignment(logType, slug, customColumns),
      headerClassName: columnTextAlignment(logType, slug, customColumns)
    };
  };
  const newColumns = columns
    .filter(column => !['timestamp', 'date', 'time'].includes(column.slug))
    .map(column => {
      return mapToColumn(column.name, column.slug);
    });
  const timeColumns = ['date', 'time'].map(column => {
    return mapToColumn(Capitalize(column), column);
  });
  return [...timeColumns, ...newColumns];
};

const MapHeaderToRows = name => {
  // return multiline jsx if header contains -, otherwise return name string
  const splitHeader = name.split('–');
  if (splitHeader.length > 1) {
    return (
      <>
        {splitHeader.map((nameItem, index) => {
          return index > 0 ? (
            <div className="font-weight-normal" key={nameItem}>
              {nameItem.trim()}
            </div>
          ) : (
            nameItem
          );
        })}
      </>
    );
  } else {
    return name.trim();
  }
};

const MappedLogLines = (columns, lines, deviceType) => {
  return lines.map(line => {
    const lineObj = {};
    line.forEach((lineItem, i) => {
      lineObj[(columns[i] || {}).slug] = lineItem;
    });
    const shouldShowSeconds = deviceType === 'rotaflow2' ? true : false;
    lineObj.time = line[0] ? (
      <Formatted24hTime
        value={line[0]}
        timeZone="UTC"
        showSeconds={shouldShowSeconds}
      />
    ) : (
      ''
    );
    lineObj.date = line[0] ? (
      <FormattedDate value={line[0]} timeZone="UTC" />
    ) : (
      ''
    );
    return lineObj;
  });
};

const DeviceLogList = props => {
  const { log, logType, manualPaginationSettings, deviceType } = props;
  const customColumns = logDefaultCustomColumns;
  // Remove unnecessary columns for servo devices
  if (isServoFamily(deviceType) && customColumns) {
    delete customColumns.internal.id;

    delete customColumns.service.serviceintervtype;
    delete customColumns.service.signature;
    delete customColumns.service['usb-memory-id'];
    delete customColumns.service['usb-memory-s-n'];

    if (logServoCustomColumns) {
      if (logServoCustomColumns.installation) {
        customColumns.installation = logServoCustomColumns.installation;
      }

      if (logServoCustomColumns.service) {
        customColumns.service = logServoCustomColumns.service;
      }
    }
  }

  return (
    (log && log.headers && log.items && logType && (
      <DataTableFixedColumnsAndHeader
        data={
          log && log.items
            ? MappedLogLines(log.headers, log.items, deviceType)
            : []
        }
        manualPaginationSettings={manualPaginationSettings}
        className="mb-4 -striped"
        resizable={false}
        columns={MappedLogColumns(
          logType,
          log.headers,
          logDefaultCustomColumns
        )}
      />
    )) || <EmptyState />
  );
};

DeviceLogList.propTypes = {
  log: propTypes.shape({
    headers: propTypes.arrayOf(
      propTypes.shape({
        kind: propTypes.string,
        name: propTypes.string,
        slug: propTypes.string,
        unit: propTypes.string
      })
    ),
    items: propTypes.arrayOf(propTypes.arrayOf(propTypes.string))
  }),
  manualPaginationSettings: propTypes.shape(),
  logType: propTypes.string,
  deviceType: propTypes.string
};

export default DeviceLogList;
