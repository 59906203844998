import React, { useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Redirect, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Login } from '../../containers/index';
import { Layout, LegalNotice, LoginCallback } from '../../components';
import { withI18n } from '../withI18n/withI18n';
import {
  fetchLegalNoticeApproval,
  setLegalNoticeApproval
} from '../../redux/actions/legalNotice';
import { loadPersistentState } from '../../redux/actions/persistentState';
import { authInit, fetchJWT } from '../../redux/actions/authorization';

export const WithAuthorization = WrappedComponent => {
  const Wrapper = props => {
    const {
      history,
      legalNotice,
      fetchLegalNoticeApproval,
      setLegalNoticeApproval,
      loadPersistentState,
      authInit,
      oidc,
      userinfo,
      fetchJWT,
      persistentState
    } = props;
    let el = null;

    const isLoginPath = () => history.location.pathname === '/login';
    const isCallbackPath = () => history.location.pathname === '/callback';
    const isAuthenticated = () => oidc && oidc.user && !oidc.user.expired;
    const isLoadingUser = () => oidc && oidc.isLoadingUser;
    const legalNoticeApproved = () =>
      legalNotice && legalNotice.approval && legalNotice.approval.accepted;

    useEffect(() => {
      if (oidc.user) {
        fetchJWT(persistentState['roleChange']);
      }
    }, [fetchJWT, oidc.user, persistentState]);

    useEffect(() => {
      if (userinfo) {
        fetchLegalNoticeApproval();
      }
    }, [fetchLegalNoticeApproval, userinfo]);

    const handleLogin = useCallback(
      oidcProvider => {
        authInit(oidcProvider);
      },
      [authInit]
    );

    const handleLegalNoticeApprove = useCallback(() => {
      if (legalNotice && legalNotice.version) {
        setLegalNoticeApproval(legalNotice.version);
      }
    }, [legalNotice, setLegalNoticeApproval]);

    if (!isLoadingUser()) {
      el = <WrappedComponent {...props} />;
    }

    if (!isAuthenticated() && !isLoadingUser()) {
      if (!(isLoginPath() || isCallbackPath())) {
        sessionStorage.setItem('pathBeforeLogin', history.location.pathname);
      }
      el = (
        <React.Fragment>
          {!(isLoginPath() || isCallbackPath()) && <Redirect to="/login" />}
          <Layout>
            {isLoginPath() && <Login login={handleLogin} />}
            {isCallbackPath() && <LoginCallback />}
          </Layout>
        </React.Fragment>
      );
    }

    if (isAuthenticated() && !legalNoticeApproved()) {
      el = (
        <React.Fragment>
          <Layout>
            <LegalNotice
              approveHandler={handleLegalNoticeApprove}
              legalNotice={legalNotice}
            />
          </Layout>
        </React.Fragment>
      );
    }

    if (isAuthenticated() && legalNoticeApproved() && isLoginPath()) {
      el = <Redirect to="/" />;
    }

    return el;
  };

  const mapStateToProps = state => ({
    oidc: state.oidc,
    userinfo: state.authorization.userinfo,
    jwt: state.authorization.jwt,
    legalNotice: state.legalNotice,
    persistentState: state.persistentState.persistentState
  });

  const mapDispatchToProps = {
    fetchLegalNoticeApproval,
    setLegalNoticeApproval,
    authInit,
    fetchJWT
  };

  Wrapper.propTypes = {
    fetchLegalNoticeApproval: PropTypes.func,
    setLegalNoticeApproval: PropTypes.func,
    authInit: PropTypes.func,
    fetchJWT: PropTypes.func
  };

  return compose(
    connect(mapStateToProps, mapDispatchToProps),
    withI18n,
    withRouter
  )(Wrapper);
};
