import React, { useCallback, useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import { withRouter } from 'react-router-dom';
import { FormattedDate, FormattedMessage } from 'react-intl';
import {
  routePaths,
  DataTableFixedColumnsAndHeader,
  getDeviceTypeDisplayName,
  Formatted24hTime
} from '..';
import { WithTooltip } from '../../hoc';
import './RunningHoursTable.scss';

export const RunningHoursTable = ({
  devices,
  maxRunningHours,
  history,
  manualPaginationSettings
}) => {
  if (!devices || devices.length === 0) {
    return null;
  }

  /* eslint-disable react-hooks/rules-of-hooks */
  const handleRowClick = useCallback(
    (state, rowInfo) => {
      if (get(rowInfo, 'original.id', false)) {
        return {
          onClick: () => {
            history.push(
              `/${routePaths.DeviceOverview.path}/${rowInfo.original.id}`
            );
          }
        };
      } else {
        return {};
      }
    },
    [history]
  );
  /* eslint-enable react-hooks/rules-of-hooks */

  const getGraphText = row => {
    const ref = useRef();
    const tooltipRef = useRef();
    let [hide, setHide] = useState(false);

    let ellipsisActive = e => {
      return e.offsetWidth < e.scrollWidth;
    };

    useEffect(() => {
      if (ellipsisActive(ref.current)) {
        setHide(true);
      }
    }, []);

    const percentageOfMax = maxRunningHours
      ? (row.value * 100) / maxRunningHours
      : 0;

    const tooltipHandlerMouseover = useCallback(() => {
      let tooltip = document.getElementsByClassName('tooltip');
      tooltip =
        tooltip.length === 2
          ? document.getElementsByClassName('tooltip')[1]
          : document.getElementsByClassName('tooltip')[0];
      tooltip.style.cssText =
        percentageOfMax === 0
          ? `left: -10px; width: 50px;`
          : `left: calc((${percentageOfMax}% / 2) - 35px);`;
    }, [percentageOfMax]);

    return (
      <WithTooltip
        tooltipContent={<div>{row.value}</div>}
        tooltipLocation="bottom"
        tooltipFixedArrow
      >
        <div
          className="gtg-agent-usage-bar"
          onMouseOver={tooltipHandlerMouseover}
        >
          <div
            className="gtgRunningHoursBar"
            style={{
              width: `${percentageOfMax}%`
            }}
            ref={ref}
          >
            <div
              className="gtg-detailed-usage-stat-list-graph-tooltip"
              ref={tooltipRef}
            >
              <span>{hide ? '' : row.value}</span>
            </div>
          </div>
        </div>
      </WithTooltip>
    );
  };

  const columns = [
    {
      Header: <FormattedMessage id="column.device" defaultMessage="Device" />,
      accessor: 'deviceType',
      headerClassName: 'text-left',
      className: 'text-left',
      Cell: row =>
        (row.value.length && getDeviceTypeDisplayName(row.value)) || ''
    },
    {
      Header: (
        <FormattedMessage id="column.department" defaultMessage="Department" />
      ),
      accessor: 'department.name',
      headerClassName: 'text-left',
      className: 'text-left'
    },
    {
      Header: (
        <FormattedMessage id="column.serialNo" defaultMessage="Serial no" />
      ),
      accessor: 'serialID',
      headerClassName: 'text-right',
      className: 'text-right'
    },
    {
      Header: (
        <FormattedMessage
          id="column.customerDeviceNo"
          defaultMessage="Customer device no"
        />
      ),
      accessor: 'customerDeviceID',
      headerClassName: 'text-left',
      className: 'text-left'
    },
    {
      Header: (
        <FormattedMessage
          id="column.lastConnected"
          defaultMessage="Last connected"
        />
      ),
      accessor: 'lastLog',
      Cell: row =>
        (row.value && (
          <React.Fragment>
            {row.value && (
              <>
                <FormattedDate value={row.value} />{' '}
                <Formatted24hTime value={row.value} />
              </>
            )}
          </React.Fragment>
        )) || (
          <FormattedMessage id="common.notAvailableAbbr" defaultMessage="N/A" />
        )
    },
    {
      Header: (
        <FormattedMessage id="column.totalHours" defaultMessage="Total hours" />
      ),
      accessor: 'runningHours',
      width: 440,
      Cell: row => {
        return !row.value && row.value !== 0 ? (
          <FormattedMessage id="common.notAvailableAbbr" defaultMessage="N/A" />
        ) : (
          getGraphText(row)
        );
      }
    }
  ];

  return (
    <DataTableFixedColumnsAndHeader
      manualPaginationSettings={manualPaginationSettings}
      getTrProps={handleRowClick}
      defaultPageSize={5}
      className="mb-3 -highlight -striped gtg-running-hours-table"
      noCustomScrollbars
      withOverflow
      data={devices}
      columns={columns}
    />
  );
};

RunningHoursTable.propTypes = {
  devices: PropTypes.arrayOf(
    PropTypes.shape({
      lastLog: PropTypes.string,
      customerDeviceID: PropTypes.string,
      serialID: PropTypes.string,
      department: PropTypes.shape({
        name: PropTypes.string
      }),
      deviceType: PropTypes.string,
      runningHours: PropTypes.number
    })
  ),
  maxRunningHours: PropTypes.number,
  manualPaginationSettings: PropTypes.shape({}),
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired
};

export default withRouter(RunningHoursTable);
