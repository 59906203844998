import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import {
  CardSection,
  CardTab,
  CardTabbed,
  CertificationUpload,
  ContainerHeader,
  SoftwareUpload
} from '../../components';
import { GlobalSearch } from '..';
import get from 'lodash.get';

const Admin = props => {
  const enableSwUpload = get(props.config, 'enableSoftwareUpload', false);
  let swUploadTab = null;
  if (enableSwUpload) {
    swUploadTab = (
      <CardTab
        title={
          <FormattedMessage
            id="admin.softwareUpload"
            defaultMessage="Software upload"
          />
        }
      >
        <CardSection>
          <SoftwareUpload />
        </CardSection>
      </CardTab>
    );
  }

  return (
    <>
      <ContainerHeader headerTitle="Admin portal" />
      <div className="row">
        <div className="col-12 col-md-12">
          <CardTabbed>
            <CardTab
              title={
                <FormattedMessage
                  id="admin.globalDeviceSearch"
                  defaultMessage="Global device search"
                />
              }
            >
              <CardSection>
                <GlobalSearch />
              </CardSection>
            </CardTab>
            <CardTab
              title={
                <FormattedMessage
                  id="admin.certificationUpload"
                  defaultMessage="Certification upload"
                />
              }
            >
              <CardSection>
                <CertificationUpload />
              </CardSection>
            </CardTab>
            {swUploadTab}
          </CardTabbed>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = state => ({
  config: state.config
});

const mapDispatchToProps = {};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)(Admin);
