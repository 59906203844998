export const isFunction = val => typeof val === 'function';

/**
 * Given a `value`, `minValue` and `maxValue`:
 * - if `minValue <= value <= maxValue` then `value` will be returned
 * - if `value < minValue` then `minValue` will be returned
 * - if `maxValue < value` then `maxValue` will be returned
 * - if `value` is not a number, then `minValue` will be returned
 * @param value The value that needs to be clamped
 * @param minValue The min value that is allowed
 * @param maxValue The max value that is allowed
 */
export const clampValue = (value, minValue, maxValue) => {
  if (minValue > maxValue) {
    const error = `clampValue function error: minValue should be smaller then maxValue`;
    console.error(error, { value, minValue, maxValue });
    throw error;
  }

  const notNumber =
    Number.isNaN(value) || value === undefined || (!value && value !== 0);

  if (notNumber) {
    console.warn(
      `clampValue function warning: value should be a number, but it was '${value}' instead`
    );
  }

  return Math.max(minValue, Math.min(notNumber ? minValue : value, maxValue));
};
