import React from 'react';
import { uuidv4, Icon, formatValueUnit } from '..';

import styles from './TrendIndicatorCell.module.scss';

function TrendIndicatorCell(trend) {
  let displayValue, symbol, className;

  switch (true) {
    case trend > 0:
      symbol = '+';
      displayValue = trend * 100;
      className = styles.gtgTrendIncrease;
      break;
    case trend === 0:
      symbol = '•';
      displayValue = 0;
      break;
    case trend < 0:
      symbol = '-';
      displayValue = trend * -1 * 100;
      className = styles.gtgTrendDecrease;
      break;
    default:
      /*
       * If trend is not a number, that means that we couldn't calculate the trend
       * The previous value was 0 (an increase from 0 to X can't be calulated)
       */
      return (
        <span key="trend-none-key">
          <Icon name="neutral" color="light" />
        </span>
      );
  }

  return (
    <span key={uuidv4()} className={className}>
      {`${symbol} ${formatValueUnit(displayValue, '%')}`}
    </span>
  );
}

export default TrendIndicatorCell;
