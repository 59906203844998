import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';

import { Icon, List, ListItem, Scrollable, SoftwareOptionsMapper } from '..';
import styles from './DeviceStatus.module.scss';
import './DeviceStatus.scss';
import { isRotaflowFamily } from '../utils/Device/deviceHelper';

const DeviceStatus = props => {
  const { deviceStatus } = props;
  const maxListHeight = 50 * 5;

  return (
    <React.Fragment>
      {!deviceStatus && (
        <div className={styles.gtgDeviceStatus + ' device-status'}>
          <h6>
            <FormattedMessage
              defaultMessage="Sorry, but no parsed status logs exist for this device."
              id="deviceStatus.noStatusLogForDevice"
            />
          </h6>
        </div>
      )}
      {deviceStatus && (
        <div className={styles.gtgDeviceStatus + ' device-status row'}>
          <div className="col">
            <h6>
              <FormattedMessage id="column.status" defaultMessage="Status" />
            </h6>
            <Scrollable minElementHeight={100} maxElementHeight={maxListHeight}>
              <List
                dataCy="deviceStatusList"
                minListItems={
                  !isRotaflowFamily(deviceStatus.deviceType) ? 5 : 1
                }
              >
                {deviceStatus.values.map(item => {
                  const itemKey =
                    (typeof item.key === 'object' &&
                      item.key.props &&
                      item.key.props.id) ||
                    item.key;
                  return (
                    <ListItem key={itemKey}>
                      <label>{item.key}</label>
                      <span>{item.value}</span>
                    </ListItem>
                  );
                })}
              </List>
            </Scrollable>
          </div>
          {!isRotaflowFamily(deviceStatus.deviceType) && (
            <>
              <div className="col">
                <h6>
                  <FormattedMessage
                    defaultMessage="Delivered software options"
                    id="deviceStatus.deliveredSoftwareOptions"
                  />
                </h6>
                <Scrollable
                  minElementHeight={100}
                  maxElementHeight={maxListHeight}
                >
                  <List
                    dataCy="deviceStatusSoftwareOptionsDelivered"
                    minListItems="5"
                  >
                    {deviceStatus.softwareOptions &&
                      SoftwareOptionsMapper(
                        deviceStatus.deviceType,
                        deviceStatus.softwareOptions.delivered
                      ).map(({ name, label }) => {
                        return (
                          <ListItem key={name}>
                            <label>{label}</label>
                          </ListItem>
                        );
                      })}
                  </List>
                </Scrollable>
              </div>
              <div className={'col d-none'}>
                <h6>
                  <FormattedMessage
                    defaultMessage="Software options available"
                    id="deviceStatus.softwareOptionsAvailable"
                  />
                </h6>
                <Scrollable
                  minElementHeight={100}
                  maxElementHeight={maxListHeight}
                >
                  <List
                    dataCy="deviceStatusSoftwareOptionsAvailable"
                    minListItems="3"
                  >
                    {deviceStatus.softwareOptions &&
                      deviceStatus.softwareOptions.available.map(
                        ({ name, url }) => {
                          return (
                            <ListItem key={name}>
                              <div>
                                <label>{name}</label>
                              </div>
                              <div>
                                <a href={url}>
                                  Download information{' '}
                                  <Icon name="download" size="sm" />
                                </a>
                              </div>
                            </ListItem>
                          );
                        }
                      )}
                  </List>
                </Scrollable>
              </div>
            </>
          )}
        </div>
      )}
    </React.Fragment>
  );
};

export default injectIntl(DeviceStatus);
