import React from 'react';
import { FormattedMessage } from 'react-intl';
import { CaseVentilationModesChart, EmptyState } from '..';
import propTypes from 'prop-types';

const CaseVentilationModesWidget = ({ caseVentilationData }) => {
  return (
    <>
      <h6 className="mb-3 gtg-case-details-title">
        <FormattedMessage
          id="case.ventilationModes"
          defaultMessage="Ventilation modes"
        />
      </h6>
      {(caseVentilationData &&
        Object.keys(caseVentilationData).length > 0 && (
          <div className="d-flex justify-content-center">
            <CaseVentilationModesChart ventilationData={caseVentilationData} />
          </div>
        )) || <EmptyState />}
    </>
  );
};

CaseVentilationModesWidget.propTypes = {
  caseVentilationData: propTypes.shape({
    manual: propTypes.number,
    afgo: propTypes.number,
    pc: propTypes.number,
    vc: propTypes.number,
    prvc: propTypes.number,
    ps: propTypes.number,
    psbackup: propTypes.number,
    pcps: propTypes.number,
    vcps: propTypes.number
  })
};

export default CaseVentilationModesWidget;
