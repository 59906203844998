import React from 'react';

function AgentLabelSubbed(str) {
  // encases numbers in a <sub> block
  return (
    <span key={str}>
      {str
        .match(/[a-z]+|[^a-z]+/gi)
        .map(s => (!isNaN(s) ? <sub key={str + s}>{s}</sub> : s))}
    </span>
  );
}

export default AgentLabelSubbed;
