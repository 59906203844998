import {
  setMetricsUsage,
  FETCH_METRICS_USAGE,
  entity
} from '../actions/metricsUsage';
import { API_SUCCESS, apiRequest } from '../actions/api';
import { MetricsTimeParams } from '../../components/';
import { API_URLS } from './urls';

const URL = API_URLS.MetricsUsage;

export const metricsUsageMdl = ({ dispatch }) => next => action => {
  next(action);

  const { type } = action;

  switch (type) {
    case FETCH_METRICS_USAGE:
      const { timeSpan, count } = action.payload;
      dispatch(
        apiRequest({
          method: 'GET',
          url: URL.url + MetricsTimeParams('metricsUsage', timeSpan, count),
          requestSignature: 'metricsUsage',
          entity: entity,
          filter: URL.filter
        })
      );

      break;
    case `${entity} GET ${API_SUCCESS}`:
      dispatch(setMetricsUsage(action.payload.data));
      break;
    // no default
  }
};
