// Add column headers and define column keys and widths
export const caseDetailsColumns = [
  { header: 'Hospital', key: 'hospital', width: 40 },
  { header: 'Department', key: 'departmentName', width: 40 },
  { header: 'Location', key: 'location', width: 40 },
  { header: 'Device', key: 'deviceType', width: 40 },
  { header: 'Serial no', key: 'serialID', width: 40 },
  { header: 'Customer device no', key: 'customerDeviceID', width: 40 },
  { header: 'Start case', key: 'startCase', width: 40 },
  { header: 'End case', key: 'endCase', width: 40 },
  { header: 'Ventilation mode - manual', key: 'manual', width: 40 },
  { header: 'Ventilation mode - PC', key: 'pc', width: 40 },
  { header: 'Ventilation mode - VC', key: 'vc', width: 40 },
  { header: 'Ventilation mode - PRVC', key: 'prvc', width: 40 },
  { header: 'Ventilation mode - PS', key: 'ps', width: 40 },
  { header: 'Ventilation mode - PS backup', key: 'psbackup', width: 40 },
  { header: 'Ventilation mode - SIMV (VC) + PS', key: 'vcps', width: 40 },
  { header: 'Average peak pressure', key: 'PeakAirwayPressure', width: 40 },
  { header: 'Average PEEP', key: 'PEEP', width: 40 },
  { header: 'Average MVi', key: 'MinuteVolumeInspired', width: 40 },
  { header: 'Average VTi', key: 'TidalVolumeInspired', width: 40 },
  { header: 'SEV usage', key: 'sevUsage', width: 40 },
  { header: 'SEV cost', key: 'sevCost', width: 40 },
  { header: 'SEV cost per liter', key: 'sevCostPerLiter', width: 40 },
  { header: 'ISO usage', key: 'isoUsage', width: 40 },
  { header: 'ISO cost', key: 'isoCost', width: 40 },
  { header: 'ISO cost per liter', key: 'isoCostPerLiter', width: 40 },
  { header: 'DES usage', key: 'desUsage', width: 40 },
  { header: 'DES cost', key: 'desCost', width: 40 },
  { header: 'DES cost per liter', key: 'desCostPerLiter', width: 40 },
  { header: 'Total agent usage', key: 'totalUsage', width: 40 },
  { header: 'Total agent usage cost', key: 'totalUsageCost', width: 40 },
  { header: 'Recruitment maneuver', key: 'RM', width: 40 }
];

// Define filename
export const caseDetailsFileName = 'Case_details.xlsx';
