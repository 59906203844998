function BarWidth(timeSpan) {
  switch (timeSpan) {
    case 'week':
      return 0.27723;
    case 'month':
      return 0.33275;
    case 'year':
      return 0.2852;
    case 'y2y':
      return 0.48715;
    default:
      return 0.33275;
  }
}

export default BarWidth;
