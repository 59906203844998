import React from 'react';

const Icon = props => {
  const iconSize = props.size ? props.size : 'md';
  const colorClassName = props.color ? 'icon-color-' + props.color : '';
  return (
    <i data-cy={props.dataCy} className={'icon ' + iconSize}>
      <svg
        viewBox="0 0 36 36"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        className={`icon-${props.name}`}
      >
        <use xlinkHref={`#icon-${props.name}`} className={colorClassName} />
      </svg>
    </i>
  );
};

export default Icon;
