import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import get from 'lodash.get';

import { Topbar, Navbar, Breadcrumbs, RoleSelectionDropdown } from '../..';

import styles from './Header.module.scss';

const Header = ({
  onLogout,
  user,
  hospital,
  fetchToken,
  savePersistentState,
  removePersistentState,
  roleChange,
  canChangeRole
}) => {
  const handleLogout = useCallback(() => {
    onLogout && onLogout();
  }, [onLogout]);

  // @TODO: Change the comparison to id, based on the SAP account id DEMO once API/ENV vars get implemented
  // We display yellow header in case demo hospital is selected
  const isDemoHospital =
    get(hospital, '[0].label') === 'Demo Hospital' ||
    get(hospital, '[0].label') === 'Demo Hospital II';

  return (
    <header className={styles.gtgHeader}>
      {user && (
        <div className={styles.gtgTopbarWrapper}>
          <div className="container-app">
            <Topbar onLogout={handleLogout} user={user} />
          </div>
        </div>
      )}
      <div className={styles.gtgNavbarWrapper}>
        <div className="container-app">
          <Navbar onLogout={handleLogout} user={user} />
        </div>
      </div>
      <div className={styles.gtgBreadcrumbsWrapper}>
        <div className={classNames('container-app', styles.gtgBreadCrumbs)}>
          {user && <Breadcrumbs />}
          <div className={styles.gtgBreadCrumbsSide}>
            {isDemoHospital && (
              <div
                className={classNames(
                  'font-weight-bold',
                  styles.gtgDemoHospitalText
                )}
              >
                Demo hospital
              </div>
            )}
            {user && canChangeRole && isDemoHospital && (
              <RoleSelectionDropdown
                user={user}
                fetchToken={fetchToken}
                savePersistentState={savePersistentState}
                removePersistentState={removePersistentState}
                roleChange={roleChange}
              />
            )}
          </div>
        </div>
      </div>
    </header>
  );
};

Header.propTypes = {
  onLogout: PropTypes.func,
  user: PropTypes.string,
  hospital: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string
    })
  ),
  fetchToken: PropTypes.func,
  savePersistentState: PropTypes.func,
  removePersistentState: PropTypes.func,
  roleChange: PropTypes.shape({
    role: PropTypes.string,
    certification: PropTypes.bool
  }),
  canChangeRole: PropTypes.bool
};

export default Header;
