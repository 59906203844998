export const agentUsageTypes = {
  Sevoflurane: 'sev',
  Desflurane: 'des',
  Isoflurane: 'iso',
  FreshGasFlow: 'fgf',
  Cases: 'cases'
};

export const trendsTypes = {
  Sevoflurane: 'sev',
  Desflurane: 'des',
  Isoflurane: 'iso',
  N2O: 'n2o',
  O2: 'o2'
};

export const metricTypes = {
  Sevoflurane: 'sev',
  Desflurane: 'des',
  Isoflurane: 'iso',
  N2O: 'n2o',
  FreshGasFlow: 'fgf',
  Cases: 'cases'
};

export const caseMetricTypes = {
  sev: 'sev',
  des: 'des',
  iso: 'iso',
  n2o: 'n2o',
  avg_fgf: 'avg_fgf',
  avg_fio2: 'avg_fio2'
};

export const adoptionMetricTypes = {
  FGF: 'adoptionFgf',
  AGC: 'adoptionAgc',
  FiO2: 'adoptionFio2',
  'Recruitment maneuver': 'adoptionRM',
  DES: 'adoptionDes',
  ISO: 'adoptionIso',
  SEV: 'adoptionSev'
};

export const averagesTypes = {
  casesTotal: 'cases',
  casesAverage: 'average',
  costPerMin: 'averagePerMin',
  costPerCase: 'averagePerCase',
  casesAgc: 'casesAgc'
};

export const ventilationModeTypes = {
  Manual: 'ventilationManual',
  AFGO: 'ventilationAfgo',
  PC: 'ventilationPc',
  VC: 'ventilationVc',
  PRVC: 'ventilationPrvc',
  PS: 'ventilationPs',
  'PS backup': 'ventilationPsBac',
  'SIMV (VC) + PS': 'ventilationVcPs',
  'SIMV (PC) + PS': 'ventilationPcPs'
};
