import { FETCH_CASE, setCase, entity } from '../actions/case';
import { apiRequest, API_SUCCESS } from '../actions/api';
import { API_URLS } from './urls';

export const caseMdl = ({ dispatch }) => next => action => {
  next(action);

  const { type } = action;
  switch (type) {
    case FETCH_CASE:
      const URL = API_URLS.Cases(action.deviceId);

      dispatch(
        apiRequest({
          method: 'GET',
          url: `${URL.url}/${action.id}`,
          requestSignature: 'case',
          entity: entity
        })
      );
      break;
    case `${entity} GET ${API_SUCCESS}`:
      dispatch(setCase(action.payload.data));
      break;
    // no default
  }
};
