import { FETCH_SERVICE_REPORT } from '../actions/serviceReport';
import { fetchBlob } from '../actions/blobDownload';
import { API_URLS } from './urls';

const URL = id => `${API_URLS.ServiceOrders.url}/${id}/service-report/download`;

export const serviceReportMdl = ({ dispatch }) => next => action => {
  next(action);

  const { type } = action;

  switch (type) {
    case FETCH_SERVICE_REPORT:
      const { id } = action.payload;

      dispatch(fetchBlob(URL(id)));
      break;
    // no default
  }
};
