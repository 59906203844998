import React from 'react';
import { Link } from 'react-router-dom';
import { WithLoadingSpinner } from '../../hoc';
import {
  routePaths,
  Card,
  CardSection,
  List,
  ListItem,
  Icon,
  Alert,
  EmptyState,
  PathJoin,
  Formatted24hTime,
  isRotaflowFamily,
  isFlowFamily,
  isServoFamily,
  TestResultIcon,
  mapTestResult
} from '..';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { Can } from '../../hoc/withAbilityContext/withAbilityContext';
import { Device } from '../../hoc/withAbilityContext/rule-builder';
import get from 'lodash.get';

const DeviceTestListWidget = ({
  device,
  tests,
  testNotPerformedAlarm,
  getOpenModalForLogType
}) => {
  const minListItems =
    testNotPerformedAlarm && isFlowFamily(device.deviceType) ? 4 : 5;
  let widgetTitle = (
    <FormattedMessage id="test.test" defaultMessage="Pre-use check" />
  );
  if (isFlowFamily(device.deviceType)) {
    widgetTitle = (
      <FormattedMessage
        id="test.systemCheckout"
        defaultMessage="System checkout"
      />
    );
  }
  if (isRotaflowFamily(device.deviceType)) {
    widgetTitle = (
      <FormattedMessage id="test.selfTest" defaultMessage="Selftest" />
    );
  }

  let downloadLogType = 'system-checkout';
  if (isServoFamily(device.deviceType)) {
    downloadLogType = 'test_xml';
  }

  return (
    <Card dataCy="cardSystemCheckout">
      <WithLoadingSpinner when="tests">
        <CardSection title={widgetTitle}>
          {(tests && device && tests.items && tests.items.length > 0 && (
            <>
              {testNotPerformedAlarm && isFlowFamily(device.deviceType) && (
                <Alert
                  level="warning"
                  msg={
                    <FormattedMessage
                      id="test.notPerformedAlarm"
                      defaultMessage="More than 24 hours have passed since the last system checkout."
                    />
                  }
                />
              )}
              <List
                dataCy="listSystemCheckout"
                minListItems={minListItems}
                minListItemHeight={60}
              >
                {tests &&
                  tests.items &&
                  tests.items.slice(0, minListItems).map(p => {
                    const failedTests =
                      p.result === 'watch'
                        ? get(p, 'failedProactiveWatch.failed', [])
                        : get(p, 'failed.failed', []);
                    return (
                      <ListItem
                        key={p.id}
                        className="py-2 px-1"
                        minListItemHeight={60}
                      >
                        <section>
                          <span>
                            <TestResultIcon
                              testResult={p.result}
                              testFailedCount={
                                failedTests.length > 0 && failedTests.length
                              }
                            />
                          </span>
                          <span
                            style={{
                              flexGrow: 1,
                              maxWidth: '40%',
                              textTransform: 'capitalize',
                              paddingLeft: 0
                            }}
                          >
                            <strong>{mapTestResult(p.result)}</strong>
                            <p>{failedTests.map(p => p.name).join()}</p>
                          </span>
                          <span style={{ width: '15.5%' }}>
                            <Formatted24hTime value={p.start} timeZone="UTC" />
                          </span>
                          <span style={{ width: '15.5%' }}>
                            <FormattedDate value={p.start} timeZone="UTC" />
                          </span>
                          <Can
                            I={Device.VIEW_TEST_DETAILS}
                            this={new Device(device.deviceType)}
                          >
                            <span style={{ width: '14.5%' }}>
                              <Link
                                className="card-link"
                                to={PathJoin([
                                  routePaths.DeviceOverview.path,
                                  device.id,
                                  routePaths.DeviceTestOverview.path,
                                  p.id
                                ])}
                              >
                                <FormattedMessage id="common.view" />
                                <Icon name="arrow-right-full" size="sm" />
                              </Link>
                            </span>
                          </Can>
                        </section>
                      </ListItem>
                    );
                  })}
              </List>
              <div className="d-flex justify-content-between mt-2">
                <Link
                  data-cy="viewAllSystemCheckoutLink"
                  className="card-link"
                  to={PathJoin([
                    routePaths.DeviceOverview.path,
                    device.id,
                    routePaths.DeviceTestOverview.path
                  ])}
                >
                  <FormattedMessage
                    id="common.viewAll"
                    defaultMessage="View all"
                  />
                  <Icon name="arrow-right-full" size="sm" />
                </Link>
                {!isRotaflowFamily(device.deviceType) && (
                  <Link
                    data-cy="downloadTestLogsLink"
                    onClick={getOpenModalForLogType(downloadLogType)}
                    to="#"
                  >
                    <FormattedMessage
                      id="common.download"
                      defaultMessage="Download"
                    />
                    <Icon name="download" size="sm" />
                  </Link>
                )}
              </div>
            </>
          )) || <EmptyState />}
        </CardSection>
      </WithLoadingSpinner>
    </Card>
  );
};

export default DeviceTestListWidget;
