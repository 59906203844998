import { SET_FILTERS } from '../actions/filters';

const initialStateFilters = {
  accounts: [],
  departments: [],
  deviceTypes: [],
  adminAccounts: [],
  adminDeviceTypes: [],
  adminSerialNumber: '',
  adminSoftwareVersion: ''
};

export function filtersReducer(state = initialStateFilters, action) {
  switch (action.type) {
    case SET_FILTERS:
      return { ...state, ...action.payload };

    default:
      return state;
  }
}
