import { SET_DEVICES_RUNNING_HOURS } from '../actions/devicesRunningHours';

export function devicesRunningHoursReducer(
  state = {
    devicesRunningHours: {}
  },
  action
) {
  switch (action.type) {
    case SET_DEVICES_RUNNING_HOURS:
      return {
        ...state,
        devicesRunningHours: action.payload
      };

    default:
      return state;
  }
}
