export const entity = '[Device]';
export const FETCH_DEVICE = `${entity} Fetch`;
export const PUT_DEVICE = `${entity} Put`;
export const SET_DEVICE = `${entity} Set`;

/**
 * Trigger fetch of device information
 */
export const fetchDevice = deviceId => ({
  type: FETCH_DEVICE,
  id: deviceId
});

/**
 * Trigger update of device information
 */
export const putDevice = device => ({
  type: PUT_DEVICE,
  payload: {
    id: device.id,
    customerDeviceId: device.customerDeviceId || '',
    location: device.location || ''
  }
});

/**
 * Set data as new device information
 * @param {array} data New device information
 */
export const setDevice = data => ({
  type: SET_DEVICE,
  payload: data
});
