import React, { useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { routePaths, Icon } from '../..';
import { useSafeListener } from '../../../hooks';

import styles from './UserDropdown.module.scss';

const UserDropdown = ({ user, isAdmin, onLogout }) => {
  const [open, setOpen] = useState(false);

  const handleDocumentClick = () => (open ? setOpen(false) : null);
  const handleDropdownClick = useCallback(() => {
    setOpen(!open);
  }, [open]);

  const handleLogout = useCallback(
    e => {
      e.preventDefault();
      onLogout();
    },
    [onLogout]
  );

  useSafeListener('click', handleDocumentClick);
  return (
    <div className={styles.gtgUserDropdown + ' dropdown ml-auto'}>
      <button
        className={styles.gtgUserDropdownBtn + ' btn dropdown-toggle'}
        type="button"
        aria-haspopup="true"
        aria-expanded={open}
        id="userDropdownMenuButton"
        onClick={handleDropdownClick}
        data-cy="userDropdownButton"
      >
        <Icon name="user-2" size="sm" />
        {user ? user : 'Profile Name'}
      </button>
      <div
        className={[
          'dropdown-menu',
          'dropdown-menu-right',
          open ? 'show' : ''
        ].join(' ')}
        aria-labelledby="userDropdownMenuButton"
      >
        <Link
          to={'/' + routePaths.Profile.path}
          className="dropdown-item"
          data-cy="userDropdownProfileItem"
        >
          <FormattedMessage id="common.profile" defaultMessage="Profile" />
        </Link>
        {isAdmin && (
          <Link
            to={'/' + routePaths.Admin.path}
            className="dropdown-item"
            data-cy="userDropdownAdminPortalItem"
          >
            <FormattedMessage
              id="common.adminPortal"
              defaultMessage="Admin portal"
            />
          </Link>
        )}
        <Link
          to="#"
          onClick={handleLogout}
          className="dropdown-item"
          data-cy="userDropdownLogoutItem"
        >
          <FormattedMessage id="common.logout" defaultMessage="Logout" />
        </Link>
      </div>
    </div>
  );
};

UserDropdown.propTypes = {
  isAdmin: PropTypes.bool,
  user: PropTypes.string,
  onLogout: PropTypes.func
};

export default UserDropdown;
