export const serviceOrdersListColumns = [
  { header: 'Status', key: 'status', width: 20 },
  { header: 'Hospital', key: 'hospital', width: 40 },
  { header: 'Department', key: 'departmentName', width: 40 },
  { header: 'Device', key: 'deviceType', width: 40 },
  { header: 'Serial no', key: 'serial', width: 20 },
  { header: 'Type', key: 'type', width: 20 },
  { header: 'Order created', key: 'created', width: 20 },
  { header: 'Order no', key: 'order', width: 20 },
  { header: 'Description', key: 'description', width: 40 },
  { header: 'Technician', key: 'technician', width: 40 },
  { header: 'Notification no', key: 'notification', width: 20 },
  { header: 'Department filter selection', key: 'department', width: 40 },
  { header: 'Device filter selection', key: 'device', width: 40 }
];

export const serviceOrdersListFileName = 'Service_orders.xlsx';
