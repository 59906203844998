export const softwareVersionFilter = inputVal => {
  // Noop, all items are returned
  if (inputVal.trim() === '') return () => true;

  // Parse up to four version fragments, remove leading zeroes from each fragment
  let reg = /([^.]+\.)?([^.]+\.)?([^.]+\.)?([^.]+)/;
  let fragments = reg.exec(inputVal).reduce((prev, curr, i) => {
    if (i === 0) {
      return prev;
    }

    if (curr) {
      return [...prev, curr.replace('.', '')];
    }

    return prev;
  }, []);

  // Create a new regex that will be used to match with
  let newReg = fragments.reduce((prev, fragment, i) => {
    if (fragment[0] === '0') {
      fragment = fragment.slice(1);
    }
    fragment = fragment.replace('.', '\\.');

    if (fragments.length === i + 1) {
      return prev + `(C?0?${fragment}).*`;
    }

    return prev + `(C?0?${fragment}\\.)`;
  }, '^');
  const r = new RegExp(newReg, 'iu');

  // Return a filter function used to test against the regex
  return item => {
    return r.test(item.label);
  };
};
