import React from 'react';
import moment from 'moment';
import { FormattedDate } from 'react-intl';
import { shortMonthLongYear } from './TimeFormat';

function MetricsTimeParams(metricType, timeSpan, count) {
  let resolution;
  let { compareStart, start, end } = getStartAndEndData(
    metricType,
    timeSpan,
    count
  );

  switch (timeSpan) {
    case 'week':
    case 'month':
      resolution = '1day';
      break;
    case 'year':
    case 'y2y':
      resolution = '1month';
      break;
    // no default
  }
  let paramsObj = {
    ...(metricType === 'trends' && { compareStart: compareStart.format() }),
    start: start.utcOffset(0, true).format(),
    end: end.utcOffset(0, true).format(),
    ...((metricType === 'metrics' ||
      metricType === 'usageStatisticsDetailed') && { resolution })
  };

  return (
    '?' +
    Object.keys(paramsObj)
      .map(function(key) {
        return key + '=' + paramsObj[key];
      })
      .join('&')
  );
}

export function getStartAndEndData(metricType, timespan, count) {
  // Current month and year
  const month = moment.utc().month();
  const year = moment.utc().year();

  // Mostly we want to start on the first and end on the last in month
  let start = moment.utc().startOf('month');
  let end = moment.utc().endOf('month');

  // compareStart is used only for trends comparison of [compareStart, start) to [start, end]
  let compareStart = moment.utc([year, month, 1]);
  let label, subLabel;

  switch (timespan) {
    case 'week':
      // We override the default
      compareStart = moment.utc().startOf('day');
      start = moment.utc().startOf('day');
      end = moment.utc().endOf('day');

      // We want total 7 days interval from current date
      compareStart.subtract(count + 2, 'week').add(1, 'days');
      start.subtract(count + 1, 'week');
      start.add(1, 'days');
      end.subtract(count, 'week');

      label = (
        <>
          <FormattedDate timeZone="utc" value={start.toDate()} />
          {' - '}
          <FormattedDate timeZone="utc" value={end.toDate()} />
        </>
      );
      break;
    case 'month':
      compareStart.subtract(count + 1, 'month');
      // Since we already have a range of (1/12/2020 - 31/12/2020), we just need to shift that selection up or down
      // and adjust to start or end of month
      start.subtract(count, 'month').startOf('month');
      end.subtract(count, 'month').endOf('month');

      label = (
        <FormattedDate
          timeZone="utc"
          value={start.toDate()}
          {...shortMonthLongYear}
        />
      );
      break;
    case 'year':
      compareStart.subtract(count + 2, 'year').add(1, 'month');
      // We want a one year interval (1/12/2019 - 31/12/2020)
      start.subtract(count + 1, 'year').add(1, 'month');
      end.subtract(count, 'year');
      label = (
        <>
          <FormattedDate
            value={start.toDate()}
            timeZone="utc"
            {...shortMonthLongYear}
          />
          {' - '}
          <FormattedDate
            value={end.toDate()}
            timeZone="utc"
            {...shortMonthLongYear}
          />
        </>
      );
      break;
    case 'y2y':
      compareStart.subtract(count + 2, 'year').add(1, 'month');

      // We want a two year interval (1/12/2018 - 31/12/2020)
      start.subtract(count + 2, 'year').add(1, 'month');
      end.subtract(count, 'year');

      // Label holds the current year
      label = (
        <>
          <FormattedDate
            value={moment
              .utc(start)
              .add(1, 'year')
              .toDate()}
            timeZone="utc"
            {...shortMonthLongYear}
          />
          {' - '}
          <FormattedDate
            value={end.toDate()}
            timeZone="utc"
            {...shortMonthLongYear}
          />
        </>
      );
      // SubLabel holds the previous year
      subLabel = (
        <>
          <FormattedDate
            value={start.toDate()}
            timeZone="utc"
            {...shortMonthLongYear}
          />
          {' - '}
          <FormattedDate
            value={moment
              .utc(end)
              .subtract(1, 'year')
              .toDate()}
            timeZone="utc"
            {...shortMonthLongYear}
          />
        </>
      );

      // For trends we want to compare the 2018,2019 to 2019-2020
      if (metricType === 'trends') {
        start.add(1, 'year');
      }
      break;
    // no default
  }

  return {
    ...(metricType === 'trends' && { compareStart }),
    start,
    end,
    label,
    subLabel
  };
}

export default MetricsTimeParams;
