import round from 'lodash.round';
import padend from 'lodash.padend';
//
// Handle mapping of units for display purposes
//

export function roundToFixed(value, decimals) {
  const rounded = round(value, decimals);

  let [roundedInteger, roundedDecimals] = rounded.toString().split('.');

  if (decimals !== (roundedDecimals || []).length) {
    roundedDecimals = padend(roundedDecimals, decimals, '0');
  }

  return roundedDecimals
    ? `${roundedInteger}.${roundedDecimals}`
    : `${roundedInteger}`;
}

export function consolidateUnit(unit = '') {
  if (!unit) return unit;

  const [left, right] = unit.split('/');

  if (right) {
    return [left, right].map(consolidateUnit).join('/');
  } else {
    switch (unit.toLowerCase()) {
      case 'l':
      case 'liters':
      case 'liter':
        return 'L';
      case 'ml':
        return 'mL';
      case 'cmh2o':
        return 'cmH2O';
      case 's':
      case 'sec':
        return 's';
      case 'mv':
        return 'mV';
      case 'ma':
        return 'mA';
      case 'hpa':
        return 'hPa';
      case 'hours':
        return 'h';
      default:
        return unit;
    }
  }
}

export function convertValueUnit(value, unit) {
  if (!unit) return { value, unit };

  const [left, right] = unit.split('/');

  if (left === 'mL' && value > 1000) {
    return {
      value: value / 1000,
      unit: right ? ['L', right].join('/') : 'L'
    };
  }

  return { value, unit };
}

export function getDecimalPlaces(key, isAgentUsagePerDevice) {
  switch (key && key.toLowerCase()) {
    case 'metrics':
    case 'metric':
    case 'currency':
    case 'ventilationmodes':
    case 'macbrain':
    case 'macy':
    case 'capacity':
    case 'carbon_footprint':
      return 2;
    case 'avg_fgf':
    case 'freshgasflow':
      return 1;
    case 'sevoflurane':
    case 'isoflurane':
      if (isAgentUsagePerDevice) {
        return 1;
      } else {
        return 0;
      }
    case 'agent':
    case 'n2o':
    case 'runninghours':
    case 'trends':
    case 'cases':
    default:
      return 0;
  }
}

export function formatValueUnit(
  value,
  unit,
  key,
  isAgentUsagePerDevice = false
) {
  const { value: newValue, unit: newUnit } = convertValueUnit(
    value,
    consolidateUnit(unit)
  );

  const valueIsRange =
    typeof newValue === 'string' &&
    ['/', '-'].find(delimiter => newValue.search(delimiter) !== -1) !==
      undefined;

  // if value is 0 or if there is no unit set, let decimal places default to 0
  const roundedValue = !valueIsRange
    ? roundToFixed(
        newValue,
        getDecimalPlaces(
          newValue && key,
          newUnit === 'L' && isAgentUsagePerDevice
        )
      )
    : newValue;

  // Do not create space before these units
  const noSpaceUnits = ['%'];

  // no space before noSpaceUnits, non-breaking space otherwise
  const separator = noSpaceUnits.includes(newUnit) ? '' : '\u00A0';

  const formattedValueUnit = [roundedValue, newUnit]
    .filter(v => v)
    .join(separator);

  return formattedValueUnit;
}
