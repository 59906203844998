import React, { useState, useCallback } from 'react';
import propTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { WithLoadingSpinner } from '../../hoc/';
import get from 'lodash.get';
import {
  CardSectionColumn,
  EmptyState,
  List,
  ListItem,
  EditButton,
  AgentCostEntryModal,
  formatValueUnit
} from '..';
import { currencyShape } from '../Cases/AgentCostEntryModal/AgentCostEntryModal';

const Averages = ({ metricsUsage, costs, setData }) => {
  const [mappedAverages, setMappedAverages] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const openModal = useCallback(() => setModalOpen(true), []);
  const closeModal = useCallback(() => setModalOpen(false), []);

  const costsCurrencySymbol = get(costs, 'currency.symbol');
  let casesAverage = get(metricsUsage, 'averages.casesAverage');

  React.useEffect(() => {
    if (metricsUsage && metricsUsage.averages) {
      const mapped = {
        casesAverage: casesAverage
          ? formatValueUnit(casesAverage, 'mL/min', 'metrics')
          : undefined,
        costPerMin:
          typeof metricsUsage.averages.costPerMin === 'number' &&
          costsCurrencySymbol
            ? formatValueUnit(
                metricsUsage.averages.costPerMin,
                costsCurrencySymbol,
                'currency'
              )
            : undefined,
        costPerCase:
          typeof metricsUsage.averages.costPerCase === 'number' &&
          costsCurrencySymbol
            ? formatValueUnit(
                metricsUsage.averages.costPerCase,
                costsCurrencySymbol,
                'currency'
              )
            : undefined,
        casesTotal:
          typeof metricsUsage.averages.casesTotal === 'number'
            ? metricsUsage.averages.casesTotal
            : undefined,
        casesAgc:
          metricsUsage.averages.casesAgc > 0
            ? metricsUsage.averages.casesAgc
            : undefined
      };
      if (setData) {
        setData(mapped);
      }
      setMappedAverages(mapped);
    }
  }, [
    metricsUsage,
    setData,
    setMappedAverages,
    casesAverage,
    costsCurrencySymbol
  ]);

  return (
    <>
      <CardSectionColumn
        titleLeft
        title={
          <FormattedMessage
            id="usageStatistics.averages"
            defaultMessage="Averages"
          />
        }
      >
        <WithLoadingSpinner when={['metricsUsage', 'costs']}>
          {(metricsUsage && metricsUsage.averages && (
            <div className="mt-3">
              <List>
                <ListItem>
                  <FormattedMessage
                    id="usageStatistics.caseAverage"
                    defaultMessage="Case average"
                  />
                  {(mappedAverages.casesAverage && (
                    <span>{mappedAverages.casesAverage}</span>
                  )) || (
                    <FormattedMessage
                      id="common.notAvailableAbbr"
                      defaultMessage="N/A"
                    />
                  )}
                </ListItem>
                <ListItem>
                  <FormattedMessage
                    id="usageStatistics.costPerMin"
                    defaultMessage="Cost per min"
                  />
                  {(mappedAverages.costPerMin && (
                    <span>{mappedAverages.costPerMin}</span>
                  )) || (
                    <FormattedMessage
                      id="common.notAvailableAbbr"
                      defaultMessage="N/A"
                    />
                  )}
                </ListItem>
                <ListItem>
                  <FormattedMessage
                    id="usageStatistics.costPerCase"
                    defaultMessage="Cost per case"
                  />
                  {(mappedAverages.costPerCase && (
                    <span>{mappedAverages.costPerCase}</span>
                  )) || (
                    <FormattedMessage
                      id="common.notAvailableAbbr"
                      defaultMessage="N/A"
                    />
                  )}
                </ListItem>
                <ListItem>
                  {// if AGC was not used in any of the cases only show total cases
                  // GDP-1462
                  (mappedAverages.casesAgc && (
                    <FormattedMessage
                      id="usageStatistics.agcCasePerTotalCases"
                      defaultMessage="AGC case/Total"
                    />
                  )) || (
                    <FormattedMessage
                      id="usageStatistics.totalCases"
                      defaultMessage="Total cases"
                    />
                  )}
                  {(mappedAverages.casesAgc && mappedAverages.casesTotal && (
                    <span>
                      {mappedAverages.casesAgc}/{mappedAverages.casesTotal}
                    </span>
                  )) ||
                    (typeof mappedAverages.casesTotal != 'undefined' && (
                      <span>{mappedAverages.casesTotal}</span>
                    )) || (
                      <FormattedMessage
                        id="common.notAvailableAbbr"
                        defaultMessage="N/A"
                      />
                    )}
                </ListItem>
              </List>
              <div className="mt-3 text-center">
                <EditButton onClick={openModal} />
              </div>
            </div>
          )) || <EmptyState />}
        </WithLoadingSpinner>
      </CardSectionColumn>
      {modalOpen && <AgentCostEntryModal onDone={closeModal} costs={costs} />}
    </>
  );
};

Averages.propTypes = {
  metricsUsage: propTypes.shape({
    averages: propTypes.shape({
      casesAgc: propTypes.number,
      casesTotal: propTypes.number,
      casesAverage: propTypes.number,
      costPerCase: propTypes.number,
      costPerMin: propTypes.number
    })
  }),
  costs: propTypes.shape({
    currency: currencyShape,
    sev: propTypes.number,
    des: propTypes.number,
    iso: propTypes.number
  }),
  setData: propTypes.func
};

export default Averages;
