export const saveBlob = (blob, filename) => {
  if (window.navigator.msSaveBlob !== undefined) {
    window.navigator.msSaveBlob(blob, filename);
    return;
  }

  var a = document.createElement('a');
  document.body.appendChild(a);
  a.style = 'display: none';

  var url = window.URL.createObjectURL(blob);
  a.href = url;
  a.download = filename;
  // setTimeout is a workaround for an issue with redux that caused an error when
  // a.click was called. Pushing a.click to the next event loop iteration works around
  // the issue.
  setTimeout(() => {
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  });
};
