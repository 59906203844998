import { addLocaleData } from 'react-intl';

import locale_af from 'react-intl/locale-data/af';
import locale_agq from 'react-intl/locale-data/agq';
import locale_ak from 'react-intl/locale-data/ak';
import locale_am from 'react-intl/locale-data/am';
import locale_ar from 'react-intl/locale-data/ar';
import locale_as from 'react-intl/locale-data/as';
import locale_asa from 'react-intl/locale-data/asa';
import locale_az from 'react-intl/locale-data/az';
import locale_bas from 'react-intl/locale-data/bas';
import locale_be from 'react-intl/locale-data/be';
import locale_bem from 'react-intl/locale-data/bem';
import locale_bez from 'react-intl/locale-data/bez';
import locale_bg from 'react-intl/locale-data/bg';
import locale_bm from 'react-intl/locale-data/bm';
import locale_bn from 'react-intl/locale-data/bn';
import locale_bo from 'react-intl/locale-data/bo';
import locale_br from 'react-intl/locale-data/br';
import locale_brx from 'react-intl/locale-data/brx';
import locale_bs from 'react-intl/locale-data/bs';
import locale_ca from 'react-intl/locale-data/ca';
import locale_cgg from 'react-intl/locale-data/cgg';
import locale_chr from 'react-intl/locale-data/chr';
import locale_cs from 'react-intl/locale-data/cs';
import locale_cy from 'react-intl/locale-data/cy';
import locale_da from 'react-intl/locale-data/da';
import locale_dav from 'react-intl/locale-data/dav';
import locale_de from 'react-intl/locale-data/de';
import locale_dje from 'react-intl/locale-data/dje';
import locale_dua from 'react-intl/locale-data/dua';
import locale_dyo from 'react-intl/locale-data/dyo';
import locale_ebu from 'react-intl/locale-data/ebu';
import locale_ee from 'react-intl/locale-data/ee';
import locale_el from 'react-intl/locale-data/el';
import locale_en from 'react-intl/locale-data/en';
import locale_eo from 'react-intl/locale-data/eo';
import locale_es from 'react-intl/locale-data/es';
import locale_et from 'react-intl/locale-data/et';
import locale_eu from 'react-intl/locale-data/eu';
import locale_ewo from 'react-intl/locale-data/ewo';
import locale_fa from 'react-intl/locale-data/fa';
import locale_ff from 'react-intl/locale-data/ff';
import locale_fi from 'react-intl/locale-data/fi';
import locale_fil from 'react-intl/locale-data/fil';
import locale_fo from 'react-intl/locale-data/fo';
import locale_fr from 'react-intl/locale-data/fr';
import locale_ga from 'react-intl/locale-data/ga';
import locale_gl from 'react-intl/locale-data/gl';
import locale_gsw from 'react-intl/locale-data/gsw';
import locale_gu from 'react-intl/locale-data/gu';
import locale_guz from 'react-intl/locale-data/guz';
import locale_gv from 'react-intl/locale-data/gv';
import locale_ha from 'react-intl/locale-data/ha';
import locale_haw from 'react-intl/locale-data/haw';
import locale_he from 'react-intl/locale-data/he';
import locale_hi from 'react-intl/locale-data/hi';
import locale_hr from 'react-intl/locale-data/hr';
import locale_hu from 'react-intl/locale-data/hu';
import locale_hy from 'react-intl/locale-data/hy';
import locale_id from 'react-intl/locale-data/id';
import locale_ig from 'react-intl/locale-data/ig';
import locale_ii from 'react-intl/locale-data/ii';
import locale_is from 'react-intl/locale-data/is';
import locale_it from 'react-intl/locale-data/it';
import locale_ja from 'react-intl/locale-data/ja';
import locale_jmc from 'react-intl/locale-data/jmc';
import locale_ka from 'react-intl/locale-data/ka';
import locale_kab from 'react-intl/locale-data/kab';
import locale_kam from 'react-intl/locale-data/kam';
import locale_kde from 'react-intl/locale-data/kde';
import locale_kea from 'react-intl/locale-data/kea';
import locale_khq from 'react-intl/locale-data/khq';
import locale_ki from 'react-intl/locale-data/ki';
import locale_kk from 'react-intl/locale-data/kk';
import locale_kl from 'react-intl/locale-data/kl';
import locale_kln from 'react-intl/locale-data/kln';
import locale_km from 'react-intl/locale-data/km';
import locale_kn from 'react-intl/locale-data/kn';
import locale_ko from 'react-intl/locale-data/ko';
import locale_kok from 'react-intl/locale-data/kok';
import locale_ksb from 'react-intl/locale-data/ksb';
import locale_ksf from 'react-intl/locale-data/ksf';
import locale_kw from 'react-intl/locale-data/kw';
import locale_lag from 'react-intl/locale-data/lag';
import locale_lg from 'react-intl/locale-data/lg';
import locale_ln from 'react-intl/locale-data/ln';
import locale_lt from 'react-intl/locale-data/lt';
import locale_lu from 'react-intl/locale-data/lu';
import locale_luo from 'react-intl/locale-data/luo';
import locale_luy from 'react-intl/locale-data/luy';
import locale_lv from 'react-intl/locale-data/lv';
import locale_mas from 'react-intl/locale-data/mas';
import locale_mer from 'react-intl/locale-data/mer';
import locale_mfe from 'react-intl/locale-data/mfe';
import locale_mg from 'react-intl/locale-data/mg';
import locale_mgh from 'react-intl/locale-data/mgh';
import locale_mk from 'react-intl/locale-data/mk';
import locale_ml from 'react-intl/locale-data/ml';
import locale_mr from 'react-intl/locale-data/mr';
import locale_ms from 'react-intl/locale-data/ms';
import locale_mt from 'react-intl/locale-data/mt';
import locale_mua from 'react-intl/locale-data/mua';
import locale_my from 'react-intl/locale-data/my';
import locale_naq from 'react-intl/locale-data/naq';
import locale_nb from 'react-intl/locale-data/nb';
import locale_nd from 'react-intl/locale-data/nd';
import locale_ne from 'react-intl/locale-data/ne';
import locale_nl from 'react-intl/locale-data/nl';
import locale_nmg from 'react-intl/locale-data/nmg';
import locale_nn from 'react-intl/locale-data/nn';
import locale_nus from 'react-intl/locale-data/nus';
import locale_nyn from 'react-intl/locale-data/nyn';
import locale_om from 'react-intl/locale-data/om';
import locale_or from 'react-intl/locale-data/or';
import locale_pa from 'react-intl/locale-data/pa';
import locale_pl from 'react-intl/locale-data/pl';
import locale_ps from 'react-intl/locale-data/ps';
import locale_pt from 'react-intl/locale-data/pt';
import locale_rm from 'react-intl/locale-data/rm';
import locale_rn from 'react-intl/locale-data/rn';
import locale_ro from 'react-intl/locale-data/ro';
import locale_rof from 'react-intl/locale-data/rof';
import locale_ru from 'react-intl/locale-data/ru';
import locale_rw from 'react-intl/locale-data/rw';
import locale_rwk from 'react-intl/locale-data/rwk';
import locale_saq from 'react-intl/locale-data/saq';
import locale_sbp from 'react-intl/locale-data/sbp';
import locale_seh from 'react-intl/locale-data/seh';
import locale_ses from 'react-intl/locale-data/ses';
import locale_sg from 'react-intl/locale-data/sg';
import locale_shi from 'react-intl/locale-data/shi';
import locale_si from 'react-intl/locale-data/si';
import locale_sk from 'react-intl/locale-data/sk';
import locale_sl from 'react-intl/locale-data/sl';
import locale_sn from 'react-intl/locale-data/sn';
import locale_so from 'react-intl/locale-data/so';
import locale_sq from 'react-intl/locale-data/sq';
import locale_sr from 'react-intl/locale-data/sr';
import locale_sv from 'react-intl/locale-data/sv';
import locale_sw from 'react-intl/locale-data/sw';
import locale_ta from 'react-intl/locale-data/ta';
import locale_te from 'react-intl/locale-data/te';
import locale_teo from 'react-intl/locale-data/teo';
import locale_th from 'react-intl/locale-data/th';
import locale_ti from 'react-intl/locale-data/ti';
import locale_to from 'react-intl/locale-data/to';
import locale_tr from 'react-intl/locale-data/tr';
import locale_twq from 'react-intl/locale-data/twq';
import locale_tzm from 'react-intl/locale-data/tzm';
import locale_uk from 'react-intl/locale-data/uk';
import locale_ur from 'react-intl/locale-data/ur';
import locale_uz from 'react-intl/locale-data/uz';
import locale_vai from 'react-intl/locale-data/vai';
import locale_vi from 'react-intl/locale-data/vi';
import locale_vun from 'react-intl/locale-data/vun';
import locale_xog from 'react-intl/locale-data/xog';
import locale_yav from 'react-intl/locale-data/yav';
import locale_yo from 'react-intl/locale-data/yo';
import locale_zh from 'react-intl/locale-data/zh';
import locale_zu from 'react-intl/locale-data/zu';

const locales = [
  ...locale_af,
  ...locale_agq,
  ...locale_ak,
  ...locale_am,
  ...locale_ar,
  ...locale_as,
  ...locale_asa,
  ...locale_az,
  ...locale_bas,
  ...locale_be,
  ...locale_bem,
  ...locale_bez,
  ...locale_bg,
  ...locale_bm,
  ...locale_bn,
  ...locale_bo,
  ...locale_br,
  ...locale_brx,
  ...locale_bs,
  ...locale_ca,
  ...locale_cgg,
  ...locale_chr,
  ...locale_cs,
  ...locale_cy,
  ...locale_da,
  ...locale_dav,
  ...locale_de,
  ...locale_dje,
  ...locale_dua,
  ...locale_dyo,
  ...locale_ebu,
  ...locale_ee,
  ...locale_el,
  ...locale_en,
  ...locale_eo,
  ...locale_es,
  ...locale_et,
  ...locale_eu,
  ...locale_ewo,
  ...locale_fa,
  ...locale_ff,
  ...locale_fi,
  ...locale_fil,
  ...locale_fo,
  ...locale_fr,
  ...locale_ga,
  ...locale_gl,
  ...locale_gsw,
  ...locale_gu,
  ...locale_guz,
  ...locale_gv,
  ...locale_ha,
  ...locale_haw,
  ...locale_he,
  ...locale_hi,
  ...locale_hr,
  ...locale_hu,
  ...locale_hy,
  ...locale_id,
  ...locale_ig,
  ...locale_ii,
  ...locale_is,
  ...locale_it,
  ...locale_ja,
  ...locale_jmc,
  ...locale_ka,
  ...locale_kab,
  ...locale_kam,
  ...locale_kde,
  ...locale_kea,
  ...locale_khq,
  ...locale_ki,
  ...locale_kk,
  ...locale_kl,
  ...locale_kln,
  ...locale_km,
  ...locale_kn,
  ...locale_ko,
  ...locale_kok,
  ...locale_ksb,
  ...locale_ksf,
  ...locale_kw,
  ...locale_lag,
  ...locale_lg,
  ...locale_ln,
  ...locale_lt,
  ...locale_lu,
  ...locale_luo,
  ...locale_luy,
  ...locale_lv,
  ...locale_mas,
  ...locale_mer,
  ...locale_mfe,
  ...locale_mg,
  ...locale_mgh,
  ...locale_mk,
  ...locale_ml,
  ...locale_mr,
  ...locale_ms,
  ...locale_mt,
  ...locale_mua,
  ...locale_my,
  ...locale_naq,
  ...locale_nb,
  ...locale_nd,
  ...locale_ne,
  ...locale_nl,
  ...locale_nmg,
  ...locale_nn,
  ...locale_nus,
  ...locale_nyn,
  ...locale_om,
  ...locale_or,
  ...locale_pa,
  ...locale_pl,
  ...locale_ps,
  ...locale_pt,
  ...locale_rm,
  ...locale_rn,
  ...locale_ro,
  ...locale_rof,
  ...locale_ru,
  ...locale_rw,
  ...locale_rwk,
  ...locale_saq,
  ...locale_sbp,
  ...locale_seh,
  ...locale_ses,
  ...locale_sg,
  ...locale_shi,
  ...locale_si,
  ...locale_sk,
  ...locale_sl,
  ...locale_sn,
  ...locale_so,
  ...locale_sq,
  ...locale_sr,
  ...locale_sv,
  ...locale_sw,
  ...locale_ta,
  ...locale_te,
  ...locale_teo,
  ...locale_th,
  ...locale_ti,
  ...locale_to,
  ...locale_tr,
  ...locale_twq,
  ...locale_tzm,
  ...locale_uk,
  ...locale_ur,
  ...locale_uz,
  ...locale_vai,
  ...locale_vi,
  ...locale_vun,
  ...locale_xog,
  ...locale_yav,
  ...locale_yo,
  ...locale_zh,
  ...locale_zu
];

addLocaleData(locales);
