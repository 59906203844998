import { FETCH_PROFILE, SET_PROFILE } from '../actions/profile';

const initialState = {
  profile: null
};

export function profileReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_PROFILE:
      return {
        ...state,
        profile: null
      };
    case SET_PROFILE:
      return {
        ...state,
        profile: action.payload
      };
    default:
      return state;
  }
}
