import { SET_CONFIG } from '../actions/config';
import { parseAsBoolValues } from '../../utils/featureFlags/parseAsBoolValues';

const initialStateConfig = null;

export function configReducer(state = initialStateConfig, action) {
  switch (action.type) {
    case SET_CONFIG:
      return {
        ...state,
        ...parseAsBoolValues(action.payload)
      };
    default:
      return state;
  }
}
