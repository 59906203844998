export const entity = '[Document]';
export const FETCH_DOCUMENT = `${entity} Fetch`;

/**
 * Trigger fetch of documents
 */
export const fetchDocument = id => ({
  type: FETCH_DOCUMENT,
  payload: { id }
});
