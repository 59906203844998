import React from 'react';
import { Icon } from '..';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';
import styles from './DevicesStatusOverview.module.scss';
import PropTypes from 'prop-types';
import get from 'lodash.get';

const DevicesStatusOverviewTimeUntilNextPM = props => {
  const timeUntilNextPM = get(
    props,
    'devicesStatusOverview.timeUntilNextPM',
    -1
  );
  if (timeUntilNextPM === -1 || typeof timeUntilNextPM !== 'number') {
    return (
      <p className={styles.gtgDevicesStatusOverviewNextPM + ' disabled'}>
        <span>N/A</span>
      </p>
    );
  }

  const daysToWarning = 30;
  return (
    <React.Fragment>
      <p className={styles.gtgDevicesStatusOverviewNextPM}>
        {props.devicesStatusOverview.timeUntilNextPM <= 0 ? (
          <Icon name="error-outline" />
        ) : props.devicesStatusOverview.timeUntilNextPM > 0 &&
          props.devicesStatusOverview.timeUntilNextPM <= daysToWarning ? (
          <Icon name="warning-outline" />
        ) : (
          <Icon name="success-outline" />
        )}
        <span>
          {props.devicesStatusOverview.timeUntilNextPM >= 0
            ? props.devicesStatusOverview.timeUntilNextPM
            : 0}
          {props.devicesStatusOverview.timeUntilNextPM === 1 ? (
            <FormattedMessage id="common.day" defaultMessage="day" />
          ) : (
            <FormattedMessage id="common.days" defaultMessage="days" />
          )}
        </span>
      </p>
    </React.Fragment>
  );
};

DevicesStatusOverviewTimeUntilNextPM.propTypes = {
  intl: intlShape,
  devicesStatusOverview: PropTypes.shape({})
};

export default injectIntl(DevicesStatusOverviewTimeUntilNextPM);
