import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

const DownloadModalDescription = ({ downloadStatus }) => {
  switch (downloadStatus) {
    default:
    case 'inprogress':
      return (
        <FormattedMessage
          id="logs.downloadInProgress"
          defaultMessage="Your file is downloading. This might take a few minutes."
        />
      );
    case 'error':
      return (
        <FormattedMessage
          id="logs.downloadFailed"
          defaultMessage="There was a problem downloading the file."
        />
      );
    case 'success':
      return (
        <FormattedMessage
          id="logs.downloadSuccess"
          defaultMessage="Download successful."
        />
      );
  }
};

DownloadModalDescription.propTypes = {
  downloadStatus: PropTypes.string.isRequired
};

export default DownloadModalDescription;
