import PropTypes from 'prop-types';

export const preventiveMaintenanceShape = PropTypes.shape({
  device: PropTypes.shape({
    department: PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.string
    }),
    customerDeviceID: PropTypes.string,
    id: PropTypes.string,
    serialID: PropTypes.string,
    type: PropTypes.string
  }),
  last: PropTypes.string,
  lastType: PropTypes.string,
  next: PropTypes.string,
  nextDue: PropTypes.number,
  runningHoursSince: PropTypes.number,
  maxRunningHours: PropTypes.number,
  status: PropTypes.string,
  battery: PropTypes.shape({
    memoryBackup: PropTypes.shape({
      last: PropTypes.string,
      next: PropTypes.string,
      status: PropTypes.string
    }),
    powerBackup: PropTypes.shape({
      last: PropTypes.string,
      next: PropTypes.string,
      status: PropTypes.string
    })
  }),
  cassetteMembrane: PropTypes.shape({
    last: PropTypes.string,
    strokes: PropTypes.number,
    status: PropTypes.string
  }),
  batteries: PropTypes.arrayOf(
    PropTypes.shape({
      manufacturingDate: PropTypes.string,
      nextDate: PropTypes.string,
      serialNumber: PropTypes.string,
      slot: PropTypes.string,
      status: PropTypes.oneOf(['OK', 'WARNING', 'ALERT'])
    })
  )
});
