export const entity = '[Device preventive maintenance]';
export const FETCH_DEVICE_PREVENTIVE_MAINTENANCE = `${entity} Fetch`;
export const SET_DEVICE_PREVENTIVE_MAINTENANCE = `${entity} Set`;

/**
 * Trigger fetch of device preventive maintenance
 */
export const fetchDevicePreventiveMaintenance = deviceId => ({
  type: FETCH_DEVICE_PREVENTIVE_MAINTENANCE,
  payload: {
    deviceId: deviceId
  }
});

/**
 * Set data as new device preventive maintenance
 * @param {array} data New device preventive maintenance
 */
export const setDevicePreventiveMaintenance = data => ({
  type: SET_DEVICE_PREVENTIVE_MAINTENANCE,
  payload: data
});
