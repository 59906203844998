import React, { useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import { FormattedDate, FormattedMessage } from 'react-intl';
import {
  routePaths,
  StatusIcon,
  EmptyState,
  Formatted24hTime,
  getDeviceTypeDisplayName,
  DataTableFixedColumnsAndHeader,
  Icon,
  subscriptionTypes
} from '..';
import DeviceAlert from './DeviceAlert';
import { Capitalize } from '../utils/StringConverters/StringConverters';
import get from 'lodash.get';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { WithTooltip } from '../../hoc';
import './DeviceList.scss';

const DeviceList = props => {
  const {
    devices,
    manualPaginationSettings,
    currentPageExtra,
    userinfo
  } = props;

  const handleRowClick = useCallback(
    (state, rowInfo) => {
      if (rowInfo && rowInfo.original && rowInfo.original.id) {
        return {
          onClick: () => {
            props.history.push(
              `/${routePaths.DeviceOverview.path}/${rowInfo.original.id}`
            );
          }
        };
      } else {
        return {};
      }
    },
    [props.history]
  );

  const statusMapping = {
    'switched-off': 'SwitchedOff',
    standby: 'Standby',
    'in-operation': 'InUse',
    offline: 'Offline'
  };

  return (
    <React.Fragment>
      {(devices && devices.length > 0 && (
        <DataTableFixedColumnsAndHeader
          data={devices}
          getTrProps={handleRowClick}
          defaultPageSize={10}
          className="mb-3 -highlight -striped gtg-installed-base-device-table"
          manualPaginationSettings={manualPaginationSettings}
          noCustomScrollbars
          withOverflow
          currentPageExtra={currentPageExtra}
          columns={[
            {
              Header: (
                <FormattedMessage id="column.status" defaultMessage="Status" />
              ),
              accessor: 'status',
              Cell: row => <StatusIcon value={row.value} />,
              width: 80,
              headerClassName: 'text-left',
              className: 'text-left',
              noOverflowTooltip: true
            },
            {
              Header: (
                <FormattedMessage
                  id="column.lastAlert"
                  defaultMessage="Last alert"
                />
              ),
              accessor: 'alert',
              headerClassName: 'text-left',
              Cell: ({ value }) => (
                <DeviceAlert
                  heading={value.name}
                  description={value.description}
                />
              ),
              width: 160,
              className: 'with-overflow'
            },
            {
              Header: (
                <FormattedMessage
                  id="column.department"
                  defaultMessage="Department"
                />
              ),
              accessor: 'department.name',
              headerClassName: 'text-left',
              className: 'text-left'
            },
            {
              Header: (
                <FormattedMessage id="column.device" defaultMessage="Device" />
              ),
              accessor: 'deviceType',
              headerClassName: 'text-left',
              className: 'text-left',
              Cell: row =>
                (row.value.length && getDeviceTypeDisplayName(row.value)) || '',
              width: 80
            },
            {
              Header: (
                <FormattedMessage
                  id="column.serialNo"
                  defaultMessage="Serial no"
                />
              ),
              accessor: 'serialID',
              headerClassName: 'text-right',
              className: 'text-right',
              width: 60
            },
            {
              Header: (
                <FormattedMessage
                  id="column.customerDeviceNo"
                  defaultMessage="Customer device no"
                />
              ),
              accessor: 'customerDeviceID',
              headerClassName: 'text-right',
              className: 'text-right',
              width: props.enableSubscriptions ? 80 : 120
            },
            {
              Header: (
                <FormattedMessage
                  id="column.availabilityStatus"
                  defaultMessage="Availability"
                />
              ),
              show: props.enableSubscriptions,
              accessor: 'availabilityStatus',
              headerClassName: 'text-right',
              className: 'gtg-availability-status-container-cell',
              width: 100,
              Cell: ({ row }) => {
                return row._original.subscription ===
                  subscriptionTypes.standard &&
                  userinfo &&
                  !userinfo.isInternalUser ? (
                  <div className="gtg-availability-standard">
                    <WithTooltip
                      tooltipContent={
                        <FormattedMessage
                          id="installedBase.availabilityStandardTooltip"
                          defaultMessage="Availability is only shown for your devices with Advanced and Premium subscription."
                        />
                      }
                      tooltipLocation="top"
                      tooltipFixedArrow
                      tooltipSmall
                    >
                      <Icon name="badge-count-standard" size="md" />
                    </WithTooltip>
                  </div>
                ) : (
                  (row.availabilityStatus && (
                    <div className="gtg-availability-status-container">
                      <WithTooltip
                        tooltipContent={
                          <FormattedMessage
                            id={`installedBase.availability${
                              statusMapping[row.availabilityStatus]
                            }`}
                            defaultMessage=""
                          />
                        }
                        tooltipLocation="top"
                        tooltipFixedArrow
                        tooltipSmall
                      >
                        <Icon
                          name={`availability-${row.availabilityStatus}`}
                          size="md"
                        />
                      </WithTooltip>
                    </div>
                  )) || (
                    <FormattedMessage
                      id="common.notAvailableAbbr"
                      defaultMessage="N/A"
                    />
                  )
                );
              }
            },
            {
              Header: (
                <FormattedMessage
                  id="column.SWVersion"
                  defaultMessage="SW Version"
                />
              ),
              accessor: 'systemSoftwareVersion',
              headerClassName: 'text-right',
              className: 'text-right',
              width: props.enableSubscriptions ? 80 : 120,
              Cell: row =>
                row.value || (
                  <FormattedMessage
                    id="common.notAvailableAbbr"
                    defaultMessage="N/A"
                  />
                )
            },
            {
              Header: (
                <FormattedMessage id="column.nextPM" defaultMessage="Next PM" />
              ),
              accessor: 'nextPreventativeMaintenance',
              width: 80,
              Cell: row =>
                (row.value && <FormattedDate value={row.value} />) || (
                  <FormattedMessage
                    id="common.notAvailableAbbr"
                    defaultMessage="N/A"
                  />
                )
            },
            {
              Header: (
                <FormattedMessage
                  id="column.lastConnected"
                  defaultMessage="Last connected"
                />
              ),
              accessor: 'lastLog',
              Cell: row =>
                (row.value && (
                  <React.Fragment>
                    {row.value && (
                      <>
                        <FormattedDate value={row.value} />{' '}
                        <Formatted24hTime value={row.value} />
                      </>
                    )}
                  </React.Fragment>
                )) || (
                  <FormattedMessage
                    id="common.notAvailableAbbr"
                    defaultMessage="N/A"
                  />
                ),
              getProps: () => {
                return {
                  style: {
                    whiteSpace: 'pre'
                  }
                };
              }
            },
            {
              Header: (
                <FormattedMessage
                  id="column.contract"
                  defaultMessage="Contract"
                />
              ),
              width: 80,
              accessor: 'serviceContract',
              headerClassName: 'text-left',
              className: 'text-left',
              Cell: row => (row.value.length && Capitalize(row.value)) || ''
            },
            {
              Header: (
                <FormattedMessage
                  id="deviceInformation.expirationDate"
                  defaultMessage="Expiration Date"
                />
              ),
              width: 75,
              show: props.enableSubscriptions,
              accessor: 'serviceContractExpirationDate',
              headerClassName: 'text-left',
              className: 'text-left',
              Cell: row =>
                (row.value && (
                  <React.Fragment>
                    {row.value && <FormattedDate value={row.value} />}
                  </React.Fragment>
                )) || (
                  <FormattedMessage
                    id="common.notAvailableAbbr"
                    defaultMessage="N/A"
                  />
                )
            }
          ]}
        />
      )) || <EmptyState />}
    </React.Fragment>
  );
};

const mapStateToProps = state => ({
  config: state.config,
  enableSubscriptions: get(state, 'config.enableSubscriptions', false),
  userinfo: get(state, 'authorization.userinfo')
});

export default compose(connect(mapStateToProps), withRouter)(DeviceList);
