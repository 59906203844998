import React from 'react';
import { FormattedMessage } from 'react-intl';

/**
 * Validates a form control for proper values and returns an array of errors
 * @param value Control value
 * @param name Control name
 */
export const getValidationErrors = (currencies, value, name) => {
  const errors = [];

  if ([null, undefined, ''].includes(value)) {
    errors.push(
      <FormattedMessage
        id="agentCost.valueRequired"
        defaultMessage="This value is required."
      />
    );
  } else if (name === 'currency') {
    // Validate currency
    if (!getCurrencyFromCode(currencies, value)) {
      errors.push(
        <FormattedMessage
          id="agentCost.selectCurrencyFromList"
          defaultMessage="Select a currency from the list."
        />
      );
    }
    // Validate all other values
  } else if (!/^\d+(\.\d{1,2})?$/.test(value)) {
    errors.push(
      <FormattedMessage
        id="agentCost.enterAValidNumber"
        defaultMessage="Enter a valid number with max 2 decimal places. Eg: 12.34."
      />
    );
  }

  return errors;
};

/**
 * Finds the currency object from currency code
 * @param currencies An array of currency objects
 * @param currencyCode Currency code for which an object should be returned
 */
export const getCurrencyFromCode = (currencies, currencyCode) =>
  currencies.find(currency => currency.code === currencyCode);

export const getInitialData = costs => {
  const { currency = {}, sev = '', des = '', iso = '' } = costs || {};

  return {
    currency: {
      name: 'currency',
      label: (
        <FormattedMessage
          id="agentCost.selectCurrency"
          defaultMessage="Select currency"
        />
      ),
      errors: [],
      value: currency.code || ''
    },
    sev: {
      name: 'sev',
      label: (
        <FormattedMessage
          id="agentCost.costOfSev"
          defaultMessage="Cost of Sevoflurane per liter:"
        />
      ),
      errors: [],
      value: sev
    },
    des: {
      name: 'des',
      label: (
        <FormattedMessage
          id="agentCost.costOfDes"
          defaultMessage="Cost of Desflurane per liter:"
        />
      ),
      errors: [],
      value: des
    },
    iso: {
      name: 'iso',
      label: (
        <FormattedMessage
          id="agentCost.costOfIso"
          defaultMessage="Cost of Isoflurane per liter:"
        />
      ),
      errors: [],
      value: iso
    }
  };
};
