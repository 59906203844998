export const entity = '[Account]';
export const ACCOUNT_INIT = `${entity} Init`;
export const ACCOUNT_SELECTED = `${entity} Selected`;
export const ACCOUNT_SET_SELECTED = `${entity} Set selected`;
export const ACCOUNT_FETCH_PERSIST = `${entity} Fetch persist`;

/**
 * Account selected action creator
 *
 * Every selection of an account should trigger the account selected event. Selecting
 * the same account multiple times will not cause any side effects. If the account selected
 * is new, the ACCOUNT_INIT action is dispatched. ACCOUNT_INIT action fetches all account
 * based data needed for the application to function.
 */
export const accountSelected = account => {
  return {
    type: ACCOUNT_SELECTED,
    payload: {
      account
    }
  };
};

/**
 * Account set selected action creator
 *
 * Persist accountID in account store as current account ID
 */
export const accountSetSelected = accountId => ({
  type: ACCOUNT_SET_SELECTED,
  payload: {
    id: accountId
  }
});

/**
 * Init account action creator
 *
 * Fetches account dependencies when a new account is selected.
 */
export const accountInit = accountId => ({
  type: ACCOUNT_INIT,
  payload: {
    id: accountId
  }
});

/**
 * Fetch account info
 */
export const fetchPersistAccount = id => ({
  type: ACCOUNT_FETCH_PERSIST,
  payload: {
    id
  }
});
