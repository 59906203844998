import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Icon } from '../..';

const TestHeaderResult = ({ result }) => {
  let headerResult;
  switch (result) {
    case 'passed':
      headerResult = (
        <div className="text-success">
          <Icon name="success-outline" size="md" />
          <FormattedMessage id="common.passed" defaultMessage="Passed" />
        </div>
      );
      break;
    case 'failed':
      headerResult = (
        <div className="text-danger">
          <Icon name="error-outline" size="md" />
          <FormattedMessage id="common.failed" defaultMessage="Failed" />
        </div>
      );
      break;
    case 'canceled':
      headerResult = (
        <div className="text-danger">
          <Icon name="error-outline" size="md" />
          <FormattedMessage id="common.canceled" defaultMessage="Canceled" />
        </div>
      );
      break;
    case 'watch':
      headerResult = (
        <div className="text-warning">
          <Icon name="warning-outline" size="md" />
          <FormattedMessage id="common.watch" defaultMessage="Watch" />
        </div>
      );
      break;
    case 'not-performed':
      headerResult = (
        <div>
          <Icon name="not-performed" size="md" />
          <FormattedMessage
            id="common.notPerformed"
            defaultMessage="Not performed"
          />
        </div>
      );
      break;
    default:
      break;
  }
  return headerResult;
};

export default TestHeaderResult;
