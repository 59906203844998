import React, { useCallback } from 'react';
import classnames from 'classnames';

import { FormElement, commonFormElementPropTypes } from '..';

const Input = ({ label, name, value, setValue, errors, postfix }) => {
  const handleChange = useCallback(
    e => {
      if (setValue) {
        const target = e.target;
        setValue(target.value, target.name);
      }
    },
    [setValue]
  );

  const hasErrors = Array.isArray(errors) && errors.length > 0;

  return (
    <FormElement label={label} errors={errors} postfix={postfix}>
      <input
        type="text"
        name={name}
        value={value}
        onChange={handleChange}
        className={classnames(hasErrors && 'gtg-form-element-with-error')}
      />
    </FormElement>
  );
};

Input.propTypes = {
  ...commonFormElementPropTypes
};

export default Input;
