import React from 'react';
import PropTypes from 'prop-types';

import { Header, Footer, NoHospital } from '../..';

import styles from './Layout.module.scss';

const Layout = ({
  user,
  children,
  onLogout,
  hospital,
  fetchToken,
  savePersistentState,
  removePersistentState,
  roleChange,
  canChangeRole
}) => {
  return (
    <React.Fragment>
      <Header
        user={user}
        hospital={hospital}
        onLogout={onLogout}
        fetchToken={fetchToken}
        savePersistentState={savePersistentState}
        removePersistentState={removePersistentState}
        roleChange={roleChange}
        canChangeRole={canChangeRole}
      />
      <div className={styles.content}>
        {((!user || (hospital && hospital.length > 0)) && children) || (
          <NoHospital />
        )}
      </div>
      {user && <Footer />}
    </React.Fragment>
  );
};

Layout.propTypes = {
  user: PropTypes.string,
  onLogout: PropTypes.func,
  hospital: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string
    })
  ),
  fetchToken: PropTypes.func,
  savePersistentState: PropTypes.func,
  removePersistentState: PropTypes.func,
  roleChange: PropTypes.shape({
    role: PropTypes.string,
    certification: PropTypes.bool
  }),
  canChangeRole: PropTypes.bool
};

export default Layout;
