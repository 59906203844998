import React, { useCallback } from 'react';
import classnames from 'classnames';
import { Icon } from '../..';
import styles from './FilterInput.module.scss';

const FilterInput = ({
  value,
  onChange,
  onInputClear,
  placeholder,
  className,
  focus = true
}) => {
  /* eslint-disable react-hooks/exhaustive-deps */
  const inputRef = useCallback(input => focus && input && input.focus());
  /* eslint-enable react-hooks/exhaustive-deps */

  return (
    <div
      data-cy="gtgFilterInput"
      className={classnames(styles.gtgFilterInput, className)}
    >
      <Icon name="search" size="sm" />
      <input
        name="filterInput"
        ref={inputRef}
        onChange={onChange}
        value={value}
        autoComplete="off"
        placeholder={placeholder || ''}
      />
      <span
        data-cy="gtgFilterInputClear"
        className={styles.gtgFilterInputClear}
        onClick={onInputClear}
      >
        <Icon name="close" size="sm" />
      </span>
    </div>
  );
};

export default FilterInput;
