import React from 'react';
import { List, ListItem } from '../../components';
import { FormattedMessage } from 'react-intl';
import propTypes from 'prop-types';

const DeviceSettingsWidget = ({ caseAgc, recruitmentManeuver }) =>
  ([true, false].includes(caseAgc) ||
    [true, false].includes(recruitmentManeuver)) && (
    <div className="mt-4">
      <h6 className="mb-3 gtg-case-details-title">
        <FormattedMessage
          id="case.deviceSettings"
          defaultMessage="Device settings"
        />
      </h6>
      <List>
        {[true, false].includes(recruitmentManeuver) && (
          <ListItem>
            <FormattedMessage
              id="case.recruitmentManeuver"
              defaultMessage="Recruitment Maneuver"
            />
            {recruitmentManeuver ? (
              <FormattedMessage id="common.yes" defaultMessage="Yes" />
            ) : (
              <FormattedMessage id="common.no" defaultMessage="No" />
            )}
          </ListItem>
        )}
        {[true, false].includes(caseAgc) && (
          <ListItem>
            <FormattedMessage id="case.agc" defaultMessage="AGC" />
            {caseAgc ? (
              <FormattedMessage id="common.yes" defaultMessage="Yes" />
            ) : (
              <FormattedMessage id="common.no" defaultMessage="No" />
            )}
          </ListItem>
        )}
      </List>
    </div>
  );

DeviceSettingsWidget.propTypes = {
  caseAgc: propTypes.bool,
  recruitmentManeuver: propTypes.bool
};

export default DeviceSettingsWidget;
