import React, { useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { injectIntl, intlShape } from 'react-intl';
import propTypes from 'prop-types';
import { WithLoadingSpinner } from '../../hoc';

import { fetchLogs } from '../../redux/actions/logs';
import { pushPaginationHistory } from '../../redux/actions/paginationHistory';
import {
  uiConfig,
  routePaths,
  DeviceLogList,
  CardTabbed,
  CardTab,
  CardSection,
  ContainerHeader,
  decodePaginationURI,
  getDeviceFamilyName,
  deviceFamilies,
  DeviceLogDownload,
  Card,
  EmptyState
} from '../../components';
import { FormattedMessage } from 'react-intl';
import { Can } from '../../hoc/withAbilityContext/withAbilityContext';
import { Device } from '../../hoc/withAbilityContext/rule-builder';

const logTypes = [
  {
    slug: 'config',
    title: <FormattedMessage id="logs.config" defaultMessage="Config" />
  },
  {
    slug: 'internal',
    title: <FormattedMessage id="logs.internal" defaultMessage="Internal" />
  },
  {
    slug: 'installation',
    title: (
      <FormattedMessage id="logs.installation" defaultMessage="Installation" />
    )
  },
  {
    slug: 'service',
    title: <FormattedMessage id="logs.service" defaultMessage="Service" />
  },
  {
    slug: 'technical',
    title: <FormattedMessage id="logs.technical" defaultMessage="Technical" />
  },
  {
    slug: 'trends',
    title: <FormattedMessage id="logs.trends" defaultMessage="Trends" />
  },
  {
    slug: 'test',
    title: <FormattedMessage id="logs.test" defaultMessage="Test" />
  },
  {
    slug: 'all-events',
    title: <FormattedMessage id="logs.allEvents" defaultMessage="All events" />
  }
];

function LogTypeProps(state, logTypes) {
  const logTypeProps = {};
  for (let i = 0; i < logTypes.length; i++) {
    logTypeProps[logTypes[i].slug + 'Log'] = state.logs.logs[logTypes[i].slug];
  }
  return logTypeProps;
}

export function FilterLogTypesByDeviceType(logTypes, deviceType) {
  let supportedLogTypes = [];

  switch (getDeviceFamilyName(deviceType)) {
    case deviceFamilies.flow.name:
      supportedLogTypes = [
        'internal',
        'installation',
        'service',
        'technical',
        'trends'
      ];
      break;
    case deviceFamilies.servo.name:
      supportedLogTypes = [
        'config',
        'internal',
        'installation',
        'service',
        'technical',
        'test'
      ];
      break;
    case deviceFamilies.rotaflow.name:
      supportedLogTypes = ['all-events'];
      break;
    // no default
  }

  return logTypes.filter(logType => supportedLogTypes.includes(logType.slug));
}

const DeviceLogOverview = props => {
  const { device, fetchLogs, match, location, pushPaginationHistory } = props;
  const initialPaginationSettings = {
    page: 0,
    pageSize: uiConfig.pageSizeOptions[0]
  };

  const filteredLogTypes = FilterLogTypesByDeviceType(
    logTypes,
    device.deviceType
  );

  const handleFetchCallback = (...fetchParams) => {
    pushPaginationHistory(fetchParams[2].page, fetchParams[2].pageSize);
    props.fetchLogs(...fetchParams);
  };

  // Check if current log type is available after filtering
  let currentLogType =
    match.params.logType || routePaths.DeviceLogOverview.defaultLogType;
  let currentTabIndex = filteredLogTypes.findIndex(
    logType => logType.slug === currentLogType
  );

  if (currentTabIndex <= -1 && filteredLogTypes.length > 0) {
    currentTabIndex = 0;
    currentLogType = filteredLogTypes[currentTabIndex].slug;
  }

  const manualPaginationSettings = props[currentLogType + 'Log'] && {
    fetchMethod: handleFetchCallback,
    fetchParams: [device.id, currentLogType],
    paginationSettings: props[currentLogType + 'Log'].pagination,
    sortingSettings: props[currentLogType + 'Log'].sorting,
    customPageSizeOptions: uiConfig.pageSizeOptions
  };

  function TabNavigation(logType, pushHistory = true) {
    /* eslint-disable react-hooks/exhaustive-deps */
    return useCallback(() => {
      if (pushHistory) {
        props.history.push(logType);
      }
    });
  }

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (location && device) {
      const urlPagination = decodePaginationURI(
        location.search,
        initialPaginationSettings
      );
      fetchLogs(device.id, currentLogType, {
        page: urlPagination.page,
        pageSize: urlPagination.pageSize
      });
    }
  }, [location]);

  const haveLogsToDisplay = filteredLogTypes.length > 0;

  return (
    <>
      <Can I={Device.VIEW_ALL_LOGS} this={new Device(device.deviceType)}>
        <ContainerHeader
          headerTitle={routePaths.DeviceLogOverview.label}
          customClass="gtg-device-log-container-header"
        >
          {haveLogsToDisplay && (
            <DeviceLogDownload deviceId={device.id} logType={currentLogType} />
          )}
        </ContainerHeader>
        <div className="row">
          <div className="col-12 col-md-12">
            {haveLogsToDisplay ? (
              <CardTabbed
                dataCy="cardLogTabs"
                currentTabIndex={currentTabIndex}
              >
                {filteredLogTypes.map(logType => (
                  <CardTab
                    title={logType.title}
                    key={logType.title}
                    customOnTabClick={TabNavigation(logType.slug)}
                  >
                    {!logType.disabled && (
                      <CardSection>
                        <WithLoadingSpinner when="logs">
                          <DeviceLogList
                            log={props[logType.slug + 'Log']}
                            logType={logType.slug}
                            manualPaginationSettings={manualPaginationSettings}
                            deviceType={device.deviceType}
                          />
                        </WithLoadingSpinner>
                      </CardSection>
                    )}
                  </CardTab>
                ))}
              </CardTabbed>
            ) : (
              <Card>
                <CardSection>
                  <EmptyState />
                </CardSection>
              </Card>
            )}
          </div>
        </div>
      </Can>
    </>
  );
};

const mapStateToProps = state => {
  return {
    ...LogTypeProps(state, logTypes)
  };
};

const mapDispatchToProps = {
  fetchLogs,
  pushPaginationHistory
};

DeviceLogOverview.propTypes = {
  intl: intlShape,
  fetchLogs: propTypes.func,
  pushPaginationHistory: propTypes.func
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  injectIntl
)(DeviceLogOverview);
