import React from 'react';
import { withRouter } from 'react-router-dom';
import { injectIntl, FormattedMessage, FormattedDate } from 'react-intl';
import propTypes from 'prop-types';

import './BadgeLabelComponent.scss';

const BadgeLabelComponent = ({ subscriptionType, expirationDate }) => {
  return (
    <>
      {subscriptionType && (
        <div className="badge-label-component">
          <section>
            <div
              className={`badge-label badge-label-${subscriptionType}`}
            ></div>
          </section>
          <section>
            <div className="badge-label-expiration-date">
              <FormattedMessage
                id="deviceInformation.expirationDate"
                defaultMessage="Expiration date"
              />
              <span>
                :&nbsp;
                {(expirationDate && (
                  <React.Fragment>
                    {expirationDate && <FormattedDate value={expirationDate} />}
                  </React.Fragment>
                )) || (
                  <FormattedMessage
                    id="common.notAvailableAbbr"
                    defaultMessage="N/A"
                  />
                )}
              </span>
            </div>
          </section>
        </div>
      )}
    </>
  );
};

BadgeLabelComponent.propTypes = {
  subscriptionType: propTypes.string,
  expirationDate: propTypes.string
};

export default withRouter(injectIntl(BadgeLabelComponent));
