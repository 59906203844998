export const entity = '[Preuse checks]';
export const SET_DEVICE_TEST_NOT_PERFORMED_ALARM = `${entity} Set device 24 hour system checkout alarm`;
export const CALCULATE_DEVICE_TEST_NOT_PERFORMED_ALARM = `${entity} Calculate device 24 hour system checkout alarm`;
export const FETCH_TESTS = `${entity} Fetch`;
export const FETCH_LAST_SYSTEM_CHECKOUT = `${entity} Fetch last system checkout`;
export const SET_LAST_SYSTEM_CHECKOUT = `${entity} Set last system checkout`;
export const SET_TESTS = `${entity} Set`;

/**
 * Trigger fetch of device information
 */
export const fetchTests = (
  deviceId,
  paginationSettings,
  sortingSettings,
  name = ''
) => {
  return {
    type: FETCH_TESTS,
    pagination: paginationSettings,
    sorting: sortingSettings,
    id: deviceId,
    name: name
  };
};

export const fetchLastSystemCheckout = deviceId => {
  return {
    type: FETCH_LAST_SYSTEM_CHECKOUT,
    deviceId: deviceId
  };
};

/**
 * Set data as new device information
 * @param {array} data New device information
 */
export const setTests = data => ({
  type: SET_TESTS,
  payload: data
});

export const setLastSystemCheckout = data => ({
  type: SET_LAST_SYSTEM_CHECKOUT,
  payload: data
});

export const setTest24HourAlarm = data => ({
  type: SET_DEVICE_TEST_NOT_PERFORMED_ALARM,
  payload: data
});

export const calculateTest24HourAlarm = data => ({
  type: CALCULATE_DEVICE_TEST_NOT_PERFORMED_ALARM,
  payload: data
});
