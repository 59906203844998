export const entityAccounts = '[Device filters accounts]';
export const entityDepartments = '[Device filters departments]';
export const entityDeviceTypes = '[Device filters device types]';
export const FETCH_ACCOUNTS = `${entityAccounts} Fetch`;
export const FETCH_DEPARTMENTS = `${entityDepartments} Fetch`;
export const FETCH_DEVICE_TYPES = `${entityDeviceTypes} Fetch`;
export const SET_ACCOUNTS = `${entityAccounts} Set`;
export const SET_DEPARTMENTS = `${entityDepartments} Set`;
export const SET_DEVICE_TYPES = `${entityDeviceTypes} Set`;

/**
 * Trigger fetch actions
 */
export const fetchAccounts = searchStr => ({
  type: FETCH_ACCOUNTS,
  searchStr: searchStr
});

export const fetchDepartments = accountId => ({
  type: FETCH_DEPARTMENTS,
  id: accountId
});

export const fetchDeviceTypes = accountId => ({
  type: FETCH_DEVICE_TYPES,
  id: accountId
});

/**
 * Set data as device filters
 * @param {array} data Device filters
 */
export const setAccounts = data => ({
  type: SET_ACCOUNTS,
  payload: data
});

export const setDepartments = data => ({
  type: SET_DEPARTMENTS,
  payload: data
});

export const setDeviceTypes = data => ({
  type: SET_DEVICE_TYPES,
  payload: data
});
