export const entity = '[Devices overview]';
export const FETCH_DEVICES_OVERVIEW = `${entity} Fetch`;
export const SET_DEVICES_OVERVIEW = `${entity} Set`;

/**
 * Trigger fetch of device status
 */
export const fetchDevicesOverview = () => ({
  type: FETCH_DEVICES_OVERVIEW
});

/**
 * Set data as new device status
 * @param {array} data New device status
 */
export const setDevicesOverview = data => ({
  type: SET_DEVICES_OVERVIEW,
  payload: data
});
