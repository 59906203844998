import React, { useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import { FormattedDate, FormattedMessage } from 'react-intl';
import {
  routePaths,
  EmptyState,
  getDeviceTypeDisplayName,
  DataTableFixedColumnHeader,
  Capitalize,
  Icon
} from '..';
import './SubscriptionsList.scss';

const SubscriptionsList = ({ devices, manualPaginationSettings, history }) => {
  const handleRowClick = useCallback(
    (state, rowInfo) => {
      if (rowInfo && rowInfo.original && rowInfo.original.id) {
        return {
          onClick: () => {
            history.push(
              `/${routePaths.DeviceOverview.path}/${rowInfo.original.id}`
            );
          }
        };
      } else {
        return {};
      }
    },
    [history]
  );

  return (
    <React.Fragment>
      {(devices && devices.length > 0 && (
        <DataTableFixedColumnHeader
          data={devices}
          getTrProps={handleRowClick}
          defaultPageSize={10}
          className="mb-3"
          manualPaginationSettings={manualPaginationSettings}
          noCustomScrollbars
          withOverflow
          columns={[
            {
              Header: (
                <FormattedMessage
                  id="column.package"
                  defaultMessage="Subscription"
                />
              ),
              accessor: 'subscription',
              Cell: row => (
                <div className={'gtg-subscription-level'}>
                  <Icon name={`badge-count-${row.value}`} size="md" />
                  <span className={'gtg-subscription-level-text'}>
                    {Capitalize(row.value)}
                  </span>
                </div>
              ),
              className: 'text-left',
              width: 130
            },
            {
              Header: (
                <FormattedMessage id="column.device" defaultMessage="Device" />
              ),
              accessor: 'deviceType',
              headerClassName: 'text-left',
              className: 'text-left',
              Cell: row =>
                (row.value.length && getDeviceTypeDisplayName(row.value)) || '',
              width: 80
            },
            {
              Header: (
                <FormattedMessage
                  id="column.serialNo"
                  defaultMessage="Serial no"
                />
              ),
              accessor: 'serialID',
              headerClassName: 'text-right',
              className: 'text-right',
              width: 120
            },
            {
              Header: (
                <FormattedMessage
                  id="column.department"
                  defaultMessage="Department"
                />
              ),
              accessor: 'department.name',
              headerClassName: 'text-left',
              className: 'text-left'
            },
            {
              Header: (
                <FormattedMessage
                  id="column.expirationDate"
                  defaultMessage="Expiration date"
                />
              ),
              accessor: 'subscriptionExpirationDate',
              width: 130,
              Cell: row =>
                (row.value && (
                  <React.Fragment>
                    {row.value && (
                      <>
                        <FormattedDate value={row.value} />
                      </>
                    )}
                  </React.Fragment>
                )) || (
                  <FormattedMessage
                    id="common.notAvailableAbbr"
                    defaultMessage="N/A"
                  />
                )
            }
          ]}
        />
      )) || <EmptyState />}
    </React.Fragment>
  );
};

export default withRouter(SubscriptionsList);
