import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './Tooltip.module.scss';

/**
 * Displays a tooltip with custom content and location.
 * @param content The content that should be displayed in the tooltip
 * @param location Where should the tooltip be displayed on 'top' (default) or 'bottom' of the element.
 * @param fixedArrow Whether or not the arrow location is fixed to the top/bottom left (not top/bottom middle)
 * @param small Change the appearance, by reducing padding and using a smaller font size
 */
const Tooltip = ({
  content,
  location = 'top',
  fixedArrow = false,
  small = false
}) => {
  return (
    <div
      className={classnames(
        styles.gtgTooltip,
        location === 'bottom' && styles.gtgTooltipBottom,
        'tooltip',
        `bs-tooltip-${location}`
      )}
      role="tooltip"
    >
      <div
        className={classnames(
          styles.gtgTooltipArrow,
          'arrow',
          fixedArrow && styles.gtgTooltipArrowFixed
        )}
      />
      <div
        className={classnames(
          styles.gtgTooltipContent,
          small && styles.gtgTooltipSmall,
          'tooltip-inner'
        )}
      >
        {content}
      </div>
    </div>
  );
};

Tooltip.propTypes = {
  content: PropTypes.node,
  location: PropTypes.oneOf(['top', 'bottom']),
  fixedArrow: PropTypes.bool,
  small: PropTypes.bool
};

export default Tooltip;
