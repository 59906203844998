import { applyMiddleware, createStore, compose } from 'redux';
import reducers from './reducers';
import { devicesMdl } from './middleware/devices';
import { devicesOverviewMdl } from './middleware/devicesOverview';
import { deviceMdl } from './middleware/device';
import { deviceFiltersMdl } from './middleware/deviceFilters';
import { deviceStatusMdl } from './middleware/deviceStatus';
import { testsMdl } from './middleware/tests';
import { testMdl } from './middleware/test';
import { apiMdl } from './middleware/api';
import { authMdl } from './middleware/authorization';
import { logTypesMdl } from './middleware/logTypes';
import { logsMdl } from './middleware/logs';
import { documentsMdl } from './middleware/documents';
import { blobDownloadMdl } from './middleware/blobDownload';
import { documentMdl } from './middleware/document';
import { serviceOrdersMdl } from './middleware/serviceOrders';
import { serviceReportMdl } from './middleware/serviceReport';
import { serviceOrdersOverviewMdl } from './middleware/serviceOrdersOverview';
import { metricsMdl } from './middleware/metrics';
import { metricsDetailedPerDeviceMdl } from './middleware/metricsDetailedPerDevice';
import { metricsDetailedMdl } from './middleware/metricsDetailed';
import { metricsUsageMdl } from './middleware/metricsUsage';
import { trendsMdl } from './middleware/trends';
import { troubleshootingGuidanceMdl } from './middleware/troubleshootingGuidance';
import { preventiveMaintenanceMdl } from './middleware/preventiveMaintenance';
import { devicePreventiveMaintenanceMdl } from './middleware/devicePreventiveMaintenance';
import { legalNoticeMdl } from './middleware/legalNotice';
import { casesMdl } from './middleware/cases';
import { caseMdl } from './middleware/case';
import { costsMdl } from './middleware/costs';
import { currencyMdl } from './middleware/currency';
import { profileMdl } from './middleware/profile';
import { configMdl } from './middleware/config';
import { accessInfoMdl } from './middleware/accessInfo';
import thunk from 'redux-thunk';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import { devicesRunningHoursMdl } from './middleware/devicesRunningHours';
import { accountMdl } from './middleware/account';
import { adminMdl } from './middleware/admin';
import { softwareVersionsMdl } from './middleware/softwareVersions';
import { usageStatisticsMdl } from './middleware/usageStatistics';
import { usageStatisticsUtilizationMdl } from './middleware/usageStatisticsUtilization';
import { usageStatisticsDetailedMdl } from './middleware/usageStatisticsDetailed';
import { connectedAccessoriesMdl } from './middleware/connectedAccessories';

// dev tool
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const history = createBrowserHistory();

const store = createStore(
  reducers(history),
  composeEnhancers(
    applyMiddleware(
      routerMiddleware(history),
      thunk,
      configMdl,
      devicesMdl,
      devicesOverviewMdl,
      devicesRunningHoursMdl,
      deviceMdl,
      deviceFiltersMdl,
      deviceStatusMdl,
      testsMdl,
      testMdl,
      ...authMdl,
      apiMdl,
      logTypesMdl,
      logsMdl,
      documentsMdl,
      blobDownloadMdl,
      documentMdl,
      serviceOrdersMdl,
      serviceReportMdl,
      serviceOrdersOverviewMdl,
      metricsMdl,
      metricsDetailedPerDeviceMdl,
      metricsDetailedMdl,
      metricsUsageMdl,
      trendsMdl,
      troubleshootingGuidanceMdl,
      preventiveMaintenanceMdl,
      devicePreventiveMaintenanceMdl,
      legalNoticeMdl,
      casesMdl,
      caseMdl,
      costsMdl,
      currencyMdl,
      profileMdl,
      accessInfoMdl,
      accountMdl,
      adminMdl,
      softwareVersionsMdl,
      usageStatisticsMdl,
      usageStatisticsUtilizationMdl,
      usageStatisticsDetailedMdl,
      connectedAccessoriesMdl
    )
  )
);

export default store;
