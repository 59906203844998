import { createUserManager } from 'redux-oidc';
import get from 'lodash.get';

const oidcConfig = (idp, oidcProviders) => {
  return {
    client_id: oidcProviders[idp].config.client_id,
    redirect_uri: `${window.location.protocol}//${window.location.hostname}${
      window.location.port ? `:${window.location.port}` : ''
    }/callback`,
    post_logout_redirect_uri: `${window.location.protocol}//${
      window.location.hostname
    }${window.location.port ? `:${window.location.port}` : ''}`,
    response_type: oidcProviders[idp].config.response_type,
    scope: 'openid profile groups',
    authority: oidcProviders[idp].config.authority,
    silent_redirect_uri: `${window.location.protocol}//${
      window.location.hostname
    }${window.location.port ? `:${window.location.port}` : ''}/silent_renew`,
    automaticSilentRenew: true,
    filterProtocolClaims: true,
    loadUserInfo: false
  };
};

export const userManager = (idp, oidc) =>
  idp && createUserManager(oidcConfig(idp, oidc));

export const bootstrapUserManager = (idp = sessionStorage.getItem('idp')) => {
  window.userManager = userManager(idp, window.oidcProviders);
};

export const getAuthToken = state => {
  const idpName = sessionStorage.getItem('idp');
  const tokenPath = get(window, `oidcProviders.${idpName}.config.token_path`);
  const user = get(state, 'oidc.user');
  const authToken = user && tokenPath && user[tokenPath];

  return authToken || '';
};

export const getJwtAuth = state => {
  const jwt = get(state, 'authorization.jwt.token');

  return jwt || '';
};
