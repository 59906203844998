import React, { useState, useCallback } from 'react';
import { Icon } from '..';
import propTypes from 'prop-types';
import styles from './DeviceInformation.module.scss';

const DeviceInformationEditForm = props => {
  const { defaultValue, width = 'auto', type = 'text', handleSubmit } = props;

  const [formActive, setFormActive] = useState(false);
  const [fieldValue, setFieldValue] = useState(defaultValue);
  const [oldValue, setOldValue] = useState(fieldValue);

  const handleStartEdit = useCallback(
    val => {
      setFormActive(true);
      setOldValue(fieldValue);
    },
    [setFormActive, setOldValue, fieldValue]
  );

  const handleCancelEdit = useCallback(() => {
    setFieldValue(oldValue);
    setFormActive(false);
  }, [setFieldValue, setFormActive, oldValue]);

  const handleFormSubmit = useCallback(() => {
    handleSubmit(fieldValue);
    setFormActive(false);
  }, [handleSubmit, setFormActive, fieldValue]);

  const handleOnKeyDown = useCallback(
    ({ key }) => key === 'Enter' && handleFormSubmit(),
    [handleFormSubmit]
  );

  /* eslint-disable react-hooks/exhaustive-deps */
  const handleOnFocus = useCallback(({ target }) => target.select());
  /* eslint-enable react-hooks/exhaustive-deps */

  const handleValueChange = useCallback(
    ({ target }) => setFieldValue(target.value),
    [setFieldValue]
  );

  return (
    <span style={{ width: width, display: 'inline-block' }}>
      {!formActive && (
        <span
          onClick={handleStartEdit}
          className="deviceInformationEditFormWrapper"
        >
          <span>{fieldValue}</span>
          <span className={styles.gtgDeviceInformationFormIcon}>
            <Icon name="edit" size="sm" />
          </span>
        </span>
      )}
      {formActive && (
        <form className={styles.gtgDeviceInformationForm}>
          <input
            type={type}
            value={fieldValue}
            onChange={handleValueChange}
            onKeyDown={handleOnKeyDown}
            onFocus={handleOnFocus}
            autoFocus
            required
          />
          <span
            onClick={handleFormSubmit}
            className={styles.gtgDeviceInformationFormIcon}
          >
            <Icon name="form-check" size="sm" />
          </span>
          <span
            onClick={handleCancelEdit}
            className={styles.gtgDeviceInformationFormIcon}
          >
            <Icon name="form-cancel" size="sm" />
          </span>
        </form>
      )}
    </span>
  );
};

DeviceInformationEditForm.propTypes = {
  defaultValue: propTypes.oneOfType([propTypes.number, propTypes.string]),
  type: propTypes.oneOf(['text', 'number']),
  width: propTypes.number,
  handleSubmit: propTypes.func
};

export default DeviceInformationEditForm;
