import React from 'react';
import classnames from 'classnames';

import { useDetectClickOutsideRef } from '../../../hooks';
import { Icon } from '../..';
import './Modal.scss';

const Modal = props => {
  const { ref } = useDetectClickOutsideRef(props.onClickedOutside);
  const saveStr = props.saveStr || 'Save';
  const closeStr = props.closeStr || 'Close';

  const defaultButtons = (
    <React.Fragment>
      {props.onClose && (
        <button
          type="button"
          className="btn btn-outline-primary"
          data-cy="modalCloseButton"
          data-dismiss="modal"
          onClick={props.onClose}
        >
          {closeStr}
        </button>
      )}
      {props.onSave && (
        <button
          type="button"
          className="btn btn-info"
          onClick={props.onSave}
          data-cy="modalSaveButton"
        >
          {saveStr}
        </button>
      )}
    </React.Fragment>
  );

  return (
    <div
      className={classnames(
        'gtg-modal',
        'modal',
        'fade',
        'show',
        props.className
      )}
      id="exampleModal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      style={{ display: 'block' }}
    >
      <div data-cy={props.dataCy} className="modal-dialog" role="document">
        <div className="modal-content" ref={ref}>
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              {props.title}
            </h5>
            {(props.onClose || props.onClickedOutside) && (
              <button
                onClick={props.onClose || props.onClickedOutside}
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <Icon name="close" size="xs" />
              </button>
            )}
          </div>
          <div className="modal-body">{props.children}</div>
          <div className="modal-footer">
            {props.buttons ? props.buttons : defaultButtons}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
