import React from 'react';
import { FormattedMessage } from 'react-intl';

export const allDateToggles = [
  {
    label: <FormattedMessage id="common.week" defaultMessage="Week" />,
    value: 'week'
  },
  {
    label: <FormattedMessage id="common.month" defaultMessage="Month" />,
    value: 'month'
  },
  {
    label: <FormattedMessage id="common.year" defaultMessage="Year" />,
    value: 'year'
  },
  {
    label: <FormattedMessage id="common.y2y" defaultMessage="Y2Y" />,
    value: 'y2y'
  }
];
