import { FETCH_DOCUMENT } from '../actions/document';
import { fetchBlob } from '../actions/blobDownload';
import { API_URLS } from './urls';

const URL = id => `${API_URLS.Documentation.url}/${id}/download`;

export const documentMdl = ({ dispatch }) => next => action => {
  next(action);

  const { type } = action;

  switch (type) {
    case FETCH_DOCUMENT:
      const { id } = action.payload;

      dispatch(fetchBlob(URL(id)));
      break;
    // no default
  }
};
