import React, { useMemo } from 'react';
import propTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { CardSectionColumn, EmptyState, CaseVentilationModesChart } from '..';
import { WithLoadingSpinner } from '../../hoc/';

export const UsageStatisticsVentilationModes = ({ metricsUsage, setData }) => {
  const hasVentilationModesData =
    metricsUsage &&
    metricsUsage.ventilationModes &&
    metricsUsage.ventilationModes.length > 0 &&
    metricsUsage.ventilationModes.some(item => item.value > 0);
  /**
   * Map array of objects to one object with labels as keys.
   * Labels are also uppercased so it lowercases them in order
   * to match the correct label display in VentilationModesChart
   */
  const mappedVentilationModes = useMemo(() => {
    return (
      hasVentilationModesData &&
      metricsUsage.ventilationModes.reduce((object, item) => {
        object[item.label.toLowerCase()] = item.value;
        return object;
      }, {})
    );
  }, [hasVentilationModesData, metricsUsage]);

  return (
    <CardSectionColumn
      title={
        <FormattedMessage
          id="case.ventilationModes"
          defaultMessage="Ventilation Modes"
        />
      }
    >
      <WithLoadingSpinner when="metricsUsage">
        {(hasVentilationModesData && (
          <div className="d-flex justify-content-center">
            <CaseVentilationModesChart
              ventilationData={mappedVentilationModes}
              chartWidth={240}
              chartHeight={240}
              innerRadius={40}
              outerRadius={65}
              labelRadius={95}
              labelPercentageThreshold={10}
              setData={setData}
            />
          </div>
        )) || <EmptyState />}
      </WithLoadingSpinner>
    </CardSectionColumn>
  );
};

UsageStatisticsVentilationModes.propTypes = {
  metricsUsage: propTypes.shape({
    ventilationModes: propTypes.arrayOf(
      propTypes.shape({
        label: propTypes.string,
        value: propTypes.number
      })
    )
  }),
  setData: propTypes.func
};

export default UsageStatisticsVentilationModes;
