export const entity = '[Legal notice approval]';
export const FETCH_LEGAL_NOTICE_APPROVAL = `${entity} Fetch`;
export const SET_LEGAL_NOTICE_APPROVAL = `${entity} Set`;
export const SET_LEGAL_NOTICE = `[Legal notice] Set`;

export const fetchLegalNoticeApproval = () => ({
  type: FETCH_LEGAL_NOTICE_APPROVAL
});

export const setLegalNoticeApproval = version => ({
  type: SET_LEGAL_NOTICE_APPROVAL,
  payload: version
});

export const setLegalNotice = (approval, content, version) => ({
  type: SET_LEGAL_NOTICE,
  payload: {
    approval: approval,
    content: content,
    version: version
  }
});
