import { SET_DEVICE_PREVENTIVE_MAINTENANCE } from '../actions/devicePreventiveMaintenance';

const initState = {
  devicePreventiveMaintenance: null
};

export const devicePreventiveMaintenanceReducer = (
  state = initState,
  action
) => {
  const { payload, type } = action;

  switch (type) {
    case SET_DEVICE_PREVENTIVE_MAINTENANCE:
      return {
        ...state,
        devicePreventiveMaintenance: payload
      };
    default:
      return state;
  }
};
