import axios from 'axios';

import {
  apiError,
  apiRequestQueue,
  apiRequestStarted,
  apiRequestFinished,
  apiSuccess,
  API_REQUEST
} from '../actions/api';
import { filterMapper } from '../../components';
import store from '../store';
import { fetchJWT, authLogout } from '../actions/authorization';
import { getJwtAuth } from '../../components/Auth/userManager';

// applyFiltersInterceptor will check if the applyFilters flag is set to true
// on the interceptor. If true, the global device filters will be appended
// to the request URL.
const applyFiltersInterceptor = request => {
  if (request.applyFilters) {
    const {
      accounts = [],
      departments = [],
      deviceTypes = []
    } = store.getState().filters;

    request.url += filterMapper(request.url, 'account_ids', accounts);

    const allDepartments = store.getState().deviceFilters.departments;
    if (allDepartments !== departments) {
      request.url += filterMapper(request.url, 'department_ids', departments);
    }

    request.url += filterMapper(request.url, 'deviceTypes', deviceTypes);
  }

  return request;
};

// apiClient acts as a singleton that encapsulates the logic of creating a client
// with a baseURL and headers (authorization). Setting the default authorization
// header could be optimized by reacting to token updates, but that is not needed
// at the moment.
const apiClient = (() => {
  let apiClient = null;
  let token = null;

  const error401Interceptor = async error => {
    // If error was not a 401 we pass the error back to the caller and ignore it
    if (error.response.status !== 401) {
      return Promise.reject(error);
    }

    if (
      error.response.status === 401 &&
      error.response.data &&
      error.response.data.message.indexOf('unknown role') !== -1
    ) {
      error.missingUserInfo = true;
    }

    if (
      error.response.status === 401 &&
      error.response.data &&
      error.response.data.message.indexOf('token is expired') !== -1
    ) {
      error.tokenRefreshNeeded = true;
    }

    return Promise.reject(error);
  };

  const error403Interceptor = async error => {
    if (
      error.response.status === 403 &&
      error.response.data &&
      error.response.data.message.includes('invalid user setup')
    ) {
      error.missingUserInfo = true;
    }
    return Promise.reject(error);
  };

  return (...args) => {
    const state = store.getState();
    const authToken = getJwtAuth(state);

    if (apiClient === null) {
      apiClient = axios.create({
        baseURL: state?.config?.beUrl ? `https://${state?.config?.beUrl}` : '',
        headers: {}
      });

      apiClient.interceptors.response.use(null, error401Interceptor);
      apiClient.interceptors.response.use(null, error403Interceptor);
      apiClient.interceptors.request.use(applyFiltersInterceptor);
    }

    if (authToken !== token) {
      apiClient.defaults.headers = { Authorization: `Bearer ${authToken}` };
      token = authToken;
    }

    return apiClient(...args);
  };
})();

// apiMdl takes care of all API calls in the portal
export const apiMdl = ({ dispatch }) => next => action => {
  next(action);

  if (action.type === API_REQUEST) {
    const { method, requestSignature, body, entity, filter } = action.meta;
    let { url } = action.meta;

    /* If the apiMdl becomes too big, requestSigning can be moved to a separate reducer and
    middleware */
    const pendingRequests = store.getState().api.pendingRequests || [];
    const uniqueSignature = requestSignature + '_|_' + method + url + filter;

    if (requestSignature) {
      if (pendingRequests.includes(uniqueSignature)) return;

      dispatch(apiRequestStarted(uniqueSignature));
    }

    apiClient({
      method,
      url: url,
      data: body,
      applyFilters: filter
    })
      .then(response => {
        dispatch(apiSuccess(response.data, entity, method));
      })
      .catch(error => {
        // If user has missing user info, sign him out
        if (error.missingUserInfo) {
          store.dispatch(authLogout());
          return null;
        }

        if (error.tokenRefreshed) {
          store.dispatch(action);
          return null;
        }

        // If error was caused by a 401 an interceptor will set tokenRefreshNeeded
        // tokenRefreshNeeded to true, so we should refresh JWT and queue failed requests.
        // Queued requests will be initiated after successful JWT refresh
        if (error.tokenRefreshNeeded) {
          const roleChange = store.getState().persistentState['roleChange'];
          store.dispatch(fetchJWT(roleChange));
          store.dispatch(apiRequestQueue(action));
          return null;
        }

        // Dispatch a generic API error action if onError is not set up
        dispatch(apiError(error, entity, method));
        return Promise.reject(error);
      })
      .finally(() => {
        if (requestSignature) {
          dispatch(apiRequestFinished(uniqueSignature));
        }
      });
  }
};
