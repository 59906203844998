export const entity = '[Usage statistics Utilization]';
export const FETCH_USAGE_STATISTICS_UTILIZATION = `${entity} Fetch`;
export const SET_USAGE_STATISTICS_UTILIZATION = `${entity} Set`;

/**
 * Trigger fetch of usage statistics utilization
 */
export const fetchUsageStatisticsUtilization = (
  timeSpan,
  count,
  patientCategories,
  deviceIds
) => ({
  type: FETCH_USAGE_STATISTICS_UTILIZATION,
  payload: {
    timeSpan,
    count,
    patientCategories,
    deviceIds
  }
});

/**
 * Set data as usage statistics utilization
 * @param {array} data usage statistics utilization
 */
export const setUsageStatisticsUtilization = data => ({
  type: SET_USAGE_STATISTICS_UTILIZATION,
  payload: data
});
