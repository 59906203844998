import jwt_decode from 'jwt-decode';
import moment from 'moment';

import {
  AUTH_LOGIN,
  AUTH_LOGOUT,
  AUTH_INIT,
  setUserinfo,
  authLogin,
  FETCH_JWT,
  setJWT,
  entityJWT
} from '../actions/authorization';
import { removePersistentState } from '../actions/persistentState';
import { apiRequest, API_SUCCESS } from '../actions/api';
import {
  bootstrapUserManager,
  getAuthToken
} from '../../components/Auth/userManager';
import { API_URLS } from './urls';

const JWT_URL = API_URLS.JWT;

const authLoginFlow = ({ getState }) => next => action => {
  next(action);

  if (action.type === AUTH_LOGIN) {
    const idp = action.payload.oidcProvider;
    bootstrapUserManager(idp);

    const args = {};

    const config = getState().config;
    // Check if we are running on preview branch and use the namespace as the state parameter
    if (config && config.previewNamespace) {
      // Check /portal/patches/README.md for more in-depth info
      args.data = { customId: config.previewNamespace };

      // TODO: Also set the window.userManager.settings.redirect_uri
    }

    window.userManager.signinRedirect(args);
  }
};

const authLogoutFlow = ({ dispatch, getState }) => next => action => {
  next(action);

  if (action.type === AUTH_LOGOUT) {
    window.userManager.removeUser();
    dispatch(removePersistentState('roleChange'));
    sessionStorage.removeItem('idp');
    window.userManager.signoutRedirect({
      id_token_hint: getState().oidc.user.id_token
    });
  }
};

const authInitFlow = store => next => action => {
  next(action);

  if (action.type === AUTH_INIT) {
    const idp = action.payload.oidcProvider;
    if (idp) {
      sessionStorage.setItem('idp', idp);
    }
    store.dispatch(authLogin(idp));
  }
};

const authJwtFlow = ({ dispatch, getState }) => next => action => {
  next(action);
  switch (action.type) {
    case FETCH_JWT:
      const authToken = getAuthToken(getState());
      const roleParam =
        action.roleChange && action.roleChange.role
          ? `&role=${action.roleChange.role}`
          : '';
      const certificationParam =
        action.roleChange && action.roleChange.certification
          ? `&certification=${
              action.roleChange.certification
            }&certificationValid=${moment()
              .add(1, 'weeks')
              .format('YYYY-MM-DD')}`
          : '';
      dispatch(
        apiRequest({
          method: 'GET',
          url: `${JWT_URL.url}?token=${authToken}${roleParam}${certificationParam}`,
          requestSignature: 'jwt',
          entity: entityJWT
        })
      );
      break;
    case `${entityJWT} GET ${API_SUCCESS}`:
      const jwt = action.payload.data.token;
      const userinfo = jwt_decode(jwt);
      dispatch(setJWT(jwt, userinfo.exp));

      if (
        !getState().authorization.userinfo ||
        getState().authorization.roleChange
      ) {
        userinfo.isRegionalUser =
          userinfo.region_codes && userinfo.region_codes.length > 0;
        // internal users are users with region code and @getinge.com email
        userinfo.isInternalUser = userinfo.email.endsWith('@getinge.com');
        dispatch(setUserinfo(userinfo));
      }

      const queuedRequests = getState().api.queuedRequests;
      queuedRequests.forEach(action => {
        dispatch(action);
      });

      break;
    default:
      break;
  }
};

export const authMdl = [
  authInitFlow,
  authLoginFlow,
  authLogoutFlow,
  authJwtFlow
];
