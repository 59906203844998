export const entity = '[Service report]';
export const FETCH_SERVICE_REPORT = `${entity} Fetch`;

/**
 * Trigger fetch of service report
 */
export const fetchServiceReport = id => ({
  type: FETCH_SERVICE_REPORT,
  payload: { id }
});
