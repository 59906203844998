import { SET_CURRENCY } from '../actions/currency';

export function currencyReducer(
  state = {
    currency: null
  },
  action
) {
  switch (action.type) {
    case SET_CURRENCY:
      return {
        ...state,
        currency: action.payload
      };
    default:
      return state;
  }
}
