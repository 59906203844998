import React from 'react';
import { FormattedMessage } from 'react-intl';

import styles from './Footer.module.scss';

function getYear() {
  return new Date().getFullYear();
}

const Footer = () => {
  return (
    <footer className={styles.gtgFooter}>
      <div className="container-app">
        © <FormattedMessage defaultMessage="Copyright" id="common.copyright" />{' '}
        {getYear()} Getinge AB.{' '}
        <FormattedMessage
          defaultMessage="All rights reserved."
          id="common.allRightsReserved"
        />
        <p>
          <FormattedMessage
            defaultMessage="Unless otherwise specified, all product and service names on this website are trademarks owned by or licensed to Getinge AB, its subsidiaries or affiliates. No trademark, trade name, or trade dress on this website may be used without the prior written authorization of Getinge AB. Getinge, Getinge Group, Getinge Passion for Life, Maquet, and Atrium are trademarks or registered trademarks of Getinge AB, its worldwide subsidiaries or affiliates. Org No. 556408-5032"
            id="footer.text1"
          />
        </p>
      </div>
    </footer>
  );
};

export default Footer;
