import React from 'react';
import { connect } from 'react-redux';
import { IntlProvider } from 'react-intl';

import { getDisplayName } from '../../utils/hoc';

import messages_en from '../../i18n/en.json';
import messages_fr from '../../i18n/fr.json';
import messages_es from '../../i18n/es.json';
import messages_it from '../../i18n/it.json';
import messages_de from '../../i18n/de.json';
import '../../i18n/locale_data';

const messages = {
  en: messages_en,
  fr: messages_fr,
  es: messages_es,
  it: messages_it,
  de: messages_de
};

export const withI18n = WrappedComponent => {
  const wrapper = props => {
    const messagesLocale = Object.keys(messages).includes(props.locale)
      ? props.locale
      : Object.keys(messages)[0];

    return (
      <IntlProvider locale={props.locale} messages={messages[messagesLocale]}>
        <WrappedComponent {...props} />
      </IntlProvider>
    );
  };

  const mapStateToProps = state => ({
    locale: state.ui.locale
  });

  wrapper.displayName = `withI18n(${getDisplayName(WrappedComponent)})`;
  return connect(mapStateToProps)(wrapper);
};
