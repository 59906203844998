import propTypes from 'prop-types';

function encodePaginationURI(page, pageSize, sort) {
  if (page) {
    page = 'page=' + encodeURI(page);
  }
  if (pageSize) {
    pageSize = 'pagesize=' + encodeURI(pageSize);
  }
  if (sort) {
    sort = 'sort=' + encodeURI(sort);
  }
  const args = [page, pageSize, sort].filter(a => a);
  return args.length > 0 ? '?' + args.join('&') : '';
}

encodePaginationURI.propTypes = {
  page: propTypes.number,
  pageSize: propTypes.number,
  sort: propTypes.string
};

export default encodePaginationURI;
