import React from 'react';
import PropTypes from 'prop-types';
import './GradientBar.scss';
import { clampValue } from '../../utils/Common';
import colorVariables from '../../../scss/_variables.module.scss';

/**
 * A horizontal gradient bar that can be used to represent a percentage value.
 * If a non-numerical `value` is passed in, it will not display an indicator.
 * @param value Percentage value of where the indicator should be positioned.
 * @param isZeroToOne Indicates that the `value` uses the zero-to-one range for percentages (default: `false`).
 * @param gradientStops Configures where background gradient changes color (default: [0, 37, 75, 100]).
 */
const GradientBar = ({
  value,
  isZeroToOne = false,
  gradientStops = [0, 37, 75, 100]
}) => {
  const hasValue = typeof value === 'number' && !isNaN(value);
  const multiplier = isZeroToOne ? 100 : 1;
  // The clampedValue is guaranteed to be in the range [0, 100]
  const clampedValue = clampValue(value * multiplier, 0, 100);

  let style;
  if (hasValue) {
    let gradient0 = colorVariables['colors-gradient--gradient0'];
    let gradient1 = colorVariables['colors-gradient--gradient1'];
    let gradient2 = colorVariables['colors-gradient--gradient2'];
    let gradient3 = colorVariables['colors-gradient--gradient3'];

    style = {
      backgroundImage: `linear-gradient(to right,
          ${gradient0} ${gradientStops[0]}%,
          ${gradient1} ${gradientStops[1]}%,
          ${gradient2} ${gradientStops[2]}%,
          ${gradient3} ${gradientStops[3]}%)`
    };
  }
  // If value is zero or undefined, gradient bar should have disabled state with 0 value
  return (
    <div className="gtg-gradient-bar" aria-disabled={!hasValue} style={style}>
      <div
        className="gtg-gradient-bar-value-indicator"
        style={{
          left: `${clampedValue}%`
        }}
      />
    </div>
  );
};

GradientBar.propTypes = {
  value: PropTypes.number,
  isZeroToOne: PropTypes.bool
};

export default GradientBar;
