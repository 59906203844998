import React from 'react';
import PropTypes from 'prop-types';

import styles from './DeviceAlert.module.scss';
import { WithTooltip } from '../../hoc';

/**
 *
 * @param {node} heading The alert heading
 * @param {string} description The description that gets displayed (usually failed tests)
 */
const DeviceAlert = ({ heading, description }) => {
  return (
    <React.Fragment>
      <WithTooltip
        tooltipContent={description}
        tooltipLocation="bottom"
        tooltipFixedArrow
        tooltipSmall
      >
        <div className={styles.gtgDeviceAlert}>
          <div className={styles.gtgHeader}>{heading}</div>
          {description && (
            <span className={styles.gtgDescription}>{description}</span>
          )}
        </div>
      </WithTooltip>
    </React.Fragment>
  );
};

DeviceAlert.propTypes = {
  heading: PropTypes.node.isRequired,
  description: PropTypes.string
};

export default DeviceAlert;
