import React from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { routePaths, ServiceOrderList, Icon, PathJoin } from '..';
import { FormattedMessage } from 'react-intl';

const DeviceServiceOrderListWidget = props => {
  const { serviceOrders, deviceID, setData } = props;

  return (
    <>
      <ServiceOrderList
        serviceOrders={serviceOrders}
        deviceID={deviceID}
        showPagination={false}
        setData={setData}
      />
      {serviceOrders && serviceOrders.length > 0 && (
        <Link
          data-cy="viewAllServiceOrdersLink"
          className="card-link"
          to={PathJoin([
            routePaths.DeviceOverview.path,
            deviceID,
            routePaths.DeviceServiceOrderOverview.path
          ])}
        >
          <FormattedMessage id="common.viewAll" defaultMessage="View all" />{' '}
          <Icon name="arrow-right-full" size="sm" />
        </Link>
      )}
    </>
  );
};

export default withRouter(DeviceServiceOrderListWidget);
