import React from 'react';
import {
  Formatted24hTime,
  deviceSwVersionMapper,
  formatValueUnit
} from '../..';
import { FormattedMessage, FormattedDate } from 'react-intl';
import {
  isFlowFamily,
  isServoFamily,
  isRotaflowFamily
} from '../Device/deviceHelper';
import { clampValue } from '../Common';

const getValue = calculatedValue => {
  if (calculatedValue.error) {
    return 'N/A';
  } else {
    switch (calculatedValue.unitOfMeasurement) {
      case 'percent':
        return formatValueUnit(calculatedValue.value, '%');
      case 'hour':
        return formatValueUnit(calculatedValue.value, 'hours');
      case 'string':
      default:
        return calculatedValue.value;
    }
  }
};

/**
 * Map device status received from the API into a presentable object
 * for the frontend.
 * @param {*} status
 */
export const mapDeviceStatus = status => {
  const result = {
    ...status,
    values: []
  };
  let statusItems;

  switch (true) {
    case isFlowFamily(status.deviceType):
      statusItems = {
        cassetteMembraneUsagePercentage: {
          key: (
            <FormattedMessage
              id="deviceStatus.cassetteMembraneRemainingCapacity"
              defaultMessage="Cassette membrane remaining capacity"
            />
          ),
          value:
            status.values.cassetteMembraneUsagePercentage &&
            getValue({
              ...status.values.cassetteMembraneUsagePercentage,
              value: clampValue(
                100 - status.values.cassetteMembraneUsagePercentage.value,
                0,
                100
              )
            })
        },
        totalPatientOperatingTimeHours: {
          key: (
            <FormattedMessage
              id="deviceStatus.totalPatientOperatingTimeHours"
              defaultMessage="Total patient operating time"
            />
          ),
          value:
            status.values.totalPatientOperatingTimeHours &&
            getValue(status.values.totalPatientOperatingTimeHours)
        },
        totalOperatingTimeHours: {
          key: (
            <FormattedMessage
              id="deviceStatus.totalOperatingTimeHours"
              defaultMessage="Total operating time"
            />
          ),
          value:
            status.values.totalOperatingTimeHours &&
            getValue(status.values.totalOperatingTimeHours)
        },
        systemSoftwareVersion: {
          key: (
            <FormattedMessage
              id="deviceStatus.systemSoftwareVersion"
              defaultMessage="System SW Version"
            />
          ),
          value:
            status.values.systemSoftwareVersion &&
            getValue(status.values.systemSoftwareVersion)
        }
      };
      break;
    case isServoFamily(status.deviceType):
      statusItems = {
        o2CellPercentage: {
          key: (
            <FormattedMessage
              id="deviceStatus.o2CellRemainingCapacity"
              defaultMessage="O2 cell remaining capacity"
            />
          ),
          // Invert usage percentage to remaining capacity percentage
          value:
            status.values.o2CellPercentage &&
            getValue({
              ...status.values.o2CellPercentage,
              value: 100 - status.values.o2CellPercentage.value
            })
        },
        o2SensorCalibrationDateTime: {
          key: (
            <FormattedMessage
              id="deviceStatus.o2SensorCalibrationDateTime"
              defaultMessage="O2 sensor calibration date"
            />
          ),
          value: status.values.o2SensorCalibrationDateTime && (
            <>
              <FormattedDate
                value={status.values.o2SensorCalibrationDateTime}
              />{' '}
              <Formatted24hTime
                value={status.values.o2SensorCalibrationDateTime}
                timeZone="UTC"
              />
            </>
          )
        },
        o2SensorFirstUseDate: {
          key: (
            <FormattedMessage
              id="deviceStatus.o2SensorFirstUseDate"
              defaultMessage="O2 sensor first use date"
            />
          ),
          value: status.values.o2SensorFirstUseDate && (
            <FormattedDate value={status.values.o2SensorFirstUseDate} />
          )
        },
        totalPatientOperatingTimeHours: {
          key: (
            <FormattedMessage
              id="deviceStatus.totalPatientOperatingTimeHours"
              defaultMessage="Total patient operating time"
            />
          ),
          value:
            status.values.totalPatientOperatingTimeHours &&
            getValue(status.values.totalPatientOperatingTimeHours)
        },
        totalOperatingTimeHours: {
          key: (
            <FormattedMessage
              id="deviceStatus.totalOperatingTimeHours"
              defaultMessage="Total operating time"
            />
          ),
          value:
            status.values.totalOperatingTimeHours &&
            getValue(status.values.totalOperatingTimeHours)
        },
        systemVersion: {
          key: (
            <FormattedMessage
              id="deviceStatus.systemVersion"
              defaultMessage="System Version"
            />
          ),
          value:
            status.values.systemVersion && getValue(status.values.systemVersion)
        },
        systemSoftwareVersion: {
          key: (
            <FormattedMessage
              id="deviceStatus.systemSoftwareVersion"
              defaultMessage="System SW Version"
            />
          ),
          value:
            status.values.systemSoftwareVersion &&
            deviceSwVersionMapper(
              status.deviceType,
              getValue(status.values.systemSoftwareVersion)
            )
        }
      };
      break;
    case isRotaflowFamily(status.deviceType):
      statusItems = {
        totalPatientOperatingTimeHours: {
          key: (
            <FormattedMessage
              id="deviceStatus.totalPatientOperatingTimeHours"
              defaultMessage="Total patient operating time"
            />
          ),
          value:
            status.values.totalPatientOperatingTimeHours &&
            getValue(status.values.totalPatientOperatingTimeHours)
        },
        totalOperatingTimeHours: {
          key: (
            <FormattedMessage
              id="deviceStatus.totalOperatingTimeHours"
              defaultMessage="Total operating time"
            />
          ),
          value:
            status.values.totalOperatingTimeHours &&
            getValue(status.values.totalOperatingTimeHours)
        },
        systemSoftwareVersion: {
          key: (
            <FormattedMessage
              id="deviceStatus.systemSoftwareVersion"
              defaultMessage="System SW Version"
            />
          ),
          value:
            status.values.systemSoftwareVersion &&
            deviceSwVersionMapper(
              status.deviceType,
              getValue(status.values.systemSoftwareVersion)
            )
        }
      };
      break;
    default:
      console.error('Unsupported device type: ' + status.deviceType);
  }

  result.values =
    statusItems &&
    Object.keys(statusItems)
      .filter(statusItem => status.values[statusItem])
      .map(statusItem => statusItems[statusItem]);
  return result.values && result.values.length > 0 ? result : null;
};
