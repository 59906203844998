import React, { useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Icon, userRoles } from '../..';
import { useSafeListener } from '../../../hooks';

import styles from './RoleSelectionDropdown.module.scss';

const RoleSelectionDropdown = ({
  fetchToken,
  savePersistentState,
  removePersistentState,
  roleChange
}) => {
  const defaultRole = 'Default role';
  const [open, setOpen] = useState(false);
  const handleDocumentClick = () => (open ? setOpen(false) : null);
  const handleDropdownClick = useCallback(() => {
    setOpen(!open);
  }, [open]);

  const handleSelect = (role, certification) => e => {
    e.preventDefault();
    // Reset to default role
    if (role === defaultRole) {
      removePersistentState('roleChange');
      window.location.reload();
    } else {
      const roleChange = {
        role: role,
        certification: certification
      };
      fetchToken(roleChange);
      savePersistentState('roleChange', roleChange);
    }
  };

  const formatCertified = roleChange => {
    return roleChange && roleChange.role
      ? `${roleChange.certification ? 'Certified ' : ''}` +
          userRoles.getDisplayRoleFor(roleChange.role)
      : undefined;
  };

  useSafeListener('click', handleDocumentClick);

  return (
    <div className={styles.gtgRoleSelectionDropdown + ' dropdown ml-auto'}>
      <button
        className={styles.gtgRoleSelectionDropdownBtn + ' btn dropdown-toggle'}
        type="button"
        aria-haspopup="true"
        aria-expanded={open}
        id="roleSelectionDropdownMenuButton"
        onClick={handleDropdownClick}
        data-cy="roleSelectionDropdownButton"
      >
        <Icon name="user-2" size="sm" />
        {formatCertified(roleChange) || 'Roles'}
      </button>
      <div
        className={[
          'dropdown-menu',
          'dropdown-menu-right',
          open ? 'show' : ''
        ].join(' ')}
        aria-labelledby="roleSelectionDropdownMenuButton"
      >
        <Link
          to="#"
          className="dropdown-item"
          data-cy="roleSelectionDropdownItem"
          onClick={handleSelect(userRoles.GetingeOnline_ServiceTechnician)}
        >
          {formatCertified({
            role: userRoles.GetingeOnline_ServiceTechnician
          })}
        </Link>
        <Link
          to="#"
          className="dropdown-item"
          data-cy="roleSelectionDropdownItem"
          onClick={handleSelect(
            userRoles.GetingeOnline_ServiceTechnician,
            true
          )}
        >
          {formatCertified({
            role: userRoles.GetingeOnline_ServiceTechnician,
            certification: true
          })}
        </Link>
        <Link
          to="#"
          onClick={handleSelect(userRoles.GetingeOnline_Clinician)}
          className="dropdown-item"
          data-cy="roleSelectionDropdownItem"
        >
          {formatCertified({
            role: userRoles.GetingeOnline_Clinician
          })}
        </Link>
        <Link
          to="#"
          className="dropdown-item"
          data-cy="roleSelectionDropdownItem"
          onClick={handleSelect(defaultRole)}
        >
          {'My default role'}
        </Link>
      </div>
    </div>
  );
};

RoleSelectionDropdown.propTypes = {
  fetchToken: PropTypes.func.isRequired,
  savePersistentState: PropTypes.func.isRequired,
  removePersistentState: PropTypes.func.isRequired,
  roleChange: PropTypes.shape({
    role: PropTypes.string,
    certification: PropTypes.bool
  })
};

export default RoleSelectionDropdown;
