export const installedBaseListColumns = [
  { header: 'Alert', key: 'alert', width: 30 },
  { header: 'Hospital', key: 'hospital', width: 20 },
  { header: 'Department', key: 'departmentName', width: 20 },
  { header: 'Device', key: 'deviceType', width: 10 },
  { header: 'Serial no', key: 'serial', width: 10 },
  { header: 'Customer device no', key: 'customerID', width: 20 },
  { header: 'SW version', key: 'swVersion', width: 10 },
  {
    header: 'Next PM',
    key: 'next',
    width: 15
  },
  { header: 'Last connected', key: 'lastConnected', width: 15 },
  { header: 'Contract', key: 'contract', width: 15 },
  { header: 'Department filter selection', key: 'department', width: 25 },
  { header: 'Device filter selection', key: 'device', width: 25 }
];

export const installedBaseListFileName = 'Installed_base.xlsx';
