import React from 'react';

const ConnectedDevicesTooltipContent = ({ devices }) => {
  return (
    <React.Fragment>
      {devices.map(device => (
        <dl key={device.name}>
          <dt>{device.name}</dt>
          <dd>{device.count}</dd>
        </dl>
      ))}
    </React.Fragment>
  );
};

export default ConnectedDevicesTooltipContent;
