// Add column headers and define column keys and widths
export const deviceAgentUsageColumns = [
  { header: 'Hospital', key: 'hospital', width: 40 },
  { header: 'Department', key: 'departmentName', width: 40 },
  { header: 'Location', key: 'location', width: 40 },
  { header: 'Device', key: 'deviceType', width: 40 },
  { header: 'Serial no', key: 'serial', width: 20 },
  { header: 'Customer device no', key: 'customerID', width: 20 },
  { header: 'Date', key: 'date', width: 20 },
  { header: 'Cases', key: 'cases', width: 10 },
  { header: 'SEV [mL]', key: 'sev', width: 10 },
  { header: 'DES [mL]', key: 'des', width: 10 },
  { header: 'ISO [mL]', key: 'iso', width: 10 },
  { header: 'FGF [mL]', key: 'fgf', width: 10 }
];

// Define filename
export const deviceAgentUsageFileName =
  'Device_details_Agent_usage_over_time.xlsx';
