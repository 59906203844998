import propTypes from 'prop-types';

export const metricUsageShape = propTypes.arrayOf(
  propTypes.shape({
    year: propTypes.string,
    servoagent: propTypes.string,
    previousYear: propTypes.bool,
    data: propTypes.arrayOf(
      propTypes.shape({
        x: propTypes.string,
        y: propTypes.number,
        value: propTypes.oneOfType([propTypes.string, propTypes.number])
      })
    )
  })
);
