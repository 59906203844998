import React from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { routePaths, PreventiveMaintenanceList, Icon } from '..';
import { FormattedMessage } from 'react-intl';

const PreventiveMaintenanceListWidget = props => {
  const { preventiveMaintenance } = props;

  return (
    <>
      <PreventiveMaintenanceList
        preventiveMaintenance={preventiveMaintenance}
        showPagination={false}
      />
      {preventiveMaintenance && preventiveMaintenance.length > 0 && (
        <Link
          data-cy="linkShowAllPreventiveMaintenance"
          className="card-link"
          to={'/' + routePaths.PreventiveMaintenanceOverview.path}
        >
          <FormattedMessage id="common.viewAll" defaultMessage="View all" />{' '}
          <Icon name="arrow-right-full" size="sm" />
        </Link>
      )}
    </>
  );
};

export default withRouter(PreventiveMaintenanceListWidget);
