import { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

/**
 * Replace target value in a children component with value from persistent store
 * target is property that needs to be replaced
 * stateKey is the persistent state property
 */
const withPersistentState = ({
  stateKey,
  target,
  children,
  persistentState,
  onLoadFromPersistentState
}) => {
  const persistedValue = persistentState[stateKey];
  if (persistedValue) {
    if (onLoadFromPersistentState) {
      /* eslint-disable react-hooks/exhaustive-deps */
      useEffect(() => {
        const handlers =
          onLoadFromPersistentState instanceof Array
            ? onLoadFromPersistentState
            : [onLoadFromPersistentState];
        handlers.forEach(handler => {
          handler(persistedValue);
        });
      }, [persistedValue]);
      /* eslint-enable react-hooks/exhaustive-deps */
    }
    return {
      ...children,
      props: {
        ...children.props,
        [target]: persistedValue
      }
    };
  }
  return children;
};

withPersistentState.propTypes = {
  stateKey: PropTypes.string.isRequired,
  target: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  onLoadFromPersistentState: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.arrayOf(PropTypes.func)
  ])
};

const mapStateToProps = state => ({
  persistentState: state.persistentState.persistentState
});

export default connect(mapStateToProps)(withPersistentState);
