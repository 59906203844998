import { apiRequest, API_SUCCESS } from '../actions/api';
import {
  FETCH_DEVICE_STATUS,
  setDeviceStatus,
  entity
} from '../actions/deviceStatus';
import { API_URLS } from './urls';

const URL = API_URLS.Devices;

export const deviceStatusMdl = ({ dispatch }) => next => action => {
  next(action);

  const { type } = action;

  switch (type) {
    case FETCH_DEVICE_STATUS:
      dispatch(
        apiRequest({
          method: 'GET',
          url: `${URL.url}/${action.payload.id}/status`,
          requestSignature: 'deviceStatus',
          entity: entity
        })
      );
      break;
    case `${entity} GET ${API_SUCCESS}`:
      dispatch(setDeviceStatus(action.payload));
    // no default
  }
};
