import React, { useMemo, useCallback, useState, useEffect } from 'react';
import { connect, shallowEqual, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import styles from './SoftwareUpload.module.scss';
import { uploadSoftware } from '../../redux/actions/admin';
import {
  connectivityNodes,
  getDeviceTypeDisplayName
} from '../utils/Device/deviceHelper';
import { Select, Spinner, isRequestPending } from '..';
import PropTypes from 'prop-types';

const SoftwareUpload = ({ uploadResult, pendingRequests, uploadSoftware }) => {
  const [isUploading, setIsUploading] = useState(false);

  // Values used in the Select component
  const selectValues = useMemo(() => {
    return Object.entries(connectivityNodes || []).map(([key, value]) => {
      return {
        value: value,
        label: getDeviceTypeDisplayName(value)
      };
    });
  }, []);

  const [cnType, setCNType] = useState(
    selectValues ? selectValues[0].value : ''
  );
  const { register, handleSubmit } = useForm();
  const onSubmit = data => {
    const fd = new FormData();
    fd.append('data', data.file[0]);
    uploadSoftware(fd, cnType);
  };

  let uploadResultElement = <></>;
  switch (uploadResult) {
    case false:
      uploadResultElement = <>Error uploading software</>;
      break;
    case true:
      uploadResultElement = <>Software uploaded successfully</>;
      break;
    default:
      break;
  }

  // Update the state value and possible errors for a giver property
  const updateValue = useCallback(
    (value, name) => {
      setCNType(value);
    },
    [setCNType]
  );

  useEffect(() => {
    setIsUploading(isRequestPending('adminUploadSoftware', pendingRequests));
  }, [pendingRequests, setIsUploading]);

  return (
    <div className={styles.softwareUpload}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <input {...register('file')} type="file" />
        <div className={styles.selectType}>
          <Select
            label={
              <FormattedMessage
                id="admin.softwareUploadType"
                defaultMessage="Connectivity module type:"
              />
            }
            value={cnType}
            setValue={updateValue}
            allValues={selectValues}
            postfix
          ></Select>
        </div>
        <div className={styles.softwareUploadSubmit}>
          <input
            className="btn btn-info"
            type="submit"
            disabled={isUploading}
          />
          {isUploading && <Spinner />}
        </div>
      </form>
      {uploadResultElement}
    </div>
  );
};

const mapStateToProps = state => ({
  uploadResult: state.admin.uploadSoftwareResult,
  pendingRequests: state.api.pendingRequests
});
const mapDispatchToProps = {
  uploadSoftware: uploadSoftware
};

SoftwareUpload.propTypes = {
  uploadResult: PropTypes.bool,
  uploadSoftware: PropTypes.func
};

export default connect(mapStateToProps, mapDispatchToProps)(SoftwareUpload);
