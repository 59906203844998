import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { CaseMetrics, TrendsList, EmptyState, Icon } from '..';
import propTypes from 'prop-types';
import {
  CASE_DETAILS_GAS_CONSUMPTION,
  caseDetailsGasConsumptionFileName,
  createDocument
} from '../../utils/export';

const CaseGasConsumptionWidget = ({
  metrics,
  device,
  caseStart,
  caseEnd,
  filters
}) => {
  const filterGasConsumptionMetrics = metrics
    ? metrics.filter(metric =>
        [
          ...CaseMetrics,
          {
            key: 'o2',
            gasConsumptionDisplay: true
          }
        ]
          .filter(caseMetric => caseMetric.gasConsumptionDisplay)
          .map(caseMetric => caseMetric.key)
          .includes(metric.key)
      )
    : [];

  const exportGasConsumptionMetrics = useCallback(() => {
    createDocument(
      CASE_DETAILS_GAS_CONSUMPTION,
      filterGasConsumptionMetrics || [],
      filters,
      caseDetailsGasConsumptionFileName,
      {
        device: device,
        caseStart: caseStart,
        caseEnd: caseEnd
      }
    );
  }, [filterGasConsumptionMetrics, device]);

  return (
    (filterGasConsumptionMetrics &&
      filterGasConsumptionMetrics.length > 0 && (
        <>
          <div className="clearfix">
            <h5 className="mb-3 float-left">
              <FormattedMessage
                id="case.gasConsumption"
                defaultMessage="Gas consumption"
              />
            </h5>
            <div className="float-right">
              <Link onClick={exportGasConsumptionMetrics} to="#">
                <Icon name="download-excel" size="sm" />
              </Link>
            </div>
          </div>
          <TrendsList
            data={filterGasConsumptionMetrics}
            includeTrends={false}
            keyType="labelShort"
          />
        </>
      )) || <EmptyState />
  );
};

CaseGasConsumptionWidget.propTypes = {
  metrics: propTypes.arrayOf(
    propTypes.shape({
      key: propTypes.string,
      unit: propTypes.string,
      value: propTypes.number
    })
  )
};

export default CaseGasConsumptionWidget;
