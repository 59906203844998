export const entity = '[Devices running hours]';
export const FETCH_DEVICES_RUNNING_HOURS = `${entity} Fetch`;
export const SET_DEVICES_RUNNING_HOURS = `${entity} Set`;

/**
 * Trigger fetch of devices with running hours
 */
export const fetchDevicesRunningHours = (
  timeSpan,
  count,
  paginationSettings,
  sortingSettings
) => ({
  type: FETCH_DEVICES_RUNNING_HOURS,
  timeSpan: timeSpan,
  count: count,
  pagination: paginationSettings,
  sorting: sortingSettings
});

/**
 * Set data as devices running hours
 * @param {array} data Devices with running hours data
 */
export const setDevicesRunningHours = data => ({
  type: SET_DEVICES_RUNNING_HOURS,
  payload: data
});
