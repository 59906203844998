import { FormattedMessage } from 'react-intl';

function KeyFromFormattedMessage(el) {
  if (el.type === FormattedMessage || el.type === 'div') {
    el = el.props.id;
  }
  return el;
}

export default KeyFromFormattedMessage;
