import React, { useState, useCallback, useMemo } from 'react';
import classnames from 'classnames';
import propTypes from 'prop-types';
import moment from 'moment';
import {
  FlexibleWidthXYPlot,
  LineSeries,
  XAxis,
  YAxis,
  HorizontalGridLines,
  ChartLabel,
  Hint
} from 'react-vis';

import {
  AgentColorByKey,
  uuidv4,
  MetricsConfig,
  AgentKeyMapper,
  formatValueUnit
} from '../../components';
import './CaseTimelineChart.scss';
import colorVariables from '../../scss/_variables.module.scss';

/**
 * Map Agent usage timeline data to separate line series data
 * Data points are grouped by agents
 * @param {Array<Object>} data Agent usage timeline data
 * @returns {Array<Object>} Array of data points objects grouped by agents
 */
export function agentUsageMapper(data) {
  let agentsLineSeriesArray = [];
  const scaleFactor = 1;
  for (let i = 0; i < data.length; i++) {
    if (data[i + 1]) {
      const agent = AgentKeyMapper(data[i].agent, 'labelShort');
      if (
        i > 0 &&
        agentsLineSeriesArray[agentsLineSeriesArray.length - 1].agent.key ===
          agent.key
      ) {
        // group sequental data points that have the same agent
        agentsLineSeriesArray[agentsLineSeriesArray.length - 1].values.push(
          lineSeriesDataPointMapper(data[i + 1], scaleFactor)
        );
      } else {
        // otherwise create a new object of values
        agentsLineSeriesArray.push({
          agent: agent,
          color: AgentColorByKey(agent.key),
          values: [data[i], data[i + 1]].map(val =>
            lineSeriesDataPointMapper(val, scaleFactor)
          )
        });
      }
    }
  }
  return agentsLineSeriesArray;
}

/**
 * Map data point to chart node
 * @param {Object} point Data point with timestamp and value
 * @param {number} scaleFactor Y value scale factor
 * @returns {Object} Node with x and y value
 */
export function lineSeriesDataPointMapper(point, scaleFactor = 1) {
  return {
    x: moment(point.timestamp).unix() * 1000,
    y: point.value * scaleFactor
  };
}

/**
 * Map array of data points to array of chart nodes
 * @param {Array<Object>} data Array of data points with timestamp and value
 * @param {number} scaleFactor Scale factor - used for normalizing different value ranges on the same y scale
 * @returns {Array<Object>} Array of nodes with x and y value
 */
export function lineSeriesMapper(data, scaleFactor = 1) {
  if (!data || !data.values) {
    return null;
  }
  // using JSON parse and stringify to clone data
  const newData = JSON.parse(JSON.stringify(data.values)).map(item =>
    lineSeriesDataPointMapper(item, scaleFactor)
  );
  return newData;
}

/**
 * Map timeline metric key to label
 * @param {String} key
 * @returns {String} Metric label
 */
export function timelineKeyMapper(key) {
  switch (key.toLowerCase()) {
    case 'freshgasflow':
      return 'FGF';
    case 'macy':
      return 'MAC Y';
    case 'macbrain':
      return 'MAC Brain';
    default:
      return key;
  }
}

const axisStyles = {
  line: { stroke: '#565959', strokeWidth: 0 },
  ticks: { stroke: '#565959' },
  text: { stroke: 'none', fill: '#6b6b76', fontWeight: 600 }
};

const gridLinesStyles = {
  stroke: 'rgba(86,89,89,.1)',
  strokeWidth: 1
};

const CaseTimelineChart = ({ caseTimelineData }) => {
  // Initial chart settings
  const initialSettings = {
    useNonLinearYAxis: true,
    enableMacY: true,
    enableMacBrain: true
  };

  // Max agent value is needed do determine agent axis range
  const maxAgentValue =
    MetricsConfig &&
    Math.max(
      MetricsConfig.maxValues.des,
      MetricsConfig.maxValues.iso,
      MetricsConfig.maxValues.sev
    );

  // Scale factors are calculated based on maxAgentValue and max value for each metric
  const scaleFactors = useMemo(() => {
    return (
      maxAgentValue &&
      MetricsConfig && {
        agent: 1,
        freshGasFlow: maxAgentValue / MetricsConfig.maxValues.freshGasFlow,
        macY: maxAgentValue / MetricsConfig.maxValues.macY,
        macBrain: maxAgentValue / MetricsConfig.maxValues.macBrain
      }
    );
  }, [maxAgentValue]);

  // Create data object with all the mapped series data
  const data = {
    agent:
      caseTimelineData &&
      scaleFactors &&
      agentUsageMapper(caseTimelineData.agentUsage),
    freshGasFlow:
      caseTimelineData &&
      scaleFactors &&
      lineSeriesMapper(
        caseTimelineData.timeline.find(t => t.key === 'FreshGasFlow'),
        scaleFactors.freshGasFlow
      ),
    macBrain:
      caseTimelineData &&
      scaleFactors &&
      lineSeriesMapper(
        caseTimelineData.timeline.find(t => t.key === 'MacBrain'),
        scaleFactors.macBrain
      ),
    macY:
      caseTimelineData &&
      scaleFactors &&
      lineSeriesMapper(
        caseTimelineData.timeline.find(t => t.key === 'MacY'),
        scaleFactors.macY
      )
  };

  // Mac Y and Mac Brain toggles
  const [macYToggle, setMacYToggle] = useState(initialSettings.enableMacY);
  const [macBrainToggle, setMacBrainToggle] = useState(
    initialSettings.enableMacBrain
  );
  // hoveredNode activates tooltip
  const [hoveredNode, setHoveredNode] = useState(false);

  /* eslint-disable react-hooks/exhaustive-deps */
  /**
   * hoverHandler attaches available metrics data to node
   * to be displayed in the tooltip for a given timestamp
   */
  const hoverHandler = useCallback(node => {
    let hoveredNode = {};
    hoveredNode.node = node;
    // find which agent is active at a given time (node.x)
    const agent = data.agent.find(agent => {
      return agent.values.find(value => value.x === node.x);
    });
    // find agent node for given x
    const agentNode = agent && agent.values.find(value => value.x === node.x);

    // Add formatted time
    let tooltipData = [
      {
        value: moment(node.x).format('HH:mm')
      }
    ];

    // Add agent information
    tooltipData = [
      ...tooltipData,
      agentNode && {
        key: 'metric',
        label: agent.agent.labelShort,
        value: agentNode.y / scaleFactors.agent,
        unit: '%'
      }
    ];

    // Add metrics information (fgf, macY, macBrain)
    tooltipData = [
      ...tooltipData,
      ...Object.keys(data)
        .map(dataKey => {
          const dataPoint = data[dataKey].find(el => el.x === node.x);
          return (
            dataPoint && {
              key: dataKey,
              label: timelineKeyMapper(dataKey),
              value: (dataPoint.y / scaleFactors[dataKey]).toFixed(2),
              // Hide units for MAC values as they are unitless, even though API returns % (GDP-1371)
              unit:
                !['macY', 'macBrain'].includes(dataKey) &&
                caseTimelineData.timeline.find(
                  el => el.key.toLowerCase() === dataKey.toLowerCase()
                ).unit
            }
          );
        })
        // filter out unavailable elements
        .filter(el => el)
    ];
    hoveredNode.data = tooltipData;
    setHoveredNode(hoveredNode);
  });
  /* eslint-enable react-hooks/exhaustive-deps */

  /**
   * mouseLeaveHandler hides tooltip
   */
  const mouseLeaveHandler = useCallback(() => {
    setHoveredNode(null);
  }, [setHoveredNode]);

  // format Fresh gas flow axis ticks by de-scaling mapped values to original values
  const FgfAxisTickFormatter = useCallback(
    v => {
      return Math.round((v / scaleFactors.freshGasFlow) * 10) / 10;
    },
    [scaleFactors]
  );

  // format Agent usage axis ticks
  const AgentAxisTickFormatter = useCallback(
    v => {
      return v * scaleFactors.agent + ' %';
    },
    [scaleFactors]
  );

  /* eslint-disable react-hooks/exhaustive-deps */
  // format time axis from unix to HH:mm
  const TimeAxisTickFormatter = useCallback(v => {
    return moment(v).format('HH:mm');
  });
  /* eslint-enable react-hooks/exhaustive-deps */

  // Agents legend
  const AgentsLegend = () => {
    const fgf = 'fgf';
    const agents = ['sev', 'iso', 'des'];
    // only include available agents
    const legendItems = [
      data.freshGasFlow && AgentKeyMapper(fgf, 'labelShort'),
      ...agents.map(agent => {
        return (
          data.agent.find(el => el.agent.labelShort.toLowerCase() === agent) &&
          AgentKeyMapper(agent, 'labelShort')
        );
      })
    ].filter(el => el);
    return (
      <div className="d-flex align-items-center">
        {legendItems.map(item => {
          return (
            <label
              key={uuidv4()}
              className="d-flex align-items-center m-0 mr-4"
            >
              {item.icon}
              {item.labelShort}
            </label>
          );
        })}
      </div>
    );
  };

  // MAC legend with toggles for Mac Y and Mac Brain
  // will return empty legend if mac data not available
  /* eslint-disable react-hooks/rules-of-hooks */
  const MacLegend = () => {
    const legendItems = [
      data.macY && {
        id: 'macY',
        label: 'MAC Y',
        toggle: macYToggle,
        toggleHandler: useCallback(() => setMacYToggle(!macYToggle), [])
      },
      data.macBrain && {
        id: 'macBrain',
        label: 'MAC Brain',
        toggle: macBrainToggle,
        toggleHandler: useCallback(() => setMacBrainToggle(!macBrainToggle), [])
      }
    ].filter(el => el);
    return (
      <div className="d-flex align-items-center">
        {legendItems.map(item => {
          return (
            <label
              key={uuidv4()}
              onClick={item.toggleHandler}
              data-mac={item.id}
              className={classnames(
                'gtg-legend-toggle',
                item.toggle ? 'on' : 'off',
                'd-flex',
                'align-items-center',
                'm-0',
                'ml-4'
              )}
            >
              <LegendDotToggle toggle={item.toggle} />
              {item.label}
            </label>
          );
        })}
      </div>
    );
  };

  // Custom hint component
  const CustomHint = hoveredNode => {
    return (
      <Hint value={hoveredNode.node}>
        <div className="gtg-case-timeline-chart-tooltip">
          {hoveredNode.data.map((el, i) => {
            // filter mac y and mac brain from tooltip if they are not enabled
            if (
              (el.key === 'macY' && !macYToggle) ||
              (el.key === 'macBrain' && !macBrainToggle)
            ) {
              return false;
            }
            const rowClassNames =
              i === 0
                ? // font weight bold for time
                  ['font-weight-bold']
                : // space other list items
                  ['d-flex', 'justify-content-between'];
            return (
              <div key={uuidv4()} className={classnames(rowClassNames)}>
                {el.label && <span className="mr-3">{el.label}</span>}
                <span>
                  {i === 0
                    ? // Time
                      el.value
                    : // Metric
                      formatValueUnit(el.value, el.unit || '', el.key)}
                </span>
              </div>
            );
          })}
        </div>
      </Hint>
    );
  };

  // Legend dot toggle component with check icon
  const LegendDotToggle = () => {
    return (
      <>
        <span className="gtg-agent-dot gtg-legend-toggle-dot"></span>
        <span className="gtg-legend-toggle-line"></span>
      </>
    );
  };

  // Controls the scaling of Y axis, based on useNonLinearYAxis setting
  const yScaleDomain = (initialSettings.useNonLinearYAxis && {
    yDomain: [0, maxAgentValue / 5, maxAgentValue],
    yRange: [310, 150, 0]
  }) || {
    yDomain: [0, maxAgentValue]
  };

  return (
    <div
      className="gtg-case-timeline-chart position-relative"
      style={{ maxWidth: 900 }}
    >
      <FlexibleWidthXYPlot
        margin={{
          right: 70,
          left: 70,
          top: 40,
          bottom: 70
        }}
        height={440}
        {...yScaleDomain}
        onMouseLeave={mouseLeaveHandler}
      >
        <XAxis
          attr="x"
          attrAxis="y"
          orientation="bottom"
          tickFormat={TimeAxisTickFormatter}
          style={{
            line: { stroke: '#565959', strokeWidth: 1 }
          }}
        />
        <YAxis
          orientation="left"
          position="start"
          style={axisStyles}
          tickTotal={4}
          tickFormat={AgentAxisTickFormatter}
        />
        <YAxis
          orientation="right"
          style={axisStyles}
          tickTotal={4}
          tickFormat={FgfAxisTickFormatter}
        />
        <HorizontalGridLines style={gridLinesStyles} />
        <ChartLabel
          text="Time"
          includeMargin={false}
          xPercent={0.5}
          yPercent={1.26}
          style={{
            textAnchor: 'middle'
          }}
        />
        <ChartLabel
          text="Agent"
          includeMargin={false}
          xPercent={-0.065}
          yPercent={0.65}
          style={{
            transform: 'rotate(-90)',
            textAnchor: 'middle'
          }}
        />
        <ChartLabel
          text="Fresh gas flow L/min"
          className="alt-y-label"
          includeMargin={false}
          xPercent={1.07}
          yPercent={0.65}
          style={{
            transform: 'rotate(-90)',
            textAnchor: 'middle'
          }}
        />
        {data.freshGasFlow && (
          <LineSeries
            curve={null}
            opacity={1}
            strokeStyle="solid"
            style={{}}
            strokeWidth={2}
            stroke={colorVariables['colors-chart--color-avg-fgf']}
            data={data.freshGasFlow}
            onNearestX={hoverHandler}
          />
        )}
        {data.macY && macYToggle && (
          <LineSeries
            curve={null}
            opacity={1}
            strokeStyle="dashed"
            style={{}}
            strokeWidth={1}
            stroke={colorVariables['colors-getinge--cliff']}
            data={data.macY}
            onNearestX={hoverHandler}
          />
        )}
        {data.macBrain && macBrainToggle && (
          <LineSeries
            curve={null}
            opacity={1}
            strokeStyle="solid"
            style={{}}
            strokeWidth={1}
            stroke={colorVariables['colors-getinge--cliff']}
            data={data.macBrain}
            onNearestX={hoverHandler}
          />
        )}
        {data.agent &&
          data.agent.map(line => {
            // create a separate LineSeries for each set of agent data
            return (
              <LineSeries
                key={uuidv4()}
                curve={null}
                stroke={line.color}
                opacity={1}
                strokeStyle="solid"
                style={{}}
                strokeWidth={4}
                data={line.values}
              />
            );
          })}
        {hoveredNode && CustomHint(hoveredNode)}
      </FlexibleWidthXYPlot>
      <div className="gtg-case-timeline-chart-legend d-flex justify-content-between py-2">
        {(data.agent || data.freshGasFlow) && <AgentsLegend />}
        {(data.macY || data.macBrain) && <MacLegend />}
      </div>
    </div>
  );
};

CaseTimelineChart.propTypes = {
  caseTimelineData: propTypes.shape({
    agentUsage: propTypes.arrayOf(
      propTypes.shape({
        agent: propTypes.oneOf(['Iso', 'Sev', 'Des']),
        timestamp: propTypes.string,
        value: propTypes.number
      })
    ),
    timeline: propTypes.arrayOf(
      propTypes.shape({
        key: propTypes.oneOf(['MacY', 'MacBrain', 'FreshGasFlow']),
        unit: propTypes.string,
        values: propTypes.arrayOf(
          propTypes.shape({
            timestamp: propTypes.string,
            value: propTypes.number
          })
        )
      })
    )
  })
};

export default CaseTimelineChart;
