export const entity = '[Costs]';
export const FETCH_COSTS = `${entity} Fetch`;
export const PUT_COSTS = `${entity} Put`;
export const SET_COSTS = `${entity} Set`;
export const SET_TEMP_COSTS = `[Temporary Costs] Set`;

/**
 * Trigger fetch of costs
 */
export const fetchCosts = () => ({
  type: FETCH_COSTS
});

/**
 * Trigger update of costs
 */
export const putCosts = costs => ({
  type: PUT_COSTS,
  payload: {
    costs
  }
});

/**
 * Set data as costs
 * @param {array} data New costs information
 */
export const setCosts = data => ({
  type: SET_COSTS,
  payload: data
});

/**
 * Set data as temp costs so we do not have to refetch costs from the api after PUT_COSTS method.
 * @param {array} data New costs information
 */
export const setTempCosts = data => ({
  type: SET_TEMP_COSTS,
  payload: data
});
