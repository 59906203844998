import PropTypes from 'prop-types';

export const userInfoShape = PropTypes.shape({
  company_codes: PropTypes.arrayOf(
    PropTypes.shape({
      sap_account_id: PropTypes.string
    })
  ),
  country_code: PropTypes.string,
  email: PropTypes.string,
  family_name: PropTypes.string,
  given_name: PropTypes.string,
  job_title: PropTypes.string,
  manager: PropTypes.string,
  phone_number: PropTypes.string,
  preferred_username: PropTypes.string,
  product_certifications: PropTypes.arrayOf(
    PropTypes.shape({
      device_type: PropTypes.string,
      expiry_date: PropTypes.string
    })
  ),
  region_codes: PropTypes.arrayOf(
    PropTypes.shape({
      region_code: PropTypes.string,
      name: PropTypes.string
    })
  ),
  role: PropTypes.string,
  unique_name: PropTypes.string
});
