import { entity } from '../actions/troubleshootingGuidance';
import { apiRequest, API_SUCCESS } from '../actions/api';
import {
  FETCH_TROUBLESHOOTING_GUIDANCE,
  setTroubleshootingGuidance
} from '../actions/troubleshootingGuidance';
import { API_URLS } from './urls';

const URL = API_URLS.TroubleshootingGuidance;

export const troubleshootingGuidanceMdl = ({ dispatch }) => next => action => {
  next(action);

  const { type } = action;

  switch (type) {
    case FETCH_TROUBLESHOOTING_GUIDANCE:
      const { deviceID, failureID, testName } = action.payload;
      const url = URL(deviceID);

      dispatch(setTroubleshootingGuidance(null));
      dispatch(
        apiRequest({
          method: 'GET',
          url: `${url.url}?failureID=${failureID}&testName=${encodeURIComponent(
            testName
          )}`,
          requestSignature: 'troubleshootingGuidance',
          entity: entity
        })
      );
      break;
    case `${entity} GET ${API_SUCCESS}`:
      dispatch(setTroubleshootingGuidance(action.payload.data));
      break;
    // no default
  }
};
