import React, { useCallback, useState, useMemo, useRef } from 'react';
import { compose } from 'redux';
import classnames from 'classnames';
import {
  FormattedDate,
  FormattedMessage,
  injectIntl,
  intlShape
} from 'react-intl';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import {
  DataTable,
  EmptyState,
  FilterInput,
  Formatted24hTime,
  Scrollable
} from '../..';
import './AdminLogList.scss';

const AdminLogList = ({ logs, intl, triggerElementRef }) => {
  const [inputVal, setInputVal] = useState('');

  const handleChange = useCallback(event => {
    const value = event.target.value;
    setInputVal(value);
  }, []);

  const handleInputClear = useCallback(() => {
    setInputVal('');
  }, [setInputVal]);

  const filteredDeviceLogs = useMemo(() => {
    const items = logs || [];

    return items.filter(
      item => item.message.includes(inputVal) || item.source.includes(inputVal)
    );
  }, [logs, inputVal]);

  let columns = [
    {
      Header: <FormattedMessage id="column.time" defaultMessage="Time" />,
      accessor: 'timestamp',
      sortable: false,
      width: 140,
      Cell: row =>
        (row.value && (
          <React.Fragment>
            {row.value && (
              <>
                <FormattedDate value={row.value} />{' '}
                <Formatted24hTime value={row.value} />
              </>
            )}
          </React.Fragment>
        )) || (
          <FormattedMessage id="common.notAvailableAbbr" defaultMessage="N/A" />
        ),
      noOverflowTooltip: true
    },
    {
      Header: <FormattedMessage id="column.message" defaultMessage="Message" />,
      accessor: 'message',
      sortable: false,
      Cell: row => <span title={row.value}>{row.value}</span>,
      noOverflowTooltip: true
    },
    {
      Header: <FormattedMessage id="column.service" defaultMessage="Service" />,
      accessor: 'source',
      sortable: false,
      width: 140,
      noOverflowTooltip: true
    },
    {
      // Additional column that attaches a ref on the n - 1 element in the list. When user only has 10 items remaining
      // to scroll through, the element will be brought into view and trigger a fetch. It could be attached to any existing
      // cell, but is separated for clarity.
      Cell: row => {
        if (row.index === filteredDeviceLogs.length - 1) {
          return <div ref={triggerElementRef} />;
        }

        return <div />;
      },
      width: 1,
      noOverflowTooltip: true
    }
  ];

  const logsContainer = useRef(null);

  let table = <EmptyState />;
  if (filteredDeviceLogs.length > 0) {
    table = (
      <Scrollable
        minElementHeight={window.innerHeight * 0.55}
        ref={logsContainer}
        options={{
          scrollbars: {
            visibility: 'hidden'
          }
        }}
      >
        <DataTable
          pageSize={[100000]}
          data={filteredDeviceLogs}
          className={classnames('mb-4', 'gtg-admin-logs-list')}
          columns={columns}
          withOverflow
        />
      </Scrollable>
    );
  }

  return (
    <React.Fragment>
      <FilterInput
        onInputClear={handleInputClear}
        value={inputVal}
        onChange={handleChange}
        placeholder={intl.formatMessage({
          id: 'admin.search',
          defaultMessage: 'Search ...'
        })}
        className={'gtg-admin-logs-filter-input'}
      />
      {table}
    </React.Fragment>
  );
};

AdminLogList.propTypes = {
  intl: intlShape,
  logs: PropTypes.arrayOf(
    PropTypes.shape({
      timestamp: PropTypes.string,
      message: PropTypes.string,
      source: PropTypes.string
    })
  )
};

export default compose(withRouter)(injectIntl(AdminLogList));
