import React, { useState, useCallback } from 'react';

const Notification = () => {
  const [visible, setVisible] = useState(true);

  const hide = useCallback(() => setVisible(false), []);

  return visible ? (
    <div style={styles.container} data-cy="deprecation-notice">
      <span style={styles.message}>
        ACT Getinge Online Portal was merged with DHS Getinge Online Portal and
        rebranded to FleetView. The Portal you are currently visiting will be
        live only until the end of September and then shut down. Please visit
        the FleetView Portal at fleetview.getinge.com and get acquainted with
        the new portal. Your experience should be very similar. In case of
        difficulties please reach out to your existing support channels.
      </span>
      <button onClick={hide} style={styles.closeButton}>
        ✖
      </button>
    </div>
  ) : null;
};

const styles = {
  container: {
    position: 'fixed',
    bottom: '20px',
    right: '20px',
    left: '50%',
    zIndex: 9999, // makes sure it stays on top of other content
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#fdecea',
    border: '1.5px solid #e94f35',
    color: '#a94442',
    padding: '10px 15px',
    borderRadius: '5px',
    fontFamily: 'Arial, sans-serif',
    boxShadow: '0 2px 10px rgba(0,0,0,0.2)'
  },
  message: {
    flex: 1
  },
  closeButton: {
    background: 'none',
    border: 'none',
    color: '#a94442',
    fontSize: '16px',
    cursor: 'pointer'
  }
};

export default Notification;
