import { SET_SERVICE_ORDERS } from '../actions/serviceOrders';

const initState = {
  serviceOrders: []
};

export const serviceOrdersReducer = (state = initState, action) => {
  const { payload, type } = action;

  switch (type) {
    case SET_SERVICE_ORDERS:
      return {
        ...state,
        serviceOrders: payload
      };
    default:
      return state;
  }
};
