import React from 'react';
import { NavLink } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { routePaths } from '../..';

import styles from './NotFound.module.scss';

const notFound = props => (
  <div className={styles.gtgNotFound}>
    <h1>
      <FormattedMessage
        defaultMessage="It’s not you. It’s us, we cannot find this page."
        id="404.title"
      />
    </h1>
    <p>
      <FormattedMessage
        defaultMessage="We suggest you try either form dashboard or your installed base."
        id="404.subTitle"
      />
    </p>

    <section className="row">
      <div className="col-12 col-md-6 col-lg-3">
        <NavLink to="/" exact className="nav-link">
          <FormattedMessage id="common.dashboard" defaultMessage="Dashboard" />
        </NavLink>
      </div>
      <div className="col-12 col-md-6 col-lg-3">
        <NavLink to={'/' + routePaths.DeviceOverview.path} className="nav-link">
          <FormattedMessage
            id="common.installedBase"
            defaultMessage="Installed base"
          />
        </NavLink>
      </div>
      <div className="col-12 col-md-6 col-lg-3">
        <NavLink to="/preventive-maintenance" className="nav-link disabled">
          <FormattedMessage
            id="common.preventiveMaintenance"
            defaultMessage="Preventive maintenance"
          />
        </NavLink>
      </div>
      <div className="col-12 col-md-6 col-lg-3">
        <NavLink to="/documentation" className="nav-link disabled">
          <FormattedMessage
            id="navbar.documentation"
            defaultMessage="Documentation"
          />
        </NavLink>
      </div>
    </section>
  </div>
);
export default notFound;
