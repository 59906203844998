// Add column headers and define column keys and widths
export const detailedUsageStatisticsColumns = [
  { header: 'Hospital', key: 'hospital', width: 40 },
  { header: 'Start date', key: 'from', width: 20 },
  { header: 'End date', key: 'to', width: 20 },
  { header: 'Total cases', key: 'cases', width: 10 },
  { header: 'Case average', key: 'average', width: 10 },
  { header: 'Average cost per min', key: 'averagePerMin', width: 10 },
  { header: 'Average cost per case', key: 'averagePerCase', width: 10 },
  { header: 'AGC cases', key: 'casesAgc', width: 10 },
  { header: 'Carbon footprint', key: 'carbonFootprint', width: 10 },
  { header: 'Adoption metrics - AGC', key: 'adoptionAgc', width: 10 },
  { header: 'Adoption metrics - FGF', key: 'adoptionFgf', width: 10 },
  { header: 'Adoption metrics - SEV', key: 'adoptionSev', width: 10 },
  { header: 'Adoption metrics - ISO', key: 'adoptionIso', width: 10 },
  { header: 'Adoption metrics - DES', key: 'adoptionDes', width: 10 },
  { header: 'Adoption metrics - FiO2', key: 'adoptionFio2', width: 10 },
  {
    header: 'Adoption metrics - Recruitment maneuver',
    key: 'adoptionRM',
    width: 10
  },
  { header: 'Ventilation mode - manual', key: 'ventilationManual', width: 10 },
  { header: 'Ventilation mode - AFGO', key: 'ventilationAfgo', width: 10 },
  { header: 'Ventilation mode - PC', key: 'ventilationPc', width: 10 },
  { header: 'Ventilation mode - VC', key: 'ventilationVc', width: 10 },
  { header: 'Ventilation mode - PRVC', key: 'ventilationPrvc', width: 10 },
  { header: 'Ventilation mode - PS', key: 'ventilationPs', width: 10 },
  {
    header: 'Ventilation mode - PS backup',
    key: 'ventilationPsBac',
    width: 10
  },
  {
    header: 'Ventilation mode - SIMV (VC) + PS',
    key: 'ventilationVcPs',
    width: 10
  },
  { header: 'Ventilation mode - PCPS', key: 'ventilationPcPs', width: 10 },
  { header: 'Department filter selection', key: 'department', width: 40 },
  { header: 'Device filter selection', key: 'device', width: 40 }
];

// Define filename
export const detailedUsageStatisticsFileName = 'Detailed_usage_statistics.xlsx';
