import { FETCH_CONFIG, SET_CONFIG, setConfig } from '../actions/config';
import { API_URLS } from './urls';

import GA4 from '../../utils/ganalytics';

const URL = API_URLS.Config;

export const configMdl = ({ dispatch }) => next => action => {
  next(action);

  const { type } = action;
  switch (type) {
    case FETCH_CONFIG:
      try {
        fetch(`${process.env.PUBLIC_URL}${URL.url}`).then(async response => {
          const generalConfig = await response.json();

          dispatch(setConfig(generalConfig));
        });
      } catch (err) {
        console.error(
          `Encountered an error while loading the general config file.`
        );
        console.error(err);
      }
      break;
    case SET_CONFIG:
      const gaTrackingCode = action?.payload?.gaTrackingCode;
      if (gaTrackingCode) {
        GA4.initialize(gaTrackingCode);
      }
      break;
    // no default
  }
};
