export const entity = '[Service orders overview]';
export const FETCH_SERVICE_ORDERS_OVERVIEW = `${entity} Fetch`;
export const SET_SERVICE_ORDERS_OVERVIEW = `${entity} Set`;

/**
 * Trigger fetch of service orders overview
 */
export const fetchServiceOrdersOverview = () => ({
  type: FETCH_SERVICE_ORDERS_OVERVIEW
});

/**
 * Set data as new service orders overview
 * @param {array} data New service orders overview
 */
export const setServiceOrdersOverview = data => ({
  type: SET_SERVICE_ORDERS_OVERVIEW,
  payload: data
});
