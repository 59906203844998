import {
  FETCH_FILTER_ACCOUNTS,
  SET_FILTER_ACCOUNTS,
  FETCH_FILTER_DEVICE_TYPES,
  SET_FILTER_DEVICE_TYPES,
  SET_DEVICES_BY_SERIAL,
  FETCH_DEVICES_BY_SERIAL,
  SET_UPLOAD_CERTIFICATIONS_RESULT,
  FETCH_DEVICE_SYSTEM_LOGS,
  SET_DEVICE_SYSTEM_LOGS,
  FETCH_CN_SYSTEM_LOGS,
  SET_CN_SYSTEM_LOGS,
  SET_UPLOAD_SOFTWARE_RESULT
} from '../actions/admin';

export function adminReducer(
  state = {
    accounts: null,
    deviceTypes: null,
    devicesBySerial: null,
    deviceLogs: null,
    connectivityNodeLogs: null
  },
  action
) {
  switch (action.type) {
    case FETCH_FILTER_ACCOUNTS:
      return {
        ...state,
        accounts: null
      };
    case SET_FILTER_ACCOUNTS:
      return {
        ...state,
        accounts: action.payload
      };
    case FETCH_FILTER_DEVICE_TYPES:
      return {
        ...state,
        deviceTypes: null
      };
    case SET_FILTER_DEVICE_TYPES:
      return {
        ...state,
        deviceTypes: action.payload
      };
    case FETCH_DEVICES_BY_SERIAL:
      return {
        ...state,
        devicesBySerial: null
      };
    case SET_DEVICES_BY_SERIAL:
      return {
        ...state,
        devicesBySerial:
          typeof action.payload === 'object' ? action.payload : null
      };
    case SET_UPLOAD_CERTIFICATIONS_RESULT:
      return {
        ...state,
        uploadCertificationsResult: action.payload
      };
    case FETCH_DEVICE_SYSTEM_LOGS:
      return {
        ...state,
        deviceLogs: null
      };
    case SET_DEVICE_SYSTEM_LOGS:
      return {
        ...state,
        deviceLogs: typeof action.payload === 'object' ? action.payload : null
      };
    case FETCH_CN_SYSTEM_LOGS:
      return {
        ...state,
        connectivityNodeLogs: null
      };
    case SET_CN_SYSTEM_LOGS:
      return {
        ...state,
        connectivityNodeLogs:
          typeof action.payload === 'object' ? action.payload : null
      };
    case SET_UPLOAD_SOFTWARE_RESULT:
      return {
        ...state,
        uploadSoftwareResult: action.payload
      };
    default:
      return state;
  }
}
