import React from 'react';
import { IconWithBadge } from '../..';
import propTypes from 'prop-types';

import './Alert.scss';

const Alert = ({ level = 'warning', msg }) => {
  return (
    <div className={['gtg-alert', 'alert-' + level].join(' ')}>
      <IconWithBadge level={level} />
      <span className="ml-2">{msg}</span>
    </div>
  );
};

Alert.propTypes = {
  level: propTypes.string,
  msg: propTypes.oneOfType([propTypes.string, propTypes.object])
};

export default Alert;
