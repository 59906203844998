import axios from 'axios';
import {
  fetchBlobSuccess,
  fetchBlobError,
  fetchBlobProgress,
  FETCH_BLOB,
  clearBlob
} from '../actions/blobDownload';
import { getJwtAuth } from '../../components/Auth/userManager';
import get from 'lodash.get';
import store from '../store';

export const blobDownloadMdl = ({ dispatch, getState }) => next => action => {
  next(action);

  const state = store.getState();
  const authToken = getJwtAuth(state);

  const { type, payload } = action;

  switch (type) {
    case FETCH_BLOB:
      let timeout = 60 * 1000;
      // This is a temporary increase to 10 minute timeout for the logs endpoint,
      // once the log storage optimization is complete, the endpoint should become
      // faster and this timeout can be set back to the default 60s.
      if (
        get(payload, 'url', '').includes('/logs') ||
        get(payload, 'url', '').includes('/documentation')
      ) {
        timeout = 10 * 60 * 1000;
      }

      axios({
        method: 'GET',
        baseURL: state?.config?.beUrl ? `https://${state?.config?.beUrl}` : '',
        url: payload.url,
        headers: {
          Authorization: `Bearer ${authToken}`,
          Accept: ''
        },
        responseType: 'blob',
        timeout,
        onDownloadProgress: p => {
          dispatch(fetchBlobProgress((p.loaded / p.total) * 100, payload.url));
        }
      })
        .then(response => {
          let filename = payload.filename;

          if (filename === undefined) {
            if (response.headers['x-filename']) {
              filename = response.headers['x-filename'];
            } else {
              filename = 'download';
            }
          }

          dispatch(fetchBlobSuccess(response.data, payload.url, filename));
          setTimeout(() => {
            dispatch(clearBlob(payload.url));
          }, 2000);
        })
        .catch(error => {
          // Dispatch a generic API error action if onError is not set up
          dispatch(fetchBlobError(error, payload.url));
          setTimeout(() => {
            dispatch(clearBlob(payload.url));
          }, 2000);
        });
      break;
    // no default
  }
};
