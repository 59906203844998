import { entity } from '../actions/test';
import { proactiveEntity } from '../actions/test';
import { mapTest } from '../../components/utils/Test/testMapper';
import { apiRequest, API_SUCCESS } from '../actions/api';
import {
  FETCH_TEST,
  FETCH_PROACTIVE_TEST,
  transformTest,
  transformProactiveTest,
  TRANSFORM_TEST,
  TRANSFORM_PROACTIVE_TEST,
  setTest,
  setProactiveTest
} from '../actions/test';
import { API_URLS } from './urls';

export const testMdl = ({ dispatch }) => next => action => {
  next(action);

  const { type } = action;

  switch (type) {
    case FETCH_TEST:
      const { id, deviceId } = action.payload;
      const URL = API_URLS.PreUseCheck;
      dispatch(
        apiRequest({
          method: 'GET',
          url: URL(deviceId, id).url,
          requestSignature: 'test',
          entity: entity
        })
      );
      break;
    case FETCH_PROACTIVE_TEST:
      const proactiveURL = API_URLS.ProactiveWatch;

      dispatch(
        apiRequest({
          method: 'GET',
          url: proactiveURL(action.payload.deviceId, action.payload.id).url,
          requestSignature: 'test',
          entity: proactiveEntity
        })
      );
      break;
    case `${entity} GET ${API_SUCCESS}`:
      dispatch(transformTest(action.payload.data));
      break;
    case `${proactiveEntity} GET ${API_SUCCESS}`:
      dispatch(transformProactiveTest(action.payload.data));
      break;
    case TRANSFORM_TEST:
      const tmp = mapTest(action.payload);
      dispatch(setTest(tmp));
      break;
    case TRANSFORM_PROACTIVE_TEST:
      const tmpData = mapTest(action.payload);
      dispatch(setProactiveTest(tmpData));
      break;
    // no default
  }
};
