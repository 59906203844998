import moment from 'moment';

const PreventiveMaintenanceDateStatus = nextPMdate => {
  if (!nextPMdate) {
    return false;
  }
  const warningDaysToNextPM = 30;
  const today = moment();
  const nextPM = moment(nextPMdate);
  const daysToNextPM = nextPM.diff(today, 'days');
  const PMdateStatus =
    daysToNextPM < 0
      ? 'ALERT'
      : daysToNextPM <= warningDaysToNextPM
      ? 'WARNING'
      : 'OK';
  return PMdateStatus;
};

export default PreventiveMaintenanceDateStatus;
