import React, { useEffect, useCallback, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import classnames from 'classnames';
import get from 'lodash.get';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
  routePaths,
  Icon,
  EmptyState,
  Card,
  CardSection,
  uiConfig,
  decodePaginationURI,
  Toggles,
  DateScroller,
  allDateToggles,
  getStartAndEndData
} from '..';
import RunningHoursTable from './RunningHoursTable';
import { WithLoadingSpinner } from '../../hoc';
import { fetchDevicesRunningHours } from '../../redux/actions/devicesRunningHours';
import { pushPaginationHistory } from '../../redux/actions/paginationHistory';
import { savePersistentState } from '../../redux/actions/persistentState';
import {
  createDocument,
  RUNNING_HOURS,
  runningHoursFileName
} from '../../utils/export';
import styles from './RunningHours.module.scss';

export const RunningHoursWidget = ({
  fetchDevicesRunningHours,
  pushPaginationHistory,
  savePersistentState,
  filters,
  location,
  pagination,
  sorting,
  persistentState,
  smallMode = false,
  devices,
  maxRunningHours,
  intl
}) => {
  // Timespan and count states
  const [timeSpan, setTimeSpan] = useState(
    persistentState['runningHoursTimeSpan'] || 'week'
  );
  const [timeSpanCount, setTimeSpanCount] = useState(
    persistentState['runningHoursTimeSpanCount'] || 0
  );

  // Set timespan and timeSpanCount
  const setSelectedRange = useCallback(
    (timeSpan, count = 0) => {
      savePersistentState('runningHoursTimeSpan', timeSpan);
      savePersistentState('runningHoursTimeSpanCount', count);
      setTimeSpan(timeSpan);
      setTimeSpanCount(count);
    },
    [savePersistentState, setTimeSpan, setTimeSpanCount]
  );

  // Callbacks for back/forward, timespan selection
  const goBack = useCallback(() => {
    setSelectedRange(timeSpan, timeSpanCount + 1);
  }, [setSelectedRange, timeSpan, timeSpanCount]);
  const goForward = useCallback(() => {
    setSelectedRange(timeSpan, timeSpanCount - 1);
  }, [setSelectedRange, timeSpan, timeSpanCount]);
  const changeSelectedTimespan = useCallback(
    toggle => setSelectedRange(toggle.value, 0),
    [setSelectedRange]
  );

  // Forward/back component
  const DateScrollerComponent = useCallback(
    () => (
      <DateScroller
        timespan={timeSpan}
        count={timeSpanCount}
        onBackClick={goBack}
        onForwardClick={goForward}
      />
    ),
    [timeSpan, timeSpanCount, goBack, goForward]
  );

  // Week/month/year toggles
  const TimespanToggles = useCallback(
    () => (
      <Toggles
        // Running hours do not have the y2y date selection
        toggles={allDateToggles.filter(toggle => toggle.value !== 'y2y')}
        selectedToggle={timeSpan}
        onSelect={changeSelectedTimespan}
      />
    ),
    [timeSpan, changeSelectedTimespan]
  );

  // Below are pagination settings
  const initialPaginationSettings = useMemo(
    () => ({
      page: 0,
      pageSize: smallMode ? 5 : uiConfig.pageSizeOptions[0],
      sort: '-runningHours'
    }),
    [smallMode]
  );

  useEffect(() => {
    const urlPagination = decodePaginationURI(
      location.search,
      initialPaginationSettings
    );
    fetchDevicesRunningHours(
      timeSpan,
      timeSpanCount,
      {
        page: urlPagination.page,
        pageSize: urlPagination.pageSize
      },
      urlPagination.sort
    );
  }, [
    location,
    filters,
    timeSpan,
    timeSpanCount,
    fetchDevicesRunningHours,
    initialPaginationSettings
  ]);

  const handleFetch = useCallback(
    (...fetchParams) => {
      pushPaginationHistory(
        fetchParams[2].page,
        fetchParams[2].pageSize,
        fetchParams[3]
      );
      fetchDevicesRunningHours(...fetchParams);
    },
    [fetchDevicesRunningHours, pushPaginationHistory]
  );

  const manualPaginationSettings = {
    fetchMethod: handleFetch,
    fetchParams: [timeSpan, timeSpanCount],
    paginationSettings: pagination,
    sortingSettings: sorting,
    customPageSizeOptions: uiConfig.pageSizeOptions,
    showPaginationTop: false
  };

  const showViewDetailsLink = smallMode;

  const tableProps = {
    devices,
    maxRunningHours,
    manualPaginationSettings: smallMode ? undefined : manualPaginationSettings
  };

  const { start, end } = getStartAndEndData(undefined, timeSpan, timeSpanCount);
  const exportRunningHoursData = useCallback(() => {
    createDocument(
      RUNNING_HOURS,
      devices || [],
      filters,
      runningHoursFileName,
      {
        from: intl.formatDate(start),
        to: intl.formatDate(end.format(), { timeZone: 'UTC' })
      },
      intl
    );
  }, [devices, filters, intl, start, end]);

  const RunningHoursCardRight = useCallback(
    () => (
      <div
        className={classnames(
          'gtg-data-export-header',
          styles.gtgRunningHoursCardRight
        )}
      >
        <TimespanToggles />
        <Link onClick={exportRunningHoursData} to="#">
          <Icon name="download-excel" size="sm" />
        </Link>
      </div>
    ),
    [exportRunningHoursData]
  );

  return (
    <Card
      title={routePaths.RunningHoursOverview.label}
      CardHeaderRight={RunningHoursCardRight}
      CardHeaderCenter={DateScrollerComponent}
      dataCy="cardRunningHours"
    >
      <CardSection>
        <WithLoadingSpinner when="devicesRunningHours">
          {(devices && devices.length > 0 && (
            <>
              <RunningHoursTable {...tableProps} />
              {showViewDetailsLink && (
                <Link
                  data-cy="linkShowInstalledBase"
                  className="card-link"
                  to={'/' + routePaths.RunningHoursOverview.path}
                >
                  <FormattedMessage
                    id="common.viewAll"
                    defaultMessage="View all"
                  />{' '}
                  <Icon name="arrow-right-full" size="sm" />
                </Link>
              )}
            </>
          )) || <EmptyState />}
        </WithLoadingSpinner>
      </CardSection>
    </Card>
  );
};

const mapStateToProps = ({
  persistentState,
  devicesRunningHours,
  filters
}) => ({
  persistentState: get(persistentState, 'persistentState'),
  filters,
  devices: get(devicesRunningHours, 'devicesRunningHours.items', []),
  pagination: get(devicesRunningHours, 'devicesRunningHours.pagination', {}),
  sorting: get(devicesRunningHours, 'devicesRunningHours.sorting', ''),
  maxRunningHours: get(
    devicesRunningHours,
    'devicesRunningHours.maxRunningHours',
    0
  )
});

const mapDispatchToProps = {
  savePersistentState,
  fetchDevicesRunningHours,
  pushPaginationHistory
};

RunningHoursWidget.propTypes = {
  fetchDevicesRunningHours: PropTypes.func.isRequired,
  pushPaginationHistory: PropTypes.func.isRequired,
  savePersistentState: PropTypes.func.isRequired,
  filters: PropTypes.shape({}).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string
  }).isRequired,
  pagination: PropTypes.shape({
    maxPageSize: PropTypes.number,
    page: PropTypes.number,
    pageSize: PropTypes.number,
    totalPages: PropTypes.number
  }).isRequired,
  sorting: PropTypes.string,
  persistentState: PropTypes.shape({
    runningHoursTimeSpan: PropTypes.string,
    runningHoursTimeSpanCount: PropTypes.number
  }).isRequired,
  smallMode: PropTypes.bool,
  devices: PropTypes.arrayOf(
    PropTypes.shape({
      lastLog: PropTypes.string,
      customerDeviceID: PropTypes.string,
      serialID: PropTypes.string,
      department: PropTypes.shape({
        name: PropTypes.string
      }),
      deviceType: PropTypes.string,
      runningHours: PropTypes.number
    })
  ),
  maxRunningHours: PropTypes.number,
  intl: intlShape
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(RunningHoursWidget);
