import moment from 'moment';
import PropTypes from 'prop-types';

const Formatted24hTime = ({ value, timeZone, showSeconds }) => {
  const formatter = timeZone === 'UTC' ? moment.utc : moment;
  return value
    ? formatter(value).format(showSeconds ? 'HH:mm:ss' : 'HH:mm')
    : null;
};

Formatted24hTime.propTypes = {
  value: PropTypes.string,
  timeZone: PropTypes.oneOf(['UTC']),
  showSeconds: PropTypes.bool
};

export default Formatted24hTime;
