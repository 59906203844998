import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { FormattedDate, FormattedMessage } from 'react-intl';
import {
  routePaths,
  DataTable,
  StatusIcon,
  Icon,
  EmptyState,
  getDeviceTypeDisplayName,
  Formatted24hTime
} from '..';
import DeviceAlert from './DeviceAlert';
import './DeviceListWidget.scss';

const DeviceListWidget = props => {
  const { devices } = props;

  const handleRowClick = useCallback(
    (state, rowInfo) => {
      if (rowInfo && rowInfo.original && rowInfo.original.id) {
        return {
          onClick: () => {
            props.history.push(
              `/${routePaths.DeviceOverview.path}/${rowInfo.original.id}`
            );
          }
        };
      } else {
        return {};
      }
    },
    [props.history]
  );

  return (
    <React.Fragment>
      {(devices && devices.length > 0 && (
        <>
          <DataTable
            showPagination={false}
            getTrProps={handleRowClick}
            defaultPageSize={5}
            className="gtg-installed-base-table mb-3"
            noCustomScrollbars
            withOverflow
            defaultSorted={[
              {
                id: 'status',
                asc: true
              }
            ]}
            data={devices}
            columns={[
              {
                Header: (
                  <FormattedMessage
                    id="column.status"
                    defaultMessage="Status"
                  />
                ),
                accessor: 'status',
                Cell: row => <StatusIcon value={row.value} />,
                width: 80,
                headerClassName: 'text-left',
                className: 'text-left',
                noOverflowTooltip: true
              },
              {
                Header: (
                  <FormattedMessage
                    id="column.lastAlert"
                    defaultMessage="Last alert"
                  />
                ),
                accessor: 'alert',
                headerClassName: 'text-left',
                Cell: ({ value }) => (
                  <DeviceAlert
                    heading={value.name}
                    description={value.description}
                  />
                ),
                width: 180,
                className: 'with-overflow'
              },
              {
                Header: (
                  <FormattedMessage
                    id="column.department"
                    defaultMessage="Department"
                  />
                ),
                accessor: 'department.name',
                headerClassName: 'text-left',
                className: 'text-left',
                width: 250
              },
              {
                Header: (
                  <FormattedMessage
                    id="column.device"
                    defaultMessage="Device"
                  />
                ),
                accessor: 'deviceType',
                headerClassName: 'text-left',
                className: 'text-left',
                Cell: row =>
                  (row.value.length && getDeviceTypeDisplayName(row.value)) ||
                  '',
                width: 80
              },
              {
                Header: (
                  <FormattedMessage
                    id="column.serialNo"
                    defaultMessage="Serial no"
                  />
                ),
                accessor: 'serialID',
                headerClassName: 'text-right',
                className: 'text-right'
              },
              {
                Header: (
                  <FormattedMessage
                    id="column.customerDeviceNo"
                    defaultMessage="Customer device no"
                  />
                ),
                accessor: 'customerDeviceID',
                headerClassName: 'text-right',
                className: 'text-right'
              },
              {
                Header: (
                  <FormattedMessage
                    id="column.lastConnected"
                    defaultMessage="Last connected"
                  />
                ),
                accessor: 'lastLog',
                headerClassName: 'text-right',
                className: 'text-right',
                Cell: row =>
                  (row.value && (
                    <React.Fragment>
                      {row.value && (
                        <>
                          <FormattedDate value={row.value} />{' '}
                          <Formatted24hTime value={row.value} />
                        </>
                      )}
                    </React.Fragment>
                  )) || (
                    <FormattedMessage
                      id="common.notAvailableAbbr"
                      defaultMessage="N/A"
                    />
                  )
              }
            ]}
          />
          <Link
            data-cy="linkShowInstalledBase"
            className="card-link"
            to={
              '/' +
              routePaths.DeviceOverview.path +
              '/' +
              routePaths.InstalledBaseDevice.path
            }
          >
            <FormattedMessage id="common.viewAll" defaultMessage="View all" />{' '}
            <Icon name="arrow-right-full" size="sm" />
          </Link>
        </>
      )) || <EmptyState />}
    </React.Fragment>
  );
};

export default withRouter(DeviceListWidget);
