import React, { useCallback, useRef, useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { EmptyState, DataTable, AgentKeyMapper, uuidv4, routePaths } from '..';
import { WithLoadingSpinner } from '../../hoc';
import { WithTooltip } from '../../hoc';
import { FormattedMessage } from 'react-intl';
import colorVariables from '../../scss/_variables.module.scss';
import { compose } from 'redux';
import { connect } from 'react-redux';

import './DetailedUsageStatisticsPerDevice.scss';

const ventilationModes = {
  manual: {
    label: 'Manual',
    color: colorVariables['colors-chart--color-chart-blue-8']
  },
  afgo: {
    label: 'AFGO',
    color: colorVariables['colors-chart--color-chart-blue-12']
  },
  pc: {
    label: 'PC',
    color: colorVariables['colors-chart--color-chart-yellow-1']
  },
  vc: {
    label: 'VC',
    color: colorVariables['colors-chart--color-chart-blue-5']
  },
  prvc: {
    label: 'PRVC',
    color: colorVariables['colors-chart--color-chart-green-1']
  },
  ps: {
    label: 'PS',
    color: colorVariables['colors-chart--color-chart-orange-1']
  },
  psbackup: {
    label: 'PSBackup',
    color: colorVariables['colors-chart--color-chart-orange-2']
  },
  pcps: {
    label: 'PCPS',
    color: colorVariables['colors-chart--color-chart-gray-4']
  },
  vcps: {
    label: 'VCPS',
    color: colorVariables['colors-chart--color-chart-gray-3']
  }
};

function ventilationModeValueAvgByTotal(val, maxTotal) {
  return val === 0 && maxTotal === 0 ? 0 : val / maxTotal;
}

const AgentUsageBar = props => {
  const { usage } = props;
  const ref = useRef();
  const totalValue = usage.reduce((a, o) => {
    return a + o.value;
  }, 0);

  let ellipsisActive = e => {
    return e.offsetHeight < e.scrollHeight || e.offsetWidth < e.scrollWidth;
  };

  const isOverflow = () => {
    let elemArr = document.getElementsByClassName(
      'gtg-detailed-usage-stat-graph-text-span'
    );
    if (elemArr.length > 0) {
      for (let el of elemArr) {
        if (el.innerHTML !== '') {
          el.style.visibility = ellipsisActive(el) ? 'hidden' : 'visible';
        }
      }
    }
  };

  const getTextContent = ventilationMode => {
    let val = Math.round(
      ventilationModeValueAvgByTotal(ventilationMode.value, totalValue) * 100
    );
    setTimeout(() => {
      isOverflow();
    }, 200);

    return (
      <div className="gtg-detailed-usage-stat-graph-text">
        <span
          ref={ref}
          className={'gtg-detailed-usage-stat-graph-text-span'}
          style={{ visibility: 'hidden' }}
        >
          {val === 0 ? '' : `${val} %`}
        </span>
      </div>
    );
  };

  return (
    <div className="gtg-agent-usage-bar">
      {usage &&
        usage.map(ventilationMode => (
          <div
            key={uuidv4()}
            style={{
              backgroundColor:
                ventilationModes[ventilationMode.label.toLowerCase()] &&
                ventilationModes[ventilationMode.label.toLowerCase()].color,
              width:
                ventilationModeValueAvgByTotal(
                  ventilationMode.value,
                  totalValue
                ) *
                  100 +
                '%'
            }}
            className="gtg-detailed-usage-stat-graph-tooltip"
          >
            <WithTooltip
              tooltipContent={
                <React.Fragment>
                  <span
                    className="gtg-agent-dot"
                    key={uuidv4()}
                    style={{
                      backgroundColor:
                        ventilationModes[ventilationMode.label.toLowerCase()] &&
                        ventilationModes[ventilationMode.label.toLowerCase()]
                          .color,
                      borderColor:
                        ventilationModes[ventilationMode.label.toLowerCase()] &&
                        ventilationModes[ventilationMode.label.toLowerCase()]
                          .color
                    }}
                  ></span>
                  {AgentKeyMapper(ventilationMode.label).labelShort}:
                  {` ${Math.round(
                    ventilationModeValueAvgByTotal(
                      ventilationMode.value,
                      totalValue
                    ) * 100
                  )} %`}
                </React.Fragment>
              }
              tooltipSmall
              tooltipLocation="top"
              tooltipFixedArrow
            >
              {getTextContent(ventilationMode)}
            </WithTooltip>
          </div>
        ))}
    </div>
  );
};

const DetailedUsageStatisticsPerDevice = ({ data, history, costs }) => {
  const filteredMetricDetailedUsage = data?.filter(
    item => item?.averages?.casesTotal > 0
  );

  const currencySymbol = costs ? costs.currency.symbol : '';

  const handleRowClick = useCallback(
    (state, rowInfo) => {
      if (rowInfo && rowInfo.original && rowInfo.original.id) {
        return {
          onClick: () => {
            history.push(
              `/${routePaths.DeviceOverview.path}/${rowInfo.original.id}/${routePaths.DeviceCaseOverview.path}`
            );
          }
        };
      } else {
        return {};
      }
    },
    [history]
  );

  const compareValues = (a, b) => {
    switch (true) {
      case a > b:
        return 1;
      case a < b:
        return -1;
      default:
        return 0;
    }
  };

  const getWeightWithUnit = val => {
    return val > 1000 ? `${val} tons` : `${val} kg`;
  };

  const formatAgcValue = (casesAgc, casesTotal) => {
    return casesTotal !== 0 ? Math.round((casesAgc / casesTotal) * 100) : 0;
  };

  return (
    <WithLoadingSpinner when="metricsDetailed">
      {(filteredMetricDetailedUsage &&
        filteredMetricDetailedUsage.length > 0 && (
          <DataTable
            showPagination={false}
            getTrProps={handleRowClick}
            pageSize={[999]}
            noCustomScrollbars
            withOverflow
            resizable={false}
            className="mb-4 gtg-agent-usage-stat-per-device"
            data={filteredMetricDetailedUsage}
            columns={[
              {
                Header: (
                  <FormattedMessage
                    id="column.serialNo"
                    defaultMessage="Serial no"
                  />
                ),
                accessor: 'serialID',
                headerClassName: 'text-left',
                className: 'text-left',
                width: 100
              },
              {
                Header: (
                  <FormattedMessage
                    id="column.customerDeviceName"
                    defaultMessage="Customer device name"
                  />
                ),
                accessor: 'department.name',
                headerClassName: 'text-left',
                className: 'text-left',
                width: 190
              },
              {
                Header: (
                  <FormattedMessage id="column.cases" defaultMessage="Cases" />
                ),
                accessor: 'averages.casesTotal',
                headerClassName: 'text-right',
                className: 'text-right',
                width: 70
              },
              {
                Header: (
                  <FormattedMessage
                    id="column.agentCost"
                    defaultMessage="Agent cost"
                  />
                ),
                accessor: 'averages.costPerCase',
                headerClassName: 'text-right',
                className: 'text-right',
                Cell: ({ value }) => {
                  return value === null
                    ? ''
                    : `${value.toFixed(2)} ${currencySymbol}`;
                },
                width: 110
              },
              {
                Header: (
                  <FormattedMessage
                    id="column.carbonFootprint"
                    defaultMessage="Carbon footprint"
                  />
                ),
                accessor: 'carbonFootprint',
                headerClassName: 'text-right',
                className: 'text-right',
                width: 150,
                Cell: ({ value }) => {
                  return value === null
                    ? ''
                    : getWeightWithUnit(value.toFixed(2));
                }
              },
              {
                Header: (
                  <FormattedMessage id="column.agc" defaultMessage="AGC" />
                ),
                accessor: 'averages.casesAgc',
                headerClassName: 'text-right',
                className: 'text-right',
                Cell: ({ value, original }) => {
                  return value === null
                    ? ''
                    : `${formatAgcValue(
                        value,
                        original?.averages?.casesTotal || 0
                      )} %`;
                },
                width: 70
              },
              {
                // Since the 'accessor' is not a string, we need to add an id
                id: 'agentUsage',
                Header: (
                  <FormattedMessage
                    id="usageStatisticsPerDevice.ventilationMode"
                    defaultMessage="Ventilation mode"
                  />
                ),
                accessor: row => row.ventilationModes,
                showPagination: false,
                headerClassName: 'text-left',
                className: 'gtg-agent-usage-bar-row text-left',
                noOverflowTooltip: true,
                sortMethod: (a, b) => {
                  const sumMetrics = ventilationModes =>
                    ventilationModes
                      .map(vm => vm.value)
                      .reduce((sum, value) => sum + value, 0);

                  return compareValues(sumMetrics(a), sumMetrics(b));
                },
                Cell: ({ value: usageData }) =>
                  (usageData.length > 0 && (
                    <AgentUsageBar usage={usageData} />
                  )) || (
                    <FormattedMessage
                      id="common.notAvailableAbbr"
                      defaultMessage="N/A"
                    />
                  )
              }
            ]}
          />
        )) || <EmptyState />}
    </WithLoadingSpinner>
  );
};

const mapStateToProps = state => ({
  stateVal: state,
  device: state.deviceDetails.device,
  costs: state.costs.costs
});

export default compose(
  connect(mapStateToProps),
  withRouter
)(DetailedUsageStatisticsPerDevice);
