import React, { useState, useCallback } from 'react';
import { Icon } from '../..';
import classnames from 'classnames';

import styles from './Card.module.scss';

const CardSection = props => {
  const {
    collapsible = false,
    collapsedContent = undefined,
    title,
    children,
    cardSectionCollapseToggle,
    cardSectionIndex,
    dataCy,
    CardHeaderRight,
    CardHeaderCenter,
    ignoreClickTarget,
    customClass,
    sectionClass
  } = props;

  const [collapsed, setCollapsed] = useState(!!props.collapsed);

  const toggleSection = useCallback(() => {
    cardSectionCollapseToggle
      ? cardSectionCollapseToggle(cardSectionIndex)
      : setCollapsed(!collapsed);
  }, [cardSectionCollapseToggle, cardSectionIndex, collapsed]);

  const onClick = useCallback(
    e => {
      e.stopPropagation();
      e.preventDefault();
      if (
        ignoreClickTarget &&
        (e.target.classList.contains(ignoreClickTarget) ||
          e.target.parentNode.classList.contains(ignoreClickTarget))
      ) {
        return null;
      }
      return collapsible ? toggleSection() : null;
    },
    [ignoreClickTarget, collapsible, toggleSection]
  );

  const ExpandIcon = () => {
    return collapsed ? (
      <Icon name="plus-big" size="md" />
    ) : (
      <Icon name="minus-big" size="md" />
    );
  };

  return (
    <section
      data-cy={dataCy}
      aria-expanded={(collapsible && !collapsed) || !collapsible}
      className={sectionClass ? sectionClass : ''}
    >
      {title && (
        // only show CardHeaderCenter and CardHeaderRight if section is not collapsible or ignoreClickTarget is defined
        <header
          className={classnames(
            styles.gtgCardWithHeader,
            CardHeaderCenter && CardHeaderRight && styles.gtgCardHeaderGrid,
            customClass ? customClass : ''
          )}
          onClick={onClick}
        >
          <h5>{title}</h5>
          {(!collapsible || ignoreClickTarget) && CardHeaderCenter && (
            <CardHeaderCenter />
          )}
          {(!collapsible || ignoreClickTarget) && CardHeaderRight && (
            <CardHeaderRight collapsed={collapsed} />
          )}
          {collapsible && <ExpandIcon />}
        </header>
      )}
      <div className="card-body">{children}</div>
      {collapsible && collapsedContent && (
        <div className="card-body">{collapsedContent}</div>
      )}
    </section>
  );
};

export default CardSection;
