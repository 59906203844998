import React from 'react';
import { List, ListItem, EmptyState, formatValueUnit } from '../../components';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

export const getAverageKeyName = key => {
  let label;
  switch (key.toLowerCase()) {
    case 'peakairwaypressure':
      label = (
        <FormattedMessage
          id="case.keyPeakAirwayPressure"
          defaultMessage="Peak Pressure"
        />
      );
      break;
    case 'minutevolumeinspired':
      label = (
        <FormattedMessage
          id="case.keyMinuteVolumeInspired"
          defaultMessage="MVi"
        />
      );
      break;
    case 'tidalvolumeinspired':
      label = (
        <FormattedMessage
          id="case.keyTidalVolumeInspired"
          defaultMessage="VTi"
        />
      );
      break;
    case 'peep':
      label = <FormattedMessage id="case.keyPeep" defaultMessage="PEEP" />;
      break;
    default:
      label = <span>{key}</span>;
  }
  return label;
};

export const CaseAveragesWidget = ({ averages }) => (
  <>
    <h6 className="mb-3 gtg-case-details-title">
      <FormattedMessage id="case.caseAverages" defaultMessage="Case averages" />
    </h6>
    <List>
      {(averages &&
        averages.length > 0 &&
        averages.map(average => {
          return (
            <ListItem key={average.key}>
              {getAverageKeyName(average.key)}
              {formatValueUnit(average.value, average.unit, average.key)}
            </ListItem>
          );
        })) || <EmptyState />}
    </List>
  </>
);

CaseAveragesWidget.propTypes = {
  averages: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
      unit: PropTypes.string.isRequired
    })
  )
};

export default CaseAveragesWidget;
