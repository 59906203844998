export const entity = '[MetricsDetailedPerDevice]';
export const FETCH_METRICS_DEVICE_USAGE = `${entity} Fetch`;
export const SET_METRICS_DEVICE_USAGE = `${entity} Set`;

/**
 * Trigger fetch of metrics device usage
 */
export const fetchMetricsDeviceUsage = (timeSpan, count) => ({
  type: FETCH_METRICS_DEVICE_USAGE,
  payload: {
    timeSpan,
    count
  }
});

/**
 * Set data as new detailed metrics per device
 * @param {array} data New metrics usage
 */
export const setMetricsDeviceUsage = data => ({
  type: SET_METRICS_DEVICE_USAGE,
  payload: data
});
