import { SET_CASE, FETCH_CASE } from '../actions/case';

export function caseReducer(
  state = {
    case: null
  },
  action
) {
  switch (action.type) {
    case FETCH_CASE:
      return {
        ...state,
        case: null
      };
    case SET_CASE:
      return {
        ...state,
        case: action.payload
      };
    default:
      return state;
  }
}
