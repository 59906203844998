import { SET_LEGAL_NOTICE } from '../actions/legalNotice';

const initState = {
  approval: null,
  content: null,
  version: null
};

export const legalNoticeReducer = (state = initState, action) => {
  const { type } = action;

  switch (type) {
    case SET_LEGAL_NOTICE:
      return {
        ...state,
        approval: action.payload.approval || state.approval,
        content: action.payload.content || state.content,
        version: action.payload.version || state.version
      };
    default:
      return state;
  }
};
