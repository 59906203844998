export const entity = '[Logs]';
export const FETCH_LOGS = `${entity} Fetch`;
export const SET_LOGS = `${entity} Set`;
export const DOWNLOAD_LOGS = `${entity} Download`;

/**
 * Trigger fetch of device logs
 */
export const fetchLogs = (
  deviceId,
  logType,
  paginationSettings,
  sortingSettings
) => ({
  type: FETCH_LOGS,
  id: deviceId,
  logType: logType,
  pagination: paginationSettings,
  sorting: sortingSettings
});

/**
 * Set data as new device logs
 * @param {array} data New device logs
 */
export const setLogs = (logType, data) => ({
  type: SET_LOGS,
  payload: data,
  logType: logType
});

/**
 * Trigger download of logs
 */
export const downloadLogs = (deviceId, logType) => ({
  type: DOWNLOAD_LOGS,
  payload: { deviceId, logType }
});
