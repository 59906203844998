import React from 'react';
import propTypes from 'prop-types';
import classnames from 'classnames';
import styles from './Card.module.scss';

const CardSectionColumn = ({
  title,
  columnSize = 'col',
  children,
  titleLeft = false,
  customClass = ''
}) => (
  <section
    className={
      customClass === ''
        ? `${columnSize} ${styles.gtgColumnSection}`
        : `${columnSize} ${styles.gtgColumnSection} ${customClass}`
    }
  >
    <div
      className={classnames('mb-3', titleLeft ? 'text-left' : 'text-center')}
    >
      {title}
    </div>
    {children}
  </section>
);

CardSectionColumn.propTypes = {
  title: propTypes.node.isRequired,
  titleLeft: propTypes.bool,
  columnSize: propTypes.string,
  children: propTypes.node.isRequired
};

export default CardSectionColumn;
