export const entity = '[Currency]';
export const FETCH_CURRENCY = `${entity} Fetch`;
export const SET_CURRENCY = `${entity} Set`;

/**
 * Trigger fetch of currencies
 */
export const fetchCurrencies = () => ({
  type: FETCH_CURRENCY
});

/**
 * Set data as currencies
 * @param {array} data New currencies information
 */
export const setCurrencies = data => ({
  type: SET_CURRENCY,
  payload: data
});
