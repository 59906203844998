import React, { useState, useCallback } from 'react';
import ReactTable, { ReactTableDefaults } from 'react-table';
import DataTablePagination from './pagination';
import './datatable.scss';
import { Scrollable, Icon, uiConfig } from '../..';
import { addWithOverflowTooltip } from './datatableHelper';
import { applyTooltipForColHeader } from '../TableHeaderTooltip/tableHeaderTooltip';

const CustomExpander = ({ isExpanded }) =>
  (isExpanded && <Icon dataCy="iconExpander" name="minus" />) || (
    <Icon dataCy="iconExpander" name="plus" />
  );

const DataTable = props => {
  const {
    manualPaginationSettings,
    handleRowClick,
    showPagination,
    minHeight,
    maxHeight,
    data,
    className,
    noCustomScrollbars,
    withOverflow,
    pageSize,
    selectable = true,
    currentPageExtra
  } = props;
  const pageSizeOptionsArr =
    (manualPaginationSettings &&
      manualPaginationSettings.customPageSizeOptions) ||
    (pageSize ? pageSize : uiConfig.pageSizeOptions);
  /* eslint-disable no-unused-vars */
  const [rowcount, setRowCount] = useState(0);
  /* eslint-enable no-unused-vars */
  const handleRowCountChange = useCallback(
    val => {
      setRowCount(val);
    },
    [setRowCount]
  );
  applyTooltipForColHeader();

  const ScrollableTbodyComponent = useCallback(
    props => {
      return (
        <Scrollable minElementHeight={minHeight} maxElementHeight={maxHeight}>
          <ReactTableDefaults.TbodyComponent>
            {props.children}
          </ReactTableDefaults.TbodyComponent>
        </Scrollable>
      );
    },
    [minHeight, maxHeight]
  );

  const rowClickHandler = useCallback(
    (e, handleOriginal, state, rowInfo, column) => {
      if (column && column.expander) {
        handleOriginal();
        return;
      }

      if (handleRowClick) {
        handleRowClick(state, rowInfo);
      }
    },
    [handleRowClick]
  );

  const manualPagination = manualPaginationSettings && {
    showPagination: true,
    showPaginationTop:
      typeof manualPaginationSettings.showPaginationTop === 'boolean'
        ? manualPaginationSettings.showPaginationTop
        : true,
    manual: true,

    /* eslint-disable react-hooks/exhaustive-deps, react-hooks/rules-of-hooks */
    onFetchData: useCallback((state, instance) => {
      const sortingStr =
        state.sorted[0] &&
        ((state.sorted[0].asc && !state.sorted[0].desc) ||
        (state.sorted[0].asc === false && state.sorted[0].desc === false)
          ? ''
          : '-') + state.sorted[0].id;
      if (
        state.page !== manualPaginationSettings.paginationSettings.page ||
        state.pageSize !==
          manualPaginationSettings.paginationSettings.pageSize ||
        sortingStr !== manualPaginationSettings.sortingSettings
      ) {
        let fetchParams = [
          {
            page: state.page,
            pageSize: state.pageSize
          },
          sortingStr
        ];
        if (manualPaginationSettings.fetchParams) {
          fetchParams = [
            ...manualPaginationSettings.fetchParams,
            ...fetchParams
          ];
        }
        manualPaginationSettings.fetchMethod(...fetchParams);
      }
    }),
    /* eslint-enable react-hooks/exhaustive-deps, react-hooks/rules-of-hooks */
    defaultSorted: [
      {
        id: manualPaginationSettings.sortingSettings.substring(
          manualPaginationSettings.sortingSettings.charAt(0) === '-' ? 1 : 0
        ),
        asc: manualPaginationSettings.sortingSettings.charAt(0) !== '-'
      }
    ],
    page: manualPaginationSettings.paginationSettings.page,
    pageSize: manualPaginationSettings.paginationSettings.pageSize,
    pages: manualPaginationSettings.paginationSettings.totalPages,
    totalItems: manualPaginationSettings.paginationSettings.totalItems
  };

  const columns = props.columns.map(addWithOverflowTooltip);

  return (
    <ReactTable
      {...props}
      currentPageExtra={currentPageExtra}
      columns={columns}
      /* eslint-disable react/jsx-no-bind */
      {...manualPagination}
      getTdProps={(state, rowInfo, column, instance) => {
        return {
          onClick: (e, handleOriginal) => {
            rowClickHandler(e, handleOriginal, state, rowInfo, column);
          }
        };
      }}
      minRows={1}
      /* eslint-enable react/jsx-no-bind */
      className={[
        '-striped',
        selectable && '-highlight',
        withOverflow && 'with-overflow',
        className
      ].join(' ')}
      ExpanderComponent={CustomExpander}
      showPagination={
        manualPaginationSettings
          ? true
          : data && data.length > pageSizeOptionsArr[0]
          ? showPagination
          : false
      }
      pageSizeOptions={pageSizeOptionsArr}
      defaultPageSize={pageSizeOptionsArr[0]}
      TbodyComponent={
        (!noCustomScrollbars && ScrollableTbodyComponent) ||
        ReactTableDefaults.TbodyComponent
      }
      PaginationComponent={DataTablePagination}
      onRowCountChange={handleRowCountChange}
    />
  );
};

export default DataTable;
