import { AgentDot } from '..';
import colorVariables from '../../scss/_variables.module.scss';

export const MetricsConfig = {
  initialTimeSpan: 'week',
  maxFgfValue: 20,
  maxValues: {
    freshGasFlow: 20,
    des: 20,
    iso: 5,
    sev: 8,
    macY: 3.2,
    macBrain: 3.2
  }
};

const Metrics = [
  {
    type: 'agent',
    key: 'Sevoflurane',
    label: 'Sevoflurane',
    labelShort: 'SEV',
    icon: AgentDot(colorVariables['colors-chart--color-sevo-insp']),
    color: colorVariables['colors-chart--color-sevo-insp'],
    unit: 'mL'
  },
  {
    type: 'agent',
    key: 'Desflurane',
    label: 'Desflurane',
    labelShort: 'DES',
    icon: AgentDot(colorVariables['colors-chart--color-deso-insp']),
    color: colorVariables['colors-chart--color-deso-insp'],
    unit: 'mL'
  },
  {
    type: 'agent',
    key: 'Isoflurane',
    label: 'Isoflurane',
    labelShort: 'ISO',
    icon: AgentDot(colorVariables['colors-chart--color-iso-insp']),
    color: colorVariables['colors-chart--color-iso-insp'],
    unit: 'mL'
  },
  {
    type: 'servoagent',
    key: 'edi-monitoring',
    label: 'EDI monitoring',
    labelShort: 'EDI monitoring',
    icon: AgentDot(colorVariables['colors-chart--color-chart-plum-2']),
    color: colorVariables['colors-chart--color-chart-plum-2'],
    unit: 'sec'
  },
  {
    type: 'servoagent',
    key: 'edi-niv-nava',
    label: 'NIV NAVA',
    labelShort: 'NIV NAVA',
    icon: AgentDot(colorVariables['colors-chart--color-chart-plum-1']),
    color: colorVariables['colors-chart--color-chart-plum-1'],
    unit: 'sec'
  },
  {
    type: 'servoagent',
    key: 'edi-nava',
    label: 'NAVA',
    labelShort: 'NAVA',
    icon: AgentDot(colorVariables['colors-chart--color-chart-plum-4']),
    color: colorVariables['colors-chart--color-chart-plum-4'],
    unit: 'sec'
  },
  {
    type: 'servoagent',
    key: 'controlled',
    label: 'Controlled Modes',
    labelShort: 'Controlled Modes',
    icon: AgentDot(colorVariables['colors-chart--color-chart-blue-11']),
    color: colorVariables['colors-chart--color-chart-blue-11'],
    unit: 'sec'
  },
  {
    type: 'servoagent',
    key: 'supported',
    label: 'Support Modes',
    labelShort: 'Support Modes',
    icon: AgentDot(colorVariables['colors-chart--color-chart-blue-7']),
    color: colorVariables['colors-chart--color-chart-blue-7'],
    unit: 'sec'
  },
  {
    type: 'servoagent',
    key: 'interactive',
    label: 'Interactive Modes',
    labelShort: 'Interactive Modes',
    icon: AgentDot(colorVariables['colors-chart--color-chart-blue-5']),
    color: colorVariables['colors-chart--color-chart-blue-5'],
    unit: 'sec'
  },
  {
    type: 'servoagent',
    key: 'adult',
    label: 'Adult',
    labelShort: 'Adult',
    icon: AgentDot(colorVariables['colors-chart--color-chart-blue-8']),
    color: colorVariables['colors-chart--color-chart-blue-8'],
    unit: 'sec'
  },
  {
    type: 'servoagent',
    key: 'neonatal',
    label: 'Neonatal',
    labelShort: 'Neonatal',
    icon: AgentDot(colorVariables['colors-chart--color-chart-green-1']),
    color: colorVariables['colors-chart--color-chart-green-1'],
    unit: 'sec'
  },
  {
    type: 'servoagent',
    key: 'pediatric',
    label: 'Pediatric',
    labelShort: 'Pediatric',
    icon: AgentDot(colorVariables['colors-chart--color-chart-blue-5']),
    color: colorVariables['colors-chart--color-chart-blue-5'],
    unit: 'sec'
  },
  {
    type: 'servoagent',
    key: 'unutilized',
    label: 'Unutilized',
    labelShort: 'Unutilized',
    icon: AgentDot(colorVariables['colors-chart--color-chart-gray-3']),
    color: colorVariables['colors-chart--color-chart-gray-3'],
    unit: 'sec'
  },
  {
    type: 'servoagent',
    key: 'invasive',
    label: 'Invasive',
    labelShort: 'Invasive',
    icon: AgentDot(colorVariables['colors-chart--color-chart-blue-5']),
    color: colorVariables['colors-chart--color-chart-blue-5'],
    unit: 'sec'
  },
  {
    type: 'servoagent',
    key: 'hfo',
    label: 'HFO',
    labelShort: 'HFO',
    icon: AgentDot(colorVariables['colors-chart--color-chart-blue-6']),
    color: colorVariables['colors-chart--color-chart-blue-6'],
    unit: 'sec'
  },
  {
    type: 'servoagent',
    key: 'high-flow',
    label: 'High Flow',
    labelShort: 'High Flow',
    icon: AgentDot(colorVariables['colors-chart--color-chart-green-3']),
    color: colorVariables['colors-chart--color-chart-green-3'],
    unit: 'sec'
  },
  {
    type: 'servoagent',
    key: 'non-invasive',
    label: 'Non-Invasive',
    labelShort: 'Non-Invasive',
    icon: AgentDot(colorVariables['colors-chart--color-chart-yellow-1']),
    color: colorVariables['colors-chart--color-chart-yellow-1'],
    unit: 'sec'
  },
  {
    type: 'servoagent',
    key: 'nava',
    label: 'NAVA',
    labelShort: 'NAVA',
    icon: AgentDot(colorVariables['colors-chart--color-chart-plum-1']),
    color: colorVariables['colors-chart--color-chart-plum-1'],
    unit: 'sec'
  },
  {
    type: 'servoagent',
    key: 'srm',
    label: 'Auto SRM',
    labelShort: 'Auto SRM',
    icon: AgentDot(colorVariables['colors-chart--color-chart-blue-8']),
    color: colorVariables['colors-chart--color-chart-blue-8']
  },
  {
    type: 'servoagent',
    key: 'rm',
    label: 'Auto RM',
    labelShort: 'Auto RM',
    icon: AgentDot(colorVariables['colors-chart--color-chart-blue-9']),
    color: colorVariables['colors-chart--color-chart-blue-9']
  },
  {
    type: 'gas',
    key: 'N2O',
    label: 'N2O',
    labelShort: 'N2O',
    icon: AgentDot(colorVariables['colors-chart--color-n2o-insp']),
    color: colorVariables['colors-chart--color-n2o-insp'],
    unit: 'L/min'
  },
  {
    type: 'gas',
    key: 'O2',
    label: 'O2',
    labelShort: 'O2',
    icon: AgentDot(colorVariables['colors-chart--color-o2-insp'], '#d4cac8'),
    color: colorVariables['colors-chart--color-o2-insp'],
    unit: 'L/min'
  },
  {
    type: 'gas',
    key: 'FractionOfInspiredOxygen',
    label: 'Fraction of inspired oxygen',
    labelShort: 'FiO2',
    icon: AgentDot(colorVariables['colors-chart--color-o2-insp'], '#d4cac8'),
    color: colorVariables['colors-chart--color-o2-insp'],
    unit: '%'
  },
  {
    type: 'fresh-gas',
    key: 'FreshGasFlow',
    label: 'Fresh gas flow',
    labelShort: 'FGF',
    icon: AgentDot(colorVariables['colors-chart--color-avg-fgf'], '#000'),
    color: colorVariables['colors-chart--color-avg-fgf'],
    unit: 'L/min'
  },
  {
    type: 'cases',
    key: 'Cases',
    label: 'Cases',
    labelShort: 'Cases',
    icon: null,
    color: null,
    unit: null
  },
  {
    type: 'cons',
    key: 'avg_fgf',
    label: 'Avg FGF',
    labelShort: 'Avg FGF',
    icon: AgentDot(colorVariables['colors-chart--color-avg-fgf']),
    color: colorVariables['colors-chart--color-avg-fgf'],
    unit: 'L/min'
  },
  {
    type: 'cons',
    key: 'O2',
    label: 'O2',
    labelShort: 'O2',
    icon: AgentDot(colorVariables['colors-chart--color-o2-insp'], '#d4cac8'),
    color: colorVariables['colors-chart--color-o2o-insp'],
    unit: 'L'
  },
  {
    type: 'mac',
    key: 'MacY',
    label: 'Mac Y',
    labelShort: 'Mac Y',
    icon: AgentDot(colorVariables['colors-chart--color-chart-gray-1']),
    color: colorVariables['colors-chart--color-chart-gray-1'],
    unit: 'cmH2O'
  },
  {
    type: 'mac',
    key: 'MacBrain',
    label: 'Mac Brain',
    labelShort: 'Mac Brain',
    icon: AgentDot(colorVariables['colors-chart--color-chart-gray-1']),
    color: colorVariables['colors-chart--color-chart-gray-1'],
    unit: 'cmH2O'
  }
];

export default Metrics;
