import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { WithLoadingSpinner, WithSubscriptionInvitation } from '../../hoc';
import get from 'lodash.get';
import { injectIntl, intlShape } from 'react-intl';

import { fetchCases } from '../../redux/actions/cases';
import { pushPaginationHistory } from '../../redux/actions/paginationHistory';
import {
  uiConfig,
  routePaths,
  ContainerHeader,
  Card,
  CardSection,
  decodePaginationURI,
  CaseList,
  Icon,
  subscriptionTypes
} from '../../components';
import {
  CASE_LIST,
  caseListFileName,
  createDocument
} from '../../utils/export';

const DeviceCaseOverview = props => {
  const {
    device,
    cases,
    filters,
    fetchCases,
    location,
    pushPaginationHistory,
    intl,
    config,
    subscriptions
  } = props;
  const [filteredCaseData, setFilteredCaseData] = useState([]);
  const handleFetchCallback = (...fetchParams) => {
    pushPaginationHistory(
      fetchParams[1].page,
      fetchParams[1].pageSize,
      fetchParams[2]
    );
    fetchCases(...fetchParams);
  };

  const initialPaginationSettings = {
    page: 0,
    pageSize: uiConfig.pageSizeOptions[0],
    sort: '-start'
  };
  const manualPaginationSettings = {
    fetchMethod: handleFetchCallback,
    fetchParams: [device.id],
    paginationSettings: props.casesPagination,
    sortingSettings: props.casesSorting,
    customPageSizeOptions: uiConfig.pageSizeOptions
  };
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    fetchCases(
      device.id,
      {
        page: initialPaginationSettings.page,
        pageSize: initialPaginationSettings.pageSize
      },
      initialPaginationSettings.sort
    );
  }, [filters]);

  useEffect(() => {
    if (device) {
      const urlPagination = decodePaginationURI(
        location.search,
        initialPaginationSettings
      );
      fetchCases(
        device.id,
        {
          page: urlPagination.page,
          pageSize: urlPagination.pageSize
        },
        urlPagination.sort
      );
    }
  }, [location]);
  /* eslint-enable react-hooks/exhaustive-deps */

  const exportCaseData = useCallback(() => {
    createDocument(
      CASE_LIST,
      filteredCaseData || [],
      filters,
      caseListFileName,
      { serialID: device.serialID },
      intl
    );
  }, [device.serialID, filteredCaseData, filters, intl]);

  let DeviceCaseCardContent = (
    <Card>
      <CardSection dataCy="sectionDeviceCaseList">
        <WithLoadingSpinner when="cases">
          <CaseList
            cases={cases}
            device={device}
            manualPaginationSettings={manualPaginationSettings}
            setData={setFilteredCaseData}
            currentPageExtra={
              <Link onClick={exportCaseData} to="#">
                <Icon name="download-excel" size="sm" />
              </Link>
            }
            config={config}
          />
        </WithLoadingSpinner>
      </CardSection>
    </Card>
  );

  DeviceCaseCardContent = (
    <WithSubscriptionInvitation
      subscriptions={
        device
          ? [
              {
                count: 1,
                level: get(device, 'subscription', 'standard'),
                priority: 1
              }
            ]
          : subscriptions
      }
      accessWidget={'cases'}
      trigger={[subscriptionTypes.premium]}
      customTitleForInvitation={'case.subscriptionInvitationTitle'}
      customSubtitleForInvitation={'case.subscriptionInvitationSubtitle'}
    >
      {DeviceCaseCardContent}
    </WithSubscriptionInvitation>
  );

  return (
    <>
      <ContainerHeader headerTitle={routePaths.DeviceCaseOverview.label} />
      <div className="row">
        <div className="col-12 col-md-12">{DeviceCaseCardContent}</div>
      </div>
    </>
  );
};

const mapStateToProps = state => ({
  cases: state.cases.cases,
  casesPagination: get(state, 'cases.cases.pagination'),
  casesSorting: get(state, 'cases.cases.sorting'),
  filters: state.filters,
  config: state.config,
  subscriptions: state.devicesStatusOverview.devicesStatusOverview.subscriptions
});

const mapDispatchToProps = {
  fetchCases,
  pushPaginationHistory
};

DeviceCaseOverview.propTypes = {
  device: PropTypes.shape({
    department: PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.string
    }),
    customerDeviceID: PropTypes.string,
    id: PropTypes.string,
    serialID: PropTypes.string,
    deviceType: PropTypes.string,
    subscription: PropTypes.string,
    failedTests: PropTypes.shape({}),
    lastAlert: PropTypes.string,
    lastConnected: PropTypes.string,
    lastLog: PropTypes.string,
    location: PropTypes.string,
    nextPreventativeMaintenance: PropTypes.string,
    status: PropTypes.string,
    systemSoftwareVersion: PropTypes.string,
    systemVersion: PropTypes.string
  }),
  cases: PropTypes.shape({
    items: PropTypes.arrayOf(PropTypes.shape({})),
    pagination: PropTypes.shape({}),
    size: PropTypes.number,
    sorting: PropTypes.string
  }),
  filters: PropTypes.shape({
    accounts: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        label: PropTypes.string,
        description: PropTypes.string,
        countryCode: PropTypes.string
      })
    ),
    departments: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        label: PropTypes.string
      })
    ),
    deviceTypes: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        label: PropTypes.string
      })
    )
  }),
  location: PropTypes.shape({}),
  fetchCases: PropTypes.func,
  pushPaginationHistory: PropTypes.func,
  intl: intlShape
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl,
  withRouter
)(DeviceCaseOverview);
