export const entity = '[Trends]';
export const FETCH_TRENDS = `${entity} Fetch`;
export const SET_TRENDS = `${entity} Set`;

/**
 * Trigger fetch of trends
 */
export const fetchTrends = (timeSpan, count, deviceIds) => ({
  type: FETCH_TRENDS,
  payload: {
    deviceIds,
    timeSpan,
    count
  }
});

/**
 * Set data as new trends
 * @param {array} data New trends
 */
export const setTrends = data => ({
  type: SET_TRENDS,
  payload: data
});
