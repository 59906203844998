export const entity = '[Usage statistics Detailed]';
export const FETCH_USAGE_STATISTICS_DETAILED = `${entity} Fetch`;
export const SET_USAGE_STATISTICS_DETAILED = `${entity} Set`;

/**
 * Trigger fetch of usage statistics detailed
 */
export const fetchUsageStatisticsDetailed = (
  timeSpan,
  count,
  patientCategories,
  deviceIds
) => ({
  type: FETCH_USAGE_STATISTICS_DETAILED,
  payload: {
    count,
    deviceIds,
    patientCategories,
    timeSpan
  }
});

/**
 * Set data as usage statistics detailed
 * @param {array} data usage statistics detailed
 */
export const setUsageStatisticsDetailed = data => ({
  type: SET_USAGE_STATISTICS_DETAILED,
  payload: data
});
