export default function ParseLegalNoticeVersion(content) {
  /* eslint-disable no-useless-escape */
  const regex = /(?:\[comment\]\:\ \#\ \(Legal\ disclaimer\ version\:(?<version>.*)\))/;
  /* eslint-enable no-useless-escape */
  const match = content.match(regex);
  return (
    match &&
    match.groups &&
    match.groups.version &&
    parseInt(content.match(regex).groups.version)
  );
}
