import { SET_SERVICE_ORDERS_OVERVIEW } from '../actions/serviceOrdersOverview';

export function serviceOrdersOverviewReducer(
  state = {
    serviceOrdersOverview: {}
  },
  action
) {
  switch (action.type) {
    case SET_SERVICE_ORDERS_OVERVIEW:
      return {
        ...state,
        serviceOrdersOverview: action.payload
      };

    default:
      return state;
  }
}
