import PropTypes from 'prop-types';

export function cursorShape(extraProps) {
  return PropTypes.shape({
    /* eslint-disable react/forbid-prop-types */
    items: PropTypes.arrayOf(PropTypes.object),
    /* eslint-enable react/forbid-prop-types */
    response_metadata: PropTypes.shape({
      next_cursor: PropTypes.string
    }),
    ...extraProps
  });
}
