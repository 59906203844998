import React from 'react';
import { IconWithBadge, deviceStatuses } from '../..';

const StatusIcon = ({ value }) => {
  let level;
  let count = value.count;
  switch (value.status) {
    case deviceStatuses.error:
      level = value.status.toLowerCase();
      break;
    case deviceStatuses.warning:
      level = value.status.toLowerCase();
      break;
    case deviceStatuses.connection:
      level = 'success';
      count = '-';
      break;
    case deviceStatuses.ok:
      level = 'success';
      break;
    default:
      level = 'neutral';
      break;
  }
  return <IconWithBadge level={level} count={count} />;
};

export default StatusIcon;
