import React from 'react';
import { Icon } from '../..';
import './IconWithBadge.scss';

function levelMapper(level) {
  return level === 'error' ? 'danger' : level;
}

function iconName(level) {
  return level === 'not-performed' ? level : level + '-outline';
}

const IconWithBadge = ({ level = 'success', count }) => {
  return (
    <div className="gtg-icon-with-badge">
      <Icon name={iconName(level)} />
      {count && (
        <span className={['badge', 'badge-' + levelMapper(level)].join(' ')}>
          {count}
        </span>
      )}
    </div>
  );
};

export default IconWithBadge;
