import ExcelJS from 'exceljs';
import { saveBlob } from '../blob/saveBlob';
import {
  transformAgentUsage,
  transformTotalGasConsumption,
  transformDeviceAgentUsage,
  transformDeviceTotalGasConsumption,
  transformDeviceUsageStatistics,
  transformDetailedUsageStatistics,
  transformDetailedUsageStatisticsPerDevice,
  transformRunningHours,
  transformCaseList,
  transformServiceOrdersList,
  transformPreventiveMaintenanceList,
  transformInstalledBaseList,
  transformUsageStatisticsServoDetailed,
  transformDeviceCaseTimeline,
  transformCaseDetails,
  transformCaseDetailsGasConsumption,
  agentUsageColumns,
  totalGasConsumptionColumns,
  deviceAgentUsageColumns,
  deviceTotalGasConsumptionColumns,
  deviceUsageStatisticsColumns,
  detailedUsageStatisticsColumns,
  detailedUsageStatisticsPerDeviceColumns,
  servoUsageStatisticsOverviewColumns,
  servoUsageStatisticsOverviewNeonatalColumns,
  servoUsageStatisticsOverviewPediatricColumns,
  servoUsageStatisticsOverviewAdultColumns,
  runningHoursColumns,
  caseListColumns,
  averagesTypes,
  serviceOrdersListColumns,
  preventiveMaintenanceListColumns,
  installedBaseListColumns,
  transformServoUsageStatisticsOverview,
  usageStatisticsServoDetailedColumns,
  usageStatisticsServoDetailedNeonatalColumns,
  usageStatisticsServoDetailedPediatricColumns,
  usageStatisticsServoDetailedAdultColumns,
  caseDetailsColumns,
  deviceCaseTimelineListColumns,
  caseDetailsGasConsumptionColumns
} from './';
import moment from 'moment';

export const AGENT_USAGE = 'AgentUsage';
export const TOTAL_GAS_CONSUMPTION = 'TotalGasConsumption';
export const DEVICE_AGENT_USAGE = 'DeviceAgentUsage';
export const DEVICE_TOTAL_GAS_CONSUMPTION = 'DeviceTotalGasConsumption';
export const DEVICE_USAGE_STATISTICS = 'DeviceUsageStatistics';
export const DETAILED_USAGE_STATISTICS = 'DetailedUsageStatistics';
export const DETAILED_USAGE_STATISTICS_PER_DEVICE =
  'DetailedUsageStatisticsPerDevice';
export const RUNNING_HOURS = 'RunningHours';
export const CASE_LIST = 'CaseList';
export const SERVICE_ORDERS_LIST = 'ServiceOrdersList';
export const PREVENTIVE_MAINTENANCE_LIST = 'PreventiveMaintenancesList';
export const INSTALLED_BASE_LIST = 'InstalledBaseList';
export const SERVO_USAGE_STATISTICS = 'ServoUsageStatisticsOverview';
export const USAGE_STATISTICS_SERVO_DETAILED = 'UsageStatisticsServoDetailed';
export const CASE_DETAILS = 'CaseDetails';
export const DEVICE_CASE_TIMELINE = 'DeviceCaseTimeline';
export const CASE_DETAILS_GAS_CONSUMPTION = 'CaseDetailsGasConsumption';

const createDocument = (type, data, filters, filename, additional, intl) => {
  const workbook = new ExcelJS.Workbook();
  workbook.creator = 'Getinge Online';
  workbook.created = new Date();
  workbook.modified = new Date();

  let transformedData,
    columns = [];
  let exportType = '';
  switch (type) {
    case AGENT_USAGE:
      exportType = type;
      columns = agentUsageColumns;
      transformedData = transformAgentUsage(data);
      break;
    case TOTAL_GAS_CONSUMPTION:
      exportType = type;
      columns = totalGasConsumptionColumns;
      transformedData = transformTotalGasConsumption(data, additional);
      break;
    case DEVICE_AGENT_USAGE:
      exportType = type;
      columns = deviceAgentUsageColumns;
      transformedData = transformDeviceAgentUsage(data, additional);
      break;
    case DEVICE_TOTAL_GAS_CONSUMPTION:
      exportType = type;
      columns = deviceTotalGasConsumptionColumns;
      transformedData = transformDeviceTotalGasConsumption(data, additional);
      break;
    case DEVICE_USAGE_STATISTICS:
      exportType = type;
      columns = deviceUsageStatisticsColumns;
      transformedData = transformDeviceUsageStatistics(data, additional);
      break;
    case DETAILED_USAGE_STATISTICS:
      exportType = type;
      columns = detailedUsageStatisticsColumns;
      transformedData = transformDetailedUsageStatistics(data, additional);
      columns = filterAgcColumns(columns, data);
      break;
    case DETAILED_USAGE_STATISTICS_PER_DEVICE:
      exportType = type;
      columns = detailedUsageStatisticsPerDeviceColumns;
      transformedData = transformDetailedUsageStatisticsPerDevice(
        data,
        additional
      );
      break;
    case SERVO_USAGE_STATISTICS:
      exportType = type;
      columns = getColName(additional.selectedPatientCategoryValue);
      transformedData = transformServoUsageStatisticsOverview(data, additional);
      break;
    case RUNNING_HOURS:
      exportType = type;
      columns = runningHoursColumns;
      transformedData = transformRunningHours(data, additional, intl);
      break;
    case CASE_LIST:
      exportType = type;
      columns = caseListColumns;
      transformedData = transformCaseList(data, additional, intl);
      break;
    case SERVICE_ORDERS_LIST:
      exportType = type;
      columns = serviceOrdersListColumns;
      transformedData = transformServiceOrdersList(data, intl);
      break;
    case PREVENTIVE_MAINTENANCE_LIST:
      exportType = type;
      columns = preventiveMaintenanceListColumns;
      transformedData = transformPreventiveMaintenanceList(data, intl);
      break;
    case INSTALLED_BASE_LIST:
      exportType = type;
      columns = installedBaseListColumns;
      transformedData = transformInstalledBaseList(data, intl);
      break;
    case DEVICE_CASE_TIMELINE:
      exportType = type;
      columns = deviceCaseTimelineListColumns;
      transformedData = transformDeviceCaseTimeline(data, intl);
      break;
    case USAGE_STATISTICS_SERVO_DETAILED:
      exportType = type;
      columns = getColName(additional.selectedPatientCategoryValue, true);
      transformedData = transformUsageStatisticsServoDetailed(data);
      break;
    case CASE_DETAILS:
      exportType = type;
      columns = caseDetailsColumns;
      transformedData = transformCaseDetails(data, additional);
      break;
    case CASE_DETAILS_GAS_CONSUMPTION:
      exportType = type;
      columns = caseDetailsGasConsumptionColumns;
      transformedData = transformCaseDetailsGasConsumption(data, additional);
      break;
    default:
      break;
  }

  const worksheet = workbook.addWorksheet(exportType);
  // Add columns and change font to bold
  worksheet.columns = columns;
  worksheet.getRow(1).font = { bold: true };

  const filterData = getFilters(filters);

  transformedData.forEach(data => {
    // Add filter data
    worksheet.addRow({
      ...data,
      ...filterData
    });
  });

  saveToFile(workbook, getFilename(filename));

  return;
};

export const filterAgcColumns = (columns, data) => {
  if (
    data.averages.hasOwnProperty(averagesTypes.casesAgc) &&
    (typeof data.averages.casesAgc === 'undefined' ||
      data.averages.casesAgc === 0)
  ) {
    return columns.filter(column => column.key != averagesTypes.casesAgc);
  }
  return columns;
};

export const getFilename = filename => {
  return `${moment(new Date()).format('YYYY-MM-DD')}_${filename}`;
};

const saveToFile = (workbook, filename) => {
  workbook.xlsx.writeBuffer().then(function(buffer) {
    const blob = new Blob([buffer], { type: 'applicationi/xlsx' });
    saveBlob(blob, filename);
  });
};

export const getFilters = filters => {
  const hospital = filters.accounts
    ? filters.accounts
        .map(({ label }) => {
          return label;
        })
        .join(',')
    : '';
  const department = filters.departments
    ? filters.departments
        .map(({ label }) => {
          return label;
        })
        .join(', ')
    : '';
  const device = filters.deviceTypes
    ? filters.deviceTypes
        .map(({ label }) => {
          return label;
        })
        .join(', ')
    : '';
  return {
    hospital,
    department,
    device
  };
};

const getColName = (selectedCategory, isDetailed = false) => {
  switch (selectedCategory) {
    case 'All Categories':
      return isDetailed
        ? usageStatisticsServoDetailedColumns
        : servoUsageStatisticsOverviewColumns;
    case 'Neonatal':
      return isDetailed
        ? usageStatisticsServoDetailedNeonatalColumns
        : servoUsageStatisticsOverviewNeonatalColumns;
    case 'Pediatric':
      return isDetailed
        ? usageStatisticsServoDetailedPediatricColumns
        : servoUsageStatisticsOverviewPediatricColumns;
    case 'Adult':
      return isDetailed
        ? usageStatisticsServoDetailedAdultColumns
        : servoUsageStatisticsOverviewAdultColumns;
    default:
      return isDetailed
        ? usageStatisticsServoDetailedColumns
        : servoUsageStatisticsOverviewColumns;
  }
};

export default createDocument;
