import { FETCH_TRENDS, setTrends, entity } from '../actions/trends';
import { API_SUCCESS, apiRequest } from '../actions/api';
import { Metrics, MetricsTimeParams } from '../../components/';
import { API_URLS } from './urls';

const URL = API_URLS.MetricsTrends;

export const trendsMdl = ({ dispatch }) => next => action => {
  next(action);

  const { type } = action;

  switch (type) {
    case FETCH_TRENDS:
      setTrends([]);
      const { deviceIds, timeSpan, count } = action.payload;
      const deviceIdsParams =
        (deviceIds &&
          deviceIds.length > 0 &&
          '&device_ids=' + deviceIds.join(',')) ||
        '';
      const metrics =
        '&metrics=' +
        [
          ...Metrics.filter(agent => agent.type === 'agent').map(
            agent => agent.key
          ),
          ...Metrics.filter(
            agent =>
              agent.type === 'gas' && agent.key !== 'FractionOfInspiredOxygen'
          ).map(agent => agent.key)
        ].join(',');

      dispatch(
        apiRequest({
          method: 'GET',
          url:
            URL.url +
            MetricsTimeParams('trends', timeSpan, count) +
            metrics +
            deviceIdsParams,
          requestSignature: 'trends',
          entity: entity,
          filter: deviceIdsParams.length > 0 ? false : URL.filter
        })
      );

      break;
    case `${entity} GET ${API_SUCCESS}`:
      dispatch(
        setTrends(
          (action.payload.data &&
            !action.payload.data.trends?.every(function(v) {
              return v.value === 0;
            }) &&
            action.payload.data.trends) ||
            []
        )
      );
      break;
    // no default
  }
};
