import {
  FETCH_DEVICE_PREVENTIVE_MAINTENANCE,
  setDevicePreventiveMaintenance,
  entity
} from '../actions/devicePreventiveMaintenance';
import { API_SUCCESS, API_ERROR, apiRequest } from '../actions/api';
import { API_URLS } from './urls';

const URL = API_URLS.DevicePreventiveMaintenance;

export const devicePreventiveMaintenanceMdl = ({
  dispatch
}) => next => action => {
  next(action);

  const { type } = action;

  switch (type) {
    case FETCH_DEVICE_PREVENTIVE_MAINTENANCE:
      setDevicePreventiveMaintenance(null);
      const { deviceId } = action.payload;
      const url = URL(deviceId);

      dispatch(
        apiRequest({
          method: 'GET',
          url: url.url,
          requestSignature: 'devicePreventiveMaintenance',
          entity: entity,
          filter: url.filter
        })
      );
      break;
    case `${entity} GET ${API_SUCCESS}`:
      dispatch(setDevicePreventiveMaintenance(action.payload.data || null));
      break;
    case `${entity} GET ${API_ERROR}`:
      dispatch(setDevicePreventiveMaintenance(null));
      break;
    // no default
  }
};
