// Add column headers and define column keys and widths
export const caseListColumns = [
  { header: 'Device', key: 'deviceType', width: 40 },
  { header: 'Serial no', key: 'serial', width: 20 },
  { header: 'Date', key: 'date', width: 20 },
  { header: 'Start case', key: 'start', width: 15 },
  { header: 'End case', key: 'end', width: 15 },
  { header: 'SEV [mL]', key: 'sev', width: 10 },
  { header: 'DES [mL]', key: 'des', width: 10 },
  { header: 'ISO [mL]', key: 'iso', width: 10 },
  { header: 'N2O [L]', key: 'n2o', width: 10 },
  { header: 'Average FGF [L/min]', key: 'avg_fgf', width: 10 },
  { header: 'FiO2 [%]', key: 'avg_fio2', width: 10 },
  { header: 'AGC', key: 'casesAgc', width: 10 }
];

// Define filename
export const caseListFileName = 'Case_list.xlsx';
