import {
  FETCH_USAGE_STATISTICS_UTILIZATION,
  setUsageStatisticsUtilization,
  entity
} from '../actions/usageStatisticsUtilization';
import { apiRequest, API_SUCCESS } from '../actions/api';
import { MetricsTimeParams } from '../../components/';
import { API_URLS } from './urls';

const URL = API_URLS.UsageStatisticsUtilization;

export const usageStatisticsUtilizationMdl = ({
  dispatch
}) => next => action => {
  next(action);

  const { type } = action;

  switch (type) {
    case FETCH_USAGE_STATISTICS_UTILIZATION:
      const { count, deviceIds, patientCategories, timeSpan } = action.payload;
      const deviceIdsParams =
        (deviceIds &&
          deviceIds.length > 0 &&
          '&device_ids=' + deviceIds.join(',')) ||
        '';
      const selectedPatientCategory =
        patientCategories === null
          ? ''
          : `&patientCategories=${patientCategories.toLowerCase()}`;
      dispatch(
        apiRequest({
          method: 'GET',
          url:
            URL.url +
            MetricsTimeParams('usageStatisticsUtilization', timeSpan, count) +
            deviceIdsParams +
            selectedPatientCategory,
          requestSignature: 'usageStatisticsUtilization',
          entity: entity,
          filter: URL.filter
        })
      );
      break;
    case `${entity} GET ${API_SUCCESS}`:
      dispatch(setUsageStatisticsUtilization(action.payload.data));
      break;
    // no default
  }
};
