import {
  API_REQUEST_STARTED,
  API_REQUEST_FINISHED,
  API_REQUEST_QUEUE
} from '../actions/api';

const initialState = {
  pendingRequests: [],
  queuedRequests: []
};

export function apiReducer(state = initialState, action) {
  switch (action.type) {
    case API_REQUEST_QUEUE: {
      const { request } = action.payload;
      const newState = {
        ...state,
        queuedRequests: [...state.queuedRequests, request]
      };
      return newState;
    }
    case API_REQUEST_STARTED: {
      const { requestSignature } = action.payload;
      const newState = {
        ...state,
        pendingRequests: [...state.pendingRequests, requestSignature],
        queuedRequests: [
          ...state.queuedRequests.filter(
            r => r.meta.requestSignature !== requestSignature.split('_')[0]
          )
        ]
      };
      return newState;
    }
    case API_REQUEST_FINISHED: {
      const { requestSignature } = action.payload;
      const newState = {
        ...state,
        pendingRequests: [
          ...state.pendingRequests.filter(r => r !== requestSignature)
        ]
      };
      return newState;
    }

    default:
      return state;
  }
}
