import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import get from 'lodash.get';
import { WithLoadingSpinner } from '../../hoc';
import PropTypes from 'prop-types';
import { fetchProfile } from '../../redux/actions/profile';
import {
  ContainerHeader,
  Card,
  CardSection,
  EmptyState,
  List,
  ListItem,
  userRoles,
  getDeviceTypeDisplayName
} from '../../components';
import { FormattedMessage, FormattedDate } from 'react-intl';

export const ProfileShape = {
  email: PropTypes.string,
  familyName: PropTypes.string,
  givenName: PropTypes.string,
  username: PropTypes.string,
  adminRole: PropTypes.string,
  portalRole: PropTypes.string,
  regions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      regionCode: PropTypes.string
    })
  ),
  companies: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      sapAccountId: PropTypes.string
    })
  )
};

export const ProfileList = ({ profile, productCertifications = [] }) => {
  const profileInfo = profile && [
    {
      label: (
        <FormattedMessage id="profile.username" defaultMessage="Username" />
      ),
      value: profile.username
    },
    {
      label: (
        <FormattedMessage id="profile.fullName" defaultMessage="Full name" />
      ),
      value: profile.givenName + ' ' + profile.familyName
    },
    {
      label: <FormattedMessage id="profile.email" defaultMessage="Email" />,
      value: profile.email
    },
    {
      label: <FormattedMessage id="profile.role" defaultMessage="Role" />,
      value: userRoles.getLabelFor(profile.portalRole)
    },
    {
      label: (
        <FormattedMessage
          id="profile.certifications"
          defaultMessage="Certifications"
        />
      ),
      Cell: () => (
        <span>
          {(get(productCertifications, 'length', 0) !== 0 &&
            productCertifications
              // Filter out certifications with missing values
              .filter(cert => cert.device_type && cert.expiry_date)
              .map(cert => (
                <div key={cert.device_type} style={{ marginBottom: '0.25rem' }}>
                  <FormattedMessage
                    id="profile.certificationValidity"
                    defaultMessage="Getinge Certified { deviceType } Training valid until"
                    values={{
                      deviceType: getDeviceTypeDisplayName(cert.device_type)
                    }}
                  />{' '}
                  <FormattedDate value={cert.expiry_date} />
                </div>
              ))) || (
            <FormattedMessage id="common.none" defaultMessage="None" />
          )}
        </span>
      )
    },
    {
      label:
        profile.regions && profile.regions.length > 0 ? (
          <FormattedMessage id="profile.ssus" defaultMessage="SSUs" />
        ) : (
          <FormattedMessage id="profile.hospitals" defaultMessage="Hospitals" />
        ),
      value: (profile.regions && profile.regions.length > 0
        ? profile.regions
        : profile.companies
      )
        .map(entity => (entity.name || '').replace('ACT GetingeOnline - ', ''))
        .join(', ')
    }
  ];

  return (
    (profileInfo && (
      <List>
        {profileInfo.map((profileInfoRow, _) => (
          <ListItem
            justifyContent="start"
            key={
              (typeof profileInfoRow.label === 'object' &&
                profileInfoRow.label.props.id) ||
              profileInfoRow.label
            }
          >
            <label style={{ minWidth: 150 }}>{profileInfoRow.label}</label>
            {profileInfoRow.Cell ? (
              profileInfoRow.Cell()
            ) : (
              <span>{profileInfoRow.value}</span>
            )}
          </ListItem>
        ))}
      </List>
    )) || <EmptyState />
  );
};

ProfileList.propTypes = {
  profile: PropTypes.shape(ProfileShape)
};

const Profile = ({ fetchProfile, profile, productCertifications, jwt }) => {
  useEffect(() => {
    fetchProfile();
  }, [fetchProfile, jwt]);
  return (
    <>
      <ContainerHeader
        headerTitle={profile && profile.givenName + ' ' + profile.familyName}
      />
      <div className="row">
        <div className="col-12 col-md-12">
          <Card dataCy="cardServiceOrders">
            <CardSection
              title={
                <FormattedMessage
                  id="profile.userDetails"
                  defaultMessage="User details"
                />
              }
            >
              <WithLoadingSpinner when="profile">
                <ProfileList
                  profile={profile}
                  productCertifications={productCertifications}
                />
              </WithLoadingSpinner>
            </CardSection>
          </Card>
        </div>
      </div>
    </>
  );
};

const mapDispatchToProps = {
  fetchProfile
};

const mapStateToProps = state => ({
  profile: state.profile.profile,
  productCertifications: get(
    state,
    'authorization.userinfo.product_certifications',
    []
  ),
  jwt: state.authorization.jwt
});

Profile.propTypes = {
  fetchProfile: PropTypes.func,
  profile: PropTypes.shape(ProfileShape),
  productCertifications: PropTypes.arrayOf(
    PropTypes.shape({
      device_type: PropTypes.string.isRequired,
      expiry_date: PropTypes.string.isRequired
    })
  ),
  jwt: PropTypes.shape({
    exp: PropTypes.number,
    token: PropTypes.string
  })
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)(Profile);
