import React, { useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import {
  PreventiveMaintenanceStatusIcon,
  PreventiveMaintenanceDateStatus,
  DataTable,
  EmptyState,
  getDeviceTypeDisplayName,
  RunningHoursStatus,
  CassetteMembraneRemainingCapacity,
  routePaths,
  deviceFamilies,
  getDeviceFamilyName,
  isFunction,
  formatValueUnit,
  DataTableFixedColumnHeader
} from '..';
import {
  PmStatusDisplay,
  PmDateDisplay,
  NotAvailable,
  getBatteriesData
} from './DevicePreventiveMaintenanceListWidget';
import './PreventiveMaintenanceList.scss';
import { preventiveMaintenanceShape } from '../../utils/proptypes/preventiveMaintenanceShape';

export const SubComponentRow = ({
  id,
  defaultMessage,
  messageAppendix,
  last,
  next,
  status
}) => (
  <dl>
    <dt>
      {isFunction(status)
        ? status()
        : (status && <PreventiveMaintenanceStatusIcon status={status} />) || (
            <span className="icon md d-flex justify-content-center align-items-center font-weight-normal">
              <NotAvailable />
            </span>
          )}
      <FormattedMessage id={id} defaultMessage={defaultMessage} />
      {messageAppendix && (
        <span style={{ marginLeft: '0.3rem' }}>{' ' + messageAppendix}</span>
      )}
    </dt>
    <dd>
      <div>{isFunction(last) ? last() : <PmDateDisplay date={last} />}</div>
      <div>{isFunction(next) ? next() : <PmDateDisplay date={next} />}</div>
    </dd>
  </dl>
);

const SubComponent = row => {
  const original = row.original;
  const subRowsData = [
    {
      id: 'preventiveMaintenance.PM',
      defaultMessage: 'PM',
      last: get(original, 'last'),
      next: get(original, 'next'),
      status: PreventiveMaintenanceDateStatus(get(original, 'next'))
    },
    {
      id: 'preventiveMaintenance.memoryBackupBatteries',
      defaultMessage: 'Memory backup batteries',
      last: get(original, 'battery.memoryBackup.last'),
      next: get(original, 'battery.memoryBackup.next'),
      status: get(original, 'battery.memoryBackup.status'),
      hideForDeviceFamily: [
        deviceFamilies.servo.name,
        deviceFamilies.rotaflow.name
      ]
    },
    {
      id: 'preventiveMaintenance.powerBackupBattery',
      defaultMessage: 'Power backup battery',
      last: get(original, 'battery.powerBackup.last'),
      next: get(original, 'battery.powerBackup.next'),
      status: get(original, 'battery.powerBackup.status'),
      hideForDeviceFamily: [
        deviceFamilies.servo.name,
        deviceFamilies.rotaflow.name
      ]
    },
    {
      id: 'preventiveMaintenance.cassetteMembraneRemainingCapacity',
      defaultMessage: 'Cassette membrane remaining capacity',
      last: get(original, 'cassetteMembrane.last'),
      next: () => {
        const strokes = get(original, 'cassetteMembrane.strokes');

        return (
          (strokes && CassetteMembraneRemainingCapacity(strokes)) || (
            <NotAvailable />
          )
        );
      },
      status: get(original, 'cassetteMembrane.status'),
      hideForDeviceFamily: [deviceFamilies.rotaflow.name]
    },
    {
      id: 'preventiveMaintenance.runningHoursSincePM',
      defaultMessage: 'Running hours since last PM',
      last: () => {},
      next: () => {
        const hours = get(original, 'runningHoursSince');
        const maxHours = get(original, 'maxRunningHours');
        return (
          (hours &&
            maxHours &&
            formatValueUnit(hours + ' / ' + maxHours, 'h')) || <NotAvailable />
        );
      },
      status: () => {
        const hours = get(original, 'runningHoursSince');
        const maxHours = get(original, 'maxRunningHours');
        return (
          (hours && maxHours && (
            <PreventiveMaintenanceStatusIcon
              status={RunningHoursStatus(
                row.original.runningHoursSince,
                row.original.maxRunningHours
              )}
            />
          )) || (
            <span className="icon md d-flex justify-content-center align-items-center font-weight-normal">
              <NotAvailable />
            </span>
          )
        );
      },
      hideForDeviceFamily: [deviceFamilies.rotaflow.name]
    }
  ];

  subRowsData.push(...getBatteriesData(original));

  return (
    <div className="gtg-preventive-maintenance-detail">
      {subRowsData
        .filter(item =>
          item.hideForDeviceFamily
            ? !item.hideForDeviceFamily.includes(
                getDeviceFamilyName(original.device.type)
              )
            : true
        )
        .map(rowData => (
          <SubComponentRow key={rowData.key || rowData.id} {...rowData} />
        ))}
    </div>
  );
};

const PreventiveMaintenanceList = props => {
  const {
    preventiveMaintenance,
    deviceID,
    showPagination,
    manualPaginationSettings,
    history,
    currentPageExtra
  } = props;

  const handleRowClick = useCallback(
    (state, rowInfo) => {
      if (
        rowInfo &&
        rowInfo.original &&
        rowInfo.original.device &&
        rowInfo.original.device.id
      ) {
        history.push(
          `/${routePaths.DeviceOverview.path}/${rowInfo.original.device.id}`
        );
      }
    },
    [history]
  );

  const columns = [
    {
      Header: (
        <FormattedMessage
          id="preventiveMaintenance.status"
          defaultMessage="PM Status"
        />
      ),
      accessor: 'status',
      Cell: row => <PmStatusDisplay status={row.value} />,
      width: 36,
      headerClassName: 'pl-0 text-left',
      className: 'text-left',
      noOverflowTooltip: true
    },
    {
      Header: (
        <FormattedMessage
          id="preventiveMaintenance.nextPMdue"
          defaultMessage="Next PM due"
        />
      ),
      Cell: row =>
        (row.value !== undefined && row.value !== null && (
          <span>
            {row.value}{' '}
            <FormattedMessage id="common.days" defaultMessage="days" />
          </span>
        )) || <NotAvailable />,
      accessor: 'nextDue',
      headerClassName: 'text-right',
      className: 'text-right'
    },
    {
      Header: <FormattedMessage id="column.device" defaultMessage="Device" />,
      Cell: row =>
        (row.value && getDeviceTypeDisplayName(row.value)) || <NotAvailable />,
      accessor: 'device.type',
      headerClassName: 'ml-2 text-left',
      className: 'ml-2 text-left'
    },
    {
      Header: (
        <FormattedMessage id="column.department" defaultMessage="Department" />
      ),
      Cell: row => row.value || <NotAvailable />,
      accessor: 'device.department.name',
      headerClassName: 'text-left',
      className: 'text-left'
    },
    {
      Header: (
        <FormattedMessage
          id="column.customerDeviceNo"
          defaultMessage="Customer device no"
        />
      ),
      Cell: row => row.value || <NotAvailable />,
      accessor: 'device.customerDeviceID',
      headerClassName: 'text-right',
      className: 'text-right'
    },
    {
      Header: (
        <FormattedMessage id="column.serialNo" defaultMessage="Serial no" />
      ),
      Cell: row => row.value || <NotAvailable />,
      accessor: 'device.serialID',
      headerClassName: 'text-right',
      className: 'text-right'
    },
    {
      Header: (
        <FormattedMessage
          id="preventiveMaintenance.lastPM"
          defaultMessage="Last PM"
        />
      ),
      Cell: row => <PmDateDisplay date={row.value} />,
      accessor: 'last',
      headerClassName: 'text-right',
      className: 'text-right'
    },
    {
      Header: (
        <FormattedMessage
          id="preventiveMaintenance.lastPMtype"
          defaultMessage="Last PM type"
        />
      ),
      Cell: row => row.value || <NotAvailable />,
      accessor: 'lastType',
      headerClassName: 'ml-2 text-left',
      className: 'ml-2 text-left'
    },
    {
      Header: (
        <FormattedMessage
          id="preventiveMaintenance.nextPM"
          defaultMessage="Next PM"
        />
      ),
      Cell: row => <PmDateDisplay date={row.value} />,
      accessor: 'next',
      headerClassName: 'text-right',
      className: 'text-right'
    }
  ];

  const preventiveMaintenanceColumns = deviceID
    ? columns.filter(column => column.hideOnSingleDeviceView !== true)
    : columns;

  return (
    <React.Fragment>
      {(preventiveMaintenance && preventiveMaintenance.length > 0 && (
        <DataTableFixedColumnHeader
          manualPaginationSettings={manualPaginationSettings}
          showPagination={showPagination}
          SubComponent={SubComponent}
          className={classnames('gtg-preventive-maintenance-list', 'mb-3')}
          data={preventiveMaintenance}
          columns={preventiveMaintenanceColumns}
          handleRowClick={handleRowClick}
          noCustomScrollbars
          withOverflow
          currentPageExtra={currentPageExtra}
        />
      )) || <EmptyState />}
    </React.Fragment>
  );
};

PreventiveMaintenanceList.propTypes = {
  preventiveMaintenance: PropTypes.arrayOf(preventiveMaintenanceShape)
};

export default withRouter(PreventiveMaintenanceList);
