import React, { useEffect, useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { Modal, Input, Select, EmptyState } from '../..';
import { WithLoadingSpinner } from '../../../hoc';
import { putCosts } from '../../../redux/actions/costs';
import { fetchCurrencies } from '../../../redux/actions/currency';

import './AgentCostEntryModal.scss';
import {
  getValidationErrors,
  getCurrencyFromCode,
  getInitialData
} from './AgentCostEntryModalHelpers';

/**
 * When using this component the only thing you need to supply is the `onDone` function, that should close the modal.
 * All other props will be suplied with `mapDispatch/StateToProps`.
 * @param onDone Function that will be called once the modal needs to be closed.
 */
export const AgentCostEntryModal = ({
  putCosts,
  fetchCurrencies,
  currencies,
  costs,
  onDone
}) => {
  // Initial data fetch
  useEffect(() => {
    if (!currencies || currencies.length === 0) {
      fetchCurrencies();
    }
  }, [currencies, fetchCurrencies]);

  // Form state with initial values
  const [state, setState] = useState(() => getInitialData(costs));

  // Update the state value and possible errors for a giver property
  const updateValue = useCallback(
    (value, name) => {
      const errors = getValidationErrors(currencies, value, name);

      setState(prevState => ({
        ...prevState,
        [name]: {
          ...prevState[name],
          value,
          errors
        }
      }));
    },
    [setState, currencies]
  );

  // Initial costs default values should get overwritten from what the API returns
  useEffect(() => {
    if (!costs) return;
    updateValue(costs.currency.code, 'currency');
    updateValue(costs.sev, 'sev');
    updateValue(costs.des, 'des');
    updateValue(costs.iso, 'iso');
  }, [costs, updateValue]);

  // Save the data, if we have it all
  const saveHandler = useCallback(() => {
    const controls = [state.currency, state.sev, state.des, state.iso];
    let haveError = false;

    controls.forEach(control => {
      // Do another round of validation
      if (
        getValidationErrors(currencies, control.value, control.name).length !==
        0
      ) {
        haveError = true;
        updateValue(control.value, control.name);
      }
    });

    if (!haveError) {
      // Everything checks out, save it
      putCosts({
        currency: getCurrencyFromCode(currencies, state.currency.value),
        sev: Number(state.sev.value),
        des: Number(state.des.value),
        iso: Number(state.iso.value)
      });
      // Notify parent component that we are done here (it should close the modal)
      onDone();
    }
  }, [
    state.currency,
    state.sev,
    state.des,
    state.iso,
    currencies,
    updateValue,
    putCosts,
    onDone
  ]);

  // Values used in the Select component
  const selectValues = useMemo(() => {
    return currencies.map(currency => ({
      value: currency.code,
      label: `${currency.name}, ${currency.code} - ${currency.symbol}`
    }));
  }, [currencies]);

  const agentPriceUnit = useMemo(() => {
    const selectedCurrency = getCurrencyFromCode(
      currencies,
      state.currency.value
    );

    return selectedCurrency ? `${selectedCurrency.symbol}/L` : ' ';
  }, [state.currency.value, currencies]);

  // These are passed to all Input components
  const commonInputProps = {
    setValue: updateValue,
    postfix: agentPriceUnit
  };

  return (
    <Modal
      saveStr={
        <FormattedMessage id="agentCost.setButton" defaultMessage="Set" />
      }
      closeStr={
        <FormattedMessage id="agentCost.cancelButton" defaultMessage="Cancel" />
      }
      title={
        <FormattedMessage
          id="agentCost.modalTitle"
          defaultMessage="Input costs"
        />
      }
      dataCy="agentCostEntryModal"
      onClickedOutside={onDone}
      onClose={onDone}
      onSave={saveHandler}
      className="gtg-agent-cost-entry-modal"
    >
      <WithLoadingSpinner when={['currency', 'costs']}>
        {(currencies.length > 0 && (
          <form>
            <Select
              {...state.currency}
              allValues={selectValues}
              setValue={updateValue}
              postfix
            ></Select>
            <Input {...state.des} {...commonInputProps} />
            <Input {...state.sev} {...commonInputProps} />
            <Input {...state.iso} {...commonInputProps} />
          </form>
        )) || <EmptyState />}
      </WithLoadingSpinner>
    </Modal>
  );
};
export const currencyShape = PropTypes.shape({
  code: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  symbol: PropTypes.string.isRequired
});

AgentCostEntryModal.propTypes = {
  onDone: PropTypes.func.isRequired,
  putCosts: PropTypes.func.isRequired,
  fetchCurrencies: PropTypes.func.isRequired,
  currencies: PropTypes.arrayOf(currencyShape).isRequired,
  costs: PropTypes.shape({
    currency: currencyShape,
    sev: PropTypes.number,
    des: PropTypes.number,
    iso: PropTypes.number
  })
};

const mapStateToProps = state => ({
  currencies: get(state, 'currency.currency.items', [])
});

const mapDispatchToProps = {
  putCosts,
  fetchCurrencies
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  AgentCostEntryModal
);
