import { SET_TRENDS } from '../actions/trends';

const initState = {
  trends: []
};

export const trendsReducer = (state = initState, action) => {
  const { payload, type } = action;

  switch (type) {
    case SET_TRENDS:
      return {
        ...state,
        trends: payload
      };
    default:
      return state;
  }
};
