import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import stylesList from './List.module.scss';

/**
 * Displays a list item within List
 * @param className CSS classname
 * @param minListItemHeight List item height in px: 40, 50 or 60
 * @param justifyContent Applies justify-content- bootstrap helper class. Options: start, end, center, between (default), around
 */

const ListItem = ({
  className,
  minListItemHeight,
  children,
  justifyContent = 'between'
}) => (
  <li
    className={classNames(
      stylesList.gtgListItem,
      className,
      minListItemHeight && 'height-' + minListItemHeight,
      'list-item',
      justifyContent && 'justify-content-' + justifyContent
    )}
    data-cy="listItem"
  >
    {children}
  </li>
);

ListItem.propTypes = {
  className: PropTypes.string,
  minListItemHeight: PropTypes.oneOf([40, 50, 60]),
  justifyContent: PropTypes.oneOf([
    'start',
    'end',
    'center',
    'between',
    'around'
  ]),
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ])
};

export default ListItem;
