import { deviceFamilies } from '../Device/deviceHelper';

const softwareOptions = [
  {
    devices: [...deviceFamilies.flow.deviceTypes],
    options: {
      PATIENT_TYPE_ADULT: false,
      PATIENT_TYPE_NEONATHAL: false,
      MODE_VOLUME_CONTROL: 'Volume Control',
      MODE_VOLUME_SUPPORT: 'Volume Support',
      MODE_PRESSURE_CONTROL: 'Pressure Control',
      MODE_PRESSURE_SUPPORT: 'Pressure Support',
      MODE_PRVC: 'PRVC',
      MODE_BIVENT: 'Bivent',
      MODE_SIMV_VOLUME_CONTROL: 'SIMV Volume Control',
      MODE_SIMV_PRESSURE_CONTROL: 'SIMV Pressure Control',
      MODE_SIMV_PRVC: 'SIMV PRVC',
      AUTOMODE: 'Automode',
      PS_BACKUP: 'Pressue Support with backup',
      GAS_DELIVERY_INSP_REG: false,
      GAS_DELIVERY_EXP_REG: 'AGC',
      AFGO: 'AFGO',
      AA_CALC_TOOL: 'Agent usage',
      LUNG_RECRUITMENT: 'Recruitment Maneuvers',
      TWO_VAPORIZER_SLOTS: false,
      HIGH_PERF_VENTILATION_PARAMS: 'High performance ventilation',
      PEDRIATRIC_RANGE_PARAMS: 'Low VT ventilation',
      PBW: 'Predicted Body Weight (PBW)',
      N2O: 'N2O option',
      HLM: 'HLM/CPB (Heart Lung Machine/CardioPulmonary Bypass mode)',
      INSP_EXP_HOLD: 'Insp hold/Exp hold',
      OXYGEN_93: 'Oxygen 93',
      OXYGEN_ONLY: 'Oxygen only'
    }
  },
  {
    devices: [...deviceFamilies.servo.deviceTypes],
    options: {
      PATIENT_TYPE_ADULT: 'Adult',
      PATIENT_TYPE_PEDIATRIC: 'Pediatric',
      PATIENT_TYPE_NEONATHAL: 'Neonatal',
      NAVA: 'NAVA',
      MODE_PRESSURE_SUPPORT: 'Pressure Support (PS)/CPAP',
      MODE_PRESSURE_CONTROL: 'Pressure Control (PC)',
      MODE_VOLUME_CONTROL: 'Volume Control (VC)',
      MODE_PRVC: 'PRVC',
      MODE_VOLUME_SUPPORT: 'Volume Support (VS)',
      MODE_BIVENT: 'Bi-Vent/APRV',
      MODE_SIMV_VOLUME_CONTROL: 'SIMV(VC) + PS',
      MODE_SIMV_PRESSURE_CONTROL: 'SIMV(PC) + PS',
      MODE_SIMV_PRVC: 'SIMV(PRVC) + PS',
      NON_INVASIVE_VENTILATION: 'NIV',
      NASAL_CPAP: 'Nasal CPAP',
      AUTOMODE: 'Automode',
      COMPASS: 'Servo Compass',
      OLT_TRENDS: 'Open Lung Tool trend',
      OLT_AUTO_RM: 'Open Lung Tool Auto RM',
      OLT_AUTO_SRM: 'Open Lung Tool Auto SRM',
      TRANSPULMONARY_PRESSURE: 'Transpulmonary pressure',
      REMOTE_ALARM: 'Alarm output',
      NIV_NAVA: 'NIV NAVA',
      YPIECE_MEASUREMENT: 'Y sensor',
      CO2_BASIC: 'CO2 analyzer',
      HFO: 'HFOV',
      HFT: 'High Flow therapy',
      HELIOX: 'Heliox',
      NIV_NEURAL_PRESSURE_SUPPORT: 'NIV Neural ressure support',
      ZERO_ASSIST_MANEUVER: 'Zero assist maneuver',
      CONNECTIVITY: 'Connectivity',
      NEURAL_PRESSURE_SUPPORT: 'Neural pressure support'
    }
  }
];

export default softwareOptions;
