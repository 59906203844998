import React from 'react';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import propTypes from 'prop-types';
import { Icon, Capitalize } from '..';

import './BadgeCountComponent.scss';

const BadgeCountComponent = ({ subscriptionType, deviceCount }) => {
  return (
    <>
      {subscriptionType && (
        <div
          title={`${Capitalize(subscriptionType)}`}
          className={`gtg-badge-count-component gtg-badge-count-component-${subscriptionType}`}
        >
          <section>
            <Icon name={`badge-count-${subscriptionType}`} size="md" />
          </section>
          <section>
            <span>{deviceCount}</span>
          </section>
        </div>
      )}
    </>
  );
};

BadgeCountComponent.propTypes = {
  device: propTypes.shape({
    subscriptionType: propTypes.string,
    deviceCount: propTypes.number
  })
};

export default withRouter(injectIntl(BadgeCountComponent));
