import React from 'react';
import {
  routePaths,
  StatusToIcon,
  PathJoin,
  BadgeCountComponent,
  Icon,
  subscriptionTypes
} from '..';
import { withI18n, WithLoadingSpinner, WithTooltip } from '../../hoc';
import DevicesStatusOverviewTimeUntilNextPM from './DevicesStatusOverviewTimeUntilNextPM';
import ConnectedDevicesTooltipContent from './ConnectedDevicesTooltipContent';
import { getDeviceTypeDisplayName } from '..';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';
import get from 'lodash.get';
import classnames from 'classnames';

import styles from './DevicesStatusOverview.module.scss';
import { compose } from 'redux';
import { connect } from 'react-redux';

const DevicesStatusOverview = props => {
  // Device status
  const status = props.devicesStatusOverview;
  const subscriptionData = get(status, 'subscriptions', []);
  const ha = get(status, 'highestAlert', '');
  let alertObj = StatusToIcon(ha);
  if (ha === 'OK') {
    alertObj.count = status.connectedDevices;
  } else {
    alertObj.count = get(status, 'devicesRequiringAttention', []).length;
  }

  let deviceStatusHref = PathJoin([routePaths.DeviceOverview.path]);
  let deviceStatusTooltip = (
    <>
      {alertObj.count}{' '}
      <FormattedMessage
        id="devicesStatusOverviewAlertLevel.devicesRequireAttention"
        defaultMessage="devices require attention"
      />
    </>
  );
  if (alertObj.count === 1) {
    deviceStatusTooltip = (
      <>
        {alertObj.count}{' '}
        <FormattedMessage
          id="devicesStatusOverviewAlertLevel.deviceRequiresAttention"
          defaultMessage="device requires attention"
        />
      </>
    );
    deviceStatusHref = PathJoin([
      routePaths.DeviceOverview.path,
      props.devicesStatusOverview.devicesRequiringAttention[0]
    ]);
  }

  // Service orders
  const openServiceOrders = get(props, 'serviceOrdersOverview.opened', null);

  function getObjWithSubscriptionType(subscriptionName, data) {
    return data.filter(function(data) {
      return data.level == subscriptionName;
    });
  }

  function formatData(data) {
    let sorted_obj = {};
    Object.keys(data).forEach(function(key) {
      let ob = data[key].sort(function(a, b) {
        return a.level.localeCompare(b.level);
      });
      sorted_obj[key] = ob;
    });
    const firstKeyOfDataObj = Object.keys(sorted_obj)[0];
    if (Object.keys(sorted_obj).length > 1) {
      let res = [];
      for (var objKey in sorted_obj) {
        for (var key in subscriptionTypes) {
          let d = getObjWithSubscriptionType(key, sorted_obj[objKey]);
          if (d[0]) {
            res.push(d[0]);
          }
        }
      }

      let result = Array.from(
        res.reduce(
          (m, { level, count }) =>
            m.set(level, (m.get(level) || 0) + count, level),
          new Map()
        ),
        ([level, count]) => ({ level, count })
      );
      return result;
    }

    return sorted_obj[firstKeyOfDataObj];
  }

  function getSortedTotalSubscriptions(subscription_data) {
    const isServoUsageStatisticsEnabled = get(
      props.config,
      'enableServoUsageStatistics',
      false
    );

    return Object.keys(subscription_data).length === 0
      ? []
      : isServoUsageStatisticsEnabled
      ? formatData(subscription_data)
      : get(status, 'subscriptions.flow', []);
  }

  return (
    <div className={styles.gtgDevicesStatusOverview + ''}>
      <section data-cy={'sectionDeviceStatus'}>
        <Link to={deviceStatusHref}>
          <WithLoadingSpinner when="devicesStatusOverview">
            <WithTooltip tooltipContent={deviceStatusTooltip} className="w-100">
              {props.devicesStatusOverview && (
                <div
                  className={classnames(
                    styles[`level-${alertObj.color}`],
                    styles[`widget-container`]
                  )}
                >
                  <h4 data-cy="txtDeviceStatus">
                    <FormattedMessage
                      id="devicesStatusOverviewAlertLevel.deviceStatus"
                      defaultMessage="Device status"
                    />
                  </h4>
                  <p>
                    <Icon
                      name={alertObj.icon}
                      className={styles.gtgDevicesStatusOverviewAlertIcon}
                    />
                    <span className={styles.gtgDevicesStatusOverviewAlertCount}>
                      {alertObj.count}
                    </span>
                  </p>
                </div>
              )}
            </WithTooltip>
          </WithLoadingSpinner>
        </Link>
      </section>
      <section>
        <WithLoadingSpinner when="devicesStatusOverview">
          <WithTooltip
            tooltipContent={
              props &&
              props.devicesStatusOverview &&
              props.devicesStatusOverview.deviceTypes && (
                <ConnectedDevicesTooltipContent
                  devices={props.devicesStatusOverview.deviceTypes.map(
                    deviceType => {
                      return {
                        ...deviceType,
                        name: getDeviceTypeDisplayName(deviceType.name)
                      };
                    }
                  )}
                />
              )
            }
          >
            <h4 data-cy="txtConnectedDevices">
              <FormattedMessage
                id="devicesStatusOverview.connectedDevices"
                defaultMessage="Connected devices"
              />
            </h4>
            <p>
              <span>
                {props &&
                  props.devicesStatusOverview &&
                  props.devicesStatusOverview.connectedDevices && (
                    <span>{props.devicesStatusOverview.connectedDevices}</span>
                  )}
              </span>
            </p>
          </WithTooltip>
        </WithLoadingSpinner>
      </section>
      <section>
        <Link
          to={
            props &&
            props.devicesStatusOverview &&
            PathJoin([routePaths.PreventiveMaintenanceOverview.path])
          }
        >
          <WithLoadingSpinner when="devicesStatusOverview">
            <div>
              <h4 data-cy="txtTimeUntilNextPM">
                <FormattedMessage
                  id="devicesStatusOverview.timeUntilNextPM"
                  defaultMessage="Time until next PM"
                />
              </h4>
              <DevicesStatusOverviewTimeUntilNextPM {...props} />
            </div>
          </WithLoadingSpinner>
        </Link>
      </section>
      <section>
        <Link
          to={
            props &&
            props.devicesStatusOverview &&
            PathJoin([routePaths.ServiceOrderOverview.path])
          }
        >
          <WithLoadingSpinner when="serviceOrdersOverview">
            <div>
              <h4 data-cy="txtServiceOrders">
                <FormattedMessage
                  id="devicesStatusOverview.openServiceOrders"
                  defaultMessage="Open service orders"
                />
              </h4>
              <p>
                <span>
                  {openServiceOrders === null ? (
                    <FormattedMessage
                      id="common.notAvailableAbbr"
                      defaultMessage="N/A"
                    />
                  ) : (
                    openServiceOrders
                  )}
                </span>
              </p>
            </div>
          </WithLoadingSpinner>
        </Link>
      </section>
      {get(props, 'config.enableSubscriptions', false) && (
        <section data-cy={'accountSubscriptionsOverview'}>
          <Link
            to={PathJoin([
              routePaths.DeviceOverview.path,
              routePaths.InstalledBaseSubscriptions.path
            ])}
          >
            <WithLoadingSpinner when="devicesStatusOverview">
              <div>
                <h4 data-cy="txtAccountSubscriptionsOverview">
                  <FormattedMessage
                    id="devicesStatusOverview.accountSubscriptionsOverview"
                    defaultMessage="Subscriptions"
                  />
                </h4>
                <div className={styles.gtgBadgeCountsContainer}>
                  {getSortedTotalSubscriptions(subscriptionData)
                    .filter(subscription =>
                      ['standard', 'advanced', 'premium'].includes(
                        subscription.level
                      )
                    )
                    .sort((a, b) => b.priority - a.priority)
                    .map(subscription => {
                      return (
                        <BadgeCountComponent
                          key={subscription.level}
                          subscriptionType={subscription.level}
                          deviceCount={subscription.count}
                        />
                      );
                    })}
                </div>
              </div>
            </WithLoadingSpinner>
          </Link>
        </section>
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  config: state.config
});

DevicesStatusOverview.propTypes = {
  intl: intlShape,
  devicesStatusOverview: PropTypes.shape({}).isRequired,
  serviceOrdersOverview: PropTypes.shape({}).isRequired
};

export default compose(
  connect(mapStateToProps),
  injectIntl
)(DevicesStatusOverview);
