import React, { useEffect, useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { WithLoadingSpinner } from '../../hoc';

import { fetchServiceOrders } from '../../redux/actions/serviceOrders';
import { pushPaginationHistory } from '../../redux/actions/paginationHistory';
import {
  uiConfig,
  routePaths,
  ContainerHeader,
  DeviceServiceOrderList,
  Card,
  CardSection,
  decodePaginationURI
} from '../../components';

const DeviceServiceOrderOverview = props => {
  const {
    device,
    serviceOrders,
    filters,
    fetchServiceOrders,
    location,
    pushPaginationHistory
  } = props;

  const handleFetchCallback = useCallback(
    (...fetchParams) => {
      pushPaginationHistory(
        fetchParams[1].page,
        fetchParams[1].pageSize,
        fetchParams[2]
      );
      fetchServiceOrders(...fetchParams);
    },
    [fetchServiceOrders, pushPaginationHistory]
  );

  const pageSizeOptions = uiConfig.pageSizeOptions[0];

  const initialPaginationSettings = useMemo(() => {
    return {
      page: 0,
      pageSize: pageSizeOptions,
      sort: 'status'
    };
  }, [pageSizeOptions]);

  const manualPaginationSettings = useMemo(() => {
    return {
      fetchMethod: handleFetchCallback,
      fetchParams: [device.id],
      paginationSettings: props.serviceOrdersPagination,
      sortingSettings: props.serviceOrdersSorting,
      customPageSizeOptions: uiConfig.pageSizeOptions
    };
  }, [
    handleFetchCallback,
    device.id,
    props.serviceOrdersPagination,
    props.serviceOrdersSorting
  ]);

  useEffect(() => {
    fetchServiceOrders(
      device.id,
      {
        page: initialPaginationSettings.page,
        pageSize: initialPaginationSettings.pageSize
      },
      initialPaginationSettings.sort
    );
  }, [
    device.id,
    fetchServiceOrders,
    filters,
    initialPaginationSettings.page,
    initialPaginationSettings.pageSize,
    initialPaginationSettings.sort
  ]);

  useEffect(() => {
    if (device) {
      const urlPagination = decodePaginationURI(
        location.search,
        initialPaginationSettings
      );
      fetchServiceOrders(
        device.id,
        {
          page: urlPagination.page,
          pageSize: urlPagination.pageSize
        },
        urlPagination.sort
      );
    }
  }, [device, fetchServiceOrders, initialPaginationSettings, location]);

  return (
    <>
      <ContainerHeader
        headerTitle={routePaths.DeviceServiceOrderOverview.label}
      />
      <div className="row">
        <div className="col-12 col-md-12">
          <Card>
            <CardSection dataCy="sectionDeviceServiceOrdersList">
              <WithLoadingSpinner when="serviceOrders">
                <DeviceServiceOrderList
                  serviceOrders={serviceOrders}
                  deviceID={device.id}
                  manualPaginationSettings={manualPaginationSettings}
                />
              </WithLoadingSpinner>
            </CardSection>
          </Card>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = state => ({
  serviceOrders: state.serviceOrders.serviceOrders.items,
  serviceOrdersPagination: state.serviceOrders.serviceOrders.pagination,
  serviceOrdersSorting: state.serviceOrders.serviceOrders.sorting,
  filters: state.filters
});

const mapDispatchToProps = {
  fetchServiceOrders,
  pushPaginationHistory
};

DeviceServiceOrderOverview.propTypes = {
  fetchServiceOrders: PropTypes.func,
  pushPaginationHistory: PropTypes.func
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)(DeviceServiceOrderOverview);
