import { formatValueUnit } from '..';

/**
 * Calculates the total amount of agent usage and returns the value in ml or L
 * @param metrics An array of metric objects
 */
export const calculateTotalAmount = (metrics, type = 'agent') => {
  // Total value in ml
  let totalValue = 0;
  metrics.forEach(metric => {
    switch (metric.unit.toLowerCase()) {
      case 'ml':
        totalValue += metric.value;
        break;
      case 'cl':
        totalValue += metric.value * 10;
        break;
      case 'dl':
        totalValue += metric.value * 100;
        break;
      case 'l':
      case 'liter':
      case 'liters':
        totalValue += metric.value * 1000;
        break;
      // no default
    }
  });

  return formatValueUnit(totalValue, 'mL', type);
};
