import React from 'react';
import propTypes from 'prop-types';
import styles from './List.module.scss';
import { ListItem } from '../..';
import classnames from 'classnames';

/**
 * Accepts minListItems prop that adds empty list items if
 * there are less list items then required
 * @param {*} props
 */
const list = props => {
  const {
    minListItems = undefined,
    minListItemHeight = undefined,
    children,
    dataCy
  } = props;
  let extraListItems = null;
  const childrenLen = children && children.length ? children.length : 0;
  if (minListItems && childrenLen < minListItems) {
    extraListItems = [...Array(minListItems - childrenLen)].map((_, i) => {
      const key = btoa(children) + i;
      return <ListItem key={key} minListItemHeight={minListItemHeight} />;
    });
  }

  return (
    <ul
      data-cy={dataCy}
      className={classnames(styles.gtgList, list, props.className)}
    >
      {props.children}
      {extraListItems}
    </ul>
  );
};

list.propTypes = {
  /** Add empty list items if length of children array is less then minListItems */
  minListItems: propTypes.oneOfType([propTypes.string, propTypes.number])
};

export default list;
