import React from 'react';

import styles from './GetingeLogo.module.scss';

const getingeLogo = props => {
  return (
    <svg
      className={styles.gtgNavbarLogo}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 945.73 141.41"
    >
      <defs>
        <style>{'.a{fill:#fff;}.b{fill:#b9b8c7;'}</style>
      </defs>
      <title>Getinge_Logo_hz_RGB_neg</title>
      <polygon
        className="a"
        points="129.05 111.72 129.05 30.99 210.48 30.99 203.01 44.01 146.4 44.01 146.4 63.4 200.13 63.38 200.13 75.66 146.4 75.65 146.4 98.22 210.33 98.22 210.33 111.72 129.05 111.72"
      />
      <polygon
        className="a"
        points="655.92 111.72 655.92 30.99 737.09 30.99 729.63 44.01 673.26 44.01 673.26 63.4 726.99 63.38 726.99 75.66 673.26 75.65 673.26 98.22 737.19 98.22 737.19 111.72 655.92 111.72"
      />
      <polygon
        className="a"
        points="272.51 111.72 272.51 44.05 234.63 44.06 234.63 30.99 328.03 30.99 320.56 44.07 289.87 44.05 289.87 111.72 272.51 111.72"
      />
      <rect className="a" x="353.55" y="30.98" width="17.35" height="80.74" />
      <path
        className="a"
        d="M159.16,417.65a23.53,23.53,0,0,1-23.53-23.53V360.44a23.53,23.53,0,0,1,23.53-23.53h68.09l-7,13.06H163.75a11,11,0,0,0-11,11v32A11.24,11.24,0,0,0,164,404.18h43.72a11.1,11.1,0,0,0,11.1-11.1v-8.8H187.4V371.22h48.14v22.9A23.53,23.53,0,0,1,212,417.65H159.16Z"
        transform="translate(-135.63 -305.92)"
      />
      <path
        className="a"
        d="M686,417.65a23.53,23.53,0,0,1-23.53-23.53V360.44A23.53,23.53,0,0,1,686,336.92H754L747,350H690.6A11.06,11.06,0,0,0,679.54,361v32.09a11.06,11.06,0,0,0,11.06,11.06h43.95a11.06,11.06,0,0,0,11.06-11.06v-8.84H714.2V371.22h48.14v22.9a23.53,23.53,0,0,1-23.53,23.53H686Z"
        transform="translate(-135.63 -305.92)"
      />
      <path
        className="a"
        d="M617.25,417.65v-56.9A10.77,10.77,0,0,0,606.48,350H556.36v67.67H539V336.9H610.9a23.53,23.53,0,0,1,23.53,23.53v57.22H617.25Z"
        transform="translate(-135.63 -305.92)"
      />
      <path
        className="b"
        d="M1020.79,338.71l-6.41,11.81-21.1,0V308a2.09,2.09,0,0,1,2.09-2.09h23.33a2.09,2.09,0,0,1,2.09,2.09v30.7Z"
        transform="translate(-135.63 -305.92)"
      />
      <path
        className="a"
        d="M1020.79,333v4l0,3.8,0,3.81a27.6,27.6,0,0,1-41,24.15l-2.66-1.53-1-.58L947.59,350.2a2.1,2.1,0,0,1-.77-2.87l11.65-20.19a2.08,2.08,0,0,1,2.85-.77l35.32,20.39A16,16,0,0,0,1020.79,333Z"
        transform="translate(-135.63 -305.92)"
      />
      <path
        className="b"
        d="M974.2,387.58l13.43-.36,10.59,18.25-36.85,21.28a2.09,2.09,0,0,1-2.85-.76l-11.66-20.2a2.1,2.1,0,0,1,.77-2.87Z"
        transform="translate(-135.63 -305.92)"
      />
      <path
        className="a"
        d="M969.27,390.42l3.46-2,3.3-1.88,3.32-1.88a27.6,27.6,0,0,1,41.39,23.4v37.17a2.1,2.1,0,0,1-2.1,2.1H995.34a2.08,2.08,0,0,1-2.09-2.08V404.47A16,16,0,0,0,969.27,390.42Z"
        transform="translate(-135.63 -305.92)"
      />
      <path
        className="b"
        d="M1039.89,403.46l-7-11.45,10.51-18.3L1080.23,395a2.09,2.09,0,0,1,.76,2.85L1069.33,418a2.09,2.09,0,0,1-2.85.76Z"
        transform="translate(-135.63 -305.92)"
      />
      <path
        className="a"
        d="M1044.82,406.3l-3.46-2-3.28-1.92-3.29-1.93a27.6,27.6,0,0,1-.43-47.54l2.65-1.53,1-.58,28.53-16.48a2.1,2.1,0,0,1,2.86.77l11.66,20.19a2.08,2.08,0,0,1-.76,2.85L1045,378.52A16,16,0,0,0,1044.82,406.3Z"
        transform="translate(-135.63 -305.92)"
      />
    </svg>
  );
};

export default getingeLogo;
