export const entity = '[Blob download]';

export const FETCH_BLOB = `${entity} Fetch`;
export const FETCH_BLOB_PROGRESS = `${entity} Progress`;
export const FETCH_BLOB_ERROR = `${entity} Error`;
export const FETCH_BLOB_SUCCESS = `${entity} Success`;
export const CLEAR_BLOB = `${entity} Clear`;

/**
 * Trigger fetch of a blob
 */
export const fetchBlob = (url, filename) => ({
  type: FETCH_BLOB,
  payload: {
    url,
    filename
  }
});

export const fetchBlobSuccess = (data, url, filename) => ({
  type: FETCH_BLOB_SUCCESS,
  payload: {
    data,
    url,
    filename
  }
});

export const fetchBlobError = (data, url) => ({
  type: FETCH_BLOB_ERROR,
  payload: {
    data,
    url
  }
});

export const fetchBlobProgress = (progress, url) => ({
  type: FETCH_BLOB_PROGRESS,
  payload: {
    progress,
    url
  }
});

export const clearBlob = url => ({
  type: CLEAR_BLOB,
  payload: {
    url
  }
});
