import React, { useEffect, useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Link, withRouter } from 'react-router-dom';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import PropTypes from 'prop-types';
import { WithLoadingSpinner, WithSubscriptionInvitation } from '../../hoc';
import { FiltersContainer } from '..';

import { fetchDevices } from '../../redux/actions/devices';
import { pushPaginationHistory } from '../../redux/actions/paginationHistory';
import {
  uiConfig,
  routePaths,
  ContainerHeader,
  DeviceList,
  SubscriptionsList,
  CardTabbed,
  CardTab,
  CardSection,
  decodePaginationURI,
  Icon,
  PathJoin,
  ConnectedAccessories,
  subscriptionTypes,
  isRotaflowFamily
} from '../../components';
import get from 'lodash.get';
import {
  createDocument,
  INSTALLED_BASE_LIST,
  installedBaseListFileName
} from '../../utils/export';
import { useLocation } from 'react-router-dom';
import './DeviceOverview.scss';

const DeviceOverview = props => {
  const handleFetchCallback = (...fetchParams) => {
    props.pushPaginationHistory(
      fetchParams[0].page,
      fetchParams[0].pageSize,
      fetchParams[1]
    );
    props.fetchDevices(...fetchParams);
  };

  const initialPaginationSettings = {
    page: 0,
    pageSize: uiConfig.pageSizeOptions[0],
    sort: '-statusWrong, -lastLog, serialID'
  };
  const manualPaginationSettings = {
    fetchMethod: handleFetchCallback,
    paginationSettings: props.devicesPagination,
    sortingSettings: props.devicesSorting,
    customPageSizeOptions: uiConfig.pageSizeOptions
  };

  function TabNavigation(logType, pushHistory = true) {
    return useCallback(() => {
      if (pushHistory) {
        props.history.push(logType);
      }
    }, [logType, pushHistory]);
  }

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    props.fetchDevices(
      {
        page: initialPaginationSettings.page,
        pageSize: initialPaginationSettings.pageSize
      },
      props.devicesSorting || initialPaginationSettings.sort
    );
  }, [props.filters, props.authorization]);

  useEffect(() => {
    const urlPagination = decodePaginationURI(
      props.location.search,
      initialPaginationSettings
    );
    props.fetchDevices(
      {
        page: urlPagination.page,
        pageSize: urlPagination.pageSize
      },
      urlPagination.sort
    );
  }, [props.location]);
  /* eslint-enable react-hooks/exhaustive-deps */

  const deviceTypesFilterApplied = get(props.filters, 'deviceTypes').length > 0;
  const totalDeviceTypesFiltered = get(props.filters, 'deviceTypes').length;

  const rotaflowInFilteredDeviceTypes = get(
    props.filters,
    'deviceTypes'
  ).filter(t => isRotaflowFamily(t['key']));

  const isOnlyRotaFlowDeviceSelected =
    deviceTypesFilterApplied &&
    totalDeviceTypesFiltered === rotaflowInFilteredDeviceTypes.length;

  const exportInstalledBaseData = useCallback(() => {
    createDocument(
      INSTALLED_BASE_LIST,
      props.devices || [],
      props.filters,
      installedBaseListFileName,
      null,
      props.intl
    );
  }, [props.devices, props.filters, props.intl]);

  const getTabIndex = () => {
    const location = useLocation();
    let pathIndex = {
      '/installed-base/devices': 0,
      '/installed-base/accessories': 1,
      '/installed-base/subscriptions': 2
    };
    return pathIndex[location.pathname];
  };

  const [tabIndex, setTabIndex] = useState(getTabIndex());

  const hasStandardDevices = () => {
    return (
      props.devices &&
      props.devices.some(function(device) {
        if (device.subscription === subscriptionTypes.standard) {
          return true;
        }
        return false;
      })
    );
  };

  return (
    <>
      <ContainerHeader headerTitle={routePaths.DeviceOverview.label}>
        <FiltersContainer />
      </ContainerHeader>
      <div className="row">
        <div className="col-12 col-md-12">
          <CardTabbed dataCy="cardInstalledBase" currentTabIndex={tabIndex}>
            <CardTab
              title={
                <FormattedMessage
                  id="common.devices"
                  defaultMessage="Devices"
                />
              }
              customOnTabClick={TabNavigation(
                PathJoin([
                  routePaths.DeviceOverview.path,
                  routePaths.InstalledBaseDevice.path
                ])
              )}
            >
              <CardSection>
                <WithLoadingSpinner when="devices">
                  <DeviceList
                    devices={props.devices}
                    manualPaginationSettings={manualPaginationSettings}
                    currentPageExtra={
                      <Link onClick={exportInstalledBaseData} to="#">
                        <Icon name="download-excel" size="sm" />
                      </Link>
                    }
                  />
                </WithLoadingSpinner>
              </CardSection>

              {props.enableSubscriptions &&
                !props.userinfo.isInternalUser &&
                hasStandardDevices() && (
                  <div className="gtg-installed-base-subscribe-invitation">
                    <div className="gtg-vertical-spacing" />
                    <CardSection>
                      <WithSubscriptionInvitation
                        customTitleForInvitation={
                          'deviceInformation.subscriptionInvitation'
                        }
                        showSubTitle={false}
                        trigger={[
                          subscriptionTypes.advanced,
                          subscriptionTypes.premium
                        ]}
                      />
                    </CardSection>
                  </div>
                )}
            </CardTab>
            <CardTab
              title={
                <FormattedMessage
                  id="common.accessories"
                  defaultMessage="Connected accessories"
                />
              }
              customOnTabClick={TabNavigation(
                PathJoin([
                  routePaths.DeviceOverview.path,
                  routePaths.InstalledBaseAccessories.path
                ])
              )}
            >
              {props.enableSubscriptions && !isOnlyRotaFlowDeviceSelected && (
                <ConnectedAccessories />
              )}
            </CardTab>
            {props.enableSubscriptions && (
              <CardTab
                title={
                  <FormattedMessage
                    id="common.subscriptions"
                    defaultMessage="Subscriptions"
                  />
                }
                customOnTabClick={TabNavigation(
                  PathJoin([
                    routePaths.DeviceOverview.path,
                    routePaths.InstalledBaseSubscriptions.path
                  ])
                )}
              >
                <CardSection>
                  <WithLoadingSpinner when="devices">
                    <SubscriptionsList
                      devices={props.devices}
                      manualPaginationSettings={manualPaginationSettings}
                    />
                  </WithLoadingSpinner>
                </CardSection>
              </CardTab>
            )}
          </CardTabbed>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = state => ({
  devices: get(state, 'devices.devices.items'),
  devicesPagination: get(state, 'devices.devices.pagination'),
  devicesSorting: get(state, 'devices.devices.sorting'),
  filters: get(state, 'filters'),
  enableSubscriptions: get(state, 'config.enableSubscriptions', false),
  userinfo: get(state, 'authorization.userinfo'),
  authorization: state.authorization.jwt
});

const mapDispatchToProps = {
  fetchDevices,
  pushPaginationHistory
};

DeviceOverview.propTypes = {
  fetchDevices: PropTypes.func,
  pushPaginationHistory: PropTypes.func,
  intl: intlShape
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl,
  withRouter
)(DeviceOverview);
