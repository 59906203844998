import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Spinner } from '../..';

import './ProgressIcon.scss';

/**
 * A circular progress icon with option of startup spinner and success/error icons
 * @param status The current status of the action. Possible values: `'download', 'error', 'success', 'inprogress'`
 * @param progress A number from 0 to 100 indicating the current progress of the action
 * @param useStartupSpinner If `true`, it will display a Spinner on startup, then switch to the circular progress icon
 */
const ProgressIcon = ({ status, progress = 0, useStartupSpinner = false }) => {
  const progressPercentage = progress < 3 ? 3 : progress;

  // Certain actions take a lot of time at the start before progressing,
  // so we can display a spinner to indicate that something is still happening
  const shouldUseSpinner =
    useStartupSpinner && status === 'inprogress' && progressPercentage < 10;

  if (shouldUseSpinner) {
    return <Spinner />;
  }

  return (
    <i
      className={classnames(
        'icon',
        'md',
        'gtg-progress-icon',
        'progress-' + status
      )}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        id="Layer_1"
        x="0"
        y="0"
        enableBackground="new 0 0 34 34"
        version="1.1"
        viewBox="0 0 34 34"
        xmlSpace="preserve"
      >
        <g id="success">
          <circle
            id="circle"
            cx="17"
            cy="17"
            r="16"
            fill="none"
            stroke="#15264A"
            strokeWidth="2"
          ></circle>
          <path
            fill="#15264A"
            d="M26.5 11.5L25 10.1 14.1 21 8.9 15.8 7.4 17.2 14.1 23.8 14.1 23.8 14.1 23.8z"
            className="st0"
          ></path>
        </g>
        <g id="error">
          <circle
            id="circle"
            cx="17"
            cy="17"
            r="16"
            fill="none"
            stroke="#15264A"
            strokeWidth="2"
          ></circle>
          <path
            fill="#15264A"
            d="M24.1 11.3L22.7 9.9 16.9 15.7 11.2 9.9 9.8 11.3 15.6 17 9.8 22.8 11.2 24.1 16.9 18.4 22.7 24.1 24.1 22.8 18.3 17z"
            className="st0"
          ></path>
        </g>
        <g id="download">
          <circle
            id="circle"
            cx="17"
            cy="17"
            r="16"
            fill="none"
            stroke="#15264A"
            strokeWidth="2"
          ></circle>
          <path
            fill="#15264A"
            d="M25 15.3c-.4-.4-1-.4-1.4 0L17.9 21V7.9H16v13.3l-5.9-5.9c-.4-.4-1-.3-1.3 0-.4.4-.3 1 0 1.3l8.2 8.1 8-8.1c.4-.4.3-1 0-1.3z"
            className="st0"
          ></path>
        </g>
        <g id="progress">
          <circle
            cx="17"
            cy="17"
            r="16"
            fill="none"
            stroke="#15264A"
            strokeWidth="2"
            strokeDasharray={progressPercentage + ', 100'}
          ></circle>
        </g>
      </svg>
    </i>
  );
};

ProgressIcon.propTypes = {
  status: PropTypes.oneOf(['download', 'error', 'success', 'inprogress'])
    .isRequired,
  progress: PropTypes.number.isRequired,
  useStartupSpinner: PropTypes.bool
};

export default ProgressIcon;
