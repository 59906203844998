export const entity = '[API]';

export const API_ERROR = `API_ERROR`;
export const API_SUCCESS = `API_SUCCESS`;
export const API_REQUEST = `${entity} Request`;
export const API_REQUEST_STARTED = `${entity} Request started`;
export const API_REQUEST_FINISHED = `${entity} Request finished`;
export const API_REQUEST_QUEUE = `${entity} Request queue`;

export const apiRequestStarted = requestSignature => ({
  type: API_REQUEST_STARTED,
  payload: { requestSignature: requestSignature }
});

export const apiRequestFinished = requestSignature => ({
  type: API_REQUEST_FINISHED,
  payload: { requestSignature: requestSignature }
});

export const apiRequestQueue = request => ({
  type: API_REQUEST_QUEUE,
  payload: { request: request }
});

export const apiSuccess = (response, entity, method) => ({
  type: `${entity} ${method} ${API_SUCCESS}`,
  payload: {
    data: response,
    meta: { entity }
  }
});

export const apiError = (error, entity, method) => ({
  type: `${entity} ${method} ${API_ERROR}`,
  payload: {
    data: error,
    meta: { entity }
  }
});

/**
 *
 * @param {string} method Method (GET, POST, PATCH, DELETE)
 * @param {string} url URL
 * @param {json} body Body to attach to the request
 * @param {string} requestSignature Optional string that identifies a request (used for spinners etc)
 * @param {string} entity Entity for the request (used for identifying the sending entity)
 * @param {boolean} filter Optional boolean to indicate if request should be filtered
 */
export const apiRequest = ({
  method,
  url,
  body,
  requestSignature,
  entity,
  filter
}) => ({
  type: API_REQUEST,
  meta: {
    method,
    url,
    requestSignature,
    body,
    entity,
    filter
  }
});
