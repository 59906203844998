import React, { useEffect, useCallback, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { WithSubscriptionInvitation, WithLoadingSpinner } from '../../hoc';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { fetchDevicesOverview } from '../../redux/actions/devicesOverview';
import { putDevice } from '../../redux/actions/device';
import { fetchConnectedAccessories } from '../../redux/actions/connectedAccessories';
import { pushPaginationHistory } from '../../redux/actions/paginationHistory';
import DeviceInformationLocation from '../Device/DeviceInformationLocation';
import {
  CardSection,
  subscriptionTypes,
  uiConfig,
  DataTableFixedColumnHeader,
  StatusIcon,
  EmptyState,
  Formatted24hTime,
  Alert,
  routePaths
} from '..';
import get from 'lodash.get';
import './ConnectedAccessories.scss';

const ConnectedAccessories = ({
  fetchDevicesOverview,
  subscriptions,
  fetchConnectedAccessories,
  connectedAccessories,
  connectedAccessoriesPagination,
  connectedAccessoriesSorting,
  pushPaginationHistory,
  filters,
  putDevice,
  userinfo,
  history
}) => {
  const [stateLocation, setLocation] = useState('');
  const [stateDeviceId, setDeviceId] = useState('');

  const initialPaginationSettings = {
    page: 0,
    pageSize: uiConfig.pageSizeOptions[0],
    sort: '-status'
  };

  const handleFetchCallback = (...fetchParams) => {
    pushPaginationHistory(
      fetchParams[0].page,
      fetchParams[0].pageSize,
      fetchParams[1]
    );
    fetchConnectedAccessories(...fetchParams);
  };

  const handleLocationSubmit = (location, deviceId, page, pageSize) => {
    setLocation(location && location.trim());
    setDeviceId(deviceId);

    fetchConnectedAccessories(
      {
        page: page,
        pageSize: pageSize
      },
      initialPaginationSettings.sort
    );

    putDevice({
      id: deviceId,
      location: location,
      customerDeviceId: null
    });
  };

  const manualPaginationSettings = {
    fetchMethod: handleFetchCallback,
    paginationSettings: connectedAccessoriesPagination,
    sortingSettings:
      connectedAccessoriesSorting === null
        ? '-status'
        : connectedAccessoriesSorting,
    customPageSizeOptions: uiConfig.pageSizeOptions
  };

  useEffect(() => {
    fetchDevicesOverview();
    fetchConnectedAccessories(
      {
        page: initialPaginationSettings.page,
        pageSize: initialPaginationSettings.pageSize
      },
      initialPaginationSettings.sort
    );
  }, [
    fetchDevicesOverview,
    fetchConnectedAccessories,
    filters,
    initialPaginationSettings.page,
    initialPaginationSettings.pageSize,
    initialPaginationSettings.sort
  ]);

  const subscriptionExists = subscription => {
    if (subscriptions && subscriptions.servo) {
      return subscriptions.servo.some(item => {
        return item.level === subscription;
      });
    }
  };

  const handleRowClick = useCallback(
    (state, rowInfo) => {
      if (rowInfo && rowInfo.original && rowInfo.original.deviceID) {
        return {
          onClick: e => {
            if (
              e.target.nodeName === 'use' ||
              e.target.nodeName === 'svg' ||
              e.target.nodeName === 'SPAN' ||
              e.target.nodeName === 'INPUT'
            ) {
              e.stopPropagation();
            } else {
              history.push(
                `/${routePaths.DeviceOverview.path}/${rowInfo.original.deviceID}`
              );
            }
          }
        };
      } else {
        return {};
      }
    },
    [history]
  );

  let connectedAccessoriesTable = (
    <div className="gtg-connected-accessories">
      {!userinfo.isInternalUser &&
        subscriptionExists(subscriptionTypes.premium) &&
        (subscriptionExists(subscriptionTypes.advanced) ||
          subscriptionExists(subscriptionTypes.standard)) && (
          <div className="mb-3">
            <Alert
              level="warning"
              msg={
                <FormattedMessage
                  id="connectedAccessories.warningMessage"
                  defaultMessage="Connected accessories are only shown for your devices with Premium subscription."
                />
              }
            />
          </div>
        )}
      <WithLoadingSpinner when="connectedAccessories">
        {(connectedAccessories && connectedAccessories.length > 0 && (
          <DataTableFixedColumnHeader
            data={connectedAccessories}
            defaultPageSize={10}
            manualPaginationSettings={manualPaginationSettings}
            className="mb-3"
            noCustomScrollbars
            withOverflow
            getTrProps={handleRowClick}
            columns={[
              {
                Header: (
                  <FormattedMessage
                    id="column.status"
                    defaultMessage="Status"
                  />
                ),
                accessor: 'status',
                Cell: row => <StatusIcon value={row.value} />,
                width: 85,
                noOverflowTooltip: true
              },
              {
                Header: (
                  <FormattedMessage id="column.type" defaultMessage="Type" />
                ),
                accessor: 'type',
                headerClassName: 'text-left',
                className: 'text-left',
                width: 120
              },
              {
                Header: (
                  <FormattedMessage
                    id="column.serialNumber"
                    defaultMessage="Serial number"
                  />
                ),
                accessor: 'serialNumber',
                headerClassName: 'text-left',
                className: 'text-left'
              },
              {
                Header: (
                  <FormattedMessage
                    id="column.SWVersion"
                    defaultMessage="SW Version"
                  />
                ),
                accessor: 'softwareVersion',
                headerClassName: 'text-left',
                className: 'text-left',
                Cell: row =>
                  row.value || (
                    <FormattedMessage
                      id="common.notAvailableAbbr"
                      defaultMessage="N/A"
                    />
                  )
              },
              {
                Header: (
                  <FormattedMessage
                    id="column.lastSeenIn"
                    defaultMessage="Last seen in"
                  />
                ),
                accessor: 'lastSeenIn',
                headerClassName: 'text-left',
                className: 'text-left',
                Cell: row =>
                  row.value || (
                    <FormattedMessage
                      id="common.notAvailableAbbr"
                      defaultMessage="N/A"
                    />
                  )
              },
              {
                Header: (
                  <FormattedMessage
                    id="column.lastConnected"
                    defaultMessage="Last connected"
                  />
                ),
                accessor: 'lastLog',
                Cell: row =>
                  (row.value && (
                    <React.Fragment>
                      {row.value && (
                        <>
                          <FormattedDate value={row.value} />{' '}
                          <Formatted24hTime value={row.value} />
                        </>
                      )}
                    </React.Fragment>
                  )) || (
                    <FormattedMessage
                      id="common.notAvailableAbbr"
                      defaultMessage="N/A"
                    />
                  ),
                getProps: () => {
                  return {
                    style: {
                      whiteSpace: 'pre'
                    }
                  };
                }
              },
              {
                Header: (
                  <FormattedMessage
                    id="deviceInformation.location"
                    defaultMessage="Location"
                  />
                ),
                accessor: 'location',
                headerClassName: 'text-left',
                className: 'text-left',
                Cell: row => (
                  <div
                    className={
                      (stateDeviceId
                      ? stateDeviceId === row.original.deviceID
                        ? stateLocation !== ''
                          ? true
                          : false
                        : row.value
                      : row.value)
                        ? 'gtg-with-value'
                        : 'gtg-with-no-value'
                    }
                  >
                    <DeviceInformationLocation
                      onLocationSubmit={useCallback(
                        location =>
                          handleLocationSubmit(
                            location,
                            row.original.deviceID,
                            row.page,
                            row.pageSize
                          ),
                        [row.original.deviceID, row.page, row.pageSize]
                      )}
                      location={
                        stateDeviceId
                          ? stateDeviceId === row.original.deviceID
                            ? stateLocation
                            : row.value
                          : row.value
                      }
                    />
                  </div>
                )
              }
            ]}
          />
        )) || <EmptyState />}
      </WithLoadingSpinner>
    </div>
  );

  let cardContent = (
    <>
      <WithSubscriptionInvitation
        accessWidget={'connected accessories'}
        trigger={[subscriptionTypes.premium]}
        subscriptions={subscriptions && subscriptions.servo}
        customTitleForInvitation={
          'connectedAccessories.connectedAccessoriesTitle'
        }
        showSubTitle={false}
      >
        <CardSection>{connectedAccessoriesTable}</CardSection>
      </WithSubscriptionInvitation>

      {subscriptionExists(subscriptionTypes.premium) &&
        (subscriptionExists(subscriptionTypes.advanced) ||
          subscriptionExists(subscriptionTypes.standard)) && (
          <div className="gtg-connected-invitation-card">
            <WithSubscriptionInvitation
              accessWidget={'connected accessories'}
              trigger={[subscriptionTypes.premium]}
              customTitleForInvitation={
                'connectedAccessories.connectedAccessoriesTitle'
              }
              showSubTitle={false}
            ></WithSubscriptionInvitation>
          </div>
        )}
    </>
  );

  return <>{cardContent}</>;
};

const mapStateToProps = state => ({
  subscriptions:
    state.devicesStatusOverview.devicesStatusOverview.subscriptions,
  connectedAccessories: get(
    state,
    'connectedAccessories.connectedAccessories.items'
  ),
  connectedAccessoriesPagination: get(
    state,
    'connectedAccessories.connectedAccessories.pagination'
  ),
  connectedAccessoriesSorting: get(
    state,
    'connectedAccessories.connectedAccessories.sorting'
  ),
  filters: get(state, 'filters'),
  userinfo: get(state, 'authorization.userinfo')
});

const mapDispatchToProps = {
  fetchDevicesOverview,
  fetchConnectedAccessories,
  pushPaginationHistory,
  putDevice
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)(ConnectedAccessories);
