import React, { useState } from 'react';
import classnames from 'classnames';
import { uuidv4 } from '../..';

import styles from './Card.module.scss';

const Card = props => {
  const { title, CardHeaderRight, CardHeaderCenter, dataCy, className } = props;
  const cardChildren = (Array.isArray(props.children)
    ? props.children
    : [props.children]
  ).filter(child => child);
  const hasChildren = cardChildren && cardChildren.length > 0;
  const [cardSectionsCollapsed, setCardSectionsCollapsed] = useState(
    (hasChildren &&
      cardChildren.map(child => (child && child.props.collapsed) || false)) ||
      null
  );
  // We need to keep track of the keys we assign to children, so that they are not rerendered everytime one child is toggled
  const [childKeys, setChildKeys] = useState(
    (hasChildren && cardChildren.map(() => uuidv4())) || []
  );

  const cardSectionCollapseToggle = cardSectionIndex => {
    const newCardSectionsCollapse = cardSectionsCollapsed.map((section, i) => {
      return cardSectionIndex === i ? !section : section;
    });
    setCardSectionsCollapsed(newCardSectionsCollapse);

    // Assign a new key to the toggled child, so that it gets rerendered
    const newChildKeys = childKeys.map((key, i) => {
      return cardSectionIndex === i ? uuidv4() : key;
    });
    setChildKeys(newChildKeys);
  };

  return (
    <div
      className={classnames(styles.gtgCard, 'card', className)}
      data-cy={dataCy}
    >
      {(CardHeaderRight || CardHeaderCenter || title) && (
        <header
          className={`${styles.gtgCardWithHeader} ${styles.gtgCardHeaderGrid}`}
        >
          {title && <h5>{title}</h5>}
          <div>{CardHeaderCenter && <CardHeaderCenter />}</div>
          <div>{CardHeaderRight && <CardHeaderRight />}</div>
        </header>
      )}
      {(cardChildren.length > 0 &&
        React.Children.map(cardChildren, (child, index) => {
          const childrenProps = {
            cardSectionIndex: index,
            collapsed: cardSectionsCollapsed[index],
            key: childKeys[index],
            cardSectionCollapseToggle: cardSectionCollapseToggle
          };

          return React.cloneElement(child, childrenProps);
        })) ||
        props.children}
    </div>
  );
};

export default Card;
