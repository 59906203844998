import React, { useState, useCallback, useEffect } from 'react';
import { connect, useSelector, shallowEqual, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { fetchDevicesBySerial } from '../../redux/actions/admin';
import { setFilters } from '../../redux/actions/filters';
import {
  AdminDeviceList,
  Spinner,
  routePaths,
  useInfiniteScroll
} from '../../components';
import { AdminFiltersContainer } from '..';
import styles from './GlobalSearch.module.scss';
import { compose } from 'redux';
import get from 'lodash.get';

const cnType = 'connectivity node';

function connectivityNodesMapper(data) {
  return data.map(
    ({
      id,
      type,
      serialNumber,
      account,
      systemSoftwareVersion,
      lastConnected
    }) => {
      return {
        id,
        deviceType: type,
        serialNumber,
        account: {
          name: account?.name
        },
        systemSoftwareVersion,
        lastLog: lastConnected,
        type: cnType
      };
    }
  );
}

function adminFiltersMapper(filters) {
  return {
    serialNumber: filters.adminSerialNumber,
    softwareVersion: get(filters.adminSoftwareVersion, '[0].id'),
    accountIDs: filters.adminAccounts,
    deviceTypes: filters.adminDeviceTypes
  };
}

const GlobalSearch = ({ adminData, filters, history }) => {
  const dispatch = useDispatch();
  const [tableData, setTableData] = useState([]);
  const [isComponentLoading, setIsComponentLoading] = useState(true);

  const handleFilterChange = useCallback(
    value => {
      setTableData([]);
      const changedFilters = { ...filters, ...value };
      dispatch(setFilters(changedFilters));
      dispatch(
        fetchDevicesBySerial(adminFiltersMapper(changedFilters), '', '')
      );
      setIsComponentLoading(false);
    },
    [filters, dispatch]
  );

  const nextDevicesCursor = adminData?.devices?.response_metadata?.next_cursor;
  const nextConnectivityNodesCursor =
    adminData?.connectivityNodes?.response_metadata?.next_cursor;

  // Infinite scroll
  const { triggerElementRef, setHasMore, setIsLoading } = useInfiniteScroll(
    () => {
      if (!isComponentLoading) {
        dispatch(
          fetchDevicesBySerial(
            adminFiltersMapper(filters),
            nextDevicesCursor,
            nextConnectivityNodesCursor
          )
        );
      }
    }
  );

  useEffect(() => {
    setHasMore(!!nextDevicesCursor || !!nextConnectivityNodesCursor);
  }, [nextDevicesCursor, nextConnectivityNodesCursor, setHasMore]);

  useEffect(() => {
    handleFilterChange();
  }, []);

  const isLoading = useSelector(
    state =>
      state.api.pendingRequests.some(
        request => request.split('_|_')[0] === 'adminSearchBySerial'
      ),
    shallowEqual
  );

  useEffect(() => {
    setIsLoading(isLoading);
  }, [isLoading, setIsLoading]);
  // Infinite scroll end

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    let adminDevices = [];
    if (((adminData?.devices && adminData?.devices?.items) || []).length > 0) {
      adminDevices = [...adminData.devices.items];
    }

    if (
      (
        (adminData?.connectivityNodes && adminData?.connectivityNodes?.items) ||
        []
      ).length > 0
    ) {
      adminDevices = [
        ...adminDevices,
        ...connectivityNodesMapper(adminData.connectivityNodes.items)
      ];
    }

    setTableData([...tableData, ...adminDevices]);
  }, [adminData]);
  /* eslint-enable react-hooks/exhaustive-deps */

  const handleRowClick = useCallback(
    (state, rowInfo) => {
      if (rowInfo && rowInfo.original && rowInfo.original.id) {
        history.push(
          `/${routePaths.Admin.path}/${
            rowInfo.original.type === cnType
              ? routePaths.AdminCN.path
              : routePaths.AdminDevices.path
          }/${rowInfo.original.id}`
        );
      }
    },
    [history]
  );

  return (
    <div className={styles.globalSearch}>
      <AdminFiltersContainer setFilter={handleFilterChange} />
      <div className="row">
        <div className="col-md-12">
          <AdminDeviceList
            devices={tableData}
            triggerElementRef={triggerElementRef}
            selectRow={handleRowClick}
          />
          {isLoading && (
            <div>
              <Spinner />
              Loading more results
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  adminData: state.admin.devicesBySerial,
  filters: state.filters
});

const ConnectedGlobalSearch = compose(
  connect(mapStateToProps),
  withRouter
)(GlobalSearch);
ConnectedGlobalSearch.WrappedComponent = GlobalSearch;

ConnectedGlobalSearch.propTypes = {
  /* eslint-disable react/forbid-prop-types */
  admin: PropTypes.arrayOf(PropTypes.object)
  /* eslint-enable react/forbid-prop-types */
};

export default ConnectedGlobalSearch;
