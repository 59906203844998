export const entity = '[Usage statistics]';
export const FETCH_USAGE_STATISTICS = `${entity} Fetch`;
export const SET_USAGE_STATISTICS = `${entity} Set`;

/**
 * Trigger fetch of usage statistics
 */
export const fetchUsageStatistics = (timeSpan, count, deviceIds) => ({
  type: FETCH_USAGE_STATISTICS,
  payload: {
    count,
    deviceIds,
    timeSpan
  }
});

/**
 * Set data as usage statistics for servo device
 * @param {array} data New usage statistics
 */
export const setUsageStatistics = data => ({
  type: SET_USAGE_STATISTICS,
  payload: data
});
