import { SET_CASES } from '../actions/cases';

const initState = {};

export const casesReducer = (state = initState, action) => {
  const { payload, type } = action;

  switch (type) {
    case SET_CASES:
      return {
        ...state,
        cases: payload
      };
    default:
      return state;
  }
};
