export const entity = '[Service orders]';
export const FETCH_SERVICE_ORDERS = `${entity} Fetch`;
export const SET_SERVICE_ORDERS = `${entity} Set`;

/**
 * Trigger fetch of service orders
 */
export const fetchServiceOrders = (
  deviceId,
  paginationSettings,
  sortingSettings
) => ({
  type: FETCH_SERVICE_ORDERS,
  pagination: paginationSettings,
  sorting: sortingSettings,
  payload: {
    deviceId: deviceId
  }
});

/**
 * Set data as new service orders
 * @param {array} data New service orders
 */
export const setServiceOrders = data => ({
  type: SET_SERVICE_ORDERS,
  payload: data
});
