import {
  entity,
  fetchFilterAccounts,
  setFilterAccounts,
  fetchFilterDeviceTypes,
  setFilterDeviceTypes,
  setSearchDevicesBySerial,
  setUploadCertificationsResult,
  setDeviceSystemLogs,
  setCNSystemLogs,
  setUploadSoftwareResult,
  FETCH_FILTER_ACCOUNTS,
  FETCH_FILTER_DEVICE_TYPES,
  FETCH_DEVICES_BY_SERIAL,
  UPLOAD_CERTIFICATIONS,
  FETCH_DEVICE_SYSTEM_LOGS,
  FETCH_CN_SYSTEM_LOGS,
  UPLOAD_SOFTWARE
} from '../actions/admin';
import { apiRequest, API_SUCCESS, API_ERROR } from '../actions/api';
import { API_URLS } from './urls';
import {
  transformAccounts,
  transformDeviceTypes,
  sortByName
} from '../../utils/transform';
import { filterMapper, filterValueMapper } from '../../components';

const SEARCH_URL = API_URLS.AdminSearchBySerial;
const UPLOAD_CERTIFICATIONS_URL = API_URLS.AdminUploadCertifications;
const DEVICE_LOGS_SEARCH_URL = API_URLS.AdminDeviceLogsSearch;
const CN_LOGS_SEARCH_URL = API_URLS.AdminCNLogsSearch;
const UPLOAD_SOFTWARE_URL = API_URLS.AdminUploadSoftware;

const URL = API_URLS.Api;
const DEVICE_TYPES_URL = API_URLS.DeviceTypes;

const accountsEntity = `${entity} fetch accounts`;
const deviceTypesEntity = `${entity} fetch device types`;
const searchEntity = `${entity} search devices by serial`;
const uploadEntity = `${entity} upload certifications`;
const deviceLogsEntity = `${entity} fetch device logs by uuid`;
const cNLogsEntity = `${entity} fetch connectivity node logs by uuid`;
const uploadSoftwareEntity = `${entity} upload software`;

export const signatureAdminAccountFilter = 'adminFilterAccounts';
export const signatureAdminDeviceTypeFilter = 'adminFilterDeviceTypes';

export const adminMdl = ({ dispatch }) => next => action => {
  next(action);

  const { type } = action;
  switch (type) {
    case FETCH_FILTER_ACCOUNTS:
      const ACCOUNTS_QUERY_PARAMS = action.searchStr
        ? `?search=${action.searchStr}`
        : '';
      dispatch(
        apiRequest({
          method: 'GET',
          url: `${URL.url}/accounts${ACCOUNTS_QUERY_PARAMS}`,
          requestSignature: signatureAdminAccountFilter,
          entity: accountsEntity
        })
      );
      break;
    case FETCH_FILTER_DEVICE_TYPES:
      dispatch(
        apiRequest({
          method: 'GET',
          url: `${URL.url}/admin/device-types`,
          requestSignature: signatureAdminDeviceTypeFilter,
          entity: deviceTypesEntity
        })
      );
      break;
    case FETCH_DEVICES_BY_SERIAL:
      dispatch(setSearchDevicesBySerial('Loading...'));
      const {
        serialNumber,
        softwareVersion,
        accountIDs,
        deviceTypes,
        devicesCursor,
        connectivityNodesCursor
      } = action;
      let url = SEARCH_URL.url;

      if (serialNumber && serialNumber != '') {
        let trimSerial = parseInt(serialNumber, 10);
        url += filterValueMapper(
          url,
          'serialNumber',
          encodeURIComponent(trimSerial)
        );
      }

      url += filterValueMapper(
        url,
        'softwareVersion',
        encodeURIComponent(softwareVersion)
      );

      url += filterMapper(url, 'account_ids', accountIDs);
      url += filterMapper(url, 'deviceTypes', deviceTypes);

      url += filterValueMapper(
        url,
        'devicesCursor',
        encodeURIComponent(devicesCursor)
      );

      url += filterValueMapper(
        url,
        'connectivityNodesCursor',
        encodeURIComponent(connectivityNodesCursor)
      );

      url += filterValueMapper(url, 'limit', encodeURIComponent(limit));

      dispatch(
        apiRequest({
          method: 'GET',
          url: url,
          requestSignature: 'adminSearchBySerial',
          entity: searchEntity
        })
      );
      break;
    case `${accountsEntity} GET ${API_SUCCESS}`:
      dispatch(
        setFilterAccounts(
          action.payload.data &&
            transformAccounts(sortByName(action.payload.data.items))
        ) || []
      );
      break;
    case `${deviceTypesEntity} GET ${API_SUCCESS}`:
      dispatch(
        setFilterDeviceTypes(
          action.payload.data && transformDeviceTypes(action.payload.data.items)
        ) || []
      );
      break;
    case `${searchEntity} GET ${API_SUCCESS}`:
      dispatch(setSearchDevicesBySerial(action.payload.data));
      break;
    case `${searchEntity} GET ${API_ERROR}`:
      dispatch(setSearchDevicesBySerial('Error'));
      break;
    case UPLOAD_CERTIFICATIONS:
      dispatch(setUploadCertificationsResult(null));
      dispatch(
        apiRequest({
          method: 'POST',
          url: `${UPLOAD_CERTIFICATIONS_URL.url}`,
          body: action.payload,
          requestSignature: 'adminUploadConfigurations',
          entity: uploadEntity
        })
      );
      break;
    case `${uploadEntity} POST ${API_SUCCESS}`:
      dispatch(setUploadCertificationsResult(true));
      break;
    case `${uploadEntity} POST ${API_ERROR}`:
      dispatch(setUploadCertificationsResult(false));
      break;
    case FETCH_DEVICE_SYSTEM_LOGS:
      dispatch(setDeviceSystemLogs('Loading...'));
      const { uuid, cursor, limit } = action;

      dispatch(
        apiRequest({
          method: 'GET',
          url:
            `${DEVICE_LOGS_SEARCH_URL.url}/${encodeURIComponent(
              uuid
            )}/system-logs` + ((cursor && '?cursor=' + cursor) || ''),
          requestSignature: 'adminDeviceLogs',
          entity: deviceLogsEntity
        })
      );
      break;
    case `${deviceLogsEntity} GET ${API_SUCCESS}`:
      dispatch(setDeviceSystemLogs(action.payload.data));
      break;
    case `${deviceLogsEntity} GET ${API_ERROR}`:
      dispatch(setDeviceSystemLogs('Error'));
      break;
    case FETCH_CN_SYSTEM_LOGS:
      dispatch(setCNSystemLogs('Loading...'));
      const { uuid: cnUuid, cursor: cnCursor, limit: cnLimit } = action;

      dispatch(
        apiRequest({
          method: 'GET',
          url:
            `${CN_LOGS_SEARCH_URL.url}/${encodeURIComponent(
              cnUuid
            )}/system-logs` + ((cnCursor && '?cursor=' + cnCursor) || ''),
          requestSignature: 'adminCNLogs',
          entity: cNLogsEntity
        })
      );
      break;
    case `${cNLogsEntity} GET ${API_SUCCESS}`:
      dispatch(setCNSystemLogs(action.payload.data));
      break;
    case `${cNLogsEntity} GET ${API_ERROR}`:
      dispatch(setCNSystemLogs('Error'));
      break;
    case UPLOAD_SOFTWARE:
      dispatch(setUploadSoftwareResult(null));
      dispatch(
        apiRequest({
          method: 'POST',
          url: `${UPLOAD_SOFTWARE_URL.url}?type=${action.cnType}`,
          body: action.payload,
          requestSignature: 'adminUploadSoftware',
          entity: uploadSoftwareEntity
        })
      );
      break;
    case `${uploadSoftwareEntity} POST ${API_SUCCESS}`:
      dispatch(setUploadSoftwareResult(true));
      break;
    case `${uploadSoftwareEntity} POST ${API_ERROR}`:
      dispatch(setUploadSoftwareResult(false));
      break;
    // no default
  }
};
