import { useRef, useCallback, useState } from 'react';

export function useInfiniteScroll(triggerCb) {
  const observer = useRef();
  const [hasMore, setHasMore] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const triggerElementRef = useCallback(
    node => {
      if (isLoading) return;

      if (observer.current) observer.current?.disconnect();
      observer.current = new IntersectionObserver(entries => {
        if (!triggerCb) {
          return;
        }

        if (entries[0].isIntersecting && hasMore) {
          triggerCb();
        }
      });

      if (node) observer.current.observe(node);
    },
    [hasMore, isLoading, triggerCb]
  );

  return { triggerElementRef, hasMore, setHasMore, isLoading, setIsLoading };
}
