import { PUSH_PAGINATION_HISTORY } from '../actions/paginationHistory';
import { createBrowserHistory } from 'history';
import { encodePaginationURI } from '../../components';

export const paginationHistoryReducer = (state = null, action) => {
  const { payload, type } = action;
  if (type === PUSH_PAGINATION_HISTORY) {
    const history = createBrowserHistory();
    history.push(
      history.location.pathname +
        encodePaginationURI(payload.page, payload.pageSize, payload.sort)
    );
  }
  return state;
};
