import { formatValueUnit } from '..';

/**
 * Calculate remaining cassette membrane capacity
 */

function CassetteMembraneRemainingCapacity(strokes, maxStrokes = 10000000) {
  const percentage = ((maxStrokes - strokes) / maxStrokes) * 100;
  return formatValueUnit(
    (percentage > 0 && Math.round(percentage * 100) / 100) || 0,
    '%',
    'capacity'
  );
}

export default CassetteMembraneRemainingCapacity;
