class GA4 {
  constructor() {
    this._scriptID = 'aSJDasdhaSAKDO134aSDajsda34a';
    window.dataLayer = window.dataLayer || [];
  }

  gtag() {
    window.dataLayer.push(arguments);
  }

  initialize(trackingCode) {
    this.loadGAScript(trackingCode);
  }

  loadGAScript(trackingCode) {
    // If a script with this ID has already been defined, remove it
    const prevScript = document.getElementById(this._scriptID);
    if (prevScript) {
      prevScript.remove();
    }

    // Create a script element that will load the GA tracker
    const script = document.createElement('script');
    script.setAttribute('id', this._scriptID);
    script.setAttribute('async', '');
    script.setAttribute(
      'src',
      `https://www.googletagmanager.com/gtag/js?id=${trackingCode}`
    );
    script.onload = () => {
      this.gtag('js', new Date());
      this.gtag('config', trackingCode, { debug_mode: false });

      console.log('GA loaded');
    };

    const head = document.getElementsByTagName('head')[0];
    head.appendChild(script);
  }

  pageView(path, location) {
    this.gtag('event', 'page_view', {
      page_path: path,
      page_location: location || window.location,
      page_title: this.friendlyTitle(path) || window.title
    });
  }

  friendlyTitle(path) {
    const uuidMatcher =
      '[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}';

    const matchers = {
      'Detailed device overview - tests': new RegExp(
        `installed-base/${uuidMatcher}/preuse-check/?$`,
        'i'
      ),
      'Detailed device overview - logs': new RegExp(
        `installed-base/${uuidMatcher}/logs/?`, // not reporting log type so no $
        'i'
      ),
      'Detailed device overview - Service orders': new RegExp(
        `installed-base/${uuidMatcher}/service-orders/?$`,
        'i'
      ),
      'Detailed device overview - Cases': new RegExp(
        `installed-base/${uuidMatcher}/cases/?$`,
        'i'
      ),
      'Device proactive watch details': new RegExp(
        `installed-base/${uuidMatcher}/preuse-check/${uuidMatcher}\\?proactive-watch$`,
        'i'
      ),
      'Device test details': new RegExp(
        `installed-base/${uuidMatcher}/preuse-check/${uuidMatcher}/?$`,
        'i'
      ),
      'Detailed device overview': new RegExp(
        `installed-base/${uuidMatcher}/?$`,
        'i'
      ),
      'Installed base': /installed-base\/?$/i,
      'Usage Statistics': /usage-statistics\/?$/i,
      'Service orders': /service-orders\/?$/i,
      'Usage statistics': /usage-statistics\/?$/i,
      'Running hours': /running-hours\/?$/i,
      'Preventive maintenance': /preventive-maintenance\/?$/i,
      Dashboard: /^\/$/
    };

    return Object.keys(matchers).find(k => {
      if (matchers[k].test(path)) {
        return k;
      }
    });
  }
}

const instance = new GA4();
Object.freeze(instance);

export default instance;
