import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Icon, KebabToWords, KebabToPascalCase } from '../../../components';

import './DeviceLogDownload.scss';
import DeviceLogDownloadModal from './DeviceLogDownloadModal/DeviceLogDownloadModal';
import { useDetectClickOutsideRef } from '../../../hooks';

export const DeviceLogDownload = ({ deviceId, logType }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const showDropdown = useCallback(() => setDropdownOpen(true), [
    setDropdownOpen
  ]);
  const hideDropdown = useCallback(() => setDropdownOpen(false), [
    setDropdownOpen
  ]);
  const { ref } = useDetectClickOutsideRef(hideDropdown);

  const [downloadLogType, setDownloadLogType] = useState(null);

  const downloadSingleLog = useCallback(() => {
    setDownloadLogType(logType);
    hideDropdown();
  }, [setDownloadLogType, logType, hideDropdown]);

  const downloadAllLogs = useCallback(() => {
    setDownloadLogType('all');
    hideDropdown();
  }, [setDownloadLogType, hideDropdown]);

  const clearDownloadLogType = useCallback(() => {
    setDownloadLogType(null);
  }, [setDownloadLogType]);

  const DownloadButtonWithIcon = ({ id, defaultMessage, onClick }) => {
    return (
      <div
        className={
          dropdownOpen
            ? 'gtg-download-button-with-icon gtg-download-button-active'
            : 'gtg-download-button-with-icon'
        }
        onClick={onClick}
      >
        <Icon name="download" size="sm" />
        <FormattedMessage id={id} defaultMessage={defaultMessage} />
      </div>
    );
  };

  const DownloadButton = ({ id, defaultMessage, onClick }) => (
    <span className="gtg-download-button" onClick={onClick}>
      {'  '}
      <FormattedMessage id={id} defaultMessage={defaultMessage} />
    </span>
  );

  return (
    <div ref={ref}>
      <DownloadButtonWithIcon
        id="common.download"
        defaultMessage="Download"
        onClick={showDropdown}
      />
      {dropdownOpen && (
        <div className="gtg-download-dropdown">
          {logType && (
            <div
              className="gtg-download-dropdown-item"
              onClick={downloadSingleLog}
            >
              <DownloadButton
                id={`logs.download${KebabToPascalCase(logType)}`}
                defaultMessage={`Download ${KebabToWords(logType, true)} Log`}
              />
            </div>
          )}
          <div className="gtg-download-dropdown-item" onClick={downloadAllLogs}>
            <DownloadButton
              id="logs.downloadAllLogs"
              defaultMessage="Download All Logs"
            />
          </div>
        </div>
      )}
      {downloadLogType && (
        <DeviceLogDownloadModal
          deviceId={deviceId}
          logType={downloadLogType}
          onDownloadComplete={clearDownloadLogType}
        />
      )}
    </div>
  );
};

DeviceLogDownload.propTypes = {
  deviceId: PropTypes.string.isRequired,
  logType: PropTypes.oneOf([
    'config',
    'installation',
    'internal',
    'service',
    'technical',
    'test',
    'trends',
    'events',
    'system-checkout',
    'all-events'
  ])
};

export default DeviceLogDownload;
