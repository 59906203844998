// Add column headers and define column keys and widths
export const totalGasConsumptionColumns = [
  { header: 'Hospital', key: 'hospital', width: 40 },
  { header: 'From', key: 'from', width: 15 },
  { header: 'To', key: 'to', width: 15 },
  { header: 'Agent', key: 'agent', width: 40 },
  { header: 'Total gas consumption', key: 'total', width: 10 },
  { header: 'Difference from previous period', key: 'difference', width: 10 },
  { header: 'Department filter selection', key: 'department', width: 40 },
  { header: 'Device filter selection', key: 'device', width: 40 }
];

// Define filename
export const totalGasConsumptionFileName =
  'Usage_statistics_overview_Total_gas_consumption.xlsx';
