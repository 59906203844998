import { SET_USAGE_STATISTICS_DETAILED } from '../actions/usageStatisticsDetailed';

const initState = {
  usageStatisticsDetailed: null
};

export const usageStatisticsDetailedReducer = (state = initState, action) => {
  const { payload, type } = action;

  switch (type) {
    case SET_USAGE_STATISTICS_DETAILED:
      return {
        ...state,
        usageStatisticsDetailed: payload.payloadData,
        usageStatisticsDetailedY2y: payload.y2yData
      };
    default:
      return state;
  }
};
