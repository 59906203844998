import { SET_LOGS, FETCH_LOGS } from '../actions/logs';

export function logsReducer(
  state = {
    logs: {}
  },
  action
) {
  switch (action.type) {
    case FETCH_LOGS:
      const fetchLogTypeLogsObj = {};
      fetchLogTypeLogsObj[action.logType] = null;
      return {
        ...state,
        ...{
          logs: {
            ...state.logs,
            ...fetchLogTypeLogsObj
          }
        }
      };
    case SET_LOGS:
      const setLogTypeLogsObj = {};
      setLogTypeLogsObj[action.logType] = action.payload;
      return {
        ...state,
        ...{
          logs: {
            ...state.logs,
            ...setLogTypeLogsObj
          }
        }
      };

    default:
      return state;
  }
}
