import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { injectIntl, intlShape } from 'react-intl';
import get from 'lodash.get';
import PropTypes from 'prop-types';

import { Icon, UserDropdown } from '../..';
import styles from './Topbar.module.scss';

const Topbar = props => {
  const { locale, user, onLogout, profile, isInternalUser } = props;

  function MapLocale(locale) {
    switch (locale) {
      case 'fr':
        return 'Français';
      default:
        return 'International (English)';
    }
  }

  const topBarLinks = (
    <React.Fragment>
      <li>
        <a
          href="https://www.getinge.com"
          target="_blank"
          className=""
          rel="noopener noreferrer"
        >
          Getinge.com
        </a>
      </li>
      <li>
        <a
          href="https://www.getinge.com"
          target="_blank"
          className="disabled"
          rel="noopener noreferrer"
        >
          Getinge training
        </a>
      </li>
      <li>
        <a
          href={`https://getingeonline.com/Account/LogOn?usertype=${
            isInternalUser ? 'employee' : 'customer'
          }`}
          target="_blank"
          rel="noopener noreferrer"
        >
          Select area
        </a>
      </li>
    </React.Fragment>
  );

  return (
    <nav
      className={styles.gtgTopbar + ' navbar navbar-dark'}
      data-cy="topbarNav"
    >
      <ul className="topbar-nav">{topBarLinks}</ul>
      {user && (
        <UserDropdown
          user={user}
          onLogout={onLogout}
          isAdmin={get(profile, 'adminRole', null) !== null}
        />
      )}
      <span>
        <Icon name="locale" size="sm" /> {MapLocale(locale)}
      </span>
    </nav>
  );
};

const mapStateToProps = state => ({
  locale: state.ui.locale,
  profile: state.profile.profile,
  isInternalUser: get(state, 'authorization.userinfo.isInternalUser')
});

Topbar.propTypes = {
  intl: intlShape,
  user: PropTypes.string,
  onLogout: PropTypes.func
};

export default compose(connect(mapStateToProps), injectIntl)(Topbar);
