import React from 'react';
import { List, ListItem } from '../../components';
import propTypes from 'prop-types';
import { FormattedMessage, FormattedDate } from 'react-intl';

const DeviceSlotInformation = ({ slots }) => {
  const { batteries, ...modules } = slots;

  const isAvailable = str => {
    return str && str.toLowerCase() !== 'not available';
  };

  const mapModuleName = moduleName => {
    let mappedModuleName = moduleName;
    switch (moduleName) {
      case 'yModule':
        mappedModuleName = 'Y module';
        break;
      case 'co2Module':
        mappedModuleName = 'CO2 module';
        break;
      case 'ediModule':
        mappedModuleName = 'Edi module';
        break;
      // no default
    }
    return mappedModuleName;
  };

  const mappedSlots = [
    ...(batteries || []).map((battery, i) => {
      return {
        ...battery,
        name: (
          <>
            <FormattedMessage
              id="deviceStatus.battery"
              defaultMessage="Battery"
            />
          </>
        ),
        key: 'battery' + i
      };
    }),
    ...Object.keys(modules).map(module => {
      return {
        ...modules[module],
        name: (
          <>
            {mapModuleName(module)}
            {modules[module].serialID &&
              isAvailable(modules[module].serialID) &&
              ' ' + modules[module].serialID}
          </>
        ),
        key: module
      };
    })
  ];

  return (
    <div className="row">
      {mappedSlots.map((slot, i) => {
        return (
          <div className="col-12 col-md-6 mb-4" key={slot.key}>
            <div className="mb-3 text-primary gtg-text-sm">
              <strong>
                <FormattedMessage
                  id="deviceStatus.slot"
                  defaultMessage="Slot"
                />
                :
              </strong>
              <span className="ml-4">{slot.name}</span>
            </div>
            <List>
              <ListItem>
                <label style={{ width: 150 }}>
                  <strong>
                    <FormattedMessage
                      id="deviceStatus.serialNumber"
                      defaultMessage="Serial number"
                    />
                  </strong>
                </label>
                <span className="col">
                  {((!slot.serialID || !isAvailable(slot.serialID)) && (
                    <FormattedMessage
                      id="common.notAvailableAbbr"
                      defaultMessage="N/A"
                    />
                  )) ||
                    slot.serialID}
                </span>
              </ListItem>
              {slot.softwareVersion && (
                <ListItem>
                  <label style={{ width: 150 }}>
                    <strong>
                      <FormattedMessage
                        id="deviceStatus.softwareVersion"
                        defaultMessage="Software version"
                      />
                    </strong>
                  </label>
                  <span className="col">
                    {(!isAvailable(slot.softwareVersion) && (
                      <FormattedMessage
                        id="common.notAvailableAbbr"
                        defaultMessage="N/A"
                      />
                    )) ||
                      slot.softwareVersion}
                  </span>
                </ListItem>
              )}
              {slot.manufacturingDate && (
                <ListItem>
                  <label style={{ width: 150 }}>
                    <strong>
                      <FormattedMessage
                        id="deviceStatus.manufacturingDate"
                        defaultMessage="Manufacturing date"
                      />
                    </strong>
                  </label>
                  <span className="col">
                    {(!isAvailable(slot.manufacturingDate) && (
                      <FormattedMessage
                        id="common.notAvailableAbbr"
                        defaultMessage="N/A"
                      />
                    )) || <FormattedDate value={slot.manufacturingDate} />}
                  </span>
                </ListItem>
              )}
            </List>
          </div>
        );
      })}
    </div>
  );
};

DeviceSlotInformation.propTypes = {
  slots: propTypes.shape({
    batteries: propTypes.arrayOf(
      propTypes.shape({
        manufacturingDate: propTypes.string,
        serialID: propTypes.string
      })
    ),
    co2Module: propTypes.shape({
      manufacturingDate: propTypes.string,
      serialID: propTypes.string
    }),
    ediModule: propTypes.shape({
      manufacturingDate: propTypes.string,
      serialID: propTypes.string
    }),
    yModule: propTypes.shape({
      manufacturingDate: propTypes.string,
      serialID: propTypes.string
    })
  })
};

export default DeviceSlotInformation;
