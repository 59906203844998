import React from 'react';
import { FormattedMessage } from 'react-intl';

const routePaths = {
  Dashboard: {
    path: '',
    label: <FormattedMessage id="common.dashboard" defaultMessage="Dashboard" />
  },
  DeviceDashboard: {
    path: '',
    label: <FormattedMessage id="common.overview" defaultMessage="Overview" />
  },
  DeviceOverview: {
    path: 'installed-base',
    label: (
      <FormattedMessage
        id="common.installedBase"
        defaultMessage="Installed base"
      />
    )
  },
  InstalledBaseDevice: {
    path: 'devices',
    label: <FormattedMessage id="common.devices" defaultMessage="Devices" />
  },
  InstalledBaseAccessories: {
    path: 'accessories',
    label: (
      <FormattedMessage id="common.accessories" defaultMessage="Accessories" />
    )
  },
  InstalledBaseSubscriptions: {
    path: 'subscriptions',
    label: (
      <FormattedMessage
        id="common.subscriptions"
        defaultMessage="Subscriptions"
      />
    )
  },
  DeviceUsageStatistics: {
    path: 'usage-statistics',
    label: (
      <FormattedMessage
        id="common.usageStatistics"
        defaultMessage="Usage Statistics"
      />
    )
  },
  DeviceUsageStatisticsFlow: {
    path: 'flow',
    label: 'Flow'
  },
  DeviceUsageStatisticsServo: {
    path: 'servo',
    label: 'Servo'
  },
  DeviceTestOverview: {
    path: 'preuse-check',
    label: <FormattedMessage id="common.tests" defaultMessage="Tests" />
  },
  DeviceLogOverview: {
    path: 'logs',
    label: <FormattedMessage id="common.logs" defaultMessage="Logs" />,
    defaultLogType: 'config'
  },
  DeviceServiceOrderOverview: {
    path: 'service-orders',
    label: (
      <FormattedMessage
        id="common.serviceOrders"
        defaultMessage="Service orders"
      />
    )
  },
  DevicePreventiveMaintenanceOverview: {
    path: 'preventive-maintenance',
    label: (
      <FormattedMessage
        id="common.preventiveMaintenance"
        defaultMessage="Preventive maintenance"
      />
    )
  },
  DeviceCaseOverview: {
    path: 'cases',
    label: <FormattedMessage id="common.cases" defaultMessage="Cases" />
  },
  Profile: {
    path: 'profile',
    label: <FormattedMessage id="common.profile" defaultMessage="Profile" />
  },
  ServiceOrderOverview: {
    path: 'service-orders',
    label: (
      <FormattedMessage
        id="common.serviceOrders"
        defaultMessage="Service orders"
      />
    )
  },
  PreventiveMaintenanceOverview: {
    path: 'preventive-maintenance',
    label: (
      <FormattedMessage
        id="common.preventiveMaintenance"
        defaultMessage="Preventive maintenance"
      />
    )
  },
  RunningHoursOverview: {
    path: 'running-hours',
    label: (
      <FormattedMessage
        id="common.runningHours"
        defaultMessage="Running hours"
      />
    )
  },
  Admin: {
    path: 'admin',
    label: (
      <FormattedMessage id="common.adminPortal" defaultMessage="Admin portal" />
    )
  },
  AdminDevices: {
    path: 'devices',
    label: <FormattedMessage id="common.devices" defaultMessage="Devices" />
  },
  AdminCN: {
    path: 'connectivity-nodes',
    label: (
      <FormattedMessage
        id="common.connectivityNodes"
        defaultMessage="Connectivity nodes"
      />
    )
  },
  UsageStatisticsOverview: {
    path: 'usage-statistics',
    label: (
      <FormattedMessage
        id="common.usageStatistics"
        defaultMessage="Usage statistics"
      />
    )
  },
  ServoUsageStatisticsOverview: {
    path: 'usage-statistics/servo'
  },
  LoginCallback: {
    path: 'callback'
  }
};

export default routePaths;
