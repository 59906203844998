import React, { useMemo } from 'react';
import propTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { RadarChart, CircularGridLines } from 'react-vis';
import { CardSectionColumn, EmptyState } from '..';
import { WithLoadingSpinner } from '../../hoc/';
import './AdoptionMetrics.scss';
import colorVariables from '../../scss/_variables.module.scss';

// Create custom made labels because the radar chart labels are not much editable.
const generateRadarChartLabels = (data, labelRadius) =>
  data instanceof Array &&
  data.map((item, i) => {
    // Calculate X and Y coordinates of label.
    const rad = (i / data.length) * 2 * Math.PI;
    const labelX = Math.sin(rad) * labelRadius * -1;
    const labelY = Math.cos(rad) * labelRadius * -1;
    return (
      <div
        key={item.label}
        style={{
          transform: `translate(${labelX}px, ${labelY}px)`
        }}
      >
        <label
          style={{
            left: labelX >= 0 ? -5 : 'initial',
            right: labelX < 0 ? -5 : 'initial',
            top: labelY >= 0 ? 5 : 'initial',
            textAlign: labelX < 0 ? 'right' : 'left'
          }}
        >
          {item.label}
        </label>
      </div>
    );
  });

const generateRadarChartData = data => [
  data.reduce((object, item) => {
    object[item.label] = item.value;
    return object;
  }, {})
];

const generateRadarChartDomains = data =>
  data.map(item => {
    return {
      name: item.label,
      domain: [0, item.maxValue]
    };
  });

export const AdoptionMetrics = ({
  metricsUsage,
  chartWidth = 220, // pixels
  chartHeight = 220, // pixels
  chartMargin = 50, // pixels
  labelRadius = 70, // pixels
  tickCount = 6, // count
  chartColors = [colorVariables['colors-chart--color-chart-orange-2']]
}) => {
  const chartData = useMemo(() => {
    if (!metricsUsage || !metricsUsage.adoption) return;

    // exclude AGC and Recruitment maneuver if they were not used in any of the cases
    // GDP-1462
    const filteredData = metricsUsage.adoption.filter(
      item =>
        !(
          ['AGC', 'Recruitment maneuver'].includes(item.label) &&
          item.value === 0
        )
    );

    // Radar chart requires data in specific format and these two functions transform recieved data.
    const generatedDomains = generateRadarChartDomains(filteredData);
    const generatedData = generateRadarChartData(filteredData);

    // Generate custom labels.
    const generatedLabelElements = generateRadarChartLabels(
      filteredData,
      labelRadius
    );

    return {
      data: generatedData,
      domains: generatedDomains,
      labels: generatedLabelElements
    };
  }, [metricsUsage, labelRadius]);

  return (
    <CardSectionColumn
      title={
        <FormattedMessage
          id="usageStatistics.adoptionMetrics"
          defaultMessage="Adoption metrics"
        />
      }
    >
      <WithLoadingSpinner when="metricsUsage">
        {(metricsUsage &&
          metricsUsage.adoption &&
          metricsUsage.adoption.length >= 3 &&
          metricsUsage.adoption.filter(item => item.value > 0).length >= 1 &&
          metricsUsage.adoption.every(item => item.maxValue) &&
          chartData && (
            <div className="mt-3 gtg-adoption-metrics-chart">
              <RadarChart
                data={chartData.data}
                domains={chartData.domains}
                width={chartWidth}
                height={chartHeight}
                margin={chartMargin}
                colorRange={chartColors}
                className="gtg-radar-chart"
                style={{
                  polygons: {
                    fillOpacity: 0.4,
                    strokeWidth: 1,
                    strokeOpacity: 1
                  },
                  axes: {
                    text: { display: 'none' },
                    ticks: {
                      display: 'none'
                    }
                  },
                  labels: {
                    display: 'none'
                  }
                }}
              >
                <CircularGridLines
                  tickValues={[...new Array(tickCount)].map(
                    (_, i) => i / tickCount - 1
                  )}
                />
              </RadarChart>
              <div className="gtg-adoption-metrics-labels">
                {chartData.labels}
              </div>
            </div>
          )) || <EmptyState />}
      </WithLoadingSpinner>
    </CardSectionColumn>
  );
};

AdoptionMetrics.propTypes = {
  metricsUsage: propTypes.shape({
    carbonFootprint: propTypes.number,
    adoption: propTypes.arrayOf(
      propTypes.shape({
        label: propTypes.string,
        maxValue: propTypes.number,
        value: propTypes.number
      })
    )
  }),
  chartWidth: propTypes.number,
  chartHeight: propTypes.number,
  chartMargin: propTypes.number,
  labelRadius: propTypes.number,
  tickCount: propTypes.number,
  chartColors: propTypes.arrayOf(propTypes.string)
};

export default AdoptionMetrics;
