import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { getStartAndEndData } from '../..';
import Icon from '../Icon/Icon';

import styles from './DateScroller.module.scss';

/**
 * Given timespan and count (backwards from today), it calculates and displays the correct dates.
 * On left/right arrow click it raises events.
 */
const DateScroller = ({ timespan, count, onBackClick, onForwardClick }) => {
  const { label, subLabel } = getStartAndEndData(undefined, timespan, count);
  const isMostRecentTimespan = count === 0;

  const rightClickHandler = useCallback(() => {
    // Don't allow to going into the future
    if (isMostRecentTimespan) return;

    onForwardClick();
  }, [isMostRecentTimespan, onForwardClick]);

  return (
    <div className={styles.gtgDateScroller}>
      <span onClick={onBackClick}>
        <Icon name="arrow-left" size="sm" />
      </span>
      <div className={styles.gtgDateScrollerHeader}>
        {label}
        {subLabel && <div>{subLabel}</div>}
      </div>
      <span onClick={rightClickHandler} aria-disabled={isMostRecentTimespan}>
        <Icon name="arrow-right" size="sm" />
      </span>
    </div>
  );
};

DateScroller.propTypes = {
  timespan: PropTypes.string.isRequired,
  count: PropTypes.number.isRequired,
  onBackClick: PropTypes.func.isRequired,
  onForwardClick: PropTypes.func.isRequired
};

export default DateScroller;
