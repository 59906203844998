import {
  setTests,
  FETCH_TESTS,
  entity,
  setTest24HourAlarm,
  calculateTest24HourAlarm,
  CALCULATE_DEVICE_TEST_NOT_PERFORMED_ALARM,
  FETCH_LAST_SYSTEM_CHECKOUT,
  setLastSystemCheckout
} from '../actions/tests';
import moment from 'moment';
import { apiRequest, API_SUCCESS } from '../actions/api';
import { API_URLS } from './urls';
import get from 'lodash.get';

const URL = API_URLS.Devices;

export const testsMdl = ({ dispatch }) => next => action => {
  next(action);

  const { type, pagination, sorting, name } = action;

  switch (type) {
    case FETCH_TESTS:
      dispatch(
        apiRequest({
          method: 'GET',
          url:
            `${URL.url}/${action.id}/pre-use-checks` +
            `?page=${pagination.page + 1}` +
            `&pageSize=${pagination.pageSize}` +
            (sorting ? `&sort=${sorting}` : '') +
            (name ? `&name=${name}` : ''),
          entity: entity,
          requestSignature: 'tests'
        })
      );
      break;
    case FETCH_LAST_SYSTEM_CHECKOUT:
      dispatch(setTest24HourAlarm(false));
      dispatch(
        apiRequest({
          method: 'GET',
          url: `${URL.url}/${
            action.deviceId
          }/pre-use-checks?page=1&pageSize=1&sort=-start&name=${encodeURIComponent(
            'System checkout'
          )}`,
          entity: FETCH_LAST_SYSTEM_CHECKOUT,
          requestSignature: 'lastSystemCheckoutForDevice'
        })
      );
      break;
    case `${entity} GET ${API_SUCCESS}`:
      if (action.type === `${entity} GET ${API_SUCCESS}`) {
        if (
          action.payload.data &&
          action.payload.data.pagination &&
          action.payload.data.size
        ) {
          action.payload.data.pagination.page =
            action.payload.data.pagination.page - 1;
          action.payload.data.pagination.totalItems = action.payload.data.size;
        }
        dispatch(setTests(action.payload.data));
      }
      break;
    case `${FETCH_LAST_SYSTEM_CHECKOUT} GET ${API_SUCCESS}`:
      if (action.type === `${FETCH_LAST_SYSTEM_CHECKOUT} GET ${API_SUCCESS}`) {
        dispatch(setLastSystemCheckout(action.payload.data));
        dispatch(calculateTest24HourAlarm(action.payload.data));
      }
      break;
    case CALCULATE_DEVICE_TEST_NOT_PERFORMED_ALARM:
      const currentTime = moment();
      const lastCheckUnder24HoursAgo = get(action, 'payload.items', []).some(
        i => {
          return currentTime.diff(moment(i.start), 'h') < 24;
        }
      );
      dispatch(setTest24HourAlarm(!lastCheckUnder24HoursAgo));
      break;
    // no default
  }
};
