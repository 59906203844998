export const entity = '[Config]';
export const FETCH_CONFIG = `${entity} Fetch`;
export const SET_CONFIG = `${entity} Set`;

/**
 * Trigger fetch of the configuration file
 */
export const fetchConfig = () => ({
  type: FETCH_CONFIG
});

/**
 * Set data as new config
 * @param {array} data New configuration data
 */
export const setConfig = data => ({
  type: SET_CONFIG,
  payload: data
});
