export const entity = '[Metrics]';
export const FETCH_METRICS = `${entity} Fetch`;
export const SET_METRICS = `${entity} Set`;

/**
 * Trigger fetch of metrics
 */
export const fetchMetrics = (timeSpan, count, deviceIds) => ({
  type: FETCH_METRICS,
  payload: {
    deviceIds,
    timeSpan,
    count
  }
});

/**
 * Set data as new metrics
 * @param {array} data New metrics
 */
export const setMetrics = data => ({
  type: SET_METRICS,
  payload: data
});
