import React, { useEffect, useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import propTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { setFilters } from '../../redux/actions/filters';
import { WithPersistentState } from '../../hoc';
import {
  fetchAccounts,
  setAccounts,
  fetchDepartments,
  fetchDeviceTypes
} from '../../redux/actions/deviceFilters';
import { savePersistentState } from '../../redux/actions/persistentState';
import { FormattedMessage } from 'react-intl';
import get from 'lodash.get';

import { Filter, isRequestPending } from '../../components';
import { accountSelected } from '../../redux/actions/account';

const FiltersContainer = ({
  filters,
  accounts,
  departments,
  deviceTypes,
  pendingRequests,
  isInternalUser
}) => {
  const dispatch = useDispatch();
  const [accountID, setAccountID] = useState(
    filters && filters.accounts && filters.accounts.length > 0
      ? filters.accounts[0].id
      : null
  );

  /* eslint-disable react-hooks/exhaustive-deps */
  const refreshFilters = useCallback(values => {
    const account = values && values.length > 0 ? values[0].id : null;

    if (
      account &&
      account !== accountID &&
      !isRequestPending('accounts', pendingRequests)
    ) {
      setAccountID(account);
      dispatch(fetchDepartments(account));
      dispatch(fetchDeviceTypes(account));
    }
  });

  useEffect(() => {
    refreshFilters(filters.accounts);
  }, [filters.accounts, refreshFilters]);

  useEffect(() => {
    // prefetch accounts for non-regional users
    if (
      !isInternalUser &&
      !accounts &&
      !isRequestPending('accounts', pendingRequests)
    ) {
      dispatch(fetchAccounts());
    }
  }, [isInternalUser, accounts, pendingRequests, dispatch]);

  const handleAccountFilterUpdate = useCallback(value => {
    if (
      value !== filters.accounts &&
      get(value, '[0]', null).id !== accountID
    ) {
      // if account filter has value and is changed, clear departments, otherwise not
      if (filters.accounts.length > 0) {
        dispatch(
          setFilters({ accounts: value, departments: [], deviceTypes: [] })
        );
        dispatch(savePersistentState('deviceFilterDepartments', []));
        dispatch(savePersistentState('deviceFilterDeviceTypes', []));
      } else {
        dispatch(setFilters({ accounts: value }));
      }

      dispatch(accountSelected(get(value, '[0]')));
    }
  });

  const handleAccountFilterInput = useCallback(value => {
    // if null or empty string is passed, clear accounts
    if (!isRequestPending('accounts', pendingRequests)) {
      (value && value !== '' && dispatch(fetchAccounts(value))) ||
        dispatch(setAccounts([]));
    }
  });

  const handleDepartmentFilterUpdate = useCallback(value => {
    if (value !== filters.departments) {
      dispatch(setFilters({ departments: value }));
      dispatch(savePersistentState('deviceFilterDepartments', value));
    }
  });

  const handleDeviceTypesFilterUpdate = useCallback(value => {
    if (value !== filters.deviceTypes) {
      dispatch(setFilters({ deviceTypes: value }));
      dispatch(savePersistentState('deviceFilterDeviceTypes', value));
    }
  });

  return (
    <>
      <WithPersistentState
        stateKey={'deviceFilterAccount'}
        target={'initialSelectedValue'}
        onLoadFromPersistentState={[handleAccountFilterUpdate, refreshFilters]}
      >
        <Filter
          dataCy="deviceFilterAccount"
          filterItems={accounts || []}
          placeHolder={
            <FormattedMessage id="column.hospital" defaultMessage="Hospital" />
          }
          icon="hospital"
          initialSelectedValue={filters.accounts || []}
          onFilterUpdate={handleAccountFilterUpdate}
          onFilterInputUpdate={
            (isInternalUser && handleAccountFilterInput) || null
          }
          queryInProgress={isRequestPending('accounts', pendingRequests)}
          filterWidth={500}
          searchPlaceholder={
            <FormattedMessage
              id="common.accountsSearch"
              defaultMessage="Search by hospital name, city or country"
            />
          }
          searchMinStrLength={1}
        />
      </WithPersistentState>
      <WithPersistentState
        stateKey={'deviceFilterDepartments'}
        target={'initialSelectedValue'}
        onLoadFromPersistentState={handleDepartmentFilterUpdate}
      >
        <Filter
          dataCy="deviceFilterDepartments"
          filterItems={(accountID && departments) || []}
          placeHolder={
            <FormattedMessage
              id="column.department"
              defaultMessage="Department"
            />
          }
          icon="department"
          initialSelectedValue={(accountID && filters.departments) || []}
          multiselect
          onFilterUpdate={accountID && handleDepartmentFilterUpdate}
        />
      </WithPersistentState>
      <WithPersistentState
        stateKey={'deviceFilterDeviceTypes'}
        target={'initialSelectedValue'}
        onLoadFromPersistentState={handleDeviceTypesFilterUpdate}
      >
        <Filter
          dataCy="deviceFilterDeviceTypes"
          filterItems={(accountID && deviceTypes) || []}
          placeHolder={
            <FormattedMessage id="common.devices" defaultMessage="Devices" />
          }
          icon="device"
          initialSelectedValue={(accountID && filters.deviceTypes) || []}
          multiselect
          onFilterUpdate={accountID && handleDeviceTypesFilterUpdate}
        />
      </WithPersistentState>
      <Filter
        dataCy="deviceFilterEndDate"
        disabled
        placeHolder={
          <FormattedMessage id="common.endDate" defaultMessage="End date" />
        }
        icon="calendar"
      />
    </>
  );
};

const mapStateToProps = state => ({
  filters: state.filters,
  accounts: state.deviceFilters.accounts,
  departments: state.deviceFilters.departments,
  deviceTypes: state.deviceFilters.deviceTypes,
  pendingRequests: state.api.pendingRequests,
  isInternalUser: get(state, 'authorization.userinfo.isInternalUser')
});

FiltersContainer.propTypes = {
  filters: propTypes.shape({
    accounts: propTypes.arrayOf(
      propTypes.shape({
        id: propTypes.string,
        label: propTypes.string,
        description: propTypes.string,
        countryCode: propTypes.string
      })
    ),
    departments: propTypes.arrayOf(
      propTypes.shape({
        id: propTypes.string,
        label: propTypes.string
      })
    ),
    deviceTypes: propTypes.arrayOf(
      propTypes.shape({
        id: propTypes.string,
        label: propTypes.string
      })
    )
  }),
  accounts: propTypes.arrayOf(
    propTypes.shape({
      id: propTypes.string,
      label: propTypes.string,
      description: propTypes.string,
      countryCode: propTypes.string
    })
  ),
  departments: propTypes.arrayOf(
    propTypes.shape({
      id: propTypes.string,
      label: propTypes.string
    })
  ),
  deviceTypes: propTypes.arrayOf(
    propTypes.shape({
      id: propTypes.string,
      label: propTypes.string
    })
  ),
  isInternalUser: propTypes.bool
};

export default compose(connect(mapStateToProps))(FiltersContainer);
