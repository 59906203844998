export const entity = '[Persistent state]';
export const PERSISTENT_STATE_REQUEST = `${entity} Request`;
export const LOAD_PERSISTENT_STATE = `${entity} Load`;
export const SAVE_PERSISTENT_STATE = `${entity} Save`;
export const LOAD_PERSISTENT_STORE = `${entity} Load store`;
export const REMOVE_PERSISTENT_STATE = `${entity} Remove`;

const properties = [
  'deviceFilterAccount',
  'deviceFilterDepartments',
  'deviceFilterDeviceTypes'
];

export const loadPersistentStore = () => {
  return {
    type: LOAD_PERSISTENT_STORE,
    payload: properties
  };
};

export const loadPersistentState = key => {
  return {
    type: LOAD_PERSISTENT_STATE,
    payload: key
  };
};

export const savePersistentState = (key, value) => {
  return {
    type: SAVE_PERSISTENT_STATE,
    payload: {
      key: key,
      value
    }
  };
};

export const removePersistentState = key => {
  return {
    type: REMOVE_PERSISTENT_STATE,
    payload: key
  };
};
