import React, { useState, useCallback } from 'react';
import {
  XYPlot,
  XAxis, // Shows the values on x axis
  VerticalBarSeries,
  LabelSeries,
  Hint
} from 'react-vis';
import { FormattedMessage } from 'react-intl';
import { CardSectionColumn, EmptyState, BarChartTooltip } from '..';
import { WithLoadingSpinner } from '../../hoc/';
import colorVariables from '../../scss/_variables.module.scss';

export const UsageStatisticsServoUtilizationBarChart = ({
  chartData,
  chartWidth = 250,
  chartHeight = 250,
  title,
  defaultTitle
}) => {
  const [hoveredValue, setHoveredValue] = useState(false);
  const handleMouseOver = useCallback(
    value => {
      setHoveredValue(value);
    },
    [setHoveredValue]
  );

  const handleMouseOut = useCallback(() => {
    setHoveredValue(false);
  }, [setHoveredValue]);

  const getMousePosition = useCallback((value, { event, innerX, index }) => {
    /* Set position of tooltip container based on the mouse pointer */
    let tooltipContainer = document.getElementsByClassName('tooltip');
    let tooltipArrow = document.getElementsByClassName('arrow');
    if (tooltipContainer.length > 0) {
      tooltipContainer[0].style.cssText += `left:${event.clientX -
        3}px !important; top:${event.clientY -
        119}px !important; bottom: auto;`;
      tooltipArrow[0].style.cssText += 'bottom:1px;';
    }
  }, []);

  const hasVentilationModesData =
    chartData && chartData.length > 0 && chartData.some(item => item.value > 0);

  const customHint = hoveredValue => {
    const tooltipText =
      hoveredValue.x === 'Auto SRM' ? (
        <FormattedMessage
          id="usageStatistics.servoUtilizationAutoSRMTooltip"
          defaultMessage="Number of Automatic Stepwise Recruitment Maneuvers"
        />
      ) : (
        <FormattedMessage
          id="usageStatistics.servoUtilizationAutoRMTooltip"
          defaultMessage="Number of Automatic Recruitment Maneuvers"
        />
      );
    return (
      <Hint
        value={{
          x: hoveredValue.x,
          y: hoveredValue.y
        }}
      >
        <BarChartTooltip content={tooltipText} />
      </Hint>
    );
  };

  const dataLabelMapper = {
    srm: 'Auto SRM',
    rm: 'Auto RM'
  };

  const formattedData =
    chartData &&
    chartData.length > 0 &&
    chartData.map((d, index) => {
      return {
        x: dataLabelMapper[d.label],
        y: d.value,
        label: d.value.toString(),
        color:
          index === 0
            ? colorVariables['colors-chart--color-chart-blue-5']
            : colorVariables['colors-chart--color-chart-blue-9']
      };
    });

  function updateTextColor() {
    /****
     * Check if bar height is more than the number label on bar chart.
     * If label text height is more than bar, then change number label color to blue
     * If label text height is less than bar, then change number label color to white
     */
    const barChart = document.getElementsByClassName(
      'rv-xy-plot__series rv-xy-plot__series--bar'
    );
    let firstLabelTextHeight = 0,
      secondLabelTextHeight = 0,
      firstBarHeight = 0,
      secondBarHeight = 0,
      firstLabel = null,
      secondLabel = null;

    if (barChart.length !== 0) {
      firstBarHeight = barChart[0].getElementsByTagName('rect')[0];
      secondBarHeight = barChart[0].getElementsByTagName('rect')[1];

      firstBarHeight = firstBarHeight.getAttribute('height');
      secondBarHeight = secondBarHeight.getAttribute('height');
    }

    const labelText = document.getElementsByClassName(
      'rv-xy-plot__series rv-xy-plot__series--label'
    );

    if (labelText.length !== 0) {
      firstLabel = labelText[0].getElementsByTagName('text')[0];
      secondLabel = labelText[0].getElementsByTagName('text')[1];
      firstLabelTextHeight = firstLabel.getBBox().height + 4;
      secondLabelTextHeight = secondLabel.getBBox().height + 4;
    }

    const textGroup = document.getElementsByClassName(
      'gtg-servo-utlization-bar-chart-count-label'
    );

    if (textGroup.length > 0) {
      firstLabel.style.cssText =
        firstBarHeight >= firstLabelTextHeight
          ? `fill: ${colorVariables['colors-general--white']};transform: translateY(25px)`
          : `fill: ${colorVariables['colors-chart--color-chart-blue-9']};transform: translateY(-10px)`;
      secondLabel.style.cssText =
        secondBarHeight >= secondLabelTextHeight
          ? `fill: ${colorVariables['colors-general--white']};transform: translateY(25px)`
          : 'fill: #6086B9;transform: translateY(-10px)';
    }

    return { fontWeight: '700' };
  }

  setTimeout(function() {
    updateTextColor();
  }, 0);

  return (
    <CardSectionColumn
      title={
        <div className="gtg-servo-utilization-chart-header">
          <FormattedMessage
            id={`usageStatistics.${title}`}
            defaultMessage={defaultTitle}
          />
        </div>
      }
    >
      <WithLoadingSpinner when="chartData">
        {(hasVentilationModesData && (
          <div className="d-flex justify-content-center">
            <XYPlot
              xType="ordinal"
              width={chartWidth}
              height={chartHeight}
              margin={{ right: 0, left: 10 }}
            >
              <XAxis
                tickSize={0}
                style={{
                  line: {
                    stroke: colorVariables['colors-general--black'],
                    strokeWidth: '1px'
                  },
                  fontWeight: 400,
                  fill: colorVariables['colors-general--black'],
                  fontSize: '12px'
                }}
                tickPadding={12}
                top={chartHeight - 39}
              />
              <VerticalBarSeries
                data={formattedData}
                barWidth={0.5}
                tickTotal={0}
                colorType="literal"
                onValueMouseOver={handleMouseOver}
                onValueMouseOut={handleMouseOut}
                onNearestX={getMousePosition}
              />
              <LabelSeries
                data={formattedData}
                labelAnchorX="middle"
                labelAnchorY="top"
                className="gtg-servo-utlization-bar-chart-count-label"
                style={updateTextColor()}
              />
              {hoveredValue && customHint(hoveredValue)}
            </XYPlot>
          </div>
        )) || <EmptyState />}
      </WithLoadingSpinner>
    </CardSectionColumn>
  );
};

export default UsageStatisticsServoUtilizationBarChart;
