export const caseDetailsGasConsumptionColumns = [
  { header: 'Hospital', key: 'hospital', width: 40 },
  { header: 'Department', key: 'departmentName', width: 40 },
  { header: 'Location', key: 'location', width: 40 },
  { header: 'Device', key: 'deviceType', width: 40 },
  { header: 'Serial no', key: 'serialID', width: 40 },
  { header: 'Customer device no', key: 'customerDeviceID', width: 40 },
  { header: 'Start case', key: 'startCase', width: 40 },
  { header: 'End case', key: 'endCase', width: 40 },
  { header: 'SEV [mL]', key: 'sev', width: 40 },
  { header: 'DES [mL]', key: 'des', width: 40 },
  { header: 'ISO [mL]', key: 'iso', width: 40 },
  { header: 'N2O [L]', key: 'n2o', width: 40 },
  { header: 'O2[L]', key: 'o2', width: 40 },
  { header: 'Average FGF [L/min]', key: 'avg_fgf', width: 40 }
];

// Define filename
export const caseDetailsGasConsumptionFileName =
  'Case_details_gas_consumption.xlsx';
