export const entity = '[Devices software versions]';
export const FETCH_SOFTWARE_VERSIONS = `${entity} Fetch`;
export const SET_SOFTWARE_VERSIONS = `${entity} Set`;

/**
 * Trigger fetch of software versions
 */
export const fetchSoftwareVersions = () => ({
  type: FETCH_SOFTWARE_VERSIONS
});

/**
 * Set software versions
 * @param {array} data New software versions
 */
export const setSoftwareVersions = data => ({
  type: SET_SOFTWARE_VERSIONS,
  payload: data
});
