import React, { useCallback } from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { WithLoadingSpinner } from '../../hoc';
import {
  Card,
  CardSection,
  DataTable,
  Icon,
  Alert,
  EmptyState,
  Formatted24hTime,
  isFlowFamily,
  TestResultIcon,
  mapTestResult,
  DataTableFixedColumnHeader
} from '..';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { Device } from '../../hoc/withAbilityContext/rule-builder';
import useAbility from '../../hooks/useAbility';
import './DeviceTestList.scss';
import get from 'lodash.get';

const DeviceTestList = props => {
  const {
    tests,
    testNotPerformedAlarm,
    manualPaginationSettings,
    device
  } = props;

  // Get the ability context in order to check for permission to
  // view test details for a device type
  const ability = useAbility();
  let canViewTestDetails = false;
  const deviceType = get(device, 'deviceType', '');

  if (deviceType && ability) {
    canViewTestDetails = ability.can(
      Device.VIEW_TEST_DETAILS,
      new Device(deviceType)
    );
  }

  const handleRowClick = useCallback(
    (state, rowInfo) => {
      if (
        canViewTestDetails &&
        rowInfo &&
        rowInfo.original &&
        rowInfo.original.id
      ) {
        return {
          onClick: () => {
            props.history.push(`${props.match.url}/${rowInfo.original.id}`);
          }
        };
      } else {
        return {};
      }
    },
    [canViewTestDetails, props.history, props.match.url]
  );

  let columns = [
    {
      Header: <FormattedMessage id="column.result" defaultMessage="Result" />,
      id: 'failedIcon',
      accessor: row =>
        row.result === 'watch'
          ? get(row, 'failedProactiveWatch.failed', [])
          : get(row, 'failed.failed', []),
      sortable: false,
      width: 170,
      noOverflowTooltip: true,
      Cell: row => (
        <>
          <TestResultIcon
            testResult={row.original.result}
            testFailedCount={(row.value || []).length > 0 && row.value.length}
          />
          <span className="ml-2 font-weight-bold">
            {mapTestResult(row.original.result)}
          </span>
        </>
      )
    },
    {
      Header: <FormattedMessage id="column.type" defaultMessage="Type" />,
      accessor: 'name',
      sortable: false,
      width: 140
    },
    {
      Header: <FormattedMessage id="column.error" defaultMessage="Error" />,
      id: 'failedError',
      accessor: row =>
        row.result === 'watch'
          ? get(row, 'failedProactiveWatch.failed', [])
          : get(row, 'failed.failed', []),
      sortable: false,
      Cell: row => <>{(row.value || []).map(error => error.name).join()}</>
    },
    {
      Header: <FormattedMessage id="column.date" defaultMessage="Date" />,
      accessor: 'start',
      sortable: false,
      width: 120,
      Cell: row => <FormattedDate value={row.value} timeZone="UTC" />
    },
    {
      Header: <FormattedMessage id="column.time" defaultMessage="Time" />,
      accessor: 'start',
      sortable: false,
      width: 120,
      Cell: row => <Formatted24hTime value={row.value} timeZone="UTC" />
    }
  ];

  if (canViewTestDetails) {
    columns = [
      ...columns,
      {
        Header: '',
        accessor: 'name',
        sortable: false,
        width: 100,
        Cell: () => (
          <span className="card-link">
            <FormattedMessage id="common.view" defaultMessage="View" />
            <Icon name="arrow-right-full" size="sm" />
          </span>
        )
      }
    ];
  }

  return (
    <>
      {testNotPerformedAlarm && isFlowFamily(deviceType) && (
        <div className="row">
          <div className="col-12 col-md-12 mb-2">
            <Alert
              level="warning"
              msg={
                <FormattedMessage
                  id="test.notPerformedAlarm"
                  defaultMessage="More than 24 hours have passed since the last system checkout."
                />
              }
            />
          </div>
        </div>
      )}
      <div className="row">
        <div className="col-12 col-md-12">
          <Card>
            <WithLoadingSpinner when="tests">
              <CardSection dataCy="sectionDeviceTestsList">
                {(tests && tests.items && tests.items.length > 0 && (
                  <>
                    <DataTableFixedColumnHeader
                      manualPaginationSettings={manualPaginationSettings}
                      data={tests && tests.items ? tests.items : []}
                      className="mb-4 gtg-device-test-list"
                      resizable={false}
                      getTrProps={handleRowClick}
                      columns={columns}
                      noCustomScrollbars
                      withOverflow
                    />
                  </>
                )) || <EmptyState />}
              </CardSection>
            </WithLoadingSpinner>
          </Card>
        </div>
      </div>
    </>
  );
};

export default compose(withRouter)(DeviceTestList);
