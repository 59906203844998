export const entity = '[Pagination history]';
export const PUSH_PAGINATION_HISTORY = `${entity} Push`;

export const pushPaginationHistory = (page, pageSize, sort) => {
  return {
    type: PUSH_PAGINATION_HISTORY,
    payload: {
      page,
      pageSize,
      sort
    }
  };
};
