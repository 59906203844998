import { FETCH_DEVICE, setDevice, PUT_DEVICE, entity } from '../actions/device';
import { apiRequest, API_SUCCESS } from '../actions/api';
import { API_URLS } from './urls';
import { deviceSwVersionMapper } from '../../components';

const URL = API_URLS.Devices;

export const deviceMdl = ({ dispatch }) => next => action => {
  next(action);

  const { type } = action;
  switch (type) {
    case FETCH_DEVICE:
      dispatch(
        apiRequest({
          method: 'GET',
          url: `${URL.url}/${action.id}`,
          requestSignature: 'deviceInformation',
          entity: entity
        })
      );
      break;
    case PUT_DEVICE:
      const { id, customerDeviceId, location } = action.payload;

      dispatch(
        apiRequest({
          method: 'PUT',
          url: `${URL.url}/${id}`,
          body: {
            customerDeviceId,
            location
          },
          entity: entity,
          filter: URL.filter
        })
      );
      break;
    case `${entity} GET ${API_SUCCESS}`:
      if (
        action.payload.data &&
        action.payload.data.deviceType &&
        action.payload.data.systemSoftwareVersion
      ) {
        action.payload.data.systemSoftwareVersion = deviceSwVersionMapper(
          action.payload.data.deviceType,
          action.payload.data.systemSoftwareVersion
        );
      }
      dispatch(setDevice(action.payload.data));
      break;
    // no default
  }
};
