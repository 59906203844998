export const entity = '[Case]';
export const FETCH_CASE = `${entity} Fetch`;
export const PUT_CASE = `${entity} Put`;
export const SET_CASE = `${entity} Set`;

/**
 * Trigger fetch of case information
 */
export const fetchCase = (deviceId, caseId) => ({
  type: FETCH_CASE,
  deviceId: deviceId,
  id: caseId
});

/**
 * Set data as new case information
 * @param {array} data New case information
 */
export const setCase = data => ({
  type: SET_CASE,
  payload: data
});
