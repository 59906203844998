import React from 'react';
import SpinnerElement from './SpinnerElement';
import './Spinner.scss';

const Spinner = props => (
  // TODO: This spinner should show a backdrop over the children
  <div className="spinner">
    {props.children}
    <SpinnerElement />
  </div>
);

export default Spinner;
