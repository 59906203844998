export const entity = '[Connected Accessories]';
export const FETCH_CONNECTED_ACCESSORIES = `${entity} Fetch`;
export const SET_CONNECTED_ACCESSORIES = `${entity} Set`;

/**
 * Trigger fetch of connected accessories
 */
export const fetchConnectedAccessories = (
  paginationSettings,
  sortingSettings
) => ({
  type: FETCH_CONNECTED_ACCESSORIES,
  pagination: paginationSettings,
  sorting: sortingSettings
});

/**
 * Set data as connected accessories for devices
 * @param {array} data New connected accessories
 */
export const setConnectedAccessories = data => ({
  type: SET_CONNECTED_ACCESSORIES,
  payload: data
});
