function AxisSettings(timeSpan) {
  let angle = 0,
    yPadding,
    displayMonth = false;
  switch (timeSpan) {
    case 'month':
      angle = -90;
      yPadding = '0.35em';
      break;
    case 'year':
      displayMonth = true;
      break;
    case 'y2y':
      displayMonth = true;
      break;
    // no default
  }
  return {
    tickLabelAngle: angle,
    yPadding: yPadding,
    displayMonth
  };
}

export default AxisSettings;
