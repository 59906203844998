import { SET_COSTS, FETCH_COSTS, SET_TEMP_COSTS } from '../actions/costs';

export function costsReducer(
  state = {
    costs: null,
    tempCosts: null
  },
  action
) {
  switch (action.type) {
    case FETCH_COSTS:
      return {
        ...state,
        costs: null
      };
    case SET_COSTS:
      return {
        ...state,
        costs: action.payload
      };
    case SET_TEMP_COSTS:
      return {
        ...state,
        tempCosts: (action.payload && action.payload.costs) || null
      };
    default:
      return state;
  }
}
