import {
  FETCH_JWT,
  FETCH_USERINFO,
  SET_USERINFO,
  SET_JWT
} from '../actions/authorization';

const initialState = {
  userinfo: null,
  jwt: null,
  roleChange: null
};

export function authorizationReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_JWT:
      return {
        ...state,
        roleChange: action.roleChange
      };
    case FETCH_USERINFO:
      return {
        ...state,
        userinfo: null
      };
    case SET_USERINFO:
      return {
        ...state,
        userinfo: action.payload
      };
    case SET_JWT:
      return {
        ...state,
        jwt: action.payload
      };
    default:
      return state;
  }
}
