import React, { useState, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { WithLoadingSpinner } from '../../hoc';
import { Modal, Icon, EmptyState, uuidv4 } from '..';
import './TestTroubleshootingGuidance.scss';
import { fetchTroubleshootingGuidance } from '../../redux/actions/troubleshootingGuidance';

const TestTroubleshootingGuidance = props => {
  const {
    checkFailureId,
    testName,
    fetchTroubleshootingGuidance,
    troubleshootingGuidance
  } = props;
  const [modalVisible, setModalVisible] = useState(false);

  const TroubleshootingGuidanceIcon = () => (
    <mark
      className="gtg-test-troubleshooting-guidance-toggle"
      onClick={showModal}
    >
      <Icon name="info" color="danger" />
    </mark>
  );

  const TroubleShootingGuidanceActionGroup = ({ actionGroup }) => (
    <React.Fragment>
      {actionGroup.condition ? (
        <p
          data-cy="troubleshootingGuidanceCondition"
          className="gtg-troubleshooting-guidance-condition text-left"
        >
          {actionGroup.condition}
        </p>
      ) : null}
      {actionGroup.actions.map(action => (
        <p
          key={uuidv4()}
          data-cy="troubleshootingGuidanceAction"
          className="text-left"
        >{`${action.ranking}. ${action.solution}`}</p>
      ))}
    </React.Fragment>
  );

  const showModal = useCallback(() => {
    if (checkFailureId && testName) {
      fetchTroubleshootingGuidance(checkFailureId, testName);
    }
    setModalVisible(true);
  }, [fetchTroubleshootingGuidance, setModalVisible, checkFailureId, testName]);

  const hideModal = useCallback(() => {
    setModalVisible(false);
  }, [setModalVisible]);

  return (
    <>
      <TroubleshootingGuidanceIcon />
      {modalVisible && (
        <Modal
          title={troubleshootingGuidance && troubleshootingGuidance.testName}
          onClickedOutside={hideModal}
          onSave={hideModal}
          className="gtg-troubleshooting-guidance-modal"
          saveStr={
            <FormattedMessage
              id="troubleshootingGuidance.ok"
              defaultMessage="OK"
            />
          }
        >
          <WithLoadingSpinner when="troubleshootingGuidance">
            {(troubleshootingGuidance && troubleshootingGuidance.testName && (
              <>
                <h5 className="text-left">
                  <FormattedMessage
                    id="troubleshootingGuidance.failureDescription"
                    defaultMessage="Description"
                  />
                </h5>
                <p className="text-left">
                  {troubleshootingGuidance.failureDescription}
                </p>
                <h5 className="text-left">
                  <FormattedMessage
                    id="troubleshootingGuidance.actions"
                    defaultMessage="Recommended actions"
                  />
                </h5>
                {troubleshootingGuidance.actionGroups.map(actionGroup => (
                  <TroubleShootingGuidanceActionGroup
                    key={uuidv4()}
                    actionGroup={actionGroup}
                  />
                ))}
              </>
            )) || (
              <EmptyState
                msg={
                  <FormattedMessage
                    id="troubleshootingGuidance.error"
                    defaultMessage="Could not fetch troubleshooting guidance"
                  />
                }
              />
            )}
          </WithLoadingSpinner>
        </Modal>
      )}
    </>
  );
};

const mapStateToProps = state => ({
  troubleshootingGuidance: state.troubleshootingGuidance.troubleshootingGuidance
});

const mapDispatchToProps = {
  fetchTroubleshootingGuidance
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  TestTroubleshootingGuidance
);
