import React, { useMemo } from 'react';
import propTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { CardSectionColumn, EmptyState, CaseUtilizationCharts } from '..';
import { WithLoadingSpinner } from '../../hoc/';
import './UsageStatisticsServoUtilizationDonutChart.scss';

export const UsageStatisticsServoUtilizationDonutChart = ({
  metricsUsage,
  dataObj,
  title,
  defaultTitle
}) => {
  const hasVentilationModesData =
    metricsUsage &&
    metricsUsage[dataObj] &&
    metricsUsage[dataObj].length > 0 &&
    metricsUsage[dataObj].some(item => item.value > 0);
  /**
   * Map array of objects to one object with labels as keys.
   * Labels are also uppercased so it lowercases them in order
   * to match the correct label display in VentilationModesChart
   */
  const mappedVentilationModes = useMemo(() => {
    return (
      hasVentilationModesData &&
      metricsUsage[dataObj].reduce((object, item) => {
        object[item.label.toLowerCase()] = item.value;
        return object;
      }, {})
    );
  }, [hasVentilationModesData, metricsUsage, dataObj]);

  return (
    <CardSectionColumn
      title={
        <div className="gtg-servo-utilization-chart-header">
          <FormattedMessage
            id={`usageStatistics.${title}`}
            defaultMessage={defaultTitle}
          />
        </div>
      }
      customClass={'gtg-servo-donut-charts-section'}
    >
      <WithLoadingSpinner when="usageStatisticsUtilization">
        {(hasVentilationModesData && (
          <div className="gtg-utilization-donut-chart-container">
            <CaseUtilizationCharts
              ventilationData={mappedVentilationModes}
              chartWidth={200}
              chartHeight={200}
              innerRadius={40}
              outerRadius={65}
              labelRadius={80}
              labelPercentageThreshold={10}
              dataObj={dataObj}
            />
          </div>
        )) || <EmptyState />}
      </WithLoadingSpinner>
    </CardSectionColumn>
  );
};

UsageStatisticsServoUtilizationDonutChart.propTypes = {
  metricsUsage: propTypes.shape({
    ventilationType: propTypes.arrayOf(
      propTypes.shape({
        label: propTypes.string,
        value: propTypes.number
      })
    ),
    patientCategory: propTypes.arrayOf(
      propTypes.shape({
        label: propTypes.string,
        value: propTypes.number
      })
    ),
    invasiveVentilation: propTypes.arrayOf(
      propTypes.shape({
        label: propTypes.string,
        value: propTypes.number
      })
    ),
    ediNava: propTypes.arrayOf(
      propTypes.shape({
        label: propTypes.string,
        value: propTypes.number
      })
    )
  })
};

export default UsageStatisticsServoUtilizationDonutChart;
