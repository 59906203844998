import React, { useState, useCallback, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { useDispatch } from 'react-redux';
import get from 'lodash.get';
import { Modal, Filter, isRequestPending } from '../..';
import { setFilters } from '../../../redux/actions/filters';
import { fetchAccounts } from '../../../redux/actions/deviceFilters';
import './HospitalSelection.scss';
import { accountSelected } from '../../../redux/actions/account';

const HospitalSelection = props => {
  const { filters, accounts, pendingRequests, isInternalUser } = props;
  const dispatch = useDispatch();
  const [modalVisible] = useState(true);

  useEffect(() => {
    // prefetch accounts for non-regional users
    if (
      !isInternalUser &&
      !accounts &&
      !isRequestPending('accounts', pendingRequests)
    ) {
      dispatch(fetchAccounts());
    }
  }, [isInternalUser, accounts, pendingRequests, dispatch]);

  const handleHospitalFilterSelect = useCallback(
    value => {
      if (value !== filters.hospitals && value.length > 0) {
        dispatch(setFilters({ hospitals: value }));
        dispatch(accountSelected(get(value, '[0]')));
      }
    },
    [dispatch, filters.hospitals]
  );

  const handleHospitalFilterInput = useCallback(
    value => {
      if (value) {
        dispatch(fetchAccounts(value));
      }
    },
    [dispatch]
  );

  return (
    <>
      {modalVisible && (
        <Modal
          title={
            <FormattedMessage
              id="common.selectHospitalInstruction"
              defaultMessage="Please select hospital to see data"
            />
          }
          className="gtg-hospital-selection-modal"
          dataCy="modalHospitalSelection"
        >
          <Filter
            filterItems={accounts || []}
            placeHolder={
              <FormattedMessage
                id="common.selectHospital"
                defaultMessage="Select hospital"
              />
            }
            icon="hospital"
            initialSelectedValue={[]}
            onFilterUpdate={handleHospitalFilterSelect}
            onFilterInputUpdate={
              (isInternalUser && handleHospitalFilterInput) || null
            }
            queryInProgress={isRequestPending('accounts', pendingRequests)}
            searchMinStrLength={1}
            searchPlaceholder={
              <FormattedMessage
                id="common.accountsSearch"
                defaultMessage="Search by hospital name, city or country"
              />
            }
          />
        </Modal>
      )}
    </>
  );
};

const mapStateToProps = state => ({
  filters: state.filters,
  accounts: state.deviceFilters.accounts,
  pendingRequests: state.api.pendingRequests,
  isInternalUser: get(state, 'authorization.userinfo.isInternalUser')
});

export default compose(connect(mapStateToProps))(HospitalSelection);
