import React, { useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import propTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import get from 'lodash.get';

import {
  fetchFilterAccounts,
  setFilterAccounts,
  fetchFilterDeviceTypes,
  setFilterDeviceTypes
} from '../../redux/actions/admin';
import { Filter, FilterInput, isRequestPending } from '../../components';
import { transformDeviceTypes } from '../../utils/transform';
import { connectivityNodes } from '../../components';
import { fetchSoftwareVersions } from '../../redux/actions/softwareVersions';
import styles from './AdminFiltersContainer.module.scss';
import { softwareVersionFilter } from '../../utils/softwareVersionFilter';

const AdminFiltersContainer = ({
  setFilter,
  filters,
  accounts,
  deviceTypes,
  pendingRequests,
  fetchFilterDeviceTypes,
  fetchFilterAccounts,
  setFilterAccounts,
  softwareVersions,
  fetchSoftwareVersions
}) => {
  const handleSerialNumberClear = useCallback(() => {
    setFilter({ adminSerialNumber: '' });
  }, [setFilter]);

  const handleSerialNumberChange = useCallback(
    event => {
      const value = event.target.value;
      setFilter({ adminSerialNumber: value });
    },
    [setFilter]
  );

  useEffect(() => {
    fetchFilterDeviceTypes();
  }, [fetchFilterDeviceTypes]);

  useEffect(() => {
    fetchSoftwareVersions();
  }, [fetchSoftwareVersions]);

  // Add connectivity nodes to device types filter
  const filterConnectivityNodes = transformDeviceTypes(
    Object.entries(connectivityNodes || []).map(([_, value]) => {
      return value;
    })
  );

  const filteredDeviceTypes = [
    ...(deviceTypes || []),
    ...(filterConnectivityNodes || [])
  ];

  const handleAccountFilterUpdate = useCallback(
    value => {
      if (value.length !== 1) {
        filters.adminSoftwareVersion = [];
      }

      if (value !== filters.adminAccounts) {
        // if account filter has value and is changed, clear device types, otherwise not
        if (filters.adminAccounts && filters.adminAccounts.length > 0) {
          setFilter({ adminAccounts: value, adminDeviceTypes: [] });
        } else {
          setFilter({ adminAccounts: value });
        }
      }
    },
    [filters, setFilter]
  );

  const handleAccountFilterInput = useCallback(
    value => {
      if (!isRequestPending('adminFilterAccounts', pendingRequests)) {
        (value && value !== '' && fetchFilterAccounts(value)) ||
          setFilterAccounts([]);
      }
    },
    [pendingRequests, fetchFilterAccounts, setFilterAccounts]
  );

  const handleDeviceTypesFilterUpdate = useCallback(
    value => {
      if (
        (get(value, '[0].id') !== 'getinge-connect' || value.length !== 1) &&
        filters.adminAccounts.length === 0
      ) {
        filters.adminSoftwareVersion = null;
      }

      if (value !== filters.adminDeviceTypes) {
        setFilter({ adminDeviceTypes: value });
      }
    },
    [filters, setFilter]
  );

  const handleSoftwareVersionsFilterUpdate = useCallback(
    value => {
      if (get(value, '[0].label', '') === 'All system software versions') {
        setFilter({ adminSoftwareVersion: null });
        return;
      }

      if (value !== filters.adminSoftwareVersion) {
        setFilter({ adminSoftwareVersion: value });
      }
    },
    [filters, setFilter]
  );

  const svv = [
    'All system software versions',
    ...(softwareVersions || []).sort()
  ]
    .map(v => ({ label: v, id: v }))
    .sort();

  // Higher order function that creates a regex used to filter items based on passed in input value
  const swVersionFilter = useCallback(softwareVersionFilter, []);
  return (
    <div className={styles.gtgAdminFilters}>
      <div>
        <p>Device type</p>
        <Filter
          dataCy="adminFilterDeviceTypes"
          filterItems={filteredDeviceTypes || []}
          placeHolder={
            <FormattedMessage id="common.devices" defaultMessage="Devices" />
          }
          icon="device"
          initialSelectedValue={filters.adminDeviceTypes || []}
          multiselect
          onFilterUpdate={handleDeviceTypesFilterUpdate}
        />
      </div>
      <div>
        <p>Serial number</p>
        <FilterInput
          value={filters.adminSerialNumber}
          onChange={handleSerialNumberChange}
          onInputClear={handleSerialNumberClear}
          focus={false}
        />
      </div>
      <div>
        <p>Hospital</p>
        <Filter
          dataCy="adminFilterAccount"
          filterItems={accounts || []}
          placeHolder={
            <FormattedMessage id="column.hospital" defaultMessage="Hospital" />
          }
          icon="hospital"
          initialSelectedValue={filters.adminAccounts || []}
          onFilterUpdate={handleAccountFilterUpdate}
          onFilterInputUpdate={handleAccountFilterInput}
          queryInProgress={isRequestPending(
            'adminFilterAccounts',
            pendingRequests
          )}
          filterWidth={500}
          searchPlaceholder={
            <FormattedMessage
              id="common.accountsSearch"
              defaultMessage="Search by hospital name, city or country"
            />
          }
          searchMinStrLength={1}
          multiselect
        />
      </div>
      <div>
        <p>Software version</p>
        <Filter
          dataCy="swVersionFilterAccount"
          filterItems={svv || []}
          placeHolder={
            <FormattedMessage
              id="deviceStatus.systemSoftwareVersion"
              defaultMessage="Software versions"
            />
          }
          icon="device"
          initialSelectedValue={filters.adminSoftwareVersion || []}
          onFilterUpdate={handleSoftwareVersionsFilterUpdate}
          queryInProgress={isRequestPending(
            'SoftwareVersions',
            pendingRequests
          )}
          filterWidth={500}
          customInputFilter={swVersionFilter}
          searchPlaceholder={
            <FormattedMessage
              id="deviceStatus.systemSoftwareVersion"
              defaultMessage="Search by software version"
            />
          }
          disabled={
            !(
              ((filters.adminDeviceTypes || []).length > 0 &&
                filters.adminDeviceTypes.every(
                  n => n.id === 'getinge-connect'
                )) ||
              (filters.adminAccounts || []).length > 0
            )
          }
        />
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  filters: state.filters,
  accounts: state.admin.accounts,
  deviceTypes: state.admin.deviceTypes,
  softwareVersions: state.softwareVersions.softwareVersions,
  pendingRequests: state.api.pendingRequests,
  isInternalUser: get(state, 'authorization.userinfo.isInternalUser')
});

const mapDispatchToProps = {
  fetchFilterAccounts,
  setFilterAccounts,
  fetchFilterDeviceTypes,
  setFilterDeviceTypes,
  fetchSoftwareVersions
};

AdminFiltersContainer.propTypes = {
  setFilter: propTypes.func,
  filters: propTypes.shape({
    adminAccounts: propTypes.arrayOf(
      propTypes.shape({
        id: propTypes.string,
        label: propTypes.string,
        description: propTypes.string,
        countryCode: propTypes.string
      })
    ),
    adminDeviceTypes: propTypes.arrayOf(
      propTypes.shape({
        id: propTypes.string,
        label: propTypes.string
      })
    ),
    adminSerialNumber: propTypes.string,
    adminSoftwareVersion: propTypes.oneOfType([
      propTypes.arrayOf(propTypes.object),
      propTypes.string
    ])
  }),
  accounts: propTypes.arrayOf(
    propTypes.shape({
      id: propTypes.string,
      label: propTypes.string,
      description: propTypes.string,
      countryCode: propTypes.string
    })
  ),
  deviceTypes: propTypes.arrayOf(
    propTypes.shape({
      id: propTypes.string,
      label: propTypes.string
    })
  ),
  softwareVersions: propTypes.arrayOf(propTypes.string),
  isInternalUser: propTypes.bool
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  AdminFiltersContainer
);
