import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { FormElement, commonFormElementPropTypes } from '..';

const Select = ({
  label,
  name,
  value,
  setValue,
  errors,
  postfix,
  allValues
}) => {
  const handleChange = useCallback(
    e => {
      if (setValue) {
        const target = e.target;
        setValue(target.value, target.name);
      }
    },
    [setValue]
  );

  const hasErrors = Array.isArray(errors) && errors.length > 0;

  return (
    <FormElement label={label} errors={errors} postfix={postfix}>
      <select
        name={name}
        value={value}
        onChange={handleChange}
        className={classnames(hasErrors && 'gtg-form-element-with-error')}
      >
        {!value && (
          <option disabled value={value}>
            -- select an option --
          </option>
        )}
        {(allValues || []).map(val => (
          <option value={val.value} key={val.value}>
            {val.label}
          </option>
        ))}
      </select>
    </FormElement>
  );
};

Select.propTypes = {
  ...commonFormElementPropTypes,
  allValues: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
      label: PropTypes.string.isRequired
    }).isRequired
  ).isRequired
};

export default Select;
