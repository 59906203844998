import React, { useEffect, useState, useCallback } from 'react';
import get from 'lodash.get';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import './DeviceLogDownloadModal.scss';
import { Modal, ProgressIcon } from '../../../../components';
import { downloadLogs } from '../../../../redux/actions/logs';
import DownloadModalDescription from './ModalDescription/DeviceLogDownloadModalDescription';

export const DeviceLogDownloadModal = ({
  deviceId,
  logType,
  onDownloadComplete,
  dispatchDownloadLogs,
  files
}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [downloadStatus, setDownloadStatus] = useState('inprogress');
  const [downloadProgress, setDownloadProgress] = useState(0);

  // Initialize
  useEffect(() => {
    dispatchDownloadLogs(deviceId, logType);
    setModalVisible(true);
  }, [dispatchDownloadLogs, deviceId, logType, setModalVisible]);

  const hideModal = useCallback(() => {
    setModalVisible(false);
    onDownloadComplete();
  }, [setModalVisible, onDownloadComplete]);

  /* eslint-disable react-hooks/rules-of-hooks, react-hooks/exhaustive-deps */
  useEffect(() => {
    // Parse status
    const failed = get(files, '[0].failed');
    if (failed || downloadStatus === 'error') {
      setDownloadStatus('error');
    } else if (failed === false) {
      setDownloadStatus('success');
    }

    // Parse progress
    // We added the `|| downloadProgress` part because on download completion Redux progress was reset to 0, causing the spinner to be shown again
    const progress = get(files, '[0].progress', 0) || downloadProgress;
    setDownloadProgress(progress);
  }, [files, downloadStatus, setDownloadStatus, setDownloadProgress]);
  /* eslint-enable react-hooks/rules-of-hooks, react-hooks/exhaustive-deps */

  if (!modalVisible) return null;

  return (
    <Modal onClickedOutside={hideModal} className="gtg-logs-download-modal">
      <div className="gtg-logs-download-modal-icon">
        <ProgressIcon
          status={downloadStatus}
          progress={downloadProgress}
          useStartupSpinner
        />
      </div>
      <DownloadModalDescription downloadStatus={downloadStatus} />
    </Modal>
  );
};

DeviceLogDownloadModal.propTypes = {
  deviceId: PropTypes.string.isRequired,
  logType: PropTypes.string,
  onDownloadComplete: PropTypes.func.isRequired,
  dispatchDownloadLogs: PropTypes.func.isRequired,
  files: PropTypes.arrayOf(
    PropTypes.shape({
      failed: PropTypes.bool,
      progress: PropTypes.number.isRequired
    })
  )
};

const mapDispatchToProps = {
  dispatchDownloadLogs: downloadLogs
};

const mapStateToProps = state => ({
  files: state.blobDownload.files
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  DeviceLogDownloadModal
);
