import React from 'react';
import { WithLoadingSpinner } from '../../hoc';
import { EmptyState, List, ListItem, uuidv4, TrendsMapper } from '..';
import propTypes from 'prop-types';

import './TrendsList.scss';

const TrendsList = ({ title, data, includeTrends = true, keyType }) => {
  const mappedData = (data && TrendsMapper(data, includeTrends, keyType)) || [];

  return (
    <WithLoadingSpinner when="">
      {title && <div className="mb-2">{title}</div>}
      <List className="gtg-trends-list">
        {(mappedData &&
          mappedData.length > 0 &&
          mappedData.map(trendItem => (
            <ListItem key={uuidv4()}>{Object.values(trendItem)}</ListItem>
          ))) || <EmptyState />}
      </List>
    </WithLoadingSpinner>
  );
};

TrendsList.propTypes = {
  title: propTypes.oneOfType([propTypes.string, propTypes.object]),
  data: propTypes.arrayOf(
    propTypes.shape({
      key: propTypes.string,
      unit: propTypes.string,
      value: propTypes.number,
      trend: propTypes.number
    })
  ),
  includeTrends: propTypes.bool
};

export default TrendsList;
