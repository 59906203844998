import React from 'react';
import { NavLink, Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import { routePaths, GetingeLogo } from '../..';
import styles from './Navbar.module.scss';

const navbar = ({ user }) => {
  const loggedInLinks = (
    <React.Fragment>
      <li className="nav-item">
        <NavLink to="/" exact className="nav-link">
          <FormattedMessage id="common.dashboard" defaultMessage="Dashboard" />
        </NavLink>
      </li>
      <li className="nav-item">
        <NavLink to={'/' + routePaths.DeviceOverview.path} className="nav-link">
          <FormattedMessage
            id="common.installedBase"
            defaultMessage="Installed base"
          />
        </NavLink>
      </li>
      <li className="nav-item">
        <NavLink
          to={'/' + routePaths.DeviceUsageStatistics.path}
          className="nav-link"
        >
          <FormattedMessage
            id="common.usageStatistics"
            defaultMessage="Usage Statistics"
          />
        </NavLink>
      </li>
      <li className="nav-item">
        <NavLink
          to={'/' + routePaths.ServiceOrderOverview.path}
          className="nav-link"
        >
          <FormattedMessage
            id="common.serviceOrders"
            defaultMessage="Service orders"
          />
        </NavLink>
      </li>
      <li className="nav-item">
        <NavLink
          to={'/' + routePaths.PreventiveMaintenanceOverview.path}
          className="nav-link"
        >
          <FormattedMessage
            id="common.preventiveMaintenance"
            defaultMessage="Preventive maintenance"
          />
        </NavLink>
      </li>
      <li className="nav-item">
        <NavLink to="/documentation" className="nav-link disabled">
          <FormattedMessage
            id="navbar.documentation"
            defaultMessage="Documentation"
          />
        </NavLink>
      </li>
    </React.Fragment>
  );

  const NavbarContactButton = () => (
    <button
      className={
        styles.gtgNavbarContactBtn +
        ' btn btn-outline-warning ml-auto disabled invisible'
      }
      type="button"
    >
      <FormattedMessage id="common.contact" defaultMessage="Contact" />
    </button>
  );

  return (
    <nav className={styles.gtgNavbar + ' navbar navbar-expand navbar-dark'}>
      <Link className="navbar-brand" to="/">
        <GetingeLogo />
      </Link>
      {user && (
        <div
          className="collapse navbar-collapse"
          id="navbarsExampleDefault"
          data-cy="navbarMain"
        >
          <ul className="navbar-nav ml-auto">{loggedInLinks}</ul>
          <NavbarContactButton />
        </div>
      )}
    </nav>
  );
};

navbar.propTypes = {
  user: PropTypes.string,
  onLogout: PropTypes.func
};

export default navbar;
