import React from 'react';
import PropTypes from 'prop-types';

import '../Form.scss';

const FormElement = ({ label, errors, postfix, children }) => {
  const hasErrors = Array.isArray(errors) && errors.length > 0;

  return (
    <div className="gtg-form-element" data-cy="formElement">
      {label && <span>{label}</span>}
      <div>
        {children}
        {postfix && <span>{postfix}</span>}
      </div>
      {hasErrors && (
        <ul>
          {errors.map(error => (
            <li key={`error-${error}`}>{error}</li>
          ))}
        </ul>
      )}
    </div>
  );
};

export const commonFormElementPropTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  postfix: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    // Used if you have other form elements that have this and you want them to have the same width
    PropTypes.bool
  ]),
  errors: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.node])
  ),
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  setValue: PropTypes.func
};

FormElement.propTypes = {
  label: commonFormElementPropTypes.label,
  errors: commonFormElementPropTypes.errors,
  postfix: commonFormElementPropTypes.postfix,
  children: PropTypes.node.isRequired
};

export default FormElement;
