import React, { useCallback } from 'react';
import propTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import get from 'lodash.get';
import {
  EmptyState,
  WEEKEND_PREFIX,
  TimeFormat,
  uuidv4,
  AgentKeyMapper,
  AgentColorByKey
} from '..';
import { WithLoadingSpinner } from '../../hoc';
import moment from 'moment';
import {
  FlexibleWidthXYPlot,
  XAxis,
  YAxis,
  VerticalGridLines,
  HorizontalGridLines,
  VerticalBarSeries,
  ChartLabel
} from 'react-vis';
import { metricUsageShape } from '../../utils/proptypes/metricUsageShape';

import './UsageStatisticsServoDetailedBarChart.scss';

const axisStyles = {
  line: { stroke: '#565959', strokeWidth: 1 },
  ticks: { stroke: '#565959' },
  text: { stroke: 'none', fill: '#6b6b76', fontWeight: 600 },
  weekendTextOverride: { fill: '#a6a6ac' }
};

const gridLinesStyles = {
  stroke: 'rgba(86,89,89,.2)',
  strokeWidth: 1
};

const UsageStatisticsServoDetailedBarChart = ({
  metricsUsage,
  dataObj,
  title,
  defaultTitle,
  metricsTimeSpan,
  intl,
  barWidth,
  xAxisSettings
}) => {
  const hasServoDetailedData =
    metricsUsage &&
    metricsUsage[dataObj] &&
    metricsUsage[dataObj].length > 0 &&
    metricsUsage[dataObj].map(metric => {
      metric.data = metric.data.map(dataItem => {
        dataItem.x = '';

        if (
          ['week', 'month'].includes(metricsTimeSpan) &&
          [6, 7].includes(moment(dataItem.xTimeStamp).isoWeekday())
        ) {
          // So that it can be styled differently
          dataItem.x = WEEKEND_PREFIX;
        }

        dataItem.x += intl.formatDate(
          moment(dataItem.xTimeStamp).toDate(),
          TimeFormat(metricsTimeSpan)
        );
        return dataItem;
      });
      return metric;
    });

  const ServoUsageDetailedChartLegend = ({ items, label = 'short' }) => {
    return (
      <div className="gtg-servo-usage-detailed-chart-legend-container">
        <div className="gtg-servo-usage-detailed-chart-legend">
          {items.map(a => (
            <span className={'agent-' + a.servoagent} key={uuidv4()}>
              {AgentKeyMapper(a.servoagent).icon}{' '}
              {(label === 'short' && AgentKeyMapper(a.servoagent).labelShort) ||
                AgentKeyMapper(a.servoagent).label}
            </span>
          ))}
        </div>
      </div>
    );
  };

  return (
    <div className="gtg-usage-statistics-detailed-charts-container">
      <div className="gtg-usage-statistics-detailed-charts-header">
        <FormattedMessage
          id={`usageStatistics.${title}`}
          defaultMessage={defaultTitle}
        />
      </div>

      <WithLoadingSpinner when="usageStatisticsDetailed">
        {(hasServoDetailedData && (
          <div className="gtg-servo-usage-detailed-chart">
            <FlexibleWidthXYPlot
              height={370}
              xType="ordinal"
              stackBy="y"
              align={{ vertical: 'top', horizontal: 'auto' }}
              margin={{ left: 80, right: 30, top: 40, bottom: 60 }}
            >
              <ServoUsageDetailedChartLegend
                items={hasServoDetailedData.filter(
                  metric => metric.year !== 'previous'
                )}
              />

              <VerticalGridLines />
              <HorizontalGridLines style={gridLinesStyles} />
              <XAxis
                style={{
                  ...axisStyles,
                  fontSize: '12px'
                }}
                {...xAxisSettings}
                tickFormat={useCallback(
                  t => {
                    // Apply a different style to X axis label if it is a weekend
                    const isWeekend = t.startsWith(WEEKEND_PREFIX);
                    // Remove the prefix
                    t = t.replace(WEEKEND_PREFIX, '');
                    if (xAxisSettings.displayMonth) {
                      t = t.slice(0, 3);
                    }

                    const dy = xAxisSettings.yPadding
                      ? xAxisSettings.yPadding
                      : '1.3em';
                    return (
                      <tspan
                        style={isWeekend ? axisStyles.weekendTextOverride : {}}
                      >
                        <tspan x="0" dy={dy}>
                          {t}
                        </tspan>
                      </tspan>
                    );
                  },
                  [xAxisSettings.displayMonth, xAxisSettings.yPadding]
                )}
              />
              <YAxis style={axisStyles} />
              <ChartLabel
                text={dataObj !== 'openLungTool' ? 'Total hours' : 'Number of'}
                className="alt-y-label"
                includeMargin={false}
                xPercent={-0.055}
                yPercent={0.65}
                style={{
                  transform: 'rotate(-90)',
                  textAnchor: 'middle'
                }}
              />
              {hasServoDetailedData &&
                hasServoDetailedData.map(bar => (
                  <VerticalBarSeries
                    key={uuidv4()}
                    stack
                    cluster={bar.year.toString()}
                    className={
                      (bar.previousYear && 'previous-year') ||
                      (hasServoDetailedData.length > 1 && 'current-year') ||
                      ''
                    }
                    color={AgentColorByKey(bar.servoagent)}
                    data={bar.data}
                    barWidth={barWidth}
                  />
                ))}
            </FlexibleWidthXYPlot>
          </div>
        )) || <EmptyState />}
      </WithLoadingSpinner>
    </div>
  );
};

UsageStatisticsServoDetailedBarChart.propTypes = {
  metricsUsage: propTypes.shape({
    ventilationType: metricUsageShape,
    patientCategory: metricUsageShape,
    invasiveVentilation: metricUsageShape,
    ediNava: metricUsageShape
  }),
  dataObj: propTypes.string,
  title: propTypes.string,
  defaultTitle: propTypes.string,
  metricsTimeSpan: propTypes.string,
  intl: intlShape,
  barWidth: propTypes.number,
  xAxisSettings: propTypes.shape({
    tickLabelAngle: propTypes.number,
    yPadding: propTypes.string,
    displayMonth: propTypes.bool,
    time: propTypes.string
  })
};

export default injectIntl(UsageStatisticsServoDetailedBarChart);
