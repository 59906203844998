import React from 'react';
import { connect } from 'react-redux';

import { Spinner } from '../../components';
import PropTypes from 'prop-types';

/**
 * Displays a spinner when a request with a matching signature is pending.
 */
const WithLoadingSpinner = ({
  children,
  when = undefined,
  pendingRequests
}) => {
  if (when) {
    const array = Array.isArray(when) ? when : [when];

    if (pendingRequests.find(r => array.includes(r.split('_|_')[0])))
      return <Spinner>{children}</Spinner>;
  }

  return children;
};

WithLoadingSpinner.propTypes = {
  pendingRequests: PropTypes.arrayOf(PropTypes.string),
  when: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string)
  ])
};

const mapStateToProps = state => ({
  pendingRequests: state.api.pendingRequests
});

export default connect(mapStateToProps)(WithLoadingSpinner);
