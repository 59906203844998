export const entity = '[Profile]';
export const FETCH_PROFILE = `${entity} Fetch`;
export const SET_PROFILE = `${entity} Set`;

export const fetchProfile = () => ({
  type: FETCH_PROFILE
});

export const setProfile = data => ({
  type: SET_PROFILE,
  payload: data
});
