import {
  agentUsageTypes,
  trendsTypes,
  metricTypes,
  adoptionMetricTypes,
  averagesTypes,
  ventilationModeTypes,
  caseMetricTypes
} from './';
import {
  WEEKEND_PREFIX,
  formatValueUnit,
  TrendIndicatorCell,
  getDeviceTypeDisplayName,
  Capitalize,
  RunningHoursStatus,
  CassetteMembraneRemainingCapacity,
  mapDeviceAlert,
  secondToHours
} from '../../components';
import { calculateTotalAmount } from '../../components/Case/AgentCostWidgetHelper';

export const transformAgentUsage = data => {
  const exportedData = [];
  data
    .filter(metric => agentUsageTypes.hasOwnProperty(metric.agent))
    .map(metric => {
      // Map each item to columns in config
      if (metric.data.length > 0) {
        metric.data.forEach((item, index) => {
          const usageType = agentUsageTypes[metric.agent];

          if (exportedData.length > index) {
            exportedData[index][usageType] = item.y;
            exportedData[index]['date'] = item.x.replace(WEEKEND_PREFIX, '');
          } else {
            exportedData.push({
              [usageType]: item.y,
              date: item.x.replace(WEEKEND_PREFIX, '')
            });
          }
        });
      }
    });

  return exportedData;
};

export const transformTotalGasConsumption = (data, additional) => {
  const exportedData = [];
  data
    .filter(trend => trendsTypes.hasOwnProperty(trend.key))
    .map(trend => {
      exportedData.push({
        from: additional?.from || '',
        to: additional?.to || '',
        agent: trend.key,
        total: formatValueUnit(trend.value, trend.unit, trend.key).replace(
          /\s/,
          ' '
        ),
        difference: TrendIndicatorCell(trend.trend ? trend.trend : 0).props
          ?.children
      });
    });

  return exportedData;
};

export const transformDeviceAgentUsage = (data, additional) => {
  const {
    department,
    location,
    deviceType,
    serialID,
    customerDeviceID
  } = additional;
  const exportedData = [];
  const departmentName = department?.name;
  data
    .filter(metric => agentUsageTypes.hasOwnProperty(metric.agent))
    .map(metric => {
      // Map each item to columns in config
      if (metric.data.length > 0) {
        metric.data.forEach((item, index) => {
          const usageType = agentUsageTypes[metric.agent];
          if (exportedData.length > index) {
            exportedData[index][usageType] = item.y;
            exportedData[index]['date'] = item.x.replace(WEEKEND_PREFIX, '');
            exportedData[index]['departmentName'] = departmentName;
            exportedData[index]['location'] = location;
            exportedData[index]['deviceType'] = getDeviceTypeDisplayName(
              deviceType
            );
            exportedData[index]['serial'] = serialID;
            exportedData[index]['customerID'] = customerDeviceID;
          } else {
            exportedData.push({
              [usageType]: item.y,
              date: item.x.replace(WEEKEND_PREFIX, ''),
              departmentName: departmentName,
              location: location,
              deviceType: getDeviceTypeDisplayName(deviceType),
              serial: serialID,
              customerID: customerDeviceID
            });
          }
        });
      }
    });
  return exportedData;
};

export const transformDeviceTotalGasConsumption = (data, additional) => {
  const exportedData = [];
  data
    .filter(trend => trendsTypes.hasOwnProperty(trend.key))
    .map(trend => {
      exportedData.push({
        from: additional?.from || '',
        to: additional?.to || '',
        agent: trend.key,
        total: formatValueUnit(trend.value, trend.unit, trend.key).replace(
          /\s/,
          ' '
        ),
        difference: TrendIndicatorCell(trend.trend ? trend.trend : 0).props
          ?.children,
        departmentName: additional.department?.name,
        location: additional.location,
        deviceType: getDeviceTypeDisplayName(additional.deviceType),
        serial: additional.serialID,
        customerID: additional.customerDeviceID
      });
    });

  return exportedData;
};

export const transformDeviceUsageStatistics = (data, additional) => {
  const exportedData = [];
  data.map(device => {
    const exportedDevice = {
      from: additional?.from || '',
      to: additional?.to || '',
      departmentName: device?.department?.name,
      location: device?.location,
      deviceType: getDeviceTypeDisplayName(device?.deviceType),
      serial: device?.serialID,
      customerID: device?.customerDeviceID
    };

    if (device.metrics && device.metrics.length > 0) {
      device.metrics
        .filter(metric => metricTypes.hasOwnProperty(metric.metric))
        .map(({ metric, value }) => {
          exportedDevice[metricTypes[metric]] = value;
        });
    }
    exportedData.push(exportedDevice);
  });

  return exportedData;
};

export const transformDetailedUsageStatistics = (data, additional) => {
  const exportedData = [
    {
      from: additional?.from || '',
      to: additional?.to || '',
      carbonFootprint: data.carbonFootprint
    }
  ];

  data.adoption
    .filter(adoption => adoptionMetricTypes.hasOwnProperty(adoption.label))
    .map(adoption => {
      const usageType = adoptionMetricTypes[adoption.label];
      exportedData[0][usageType] = adoption.value;
    });

  data.ventilationModes
    .filter(mode => ventilationModeTypes.hasOwnProperty(mode.sliceLabel))
    .map(mode => {
      const modeType = ventilationModeTypes[mode.sliceLabel];
      exportedData[0][modeType] = `${mode.slicePercentage}%`;
    });

  data.averages &&
    Object.keys(data.averages)
      .filter(key => averagesTypes.hasOwnProperty(key))
      .reduce((obj, key) => {
        exportedData[0][averagesTypes[key]] = data.averages[key];
      }, {});

  return exportedData;
};

const getDataValue = (labelName, data) => {
  let res = data
    .filter(item => item.label === labelName)
    .map(item => item.value);
  return res[0];
};

export const transformDetailedUsageStatisticsPerDevice = (data, additional) => {
  let exportedData = [];
  data.map(rowData => {
    let exportedDevice = {
      from: additional?.from || '',
      to: additional?.to || '',
      serial: rowData?.serialID,
      departmentName: rowData?.department?.name,
      cases: rowData?.averages?.casesTotal,
      agentCost: rowData?.averages?.costPerCase,
      carbonFootprint: rowData?.carbonFootprint,
      agc: rowData?.averages?.casesAgc,
      deviceType: rowData?.deviceType,
      afgo: getDataValue('AFGO', rowData?.ventilationModes),
      manual: getDataValue('Manual', rowData?.ventilationModes),
      pc: getDataValue('PC', rowData?.ventilationModes),
      pcps: getDataValue('PCPS', rowData?.ventilationModes),
      prvc: getDataValue('PRVC', rowData?.ventilationModes),
      ps: getDataValue('PS', rowData?.ventilationModes),
      psbackup: getDataValue('PSBackup', rowData?.ventilationModes),
      vc: getDataValue('VC', rowData?.ventilationModes),
      vcps: getDataValue('VCPS', rowData?.ventilationModes)
    };

    rowData.ventilationModes.map(mode => {
      exportedDevice[mode.label] = `${mode.value}%`;
    });
    exportedData.push(exportedDevice);
  });
  return exportedData;
};

const mappedVentilationModes = (data, dataObj) => {
  return data[dataObj].reduce((object, item) => {
    object[item.label.toLowerCase()] = item.value;
    return object;
  }, {});
};

function ventilationModesDataMapper(data) {
  return (
    (data instanceof Object &&
      Object.keys(data).map((key, i) => {
        // calculate midpoint value of current slice
        const currentTotal =
          Object.values(data)
            .slice(0, i)
            .reduce((a, b) => a + b, 0) +
          Object.values(data)[i] / 2;
        // total (represents 100% or 360deg)
        const valuesTotal = Object.values(data).reduce((a, b) => a + b, 0);
        // current slice as percentage of total
        const slicePercentage = Math.round((data[key] / valuesTotal) * 100);
        // return mapped label if it exists
        const sliceLabel = key;
        return {
          sliceLabel: sliceLabel,
          slicePercentage: slicePercentage
        };
      })) ||
    []
  );
}

export const transformServoUsageStatisticsOverview = (data, additional) => {
  let exportedData = [];
  const formattedDataForPatientCategory = (data, categoryName) => {
    return ventilationModesDataMapper(
      mappedVentilationModes(data, categoryName)
    );
  };

  let exportedDevice = {
    from: additional?.from || '',
    to: additional?.to || '',
    neonatalHrsOfVentilation: data?.patientCategory[0]
      ? secondToHours(data?.patientCategory[0].value)
      : '',
    pediatricHrsOfVentilation: data?.patientCategory[1]
      ? secondToHours(data?.patientCategory[1].value)
      : data?.patientCategory.length === 1
      ? secondToHours(data?.patientCategory[0].value)
      : '',
    adultHrsOfVentilation: data?.patientCategory[2]
      ? secondToHours(data?.patientCategory[2].value)
      : data?.patientCategory.length === 1
      ? secondToHours(data?.patientCategory[0].value)
      : '',
    neonatalPatientCategoryUtilization: data?.patientCategory[0]
      ? `${
          formattedDataForPatientCategory(data, 'patientCategory')[0]
            .slicePercentage
        }%`
      : '',
    pediatricPatientCategoryUtilization: data?.patientCategory[1]
      ? `${
          formattedDataForPatientCategory(data, 'patientCategory')[1]
            .slicePercentage
        }%`
      : '',
    adultPatientCategoryUtilization: data?.patientCategory[2]
      ? `${
          formattedDataForPatientCategory(data, 'patientCategory')[2]
            .slicePercentage
        }%`
      : '',
    unutilizedPatientCategoryUtilization: data?.patientCategory[3]
      ? `${
          formattedDataForPatientCategory(data, 'patientCategory')[3]
            .slicePercentage
        }%`
      : '',
    invasiveVentilationUtilization: data?.ventilationType[0]
      ? `${
          formattedDataForPatientCategory(data, 'ventilationType')[0]
            .slicePercentage
        }%`
      : '',
    hfoVentilationUtilization: data?.ventilationType[1]
      ? `${
          formattedDataForPatientCategory(data, 'ventilationType')[1]
            .slicePercentage
        }%`
      : '',
    highFlowVentilationUtilization: data?.ventilationType[2]
      ? `${
          formattedDataForPatientCategory(data, 'ventilationType')[2]
            .slicePercentage
        }%`
      : '',
    nonInvasiveVentilationUtilization: data?.ventilationType[3]
      ? `${
          formattedDataForPatientCategory(data, 'ventilationType')[3]
            .slicePercentage
        }%`
      : '',
    navaVentilationUtilization: data?.ventilationType[4]
      ? `${
          formattedDataForPatientCategory(data, 'ventilationType')[4]
            .slicePercentage
        }%`
      : '',
    controlledModeInvasiveVentilationUtilization: data?.ventilationType[0]
      ? `${
          formattedDataForPatientCategory(data, 'invasiveVentilation')[0]
            .slicePercentage
        }%`
      : '',
    supportedModeInvasiveVentilationUtilization: data?.ventilationType[1]
      ? `${
          formattedDataForPatientCategory(data, 'invasiveVentilation')[1]
            .slicePercentage
        }%`
      : '',
    interactiveModeInvasiveVentilationUtilization: data?.ventilationType[2]
      ? `${
          formattedDataForPatientCategory(data, 'invasiveVentilation')[2]
            .slicePercentage
        }%`
      : '',
    ediMonitoringEdiNavaUtilization: data?.ventilationType
      ? `${
          formattedDataForPatientCategory(data, 'ediNava')[0].slicePercentage
        }%`
      : '',
    navaEdiNavaUtilization: data?.ventilationType
      ? `${
          formattedDataForPatientCategory(data, 'ediNava')[1].slicePercentage
        }%`
      : '',
    nivNavaEdiNavaUtilization: data?.ventilationType
      ? `${
          formattedDataForPatientCategory(data, 'ediNava')[2].slicePercentage
        }%`
      : '',
    openLungToolAutoSrm: data.openLungTool[0] ? data.openLungTool[0].value : '',
    openLungToolAutoRm: data.openLungTool[1] ? data.openLungTool[1].value : ''
  };

  exportedData.push(exportedDevice);
  return exportedData;
};

export const transformRunningHours = (data, additional, intl) => {
  return data.map(device => {
    return {
      departmentName: device?.department?.name,
      deviceType: getDeviceTypeDisplayName(device?.deviceType),
      serial: device?.serialID,
      customerID: device?.customerDeviceID,
      totalHours: device?.runningHours,
      lastConnected: convertDateTime(device?.lastLog, intl),
      location: device?.location,
      from: additional?.from || '',
      to: additional?.to || ''
    };
  });
};

export const transformCaseList = (data, additional, intl) => {
  const exportedData = [];
  data.map(item => {
    const exportedCase = {
      deviceType: getDeviceTypeDisplayName(item?.deviceType),
      serial: additional?.serialID,
      date: intl.formatDate(item?.start),
      start: convertTime(item?.start, intl),
      end: convertTime(item?.end, intl),
      casesAgc: item?.agc ? 'Yes' : 'No'
    };

    if (item.metrics && item.metrics.length > 0) {
      item.metrics
        .filter(metric => caseMetricTypes.hasOwnProperty(metric.key))
        .map(({ key, value }) => {
          exportedCase[caseMetricTypes[key]] = value;
        });
    }
    exportedData.push(exportedCase);
  });
  return exportedData;
};

export const transformServiceOrdersList = (data, intl) => {
  const exportedData = [];
  data.map(item => {
    const departmentName = item?.department?.name;
    const exportedServiceOrder = {
      status: Capitalize(item?.status.toLowerCase()),
      departmentName: departmentName,
      deviceType: getDeviceTypeDisplayName(item?.deviceType),
      serial: item?.serialID,
      type: item?.type,
      created: convertDate(item?.created, intl),
      order: item?.number,
      description: item?.description,
      technician: item?.technician,
      notification: item?.notification
    };
    exportedData.push(exportedServiceOrder);
  });
  return exportedData;
};

export const transformDeviceCaseTimeline = (data, intl) => {
  const exportedData = [];
  const filterMacYObj = data?.timeline?.filter(item => item.key === 'MacY');
  const filterFgfObj = data?.timeline?.filter(
    item => item.key === 'FreshGasFlow'
  );
  const filterMacBrainObj = data?.timeline?.filter(
    item => item.key === 'MacBrain'
  );

  data.agentUsage.map((item, index) => {
    const exportedDeviceCaseTimeline = {
      departmentName: data?.department?.name,
      location: data?.location,
      deviceType: data?.deviceType,
      serial: data?.serialID,
      customerDeviceNum: data?.customerDeviceID,
      startCase: convertDateTime(data?.start, intl),
      endCase: convertDateTime(data?.end, intl),
      timestamp: convertDateTime(item?.timestamp, intl),
      sev: item?.agent.toLowerCase() === 'sev' ? item?.value : '',
      des: item?.agent.toLowerCase() === 'des' ? item?.value : '',
      iso: item?.agent.toLowerCase() === 'iso' ? item?.value : '',
      fgf: filterFgfObj[0].values[index].value,
      macy: filterMacYObj[0].values[index].value,
      macBrain: filterMacBrainObj[0].values[index].value
    };
    exportedData.push(exportedDeviceCaseTimeline);
  });
  return exportedData;
};

export const transformPreventiveMaintenanceList = (data, intl) => {
  const exportedData = [];
  data.map(item => {
    const departmentName = item.device?.department?.name;
    const batteries = {};
    for (let i = 0; i < item.batteries?.length; i++) {
      batteries[`battery${i + 1}Serial`] = item.batteries[i].serialNumber;
      batteries[`battery${i + 1}Status`] = mapPMStatus(
        item.batteries[i].status
      );
      batteries[`battery${i + 1}Next`] = convertDate(
        item.batteries[i].nextDate,
        intl
      );
    }

    exportedData.push({
      status: mapPMStatus(item.status),
      departmentName: departmentName,
      location: item.device?.location,
      deviceType: getDeviceTypeDisplayName(item.device?.type),
      serial: item.device?.serialID,
      customerID: item.device?.customerDeviceID,
      nextDue: item.nextDue,
      last: convertDate(item.last, intl),
      next: convertDate(item.next, intl),
      lastType: item.lastType,
      cassetteMembraneStatus: mapPMStatus(item.cassetteMembrane?.status),
      cassetteMembraneLast: convertDate(item.cassetteMembrane?.last, intl),
      cassetteMembraneStrokes: CassetteMembraneRemainingCapacity(
        item.cassetteMembrane?.strokes
      ),
      runningHoursSinceStatus: mapPMStatus(
        RunningHoursStatus(item.runningHoursSince, item.maxRunningHours)
      ),
      runningHoursSinceLastNext: `${item.runningHoursSince}/${item.maxRunningHours}h`,
      memoryBackupStatus: mapPMStatus(item.battery?.memoryBackup?.status),
      memoryBackupLast: convertDate(item.battery?.memoryBackup?.last, intl),
      memoryBackupNext: convertDate(item.battery?.memoryBackup?.next, intl),
      powerBackupStatus: mapPMStatus(item.battery?.powerBackup?.status),
      powerBackupLast: convertDate(item.battery?.powerBackup?.last, intl),
      powerBackupNext: convertDate(item.battery?.powerBackup?.next, intl),
      ...batteries
    });
  });
  return exportedData;
};

export const transformInstalledBaseList = (data, intl) => {
  const exportedData = [];
  data.map(item => {
    const departmentName = item?.department?.name;
    const deviceAlert = mapDeviceAlert(item?.deviceType, item?.status?.status)
      ?.defaultMessage;
    const exportedInstalledBase = {
      alert: deviceAlert || 'Pass',
      departmentName: departmentName,
      deviceType: getDeviceTypeDisplayName(item?.deviceType),
      serial: item?.serialID,
      customerID: item?.customerDeviceID,
      swVersion: item?.systemSoftwareVersion,
      next: convertDate(item?.nextPreventativeMaintenance, intl),
      lastConnected: convertDate(item?.lastLog, intl),
      subscription: item?.subscription
    };
    exportedData.push(exportedInstalledBase);
  });
  return exportedData;
};

export const transformUsageStatisticsServoDetailed = data => {
  const exportedData = [];
  data.map(metric => {
    // Map each item to columns in config
    if (metric.data.length > 0) {
      metric.data.forEach((item, index) => {
        const usageType = metric.servoagent;
        if (exportedData.length > index) {
          exportedData[index][usageType] = item?.y?.toFixed(1);
          exportedData[index]['date'] = item.x.replace(WEEKEND_PREFIX, '');
        } else {
          exportedData.push({
            [usageType]: item?.y?.toFixed(1),
            date: item.x.replace(WEEKEND_PREFIX, '')
          });
        }
      });
    }
  });
  return exportedData;
};

const filteredAgentUsage = caseInfo => {
  const displayMetrics = ['sev', 'iso', 'des'];
  return caseInfo?.metrics
    ?.filter(metric => displayMetrics.includes(metric.key))
    .slice()
    .sort(function(a, b) {
      return displayMetrics.indexOf(a.key) - displayMetrics.indexOf(b.key);
    });
};

const getTotalUsageCost = (data, costs) => {
  const calculatedCost = filteredAgentUsage(data)
    .reduce((total, metric) => total + (data.costs[metric.key] || 0), 0)
    .toFixed(2);
  const symbol = costs ? costs.currency.symbol : '';

  return formatValueUnit(calculatedCost, symbol, 'currency');
};

export const transformCaseDetails = (data, additional) => {
  const exportedData = [];
  exportedData.push({
    departmentName: additional?.device?.department?.name,
    location: additional?.device?.location,
    deviceType: getDeviceTypeDisplayName(additional?.device?.deviceType),
    serialID: additional?.device?.serialID,
    customerDeviceID: additional?.device?.customerDeviceID,
    startCase: data.start,
    endCase: data.end
  });
  ventilationModesDataMapper(data.ventilation)?.forEach(item => {
    exportedData[0][item?.sliceLabel] = `${item?.slicePercentage}%`;
  });
  data?.averages?.forEach(item => {
    exportedData[0][item?.key] = formatValueUnit(
      item?.value,
      item?.unit,
      item?.key
    );
  });
  filteredAgentUsage(data)?.forEach(metric => {
    exportedData[0][`${metric.key}Usage`] = formatValueUnit(
      metric?.value,
      metric?.unit,
      metric?.key
    );
    exportedData[0][`${metric.key}Cost`] = formatValueUnit(
      additional?.costs[metric.key],
      additional?.costs?.currency
        ? `${additional?.costs?.currency?.symbol}/L`
        : ' ',
      'currency'
    );
    exportedData[0][`${metric.key}CostPerLiter`] = formatValueUnit(
      data?.costs[metric.key],
      data?.costs?.currency?.symbol,
      'currency'
    );
  });
  exportedData[0]['totalUsage'] = calculateTotalAmount(
    filteredAgentUsage(data)
  );
  exportedData[0]['totalUsageCost'] = getTotalUsageCost(
    data,
    additional?.costs
  );
  exportedData[0]['RM'] = data.recruitmentManeuver ? 'Yes' : 'No';
  return exportedData;
};

export const transformCaseDetailsGasConsumption = (data, additional) => {
  const exportedData = [];
  exportedData.push({
    departmentName: additional?.device?.department?.name,
    location: additional?.device?.location,
    deviceType: getDeviceTypeDisplayName(additional?.device?.deviceType),
    serialID: additional?.device?.serialID,
    customerDeviceID: additional?.device?.customerDeviceID,
    startCase: additional?.caseStart,
    endCase: additional?.caseEnd
  });
  data?.forEach(item => {
    exportedData[0][item?.key] = formatValueUnit(
      item?.value,
      item?.unit,
      item?.key
    );
  });

  return exportedData;
};

export const mapPMStatus = status => {
  if (status == undefined) {
    return '';
  }

  switch (status.toLowerCase()) {
    case 'alert':
      return 'Overdue';
    case 'warning':
      return 'Upcoming';
    case 'ok':
      return 'OK';
    default:
      return status;
  }
};

export const convertTime = (time, intl) => {
  return time == undefined
    ? 'N/A'
    : `${intl?.formatTime(time, {
        hour: 'numeric',
        minute: 'numeric',
        hour12: false
      })}`;
};

export const convertDate = (date, intl) => {
  return date == undefined ? 'N/A' : `${intl?.formatDate(date)}`;
};

export const convertDateTime = (timestamp, intl) => {
  return timestamp == undefined
    ? 'N/A'
    : `${convertDate(timestamp, intl)} ${convertTime(timestamp, intl)}`;
};
