export const entity = '[Device status]';
export const FETCH_DEVICE_STATUS = `${entity} Fetch`;
export const SET_DEVICE_STATUS = `${entity} Set`;

export const fetchDeviceStatus = id => ({
  type: FETCH_DEVICE_STATUS,
  payload: {
    id: id
  }
});

export const setDeviceStatus = data => ({
  type: SET_DEVICE_STATUS,
  payload: data
});
