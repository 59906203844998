import { SET_LOG_TYPES, FETCH_LOG_TYPES } from '../actions/logTypes';

const initState = {
  logTypes: null
};

export const logTypesReducer = (state = initState, action) => {
  const { type } = action;

  switch (type) {
    case FETCH_LOG_TYPES:
      return {
        ...state,
        logTypes: null
      };
    case SET_LOG_TYPES:
      return {
        ...state,
        logTypes: action.payload
      };
    default:
      return state;
  }
};
