import React, { useCallback } from 'react';
import classnames from 'classnames';
import { FormattedMessage, FormattedDate } from 'react-intl';
import {
  DataTable,
  EmptyState,
  Formatted24hTime,
  Icon,
  CaseMetrics,
  formatValueUnit,
  DataTableFixedColumnHeader,
  CarbonMetric
} from '..';
import { Link } from 'react-router-dom';
import get from 'lodash.get';

const CaseList = ({
  cases,
  showPagination,
  manualPaginationSettings,
  device,
  setData,
  currentPageExtra,
  config
}) => {
  const getMetricColumn = metric => {
    if (metric.hideColumnIfNoData && cases && cases.items) {
      const metricHasNoData = cases.items.every(
        // Check that every case for this metric has no data
        item =>
          item.metrics.find(itemMetric => itemMetric.key === metric.key) ===
          undefined
      );

      if (metricHasNoData) return undefined;
    }

    if (
      metric.key === 'carbon_footprint' &&
      !get(config, 'enableSubscriptions', false)
    ) {
      return undefined;
    }

    const column = {
      Header:
        // Use header text if available
        (metric.key && metric.headerText && (
          <FormattedMessage
            id={`column.metric${metric.key}`}
            defaultMessage={metric.headerText}
          />
        )) ||
        // Use Header component if available
        (metric.Header && metric.Header()) ||
        console.error(
          `Metric '${metric.key}' should have either 'headerText' or 'Header' properties.`
        ),
      Cell: ({ original }) => {
        const data = original.metrics.find(item => item.key === metric.key);

        // Don't display anything if no data
        if (!data || !data.value || !data.unit) return null;

        let { value, unit } = data;

        return formatValueUnit(value, unit, metric.key);
      },
      accessor: metric.key,
      headerClassName: 'text-right',
      className: 'text-right'
    };

    return column;
  };

  // Either returns a column if there are some values to display or null if the column should be hidden
  const getAGCColumn = useCallback(() => {
    if (!cases || !cases.items) return {};

    const haveSomeValues = cases.items.some(caseItem =>
      [false, true].includes(caseItem.agc)
    );

    // Hide the column if there are no boolean values
    if (!haveSomeValues) return null;

    return {
      Header: <FormattedMessage id="case.agc" defaultMessage="AGC" />,
      accessor: 'agc',
      Cell: ({ original }) => {
        switch (original.agc) {
          case true:
            return <FormattedMessage id="common.yes" defaultMessage="Yes" />;
          case false:
            return <FormattedMessage id="common.no" defaultMessage="No" />;
          default:
            return (
              <FormattedMessage
                id="common.notAvailableAbbr"
                defaultMessage="N/A"
              />
            );
        }
      }
    };
  }, [cases]);

  const columns = [
    {
      Header: <FormattedMessage id="column.date" defaultMessage="Date" />,
      accessor: 'start',
      Cell: ({ original }) => <FormattedDate value={original.start} />
    },
    {
      Header: (
        <FormattedMessage id="column.startCase" defaultMessage="Start case" />
      ),
      accessor: 'start',
      Cell: ({ original }) => <Formatted24hTime value={original.start} />,
      sortable: false
    },
    {
      Header: (
        <FormattedMessage id="column.endCase" defaultMessage="End case" />
      ),
      accessor: 'End',
      Cell: ({ original }) => <Formatted24hTime value={original.end} />,
      sortable: false
    },
    ...CaseMetrics.map(getMetricColumn).filter(column => column),
    getAGCColumn(),
    getMetricColumn(CarbonMetric),
    device &&
      device.systemSoftwareVersion > '04.01.00' && {
        accessor: 'id',
        Cell: ({ original }) => {
          // Don't show the link if we can't show the page
          return original.hasDetails ? (
            <Link to={`cases/${original.id}`}>
              <FormattedMessage
                id="column.viewMore"
                defaultMessage="View more"
              />
              <Icon name="arrow-right-full" size="sm" />
            </Link>
          ) : null;
        },
        width: 110
      }
  ];

  React.useEffect(() => {
    if (setData) {
      setData(cases && cases.items);
    }
  }, [setData, cases]);

  return (
    <React.Fragment>
      {(cases && cases.items && cases.items.length > 0 && (
        <DataTableFixedColumnHeader
          defaultPageSize={10}
          manualPaginationSettings={manualPaginationSettings}
          showPagination={showPagination}
          className={classnames('mb-3')}
          data={cases.items}
          columns={columns.filter(column => column)}
          selectable={false}
          currentPageExtra={currentPageExtra}
        />
      )) || <EmptyState />}
    </React.Fragment>
  );
};

export default CaseList;
