import {
  FETCH_DEVICES_RUNNING_HOURS,
  setDevicesRunningHours,
  entity
} from '../actions/devicesRunningHours';
import { apiRequest, API_SUCCESS } from '../actions/api';
import { API_URLS } from './urls';
import { deviceFamilies, getStartAndEndData } from '../../components';

const URL = API_URLS.DevicesRunningHours;

export const devicesRunningHoursMdl = ({ dispatch }) => next => action => {
  next(action);

  const { type, pagination, sorting, timeSpan, count } = action;
  const { start, end } = getStartAndEndData(undefined, timeSpan, count);
  switch (type) {
    case FETCH_DEVICES_RUNNING_HOURS:
      dispatch(
        apiRequest({
          method: 'GET',
          url:
            URL.url +
              '?page=' +
              (pagination.page + 1) +
              '&pageSize=' +
              pagination.pageSize +
              '&sort=' +
              (sorting || '-runningHours') +
              '&start=' +
              start.format() +
              (end && '&end=' + end.format()) || '',
          requestSignature: 'devicesRunningHours',
          entity: entity,
          filter: URL.filter
        })
      );

      break;
    case `${entity} GET ${API_SUCCESS}`:
      action.payload.data = {
        ...action.payload.data,
        maxRunningHours:
          Math.round(action.payload.data.maxRunningHours * 100) / 100,
        items: (action.payload.data.items || []).map(item => ({
          ...item,
          runningHours: Math.round(item.runningHours * 100) / 100
        })),
        pagination: {
          ...action.payload.data.pagination,
          page: action.payload.data.pagination.page - 1
        }
      };
      dispatch(setDevicesRunningHours(action.payload.data));
      break;
    // no default
  }
};
