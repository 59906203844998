export const entity = '[Documents]';
export const FETCH_DOCUMENTS = `${entity} Fetch`;
export const SET_DOCUMENTS = `${entity} Set`;

/**
 * Trigger fetch of documents
 */
export const fetchDocuments = deviceId => ({
  type: FETCH_DOCUMENTS,
  payload: { deviceId }
});

/**
 * Set documents
 * @param {array} data New documents
 */
export const setDocuments = data => ({
  type: SET_DOCUMENTS,
  payload: data
});
