import {
  FETCH_METRICS_DETAILED,
  setMetricsDetailed,
  entity
} from '../actions/metricsDetailed';
import { API_SUCCESS, apiRequest } from '../actions/api';
import { Metrics, MetricsTimeParams } from '../../components/';
import { API_URLS } from './urls';

const URL = API_URLS.MetricsDetailed;

function transformResponse(data) {
  return data?.map(device => {
    device.metrics = device.metrics?.columns.map((column, i) => {
      return {
        metric: column.name,
        unit: column.unit,
        value: Math.round(device.metrics.values[i] * 100) / 100
      };
    });
    return device;
  });
}

export const metricsDetailedMdl = ({ dispatch }) => next => action => {
  next(action);

  const { type } = action;

  switch (type) {
    case FETCH_METRICS_DETAILED:
      setMetricsDetailed([]);
      const { timeSpan, count } = action.payload;
      const metrics =
        '&metrics=' +
        [
          ...Metrics.filter(agent => agent.type === 'agent').map(
            agent => agent.key
          ),
          ...Metrics.filter(agent => agent.type === 'gas').map(
            agent => agent.key
          ),
          'Cases',
          'FreshGasFlow'
        ].join(',');

      dispatch(
        apiRequest({
          method: 'GET',
          url:
            URL.url +
            MetricsTimeParams('metricsDetailed', timeSpan, count) +
            metrics,
          requestSignature: 'metricsDetailed',
          entity: entity,
          filter: URL.filter
        })
      );

      break;
    case `${entity} GET ${API_SUCCESS}`:
      dispatch(
        setMetricsDetailed(
          (action.payload.data &&
            transformResponse(action.payload.data.items)) ||
            []
        )
      );
      break;
    // no default
  }
};
