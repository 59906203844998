import { apiRequest, API_SUCCESS } from '../actions/api';
import {
  FETCH_ACCOUNTS,
  FETCH_DEPARTMENTS,
  FETCH_DEVICE_TYPES,
  setAccounts,
  setDepartments,
  setDeviceTypes,
  entityAccounts,
  entityDepartments,
  entityDeviceTypes
} from '../actions/deviceFilters';
import {
  transformAccounts,
  transformDeviceTypes,
  sortByName
} from '../../utils/transform';
import { API_URLS } from './urls';

const URL = API_URLS.Api;
const ACCOUNTS_URL = API_URLS.Accounts;

const transformResponse = data => {
  return data.map(entity => {
    return {
      id: entity.id,
      label: entity.name
    };
  });
};

export const deviceFiltersMdl = ({ dispatch }) => next => action => {
  next(action);

  const { type } = action;

  switch (type) {
    case FETCH_ACCOUNTS:
      const ACCOUNTS_QUERY_PARAMS = action.searchStr
        ? `?search=${action.searchStr}`
        : '';
      dispatch(
        apiRequest({
          method: 'GET',
          url: `${URL.url}/accounts${ACCOUNTS_QUERY_PARAMS}`,
          requestSignature: 'accounts',
          entity: entityAccounts
        })
      );
      break;
    case FETCH_DEPARTMENTS:
      dispatch(
        apiRequest({
          method: 'GET',
          url: `${ACCOUNTS_URL.url}/${action.id}/departments`,
          requestSignature: 'departments',
          entity: entityDepartments
        })
      );
      break;
    case FETCH_DEVICE_TYPES:
      dispatch(
        apiRequest({
          method: 'GET',
          url: `${ACCOUNTS_URL.url}/${action.id}/device-types`,
          requestSignature: 'deviceTypes',
          entity: entityDeviceTypes
        })
      );
      break;
    case `${entityAccounts} GET ${API_SUCCESS}`:
      dispatch(
        setAccounts(
          action.payload.data &&
            transformAccounts(sortByName(action.payload.data.items))
        ) || []
      );
      break;
    case `${entityDepartments} GET ${API_SUCCESS}`:
      dispatch(
        setDepartments(
          action.payload.data && transformResponse(action.payload.data.items)
        ) || []
      );
      break;
    case `${entityDeviceTypes} GET ${API_SUCCESS}`:
      dispatch(
        setDeviceTypes(
          action.payload.data && transformDeviceTypes(action.payload.data.items)
        ) || []
      );
      break;
    // no default
  }
};
