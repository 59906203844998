import { combineReducers } from 'redux';
import { devicesReducer } from './devices';
import { devicesOverviewReducer } from './devicesOverview';
import { deviceDetailsReducer } from './device';
import { testsReducer } from './tests';
import { testReducer } from './test';
import { uiReducer } from './ui';
import { authorizationReducer } from './authorization';
import { apiReducer } from './api';
import { deviceFiltersReducer } from './deviceFilters';
import { deviceStatusReducer } from './deviceStatus';
import { logTypesReducer } from './logTypes';
import { logsReducer } from './logs';
import { documentsReducer } from './documents';
import { blobDownloadReducer } from './blobDownload';
import { serviceOrdersReducer } from './serviceOrders';
import { serviceOrdersOverviewReducer } from './serviceOrdersOverview';
import { metricsReducer } from './metrics';
import { metricsDetailedReducer } from './metricsDetailed';
import { metricsUsageReducer } from './metricsUsage';
import { metricsDetailedPerDeviceReducer } from './metricsDetailedPerDevice';
import { trendsReducer } from './trends';
import { filtersReducer } from './filters';
import { troubleshootingGuidanceReducer } from './troubleshootingGuidance';
import { persistentStateReducer } from './persistentState';
import { paginationHistoryReducer } from './paginationHistory';
import { preventiveMaintenanceReducer } from './preventiveMaintenance';
import { devicePreventiveMaintenanceReducer } from './devicePreventiveMaintenance';
import { legalNoticeReducer } from './legalNotice';
import { reducer as oidcReducer } from 'redux-oidc';
import { connectRouter } from 'connected-react-router';
import { casesReducer } from './cases';
import { caseReducer } from './case';
import { profileReducer } from './profile';
import { costsReducer } from './costs';
import { currencyReducer } from './currency';
import { configReducer } from './config';
import { devicesRunningHoursReducer } from './devicesRunningHours';
import { accessInfoReducer } from './accessInfo';
import { accountReducer } from './account';
import { adminReducer } from './admin';
import { softwareVersionsReducer } from './softwareVersions';
import { usageStatisticsReducer } from './usageStatistics';
import { usageStatisticsUtilizationReducer } from './usageStatisticsUtilization';
import { usageStatisticsDetailedReducer } from './usageStatisticsDetailed';
import { connectedAccessoriesReducer } from './connectedAccessories';

const createRootReducer = history =>
  combineReducers({
    config: configReducer,
    ui: uiReducer,
    filters: filtersReducer,
    devices: devicesReducer,
    devicesStatusOverview: devicesOverviewReducer,
    devicesRunningHours: devicesRunningHoursReducer,
    deviceDetails: deviceDetailsReducer,
    deviceFilters: deviceFiltersReducer,
    deviceStatus: deviceStatusReducer,
    authorization: authorizationReducer,
    api: apiReducer,
    tests: testsReducer,
    test: testReducer,
    logTypes: logTypesReducer,
    logs: logsReducer,
    documents: documentsReducer,
    blobDownload: blobDownloadReducer,
    serviceOrders: serviceOrdersReducer,
    serviceOrdersOverview: serviceOrdersOverviewReducer,
    metrics: metricsReducer,
    metricsDetailed: metricsDetailedReducer,
    metricsUsage: metricsUsageReducer,
    metricsDetailedPerDevice: metricsDetailedPerDeviceReducer,
    trends: trendsReducer,
    troubleshootingGuidance: troubleshootingGuidanceReducer,
    persistentState: persistentStateReducer,
    paginationHistory: paginationHistoryReducer,
    preventiveMaintenance: preventiveMaintenanceReducer,
    devicePreventiveMaintenance: devicePreventiveMaintenanceReducer,
    legalNotice: legalNoticeReducer,
    cases: casesReducer,
    case: caseReducer,
    costs: costsReducer,
    currency: currencyReducer,
    profile: profileReducer,
    oidc: oidcReducer,
    accessInfo: accessInfoReducer,
    account: accountReducer,
    router: connectRouter(history),
    admin: adminReducer,
    softwareVersions: softwareVersionsReducer,
    usageStatistics: usageStatisticsReducer,
    usageStatisticsUtilization: usageStatisticsUtilizationReducer,
    usageStatisticsDetailed: usageStatisticsDetailedReducer,
    connectedAccessories: connectedAccessoriesReducer
  });

export default createRootReducer;
