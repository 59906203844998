import React, { useState, useCallback, useRef, useEffect } from 'react';
import './CaseUtilizationCharts.scss';
import propTypes from 'prop-types';
import { RadialChart, Hint } from 'react-vis';
import colorVariables from '../../scss/_variables.module.scss';
import { secondToHours } from '..';

const colorsData = {
  ventilationType: {
    invasive: {
      label: 'Invasive',
      color: colorVariables['colors-chart--color-chart-blue-5']
    },
    hfo: {
      label: 'HFO',
      color: colorVariables['colors-chart--color-chart-blue-6']
    },
    'high-flow': {
      label: 'High Flow',
      color: colorVariables['colors-chart--color-chart-green-3']
    },
    nava: {
      label: 'NAVA',
      color: colorVariables['colors-chart--color-chart-plum-1']
    },
    'non-invasive': {
      label: 'Non Invasive',
      color: colorVariables['colors-chart--color-chart-yellow-1']
    }
  },
  ediNava: {
    'edi-monitoring': {
      label: 'Edi monitoring',
      color: colorVariables['colors-chart--color-chart-plum-2']
    },
    'edi-niv-nava': {
      label: 'NIV NAVA',
      color: colorVariables['colors-chart--color-chart-plum-1']
    },
    'edi-nava': {
      label: 'NAVA',
      color: colorVariables['colors-chart--color-chart-plum-3']
    }
  },
  invasiveVentilation: {
    controlled: {
      label: 'Controlled Modes',
      color: colorVariables['colors-chart--color-chart-blue-6']
    },
    supported: {
      label: 'Supported Modes',
      color: colorVariables['colors-chart--color-chart-blue-7']
    },
    interactive: {
      label: 'Interactive Modes',
      color: colorVariables['colors-chart--color-chart-blue-5']
    }
  },
  patientCategory: {
    adult: {
      label: 'Adult',
      color: colorVariables['colors-chart--color-chart-blue-8']
    },
    pediatric: {
      label: 'Pediatric',
      color: colorVariables['colors-chart--color-chart-blue-5']
    },
    neonatal: {
      label: 'Neonatal',
      color: colorVariables['colors-chart--color-chart-green-1']
    },
    unutilized: {
      label: 'Unutilized',
      color: colorVariables['colors-chart--color-chart-gray-3']
    }
  }
};

function ventilationModesDataMapper(data, outerRadius, labelRadius, dataObj) {
  const colorMapper = colorsData[dataObj];
  return (
    (data instanceof Object &&
      Object.keys(data).map((key, i) => {
        // calculate midpoint value of current slice
        const currentTotal =
          Object.values(data)
            .slice(0, i)
            .reduce((a, b) => a + b, 0) +
          Object.values(data)[i] / 2;
        // total (represents 100% or 360deg)
        const valuesTotal = Object.values(data).reduce((a, b) => a + b, 0);
        // angle in radians from origin to midpoint of the current slice
        const rad = (currentTotal / valuesTotal) * 2 * Math.PI;
        // current slice as percentage of total
        const slicePercentage = Math.round((data[key] / valuesTotal) * 100);
        // return mapped label if it exists
        const sliceLabel = colorMapper[key] ? colorMapper[key].label : key;
        return {
          sliceLabel: sliceLabel,
          slicePercentage: slicePercentage,
          angle: data[key],
          color: colorMapper[key] && colorMapper[key].color,
          // calculate xy coordinates for label position from chart center
          labelX: Math.sin(rad) * labelRadius * 1,
          labelY: Math.cos(rad) * labelRadius * -1,
          // calculate label arrow dimensions
          labelArrowX: Math.sin(rad) * (labelRadius - outerRadius),
          labelArrowY: Math.cos(rad) * (labelRadius - outerRadius) * -1
        };
      })) ||
    []
  );
}

const CaseUtilizationCharts = props => {
  const {
    ventilationData,
    /**
     * Ventilation chart dimensions (in px)
     */
    chartWidth = 200,
    chartHeight = 200,
    innerRadius = 40,
    outerRadius = 65.5,
    labelRadius = 75,
    arrowWidth = 1,
    // Threshold in percents for labels to be shown (greater than)
    labelPercentageThreshold = 5,
    setData,
    dataObj
  } = props;
  const [mappedData, setMappedData] = useState([]);
  const [hoveredValue, setHoveredValue] = useState(false);
  const handleMouseOver = useCallback(
    value => {
      setHoveredValue(value);
    },
    [setHoveredValue]
  );
  const ref = useRef(0);

  const getMousePosition = useCallback((value, { event, innerX, index }) => {
    /* Set position of tooltip container based on the mouse pointer */
    let tooltipContainer = document.getElementsByClassName(
      'gtg-servo-utilization-donut-chart-tooltip'
    );
    if (tooltipContainer.length > 0) {
      tooltipContainer[0].style.cssText += `left:${event.clientX -
        35}px !important; top:${event.clientY -
        70}px !important; bottom: auto;`;
    }
  }, []);

  const handleMouseOut = useCallback(() => {
    setHoveredValue(false);
  }, [setHoveredValue]);

  function getKeyByValue(object, value) {
    return Object.keys(object).find(key => object[key].label === value);
  }

  const customHint = hoveredValue => {
    const hoveredSliceName = getKeyByValue(
      colorsData[dataObj],
      hoveredValue.sliceLabel
    );
    return (
      <Hint
        value={{
          x: hoveredValue.x,
          y: hoveredValue.y
        }}
        className="gtg-servo-utilization-donut-chart-tooltip"
      >
        <div className="gtg-servo-utilization-donut-chart-tooltipText">
          {secondToHours(ventilationData[hoveredSliceName])}
        </div>
      </Hint>
    );
  };

  React.useEffect(() => {
    const mapped = ventilationModesDataMapper(
      ventilationData,
      outerRadius,
      labelRadius,
      dataObj
    );
    if (setData) {
      setData(mapped);
    }
    setMappedData(mapped);
  }, [
    setData,
    setMappedData,
    ventilationData,
    outerRadius,
    labelRadius,
    dataObj
  ]);

  function horizontallyBound(parentDiv, childDiv) {
    var parentRect = parentDiv.getBoundingClientRect();
    var childRect = childDiv.getBoundingClientRect();
    return (
      childRect.left < parentRect.left || childRect.right > parentRect.right
    );
  }

  let filterTimeout;
  const updateDonutChartLabelPosition = () => {
    /***
     * This function is to show/hide the donut lables when
     * they go beyond container boundaries.
     * Timeout is used for debounce the call.
     */
    clearTimeout(filterTimeout);
    filterTimeout = setTimeout(() => {
      let labelsArray = document.getElementsByClassName(
        'gtg-servo-donut-chart-label-text'
      );
      labelsArray &&
        labelsArray.length > 0 &&
        [...labelsArray].forEach(e => {
          let parentDiv = e.closest('.gtg-servo-donut-charts-section');
          let res = horizontallyBound(parentDiv, e);
          e.closest(
            '.gtg-servo-donut-chart-label-div'
          ).style.cssText += `visibility:${res ? 'hidden' : 'visible'}`;
        });
    }, 500);
  };

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    updateDonutChartLabelPosition();
    window.addEventListener('resize', updateDonutChartLabelPosition);
    return () =>
      window.removeEventListener('resize', updateDonutChartLabelPosition);
  }, [ref.current]);
  /* eslint-enable react-hooks/exhaustive-deps */

  return (
    <div className="gtg-servo-utilization-chart">
      <RadialChart
        innerRadius={innerRadius}
        radius={outerRadius}
        data={mappedData}
        width={chartWidth}
        height={chartHeight}
        colorType={'literal'}
        onValueMouseOver={handleMouseOver}
        onSeriesMouseOut={handleMouseOut}
        onNearestX={getMousePosition}
      >
        {hoveredValue && customHint(hoveredValue)}
      </RadialChart>
      <div className="gtg-ventilation-modes-labels">
        {mappedData.map(
          dataItem =>
            ((!hoveredValue &&
              dataItem.slicePercentage > labelPercentageThreshold) ||
              hoveredValue.sliceLabel === dataItem.sliceLabel) && (
              <React.Fragment key={dataItem.sliceLabel}>
                <div
                  style={{
                    transform: `translate(${dataItem.labelX}px, ${dataItem.labelY}px)`,
                    visibility: 'hidden'
                  }}
                  className={'gtg-servo-donut-chart-label-div'}
                >
                  <span
                    // Label arrow is basically a rectangle attached to label position,
                    // translated and given border based on labels position in coordinate system
                    // to form a visual connection between slice midpoint and label
                    className="gtg-label-arrow"
                    style={{
                      width: Math.abs(dataItem.labelArrowX) + 5,
                      height: Math.abs(dataItem.labelArrowY),
                      borderWidth: `
              ${dataItem.labelArrowY < 0 ? arrowWidth : 0}px
              ${dataItem.labelArrowX < 0 ? arrowWidth : 0}px
              ${dataItem.labelArrowY >= 0 ? arrowWidth : 0}px
              ${dataItem.labelArrowX >= 0 ? arrowWidth : 0}px
            `,
                      borderColor: dataItem.color,
                      transform: `translate(${
                        dataItem.labelArrowX > 0 ? dataItem.labelArrowX * -1 : 0
                      }px, ${
                        dataItem.labelArrowY > 0 ? dataItem.labelArrowY * -1 : 0
                      }px)`
                    }}
                  ></span>
                  <label
                    style={{
                      left: dataItem.labelX >= 0 ? 1 : 'initial',
                      right: dataItem.labelX < 0 ? 1 : 'initial',
                      textAlign: 'center'
                    }}
                    className={'gtg-servo-donut-chart-label-text'}
                    ref={ref}
                  >
                    <div>{dataItem.sliceLabel}</div>
                    <div>{dataItem.slicePercentage}%</div>
                  </label>
                </div>
              </React.Fragment>
            )
        )}
      </div>
    </div>
  );
};

CaseUtilizationCharts.propTypes = {
  utilizationData: propTypes.shape({
    ventilationType: propTypes.arrayOf(
      propTypes.shape({
        label: propTypes.string,
        value: propTypes.number
      })
    ),
    patientCategory: propTypes.arrayOf(
      propTypes.shape({
        label: propTypes.string,
        value: propTypes.number
      })
    ),
    invasiveVentilation: propTypes.arrayOf(
      propTypes.shape({
        label: propTypes.string,
        value: propTypes.number
      })
    ),
    ediNava: propTypes.arrayOf(
      propTypes.shape({
        label: propTypes.string,
        value: propTypes.number
      })
    )
  }),
  chartWidth: propTypes.number,
  chartHeight: propTypes.number,
  innerRadius: propTypes.number,
  outerRadius: propTypes.number,
  labelRadius: propTypes.number,
  arrowWidth: propTypes.number,
  labelPercentageThreshold: propTypes.number,
  setData: propTypes.func
};

export default CaseUtilizationCharts;
