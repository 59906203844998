import { mapDeviceStatus } from '../../components/utils/DeviceStatus/deviceStatusMapper';
import {
  FETCH_DEVICE_STATUS,
  SET_DEVICE_STATUS
} from '../actions/deviceStatus';

const initialState = {
  deviceStatus: null
};

export const deviceStatusReducer = (state = initialState, action) => {
  const { type } = action;

  switch (type) {
    case FETCH_DEVICE_STATUS:
      return {
        ...state,
        deviceStatus: null
      };
    case SET_DEVICE_STATUS:
      return {
        ...state,
        deviceStatus: mapDeviceStatus(action.payload.data)
      };

    default:
      return state;
  }
};
