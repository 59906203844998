import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { injectIntl, intlShape } from 'react-intl';
import propTypes from 'prop-types';

import { fetchTests, fetchLastSystemCheckout } from '../../redux/actions/tests';
import { pushPaginationHistory } from '../../redux/actions/paginationHistory';
import {
  uiConfig,
  routePaths,
  ContainerHeader,
  DeviceTestList,
  decodePaginationURI
} from '../../components';

const DeviceTestOverview = props => {
  const {
    device,
    fetchTests,
    fetchLastSystemCheckout,
    tests,
    testsPagination,
    testsSorting,
    testNotPerformedAlarm,
    location,
    pushPaginationHistory,
    authorization
  } = props;

  const handleFetchCallback = (...fetchParams) => {
    pushPaginationHistory(
      fetchParams[1].page,
      fetchParams[1].pageSize,
      fetchParams[2]
    );
    fetchTests(...fetchParams);
  };

  const initialPaginationSettings = {
    page: 0,
    pageSize: uiConfig.pageSizeOptions[0],
    sort: '-start'
  };
  const manualPaginationSettings = testsPagination &&
    testsSorting && {
      fetchMethod: handleFetchCallback,
      fetchParams: [device.id],
      paginationSettings: testsPagination,
      sortingSettings: testsSorting,
      customPageSizeOptions: uiConfig.pageSizeOptions
    };

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    fetchLastSystemCheckout(device.id);
  }, []);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (location && device) {
      const urlPagination = decodePaginationURI(
        location.search,
        initialPaginationSettings
      );
      fetchTests(
        device.id,
        {
          page: urlPagination.page,
          pageSize: urlPagination.pageSize
        },
        urlPagination.sort
      );
    }
  }, [location, authorization]);

  return (
    <>
      <ContainerHeader headerTitle={routePaths.DeviceTestOverview.label} />
      <DeviceTestList
        device={device}
        tests={tests}
        testNotPerformedAlarm={testNotPerformedAlarm}
        manualPaginationSettings={manualPaginationSettings}
      />
    </>
  );
};

const mapStateToProps = state => {
  return {
    tests: state.tests.tests,
    testsPagination: state.tests.tests && state.tests.tests.pagination,
    testsSorting: state.tests.tests && state.tests.tests.sorting,
    testNotPerformedAlarm: state.tests.testNotPerformedAlarm,
    authorization: state.authorization.jwt
  };
};

const mapDispatchToProps = {
  fetchTests,
  fetchLastSystemCheckout,
  pushPaginationHistory
};

DeviceTestOverview.propTypes = {
  intl: intlShape,
  fetchTests: propTypes.func,
  pushPaginationHistory: propTypes.func
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  injectIntl
)(DeviceTestOverview);
