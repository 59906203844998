import React, { useCallback } from 'react';
import { EmptyState, DataTable, Icon, secondToHours } from '..';
import { FormattedMessage } from 'react-intl';
import { WithTooltip } from '../../hoc';

import './UsageStatisticsServoAllCategory.scss';

const categoryExists = (category, data) => {
  if (data !== null)
    return data.usage.some(el => {
      return el.category === category;
    });
};

const getIndex = (category, data) => {
  if (data !== null) {
    return data.usage.findIndex(obj => {
      return obj.category === category;
    });
  }
};

const UsageStatisticsServoAllCategory = ({
  data,
  updateSelectedCategory,
  metricsTimeSpan,
  shouldShowCategoryList
}) => {
  const tableData = [];

  const totalUsageText = [
    {
      text: (
        <FormattedMessage
          id="usageStatistics.totalHoursOfVentilation"
          defaultMessage="Total hours of ventilation [h]"
        />
      ),
      field: 'ventilation'
    }
  ];

  if (data && data !== null && data.usage && data.usage.length > 0) {
    totalUsageText.map(item => {
      tableData.push({
        text: item.text,
        neonatal: categoryExists('neonatal', data)
          ? data.usage[getIndex('neonatal', data)][item.field] !== undefined &&
            data.usage[getIndex('neonatal', data)][item.field] !== null
            ? secondToHours(data.usage[getIndex('neonatal', data)][item.field])
            : 0
          : 0,
        pediatric: categoryExists('pediatric', data)
          ? data.usage[getIndex('pediatric', data)][item.field] !== undefined &&
            data.usage[getIndex('pediatric', data)][item.field] !== null
            ? secondToHours(data.usage[getIndex('pediatric', data)][item.field])
            : 0
          : 0,
        adult: categoryExists('adult', data)
          ? data.usage[getIndex('adult', data)][item.field] !== undefined &&
            data.usage[getIndex('adult', data)][item.field] !== null
            ? secondToHours(data.usage[getIndex('adult', data)][item.field])
            : 0
          : 0
      });
    });
  }

  const updateSelectedCategoryChange = useCallback(
    e => {
      updateSelectedCategory(e.target.innerText);
    },
    [updateSelectedCategory]
  );

  return (
    (tableData && tableData.length > 0 && (
      <DataTable
        showPagination={false}
        pageSize={[999]}
        noCustomScrollbars
        withOverflow
        resizable={false}
        data={tableData}
        className="mb-4 gtg-agent-usage-servo-list"
        columns={[
          {
            Header: (
              <FormattedMessage
                id="usageStatistics.patientCategory"
                defaultMessage="Patient category"
              />
            ),
            accessor: 'text',
            headerClassName: 'text-left gtg-agent-usage-servo-table-header',
            className: 'text-left',
            width: 200,
            sortable: false
          },
          {
            Header: 'Neonatal',
            Header: (
              <div
                id="neonatal"
                onClick={
                  shouldShowCategoryList
                    ? updateSelectedCategoryChange
                    : undefined
                }
              >
                <Icon name="neonatal" size="sm" />
                <FormattedMessage
                  id="usageStatistics.neonatal"
                  defaultMessage="Neonatal"
                />
              </div>
            ),
            accessor: 'neonatal',
            headerClassName: 'text-center category-text',
            className: 'text-center',
            sortable: false
          },
          {
            Header: 'Pediatric',
            Header: (
              <div
                id="pediatric"
                onClick={
                  shouldShowCategoryList
                    ? updateSelectedCategoryChange
                    : undefined
                }
              >
                <Icon name="pediatric" size="sm" />
                <FormattedMessage
                  id="usageStatistics.pediatric"
                  defaultMessage="Pediatric"
                />
              </div>
            ),
            accessor: 'pediatric',
            headerClassName: 'text-center category-text',
            className: 'text-center',
            sortable: false
          },
          {
            Header: 'Adult',
            Header: (
              <div
                id="adult"
                onClick={
                  shouldShowCategoryList
                    ? updateSelectedCategoryChange
                    : undefined
                }
              >
                <Icon name="adult" size="sm" />
                <FormattedMessage
                  id="usageStatistics.adult"
                  defaultMessage="Adult"
                />
              </div>
            ),
            accessor: 'adult',
            headerClassName: 'text-center category-text',
            className: 'text-center',
            sortable: false
          }
        ]}
      />
    )) || <EmptyState />
  );
};

export default UsageStatisticsServoAllCategory;
