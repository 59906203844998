import {
  deviceAlertMapper,
  deviceSwVersionMapper,
  deviceStatuses
} from '../../';
import get from 'lodash.get';

/**
 * Map device info received from the API into a datatable-ready object
 * for the frontend.
 * @param {*} device
 */
const devicesMapper = devices => {
  return (
    devices &&
    devices.map(device => {
      const {
        deviceType,
        status,
        failedTests,
        failedPWTests,
        systemSoftwareVersion
      } = device;
      // failedTests take priority from failedPWTests
      const failedTestsToShow = [failedTests, failedPWTests].find(
        tests => get(tests, 'failed.length', 0) > 0
      );
      return {
        ...device,
        status: {
          count: get(failedTestsToShow, 'failed.length') || null,
          status: status
        },
        alert: {
          name: deviceAlertMapper(deviceType, status),
          description:
            ([deviceStatuses.error, deviceStatuses.warning].includes(status) &&
              failedTestsToShow &&
              failedTestsToShow.failed.map(t => t.name).join(', ')) ||
            null
        },
        systemSoftwareVersion: deviceSwVersionMapper(
          deviceType,
          systemSoftwareVersion
        )
      };
    })
  );
};

export default devicesMapper;
