import { SET_DEVICES } from '../actions/devices';

const initState = {
  devices: []
};

export const devicesReducer = (state = initState, action) => {
  const { payload, type } = action;

  switch (type) {
    case SET_DEVICES:
      return {
        ...state,
        devices: payload
      };
    default:
      return state;
  }
};
