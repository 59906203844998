import React, { useEffect, useCallback } from 'react';
import { connect, useDispatch } from 'react-redux';
import { compose } from 'redux';
import { Switch, withRouter } from 'react-router-dom';
import { injectIntl, intlShape } from 'react-intl';
import propTypes from 'prop-types';
import { WithLoadingSpinner } from '../../hoc';
import { fetchDevice, putDevice } from '../../redux/actions/device';
import { DeviceInformation, DeviceNavigation } from '../../components';
import { accountSelected } from '../../redux/actions/account';
import get from 'lodash.get';

const Device = props => {
  const { match, device, fetchDevice, putDevice } = props;
  const deviceId = match.params.deviceId;
  const dispatch = useDispatch();

  useEffect(() => {
    fetchDevice(deviceId);
  }, [deviceId, fetchDevice]);

  const handleCustomerIdSubmit = useCallback(
    id => {
      putDevice({
        id: deviceId,
        location: get(device, 'location', null),
        customerDeviceId: id
      });
    },
    [device, deviceId, putDevice]
  );

  const handleLocationSubmit = useCallback(
    location => {
      putDevice({
        id: deviceId,
        location: location,
        customerDeviceId: get(device, 'customerDeviceID', null)
      });
    },
    [device, deviceId, putDevice]
  );

  // Dispatch accountSelected to ensure account id specific data is updated if a device is available.
  if (device) {
    const accountId = get(device, 'accountID', null);
    dispatch(accountSelected({ id: accountId }));
  }

  return (
    <>
      <div className="row">
        <div className="col-12">
          <WithLoadingSpinner when="deviceInformation">
            <DeviceInformation
              device={device}
              onCustomerIdSubmit={handleCustomerIdSubmit}
              onLocationSubmit={handleLocationSubmit}
            />
            <DeviceNavigation device={device} />
          </WithLoadingSpinner>

          {device &&
            React.Children.map(props.children, child => {
              // pass device prop to all Device container children
              if (child.type === Switch) {
                return React.cloneElement(
                  child,
                  {},
                  child.props.children.map(route => {
                    return route.type.name === 'Redirect'
                      ? route
                      : React.cloneElement(
                          route,
                          { key: 'route-' + route.path },
                          route.props.children
                            ? React.cloneElement(route.props.children, {
                                device
                              })
                            : null
                        );
                  })
                );
              }
            })}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = state => ({
  device: state.deviceDetails.device
});

const mapDispatchToProps = {
  fetchDevice: fetchDevice,
  putDevice: putDevice
};

Device.propTypes = {
  intl: intlShape,
  fetchDevice: propTypes.func,
  putDevice: propTypes.func
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  injectIntl
)(Device);
