import { ACCOUNT_INIT, ACCOUNT_SET_SELECTED } from '../actions/account';

export function accountReducer(
  state = {
    current: null
  },
  action
) {
  switch (action.type) {
    case ACCOUNT_SET_SELECTED:
      return {
        ...state,
        current: action.payload
      };
    case ACCOUNT_INIT:
      return {
        ...state
      };
    default:
      return state;
  }
}
