import { Capitalize } from '../StringConverters/StringConverters';

export const deviceTypes = {
  flowI: 'flow-i',
  flowE: 'flow-e',
  flowC: 'flow-c',
  servoU: 'servo-u',
  servoN: 'servo-n',
  servoAir: 'servo-air',
  rotaflow2: 'rotaflow2'
};

export const connectivityNodes = {
  cn: 'cn',
  getingeConnect: 'getinge-connect',
  getingeConnectGuide: 'getinge-connect-guide',
  getingeConnectP10: 'getinge-connect-p10',
  rotaflow2cm: 'rotaflow2-cm'
};

export const deviceFamilies = {
  flow: {
    name: 'flow',
    deviceTypes: [deviceTypes.flowI, deviceTypes.flowE, deviceTypes.flowC]
  },
  servo: {
    name: 'servo',
    deviceTypes: [deviceTypes.servoU, deviceTypes.servoN, deviceTypes.servoAir]
  },
  rotaflow: {
    name: 'rotaflow2',
    deviceTypes: [deviceTypes.rotaflow2]
  }
};

/**
 * Returns the family that this deviceType belongs to or `null` if an invalid argument has been passed
 * @param {string} deviceType The deviceType for which the device family should be determined
 */
export const getDeviceFamilyName = (deviceType = '') => {
  if (!deviceType || typeof deviceType !== 'string') {
    return null;
  }

  const split = deviceType.toLowerCase().split('-');

  if (split.length > 3) {
    return null;
  }

  const family = split[0];

  // We expect non-empty elements
  if (family === '') {
    return null;
  }

  // These are the only supported device families
  if (
    ![
      deviceFamilies.flow.name,
      deviceFamilies.servo.name,
      deviceFamilies.rotaflow.name
    ].includes(family)
  ) {
    return null;
  }

  return family;
};

export const isFlowFamily = (deviceType = '') => {
  return getDeviceFamilyName(deviceType) === deviceFamilies.flow.name;
};

export const isServoFamily = (deviceType = '') => {
  return getDeviceFamilyName(deviceType) === deviceFamilies.servo.name;
};

export const isRotaflowFamily = (deviceType = '') => {
  return getDeviceFamilyName(deviceType) === deviceFamilies.rotaflow.name;
};

// When making changes also update method in 'code/e2e/cypress/support/helperMethods.js'
export const getDeviceTypeDisplayName = (deviceType = '') => {
  let displayName = deviceType;

  switch (displayName) {
    case deviceTypes.rotaflow2:
      displayName = 'Rotaflow II';
      break;
    case connectivityNodes.getingeConnect:
      displayName = 'Getinge Connect';
      break;
    default:
      break;
  }

  return Capitalize(displayName);
};
