import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import styles from './Toggles.module.scss';

/**
 * Displays a list of buttons of which one can be selected.
 * It raises onSelect event when a button is clicked.
 */

const Toggles = ({ toggles, selectedToggle, onSelect, ...rest }) => {
  /* eslint-disable react-hooks/rules-of-hooks */
  return (
    <div className={styles.gtgToggles} {...rest}>
      {toggles.map(toggle => {
        const handleClick = useCallback(
          () => !toggle.disabled && onSelect(toggle),
          [toggle]
        );

        return (
          <button
            aria-disabled={toggle.disabled}
            aria-current={toggle.value === selectedToggle}
            key={toggle.value}
            onClick={handleClick}
          >
            {toggle.label}
          </button>
        );
      })}
    </div>
  );
};

Toggles.propTypes = {
  toggles: PropTypes.arrayOf(
    PropTypes.shape({
      disabled: PropTypes.bool,
      value: PropTypes.string.isRequired,
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired
    })
  ).isRequired,
  selectedToggle: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired
};

export default Toggles;
