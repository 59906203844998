import React from 'react';
import { Icon } from '..';

const PreventiveMaintenanceStatusIcon = ({ status }) => {
  switch (status.toLowerCase()) {
    case 'ok':
      return <Icon name="success-outline" />;
    case 'warning':
      return <Icon name="warning-outline" />;
    case 'alert':
      return <Icon name="error-outline" />;
    // no default
  }
};

export default PreventiveMaintenanceStatusIcon;
