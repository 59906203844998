// Add column headers and define column keys and widths
export const agentUsageColumns = [
  { header: 'Hospital', key: 'hospital', width: 40 },
  { header: 'Date', key: 'date', width: 20 },
  { header: 'Cases', key: 'cases', width: 10 },
  { header: 'SEV [mL]', key: 'sev', width: 10 },
  { header: 'DES [mL]', key: 'des', width: 10 },
  { header: 'ISO [mL]', key: 'iso', width: 10 },
  { header: 'FGF [mL]', key: 'fgf', width: 10 },
  { header: 'Department filter selection', key: 'department', width: 40 },
  { header: 'Device filter selection', key: 'device', width: 40 }
];

// Define filename
export const agentUsageFileName =
  'Usage_statistics_overview_Agent_usage_over_time.xlsx';
