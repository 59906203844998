import { SET_METRICS_DETAILED } from '../actions/metricsDetailed';

const initState = {
  metricsDetailed: []
};

export const metricsDetailedReducer = (state = initState, action) => {
  const { payload, type } = action;

  switch (type) {
    case SET_METRICS_DETAILED:
      return {
        ...state,
        metricsDetailed: payload
      };
    default:
      return state;
  }
};
