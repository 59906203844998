import { entity } from '../actions/legalNotice';
import { apiRequest, API_SUCCESS } from '../actions/api';
import {
  FETCH_LEGAL_NOTICE_APPROVAL,
  SET_LEGAL_NOTICE_APPROVAL,
  setLegalNotice
} from '../actions/legalNotice';
import { ParseLegalNoticeVersion } from '../../components';
import { API_URLS } from './urls';
import legalNoticePath from '../../legalNotice.md';

const URL = API_URLS.LegalNoticeApproval;

export const legalNoticeMdl = ({ dispatch }) => next => action => {
  next(action);

  const { type } = action;

  switch (type) {
    case FETCH_LEGAL_NOTICE_APPROVAL:
      let legalDocumentVersion;
      fetch(legalNoticePath)
        .then(response => response.text())
        .then(content => {
          legalDocumentVersion = ParseLegalNoticeVersion(content);
          if (legalDocumentVersion) {
            dispatch(setLegalNotice(undefined, content, legalDocumentVersion));
            dispatch(
              apiRequest({
                method: 'GET',
                url: `${URL.url}?version=${legalDocumentVersion}`,
                requestSignature: 'legalNoticeApproval',
                entity: entity
              })
            );
          }
        });
      break;
    case `${entity} GET ${API_SUCCESS}`:
      dispatch(setLegalNotice(action.payload.data));
      break;
    case SET_LEGAL_NOTICE_APPROVAL:
      dispatch(
        apiRequest({
          method: 'POST',
          url: `${URL.url}?version=${action.payload}`,
          requestSignature: 'legalNoticeApproval',
          entity: entity
        })
      );
      break;
    case `${entity} POST ${API_SUCCESS}`:
      dispatch(setLegalNotice({ accepted: true, timestamp: null }));
      break;
    // no default
  }
};
