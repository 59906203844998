import { SET_DEVICES_OVERVIEW } from '../actions/devicesOverview';

export function devicesOverviewReducer(
  state = {
    devicesStatusOverview: {}
  },
  action
) {
  switch (action.type) {
    case SET_DEVICES_OVERVIEW:
      return {
        ...state,
        devicesStatusOverview: action.payload
      };

    default:
      return state;
  }
}
