import {
  FETCH_CONNECTED_ACCESSORIES,
  setConnectedAccessories,
  entity
} from '../actions/connectedAccessories';
import { API_SUCCESS, API_ERROR, apiRequest } from '../actions/api';
import { API_URLS } from './urls';

const URL = API_URLS.ConnectedAccessories;

const connectedAccessoriesMapper = accessories => {
  return (
    accessories &&
    accessories.map(accessory => {
      const { status, deviceType, deviceSerialNumber } = accessory;
      return {
        ...accessory,
        status: {
          count: null,
          status: status
        },
        lastSeenIn: `${deviceType} S/N ${deviceSerialNumber}`
      };
    })
  );
};

export const connectedAccessoriesMdl = ({ dispatch }) => next => action => {
  next(action);

  const { type, pagination, sorting } = action;

  switch (type) {
    case FETCH_CONNECTED_ACCESSORIES:
      dispatch(setConnectedAccessories([]));

      dispatch(
        apiRequest({
          method: 'GET',
          url:
            URL.url +
            '?page=' +
            (pagination.page + 1) +
            '&pageSize=' +
            pagination.pageSize +
            (sorting ? '&sort=' + sorting : ''),
          requestSignature: 'connectedAccessories',
          entity: entity,
          filter: URL.filter
        })
      );
      break;
    case `${entity} GET ${API_SUCCESS}`:
      if (
        action.payload.data &&
        action.payload.data.pagination &&
        action.payload.data.size
      ) {
        action.payload.data.pagination.page =
          action.payload.data.pagination.page - 1;
        action.payload.data.pagination.totalItems = action.payload.data.size;
      }
      if (action.payload.data && action.payload.data.items) {
        action.payload.data.items = connectedAccessoriesMapper(
          action.payload.data.items
        );
      }
      dispatch(setConnectedAccessories(action.payload.data || []));
      break;
    case `${entity} GET ${API_ERROR}`:
      dispatch(setConnectedAccessories([]));
      break;
    // no default
  }
};
