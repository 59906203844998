import { entity, FETCH_LOG_TYPES, setLogTypes } from '../actions/logTypes';
import { apiRequest, API_SUCCESS } from '../actions/api';
import { API_URLS } from './urls';

const URL = API_URLS.LogTypes;

export const logTypesMdl = ({ dispatch }) => next => action => {
  next(action);

  const { type } = action;

  switch (type) {
    case FETCH_LOG_TYPES:
      const { deviceId } = action.payload;
      const url = URL(deviceId);

      dispatch(
        apiRequest({
          method: 'GET',
          url: url.url,
          requestSignature: entity,
          entity: entity
        })
      );
      break;
    case `${entity} GET ${API_SUCCESS}`:
      dispatch(setLogTypes(action.payload.data));
      break;
    // no default
  }
};
