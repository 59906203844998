const mainColumns = [
  { header: 'PM Status', key: 'status', width: 10 },
  { header: 'Hospital', key: 'hospital', width: 20 },
  { header: 'Department', key: 'departmentName', width: 20 },
  { header: 'Location', key: 'location', width: 20 },
  { header: 'Device', key: 'deviceType', width: 10 },
  { header: 'Serial no', key: 'serial', width: 10 },
  { header: 'Customer device no', key: 'customerID', width: 20 },
  {
    header: 'Next PM due',
    key: 'nextDue',
    width: 10
  },
  {
    header: 'Last PM',
    key: 'last',
    width: 15
  },
  { header: 'Last PM type', key: 'lastType', width: 15 },
  {
    header: 'Next PM',
    key: 'next',
    width: 15
  },
  {
    header: 'Memory backup batteries - PM status',
    key: 'memoryBackupStatus',
    width: 25
  },
  {
    header: 'Memory backup batteries - Last PM',
    key: 'memoryBackupLast',
    width: 25
  },
  {
    header: 'Memory backup batteries - Next PM',
    key: 'memoryBackupNext',
    width: 25
  },
  {
    header: 'Power backup battery - PM status',
    key: 'powerBackupStatus',
    width: 25
  },
  {
    header: 'Power backup battery - Last PM',
    key: 'powerBackupLast',
    width: 25
  },
  {
    header: 'Power backup battery - Next PM',
    key: 'powerBackupNext',
    width: 25
  },
  {
    header: 'Cassette membrane remaining capacity - PM status',
    key: 'cassetteMembraneStatus',
    width: 30
  },
  {
    header: 'Cassette membrane remaining capacity - Last PM',
    key: 'cassetteMembraneLast',
    width: 30
  },
  {
    header: 'Cassette membrane remaining capacity - Next PM',
    key: 'cassetteMembraneStrokes',
    width: 30
  },
  {
    header: 'Running hours since last PM – PM Status',
    key: 'runningHoursSinceStatus',
    width: 25
  },
  {
    header: 'Running hours since last PM - Next PM',
    key: 'runningHoursSinceLastNext',
    width: 25
  },
  { header: 'Department filter selection', key: 'department', width: 25 },
  { header: 'Device filter selection', key: 'device', width: 25 }
];

let batteryColumns = [];
for (let i = 1; i <= 6; i++) {
  batteryColumns.push(
    {
      header: `Battery ${i} - Serial no`,
      key: `battery${i}Serial`,
      width: 10
    },
    {
      header: `Battery ${i} - PM Status`,
      key: `battery${i}Status`,
      width: 10
    },
    {
      header: `Battery ${i} - Next PM`,
      key: `battery${i}Next`,
      width: 10
    }
  );
}

export const preventiveMaintenanceListColumns = [
  ...mainColumns,
  ...batteryColumns
];

export const preventiveMaintenanceListFileName = 'Preventive_maintenance.xlsx';
