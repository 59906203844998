import PropTypes from 'prop-types';

export const deviceTestShape = PropTypes.shape({
  children: PropTypes.arrayOf(
    PropTypes.shape({
      data: PropTypes.shape({
        end: PropTypes.string,
        id: PropTypes.string,
        itemType: PropTypes.string,
        measurementValues: PropTypes.arrayOf(PropTypes.shape({})),
        name: PropTypes.string,
        result: PropTypes.string,
        resultCell: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
        start: PropTypes.oneOfType([PropTypes.func, PropTypes.object])
      }),
      children: PropTypes.arrayOf(PropTypes.shape({}))
    })
  ),
  deviceID: PropTypes.string,
  deviceType: PropTypes.string,
  end: PropTypes.string,
  failed: PropTypes.arrayOf(
    PropTypes.shape({
      end: PropTypes.string,
      id: PropTypes.string,
      itemType: PropTypes.string,
      name: PropTypes.string,
      result: PropTypes.string,
      start: PropTypes.string
    })
  ),
  failedProactiveWatch: PropTypes.shape({
    failed: PropTypes.arrayOf(
      PropTypes.shape({
        end: PropTypes.string,
        id: PropTypes.string,
        itemType: PropTypes.string,
        name: PropTypes.string,
        result: PropTypes.string,
        start: PropTypes.string
      })
    )
  }),
  id: PropTypes.string,
  itemType: PropTypes.string,
  name: PropTypes.string,
  preUseCheckType: PropTypes.string,
  result: PropTypes.string,
  softwareVersion: PropTypes.string,
  start: PropTypes.string
});
