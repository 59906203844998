import { SET_SOFTWARE_VERSIONS } from '../actions/softwareVersions';

const initState = {
  softwareVersions: []
};

export const softwareVersionsReducer = (state = initState, action) => {
  const { payload, type } = action;

  switch (type) {
    case SET_SOFTWARE_VERSIONS:
      return {
        ...state,
        softwareVersions: payload
      };
    default:
      return state;
  }
};
