import React, { useCallback } from 'react';
import ReactTable from 'react-table';
import withFixedColumns from './ReactTableFixedColumns';
import { Scrollable, uiConfig } from '../..';
import DataTablePagination from './pagination';
import classnames from 'classnames';
import { addWithOverflowTooltip } from './datatableHelper';
import { applyTooltipForColHeader } from '../TableHeaderTooltip/tableHeaderTooltip';

const ReactTableFixedColumns = withFixedColumns(ReactTable);

const DataTableFixedColumns = props => {
  applyTooltipForColHeader();

  const pageSizeOptionsArr =
    (props.manualPaginationSettings &&
      props.manualPaginationSettings.customPageSizeOptions) ||
    props.pageSizeOptions ||
    uiConfig.pageSizeOptions;
  const CustomTableComponent = useCallback(
    ({ children, className, ...rest }) => {
      const currentRowCount =
        children[3].props.children[0].length || props.defaultPageSize;
      return (
        <div
          className={classnames('rt-table', className)}
          role="grid"
          {...rest}
        >
          <Scrollable minElementHeight={currentRowCount * 50}>
            {children}
          </Scrollable>
        </div>
      );
    },
    [props.defaultPageSize]
  );
  const manualPagination = props.manualPaginationSettings && {
    showPagination: true,
    showPaginationTop: true,
    manual: true,
    /* eslint-disable react-hooks/rules-of-hooks */
    onFetchData: useCallback(
      (state, instance) => {
        const sortingStr =
          state.sorted[0] &&
          ((state.sorted[0].asc && !state.sorted[0].desc) ||
          (state.sorted[0].asc === false && state.sorted[0].desc === false)
            ? ''
            : '-') + state.sorted[0].id;
        if (
          state.page !==
            props.manualPaginationSettings.paginationSettings.page ||
          state.pageSize !==
            props.manualPaginationSettings.paginationSettings.pageSize ||
          sortingStr !== props.manualPaginationSettings.sortingSettings
        ) {
          let fetchParams = [
            {
              page: state.page,
              pageSize: state.pageSize
            },
            sortingStr
          ];
          if (props.manualPaginationSettings.fetchParams) {
            fetchParams = [
              ...props.manualPaginationSettings.fetchParams,
              ...fetchParams
            ];
          }
          props.manualPaginationSettings.fetchMethod(...fetchParams);
        }
      },
      [props.manualPaginationSettings]
    ),
    defaultSorted: [
      {
        id: props.manualPaginationSettings.sortingSettings.substring(
          props.manualPaginationSettings.sortingSettings.charAt(0) === '-'
            ? 1
            : 0
        ),
        asc: props.manualPaginationSettings.sortingSettings.charAt(0) !== '-'
      }
    ],
    page: props.manualPaginationSettings.paginationSettings.page,
    pageSize: props.manualPaginationSettings.paginationSettings.pageSize,
    pages: props.manualPaginationSettings.paginationSettings.totalPages,
    totalItems: props.manualPaginationSettings.paginationSettings.totalItems
  };

  const columns = props.columns.map(addWithOverflowTooltip);

  return (
    <ReactTableFixedColumns
      {...props}
      {...manualPagination}
      PaginationComponent={DataTablePagination}
      TableComponent={CustomTableComponent}
      pageSizeOptions={pageSizeOptionsArr}
      defaultPageSize={pageSizeOptionsArr[0]}
      columns={columns}
    />
  );
};

export default DataTableFixedColumns;
