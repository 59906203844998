import {
  entity,
  setPWAvailableDeviceTypes,
  FETCH_PW_AVAILABLE_DEVICE_TYPES
} from '../actions/accessInfo';
import { API_SUCCESS, apiRequest } from '../actions/api';
import { API_URLS } from './urls';

const PW_DEVICE_TYPES_URL = accountId =>
  `${API_URLS.Accounts.url}/${accountId}/pw-device-types`;

export const accessInfoMdl = ({ dispatch }) => next => action => {
  next(action);

  const { type } = action;

  switch (type) {
    case FETCH_PW_AVAILABLE_DEVICE_TYPES:
      // Clear existing available types right away, to prevent an API error
      // or latency causing the portal to show more than available
      dispatch(setPWAvailableDeviceTypes([]));

      const { accountId } = action.payload;
      if (accountId !== null) {
        dispatch(
          apiRequest({
            method: 'GET',
            url: `${PW_DEVICE_TYPES_URL(accountId)}`,
            requestSignature: 'pw-device-types',
            entity: entity
          })
        );
      }
      break;
    case `${entity} GET ${API_SUCCESS}`:
      dispatch(setPWAvailableDeviceTypes(action.payload.data.items));
      break;
    // no default
  }
};
