import {
  setMetricsDeviceUsage,
  FETCH_METRICS_DEVICE_USAGE,
  entity
} from '../actions/metricsDetailedPerDevice';
import { API_SUCCESS, apiRequest } from '../actions/api';
import { MetricsTimeParams } from '../../components/';
import { API_URLS } from './urls';

const URL = API_URLS.MetricsDetailedUsagePerDevice;

export const metricsDetailedPerDeviceMdl = ({ dispatch }) => next => action => {
  next(action);

  const { type } = action;

  switch (type) {
    case FETCH_METRICS_DEVICE_USAGE:
      const { timeSpan, count } = action.payload;
      dispatch(
        apiRequest({
          method: 'GET',
          url:
            URL.url + MetricsTimeParams('metricsDeviceUsage', timeSpan, count),
          requestSignature: 'metricsDeviceUsage',
          entity: entity,
          filter: URL.filter
        })
      );

      break;
    case `${entity} GET ${API_SUCCESS}`:
      dispatch(setMetricsDeviceUsage(action.payload.data));
      break;
  }
};
