import React, { createRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { WithTooltip } from '../../hoc';
import { useOverflowObserver } from '../../hooks';

import './withOverflowTooltip.scss';

/**
 * Displays a tooltip when hovered if the child is overflowing.
 * @param customTooltipContent The content that should be displayed in the tooltip if the child element is overflowing.
 *
 */
const WithOverflowTooltip = ({ children, customTooltipContent }) => {
  const cellRef = createRef();
  const isOverflowActive = useOverflowObserver(cellRef);

  // Display children if tooltipContent is not given specifically.
  const tooltipContent = customTooltipContent || children;
  return (
    <WithTooltip
      tooltipContent={isOverflowActive && tooltipContent}
      tooltipSmall
      tooltipLocation="bottom"
      tooltipFixedArrow
    >
      <div ref={cellRef} className={classnames('gtg-ellipsis-overflow')}>
        {children}
      </div>
    </WithTooltip>
  );
};

WithOverflowTooltip.propTypes = {
  customTooltipContent: PropTypes.node,
  children: PropTypes.node
};

export default WithOverflowTooltip;
