import React from 'react';
import { WithOverflowTooltip } from '../../../hoc';

export const addWithOverflowTooltip = column => {
  if (!column || column.noOverflowTooltip) return { ...column };

  const className = column.className
    ? column.className + ' with-overflow'
    : 'with-overflow';

  const Cell = column.Cell
    ? row => <WithOverflowTooltip>{column.Cell(row)}</WithOverflowTooltip>
    : row => <WithOverflowTooltip>{row.value}</WithOverflowTooltip>;

  return { ...column, className, Cell };
};
