const isRequestPending = (requestName, pendingRequests) => {
  return (
    (requestName &&
      pendingRequests &&
      pendingRequests.some(req => req.split('_|_')[0] === requestName)) ||
    false
  );
};

export default isRequestPending;
