import {
  SET_TROUBLESHOOTING_GUIDANCE,
  FETCH_TROUBLESHOOTING_GUIDANCE
} from '../actions/troubleshootingGuidance';

const initState = {
  test: null
};

export const troubleshootingGuidanceReducer = (state = initState, action) => {
  const { type } = action;

  switch (type) {
    case FETCH_TROUBLESHOOTING_GUIDANCE:
      return {
        ...state,
        troubleshootingGuidance: null
      };
    case SET_TROUBLESHOOTING_GUIDANCE:
      return {
        ...state,
        troubleshootingGuidance: action.payload
      };
    default:
      return state;
  }
};
