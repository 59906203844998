import React from 'react';
import { ServiceOrderList } from '..';

const DeviceServiceOrderList = props => {
  const { serviceOrders, deviceID, manualPaginationSettings } = props;

  return (
    <ServiceOrderList
      serviceOrders={serviceOrders}
      deviceID={deviceID}
      manualPaginationSettings={manualPaginationSettings}
    />
  );
};

export default DeviceServiceOrderList;
