import propTypes from 'prop-types';
import React from 'react';
import { Formatted24hTime } from '../../components';
import { currencyShape } from '../../components/Cases/AgentCostEntryModal/AgentCostEntryModal';
import { FormattedDate, FormattedMessage } from 'react-intl';
import moment from 'moment';

export const caseShape = {
  deviceID: propTypes.string.isRequired,
  deviceType: propTypes.string.isRequired,
  id: propTypes.string.isRequired,
  hasDetails: propTypes.bool.isRequired,
  start: propTypes.string.isRequired,
  end: propTypes.string,
  agc: propTypes.bool,
  recruitmentManeuver: propTypes.bool,
  averages: propTypes.arrayOf(
    propTypes.shape({
      key: propTypes.string.isRequired,
      value: propTypes.number.isRequired,
      unit: propTypes.string.isRequired
    })
  ),
  costs: propTypes.shape({
    currency: currencyShape,
    sev: propTypes.number,
    des: propTypes.number,
    iso: propTypes.number
  }),
  metrics: propTypes.arrayOf(
    propTypes.shape({
      key: propTypes.string,
      unit: propTypes.string,
      value: propTypes.number
    })
  ),
  agentUsage: propTypes.arrayOf(
    propTypes.shape({
      agent: propTypes.oneOf(['Iso', 'Sev', 'Des']),
      timestamp: propTypes.string,
      value: propTypes.number
    })
  ),
  timeline: propTypes.arrayOf(
    propTypes.shape({
      key: propTypes.oneOf(['MacY', 'MacBrain', 'FreshGasFlow']),
      unit: propTypes.string,
      values: propTypes.arrayOf(
        propTypes.shape({
          timestamp: propTypes.string,
          value: propTypes.number
        })
      )
    })
  ),
  ventilation: propTypes.shape({
    manual: propTypes.number,
    afgo: propTypes.number,
    pc: propTypes.number,
    vc: propTypes.number,
    prvc: propTypes.number,
    ps: propTypes.number,
    psbackup: propTypes.number,
    pcps: propTypes.number,
    vcps: propTypes.number
  })
};

export const CaseDetailTitle = ({ start, end }) => {
  const isDifferentDate =
    moment(start).format('MM/DD/YYYY') !== moment(end).format('MM/DD/YYYY');

  const areValidDates = moment(start).isValid() && moment(end).isValid();

  return (
    (start && end && areValidDates && (
      <>
        <FormattedMessage id="case.title" defaultMessage="Case" />{' '}
        <FormattedDate value={start} /> <Formatted24hTime value={start} /> -{' '}
        {isDifferentDate && (
          <>
            <FormattedDate value={end} />{' '}
          </>
        )}
        <Formatted24hTime value={end} />
      </>
    )) || (
      <FormattedMessage id="case.noInfoTitle" defaultMessage="Case Details" />
    )
  );
};

CaseDetailTitle.propTypes = {
  start: propTypes.string,
  end: propTypes.string
};
