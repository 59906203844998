import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { WithLoadingSpinner } from '../../hoc';
import { FiltersContainer } from '..';

import { fetchServiceOrders } from '../../redux/actions/serviceOrders';
import { pushPaginationHistory } from '../../redux/actions/paginationHistory';
import {
  uiConfig,
  routePaths,
  ContainerHeader,
  ServiceOrderList,
  Card,
  CardSection,
  decodePaginationURI,
  Icon
} from '../../components';
import {
  SERVICE_ORDERS_LIST,
  serviceOrdersListFileName,
  createDocument
} from '../../utils/export';

const ServiceOrderOverview = props => {
  const {
    serviceOrders,
    filters,
    fetchServiceOrders,
    location,
    pushPaginationHistory,
    intl
  } = props;
  const [filteredServiceOrdersData, setFilteredServiceOrdersData] = useState(
    []
  );

  const handleFetchCallback = (...fetchParams) => {
    pushPaginationHistory(
      fetchParams[1].page,
      fetchParams[1].pageSize,
      fetchParams[2]
    );
    fetchServiceOrders(...fetchParams);
  };

  const initialPaginationSettings = {
    page: 0,
    pageSize: uiConfig.pageSizeOptions[0],
    sort: '-created'
  };
  const manualPaginationSettings = {
    fetchMethod: handleFetchCallback,
    fetchParams: [undefined],
    paginationSettings: props.serviceOrdersPagination,
    sortingSettings: props.serviceOrdersSorting,
    customPageSizeOptions: uiConfig.pageSizeOptions
  };

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    fetchServiceOrders(
      undefined,
      {
        page: initialPaginationSettings.page,
        pageSize: initialPaginationSettings.pageSize
      },
      props.serviceOrdersSorting || initialPaginationSettings.sort
    );
  }, [filters]);

  useEffect(() => {
    const urlPagination = decodePaginationURI(
      location.search,
      initialPaginationSettings
    );
    fetchServiceOrders(
      undefined,
      {
        page: urlPagination.page,
        pageSize: urlPagination.pageSize
      },
      urlPagination.sort
    );
  }, [location]);
  /* eslint-enable react-hooks/exhaustive-deps */

  const exportServiceOrdersData = useCallback(() => {
    createDocument(
      SERVICE_ORDERS_LIST,
      filteredServiceOrdersData || [],
      filters,
      serviceOrdersListFileName,
      null,
      intl
    );
  }, [filteredServiceOrdersData, filters, intl]);

  return (
    <>
      <ContainerHeader headerTitle={routePaths.ServiceOrderOverview.label}>
        <FiltersContainer />
      </ContainerHeader>
      <div className="row">
        <div className="col-12 col-md-12">
          <Card dataCy="cardServiceOrders">
            <CardSection>
              <WithLoadingSpinner when="serviceOrders">
                <ServiceOrderList
                  serviceOrders={serviceOrders}
                  manualPaginationSettings={manualPaginationSettings}
                  setData={setFilteredServiceOrdersData}
                  currentPageExtra={
                    <Link onClick={exportServiceOrdersData} to="#">
                      <Icon name="download-excel" size="sm" />
                    </Link>
                  }
                />
              </WithLoadingSpinner>
            </CardSection>
          </Card>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = state => ({
  serviceOrders: state.serviceOrders.serviceOrders.items,
  serviceOrdersPagination: state.serviceOrders.serviceOrders.pagination,
  serviceOrdersSorting: state.serviceOrders.serviceOrders.sorting,
  filters: state.filters
});

const mapDispatchToProps = {
  fetchServiceOrders,
  pushPaginationHistory
};

ServiceOrderOverview.propTypes = {
  fetchServiceOrders: PropTypes.func,
  pushPaginationHistory: PropTypes.func,
  intl: intlShape
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl,
  withRouter
)(ServiceOrderOverview);
