import store from '../store';
export const entity = '[Troubleshooting guidance]';
export const FETCH_TROUBLESHOOTING_GUIDANCE = `${entity} Fetch`;
export const SET_TROUBLESHOOTING_GUIDANCE = `${entity} Set`;

export const fetchTroubleshootingGuidance = (failureID, testName) => ({
  type: FETCH_TROUBLESHOOTING_GUIDANCE,
  payload: {
    deviceID: store.getState().deviceDetails.device.id,
    failureID: failureID,
    testName: testName
  }
});

export const setTroubleshootingGuidance = data => ({
  type: SET_TROUBLESHOOTING_GUIDANCE,
  payload: data
});
