export const entity = '[Detailed metrics]';
export const FETCH_METRICS_DETAILED = `${entity} Fetch`;
export const SET_METRICS_DETAILED = `${entity} Set`;

/**
 * Trigger fetch of detailed metrics
 */
export const fetchMetricsDetailed = (timeSpan, count, deviceId) => ({
  type: FETCH_METRICS_DETAILED,
  payload: {
    deviceId,
    timeSpan,
    count
  }
});

/**
 * Set data as new metricsDetailed
 * @param {array} data New metricsDetailed
 */
export const setMetricsDetailed = data => ({
  type: SET_METRICS_DETAILED,
  payload: data
});
