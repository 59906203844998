import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import colorVariables from '../../../scss/_variables.module.scss';
import { useSafeListener } from '../../../hooks';

const Dropdown = ({
  values = [],
  placeholder = 'Select an option',
  onItemSelected = () => {},
  className = 'btn-primary',
  textColorValue = colorVariables['colors-getinge--white'],
  bgColorValue = 'transparent',
  borderColor = 'transparent',
  dropdownComponent
}) => {
  const [open, setOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(undefined);

  useEffect(() => {
    dropdownComponent.current = updateSelect;
  }, [selectedItem, dropdownComponent]);

  const handleDocumentClick = () => (open ? setOpen(false) : null);
  const handleDropdownClick = useCallback(() => {
    setOpen(!open);
  }, [open, setOpen]);
  const handleSelect = value => e => {
    e.preventDefault();
    setSelectedItem(value);
    onItemSelected && onItemSelected(value);
  };

  function updateSelect(value) {
    setSelectedItem({ value: value, text: value });
  }

  useSafeListener('click', handleDocumentClick);

  return (
    <div className="dropdown">
      <button
        className={['btn dropdown-toggle', className].join(' ')}
        type="button"
        aria-haspopup="true"
        aria-expanded={open}
        id="dropdownMenuButton"
        onClick={handleDropdownClick}
        style={{
          backgroundColor: bgColorValue,
          color: textColorValue,
          border: borderColor
        }}
      >
        {selectedItem
          ? selectedItem.text
          : placeholder
          ? placeholder
          : 'Dropdown button'}
      </button>
      <div
        className={['dropdown-menu', open ? 'show' : ''].join(' ')}
        aria-labelledby="dropdownMenuButton"
      >
        {values.map(item => (
          // eslint-disable-next-line
          <a
            className="dropdown-item"
            href="#"
            onClick={handleSelect(item)}
            key={item.value}
          >
            {item.text}
          </a>
        ))}
      </div>
    </div>
  );
};

Dropdown.propTypes = {
  values: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      text: PropTypes.string
    })
  ),
  placeholder: PropTypes.string,
  onItemSelected: PropTypes.func,
  variant: PropTypes.string
};

export default Dropdown;
