import { SET_DEVICE, FETCH_DEVICE } from '../actions/device';

export function deviceDetailsReducer(
  state = {
    device: null
  },
  action
) {
  switch (action.type) {
    case FETCH_DEVICE:
      return {
        ...state,
        device: null
      };
    case SET_DEVICE:
      return {
        ...state,
        device: action.payload
      };
    default:
      return state;
  }
}
