import {
  FETCH_USAGE_STATISTICS_DETAILED,
  setUsageStatisticsDetailed,
  entity
} from '../actions/usageStatisticsDetailed';
import { apiRequest, API_SUCCESS } from '../actions/api';
import { MetricsTimeParams } from '../../components/';
import { API_URLS } from './urls';
import {
  transformResponse,
  transformResponseForY2yData
} from './../../utils/transform';

const URL = API_URLS.UsageStatisticsDetailed;

const transformData = (data, isY2y) => {
  const result = {};
  for (let key in data) {
    Object.assign(result, {
      [key]: isY2y
        ? transformResponseForY2yData(data[key], 'usageStatisticsDetailed')
        : transformResponse(data[key], 'usageStatisticsDetailed')
    });
  }
  return result;
};

export const usageStatisticsDetailedMdl = ({ dispatch }) => next => action => {
  next(action);

  const { type } = action;

  switch (type) {
    case FETCH_USAGE_STATISTICS_DETAILED:
      const { count, deviceIds, patientCategories, timeSpan } = action.payload;
      const deviceIdsParams =
        (deviceIds &&
          deviceIds.length > 0 &&
          '&device_ids=' + deviceIds.join(',')) ||
        '';
      const selectedPatientCategory =
        patientCategories === null
          ? ''
          : `&patientCategories=${patientCategories.toLowerCase()}`;
      dispatch(
        apiRequest({
          method: 'GET',
          url:
            URL.url +
            MetricsTimeParams('usageStatisticsDetailed', timeSpan, count) +
            selectedPatientCategory +
            deviceIdsParams,
          requestSignature: 'usageStatisticsDetailed',
          entity: entity,
          filter: URL.filter
        })
      );
      break;
    case `${entity} GET ${API_SUCCESS}`:
      dispatch(
        setUsageStatisticsDetailed(
          action.payload.data
            ? {
                payloadData: transformData(action.payload.data, false),
                y2yData: transformData(action.payload.data, true)
              }
            : []
        )
      );
      break;
    // no default
  }
};
