import { SET_USAGE_STATISTICS } from '../actions/usageStatistics';

const initState = {
  usageStatistics: null
};

export const usageStatisticsReducer = (state = initState, action) => {
  const { payload, type } = action;

  switch (type) {
    case SET_USAGE_STATISTICS:
      return {
        ...state,
        usageStatistics: payload
      };
    default:
      return state;
  }
};
