export const entity = '[Devices]';
export const FETCH_DEVICES = `${entity} Fetch`;
export const SET_DEVICES = `${entity} Set`;

/**
 * Trigger fetch of devices
 */
export const fetchDevices = (paginationSettings, sortingSettings) => ({
  type: FETCH_DEVICES,
  pagination: paginationSettings,
  sorting: sortingSettings
});

/**
 * Set devices
 * @param {array} data New devices
 */
export const setDevices = data => ({
  type: SET_DEVICES,
  payload: data
});
