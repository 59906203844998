import { FETCH_CASES, setCases, entity } from '../actions/cases';
import { API_SUCCESS, apiRequest } from '../actions/api';
import { API_URLS } from './urls';

export const casesMdl = ({ dispatch }) => next => action => {
  next(action);

  const { type, pagination, sorting } = action;

  switch (type) {
    case FETCH_CASES:
      setCases([]);
      const { deviceId } = action.payload;
      const URL = API_URLS.Cases(deviceId);

      dispatch(
        apiRequest({
          method: 'GET',
          url:
            URL.url +
            '?page=' +
            (pagination.page + 1) +
            '&pageSize=' +
            pagination.pageSize +
            (sorting ? '&sort=' + sorting : ''),
          requestSignature: 'cases',
          entity: entity,
          filter: URL.filter
        })
      );
      break;
    case `${entity} GET ${API_SUCCESS}`:
      if (
        action.payload.data &&
        action.payload.data.pagination &&
        action.payload.data.size
      ) {
        action.payload.data.pagination.page = --action.payload.data.pagination
          .page;
        action.payload.data.pagination.totalItems = action.payload.data.size;
      }
      dispatch(setCases(action.payload.data || []));
      break;
    // no default
  }
};
