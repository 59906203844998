// Add column headers and define column keys and widths
export const runningHoursColumns = [
  { header: 'Hospital', key: 'hospital', width: 40 },
  { header: 'Department', key: 'departmentName', width: 40 },
  { header: 'Location', key: 'location', width: 40 },
  { header: 'Device', key: 'deviceType', width: 40 },
  { header: 'Serial no', key: 'serial', width: 20 },
  { header: 'Customer device no', key: 'customerID', width: 20 },
  { header: 'Last connected', key: 'lastConnected', width: 15 },
  { header: 'Total hours', key: 'totalHours', width: 15 },
  { header: 'From', key: 'from', width: 20 },
  { header: 'To', key: 'to', width: 20 },
  { header: 'Department filter selection', key: 'department', width: 40 },
  { header: 'Device filter selection', key: 'device', width: 40 }
];

// Define filename
export const runningHoursFileName = 'Running_hours.xlsx';
