import React from 'react';
import styles from './PrettyJSON.module.scss';
import propTypes from 'prop-types';

export const PrettyJSON = props => {
  const { data } = props;

  let result = (
    <div className={styles.header}>
      <strong>No result</strong>
    </div>
  );

  if (props.data) {
    let display = data;
    if (typeof data !== 'string') {
      display = JSON.stringify(data, null, 2);
    }

    result = (
      <>
        <div className={styles.header}>
          <strong>Result</strong>
        </div>
        <pre className={styles.body}>{display}</pre>
      </>
    );
  }

  return <div className={styles.prettyJSON}>{result}</div>;
};

PrettyJSON.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: propTypes.any
};
