import {
  FETCH_SERVICE_ORDERS,
  setServiceOrders,
  entity
} from '../actions/serviceOrders';
import { API_SUCCESS, API_ERROR, apiRequest } from '../actions/api';
import { API_URLS } from './urls';

const URL = API_URLS.ServiceOrders;

export const serviceOrdersMdl = ({ dispatch }) => next => action => {
  next(action);

  const { type, pagination, sorting } = action;

  switch (type) {
    case FETCH_SERVICE_ORDERS:
      dispatch(setServiceOrders([]));
      const { deviceId } = action.payload;

      dispatch(
        apiRequest({
          method: 'GET',
          url:
            URL.url +
            '?page=' +
            (pagination.page + 1) +
            '&pageSize=' +
            pagination.pageSize +
            (sorting ? '&sort=' + sorting : '') +
            (deviceId ? `&device_id=${deviceId}` : ''),
          requestSignature: 'serviceOrders',
          entity: entity,
          filter: URL.filter
        })
      );
      break;
    case `${entity} GET ${API_SUCCESS}`:
      if (
        action.payload.data &&
        action.payload.data.pagination &&
        action.payload.data.size
      ) {
        action.payload.data.pagination.page =
          action.payload.data.pagination.page - 1;
        action.payload.data.pagination.totalItems = action.payload.data.size;
      }
      dispatch(setServiceOrders(action.payload.data || []));
      break;
    case `${entity} GET ${API_ERROR}`:
      dispatch(setServiceOrders([]));
      break;
    // no default
  }
};
