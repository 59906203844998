import React, { useEffect, useState } from 'react';
import get from 'lodash.get';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { WithLoadingSpinner, WithSubscriptionInvitation } from '../../hoc';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { fetchTest, fetchProactiveTest } from '../../redux/actions/test';
import {
  TreeTable,
  Card,
  CardTabbed,
  CardSection,
  CardTab,
  EmptyState,
  Formatted24hTime,
  TestHeaderResult,
  subscriptionTypes
} from '../../components';
import { testIdFromPath } from '../../components/utils/IdFromPath/IdFromPath';
import { ContainerHeader, DeviceTestFailedWidget } from '../../components';
import { deviceTestShape } from '../../utils/proptypes/deviceTestShape';

import './Test.scss';

const DeviceTest = props => {
  const dispatch = useDispatch();

  const {
    test,
    proactive,
    history,
    location,
    pwDeviceTypes,
    device,
    config,
    authorization
  } = props;
  const ids = testIdFromPath(props.history.location.pathname);
  const proactiveWatchEnabled = pwDeviceTypes.some(type => {
    const deviceType = get(device, 'deviceType', null);
    return (
      deviceType !== null &&
      deviceType === type &&
      get(config, 'enableProactiveWatch', false)
    );
  });

  const [proactiveWatch, setProactiveWatch] = useState(
    proactiveWatchEnabled && decodePwURI(location.search)
  );
  const initialTabIndex = proactiveWatch ? 1 : 0;

  function decodePwURI(search) {
    const params = new URLSearchParams(search);
    return params.get('proactive-watch') !== null;
  }

  const TabNavigation = tabProactiveWatch => () => {
    tabProactiveWatch
      ? dispatch(fetchProactiveTest(ids.testId, ids.deviceId))
      : dispatch(fetchTest(ids.testId, ids.deviceId));
    if (proactiveWatch !== tabProactiveWatch) {
      setProactiveWatch(tabProactiveWatch);
      history.push({
        pathname: history.location.pathname,
        search: tabProactiveWatch && '?proactive-watch'
      });
    }
  };

  useEffect(() => {
    // handle back button
    if (history.action === 'POP') {
      setProactiveWatch(proactiveWatchEnabled && decodePwURI(location.search));
    }
  }, [history.action, location, proactiveWatchEnabled]);

  useEffect(() => {
    dispatch(fetchTest(ids.testId, ids.deviceId));
  }, [dispatch, ids.testId, ids.deviceId, authorization]);

  const tableColumns = [
    {
      dataField: 'name',
      heading: <FormattedMessage id="test.columnTest" defaultMessage="Test" />,
      fixedWidth: true,
      percentageWidth: 55
    },
    {
      dataField: 'start',
      heading: (
        <FormattedMessage id="test.columnStart" defaultMessage="Start" />
      ),
      fixedWidth: true,
      percentageWidth: 15
    },
    {
      dataField: 'intervalFormatted',
      heading: (
        <FormattedMessage
          id="test.columnSCOInterval"
          defaultMessage="SCO Interval"
        />
      ),
      fixedWidth: true,
      percentageWidth: 15,
      styleClass: 'text-right'
    },
    {
      dataField: 'valueFormatted',
      heading: (
        <FormattedMessage id="test.columnValue" defaultMessage="Value" />
      ),
      fixedWidth: true,
      percentageWidth: 15
    },
    {
      dataField: 'resultCell',
      heading: (
        <FormattedMessage id="test.columnResult" defaultMessage="Result" />
      ),
      fixedWidth: true,
      percentageWidth: 15
    }
  ];

  if (proactiveWatch) {
    // insert PW column to 3rd before last position
    tableColumns.splice(tableColumns.length - 2, 0, {
      dataField: 'pwIntervalFormatted',
      heading: (
        <FormattedMessage
          id="test.columnPWInterval"
          defaultMessage="Proactive Interval"
        />
      ),
      fixedWidth: true,
      percentageWidth: 15,
      styleClass: 'text-right'
    });
  }

  const tableControl = {
    tableClasses: ['table', 'sticky-header', 'gtg-pre-use-check-table'],
    buttonClasses: 'btn btn-default',
    showButton: false
  };

  const TestCardSection = props => {
    return (
      <CardSection dataCy={props?.dataCy}>
        <WithLoadingSpinner when={['test', 'proactive']}>
          <div className="row justify-content-between">
            {get(props?.data, 'failed.length', 0) !== 0 ? (
              <DeviceTestFailedWidget failedTests={props?.data?.failed} />
            ) : (
              <div />
            )}
            {props.proactiveWatch &&
              get(props?.data, 'failedProactiveWatch.failed.length', 0) !==
                0 && (
                <DeviceTestFailedWidget
                  failedTests={props?.data?.failedProactiveWatch?.failed}
                  proactiveWatch
                />
              )}
          </div>
          <TreeTable
            columns={tableColumns}
            tableData={props?.data?.children}
            control={tableControl}
          />
        </WithLoadingSpinner>
      </CardSection>
    );
  };

  const TestTitle = props => {
    const { data } = props;

    return (
      <ContainerHeader headerTitle={data && data.name} contentAlign="left">
        <span className="gtg-test-header-field gtg-test-header-date">
          <FormattedDate value={data && data.start} timeZone="UTC" />
        </span>
        <span className="gtg-test-header-field gtg-test-header-time">
          <Formatted24hTime value={data && data.start} timeZone="UTC" /> -{' '}
          <Formatted24hTime value={data && data.end} timeZone="UTC" />
        </span>
        <span className="gtg-test-header-field gtg-test-header-result">
          <TestHeaderResult result={data && data.result} />
        </span>
      </ContainerHeader>
    );
  };

  return (
    <>
      <WithLoadingSpinner when={['test', 'proactive']}>
        {(proactiveWatch && proactive && <TestTitle data={proactive} />) ||
          (test && <TestTitle data={test} />)}
      </WithLoadingSpinner>
      <div className="row">
        <div className="col-12 col-md-12">
          {(test || proactive) && (
            <div>
              {(test?.name === 'System checkout' ? (
                <CardTabbed
                  dataCy="systemCheckoutCardTabbed"
                  currentTabIndex={initialTabIndex}
                >
                  <CardTab
                    title={
                      <FormattedMessage
                        id="test.systemCheckout"
                        defaultMessage="System checkout"
                      />
                    }
                    customOnTabClick={TabNavigation(false)}
                  >
                    <TestCardSection
                      {...props}
                      proactiveWatch={false}
                      data={test && test}
                      dataCy="systemCheckoutCardSection"
                    />
                  </CardTab>
                  {proactiveWatchEnabled && (
                    <CardTab
                      title={
                        <FormattedMessage
                          id="test.proactiveWatch"
                          defaultMessage="Proactive watch"
                        />
                      }
                      customOnTabClick={TabNavigation(true)}
                    >
                      <div className="gtg-device-proactive-card">
                        <WithSubscriptionInvitation
                          accessWidget={'proactive watch'}
                          trigger={[subscriptionTypes.premium]}
                          subscriptions={
                            device && [
                              {
                                count: 1,
                                level: get(device, 'subscription', 'standard'),
                                priority: 1
                              }
                            ]
                          }
                          customTitleForInvitation={
                            'proactiveWatch.proactiveWatchTitle'
                          }
                          customSubtitleForInvitation={
                            'proactiveWatch.proactiveWatchSubTitle'
                          }
                        >
                          <TestCardSection
                            {...props}
                            proactiveWatch
                            data={proactive && proactive}
                            dataCy="proactiveWatchCardSection"
                          />
                        </WithSubscriptionInvitation>
                      </div>
                    </CardTab>
                  )}
                </CardTabbed>
              ) : (
                <Card dataCy="preUseCheckCard">
                  <TestCardSection
                    dataCy="preUseCheckCardSection"
                    data={test && test}
                    {...props}
                  />
                </Card>
              )) || (
                <Card>
                  <CardSection>
                    <EmptyState />
                  </CardSection>
                </Card>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = state => ({
  test: state.test.test,
  proactive: state.test.proactive,
  pwDeviceTypes: get(state, 'accessInfo.pwDeviceTypes', []),
  config: state.config,
  authorization: state.authorization.jwt
});

DeviceTest.propTypes = {
  test: deviceTestShape,
  proactive: deviceTestShape,
  history: PropTypes.shape({}),
  location: PropTypes.shape({}),
  currentAccountId: PropTypes.string
};

export default compose(connect(mapStateToProps), withRouter)(DeviceTest);
