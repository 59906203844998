export const entity = '[Preventive maintenance]';
export const FETCH_PREVENTIVE_MAINTENANCE = `${entity} Fetch`;
export const SET_PREVENTIVE_MAINTENANCE = `${entity} Set`;

/**
 * Trigger fetch of preventive maintenance
 */
export const fetchPreventiveMaintenance = (
  deviceId,
  paginationSettings,
  sortingSettings
) => ({
  type: FETCH_PREVENTIVE_MAINTENANCE,
  pagination: paginationSettings,
  sorting: sortingSettings,
  payload: {
    deviceId: deviceId
  }
});

/**
 * Set data as new preventive maintenance
 * @param {array} data New preventive maintenance
 */
export const setPreventiveMaintenance = data => ({
  type: SET_PREVENTIVE_MAINTENANCE,
  payload: data
});
