import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fetchConfig } from '../../redux/actions/config';
import { Spinner } from '../../components';

/**
 * This HOC is used to do early application initialization like fetching the config file
 */
export const WithStartup = ({ children, fetchConfig, config }) => {
  useEffect(() => {
    fetchConfig();
  }, [fetchConfig]);

  return config ? children : <Spinner />;
};

WithStartup.propTypes = {
  fetchConfig: PropTypes.func.isRequired,
  config: PropTypes.shape()
};

const mapStateToProps = state => ({
  config: state.config
});

const mapDispatchToProps = {
  fetchConfig
};

export default connect(mapStateToProps, mapDispatchToProps)(WithStartup);
