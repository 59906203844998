import React from 'react';
import { connect } from 'react-redux';
import { useForm } from 'react-hook-form';
import classnames from 'classnames';
import styles from './CertificationUpload.module.scss';
import { uploadCertifications } from '../../redux/actions/admin';
import PropTypes from 'prop-types';

const CertificationUpload = props => {
  const { register, handleSubmit } = useForm();
  const onSubmit = data => {
    const fd = new FormData();
    fd.append('file', data.file[0]);

    props.uploadCertifications(fd);
  };

  let uploadResult = <></>;
  switch (props.uploadResult) {
    case false:
      uploadResult = <>Error uploading file</>;
      break;
    case true:
      uploadResult = <>File uploaded successfully</>;
      break;
    default:
      break;
  }

  return (
    <div className={styles.certificationUpload}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.certificationForm}>
          <input {...register('file')} type="file" />
          <input
            className={classnames('btn btn-info', styles.btnCertification)}
            type="submit"
          />
        </div>
      </form>

      {uploadResult}
    </div>
  );
};

const mapStateToProps = state => ({
  uploadResult: state.admin.uploadCertificationsResult
});
const mapDispatchToProps = {
  uploadCertifications: uploadCertifications
};

CertificationUpload.propTypes = {
  uploadResult: PropTypes.bool,
  uploadCertifications: PropTypes.func
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CertificationUpload);
