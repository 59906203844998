export const entity = '[Access Info]';
export const FETCH_PW_AVAILABLE_DEVICE_TYPES = `${entity} Fetch PW Device types`;
export const SET_PW_AVAILABLE_DEVICE_TYPES = `${entity} Set PW Device types`;

export const fetchPWAvailableDeviceTypes = accountId => ({
  type: FETCH_PW_AVAILABLE_DEVICE_TYPES,
  payload: { accountId }
});

export const setPWAvailableDeviceTypes = data => ({
  type: SET_PW_AVAILABLE_DEVICE_TYPES,
  payload: data
});
