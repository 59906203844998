import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Icon, Formatted24hTime, Capitalize } from '../..';

/**
 * Map system checkout data received from the API into a flat array
 * for the frontend.
 * @param {*} data
 */
export const mapTest = data => {
  data.children = mapTestChildren(data.children.reverse());
  return data;
};

function getRowStatus(row) {
  return (
    row.result &&
    (row.result === 'failed' || row.result === 'canceled'
      ? 'error'
      : row.result === 'watch' && 'warning')
  );
}

export function mapTestResult(result) {
  switch (result) {
    case 'passed':
      return <FormattedMessage id="common.passed" defaultMessage="Passed" />;
    case 'failed':
      return <FormattedMessage id="common.failed" defaultMessage="Failed" />;
    case 'canceled':
      return (
        <FormattedMessage id="common.canceled" defaultMessage="Canceled" />
      );
    case 'watch':
      return <FormattedMessage id="common.watch" defaultMessage="Watch" />;
    case 'not-connected':
      return (
        <FormattedMessage
          id="common.notConnected"
          defaultMessage="Not connected"
        />
      );
    case 'not-performed':
      return (
        <FormattedMessage
          id="common.notPerformed"
          defaultMessage="Not performed"
        />
      );
    default:
      return <React.Fragment>{Capitalize(result)}</React.Fragment>;
  }
}

function mapTestChildren(data) {
  return data.map(item => {
    const { children, ...dataItem } = item;
    const rowStatus = getRowStatus(dataItem);
    if (rowStatus) {
      // expand item if row has existing inherited rowStatus, or if it's top level
      dataItem.rowStatus = {
        status: rowStatus,
        expand: (!dataItem.parentIds || dataItem.rowStatus) && true
      };
    }
    if (children) {
      children.reverse().map(child => {
        // inherit parentStatus
        if (dataItem.rowStatus) {
          child.rowStatus = {
            parentStatus: dataItem.rowStatus.parentStatus
          };
        }
        // show row if parent has status
        if (rowStatus) {
          child.rowStatus = {
            parentStatus: rowStatus,
            show: true
          };
          if (child.measurementValues) {
            const childMeasurementStatus = getRowStatus(child);
            child.rowStatus.expand = true;
            child.measurementValues.map(val => {
              // inherit status first from measurement, otherwise from parent
              val.rowStatus = {
                parentStatus: childMeasurementStatus || rowStatus,
                show: true
              };
              const valRowStatus = getRowStatus(val);
              if (valRowStatus) {
                val.rowStatus.status = valRowStatus;
              }
              return val;
            });
          }
        }
        child.parentIds = item.parentIds
          ? [...item.parentIds, item.id]
          : [item.id];
        return child;
      });
    }
    if (
      ['test', 'check'].includes(dataItem.itemType) &&
      dataItem.checkFailureId
    ) {
      dataItem.troubleshooting = {
        checkFailureId: dataItem.checkFailureId,
        testName: dataItem.name
      };
    }
    if (dataItem.itemType === 'info') {
      dataItem.name = dataItem.info;
    }
    if (dataItem.itemType === 'check' && dataItem.check) {
      dataItem.name = dataItem.check;
    }
    if (dataItem.itemType === 'measurement') {
      // split measurement into measurementgroup and measurementgroupitems
      delete dataItem.start;
      const { measurementValues, ...dataItemMeasurement } = dataItem;
      if (measurementValues && measurementValues.length > 1) {
        dataItemMeasurement.itemType = 'measurementgroup';
        const newValues = measurementValues.map(val => {
          val.itemType = 'measurementgroupitem';
          if (dataItemMeasurement.rowStatus && !val.rowStatus) {
            // inherit either status or parentStatus from measurement if the value doesn't have status yet
            val.rowStatus = {
              parentStatus:
                dataItemMeasurement.rowStatus.status ||
                dataItemMeasurement.rowStatus.parentStatus
            };
          }
          return {
            data: val,
            children: []
          };
        });
        return {
          data: dataItemMeasurement,
          children: newValues
        };
      } else if (measurementValues && measurementValues.length === 1) {
        const val = measurementValues[0];
        return {
          data: { ...dataItemMeasurement, ...val },
          children: []
        };
      }
    }
    if (dataItem.itemType === 'dialogChoice') {
      const dialogChoiceName =
        (dataItem.choice &&
          dataItem.retryCounter &&
          [
            'Dialog choice:',
            dataItem.choice,
            '(' + dataItem.retryCounter + ')'
          ].join(' ')) ||
        'Dialog choice';
      const dialogChoiceDescription =
        dataItem.dialogMessage &&
        ['Dialog message:', dataItem.dialogMessage].join(' ');
      dataItem.name = (
        <div>
          <div className="my-1">{dialogChoiceName}</div>
          {dialogChoiceDescription && (
            <div className="mt-1 mb-2 muted">{dialogChoiceDescription}</div>
          )}
        </div>
      );
    }
    switch (dataItem.result) {
      case 'passed':
        dataItem.resultCell = (
          <div className="text-success">
            <FormattedMessage id="common.passed" defaultMessage="Passed" />
            <Icon name="success-outline" />
          </div>
        );
        break;
      case 'failed':
        dataItem.resultCell = (
          <div className="text-danger">
            <FormattedMessage id="common.failed" defaultMessage="Failed" />
            <Icon name="error-outline" />
          </div>
        );
        break;
      case 'canceled':
        dataItem.resultCell = (
          <div className="text-danger">
            <FormattedMessage id="common.canceled" defaultMessage="Canceled" />
            <Icon name="error-outline" />
          </div>
        );
        break;
      case 'watch':
        dataItem.resultCell = (
          <div className="text-warning">
            <FormattedMessage id="common.watch" defaultMessage="Watch" />
            <Icon name="warning-outline" />
          </div>
        );
        break;
      case 'not-connected':
        dataItem.resultCell = (
          <div>
            <FormattedMessage
              id="common.notConnected"
              defaultMessage="Not connected"
            />
            <Icon name="neutral-outline" />
          </div>
        );
        break;
      case 'not-performed':
        dataItem.resultCell = (
          <div>
            <FormattedMessage
              id="common.notPerformed"
              defaultMessage="Not performed"
            />
            <Icon name="not-performed" />
          </div>
        );
        break;
      default:
        break;
    }
    dataItem.start = dataItem.start && (
      <Formatted24hTime value={dataItem.start} timeZone="UTC" />
    );
    return {
      data: dataItem,
      children: children ? mapTestChildren(children) : []
    };
  });
}
