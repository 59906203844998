import { useEffect } from 'react';

const useSafeListener = (event, handler) => {
  useEffect(() => {
    document.addEventListener(event, handler);

    return function cleanup() {
      document.removeEventListener(event, handler);
    };
  });
};

export default useSafeListener;
