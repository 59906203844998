import React, { useCallback, useEffect } from 'react';
import ReactTable from 'react-table';
import withFixedColumns from './ReactTableFixedColumnsAndHeader';
import { Scrollable, uiConfig } from '../..';
import DataTablePagination from './pagination';
import classnames from 'classnames';
import { addWithOverflowTooltip } from './datatableHelper';
import './DataTableFixedColumnAndHeader.scss';
import { applyTooltipForColHeader } from '../TableHeaderTooltip/tableHeaderTooltip';

const ReactTableFixedColumnsAndHeader = withFixedColumns(ReactTable);
const DataTableFixedColumnsAndHeader = props => {
  useEffect(() => {
    let headerItems = document.getElementsByClassName('rt-thead -header');
    let table = document.getElementsByClassName('rt-table');
    let header = headerItems[0];
    let tableRow = document.getElementsByClassName('rt-td');
    let tableHeaderRow = document.getElementsByClassName('rt-tr');
    let tableBodyItems = document.getElementsByClassName('rt-tbody');
    const tableBody = document.getElementsByClassName('os-viewport');
    applyTooltipForColHeader();

    const onScrollEvent = () => {
      if (tableRow.length !== 0) {
        let paginationRowTopPos = tableRow[0].getBoundingClientRect();
        let offsetWidthVal = table[0] ? table[0].offsetWidth : 0;
        /* When page is scrolled vertically and table header goes beyond visible part of screen, do below */
        if (window.pageYOffset > paginationRowTopPos.top + 160) {
          header.classList.add('gtg-data-table-stick-headers');
          if (table[0]) {
            table[0].closest('div[class^="col-"]').style.cssText = `z-index: 3`;
          }
          header.style.cssText = `min-width: ${offsetWidthVal}px; max-width: ${offsetWidthVal}px; overflow: hidden; left: ${paginationRowTopPos.left}px;`;
          tableBodyItems[0].style.cssText = `margin-top: ${tableHeaderRow[0].offsetHeight}px`;
        }
        /* When page is scrolled vertically to top and table header comes in visible part of screen, do below */
        if (window.pageYOffset < paginationRowTopPos.top + 160) {
          header.classList.remove('gtg-data-table-stick-headers');
          if (table[0]) {
            table[0].closest('div[class^="col-"]').style.cssText = `z-index: 1`;
          }
          header.style.cssText = `min-width: ${offsetWidthVal}px; max-width: ${offsetWidthVal}px; overflow: visible; left: ${paginationRowTopPos.left}px;`;
          tableBodyItems[0].style.cssText = `margin-top: 0`;
        }
      }
    };

    const onResizeEvent = () => {
      /* On page resize this code will make sure the scrollable table header is also updated with the latest table size */
      let paginationRowTopPos = tableRow[0].getBoundingClientRect();
      let offsetWidthVal = table[0] ? table[0].offsetWidth : 0;
      header.style.cssText = `min-width: ${offsetWidthVal}px; max-width: ${offsetWidthVal}px; left: ${paginationRowTopPos.left}px;`;
    };

    const onTableHeaderScrollEvent = event => {
      /* When table header is horizontally scrolled, this will make sure the table body is also scrolled accordingly */
      if (tableBody[0] && tableHeaderRow[0]) {
        tableBody[0].scrollLeft = tableHeaderRow[0].scrollLeft =
          event.target.scrollLeft;
      }
    };

    const setRowHeaderWidth = () => {
      /* If tr-td, table row header width is more than table body, then set width for auto. Else header will get issue while scrolling header with fixed 2 cols */
      if (tableHeaderRow[0].offsetWidth > tableBody[0].offsetWidth) {
        tableHeaderRow[0].style.cssText = `width: auto;`;
      }
    };

    if (props.showPagination || props.manualPaginationSettings) {
      window.addEventListener('scroll', onScrollEvent);
      window.addEventListener('resize', onResizeEvent);
      tableBody[0] &&
        tableBody[0].addEventListener('scroll', onTableHeaderScrollEvent);
      tableHeaderRow[0] &&
        tableHeaderRow[0].addEventListener('scroll', onTableHeaderScrollEvent);
      setRowHeaderWidth();
    }

    return () => {
      if (props.showPagination || props.manualPaginationSettings) {
        window.removeEventListener('scroll', onScrollEvent);
        window.removeEventListener('resize', onResizeEvent);
        tableBody[0] &&
          tableBody[0].removeEventListener('scroll', onTableHeaderScrollEvent);
        tableHeaderRow[0] &&
          tableHeaderRow[0].removeEventListener(
            'scroll',
            onTableHeaderScrollEvent
          );
      }
    };
  }, [props.manualPaginationSettings, props.showPagination]);

  const pageSizeOptionsArr =
    (props.manualPaginationSettings &&
      props.manualPaginationSettings.customPageSizeOptions) ||
    props.pageSizeOptions ||
    uiConfig.pageSizeOptions;
  const CustomTableComponent = useCallback(
    ({ children, className, ...rest }) => {
      const currentRowCount =
        children[3].props.children[0].length || props.defaultPageSize;
      return (
        <div
          className={classnames('rt-table', className)}
          role="grid"
          {...rest}
        >
          <Scrollable minElementHeight={currentRowCount * 50}>
            {children}
          </Scrollable>
        </div>
      );
    },
    [props.defaultPageSize]
  );
  const manualPagination = props.manualPaginationSettings && {
    showPagination: true,
    showPaginationTop: true,
    manual: true,
    /* eslint-disable react-hooks/rules-of-hooks */
    onFetchData: useCallback(
      (state, instance) => {
        const sortingStr =
          state.sorted[0] &&
          ((state.sorted[0].asc && !state.sorted[0].desc) ||
          (state.sorted[0].asc === false && state.sorted[0].desc === false)
            ? ''
            : '-') + state.sorted[0].id;
        if (
          state.page !==
            props.manualPaginationSettings.paginationSettings.page ||
          state.pageSize !==
            props.manualPaginationSettings.paginationSettings.pageSize ||
          sortingStr !== props.manualPaginationSettings.sortingSettings
        ) {
          let fetchParams = [
            {
              page: state.page,
              pageSize: state.pageSize
            },
            sortingStr
          ];
          if (props.manualPaginationSettings.fetchParams) {
            fetchParams = [
              ...props.manualPaginationSettings.fetchParams,
              ...fetchParams
            ];
          }
          props.manualPaginationSettings.fetchMethod(...fetchParams);
        }
      },
      [props.manualPaginationSettings]
    ),
    defaultSorted: [
      {
        id: props.manualPaginationSettings.sortingSettings.substring(
          props.manualPaginationSettings.sortingSettings.charAt(0) === '-'
            ? 1
            : 0
        ),
        asc: props.manualPaginationSettings.sortingSettings.charAt(0) !== '-'
      }
    ],
    page: props.manualPaginationSettings.paginationSettings.page,
    pageSize: props.manualPaginationSettings.paginationSettings.pageSize,
    pages: props.manualPaginationSettings.paginationSettings.totalPages,
    totalItems: props.manualPaginationSettings.paginationSettings.totalItems
  };

  const columns = props.columns.map(addWithOverflowTooltip);

  return (
    <ReactTableFixedColumnsAndHeader
      {...props}
      {...manualPagination}
      minRows={1}
      PaginationComponent={DataTablePagination}
      TableComponent={CustomTableComponent}
      pageSizeOptions={pageSizeOptionsArr}
      defaultPageSize={pageSizeOptionsArr[0]}
      columns={columns}
    />
  );
};

export default DataTableFixedColumnsAndHeader;
