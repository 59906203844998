import { AgentLabelSubbed } from '..';

/**
 * hideColumnIfNoData: hide the whole column if there is no data for that gas in all cases
 */
export const CarbonMetric = {
  key: 'carbon_footprint',
  headerText: 'Carbon footprint',
  hideColumnIfNoData: true
};

const CaseMetrics = [
  {
    key: 'sev',
    headerText: 'SEV',
    hideColumnIfNoData: true,
    gasConsumptionDisplay: true
  },
  {
    key: 'des',
    headerText: 'DES',
    hideColumnIfNoData: true,
    gasConsumptionDisplay: true
  },
  {
    key: 'iso',
    headerText: 'ISO',
    hideColumnIfNoData: true,
    gasConsumptionDisplay: true
  },
  {
    key: 'avg_fio2',
    Header: () => AgentLabelSubbed('FiO2'),
    hideColumnIfNoData: true,
    gasConsumptionDisplay: false
  },
  {
    key: 'n2o',
    Header: () => AgentLabelSubbed('N2O'),
    hideColumnIfNoData: true,
    gasConsumptionDisplay: true
  },
  {
    key: 'avg_fgf',
    headerText: 'Avg FGF',
    gasConsumptionDisplay: true
  }
];

export default CaseMetrics;
