import {
  FETCH_PREVENTIVE_MAINTENANCE,
  setPreventiveMaintenance,
  entity
} from '../actions/preventiveMaintenance';
import { API_SUCCESS, apiRequest } from '../actions/api';
import { API_URLS } from './urls';

const URL = API_URLS.PreventiveMaintenance;

export const preventiveMaintenanceMdl = ({ dispatch }) => next => action => {
  next(action);

  const { type, pagination, sorting } = action;

  switch (type) {
    case FETCH_PREVENTIVE_MAINTENANCE:
      setPreventiveMaintenance([]);
      const { deviceId } = action.payload;

      dispatch(
        apiRequest({
          method: 'GET',
          url:
            URL.url +
            '?page=' +
            (pagination.page + 1) +
            '&pageSize=' +
            pagination.pageSize +
            (sorting ? '&sort=' + sorting : '') +
            (deviceId ? `&device_id=${deviceId}` : ''),
          requestSignature: 'preventiveMaintenance',
          entity: entity,
          filter: URL.filter
        })
      );
      break;
    case `${entity} GET ${API_SUCCESS}`:
      if (
        action.payload.data &&
        action.payload.data.pagination &&
        action.payload.data.size
      ) {
        action.payload.data.pagination.page =
          action.payload.data.pagination.page - 1;
        action.payload.data.pagination.totalItems = action.payload.data.size;
      }
      dispatch(setPreventiveMaintenance(action.payload.data || []));
      break;
    // no default
  }
};
