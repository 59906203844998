import React, { useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Link, withRouter } from 'react-router-dom';
import { injectIntl, intlShape } from 'react-intl';
import PropTypes from 'prop-types';

import { WithLoadingSpinner } from '../../hoc';
import { FiltersContainer } from '..';
import { fetchPreventiveMaintenance } from '../../redux/actions/preventiveMaintenance';
import { pushPaginationHistory } from '../../redux/actions/paginationHistory';
import {
  uiConfig,
  routePaths,
  ContainerHeader,
  PreventiveMaintenanceList,
  Card,
  CardSection,
  decodePaginationURI,
  Icon
} from '../../components';
import {
  createDocument,
  PREVENTIVE_MAINTENANCE_LIST,
  preventiveMaintenanceListFileName
} from '../../utils/export';

const PreventiveMaintenanceOverview = props => {
  const {
    preventiveMaintenance,
    filters,
    fetchPreventiveMaintenance,
    location,
    pushPaginationHistory,
    intl
  } = props;

  const handleFetchCallback = (...fetchParams) => {
    pushPaginationHistory(
      fetchParams[1].page,
      fetchParams[1].pageSize,
      fetchParams[2]
    );
    fetchPreventiveMaintenance(...fetchParams);
  };

  const initialPaginationSettings = {
    page: 0,
    pageSize: uiConfig.pageSizeOptions[0],
    sort: 'next'
  };
  const manualPaginationSettings = {
    fetchMethod: handleFetchCallback,
    fetchParams: [undefined],
    paginationSettings: props.preventiveMaintenancePagination,
    sortingSettings: props.preventiveMaintenanceSorting,
    customPageSizeOptions: uiConfig.pageSizeOptions
  };
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    fetchPreventiveMaintenance(
      undefined,
      {
        page: initialPaginationSettings.page,
        pageSize: initialPaginationSettings.pageSize
      },
      props.preventiveMaintenanceSorting || initialPaginationSettings.sort
    );
  }, [filters]);

  useEffect(() => {
    const urlPagination = decodePaginationURI(
      location.search,
      initialPaginationSettings
    );
    fetchPreventiveMaintenance(
      undefined,
      {
        page: urlPagination.page,
        pageSize: urlPagination.pageSize
      },
      urlPagination.sort
    );
  }, [location]);
  /* eslint-enable react-hooks/exhaustive-deps */

  const exportPreventiveMaintenanceData = useCallback(() => {
    createDocument(
      PREVENTIVE_MAINTENANCE_LIST,
      preventiveMaintenance || [],
      filters,
      preventiveMaintenanceListFileName,
      null,
      intl
    );
  }, [intl, preventiveMaintenance, filters]);

  return (
    <>
      <ContainerHeader
        headerTitle={routePaths.PreventiveMaintenanceOverview.label}
      >
        <FiltersContainer />
      </ContainerHeader>
      <div className="row">
        <div className="col-12 col-md-12">
          <Card dataCy="cardPreventiveMaintenance">
            <CardSection>
              <WithLoadingSpinner when="preventiveMaintenance">
                <PreventiveMaintenanceList
                  preventiveMaintenance={preventiveMaintenance}
                  manualPaginationSettings={manualPaginationSettings}
                  currentPageExtra={
                    <Link onClick={exportPreventiveMaintenanceData} to="#">
                      <Icon name="download-excel" size="sm" />
                    </Link>
                  }
                />
              </WithLoadingSpinner>
            </CardSection>
          </Card>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = state => ({
  preventiveMaintenance:
    state.preventiveMaintenance.preventiveMaintenance.items,
  preventiveMaintenancePagination:
    state.preventiveMaintenance.preventiveMaintenance.pagination,
  preventiveMaintenanceSorting:
    state.preventiveMaintenance.preventiveMaintenance.sorting,
  filters: state.filters
});

const mapDispatchToProps = {
  fetchPreventiveMaintenance,
  pushPaginationHistory
};

PreventiveMaintenanceOverview.propTypes = {
  fetchPreventiveMaintenance: PropTypes.func,
  pushPaginationHistory: PropTypes.func,
  filters: PropTypes.shape({
    accounts: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
        id: PropTypes.string,
        label: PropTypes.string
      })
    ),
    departments: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
        id: PropTypes.string,
        label: PropTypes.string
      })
    ),
    devices: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
        id: PropTypes.string,
        label: PropTypes.string
      })
    )
  }),
  preventiveMaintenance: PropTypes.arrayOf(
    PropTypes.shape({
      device: PropTypes.shape({
        department: PropTypes.shape({
          name: PropTypes.string,
          id: PropTypes.string
        }),
        customerDeviceID: PropTypes.string,
        id: PropTypes.string,
        serialID: PropTypes.string,
        type: PropTypes.string
      }),
      last: PropTypes.string,
      lastType: PropTypes.string,
      next: PropTypes.string,
      nextDue: PropTypes.number,
      runningHoursSince: PropTypes.number,
      maxRunningHours: PropTypes.number,
      status: PropTypes.string,
      battery: PropTypes.shape({
        memoryBackup: PropTypes.shape({
          last: PropTypes.string,
          next: PropTypes.string,
          status: PropTypes.string
        }),
        powerBackup: PropTypes.shape({
          last: PropTypes.string,
          next: PropTypes.string,
          status: PropTypes.string
        })
      }),
      cassetteMembrane: PropTypes.shape({
        last: PropTypes.string,
        strokes: PropTypes.number,
        status: PropTypes.string
      })
    })
  ),
  intl: intlShape
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl,
  withRouter
)(PreventiveMaintenanceOverview);
