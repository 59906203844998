import React from 'react';
import ReactTable from 'react-table';
import './pagination.scss';
import { Icon } from '../../../components';
import { FormattedMessage } from 'react-intl';

class DataTablePagination extends ReactTable.defaultProps.PaginationComponent {
  clickPrevious = () => {
    if (!this.props.canPrevious) return;
    this.changePage(this.props.page - 1);
  };

  clickNext = () => {
    if (!this.props.canNext) return;
    this.changePage(this.props.page + 1);
  };

  paginationCurrentPageResults = (
    firstPageItemIndex,
    lastPageItemIndex,
    dataLength
  ) => {
    return (
      <span className={'gtgPaginationCurrentResults'}>
        <FormattedMessage id="pagination.showing" defaultMessage="Showing" />{' '}
        {firstPageItemIndex} - {lastPageItemIndex}{' '}
        <FormattedMessage id="pagination.of" defaultMessage="of" />{' '}
        <strong>{dataLength}</strong>{' '}
        <FormattedMessage id="pagination.results" defaultMessage="results" />
      </span>
    );
  };

  render() {
    const {
      // Computed
      pages,
      // Props
      manual,
      totalItems,
      page,
      data,
      showPageSizeOptions,
      pageSizeOptions,
      pageSize,
      showPageJump,
      canPrevious,
      canNext,
      onPageSizeChange,
      renderPageJump,
      renderCurrentPage,
      renderTotalPagesCount,
      renderPageSizeOptions,
      currentPageExtra
    } = this.props;

    const firstIndex = page * pageSize + 1;
    const totalCount = (manual && totalItems) || data.length;
    const lastIndex =
      (page + 1) * pageSize < totalCount ? (page + 1) * pageSize : totalCount;
    const pageInputWidth =
      pages === undefined ? 6 : pages.toString().length + 6;

    return (
      <div className="gtgPagination" style={this.props.style}>
        <div className="left">
          <FormattedMessage
            id="common.rows"
            defaultMessage={this.props.rowsText}
          >
            {rowsText =>
              showPageSizeOptions &&
              renderPageSizeOptions({
                pageSize,
                rowsSelectorText: this.props.rowsSelectorText,
                pageSizeOptions,
                onPageSizeChange,
                rowsText: rowsText
              })
            }
          </FormattedMessage>
        </div>
        <div className="center">
          <span
            data-cy="btnPaginationPrev"
            className={
              'gtgPaginationBtnPrev' + (!canPrevious ? ' disabled' : '')
            }
            onClick={this.clickPrevious}
            alt={this.props.previousText}
          >
            <Icon
              name="arrow-left"
              size="sm"
              color={!canPrevious ? 'primary' : 'info'}
            />
          </span>
          <span className="pageInfo">
            {<FormattedMessage id="pagination.page" defaultMessage="Page" />}{' '}
            <div style={{ width: `${pageInputWidth}ch` }}>
              {showPageJump
                ? renderPageJump(this.getPageJumpProperties())
                : renderCurrentPage(page)}{' '}
            </div>
            {<FormattedMessage id="pagination.of" defaultMessage="of" />}{' '}
            {renderTotalPagesCount(pages)}
          </span>
          <span
            data-cy="btnPaginationNext"
            className={'gtgPaginationBtnNext' + (!canNext ? ' disabled' : '')}
            onClick={this.clickNext}
            alt={this.props.nextText}
          >
            <Icon
              name="arrow-right"
              size="sm"
              color={!canNext ? 'primary' : 'info'}
            />
          </span>
        </div>
        <div className="right">
          {firstIndex &&
            lastIndex &&
            this.paginationCurrentPageResults(
              firstIndex,
              lastIndex,
              totalCount
            )}
          <div className={currentPageExtra ? 'gtg-pagination-right-extra' : ''}>
            {currentPageExtra}
          </div>
        </div>
      </div>
    );
  }
}

export default DataTablePagination;
