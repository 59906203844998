import {
  FETCH_COSTS,
  setCosts,
  PUT_COSTS,
  entity,
  setTempCosts
} from '../actions/costs';
import { fetchCase } from '../actions/case';
import { fetchMetricsUsage } from '../actions/metricsUsage';
import { apiRequest, API_SUCCESS, API_ERROR } from '../actions/api';
import { API_URLS } from './urls';
import get from 'lodash.get';

const URL = API_URLS.Accounts;

export const costsMdl = ({ dispatch, getState }) => next => action => {
  next(action);

  const url = `${URL.url}/${get(
    getState(),
    'persistentState.persistentState.deviceFilterAccount[0].id',
    ''
  )}/costs`;
  const { type } = action;
  switch (type) {
    case FETCH_COSTS:
      dispatch(
        apiRequest({
          method: 'GET',
          url,
          requestSignature: 'costs',
          entity: entity
        })
      );
      break;
    case PUT_COSTS:
      const { costs } = action.payload;
      dispatch(
        apiRequest({
          method: 'PUT',
          url,
          body: costs,
          entity: entity,
          filter: URL.filter
        })
      );
      // Set the new costs as temporary data until we get a response.
      // This way the UX is much clearer, the user does not have to wait for request to finish up.
      dispatch(setTempCosts(action.payload));
      break;
    case `${entity} GET ${API_SUCCESS}`:
      dispatch(setCosts(action.payload.data));
      break;
    case `${entity} PUT ${API_SUCCESS}`:
      // We set the temporary data as the new data because the request was sucessfull.
      const newCosts = get(getState(), 'costs.tempCosts');
      const currentCaseId = get(getState(), 'case.case.id');
      const currentDeviceId = get(getState(), 'case.case.deviceID');
      if (newCosts) {
        dispatch(setCosts(newCosts));
        dispatch(setTempCosts()); // Clear temporary costs.

        // When put success is succesful. The costs sent on the api will update other endpoints (metricsUsage, case).
        // Because of that we refetch the data that is updated.
        currentCaseId && dispatch(fetchCase(currentDeviceId, currentCaseId));
        const state = getState();
        dispatch(
          fetchMetricsUsage(
            get(
              state,
              'persistentState.persistentState.usageStatisticsTimeSpan'
            ),
            get(
              state,
              'persistentState.persistentState.usageStatisticsTimeSpanCount'
            )
          )
        );
      }
      break;
    case `${entity} PUT ${API_ERROR}`:
      // If request fails we can clean up temporary costs.
      dispatch(setTempCosts());
      break;
    // no default
  }
};
