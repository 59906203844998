import React, { useCallback } from 'react';
import './DeviceInformation.module.scss';
import DeviceInformationEditForm from './DeviceInformationEditForm';

const DeviceInformationLocation = props => {
  const { location, onLocationSubmit } = props;

  const handleLocationSubmit = useCallback(
    location => {
      onLocationSubmit(location);
    },
    [onLocationSubmit]
  );

  return (
    <>
      {props && (
        <DeviceInformationEditForm
          type="text"
          defaultValue={location}
          handleSubmit={handleLocationSubmit}
        />
      )}
    </>
  );
};

DeviceInformationLocation.propTypes = {};

export default DeviceInformationLocation;
