import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { Icon } from '../../../components';
import './EditButton.scss';

const EditButton = ({ onClick }) => (
  <button
    onClick={onClick}
    className="btn gtg-edit-button"
    data-cy="editButton"
  >
    <Icon name="edit" />
    <FormattedMessage id="common.edit" defaultMessage="Edit" />
  </button>
);

EditButton.propTypes = {
  onClick: PropTypes.func.isRequired
};

export default EditButton;
