import {
  FETCH_ACCOUNTS,
  FETCH_DEPARTMENTS,
  FETCH_DEVICE_TYPES,
  SET_ACCOUNTS,
  SET_DEPARTMENTS,
  SET_DEVICE_TYPES
} from '../actions/deviceFilters';

const initialState = {
  accounts: null,
  departments: null,
  deviceTypes: null
};

export const deviceFiltersReducer = (state = initialState, action) => {
  const { type } = action;

  switch (type) {
    case FETCH_ACCOUNTS:
      return {
        ...state,
        accounts: null
      };
    case FETCH_DEPARTMENTS:
      return {
        ...state,
        departments: null
      };
    case FETCH_DEVICE_TYPES:
      return {
        ...state,
        deviceTypes: null
      };
    case SET_ACCOUNTS:
      return {
        ...state,
        accounts: action.payload,
        departments: null
      };
    case SET_DEPARTMENTS:
      return {
        ...state,
        departments: action.payload
      };
    case SET_DEVICE_TYPES:
      return {
        ...state,
        deviceTypes: action.payload
      };
    default:
      return state;
  }
};
