import React, { useEffect, useCallback, useState, Children } from 'react';
import { Tabs, useTabState, usePanelState } from '../../utils/Tabs/Tabs';
import { KeyFromFormattedMessage } from '../..';

import styles from './Card.module.scss';

const CardTab = ({ children, hasChildren, customOnTabClick }) => {
  const { onClick, isActive } = useTabState();
  const onTabClick = useCallback(() => {
    onClick();
    if (customOnTabClick) {
      customOnTabClick();
    }
  }, [customOnTabClick, onClick]);
  return (
    <button
      onClick={onTabClick}
      aria-disabled={!hasChildren}
      aria-current={isActive}
    >
      {children}
    </button>
  );
};

const CardTabPanel = ({ children }) => {
  const isActive = usePanelState();
  return isActive ? <>{children}</> : null;
};

const CardTabbed = ({
  children,
  currentTabIndex,
  title,
  dataCy,
  customClass
}) => {
  currentTabIndex = currentTabIndex ? currentTabIndex : 0;
  const [index, setIndex] = useState(currentTabIndex);
  const tabs = Children.toArray(children);
  const additionalClass = customClass ? customClass : '';

  useEffect(() => {
    setIndex(currentTabIndex);
  }, [currentTabIndex]);

  return (
    <div
      className={styles.gtgCard + ' card ' + additionalClass}
      data-cy={dataCy}
    >
      <Tabs state={[index, setIndex]}>
        <header className={styles.gtgCardWithHeader}>
          {title && <h5>{title}</h5>}
          {tabs.map(tab => (
            <CardTab
              key={'tab-' + KeyFromFormattedMessage(tab.props.title)}
              hasChildren={tab.props.children}
              customOnTabClick={tab.props.customOnTabClick}
            >
              {tab.props.title}
            </CardTab>
          ))}
        </header>
        {tabs.map(tab => (
          <CardTabPanel
            key={'panel-' + KeyFromFormattedMessage(tab.props.title)}
          >
            {tab.props.children ? tab.props.children : null}
          </CardTabPanel>
        ))}
      </Tabs>
    </div>
  );
};

export default CardTabbed;
