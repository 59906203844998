import {
  FETCH_SERVICE_ORDERS_OVERVIEW,
  setServiceOrdersOverview,
  entity
} from '../actions/serviceOrdersOverview';
import { apiRequest, API_SUCCESS } from '../actions/api';
import { API_URLS } from './urls';

const URL = API_URLS.ServiceOrdersOverview;

export const serviceOrdersOverviewMdl = ({ dispatch }) => next => action => {
  next(action);

  const { type } = action;

  switch (type) {
    case FETCH_SERVICE_ORDERS_OVERVIEW:
      dispatch(
        apiRequest({
          method: 'GET',
          url: URL.url,
          requestSignature: 'serviceOrdersOverview',
          entity: entity,
          filter: URL.filter
        })
      );
      break;
    case `${entity} GET ${API_SUCCESS}`:
      dispatch(setServiceOrdersOverview(action.payload.data));
      break;
    // no default
  }
};
