import React from 'react';
import { NavLink } from 'react-router-dom';
import classnames from 'classnames';
import propTypes from 'prop-types';
import { routePaths, PathJoin, isFlowFamily } from '..';

import './DeviceNavigation.scss';
import { Device } from '../../hoc/withAbilityContext/rule-builder';
import useAbility from '../../hooks/useAbility';
import { isRotaflowFamily } from '../utils/Device/deviceHelper';

const DeviceNavigation = props => {
  const { device } = props;

  // Get the ability context in order to check for permission to
  // view all tests for a device type
  const ability = useAbility();
  let canSeeAllDeviceLogs = false;
  if (device && ability) {
    canSeeAllDeviceLogs = ability.can(
      Device.VIEW_ALL_LOGS,
      new Device(device.deviceType)
    );
  }

  const deviceTabs = [
    {
      path: routePaths.DeviceDashboard.path,
      label: routePaths.DeviceDashboard.label,
      exactMatch: true
    },
    {
      path: routePaths.DeviceTestOverview.path,
      label: routePaths.DeviceTestOverview.label,
      preventAccess: !device
    },
    {
      path: routePaths.DeviceLogOverview.path,
      label: routePaths.DeviceLogOverview.label,
      preventAccess: !canSeeAllDeviceLogs || !device
    },
    {
      path: routePaths.DeviceServiceOrderOverview.path,
      label: routePaths.DeviceServiceOrderOverview.label,
      preventAccess: !device || isRotaflowFamily(device.deviceType)
    },
    {
      path: routePaths.DeviceCaseOverview.path,
      label: routePaths.DeviceCaseOverview.label,
      preventAccess: !device || !isFlowFamily(device.deviceType)
    }
  ];

  return (
    <div className="gtg-device-navigation">
      {device &&
        deviceTabs
          .filter(tab => !tab['preventAccess'])
          .map(tab => {
            return (
              <NavLink
                className={classnames(
                  'gtg-device-navigation-link',
                  tab.disabled && 'disabled'
                )}
                exact={tab.exactMatch && true}
                activeClassName="active"
                key={device.id + tab.path}
                to={PathJoin([
                  routePaths.DeviceOverview.path,
                  device.id,
                  tab.path
                ])}
              >
                {tab.label}
              </NavLink>
            );
          })}
    </div>
  );
};

DeviceNavigation.propTypes = {
  device: propTypes.shape({
    deviceType: propTypes.string,
    serialID: propTypes.string,
    department: propTypes.shape({
      name: propTypes.string
    })
  })
};

export default DeviceNavigation;
