import { deviceFamilies } from './components/utils/Device/deviceHelper';

export const userRoles = {
  GetingeOnline_ServiceTechnician: 'GetingeOnline-ACT-ServiceTechnician',
  GetingeOnline_ServiceAccess: 'GetingeOnline-ACT-ServiceAccess',
  GetingeOnline_Clinician: 'GetingeOnline-ACT-Clinician',
  getLabelFor: role => {
    switch (role) {
      case userRoles.GetingeOnline_ServiceTechnician:
        return 'ServiceTechnician';
      case userRoles.GetingeOnline_ServiceAccess:
        return 'ServiceAccess';
      case userRoles.GetingeOnline_Clinician:
        return 'Clinician';
      default:
        return role;
    }
  },
  getDisplayRoleFor: role => {
    switch (role) {
      case userRoles.GetingeOnline_ServiceTechnician:
        return 'Biomed';
      case userRoles.GetingeOnline_ServiceAccess:
        return 'Service Access';
      case userRoles.GetingeOnline_Clinician:
        return 'Clinician';
      default:
        return role;
    }
  }
};

const uiConfig = {
  pageSizeOptions: [50, 100],
  dashboardWidgetsConfig: [
    {
      userRoles: [
        {
          userRole: userRoles.GetingeOnline_Clinician
        }
      ],
      config: [
        { widgetName: 'devicesStatusOverview', fullWidth: true },
        { widgetName: 'subscriptionInvitation', fullWidth: true },
        { widgetName: 'usageStatistics', fullWidth: true },
        { widgetName: 'deviceList', fullWidth: true },
        { widgetName: 'serviceOrderList', fullWidth: true },
        { widgetName: 'preventiveMaintenanceList', fullWidth: true },
        { widgetName: 'runningHoursList', fullWidth: true }
      ]
    },
    {
      userRoles: [
        {
          userRole: userRoles.GetingeOnline_ServiceAccess
        },
        {
          userRole: userRoles.GetingeOnline_ServiceTechnician
        }
      ],
      config: [
        { widgetName: 'devicesStatusOverview', fullWidth: true },
        { widgetName: 'subscriptionInvitation', fullWidth: true },
        { widgetName: 'deviceList', fullWidth: true },
        { widgetName: 'serviceOrderList', fullWidth: true },
        { widgetName: 'preventiveMaintenanceList', fullWidth: true },
        { widgetName: 'usageStatistics', fullWidth: true },
        { widgetName: 'runningHoursList', fullWidth: true }
      ]
    }
  ],
  deviceDashboardWidgetsConfig: [
    {
      userRoles: [
        {
          userRole: userRoles.GetingeOnline_Clinician,
          checkCertification: false
        },
        {
          userRole: userRoles.GetingeOnline_ServiceTechnician,
          checkCertification: true,
          certified: false
        }
      ],
      config: [
        {
          widgetName: 'deviceTestListWidget'
        },
        { widgetName: 'deviceDocumentationWidget' },
        {
          widgetName: 'deviceServiceOrderListWidget',
          fullWidth: true,
          hideForDeviceFamilies: [deviceFamilies.rotaflow.name]
        },
        { widgetName: 'deviceStatusWidget', fullWidth: true },
        {
          widgetName: 'devicePreventiveMaintenanceListWidget',
          fullWidth: true
        },
        {
          widgetName: 'deviceUsageStatisticsWidget',
          fullWidth: true,
          hideForDeviceFamilies: [
            deviceFamilies.servo.name,
            deviceFamilies.rotaflow.name
          ]
        },
        {
          widgetName: 'deviceUsageStatisticsServoWidget',
          fullWidth: true,
          hideForDeviceFamilies: [
            deviceFamilies.flow.name,
            deviceFamilies.rotaflow.name
          ]
        }
      ]
    },
    {
      userRoles: [
        {
          userRole: userRoles.GetingeOnline_ServiceAccess,
          checkCertification: false
        },
        {
          userRole: userRoles.GetingeOnline_ServiceTechnician,
          checkCertification: true,
          certified: true
        }
      ],
      config: [
        {
          widgetName: 'deviceTestListWidget'
        },
        { widgetName: 'deviceLogListWidget' },
        { widgetName: 'deviceStatusWidget', fullWidth: true },
        {
          widgetName: 'devicePreventiveMaintenanceListWidget'
        },
        { widgetName: 'deviceDocumentationWidget' },
        {
          widgetName: 'deviceServiceOrderListWidget',
          fullWidth: true,
          hideForDeviceFamilies: [deviceFamilies.rotaflow.name]
        },
        {
          widgetName: 'deviceUsageStatisticsWidget',
          fullWidth: true,
          hideForDeviceFamilies: [
            deviceFamilies.servo.name,
            deviceFamilies.rotaflow.name
          ]
        },
        {
          widgetName: 'deviceUsageStatisticsServoWidget',
          fullWidth: true,
          hideForDeviceFamilies: [
            deviceFamilies.flow.name,
            deviceFamilies.rotaflow.name
          ]
        }
      ]
    }
  ]
};

export default uiConfig;

export const deviceStatuses = {
  ok: 'OK',
  unknown: 'UNKNOWN',
  connection: 'CONNECTION',
  warning: 'WARNING',
  error: 'ERROR'
};

export const subscriptionTypes = {
  standard: 'standard',
  advanced: 'advanced',
  premium: 'premium'
};
