import { FETCH_DEVICES, setDevices, entity } from '../actions/devices';
import { API_SUCCESS, apiRequest } from '../actions/api';
import { API_URLS } from './urls';
import { devicesMapper } from '../../components';

const URL = API_URLS.Devices;

export const devicesMdl = ({ dispatch }) => next => action => {
  next(action);

  const { type, pagination, sorting } = action;

  switch (type) {
    case FETCH_DEVICES:
      dispatch(
        apiRequest({
          method: 'GET',
          url:
            URL.url +
            '?page=' +
            (pagination.page + 1) +
            '&pageSize=' +
            pagination.pageSize +
            ((sorting && '&sort=' + sorting) || ''),
          requestSignature: 'devices',
          entity: entity,
          filter: true
        })
      );
      break;
    case `${entity} GET ${API_SUCCESS}`:
      if (
        action.payload.data &&
        action.payload.data.pagination &&
        action.payload.data.size
      ) {
        action.payload.data.pagination.page =
          action.payload.data.pagination.page - 1;
        action.payload.data.pagination.totalItems = action.payload.data.size;
      }
      if (action.payload.data && action.payload.data.items) {
        action.payload.data.items = devicesMapper(action.payload.data.items);
      }
      dispatch(setDevices(action.payload.data));
      break;
    // no default
  }
};
