import React, { useState, useCallback } from 'react';
import { Tooltip } from '../../components';
import PropTypes from 'prop-types';

/**
 * Displays a tooltip when hovered.
 * @param tooltipContent The content that should be displayed in the tooltip
 * @param tooltipLocation Where should the tooltip be displayed on 'top' (default) or 'bottom' of the element.
 * @param tooltipFixedArrow Whether or not the arrow location is fixed to the top/bottom left (not top/bottom middle)
 * @param tooltipSmall Change the appearance of the tooltip, by reducing padding and using a smaller font size
 */
const WithTooltip = ({
  children,
  tooltipContent,
  tooltipLocation,
  tooltipFixedArrow,
  tooltipSmall,
  className
}) => {
  const [hover, setHover] = useState(false);
  const handleMouseEnter = useCallback(() => {
    setHover(true);
  }, [setHover]);
  const handleMouseLeave = useCallback(() => {
    setHover(false);
  }, [setHover]);

  return (
    <div
      style={{ position: 'relative' }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className={className}
    >
      {hover && tooltipContent && (
        <Tooltip
          content={tooltipContent}
          location={tooltipLocation}
          fixedArrow={tooltipFixedArrow}
          small={tooltipSmall}
        />
      )}
      {children}
    </div>
  );
};

WithTooltip.propTypes = {
  tooltipContent: PropTypes.node,
  tooltipLocation: PropTypes.oneOf(['top', 'bottom']),
  tooltipFixedArrow: PropTypes.bool,
  tooltipSmall: PropTypes.bool
};

export default WithTooltip;
