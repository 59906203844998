/**
 * A timeformat that can be used to format <FormattedDate /> or intl.formatDate
 * @param timeSpan The timespan that you want to get a format for
 */
function TimeFormat(timeSpan) {
  switch (timeSpan) {
    case 'week':
    case 'month':
      return { day: 'numeric', month: 'numeric' };
    case 'year':
    case 'y2y':
      return { month: 'long', year: 'numeric' };
    default:
      return undefined;
  }
}

export const shortMonthLongYear = { month: 'short', year: 'numeric' };

export default TimeFormat;
