/**
 * Attempts to return the browser language that the user is using. Newer browsers contain an array of
 * languages, ordered by priority, if this matches we take the first one. If undefined, we try to get
 * the userLanguage or just navigator.language. If this fails, we check browserLanguage (older IE
 * browsers). If no match is found, we default to 'en'.
 */
export const getNavigatorLanguage = navigator => {
  const lang =
    navigator.languages && navigator.languages.length
      ? navigator.languages[0]
      : navigator.userLanguage ||
        navigator.language ||
        navigator.browserLanguage ||
        'en';

  const langWithoutPostfix = lang.split('-')[0];
  return langWithoutPostfix;
};
