import { AgentKeyMapper } from '..';
import moment from 'moment';

function NodeHintDataMapper(node, data = []) {
  const labelYear = moment(node.xTimeStamp).format('Y');
  return data.map(a => ({
    icon: AgentKeyMapper(a.agent).icon,
    name: AgentKeyMapper(a.agent).labelShort,
    value:
      (a.data.find(i => i.x === node.x) &&
        a.data.find(i => i.x === node.x).value) ||
      'N/A',
    year: a.year === 'previous' ? labelYear - 1 : labelYear
  }));
}

export default NodeHintDataMapper;
