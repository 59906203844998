import React, { useCallback } from 'react';
import classnames from 'classnames';
import { withRouter } from 'react-router-dom';
import { injectIntl, FormattedMessage, FormattedDate } from 'react-intl';
import { WithLoadingSpinner } from '../../hoc';
import { Icon, getDeviceTypeDisplayName } from '..';
import DeviceInformationCustomerID from './DeviceInformationCustomerID';
import propTypes from 'prop-types';
import DeviceInformationLocation from './DeviceInformationLocation';
import BadgeLabelComponent from '../Badges/BadgeLabelComponent';
import get from 'lodash.get';
import { compose } from 'redux';
import { connect } from 'react-redux';

import styles from './DeviceInformation.module.scss';

const DeviceInformation = props => {
  const { device, onCustomerIdSubmit, onLocationSubmit } = props;
  const handleCustomerIdSubmit = useCallback(
    id => {
      onCustomerIdSubmit(id);
    },
    [onCustomerIdSubmit]
  );

  const handleLocationSubmit = useCallback(
    location => {
      onLocationSubmit(location);
    },
    [onLocationSubmit]
  );

  return (
    <WithLoadingSpinner when="deviceInformation">
      {device && (
        <>
          <header className={styles.gtgDeviceInformation}>
            <h2>{getDeviceTypeDisplayName(device.deviceType)}</h2>
            <div
              data-cy={'divDeviceInformation'}
              className={classnames(
                'gtg-container-header-content',
                'justify-content-end'
              )}
            >
              <section>
                <label>
                  <Icon name="serial" size="sm" />
                  <FormattedMessage
                    id="deviceInformation.serialNumber"
                    defaultMessage="Serial number"
                  />
                </label>
                <span>{device.serialID}</span>
              </section>
              {device.department && (
                <section>
                  <label>
                    <Icon name="department" size="sm" />
                    <FormattedMessage
                      id="deviceInformation.department"
                      defaultMessage="Department"
                    />
                  </label>
                  <span>{device.department.name}</span>
                </section>
              )}
              <section>
                <label>
                  <Icon name="location" size="sm" />
                  <FormattedMessage
                    id="deviceInformation.location"
                    defaultMessage="Location"
                  />
                </label>
                <DeviceInformationLocation
                  onLocationSubmit={handleLocationSubmit}
                  location={device.location}
                />
              </section>
              <section>
                <label>
                  <Icon name="tag" size="sm" />
                  <FormattedMessage
                    id="deviceInformation.customerID"
                    defaultMessage="Customer ID"
                  />
                </label>
                <DeviceInformationCustomerID
                  onCustomerIdSubmit={handleCustomerIdSubmit}
                  customerDeviceID={device.customerDeviceID}
                />
              </section>
            </div>
          </header>
          {get(props, 'config.enableSubscriptions', false) && (
            <BadgeLabelComponent
              subscriptionType={device.subscription}
              expirationDate={device.subscriptionExpirationDate}
            />
          )}
        </>
      )}
    </WithLoadingSpinner>
  );
};

DeviceInformation.propTypes = {
  device: propTypes.shape({
    deviceType: propTypes.string,
    serialID: propTypes.string,
    department: propTypes.shape({
      name: propTypes.string
    }),
    customerDeviceID: propTypes.string,
    subscriptionExpirationDate: propTypes.string
  })
};

const mapStateToProps = state => ({
  config: state.config
});

export default compose(
  connect(mapStateToProps),
  injectIntl,
  withRouter
)(DeviceInformation);
