import React, { useEffect, useState } from 'react';
import { connect, shallowEqual, useSelector } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { fetchCNSystemLogs } from '../../redux/actions/admin';
import idFromPath from '../../components/utils/IdFromPath/IdFromPath';
import {
  ContainerHeader,
  Card,
  CardSection,
  AdminLogList,
  Spinner
} from '../../components';
import { useInfiniteScroll } from '../../components/utils/UseInfiniteScroll/UseInfiniteScroll';
import { cursorShape } from '../../utils/proptypes/cursorShape';
import './AdminConnectivityNode.scss';

const AdminConnectivityNode = ({ history, logs, fetchCNSystemLogs }) => {
  const [data, setData] = useState([]);
  const cnID = idFromPath(history.location.pathname);
  const nextCursor = logs?.response_metadata?.next_cursor;

  // Load initial data
  useEffect(() => {
    fetchCNSystemLogs(cnID, '');
  }, [cnID, fetchCNSystemLogs]);

  // Infinite scroll
  const {
    triggerElementRef,
    setHasMore,
    setIsLoading
  } = useInfiniteScroll(() => fetchCNSystemLogs(cnID, nextCursor));

  useEffect(() => {
    setHasMore(!!nextCursor);
  }, [nextCursor, setHasMore]);

  const isLoading = useSelector(
    state =>
      state.api.pendingRequests.some(
        request => request.split('_|_')[0] === 'adminCNLogs'
      ),
    shallowEqual
  );

  useEffect(() => {
    setIsLoading(isLoading);
  }, [isLoading, setIsLoading]);
  // Infinite scroll end

  useEffect(() => {
    if ((logs?.items || []).length > 0) {
      setData([...data, ...logs.items]);

      // new items have been added, clear the array
      logs.items = [];
    }
  }, [data, logs]);

  return (
    <React.Fragment>
      <ContainerHeader headerTitle={`Connectivity node`} />
      <div className="row">
        <div className="col-12 col-md-12">
          <Card>
            <CardSection
              title={
                <FormattedMessage
                  id="admin.systemLogging"
                  defaultMessage="System logging"
                />
              }
            >
              <FormattedMessage
                id="admin.systemLoggingInfo"
                defaultMessage="System logging contains information from the system that concerns
                the device when it comes to device connection and data flow
                through the system."
              />
              <AdminLogList logs={data} triggerElementRef={triggerElementRef} />
              {isLoading && (
                <div className="gtg-admin-connectivity-node">
                  <Spinner />
                  Loading more results
                </div>
              )}
            </CardSection>
          </Card>
        </div>
      </div>
    </React.Fragment>
  );
};

AdminConnectivityNode.proptypes = {
  logs: cursorShape()
};

const mapStateToProps = state => ({
  logs: state.admin.connectivityNodeLogs
});

const mapDispatchToProps = {
  fetchCNSystemLogs
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)(AdminConnectivityNode);
