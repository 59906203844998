export const isColumnFixed = (logType, columnSlug, customColumns) => {
  return (
    customColumns[logType] &&
    customColumns[logType][columnSlug] &&
    customColumns[logType][columnSlug].fixed
  );
};

export const columnWidth = (logType, columnSlug, customColumns) => {
  return customColumns[logType] && customColumns[logType][columnSlug]
    ? customColumns[logType][columnSlug].width
    : undefined;
};

export const columnTextAlignment = (logType, columnSlug, customColumns) => {
  return customColumns[logType] &&
    customColumns[logType][columnSlug] &&
    customColumns[logType][columnSlug].textAlign
    ? 'text-' + customColumns[logType][columnSlug].textAlign
    : 'text-right';
};

export const logDefaultCustomColumns = {
  config: {
    date: {
      width: 100,
      textAlign: 'center'
    },
    time: {
      width: 90,
      textAlign: 'center'
    },
    configurationChange: {
      textAlign: 'left'
    },
    info: {
      textAlign: 'left'
    }
  },
  installation: {
    date: {
      fixed: true,
      width: 100,
      textAlign: 'center'
    },
    time: {
      fixed: true,
      width: 90,
      textAlign: 'center'
    },
    installationType: {
      fixed: true,
      width: 140,
      textAlign: 'left'
    }
  },
  internal: {
    date: {
      width: 120,
      textAlign: 'center'
    },
    time: {
      width: 110,
      textAlign: 'center'
    },
    id: {
      width: 110,
      textAlign: 'right'
    },
    type: {
      width: 200,
      textAlign: 'left'
    },
    info: {
      textAlign: 'left'
    }
  },
  technical: {
    date: {
      width: 110,
      textAlign: 'center'
    },
    time: {
      width: 110,
      textAlign: 'center'
    },
    'failure-type': {
      width: 120,
      textAlign: 'left'
    },
    subsystem: {
      width: 110,
      textAlign: 'left'
    },
    info: {
      textAlign: 'left'
    },
    text: {
      width: 180,
      textAlign: 'left'
    }
  },
  trends: {
    date: {
      fixed: true,
      width: 100,
      textAlign: 'center'
    },
    time: {
      fixed: true,
      width: 90,
      textAlign: 'center'
    }
  },
  service: {
    date: {
      textAlign: 'center'
    },
    time: {
      textAlign: 'center'
    },
    serviceintervtype: {
      textAlign: 'left'
    },
    'usb-memory-id': {
      textAlign: 'left'
    },
    'usb-memory-s-n': {
      textAlign: 'left'
    },
    signature: {
      textAlign: 'left'
    },
    remark: {
      textAlign: 'left'
    }
  },
  test: {
    date: {
      width: 100,
      textAlign: 'center'
    },
    time: {
      width: 90,
      textAlign: 'center'
    },
    info: {
      textAlign: 'left'
    }
  },
  'all-events': {
    date: {
      width: 100,
      textAlign: 'center'
    },
    time: {
      width: 90,
      textAlign: 'center'
    }
  }
};

export const logServoCustomColumns = {
  installation: {
    date: {
      width: 100,
      textAlign: 'center'
    },
    time: {
      width: 90,
      textAlign: 'center'
    },
    info: {
      textAlign: 'left'
    }
  },
  service: {
    date: {
      width: 100,
      textAlign: 'center'
    },
    time: {
      width: 90,
      textAlign: 'center'
    },
    remark: {
      textAlign: 'left'
    }
  }
};
