import { SET_METRICS } from '../actions/metrics';

const initState = {
  metrics: []
};

export const metricsReducer = (state = initState, action) => {
  const { payload, type } = action;

  switch (type) {
    case SET_METRICS:
      return {
        ...state,
        metrics: payload.payloadData,
        metricsY2y: payload.y2yData
      };
    default:
      return state;
  }
};
