import React from 'react';
import './Spinner.scss';

const SpinnerElement = props => (
  <svg
    id="spinner"
    viewBox="0 0 60 60"
    xmlns="http://www.w3.org/2000/svg"
    fillRule="evenodd"
    clipRule="evenodd"
    strokeLinejoin="round"
    strokeMiterlimit="1.414"
  >
    <g>
      <path
        d="M23.123,4.047C23.123,1.813 21.31,0 19.076,0L4.047,0C1.813,0 0,1.813 0,4.047C0,6.28 1.813,8.093 4.047,8.093L19.076,8.093C21.31,8.093 23.123,6.28 23.123,4.047Z"
        fill="#17264a"
        transform="matrix(0 .80594 -.80594 0 33.261 0)"
        id="Rectangle_6"
      />
      <path
        d="M23.123,4.047C23.123,1.813 21.31,0 19.076,0L4.047,0C1.813,0 0,1.813 0,4.047C0,6.28 1.813,8.093 4.047,8.093L19.076,8.093C21.31,8.093 23.123,6.28 23.123,4.047Z"
        fill="#17264a"
        transform="scale(.80594) rotate(60 8.491 20.635)"
        id="Rectangle_61"
      />
      <path
        d="M23.123,4.047C23.123,1.813 21.31,0 19.076,0L4.047,0C1.813,0 0,1.813 0,4.047C0,6.28 1.813,8.093 4.047,8.093L19.076,8.093C21.31,8.093 23.123,6.28 23.123,4.047Z"
        fill="#17264a"
        transform="scale(.80594) rotate(30 -24.686 20.635)"
        id="Rectangle_62"
      />
      <path
        d="M23.123,4.047C23.123,1.813 21.31,0 19.076,0L4.047,0C1.813,0 0,1.813 0,4.047C0,6.28 1.813,8.093 4.047,8.093L19.076,8.093C21.31,8.093 23.123,6.28 23.123,4.047Z"
        fill="#17264a"
        fillOpacity="0.9"
        transform="matrix(.80594 0 0 .80594 0 26.739)"
        id="Rectangle_63"
      />
      <path
        d="M23.123,4.047C23.123,1.813 21.31,0 19.076,0L4.047,0C1.813,0 0,1.813 0,4.047C0,6.28 1.813,8.093 4.047,8.093L19.076,8.093C21.31,8.093 23.123,6.28 23.123,4.047Z"
        fill="#17264a"
        fillOpacity="0.8"
        transform="scale(.80594) rotate(-30 99.132 20.635)"
        id="Rectangle_14"
      />
      <path
        d="M23.123,4.047C23.123,1.813 21.31,0 19.076,0L4.047,0C1.813,0 0,1.813 0,4.047C0,6.28 1.813,8.093 4.047,8.093L19.076,8.093C21.31,8.093 23.123,6.28 23.123,4.047Z"
        fill="#17264a"
        fillOpacity="0.7"
        transform="scale(.80594) rotate(-60 65.955 20.635)"
        id="Rectangle_141"
      />
      <path
        d="M23.123,4.047C23.123,1.813 21.31,0 19.076,0L4.047,0C1.813,0 0,1.813 0,4.047C0,6.28 1.813,8.093 4.047,8.093L19.076,8.093C21.31,8.093 23.123,6.28 23.123,4.047Z"
        fill="#17264a"
        fillOpacity="0.6"
        transform="matrix(0 -.80594 .80594 0 26.739 60)"
        id="Rectangle_142"
      />
      <path
        d="M23.123,4.047C23.123,1.813 21.31,0 19.076,0L4.047,0C1.813,0 0,1.813 0,4.047C0,6.28 1.813,8.093 4.047,8.093L19.076,8.093C21.31,8.093 23.123,6.28 23.123,4.047Z"
        fill="#17264a"
        fillOpacity="0.5"
        transform="scale(-.80594) rotate(60 35.74 -81.061)"
        id="Rectangle_143"
      />
      <path
        d="M23.123,4.047C23.123,1.813 21.31,0 19.076,0L4.047,0C1.813,0 0,1.813 0,4.047C0,6.28 1.813,8.093 4.047,8.093L19.076,8.093C21.31,8.093 23.123,6.28 23.123,4.047Z"
        fill="#17264a"
        fillOpacity="0.4"
        transform="scale(-.80594) rotate(30 77.01 -155.508)"
        id="Rectangle_144"
      />
      <path
        d="M23.123,4.047C23.123,1.813 21.31,0 19.076,0L4.047,0C1.813,0 0,1.813 0,4.047C0,6.28 1.813,8.093 4.047,8.093L19.076,8.093C21.31,8.093 23.123,6.28 23.123,4.047Z"
        fill="#17264a"
        fillOpacity="0.3"
        transform="matrix(-.80594 0 0 -.80594 60 33.261)"
        id="Rectangle_145"
      />
      <path
        d="M23.123,4.047C23.123,1.813 21.31,0 19.076,0L4.047,0C1.813,0 0,1.813 0,4.047C0,6.28 1.813,8.093 4.047,8.093L19.076,8.093C21.31,8.093 23.123,6.28 23.123,4.047Z"
        fill="#17264a"
        fillOpacity="0.2"
        transform="scale(-.80594) rotate(-30 -77.01 122.331)"
        id="Rectangle_64"
      />
      <path
        d="M23.123,4.047C23.123,1.813 21.31,0 19.076,0L4.047,0C1.813,0 0,1.813 0,4.047C0,6.28 1.813,8.093 4.047,8.093L19.076,8.093C21.31,8.093 23.123,6.28 23.123,4.047Z"
        fill="#17264a"
        fillOpacity="0.1"
        transform="scale(-.80594) rotate(-60 -35.74 47.884)"
        id="Rectangle_65"
      />
    </g>
  </svg>
);

export default SpinnerElement;
