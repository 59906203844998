import { Metrics, AgentDot } from '..';

function AgentKeyMapper(key, keyType) {
  // Because api agent/metrics data being passed to mappers contains keys that are sometimes
  // inconsistent with the keys from Metrics.js, we try to match agent first by key and
  // then by custom keyType, if specified

  // always try to find agent by key first
  let agent = Metrics.find(a => a.key.toLowerCase() === key.toLowerCase());
  // if unsuccessful and if keyType is specified, try searching by keyType as well
  if (!agent && keyType) {
    agent = Metrics.find(a => a[keyType].toLowerCase() === key.toLowerCase());
  }
  return agent ? agent : { label: key, labelShort: key, icon: AgentDot() };
}

export default AgentKeyMapper;
