import React, { useCallback, useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import { WithLoadingSpinner, WithSubscriptionInvitation } from '../../hoc';
import {
  Card,
  CardSection,
  allDateToggles,
  Toggles,
  DateScroller,
  Dropdown,
  UsageStatisticsServoUtilizationDonutChart,
  UsageStatisticsServoUtilizationBarChart,
  UsageStatisticsServoAllCategory,
  EmptyState,
  UsageStatisticsServoDetailedBarChart,
  BarWidth,
  AxisSettings,
  secondToHours,
  Icon,
  routePaths,
  PathJoin,
  getStartAndEndData,
  TimeFormat,
  WEEKEND_PREFIX
} from '..';
import {
  SERVO_USAGE_STATISTICS,
  servoUsageStatisticsOverviewFileName,
  servoUsageStatisticsOverviewNeonatalFileName,
  servoUsageStatisticsOverviewPediatricFileName,
  servoUsageStatisticsOverviewAdultFileName,
  USAGE_STATISTICS_SERVO_DETAILED,
  usageStatisticsServoDetailedFileName,
  usageStatisticsServoDetailedNeonatalFileName,
  usageStatisticsServoDetailedPediatricFileName,
  usageStatisticsServoDetailedAdultFileName,
  createDocument
} from '../../utils/export';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import './UsageStatistics.scss';
import { BadgeCountComponent, subscriptionTypes } from '../../components';
import get from 'lodash.get';
import { savePersistentState } from '../../redux/actions/persistentState';
import { MetricsConfig } from './Metrics';
import { fetchMetrics } from '../../redux/actions/metrics';
import { fetchMetricsDetailed } from '../../redux/actions/metricsDetailed';
import { fetchMetricsUsage } from '../../redux/actions/metricsUsage';
import { fetchTrends } from '../../redux/actions/trends';
import { fetchCosts } from '../../redux/actions/costs';
import { fetchDevicesOverview } from '../../redux/actions/devicesOverview';
import { fetchUsageStatistics } from '../../redux/actions/usageStatistics';
import { fetchUsageStatisticsUtilization } from '../../redux/actions/usageStatisticsUtilization';
import { fetchUsageStatisticsDetailed } from '../../redux/actions/usageStatisticsDetailed';
import moment from 'moment';
import colorVariables from '../../scss/_variables.module.scss';
import './UsageStatisticsServoWidget.scss';

const UsageStatisticsServoWidget = ({
  device,
  subscriptions,
  savePersistentState,
  persistentState,
  metricsUsage,
  withPerDeviceMetrics,
  config,
  userinfo,
  fetchDevicesOverview,
  fetchUsageStatistics,
  fetchUsageStatisticsUtilization,
  fetchUsageStatisticsDetailed,
  usageStatistics,
  usageStatisticsUtilization,
  shouldShowBadges,
  shouldShowCategoryList,
  usageStatisticsDetailed,
  usageStatisticsDetailedY2y,
  withViewDetails,
  shouldShowy2y,
  filters,
  intl
}) => {
  let servoSubscriptions = subscriptions && subscriptions.servo;
  const [collapsed, setCollapsed] = useState(false);

  const [timeSpanCount, setTimeSpanCount] = useState(
    persistentState['usageStatisticsTimeSpanCount'] || 0
  );
  const [metricsTimeSpan, setMetricsTimeSpan] = useState(
    persistentState['usageStatisticsTimeSpan'] || MetricsConfig.initialTimeSpan
  );

  const [selectedCategory, setSelectedCategory] = useState('All Categories');
  const dropdownComponent = useRef(null);

  const refetchData = useCallback(toggle => fetchData(toggle.value, 0), [
    fetchData
  ]);

  const shouldy2yBeShown =
    typeof shouldShowy2y === 'undefined' ? true : shouldShowy2y;

  const fetchData = useCallback(
    (timeSpan, count) => {
      let isY2yShown =
        typeof shouldShowy2y === 'undefined' ? true : shouldShowy2y;
      timeSpan = !isY2yShown && timeSpan === 'y2y' ? 'week' : timeSpan;
      savePersistentState('usageStatisticsTimeSpan', timeSpan);
      savePersistentState('usageStatisticsTimeSpanCount', count);
      setMetricsTimeSpan(timeSpan);
      setTimeSpanCount(count);

      let selectedCategoryValue =
        selectedCategory !== 'All Categories' ? selectedCategory : null;

      // If we are on device view, no details are needed
      if (device && device.id) {
        fetchMetrics(timeSpan, count, [device.id]);
        fetchTrends(timeSpan, count, [device.id]);
        fetchUsageStatistics(timeSpan, count, [device.id]);
        fetchUsageStatisticsUtilization(
          timeSpan,
          count,
          selectedCategoryValue,
          [device.id]
        );
      } else {
        fetchMetrics(timeSpan, count);
        fetchTrends(timeSpan, count);
        fetchUsageStatistics(timeSpan, count);
        fetchUsageStatisticsUtilization(timeSpan, count, selectedCategoryValue);
        fetchUsageStatisticsDetailed(timeSpan, count, selectedCategoryValue);

        if (withPerDeviceMetrics) {
          fetchMetricsDetailed(timeSpan, count);
          fetchMetricsUsage(timeSpan, count);
          fetchCosts();
        }
      }
      fetchDevicesOverview();
    },
    [
      savePersistentState,
      device,
      withPerDeviceMetrics,
      fetchDevicesOverview,
      fetchUsageStatistics,
      fetchUsageStatisticsUtilization,
      selectedCategory,
      fetchUsageStatisticsDetailed,
      shouldShowy2y
    ]
  );

  useEffect(() => {
    fetchData(metricsTimeSpan, timeSpanCount);
  }, [fetchData, filters, metricsTimeSpan, timeSpanCount, selectedCategory]);

  const TimespanToggles = useCallback(
    () => (
      <Toggles
        data-cy="timespan-toggles"
        toggles={
          shouldy2yBeShown
            ? allDateToggles
            : allDateToggles.filter(toggle => toggle.value !== 'y2y')
        }
        selectedToggle={metricsTimeSpan}
        onSelect={refetchData}
      />
    ),
    [metricsTimeSpan, refetchData, shouldy2yBeShown]
  );

  const goBack = useCallback(() => {
    fetchData(metricsTimeSpan, timeSpanCount + 1);
  }, [fetchData, metricsTimeSpan, timeSpanCount]);
  const goForward = useCallback(() => {
    fetchData(metricsTimeSpan, timeSpanCount - 1);
  }, [fetchData, metricsTimeSpan, timeSpanCount]);

  const DateScrollerComponent = useCallback(
    () => (
      <DateScroller
        timespan={metricsTimeSpan}
        count={timeSpanCount}
        onBackClick={goBack}
        onForwardClick={goForward}
      />
    ),
    [metricsTimeSpan, timeSpanCount, goBack, goForward]
  );

  const servoUsageDetailedData =
    usageStatisticsDetailed && usageStatisticsDetailed.usageStatisticsDetailed;

  const servoUsageDetailedDataY2y =
    usageStatisticsDetailedY2y && usageStatisticsDetailedY2y;

  const formatservoUsageDetailedDataY2y = data => {
    data &&
      data.length > 0 &&
      data.map(metric => {
        metric.data = metric.data.map(dataItem => {
          dataItem.x = '';

          if (
            ['week', 'month'].includes(metricsTimeSpan) &&
            [6, 7].includes(moment(dataItem.xTimeStamp).isoWeekday())
          ) {
            // So that it can be styled differently
            dataItem.x = WEEKEND_PREFIX;
          }

          dataItem.x += intl.formatDate(
            moment(dataItem.xTimeStamp).toDate(),
            TimeFormat(metricsTimeSpan)
          );
          return dataItem;
        });
        return metric;
      });
    return data;
  };

  const shouldBadgeBeShown =
    typeof shouldShowBadges === 'undefined' ? true : shouldShowBadges;
  const shouldCategoryListBeShown =
    typeof shouldShowCategoryList === 'undefined'
      ? true
      : shouldShowCategoryList;

  const BadgeComponent = flowSubscriptions => {
    if (!flowSubscriptions || flowSubscriptions.length === 0) {
      return <></>;
    }
    const badgeData = flowSubscriptions.sort((a, b) => {
      return a.priority - b.priority;
    });

    return badgeData.map(function(badgeItem) {
      return badgeItem.level === subscriptionTypes.standard ? null : (
        <BadgeCountComponent
          key={`${badgeItem.level}-${badgeItem.count}`}
          subscriptionType={badgeItem.level}
          deviceCount={badgeItem.count}
        />
      );
    });
  };

  const handleSelect = value => e => {
    if (e.value) {
      setSelectedCategory(e.value);
    } else {
      setSelectedCategory(e);
      dropdownComponent.current(e);
    }
  };

  const categories = [
    {
      value: 'All Categories',
      text: 'All Categories'
    },
    {
      value: 'Neonatal',
      text: 'Neonatal'
    },
    {
      value: 'Pediatric',
      text: 'Pediatric'
    },
    {
      value: 'Adult',
      text: 'Adult'
    }
  ];

  const filterWidgetLeftContent = (
    <>
      <Dropdown
        values={categories}
        placeholder="All Categories"
        textColorValue={colorVariables['colors-getinge--ocean']}
        bgColorValue={colorVariables['colors-getinge--white']}
        borderColor={colorVariables['colors-getinge--white']}
        onItemSelected={handleSelect()}
        dropdownComponent={dropdownComponent}
      ></Dropdown>
    </>
  );

  const usageStatisticsOverviewTitle = () => {
    return (
      <FormattedMessage
        id="usageStatistics.usageStatisticsOverview"
        defaultMessage="Usage statistics overview"
      />
    );
  };

  const detailedUsageStatisticsServoBadges = () => {
    let subscriptionData = subscriptions ? subscriptions.servo : [];
    const flowSubscriptions = subscriptionData?.slice();
    if (!flowSubscriptions || flowSubscriptions.length === 0) {
      return <></>;
    }

    const badges = flowSubscriptions.map(function(badgeItem) {
      return badgeItem.level === subscriptionTypes.premium ? (
        <BadgeCountComponent
          key={`${badgeItem.level}-${badgeItem.count}`}
          subscriptionType={badgeItem.level}
          deviceCount={badgeItem.count}
        />
      ) : null;
    });
    return <div className="gtg-usage-statistics-card-badges">{badges}</div>;
  };

  const getTitleAndBadgesForOverview = useCallback(() => {
    let subscriptionData = subscriptions ? subscriptions.servo : [];
    const usageStatisticsCardTitle = () => {
      return (
        <div id="gtg-usage-statistics-card-title">
          <FormattedMessage
            id={
              isAllCategory
                ? 'usageStatistics.usageStatisticsOverview'
                : `usageStatistics.usageStatisticsForDevice`
            }
            defaultMessage="Usage statistics overview"
          />
          {!isAllCategory && selectedCategory}
        </div>
      );
    };

    const usageStatisticsBadges = () => {
      return (
        <div className="gtg-usage-statistics-card-badges">
          {BadgeComponent(subscriptionData)}
        </div>
      );
    };

    return (
      <>
        {shouldBadgeBeShown && !shouldCategoryListBeShown ? (
          <div id="gtg-usage-statistics-card-title">
            {usageStatisticsOverviewTitle()}
            {get(config, 'enableSubscriptions', false) &&
            !userinfo.isInternalUser
              ? usageStatisticsBadges()
              : null}
          </div>
        ) : null}
        {!shouldCategoryListBeShown && !shouldBadgeBeShown ? (
          <div id="gtg-usage-statistics-card-title">
            {usageStatisticsOverviewTitle()}
          </div>
        ) : null}
        {get(config, 'enableSubscriptions', false) &&
        shouldBadgeBeShown &&
        shouldCategoryListBeShown &&
        !userinfo.isInternalUser
          ? usageStatisticsBadges()
          : null}
        {shouldCategoryListBeShown ? usageStatisticsCardTitle() : null}
      </>
    );
  }, [
    shouldBadgeBeShown,
    shouldCategoryListBeShown,
    config,
    userinfo.isInternalUser,
    isAllCategory,
    selectedCategory,
    subscriptions
  ]);

  const { start, end } = getStartAndEndData(
    undefined,
    metricsTimeSpan,
    timeSpanCount
  );

  const isAllCategory = selectedCategory === 'All Categories' ? true : false;

  const getFileName = (selectedCategory, isDetailed = false) => {
    switch (selectedCategory) {
      case 'All Categories':
        return isDetailed
          ? usageStatisticsServoDetailedFileName
          : servoUsageStatisticsOverviewFileName;
      case 'Neonatal':
        return isDetailed
          ? usageStatisticsServoDetailedNeonatalFileName
          : servoUsageStatisticsOverviewNeonatalFileName;
      case 'Pediatric':
        return isDetailed
          ? usageStatisticsServoDetailedPediatricFileName
          : servoUsageStatisticsOverviewPediatricFileName;
      case 'Adult':
        return isDetailed
          ? usageStatisticsServoDetailedAdultFileName
          : servoUsageStatisticsOverviewAdultFileName;
      default:
        return isDetailed
          ? usageStatisticsServoDetailedFileName
          : servoUsageStatisticsOverviewFileName;
    }
  };

  const exportServoUsageStatisticsData = useCallback(() => {
    createDocument(
      SERVO_USAGE_STATISTICS,
      servoUtilizationsData ? servoUtilizationsData : [],
      filters,
      getFileName(selectedCategory),
      {
        from: intl.formatDate(start),
        to: intl.formatDate(end.format(), { timeZone: 'UTC' }),
        selectedPatientCategoryValue: selectedCategory
      }
    );
  }, [filters, intl, start, end, servoUtilizationsData, selectedCategory]);

  const ServoUsageStatisticsCardRightWithMargin = useCallback(() => {
    return (
      <div className="gtg-usage-statistics-card-right-with-margin">
        <Link
          onClick={exportServoUsageStatisticsData}
          to="#"
          className="float-right"
        >
          <Icon name="download-excel" size="sm" />
        </Link>
      </div>
    );
  }, [exportServoUsageStatisticsData]);

  const exportUsageStatisticsServoDetailed = useCallback(() => {
    createDocument(
      USAGE_STATISTICS_SERVO_DETAILED,
      [
        ...formatservoUsageDetailedDataY2y(
          isAllCategory ? servoUsageDetailedDataY2y?.patientCategory : []
        ),
        ...formatservoUsageDetailedDataY2y(
          servoUsageDetailedDataY2y?.ventilationType
        ),
        ...formatservoUsageDetailedDataY2y(
          servoUsageDetailedDataY2y?.invasiveVentilation
        ),
        ...formatservoUsageDetailedDataY2y(servoUsageDetailedDataY2y?.ediNava),
        ...formatservoUsageDetailedDataY2y(
          selectedCategory === 'Adult'
            ? servoUsageDetailedDataY2y?.openLungTool
            : []
        )
      ] || [],
      filters,
      getFileName(selectedCategory, true),
      {
        selectedPatientCategoryValue: selectedCategory
      }
    );
  }, [
    filters,
    servoUsageDetailedDataY2y,
    formatservoUsageDetailedDataY2y,
    isAllCategory,
    selectedCategory
  ]);

  const excelExportUsageStatisticsServoDetailed = useCallback(
    props => (
      <div className={classnames('gtg-usage-statistics-per-device-right')}>
        <Link
          onClick={exportUsageStatisticsServoDetailed}
          to="#"
          className="float-right"
        >
          <Icon name="download-excel" size="sm" />
          {setCollapsed(props.collapsed)}
        </Link>
      </div>
    ),
    [exportUsageStatisticsServoDetailed]
  );

  let filterWidget = (
    <div id="gtg-usage-statistics-servo-filter">
      <div className="gtg-usage-statistics-servo-filter-item">
        {shouldCategoryListBeShown ? (
          <>
            <div className="gtg-usage-statistics-servo-filter-item-title-text">
              <FormattedMessage
                id="usageStatistics.patientCategoryWithColon"
                defaultMessage="Patient category:"
              />
            </div>
            <div className="gtg-usage-statistics-servo-filter-dropdown">
              {filterWidgetLeftContent}
            </div>
          </>
        ) : (
          getTitleAndBadgesForOverview()
        )}
      </div>
      <div className="gtg-usage-statistics-servo-filter-item gtg-usage-statistics-servo-datescroller">
        <DateScrollerComponent />
      </div>
      <div className="gtg-usage-statistics-servo-filter-item gtg-usage-statistics-servo-filter-period">
        <>
          {shouldCategoryListBeShown ? null : (
            <Link
              onClick={exportServoUsageStatisticsData}
              to="#"
              className="gtg-servo-usage-statistics-overview-dashboard-download-icon"
            >
              <Icon name="download-excel" size="sm" />
            </Link>
          )}
          <div className="float-right">
            <TimespanToggles />
          </div>
        </>
      </div>
    </div>
  );

  const getHoursPbwObj = category => {
    if (usageStatistics?.usageStatistics !== null) {
      return usageStatistics?.usageStatistics?.usage?.filter(item => {
        if (item.category === category.toLowerCase()) {
          return item;
        }
      });
    }
  };

  const servoUtilizationsData =
    usageStatisticsUtilization &&
    usageStatisticsUtilization.usageStatisticsUtilization;
  const utilizationCharts = (
    <div className="row">
      {servoUtilizationsData && (
        <>
          {isAllCategory && (
            <UsageStatisticsServoUtilizationDonutChart
              metricsUsage={servoUtilizationsData}
              dataObj="patientCategory"
              title="servoUtilizationDonutPatientCategory"
              defaultTitle="Patient Category Utilization"
            />
          )}
          <UsageStatisticsServoUtilizationDonutChart
            metricsUsage={servoUtilizationsData}
            dataObj="ventilationType"
            title="servoUtilizationDonutVentilationType"
            defaultTitle="Ventilation Type Utilization"
          />
          <UsageStatisticsServoUtilizationDonutChart
            metricsUsage={servoUtilizationsData}
            dataObj="invasiveVentilation"
            title="servoUtilizationDonutInvasiveVentilation"
            defaultTitle="Invasive Ventilation Utilization"
          />
          <UsageStatisticsServoUtilizationDonutChart
            metricsUsage={servoUtilizationsData}
            dataObj="ediNava"
            title="servoUtilizationDonutEdiNava"
            defaultTitle="Edi & NAVA Utilization"
          />
          {selectedCategory === 'Adult' && (
            <UsageStatisticsServoUtilizationBarChart
              chartData={servoUtilizationsData.openLungTool}
              chartWidth={200}
              chartHeight={250}
              title="openLungToolRecruitment"
              defaultTitle="Open Lung Tool Recruitment"
            />
          )}
        </>
      )}
    </div>
  );

  const hoursPbwObj = getHoursPbwObj(selectedCategory);

  const usageStatisticsPerCategory = (
    <div className="row">
      <div className="col-12 gtg-usage-statistics-servo-column">
        <div className="gtg-usage-statistics-servo-hours-title">
          <FormattedMessage
            id="usageStatistics.hoursOfVentilation"
            defaultMessage="Hours of Ventilation [h]"
          />
        </div>
        <span className="gtg-usage-statistics-servo-hours">
          {typeof hoursPbwObj !== 'undefined'
            ? hoursPbwObj.length > 0 && hoursPbwObj[0].ventilation !== null
              ? secondToHours(hoursPbwObj[0].ventilation)
              : 0
            : 0}
        </span>
      </div>
    </div>
  );

  const usageStatisticsAllCategory = (
    <div className="pt-3 clearfix">
      <WithLoadingSpinner when="usageStatistics">
        {(usageStatistics && (
          <>
            <UsageStatisticsServoAllCategory
              data={usageStatistics.usageStatistics}
              updateSelectedCategory={handleSelect()}
              metricsTimeSpan={metricsTimeSpan}
              shouldShowCategoryList={shouldCategoryListBeShown}
            />
          </>
        )) || <EmptyState />}
      </WithLoadingSpinner>
    </div>
  );

  let usageStatisticsServoWidget = (
    <div id="gtg-usage-statistics-servo-overview">
      <CardSection
        dataCy="cardUsageStatisticsServoOverview"
        title={
          shouldCategoryListBeShown ? getTitleAndBadgesForOverview() : null
        }
        ignoreClickTarget={'icon-download-excel'}
        CardHeaderRight={ServoUsageStatisticsCardRightWithMargin}
      >
        {isAllCategory
          ? usageStatisticsAllCategory
          : usageStatisticsPerCategory}
      </CardSection>
      <CardSection dataCy="cardUsageStatisticsServoUtilizationCharts">
        {utilizationCharts}
      </CardSection>
      {withViewDetails && (
        <Link
          data-cy="linkShowStatisticsOverviewDetails"
          className="card-link mt-auto pt-3"
          to={PathJoin([routePaths.ServoUsageStatisticsOverview.path])}
        >
          <FormattedMessage
            id="common.viewDetails"
            defaultMessage="View details"
          />
          <Icon name="arrow-right-full" size="sm" />
        </Link>
      )}
    </div>
  );

  const enableUsageStatistics = get(
    config,
    'enableServoUsageStatistics',
    false
  );

  usageStatisticsServoWidget = (
    <WithSubscriptionInvitation
      accessWidget={'usage statistics'}
      trigger={[subscriptionTypes.advanced, subscriptionTypes.premium]}
      subscriptions={
        device
          ? [
              {
                count: 1,
                level: get(device, 'subscription', 'standard'),
                priority: 1
              }
            ]
          : servoSubscriptions
      }
    >
      {usageStatisticsServoWidget}
    </WithSubscriptionInvitation>
  );

  const servoUsageDetailedCharts = (
    <>
      {servoUsageDetailedData && (
        <>
          {isAllCategory && (
            <UsageStatisticsServoDetailedBarChart
              metricsUsage={servoUsageDetailedData}
              dataObj="patientCategory"
              metricsTimeSpan={metricsTimeSpan}
              title="servoUtilizationDonutPatientCategory"
              defaultTitle="Patient Category Utilization"
              barWidth={BarWidth(metricsTimeSpan)}
              xAxisSettings={AxisSettings(metricsTimeSpan)}
            />
          )}
          <UsageStatisticsServoDetailedBarChart
            metricsUsage={servoUsageDetailedData}
            dataObj="ventilationType"
            metricsTimeSpan={metricsTimeSpan}
            title="servoUtilizationDonutVentilationType"
            defaultTitle="Ventilation Type Utilization"
            barWidth={BarWidth(metricsTimeSpan)}
            xAxisSettings={AxisSettings(metricsTimeSpan)}
          />
          <UsageStatisticsServoDetailedBarChart
            metricsUsage={servoUsageDetailedData}
            dataObj="invasiveVentilation"
            metricsTimeSpan={metricsTimeSpan}
            title="servoUtilizationDonutInvasiveVentilation"
            defaultTitle="Invasive Ventilation Utilization"
            barWidth={BarWidth(metricsTimeSpan)}
            xAxisSettings={AxisSettings(metricsTimeSpan)}
          />
          <UsageStatisticsServoDetailedBarChart
            metricsUsage={servoUsageDetailedData}
            dataObj="ediNava"
            metricsTimeSpan={metricsTimeSpan}
            title="servoUtilizationDonutEdiNava"
            defaultTitle="Edi & NAVA Utilization"
            barWidth={BarWidth(metricsTimeSpan)}
            xAxisSettings={AxisSettings(metricsTimeSpan)}
          />
          {selectedCategory === 'Adult' && (
            <UsageStatisticsServoDetailedBarChart
              metricsUsage={servoUsageDetailedData}
              dataObj="openLungTool"
              metricsTimeSpan={metricsTimeSpan}
              title="openLungRecruitment"
              defaultTitle="Open Lung Recruitment"
              barWidth={BarWidth(metricsTimeSpan)}
              xAxisSettings={AxisSettings(metricsTimeSpan)}
            />
          )}
        </>
      )}
    </>
  );

  const detailedUsageStatisticsCardTitle = () => {
    return (
      <div id="gtg-usage-statistics-card-title">
        <FormattedMessage
          id={
            isAllCategory
              ? 'usageStatistics.detailedUsageStatisticsOverview'
              : `usageStatistics.detailedUsageStatisticsForDevice`
          }
          defaultMessage="Detailed usage statistics for all categories"
        />
        {!isAllCategory && selectedCategory}
      </div>
    );
  };

  let detailedUsageStatisticsServoCard = (
    <>
      <CardSection
        title={
          <div id="gtg-usage-statistics-card-title">
            {detailedUsageStatisticsCardTitle()}
            {get(config, 'enableSubscriptions', false) &&
            shouldBadgeBeShown &&
            !userinfo.isInternalUser
              ? detailedUsageStatisticsServoBadges()
              : null}
          </div>
        }
        dataCy="cardUsageStatisticsServoDetailedCharts"
        ignoreClickTarget={'icon-download-excel'}
        CardHeaderRight={excelExportUsageStatisticsServoDetailed}
        collapsible
      >
        {servoUsageDetailedCharts}
      </CardSection>
    </>
  );

  detailedUsageStatisticsServoCard = metricsUsage && (
    <WithSubscriptionInvitation
      subscriptions={servoSubscriptions}
      accessWidget={'detailed usage statistics'}
      customSubtitleForInvitation={
        'usageStatistics.detailedUsageStatisticsServoSubtitle'
      }
      trigger={[subscriptionTypes.premium]}
    >
      {detailedUsageStatisticsServoCard}
    </WithSubscriptionInvitation>
  );

  let cardContent = enableUsageStatistics ? (
    <WithSubscriptionInvitation
      accessWidget={'usage statistics'}
      trigger={[subscriptionTypes.advanced, subscriptionTypes.premium]}
      subscriptions={
        device
          ? [
              {
                count: 1,
                level: get(device, 'subscription', 'standard'),
                priority: 1
              }
            ]
          : servoSubscriptions
      }
    >
      {filterWidget}
      {(metricsTimeSpan === 'y2y' && metricsUsage) ||
        usageStatisticsServoWidget}
      {detailedUsageStatisticsServoCard}
    </WithSubscriptionInvitation>
  ) : null;

  return <Card>{cardContent}</Card>;
};

const mapStateToProps = state => ({
  subscriptions:
    state.devicesStatusOverview.devicesStatusOverview.subscriptions,
  config: state.config,
  userinfo: get(state, 'authorization.userinfo'),
  persistentState: state.persistentState.persistentState,
  usageStatistics: state.usageStatistics,
  usageStatisticsUtilization: state.usageStatisticsUtilization,
  usageStatisticsDetailed: state.usageStatisticsDetailed,
  filters: state.filters,
  intl: intlShape,
  usageStatisticsDetailedY2y:
    state.usageStatisticsDetailed.usageStatisticsDetailedY2y
});

const mapDispatchToProps = {
  savePersistentState,
  fetchDevicesOverview,
  fetchUsageStatistics,
  fetchUsageStatisticsUtilization,
  fetchUsageStatisticsDetailed
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(UsageStatisticsServoWidget);
