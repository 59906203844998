import React from 'react';
import { uuidv4 } from '..';

function AgentDot(bgColor = null, borderColor = bgColor) {
  return (
    <span
      className="gtg-agent-dot"
      key={uuidv4()}
      style={{
        backgroundColor: bgColor,
        borderColor: borderColor
      }}
    ></span>
  );
}

export default AgentDot;
