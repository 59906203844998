import moment from 'moment';

// Filter array based on valid product certifications
function filterValidProductCertifications(product_certifications) {
  return (product_certifications || []).filter(c => {
    const { expiry_date } = c;
    return moment(expiry_date).isAfter(moment.now());
  });
}

export default filterValidProductCertifications;
