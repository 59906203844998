import propTypes from 'prop-types';

function decodePaginationURI(search, initialSettings) {
  const params = new URLSearchParams(search);
  return {
    page: (params.get('page') && +params.get('page')) || initialSettings.page,
    pageSize:
      (params.get('pagesize') && +params.get('pagesize')) ||
      initialSettings.pageSize,
    sort:
      (params.get('sort') && params.get('sort').trim()) || initialSettings.sort
  };
}

decodePaginationURI.propTypes = {
  search: propTypes.string,
  initialSettings: propTypes.shape({
    page: propTypes.number,
    pageSize: propTypes.number,
    sort: propTypes.string
  })
};

export default decodePaginationURI;
