import React from 'react';
import {
  AgentKeyMapper,
  AgentLabelSubbed,
  TrendIndicatorCell,
  uuidv4,
  formatValueUnit
} from '..';

function TrendsMapper(data, includeTrends, keyType) {
  return data.map(trendItem => {
    const mappedTrend = AgentKeyMapper(trendItem.key, keyType);
    return {
      icon: mappedTrend.icon,
      label: AgentLabelSubbed(mappedTrend.labelShort),
      value: (
        <span key={uuidv4()}>
          {formatValueUnit(trendItem.value, trendItem.unit, trendItem.key)}
        </span>
      ),
      trend: includeTrends ? TrendIndicatorCell(trendItem.trend) : null
    };
  });
}

export default TrendsMapper;
