/**
 * Capitalize first character in string
 * - needed to avoid capitalizing hyphened word
 * @param {String} str
 * @returns {String}
 */

export function Capitalize(str) {
  return str ? str.charAt(0).toUpperCase() + str.slice(1) : '';
}

/**
 * Convert string from kebab-case to PascalCase
 * @param {String} str
 * @returns {String}
 */

export function KebabToPascalCase(str) {
  return str ? str.replace(/(^\w|-\w)/g, clearAndUpperCase) : '';
}

function clearAndUpperCase(text) {
  return text.replace(/-/, '').toUpperCase();
}

/**
 * Convert string from kebab-case string to separated words string (ie. "Kebab Case")
 * @param {String} str
 * @param {Boolean} capitalize Capitalize words
 * @returns {String}
 */

export function KebabToWords(str, capitalize = false) {
  return str
    ? str
        .split('-')
        .map(word => (capitalize ? Capitalize(word) : word))
        .join(' ')
    : '';
}

/**
 * Convert separated strings to camel case string (ie. "camelCase")
 * @param {String} str
 * @returns {String}
 */

export function Camelize(str) {
  return str
    ? str
        .replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index) {
          return index === 0 ? word.toLowerCase() : word.toUpperCase();
        })
        .replace(/[\s+-]/g, '')
    : '';
}
