import { SET_USAGE_STATISTICS_UTILIZATION } from '../actions/usageStatisticsUtilization';

const initState = {
  usageStatisticsUtilization: null
};

export const usageStatisticsUtilizationReducer = (
  state = initState,
  action
) => {
  const { payload, type } = action;

  switch (type) {
    case SET_USAGE_STATISTICS_UTILIZATION:
      return {
        ...state,
        usageStatisticsUtilization: payload
      };
    default:
      return state;
  }
};
