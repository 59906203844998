// Add column headers and define column keys and widths
export const detailedUsageStatisticsPerDeviceColumns = [
  { header: 'Hospital', key: 'hospital', width: 20 },
  { header: 'From', key: 'from', width: 13 },
  { header: 'To', key: 'to', width: 13 },
  { header: 'Serial no', key: 'serial', width: 12 },
  { header: 'Cases', key: 'cases', width: 10 },
  { header: 'Agent cost', key: 'agentCost', width: 10 },
  { header: 'Carbon footprint', key: 'carbonFootprint', width: 18 },
  { header: 'AGC', key: 'agc', width: 10 },
  { header: 'AFGO', key: 'afgo', width: 10 },
  { header: 'Manual', key: 'manual', width: 10 },
  { header: 'PC', key: 'pc', width: 10 },
  { header: 'PCPS', key: 'pcps', width: 10 },
  { header: 'PRVC', key: 'prvc', width: 10 },
  { header: 'PS', key: 'ps', width: 10 },
  { header: 'PSBackup', key: 'psbackup', width: 10 },
  { header: 'VC', key: 'vc', width: 10 },
  { header: 'VCPS', key: 'vcps', width: 10 },
  { header: 'Department filter selection', key: 'departmentName', width: 25 },
  { header: 'Device filter selection', key: 'deviceType', width: 25 }
];

// Define filename
export const detailedUsageStatisticsPerDeviceFileName =
  'Detailed_Usage_statistics_per_device.xlsx';
