import React from 'react';
import { Icon } from '../..';
import propTypes from 'prop-types';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';

import './EmptyState.scss';
const defaultMsg = (
  <FormattedMessage
    id="common.noDataAvailable"
    defaultMessage="No data available"
  />
);

const EmptyState = ({ msg = defaultMsg }) => {
  return (
    <div className={classnames('gtg-empty-state')} data-cy="emptyState">
      <Icon name={'no-connection'} />
      <span className="ml-2">{msg}</span>
    </div>
  );
};

EmptyState.propTypes = {
  msg: propTypes.oneOfType([propTypes.string, propTypes.node])
};

export default EmptyState;
