function idFromPath(pathname) {
  const regex = /\/(?<base>.*)\/(?<deviceId>.*)/;
  const path = pathname.match(regex);
  return path && path.groups && path.groups.deviceId
    ? path.groups.deviceId
    : null;
}

export function testIdFromPath(pathname) {
  const regex = /\/(?<base>.*)\/(?<deviceId>.*)\/preuse-check\/(?<testId>.*)/;
  const path = pathname.match(regex);
  return path && path.groups && path.groups.deviceId && path.groups.testId
    ? {
        deviceId: path.groups.deviceId,
        testId: path.groups.testId
      }
    : null;
}

// TODO: refactor to get all ids with prefixed entity names

export default idFromPath;
