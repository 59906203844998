import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { fetchCosts } from '../../redux/actions/costs';
import { WithLoadingSpinner } from '../../hoc';
import {
  List,
  ListItem,
  EditButton,
  AgentCostEntryModal,
  EmptyState,
  formatValueUnit
} from '..';
import { currencyShape } from '../../components/Cases/AgentCostEntryModal/AgentCostEntryModal';
import { caseShape } from '../../containers/Case/caseHelpers';
import { calculateTotalAmount } from './AgentCostWidgetHelper';
import './AgentCostWidget.scss';

export const AgentCostWidget = ({ costs, fetchCosts, caseInfo }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const openModal = useCallback(() => setModalOpen(true), []);
  const closeModal = useCallback(() => setModalOpen(false), []);

  // Initial Data Fetch
  useEffect(() => {
    fetchCosts();
  }, [fetchCosts]);

  // Currently there are only options to edit costs for des, iso and sev so we only show these three metrics.
  const filteredMetrics = useMemo(() => {
    const displayMetrics = ['sev', 'iso', 'des'];

    return (
      caseInfo &&
      caseInfo.metrics
        .filter(metric => displayMetrics.includes(metric.key))
        .slice()
        .sort(function(a, b) {
          return displayMetrics.indexOf(a.key) - displayMetrics.indexOf(b.key);
        })
    );
  }, [caseInfo]);

  const haveAllData =
    caseInfo &&
    costs &&
    filteredMetrics &&
    filteredMetrics.length > 0 &&
    caseInfo.costs;

  const totalAmount = useMemo(() => {
    // This data can have different units and is calculated differently.
    return filteredMetrics && calculateTotalAmount(filteredMetrics);
  }, [filteredMetrics]);

  const totalCost = useMemo(() => {
    // This data's units are always the same.
    const calculatedCost =
      haveAllData &&
      filteredMetrics
        .reduce((total, metric) => total + (caseInfo.costs[metric.key] || 0), 0)
        .toFixed(2);
    const symbol = costs ? costs.currency.symbol : '';

    return formatValueUnit(calculatedCost, symbol, 'currency');
  }, [caseInfo, costs, filteredMetrics, haveAllData]);

  return (
    <>
      <div>
        <h6 className="mb-3 d-inline-block gtg-case-details-title">
          <FormattedMessage id="case.agentCost" defaultMessage="Agent cost" />
        </h6>
        <div className="float-right">
          <EditButton onClick={openModal} />
        </div>
      </div>
      <WithLoadingSpinner when={['case', 'costs']}>
        {(haveAllData && (
          <List className="gtg-agent-costs-list" dataCy="agentCostList">
            {filteredMetrics &&
              filteredMetrics.length > 0 &&
              filteredMetrics.map(metric => (
                <ListItem key={metric.key}>
                  <strong data-cy="agentCostListMetricKey">
                    {metric.key.toUpperCase()}
                  </strong>
                  <span
                    className="text-left"
                    data-cy="agentCostListMetricValue"
                  >
                    {formatValueUnit(metric.value, metric.unit, metric.key)}
                  </span>
                  <span
                    className="text-center"
                    data-cy="agentCostListCostPerLiter"
                  >
                    {formatValueUnit(
                      costs[metric.key],
                      costs.currency ? `${costs.currency.symbol}/L` : ' ',
                      'currency'
                    )}
                  </span>
                  <span
                    className="text-right"
                    data-cy="agentCostListMetricCost"
                  >
                    {formatValueUnit(
                      caseInfo.costs[metric.key],
                      caseInfo.costs.currency.symbol,
                      'currency'
                    )}
                  </span>
                </ListItem>
              ))}
            <ListItem>
              <strong data-cy="agentCostListTotal">
                <FormattedMessage id="common.total" defaultMessage="Total" />
              </strong>
              <span className="text-left" data-cy="agentCostListTotalAmount">
                {totalAmount}
              </span>
              <span />
              <span className="text-right" data-cy="agentCostListTotalCost">
                {totalCost}
              </span>
            </ListItem>
          </List>
        )) ||
          (filteredMetrics && filteredMetrics.length !== 0 && (
            <EmptyState
              msg={
                <FormattedMessage
                  id="case.noCostsSet"
                  defaultMessage="No costs per liter set. Please edit."
                />
              }
            />
          )) || <EmptyState />}
      </WithLoadingSpinner>
      {modalOpen && <AgentCostEntryModal onDone={closeModal} costs={costs} />}
    </>
  );
};

AgentCostWidget.propTypes = {
  fetchCosts: PropTypes.func.isRequired,
  caseInfo: PropTypes.shape(caseShape),
  costs: PropTypes.shape({
    currency: currencyShape,
    sev: PropTypes.number,
    des: PropTypes.number,
    iso: PropTypes.number
  })
};

const mapStateToProps = state => ({
  costs: state.costs.costs,
  caseInfo: state.case.case
});

const mapDispatchToProps = {
  fetchCosts: fetchCosts
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  AgentCostWidget
);
