import { useEffect, useState } from 'react';

export default function useOverflowObserver(targetRef) {
  const [activeOverflow, setActiveOverflow] = useState(null);

  useEffect(() => {
    let isMounted = true;
    let resizeObserver;

    function startObserving() {
      resizeObserver = new ResizeObserver(entries => {
        window.requestAnimationFrame(_ => {
          const node = entries[0].target;
          if (isMounted) {
            setActiveOverflow(
              node.offsetHeight < node.scrollHeight ||
                node.offsetWidth < node.scrollWidth
            );
          }
        });
      });

      if (targetRef.current) {
        resizeObserver.observe(targetRef.current);
      }
    }

    function stopObserving() {
      if (resizeObserver) {
        resizeObserver.disconnect();
      }
    }

    startObserving();

    return () => {
      isMounted = false;
      stopObserving();
    };
  }, [targetRef]);

  return activeOverflow;
}
