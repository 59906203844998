const invasiveVentilationHeaders = [
  {
    header: 'Invasive ventilation utilization - Controlled mode',
    key: 'controlledModeInvasiveVentilationUtilization',
    width: 10
  },
  {
    header: 'Invasive ventilation utilization - Supported mode',
    key: 'supportedModeInvasiveVentilationUtilization',
    width: 10
  },
  {
    header: 'Invasive ventilation utilization - Interactive mode',
    key: 'interactiveModeInvasiveVentilationUtilization',
    width: 10
  }
];

const ediNavaUtilizationHeaders = [
  {
    header: 'Edi & Nava utilization - Edi monitoring',
    key: 'ediMonitoringEdiNavaUtilization',
    width: 10
  },
  {
    header: 'Edi & Nava utilization - Nava',
    key: 'navaEdiNavaUtilization',
    width: 10
  },
  {
    header: 'Edi & Nava utilization - Niv Nava',
    key: 'nivNavaEdiNavaUtilization',
    width: 10
  }
];

const departmentAndDeviceHeaders = [
  {
    header: 'Department filter selection',
    key: 'department',
    width: 25
  },
  {
    header: 'Device filter selection',
    key: 'device',
    width: 25
  }
];

const ventilationTypeUtlizationHeaders = [
  {
    header: 'Ventilation type utilization - Invasive',
    key: 'invasiveVentilationUtilization',
    width: 10
  },
  {
    header: 'Ventilation type utilization - Hfo',
    key: 'hfoVentilationUtilization',
    width: 10
  },
  {
    header: 'Ventilation type utilization - High flow',
    key: 'highFlowVentilationUtilization',
    width: 10
  },
  {
    header: 'Ventilation type utilization - Non invasive',
    key: 'nonInvasiveVentilationUtilization',
    width: 10
  },
  {
    header: 'Ventilation type utilization - Nava',
    key: 'navaVentilationUtilization',
    width: 10
  }
];

const hospitalFromToHeaders = [
  { header: 'Hospital', key: 'hospital', width: 20 },
  { header: 'From', key: 'from', width: 10 },
  { header: 'To', key: 'to', width: 10 }
];

// Add column headers and define column keys and widths
export const servoUsageStatisticsOverviewColumns = [
  ...hospitalFromToHeaders,
  {
    header: 'Neonatal total hours of ventilation',
    key: 'neonatalHrsOfVentilation',
    width: 12
  },
  {
    header: 'Pediatric total hours of ventilation',
    key: 'pediatricHrsOfVentilation',
    width: 10
  },
  {
    header: 'Adult total hours of ventilation',
    key: 'adultHrsOfVentilation',
    width: 10
  },
  {
    header: 'Patient category utilization - Neonatal',
    key: 'neonatalPatientCategoryUtilization',
    width: 18
  },
  {
    header: 'Patient category utilization - Pediatric',
    key: 'pediatricPatientCategoryUtilization',
    width: 10
  },
  {
    header: 'Patient category utilization - Adult',
    key: 'adultPatientCategoryUtilization',
    width: 10
  },
  {
    header: 'Patient category utilization - Unutilized',
    key: 'unutilizedPatientCategoryUtilization',
    width: 10
  },
  ...ventilationTypeUtlizationHeaders,
  ...invasiveVentilationHeaders,
  ...ediNavaUtilizationHeaders,
  ...departmentAndDeviceHeaders
];

export const servoUsageStatisticsOverviewNeonatalColumns = [
  ...hospitalFromToHeaders,
  {
    header: 'Neonatal total hours of ventilation',
    key: 'neonatalHrsOfVentilation',
    width: 12
  },
  ...ventilationTypeUtlizationHeaders,
  ...invasiveVentilationHeaders,
  ...ediNavaUtilizationHeaders,
  ...departmentAndDeviceHeaders
];

export const servoUsageStatisticsOverviewPediatricColumns = [
  ...hospitalFromToHeaders,
  {
    header: 'Pediatric total hours of ventilation',
    key: 'pediatricHrsOfVentilation',
    width: 10
  },
  ...ventilationTypeUtlizationHeaders,
  ...invasiveVentilationHeaders,
  ...ediNavaUtilizationHeaders,
  ...departmentAndDeviceHeaders
];

export const servoUsageStatisticsOverviewAdultColumns = [
  ...hospitalFromToHeaders,
  {
    header: 'Adult total hours of ventilation',
    key: 'adultHrsOfVentilation',
    width: 10
  },
  ...ventilationTypeUtlizationHeaders,
  ...invasiveVentilationHeaders,
  ...ediNavaUtilizationHeaders,
  {
    header: 'Open Lung Tool Auto SRM',
    key: 'openLungToolAutoSrm',
    width: 10
  },
  {
    header: 'Open Lung Tool Auto RM',
    key: 'openLungToolAutoRm',
    width: 10
  },
  ...departmentAndDeviceHeaders
];

// Define filename
export const servoUsageStatisticsOverviewFileName =
  'Servo_usage_statistics_overview.xlsx';

export const servoUsageStatisticsOverviewNeonatalFileName =
  'Servo_usage_statistics_neonatal.xlsx';

export const servoUsageStatisticsOverviewPediatricFileName =
  'Servo_usage_statistics_pediatric.xlsx';

export const servoUsageStatisticsOverviewAdultFileName =
  'Servo_usage_statistics_adult.xlsx';
