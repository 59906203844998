import { FETCH_DOCUMENTS, setDocuments, entity } from '../actions/documents';
import { API_SUCCESS, apiRequest } from '../actions/api';
import { API_URLS } from './urls';

const BASE_URL = API_URLS.Devices;

export const documentsMdl = ({ dispatch }) => next => action => {
  next(action);

  const { type } = action;

  switch (type) {
    case FETCH_DOCUMENTS:
      const { deviceId } = action.payload;

      const URL = `${BASE_URL.url}/${deviceId}/documentation`;

      dispatch(
        apiRequest({
          method: 'GET',
          url: `${URL}`,
          requestSignature: 'documents',
          entity: entity
        })
      );
      break;
    case `${entity} GET ${API_SUCCESS}`:
      dispatch(
        setDocuments(action.payload.data ? action.payload.data.items : [])
      );
      break;
    // no default
  }
};
