import React from 'react';
import PropTypes from 'prop-types';
import { Icon, TestTroubleshootingGuidance } from '../..';

import './TreeTable.scss';

class TreeTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      enhancedTableData: this.generateStateTableData(this.props.tableData),
      expanded: false
    };
    this.expandOrCollapseAll = this.expandOrCollapseAll.bind(this);
  }

  generateStateTableData(tree, n = 1) {
    function shouldExpand(rowStatus) {
      return rowStatus && rowStatus.expand;
    }
    function shouldBeVisible(rowStatus) {
      return rowStatus && (rowStatus.expand || rowStatus.show);
    }
    return (function recurse(children, parentRowIndex = 0, rowLevel = 1) {
      if (children) {
        return children.map(node => {
          let rowID = n++;
          return Object.assign({}, node, {
            rowID: rowID,
            rowLevel: rowLevel,
            parentRowIndex: parentRowIndex,
            visible:
              parentRowIndex === 0 || shouldBeVisible(node.data.rowStatus),
            expanded: shouldExpand(node.data.rowStatus),
            children: recurse(node.children, rowID, rowLevel + 1)
          });
        });
      }
    })(tree);
  }

  expandOrCollapseAll() {
    let action = !this.state.expanded;
    let newTree = (function recurse(children) {
      return children.map(node => {
        let visibleAction = node.rowLevel === 1 ? true : action;
        return Object.assign({}, node, {
          visible: visibleAction,
          expanded: action,
          children: recurse(node.children)
        });
      });
    })(this.state.enhancedTableData);
    this.setState({
      enhancedTableData: newTree,
      expanded: action
    });
  }

  rowExpandOrCollapse = selectedRowID => e => {
    let newTree = this.expandOrCollapseTree(
      this.state.enhancedTableData,
      [selectedRowID],
      false,
      false
    );
    this.setState({ enhancedTableData: newTree });
  };

  expandOrCollapseTree(data, selectedRowIDs, expandAll, collapseAll) {
    function recursiveCollapse(nodes) {
      return nodes.map(child => {
        child.expanded = false;
        if (child.children) {
          return recursiveCollapse(child.children);
        }
        return child;
      });
    }
    return (function recurse(
      children,
      expandBranch = expandAll,
      collapseBranch = collapseAll
    ) {
      return children.map(node => {
        let setExpanded = node.expanded;
        if (selectedRowIDs.includes(node.rowID)) {
          if (node.expanded && node.children && node.children.length > 0) {
            recursiveCollapse(node.children);
          }
          setExpanded = !node.expanded;
        }
        let setVisible = selectedRowIDs.includes(node.parentRowIndex)
          ? !node.visible
          : node.visible;
        if (expandBranch) {
          setExpanded = true;
          setVisible = true;
        }
        if (collapseBranch) {
          setExpanded = false;
          setVisible = false;
        }
        //collapse and hide all below
        if (selectedRowIDs.includes(node.parentRowIndex) && !setVisible) {
          collapseBranch = true;
        }
        return Object.assign({}, node, {
          visible: setVisible,
          expanded: setExpanded,
          children: recurse(node.children, expandBranch, collapseBranch)
        });
      });
    })(data);
  }

  generateTableBody(tableData) {
    let tableBody = [];
    let j = 0;
    tableData.forEach(dataRow => {
      let rowData = this.processDataRow(dataRow);
      let key = dataRow.parentRowIndex + '-' + dataRow.rowID;
      let rowClasses = [
        dataRow.visible ? 'shown' : 'hidden',
        dataRow.data.itemType
      ];
      if (dataRow.rowLevel === 1) {
        j++;
        rowClasses = [...rowClasses, j % 2 === 0 ? 'row-even' : 'row-odd'];
      }
      if (dataRow.data) {
        if (dataRow.data.rowStatus) {
          // append row class if row or parent has error or warning
          if (dataRow.data.rowStatus.status) {
            rowClasses = [
              ...rowClasses,
              'row-' + dataRow.data.rowStatus.status
            ];
          }
          if (dataRow.data.rowStatus.parentStatus) {
            rowClasses = [
              ...rowClasses,
              'row-parent-' + dataRow.data.rowStatus.parentStatus
            ];
          }
        }
      }
      if (dataRow.children.length > 0) {
        rowClasses = [...rowClasses, 'expandable'];
      }
      tableBody.push(
        <tr id={dataRow.data.id} className={rowClasses.join(' ')} key={key}>
          {rowData}
        </tr>
      );
      if (dataRow.children) {
        tableBody.push(...this.generateTableBody(dataRow.children));
      }
    });
    return tableBody;
  }

  generateExpandColumn(dataRow, key, dataField) {
    const columnWidth = {
      width: this.props.columns[0].fixedWidth
        ? this.props.columns[0].percentageWidth + '%'
        : 'initial'
    };
    const columnMarginLeft = {
      marginLeft: (dataRow.rowLevel - 1) * 2 + 'em'
    };
    const iconCell =
      dataRow.children && dataRow.children.length > 0 ? (
        <Icon name={dataRow.expanded ? 'minus' : 'plus'} color="primary" />
      ) : (
        undefined
      );
    return (
      <td
        key={key}
        className={this.props.columns[0].styleClass}
        {...columnWidth}
      >
        <span
          style={columnMarginLeft}
          onClick={this.rowExpandOrCollapse(dataRow.rowID)}
        >
          {iconCell}
          <span className="iconPadding">{dataRow.data[dataField]}</span>
        </span>
        {dataRow && dataRow.data && dataRow.data.troubleshooting && (
          <TestTroubleshootingGuidance {...dataRow.data.troubleshooting} />
        )}
      </td>
    );
  }

  processDataRow(dataRow) {
    let rowBody = [];
    rowBody.push(
      this.props.columns.map((column, index) => {
        let key = dataRow.parentRowIndex + '-' + dataRow.rowID + '-' + index;
        let output = dataRow.data[column.dataField];
        if (column.renderer) {
          output = this.props.columns[index].renderer(
            dataRow,
            column.dataField
          );
        }
        if (index === 0) {
          return this.generateExpandColumn(dataRow, key, column.dataField);
        } else {
          if (column.fixedWidth) {
            return (
              <td
                key={key}
                className={column.styleClass}
                width={column.percentageWidth + '%'}
              >
                {output}
              </td>
            );
          } else {
            return (
              <td key={key} className={column.styleClass}>
                {output}
              </td>
            );
          }
        }
      })
    );
    return rowBody;
  }

  generateHeaderRow() {
    let headingRows = [];
    if (this.props.columns) {
      headingRows.push(
        this.props.columns.map(column => {
          return (
            <th key={column.dataField}>
              {column.heading ? column.heading : column.dataField}
            </th>
          );
        })
      );
    }
    return headingRows;
  }

  render() {
    let headingRows = this.generateHeaderRow();
    let tableBody = this.generateTableBody(this.state.enhancedTableData);
    return (
      <div>
        <table
          className={[
            ...this.props.control.tableClasses,
            'gtg-tree-table'
          ].join(' ')}
        >
          <thead>
            <tr>{headingRows}</tr>
          </thead>
          <tbody>{tableBody}</tbody>
        </table>
      </div>
    );
  }
}

const rowDataShape = {
  id: PropTypes.string,
  itemType: PropTypes.string,
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  parentIds: PropTypes.arrayOf(PropTypes.string),
  result: PropTypes.string,
  rowStatus: PropTypes.shape({
    status: PropTypes.oneOf(['error', 'warning']),
    parentStatus: PropTypes.oneOf(['error', 'warning']),
    expand: PropTypes.bool,
    show: PropTypes.bool
  })
};

const childrenShape = {
  data: PropTypes.shape(rowDataShape),
  expanded: PropTypes.bool,
  parentRowIndex: PropTypes.number,
  rowID: PropTypes.number,
  rowLevel: PropTypes.number,
  visible: PropTypes.bool
};
childrenShape.children = PropTypes.arrayOf(PropTypes.shape(childrenShape));

TreeTable.propTypes = {
  tableData: PropTypes.arrayOf(
    PropTypes.shape({
      data: PropTypes.shape(rowDataShape),
      children: PropTypes.arrayOf(PropTypes.shape(childrenShape))
    })
  ).isRequired,
  control: PropTypes.shape({
    tableClasses: PropTypes.arrayOf(PropTypes.string),
    buttonClasses: PropTypes.string,
    showButton: PropTypes.bool
  }),
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      dataField: PropTypes.string.isRequired,
      heading: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      fixedWidth: PropTypes.bool,
      percentageWidth: PropTypes.number,
      styleClass: PropTypes.string,
      renderer: PropTypes.func
    })
  )
};

TreeTable.defaultProps = {
  tableData: [],
  control: {
    tableClasses: [],
    buttonClasses: '',
    showButton: false
  },
  columns: [
    {
      dataField: '',
      heading: '',
      fixedWidth: false,
      percentageWidth: 0,
      styleClass: '',
      renderer: null
    }
  ]
};

export default TreeTable;
