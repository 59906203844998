import React, { useCallback, useRef, useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import {
  EmptyState,
  DataTable,
  AgentLabelSubbed,
  AgentColorByKey,
  AgentKeyMapper,
  uuidv4,
  formatValueUnit,
  routePaths
} from '..';
import { WithLoadingSpinner } from '../../hoc';
import { WithTooltip } from '../../hoc';
import { FormattedMessage } from 'react-intl';

import './DetailedUsageStatisticsList.scss';

const agents = ['Sevoflurane', 'Desflurane', 'Isoflurane'];

function FindMaxDeviceUsage(data) {
  const agentsData = data
    .map(device =>
      device.metrics
        .filter(metric => agents.includes(metric.metric))
        .map(metric => metric.value)
    )
    .filter(arr => arr.length > 0);
  return (
    (agentsData &&
      agentsData.length > 0 &&
      Math.max(
        ...agentsData.map(deviceDataArr => {
          return deviceDataArr.reduce((acc, cur) => acc + cur);
        })
      )) ||
    null
  );
}

const AgentUsageBar = props => {
  const { usage, maxUsage } = props;
  const ref = useRef();
  const isAgentUsagePerDevice = true;

  let ellipsisActive = e => {
    return e.offsetHeight < e.scrollHeight || e.offsetWidth < e.scrollWidth;
  };

  const isOverflow = () => {
    let elemArr = document.getElementsByClassName(
      'gtg-usage-stat-graph-text-span'
    );
    if (elemArr.length > 0) {
      for (let el of elemArr) {
        if (el.innerHTML !== '') {
          el.style.visibility = ellipsisActive(el) ? 'hidden' : 'visible';
        }
      }
    }
  };

  const getTextContent = metric => {
    setTimeout(() => {
      isOverflow();
    }, 200);

    return (
      <div className="gtg-detailed-usage-stat-list-text">
        <span
          ref={ref}
          className={'gtg-usage-stat-graph-text-span'}
          style={{ visibility: 'hidden' }}
        >
          {formatValueUnit(
            metric.value,
            metric.unit,
            metric.metric,
            isAgentUsagePerDevice
          )}
        </span>
      </div>
    );
  };

  return (
    <div className="gtg-agent-usage-bar">
      {usage &&
        usage.map(metric => (
          <div
            key={uuidv4()}
            style={{
              backgroundColor: AgentColorByKey(metric.metric),
              width: (metric.value / maxUsage) * 100 + '%'
            }}
            className="gtg-detailed-usage-stat-list-graph-tooltip"
          >
            <WithTooltip
              tooltipContent={
                <React.Fragment>
                  {AgentKeyMapper(metric.metric).icon}{' '}
                  {AgentKeyMapper(metric.metric).labelShort}:{' '}
                  {formatValueUnit(
                    metric.value,
                    metric.unit,
                    metric.metric,
                    isAgentUsagePerDevice
                  )}
                </React.Fragment>
              }
            >
              {getTextContent(metric)}
            </WithTooltip>
          </div>
        ))}
    </div>
  );
};

const DetailedUsageStatisticsList = ({ data, history }) => {
  const maxDeviceUsage = data && data.length > 0 && FindMaxDeviceUsage(data);

  const handleRowClick = useCallback(
    (state, rowInfo) => {
      if (rowInfo && rowInfo.original && rowInfo.original.id) {
        return {
          onClick: () => {
            history.push(
              `/${routePaths.DeviceOverview.path}/${rowInfo.original.id}/${routePaths.DeviceCaseOverview.path}`
            );
          }
        };
      } else {
        return {};
      }
    },
    [history]
  );

  const compareValues = (a, b) => {
    switch (true) {
      case a > b:
        return 1;
      case a < b:
        return -1;
      default:
        return 0;
    }
  };

  const MetricCell = ({ value: metric }) =>
    (metric &&
      metric.value !== null &&
      formatValueUnit(metric.value, metric.unit, metric.metric)) || (
      <FormattedMessage id="common.notAvailableAbbr" defaultMessage="N/A" />
    );

  return (
    <WithLoadingSpinner when="metricsDetailed">
      {(data && data.length > 0 && (
        <DataTable
          showPagination={false}
          getTrProps={handleRowClick}
          pageSize={[999]}
          noCustomScrollbars
          withOverflow
          resizable={false}
          className="mb-4 gtg-agent-usage-list"
          data={data}
          columns={[
            {
              Header: (
                <FormattedMessage
                  id="column.serialNo"
                  defaultMessage="Serial no"
                />
              ),
              accessor: 'serialID',
              headerClassName: 'text-left',
              className: 'text-left'
            },
            {
              Header: (
                <FormattedMessage
                  id="column.department"
                  defaultMessage="Department"
                />
              ),
              accessor: 'department.name',
              headerClassName: 'text-left',
              className: 'text-left'
            },
            {
              Header: (
                <FormattedMessage
                  id="column.customerDeviceNo"
                  defaultMessage="Customer device no"
                />
              ),
              accessor: 'customerDeviceID',
              headerClassName: 'text-left',
              className: 'text-left'
            },
            {
              // Since the 'accessor' is not a string, we need to add an id
              id: 'agentUsage',
              Header: (
                <FormattedMessage
                  id="usageStatistics.agentUsage"
                  defaultMessage="Agent usage"
                />
              ),
              accessor: row =>
                row.metrics.filter(metric => agents.includes(metric.metric)),
              width: 350,
              showPagination: false,
              headerClassName: 'text-left',
              className: 'gtg-agent-usage-bar-row text-left',
              noOverflowTooltip: true,
              sortMethod: (a, b) => {
                const sumMetrics = metrics =>
                  metrics
                    .map(metric => metric.value)
                    .reduce((sum, value) => sum + value, 0);

                return compareValues(sumMetrics(a), sumMetrics(b));
              },
              Cell: ({ value: usageData }) =>
                (usageData.length > 0 && (
                  <AgentUsageBar usage={usageData} maxUsage={maxDeviceUsage} />
                )) || (
                  <FormattedMessage
                    id="common.notAvailableAbbr"
                    defaultMessage="N/A"
                  />
                )
            },
            {
              id: 'n2o',
              Header: AgentLabelSubbed('N2O'),
              accessor: row =>
                row.metrics.find(metric => metric.metric === 'N2O'),
              headerClassName: 'text-right',
              className: 'text-right',
              sortMethod: (a, b) => compareValues(a.value, b.value),
              Cell: MetricCell
            },
            {
              id: 'avgFgF',
              Header: (
                <FormattedMessage
                  id="usageStatistics.averageFgf"
                  defaultMessage="Average FGF"
                />
              ),
              accessor: row =>
                row.metrics.find(metric => metric.metric === 'FreshGasFlow'),
              headerClassName: 'text-right',
              className: 'text-right',
              sortMethod: (a, b) => compareValues(a.value, b.value),
              Cell: MetricCell
            },
            {
              id: 'cases',
              Header: (
                <FormattedMessage
                  id="usageStatistics.cases"
                  defaultMessage="Cases"
                />
              ),
              accessor: row =>
                row.metrics.find(metric => metric.metric === 'Cases').value,
              headerClassName: 'text-right',
              className: 'text-right',
              Cell: ({ value }) =>
                value || (
                  <FormattedMessage
                    id="common.notAvailableAbbr"
                    defaultMessage="N/A"
                  />
                )
            }
          ]}
        />
      )) || <EmptyState />}
    </WithLoadingSpinner>
  );
};

export default withRouter(DetailedUsageStatisticsList);
