export const entity = '[Auth]';
export const entityUserinfo = '[Userinfo]';
export const entityJWT = '[JWT]';
export const AUTH_LOGIN = `${entity} Login`;
export const AUTH_LOGOUT = `${entity} Logout`;
export const AUTH_INIT = `${entity} Init`;
export const FETCH_USERINFO = `${entityUserinfo} Fetch`;
export const SET_USERINFO = `${entityUserinfo} Set`;
export const FETCH_JWT = `${entityJWT} Fetch`;
export const SET_JWT = `${entityJWT} Set`;

export const authLogin = oidcProvider => ({
  type: AUTH_LOGIN,
  payload: {
    oidcProvider: oidcProvider
  }
});

export const authLogout = () => ({
  type: AUTH_LOGOUT
});

export const authInit = oidcProvider => ({
  type: AUTH_INIT,
  payload: {
    oidcProvider: oidcProvider
  }
});

export const fetchUserinfo = () => ({
  type: FETCH_USERINFO
});

export const setUserinfo = data => ({
  type: SET_USERINFO,
  payload: data
});

export const fetchJWT = roleChange => ({
  type: FETCH_JWT,
  roleChange: roleChange
});

export const setJWT = (token, exp) => ({
  type: SET_JWT,
  payload: {
    token,
    exp
  }
});
