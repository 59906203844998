import React, { useEffect, useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Link, withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { FiltersContainer } from '..';

import {
  ContainerHeader,
  UsageStatisticsWidget,
  CardTabbed,
  CardTab,
  CardSection,
  Icon,
  UsageStatisticsServoWidget,
  Card,
  deviceTypes,
  isServoFamily,
  EmptyState,
  isRotaflowFamily,
  isFlowFamily
} from '../../components';
import get from 'lodash.get';
import { useLocation } from 'react-router-dom';
import './UsageStatisticsOverview.scss';

const UsageStatisticsOverview = ({ config, filters, availableDeviceTypes }) => {
  // We only capture usage statistics for flow-i devices, therefore the usage statistics can be
  // hidden if there are no flow-i devices in the currently selected account
  const deviceTypesFilterApplied = get(filters, 'deviceTypes').length > 0;
  const totalDeviceTypesFiltered = get(filters, 'deviceTypes').length;
  const flowiDeviceTypeAvailable = (availableDeviceTypes || []).some(t =>
    isFlowFamily(t['key'])
  );
  const flowiInFilteredDeviceTypes = get(filters, 'deviceTypes').some(t =>
    isFlowFamily(t['key'])
  );

  const servoDeviceTypeAvailable = (availableDeviceTypes || []).some(t =>
    isServoFamily(t['key'])
  );
  const servoInFilteredDeviceTypes = get(filters, 'deviceTypes').some(t =>
    isServoFamily(t['key'])
  );
  const rotaflowInFilteredDeviceTypes = get(filters, 'deviceTypes').filter(t =>
    isRotaflowFamily(t['key'])
  );

  const isOnlyRotaFlowDeviceSelected =
    deviceTypesFilterApplied &&
    totalDeviceTypesFiltered === rotaflowInFilteredDeviceTypes.length;

  const displayFlowUsageStatistics =
    (!deviceTypesFilterApplied && flowiDeviceTypeAvailable) ||
    (deviceTypesFilterApplied && flowiInFilteredDeviceTypes);

  const displayServoUsageStatistics =
    (!deviceTypesFilterApplied && servoDeviceTypeAvailable) ||
    (deviceTypesFilterApplied && servoInFilteredDeviceTypes);

  const enableServoUsageStatistics = get(
    config,
    'enableServoUsageStatistics',
    false
  );

  const notApplicableForRotaflowDevicesMsg = (
    <FormattedMessage
      id="common.notApplicableForRotaflowDevices"
      defaultMessage="Not applicable for Rotaflow devices"
    />
  );

  let usageStatisticsWidget = (
    <Card className="gtg-flow-device-usage-statistics">
      <UsageStatisticsWidget withPerDeviceMetrics />
    </Card>
  );

  const getTabIndex = () => {
    const location = useLocation();
    let pathIndex = {
      '/usage-statistics/flow': 0,
      '/usage-statistics/servo': 1
    };
    return pathIndex[location.pathname];
  };

  const [tabIndex, setTabIndex] = useState(getTabIndex());

  if (
    enableServoUsageStatistics &&
    displayFlowUsageStatistics &&
    displayServoUsageStatistics
  ) {
    return (
      <div className="gtg-usage-statistics-tabs">
        <ContainerHeader
          headerTitle={
            <FormattedMessage
              id="common.usageStatistics"
              defaultMessage="Usage statistics"
            />
          }
        >
          <FiltersContainer />
        </ContainerHeader>
        <div className="row">
          <div className="col-12 col-md-12">
            <CardTabbed
              dataCy="cardUsageStatisticsOverview"
              id="gtg-usage-statistics-card-tab"
              currentTabIndex={tabIndex}
              customClass="gtg-usage-statistics-card-margin-zero"
            >
              <CardTab
                title={
                  <div id="flow">
                    <Icon name="flow" size="md" />
                    <span>Flow</span>
                  </div>
                }
              >
                <CardSection>{usageStatisticsWidget}</CardSection>
              </CardTab>
              <CardTab
                title={
                  <div id="servo">
                    <Icon name="servo" size="md" />
                    <span>Servo</span>
                  </div>
                }
              >
                <UsageStatisticsServoWidget metricsUsage />
              </CardTab>
            </CardTabbed>
          </div>
        </div>
      </div>
    );
  } else if (displayFlowUsageStatistics) {
    return (
      <div className="gtg-usage-statistics-tabs">
        <ContainerHeader
          headerTitle={
            <FormattedMessage
              id="common.usageStatistics"
              defaultMessage="Usage statistics"
            />
          }
        >
          <FiltersContainer />
        </ContainerHeader>
        {usageStatisticsWidget}
      </div>
    );
  } else if (enableServoUsageStatistics && displayServoUsageStatistics) {
    return (
      <div className="gtg-usage-statistics-tabs">
        <ContainerHeader
          headerTitle={
            <FormattedMessage
              id="common.usageStatistics"
              defaultMessage="Usage statistics"
            />
          }
        >
          <FiltersContainer />
        </ContainerHeader>
        <Card className="gtg-flow-device-usage-statistics">
          <UsageStatisticsServoWidget metricsUsage />
        </Card>
      </div>
    );
  } else if (isOnlyRotaFlowDeviceSelected) {
    return (
      <div className="gtg-usage-statistics-tabs">
        <ContainerHeader
          headerTitle={
            <FormattedMessage
              id="common.usageStatistics"
              defaultMessage="Usage statistics"
            />
          }
        >
          <FiltersContainer />
        </ContainerHeader>
        <Card className="gtg-flow-device-usage-statistics">
          <div className="mt-3 mr-3 ml-3">
            <EmptyState msg={notApplicableForRotaflowDevicesMsg} />
          </div>
        </Card>
      </div>
    );
  } else {
    return (
      <div className="gtg-usage-statistics-tabs">
        <ContainerHeader
          headerTitle={
            <FormattedMessage
              id="common.usageStatistics"
              defaultMessage="Usage statistics"
            />
          }
        >
          <FiltersContainer />
        </ContainerHeader>
        <Card className="gtg-flow-device-usage-statistics">
          <div className="mt-3 mr-3 ml-3">
            <EmptyState />
          </div>
        </Card>
      </div>
    );
  }
};

const mapStateToProps = state => ({
  config: state.config,
  filters: get(state, 'filters'),
  availableDeviceTypes: get(state, 'deviceFilters.deviceTypes')
});

const mapDispatchToProps = {};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)(UsageStatisticsOverview);
