import React from 'react';
import { IconWithBadge } from '../..';

const TestResultIcon = ({ testResult, testFailedCount }) => {
  let level;
  switch (testResult) {
    case 'canceled':
    case 'failed':
      level = 'error';
      break;
    case 'watch':
      level = 'warning';
      break;
    case 'not-performed':
      level = testResult;
      break;
    default:
      level = 'success';
  }
  return <IconWithBadge level={level} count={testFailedCount} />;
};

export default TestResultIcon;
