const hospitalDateHeaders = [
  { header: 'Hospital', key: 'hospital', width: 40 },
  { header: 'Date', key: 'date', width: 20 }
];

const ventilationTypeUtlizationHeaders = [
  {
    header: 'Ventilation type utilization - Invasive',
    key: 'invasive',
    width: 20
  },
  {
    header: 'Ventilation type utilization - Hfo',
    key: 'hfo',
    width: 20
  },
  {
    header: 'Ventilation type utilization - High flow',
    key: 'high-flow',
    width: 20
  },
  {
    header: 'Ventilation type utilization - Non invasive',
    key: 'non-invasive',
    width: 20
  },
  {
    header: 'Ventilation type utilization - Nava',
    key: 'nava',
    width: 20
  }
];

const invasiveVentilationHeaders = [
  {
    header: 'Invasive ventilation utilization - Controlled mode',
    key: 'controlled',
    width: 20
  },
  {
    header: 'Invasive ventilation utilization - Supported mode',
    key: 'supported',
    width: 20
  },
  {
    header: 'Invasive ventilation utilization - Interactive mode',
    key: 'interactive',
    width: 20
  }
];

const ediNavaUtilizationHeaders = [
  {
    header: 'Edi & Nava utilization - Edi monitoring',
    key: 'edi-monitoring',
    width: 20
  },
  {
    header: 'Edi & Nava utilization - Nava',
    key: 'edi-nava',
    width: 20
  },
  {
    header: 'Edi & Nava utilization - Niv Nava',
    key: 'edi-niv-nava',
    width: 20
  }
];

const departmentAndDeviceHeaders = [
  { header: 'Department filter selection', key: 'department', width: 40 },
  { header: 'Device filter selection', key: 'device', width: 40 }
];

// Add column headers and define column keys and widths
export const usageStatisticsServoDetailedColumns = [
  ...hospitalDateHeaders,
  {
    header: 'Patient category utilization - Neonatal',
    key: 'neonatal',
    width: 20
  },
  {
    header: 'Patient category utilization - Pediatric',
    key: 'pediatric',
    width: 20
  },
  {
    header: 'Patient category utilization - Adult',
    key: 'adult',
    width: 20
  },
  {
    header: 'Patient category utilization - Unutilized',
    key: 'unutilized',
    width: 20
  },
  ...ventilationTypeUtlizationHeaders,
  ...invasiveVentilationHeaders,
  ...ediNavaUtilizationHeaders,
  ...departmentAndDeviceHeaders
];

export const usageStatisticsServoDetailedNeonatalColumns = [
  ...hospitalDateHeaders,
  ...ventilationTypeUtlizationHeaders,
  ...invasiveVentilationHeaders,
  ...ediNavaUtilizationHeaders,
  ...departmentAndDeviceHeaders
];

export const usageStatisticsServoDetailedPediatricColumns = [
  ...hospitalDateHeaders,
  ...ventilationTypeUtlizationHeaders,
  ...invasiveVentilationHeaders,
  ...ediNavaUtilizationHeaders,
  ...departmentAndDeviceHeaders
];

export const usageStatisticsServoDetailedAdultColumns = [
  ...hospitalDateHeaders,
  ...ventilationTypeUtlizationHeaders,
  ...invasiveVentilationHeaders,
  ...ediNavaUtilizationHeaders,
  {
    header: 'Open Lung Tool Auto SRM',
    key: 'srm',
    width: 20
  },
  {
    header: 'Open Lung Tool Auto RM',
    key: 'rm',
    width: 20
  },
  ...departmentAndDeviceHeaders
];

// Define filename
export const usageStatisticsServoDetailedFileName =
  'Servo_detailed_usage_statistics.xlsx';
export const usageStatisticsServoDetailedNeonatalFileName =
  'Servo_detailed_usage_statistics_neonatal.xlsx';
export const usageStatisticsServoDetailedPediatricFileName =
  'Servo_detailed_usage_statistics_pediatric.xlsx';
export const usageStatisticsServoDetailedAdultFileName =
  'Servo_detailed_usage_statistics_adult.xlsx';
