import React from 'react';
import classnames from 'classnames';
import './ContainerHeader.scss';

const ContainerHeader = ({
  headerTitle,
  contentAlign = 'right',
  children,
  customClass = ''
}) => {
  return (
    <header
      className={classnames('gtg-container-header', customClass && customClass)}
    >
      {headerTitle && <h2>{headerTitle}</h2>}
      {children && (
        <div
          className={classnames(
            'gtg-container-header-content',
            contentAlign === 'left'
              ? 'justify-content-start'
              : 'justify-content-end'
          )}
        >
          {children}
        </div>
      )}
    </header>
  );
};

export default ContainerHeader;
