import React, { useCallback } from 'react';
import { Icon, scrollToElement } from '../../components';
import { FormattedMessage } from 'react-intl';
import propTypes from 'prop-types';
import classnames from 'classnames';

import './DeviceTestFailedWidget.scss';

const DeviceTestFailedWidget = ({ failedTests, proactiveWatch = false }) => {
  const handleFailedTestClick = useCallback(e => {
    scrollToElement(e.currentTarget.dataset.id);
  }, []);

  return (
    <>
      <div
        className={classnames(
          'col-12',
          'gtg-failed-tests-widget',
          proactiveWatch && 'gtg-proactive-watch'
        )}
      >
        <h2>
          {proactiveWatch ? (
            <FormattedMessage
              id="test.testsToWatch"
              defaultMessage="Tests to watch"
            />
          ) : (
            <FormattedMessage
              id="test.failedTests"
              defaultMessage="Failed tests"
            />
          )}
        </h2>
        <ul>
          {failedTests &&
            failedTests.map(function(item, i) {
              return (
                <li
                  key={item.id}
                  data-id={item.id}
                  onClick={handleFailedTestClick}
                >
                  {item.name}
                  <Icon
                    name="arrow-right-full"
                    size="sm"
                    color={proactiveWatch ? 'warning' : 'danger'}
                  />
                </li>
              );
            })}
        </ul>
      </div>
    </>
  );
};

DeviceTestFailedWidget.propTypes = {
  failedTests: propTypes.arrayOf(propTypes.shape({})).isRequired
};

export default DeviceTestFailedWidget;
