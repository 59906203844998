import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Link, withRouter } from 'react-router-dom';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import classnames from 'classnames';
import get from 'lodash.get';
import { WithLoadingSpinner } from '../../hoc';
import { fetchTests, fetchLastSystemCheckout } from '../../redux/actions/tests';
import { fetchLogTypes } from '../../redux/actions/logTypes';
import { fetchDevicePreventiveMaintenance } from '../../redux/actions/devicePreventiveMaintenance';
import { fetchDeviceStatus } from '../../redux/actions/deviceStatus';
import { fetchDocuments } from '../../redux/actions/documents';
import { fetchServiceOrders } from '../../redux/actions/serviceOrders';

import {
  filterValidProductCertifications,
  EmptyState,
  getDeviceFamilyName,
  isServoFamily,
  isFlowFamily,
  isRotaflowFamily,
  DeviceLogDownloadModal,
  DeviceStatusWidget,
  DeviceTestListWidget,
  Card,
  CardSection,
  VaporizerInfo,
  DeviceLogListWidget,
  DeviceDocumentationWidget,
  DeviceServiceOrderListWidget,
  UsageStatisticsWidget,
  DevicePreventiveMaintenanceListWidget,
  uiConfig,
  userRoles,
  DeviceSlotInformation,
  DeviceRotaflowBatteries,
  Icon,
  UsageStatisticsServoWidget
} from '../../components';
import {
  createDocument,
  SERVICE_ORDERS_LIST,
  serviceOrdersListFileName
} from '../../utils/export';
import { userInfoShape } from '../../utils/proptypes/userInfoShape';

const VaporizerUnavailable = () => <div>This vaporizer is not available</div>;

export const DeviceDashboard = props => {
  const {
    intl,
    device,
    deviceStatus,
    fetchDeviceStatus,
    fetchTests,
    fetchLastSystemCheckout,
    tests,
    testNotPerformedAlarm,
    fetchLogTypes,
    logTypes,
    fetchServiceOrders,
    serviceOrders,
    fetchDevicePreventiveMaintenance,
    devicePreventiveMaintenance,
    userinfo,
    filters,
    authorization,
    fetchDocuments,
    documents
  } = props;
  const [filteredServiceOrdersData, setFilteredServiceOrdersData] = useState(
    []
  );

  const deviceId = window?.location?.pathname?.split('/').pop();

  // Tests and logs should be reloaded when the token gets updated in authorization object as the role is changed
  React.useEffect(() => {
    // We are only interested in these tests
    let testsName = 'System checkout';
    if (isServoFamily(device.deviceType)) {
      testsName = 'Pre-use check';
    }
    if (isRotaflowFamily(device.deviceType)) {
      testsName = 'Selftest';
    }
    fetchLogTypes(device.id);
    fetchTests(
      device.id,
      {
        page: 0,
        pageSize: 5
      },
      '-start',
      testsName
    );
    fetchDocuments(device.id);
  }, [
    device.deviceType,
    device.id,
    fetchLogTypes,
    fetchTests,
    fetchDocuments,
    authorization
  ]);

  React.useEffect(() => {
    fetchDeviceStatus(device.id);

    fetchLastSystemCheckout(device.id);

    fetchServiceOrders(
      device.id,
      {
        page: 0,
        pageSize: 5
      },
      '-created'
    );
    fetchDevicePreventiveMaintenance(device.id);
  }, [
    deviceId,
    device.deviceType,
    device.id,
    fetchDevicePreventiveMaintenance,
    fetchDeviceStatus,
    fetchLastSystemCheckout,
    fetchServiceOrders,
    fetchDocuments
  ]);
  const [downloadLogType, setDownloadLogType] = useState(null);

  const getOpenModalForLogType = useCallback(
    logType => {
      return () => setDownloadLogType(logType);
    },
    [setDownloadLogType]
  );

  const closeDownloadLogModal = useCallback(() => {
    setDownloadLogType(null);
  }, [setDownloadLogType]);

  const exportServiceOrdersData = useCallback(() => {
    createDocument(
      SERVICE_ORDERS_LIST,
      filteredServiceOrdersData || [],
      filters,
      serviceOrdersListFileName,
      null,
      intl
    );
  }, [intl, filteredServiceOrdersData, filters]);

  const ServiceOrdersCardRight = useCallback(
    () => (
      <Link onClick={exportServiceOrdersData} to="#">
        <Icon name="download-excel" size="sm" />
      </Link>
    ),
    [exportServiceOrdersData]
  );

  const getDeviceDashboardWidgets = () => {
    const deviceType = device && device.deviceType;
    const deviceFamily = getDeviceFamilyName(deviceType);
    const userRole =
      (userinfo && userinfo.portal_role) || userRoles.GetingeOnline_Clinician;
    const userProductCertifications =
      userinfo &&
      userinfo.product_certifications &&
      filterValidProductCertifications(userinfo.product_certifications);
    const userIsCertified =
      (userProductCertifications &&
        userProductCertifications.find(
          certification => certification.device_type === deviceType
        ) &&
        true) ||
      false;
    const widgetConfig = uiConfig.deviceDashboardWidgetsConfig.find(config => {
      return config.userRoles.find(
        role =>
          role.userRole === userRole &&
          (!role.checkCertification ||
            (role.checkCertification && role.certified === userIsCertified))
      );
    });

    return (
      widgetConfig &&
      // Filter out widgets that should be hidden for this device family
      widgetConfig.config.filter(widget =>
        widget.hideForDeviceFamilies
          ? !widget.hideForDeviceFamilies.includes(deviceFamily)
          : true
      )
    );
  };

  const deviceDashboardWidgets = {
    deviceTestListWidget: (
      <DeviceTestListWidget
        device={device}
        tests={tests}
        testNotPerformedAlarm={testNotPerformedAlarm}
        getOpenModalForLogType={getOpenModalForLogType}
      />
    ),
    deviceLogListWidget: (
      <DeviceLogListWidget
        device={device}
        logTypes={logTypes}
        getOpenModalForLogType={getOpenModalForLogType}
      />
    ),
    deviceStatusWidget: (
      <Card dataCy="cardDeviceStatus">
        <CardSection
          dataCy="sectionDeviceStatus"
          title={
            <FormattedMessage
              id="common.deviceStatus"
              defaultMessage="Device status"
            />
          }
        >
          <WithLoadingSpinner when="deviceStatus">
            <DeviceStatusWidget deviceStatus={deviceStatus} />
          </WithLoadingSpinner>
        </CardSection>
        {device && isFlowFamily(device.deviceType) && (
          <CardSection
            dataCy="sectionVaporizerInfo"
            title={
              <FormattedMessage
                id="vaporizer.info"
                defaultMessage="Vaporizer info"
              />
            }
            collapsible
          >
            <div data-cy="rowVaporizerInfo" className="row">
              <div data-cy="vaporizerDiv" className="col-12 col-md-6">
                <WithLoadingSpinner when="deviceStatus">
                  {(deviceStatus &&
                    deviceStatus.vaporizers &&
                    deviceStatus.vaporizers.vaporizer1 && (
                      <VaporizerInfo
                        vaporizer={deviceStatus.vaporizers.vaporizer1}
                        intl={intl}
                        vaporizerUnavailable={<VaporizerUnavailable />}
                        vaporizerName={
                          <React.Fragment>
                            <FormattedMessage
                              id="vaporizer.name"
                              defaultMessage="Vaporizer"
                            />
                            {' 1'}
                          </React.Fragment>
                        }
                      />
                    )) || <EmptyState />}
                </WithLoadingSpinner>
              </div>
              <div data-cy="vaporizerDiv" className="col-12 col-md-6">
                <WithLoadingSpinner when="deviceStatus">
                  {(deviceStatus &&
                    deviceStatus.vaporizers &&
                    deviceStatus.vaporizers.vaporizer2 && (
                      <VaporizerInfo
                        vaporizer={deviceStatus.vaporizers.vaporizer2}
                        intl={intl}
                        vaporizerUnavailable={<VaporizerUnavailable />}
                        vaporizerName={
                          <React.Fragment>
                            <FormattedMessage
                              id="vaporizer.name"
                              defaultMessage="Vaporizer"
                            />
                            {' 2'}
                          </React.Fragment>
                        }
                      />
                    )) || <EmptyState />}
                </WithLoadingSpinner>
              </div>
            </div>
          </CardSection>
        )}
        {device && isServoFamily(device.deviceType) && (
          <CardSection
            title={
              <FormattedMessage
                id="deviceStatus.slotInformation"
                defaultMessage="Slot information"
              />
            }
            collapsible
          >
            <WithLoadingSpinner when="deviceStatus">
              {(deviceStatus && deviceStatus.slots && (
                <DeviceSlotInformation slots={deviceStatus.slots} />
              )) || <EmptyState />}
            </WithLoadingSpinner>
          </CardSection>
        )}
        {device && isRotaflowFamily(device.deviceType) && (
          <CardSection
            dataCy="sectionRotaflowBatteries"
            title={
              <FormattedMessage
                id="deviceStatus.batteries"
                defaultMessage="Batteries"
              />
            }
            collapsible
          >
            <WithLoadingSpinner when="deviceStatus">
              {(get(deviceStatus, 'slots.batteries') && (
                <DeviceRotaflowBatteries
                  batteries={deviceStatus.slots.batteries}
                />
              )) || <EmptyState />}
            </WithLoadingSpinner>
          </CardSection>
        )}
      </Card>
    ),
    deviceServiceOrderListWidget: (
      <Card dataCy="cardServiceOrdersOverview">
        <CardSection
          title={
            <FormattedMessage
              id="common.serviceOrdersOverview"
              defaultMessage="Service orders overview"
            />
          }
          CardHeaderRight={ServiceOrdersCardRight}
        >
          <WithLoadingSpinner when="serviceOrders">
            <DeviceServiceOrderListWidget
              serviceOrders={serviceOrders}
              deviceID={device.id}
              setData={setFilteredServiceOrdersData}
            />
          </WithLoadingSpinner>
        </CardSection>
      </Card>
    ),
    deviceUsageStatisticsWidget: (
      <Card>
        <UsageStatisticsWidget device={device} shouldShowBadges={false} />
      </Card>
    ),
    deviceUsageStatisticsServoWidget: (
      <UsageStatisticsServoWidget
        device={device}
        shouldShowBadges={false}
        shouldShowCategoryList={false}
        shouldShowy2y={false}
      />
    ),
    devicePreventiveMaintenanceListWidget: (
      <DevicePreventiveMaintenanceListWidget
        preventiveMaintenance={devicePreventiveMaintenance}
      />
    ),
    deviceDocumentationWidget: (
      <DeviceDocumentationWidget documents={documents} />
    )
  };

  const dashboardWidgets = getDeviceDashboardWidgets();

  return (
    <>
      <div className="row">
        {dashboardWidgets &&
          dashboardWidgets.map(widget => {
            return (
              <div
                key={widget.widgetName}
                className={classnames(
                  'col-12',
                  !widget.fullWidth && 'col-md-6',
                  widget?.widgetName === 'deviceUsageStatisticsServoWidget' &&
                    'gtg-usage-statistics-servo-device-dashboard'
                )}
              >
                {deviceDashboardWidgets[widget.widgetName]}
              </div>
            );
          })}
      </div>
      {downloadLogType && (
        <DeviceLogDownloadModal
          deviceId={device.id}
          logType={downloadLogType}
          onDownloadComplete={closeDownloadLogModal}
        />
      )}
    </>
  );
};

const mapStateToProps = state => ({
  devicePreventiveMaintenance:
    state.devicePreventiveMaintenance.devicePreventiveMaintenance,
  deviceStatus: state.deviceStatus.deviceStatus,
  tests: state.tests.tests,
  testNotPerformedAlarm: state.tests.testNotPerformedAlarm,
  logTypes: state.logTypes.logTypes,
  serviceOrders: state.serviceOrders.serviceOrders.items,
  userinfo: state.authorization.userinfo,
  filters: state.filters,
  config: state.config,
  authorization: state.authorization.jwt,
  documents: state.documents.documents
});

const mapDispatchToProps = {
  fetchDeviceStatus,
  fetchTests,
  fetchLogTypes,
  fetchDocuments,
  fetchServiceOrders,
  fetchLastSystemCheckout,
  fetchDevicePreventiveMaintenance
};

DeviceDashboard.propTypes = {
  userinfo: userInfoShape,
  intl: intlShape
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl,
  withRouter
)(DeviceDashboard);
