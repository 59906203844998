import moment from 'moment';
import { getDeviceTypeDisplayName, formatValueUnit } from '../../components';

export const transformAccounts = data => {
  return data.map(entity => {
    const entityLocation = [entity.city, entity.countryCode].filter(e => e);
    return {
      id: entity.id,
      label: entity.name,
      countryCode: entity.countryCode && entity.countryCode.toLowerCase(),
      description:
        (entityLocation.length > 0 && entityLocation.join(', ')) || null
    };
  });
};

export const transformDeviceTypes = data => {
  return data.map(deviceType => {
    return {
      key: deviceType,
      id: deviceType,
      label: getDeviceTypeDisplayName(deviceType)
    };
  });
};

export function sortByName(data) {
  if (data == null) return data;

  return data.sort((a, b) => a.name.localeCompare(b.name));
}

export const transformResponse = (data, metricType) => {
  let agentKey = metricType === 'metrics' ? 'agent' : 'servoagent';
  if (data && data.values && data.values.length > 0) {
    const lastTimeStamp = data.values[data.values.length - 1][0];
    const yearAgo = moment(lastTimeStamp)
      .subtract(1, 'years')
      .add(1, 'month');
    let valuesByYear = {};
    for (let j = 0; j < data.values.length; j++) {
      const year = moment(data.values[j][0]).isBefore(yearAgo)
        ? 'previous'
        : 'current';
      if (!valuesByYear[year]) {
        valuesByYear[year] = [];
      }
      valuesByYear[year] = [...valuesByYear[year], data.values[j]];
    }
    const yearsArr = Object.keys(valuesByYear);
    const metricsColumns = data.columns.filter(
      column => column.name !== 'time'
    );
    let result = yearsArr.map(year => {
      return metricsColumns.map((column, i) => {
        const mappedMetricData = valuesByYear[year].map(value => ({
          xTimeStamp:
            year === 'previous'
              ? moment
                  .utc(value[0])
                  .add(1, 'years')
                  .format()
              : moment.utc(value[0]).format(),
          timeStamp: value[0],
          y:
            metricType === 'metrics' || column.unit !== 'sec'
              ? +value[i + 1]
              : +value[i + 1] / 3600,
          value: formatValueUnit(value[i + 1], column.unit, column.name)
        }));
        return (
          !mappedMetricData.every(function(v) {
            return v.y === 0;
          }) && {
            [agentKey]: column.name,
            year: year,
            previousYear: year === 'previous',
            data: mappedMetricData
          }
        );
      }, []);
    });
    result = [].concat.apply([], result).filter(data => data);
    if (metricType === 'metrics') {
      return (
        (!result.every(function(s) {
          return s.agent === 'Cases';
        }) &&
          result) ||
        []
      );
    } else {
      return result;
    }
  }
  return [];
};

export const transformResponseForY2yData = (data, metricType) => {
  let agentKey = metricType === 'metrics' ? 'agent' : 'servoagent';
  if (data && data.values && data.values.length > 0) {
    const lastTimeStamp = data.values[data.values.length - 1][0];
    const yearAgo = moment(lastTimeStamp)
      .subtract(1, 'years')
      .add(1, 'month');
    let valuesByYear = {};
    for (let j = 0; j < data.values.length; j++) {
      const year = moment(data.values[j][0]).isBefore(yearAgo)
        ? 'previous'
        : 'current';
      if (!valuesByYear[year]) {
        valuesByYear[year] = [];
      }
      valuesByYear[year] = [...valuesByYear[year], data.values[j]];
    }
    const yearsArr = Object.keys(valuesByYear);
    const metricsColumns = data.columns.filter(
      column => column.name !== 'time'
    );
    const getDataArray = (value, column, index) => {
      return {
        xTimeStamp: moment.utc(value[0]).format(),
        timeStamp: value[0],
        y:
          metricType === 'metrics' || column.unit !== 'sec'
            ? +value[index + 1]
            : +value[index + 1] / 3600,
        value: formatValueUnit(value[index + 1], column.unit, column.name)
      };
    };
    let dataArrayPrevious = [];
    let dataArrayCurrent = [];
    let result = yearsArr.map(year => {
      return metricsColumns.map((column, i) => {
        valuesByYear?.previous &&
          valuesByYear['previous'].forEach(value => {
            dataArrayPrevious.push(getDataArray(value, column, i));
          });

        valuesByYear?.current &&
          valuesByYear['current'].forEach(value => {
            dataArrayCurrent.push(getDataArray(value, column, i));
          });

        const key = 'timeStamp';

        const mappedMetricData = [
          ...new Map(dataArrayPrevious.map(item => [item[key], item])).values(),
          ...new Map(dataArrayCurrent.map(item => [item[key], item])).values()
        ];

        return (
          !mappedMetricData.every(function(v) {
            return v.y === 0;
          }) && {
            [agentKey]: column.name,
            year: year,
            previousYear: year === 'previous',
            data: mappedMetricData
          }
        );
      }, []);
    });
    result = [].concat.apply([], result).filter(data => data);
    if (metricType === 'metrics') {
      return (
        (!result.every(function(s) {
          return s.agent === 'Cases';
        }) &&
          result) ||
        []
      );
    } else {
      return result;
    }
  }
  return [];
};
