import React from 'react';
import propTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { CardSectionColumn, EmptyState, Capitalize } from '..';
import Leaf from '../../assets/icons/Leaf.svg';
import { WithLoadingSpinner } from '../../hoc/';
import './CarbonFootprint.scss';
import { IsY2Y } from '../utils/IsY2Y/IsY2Y';

export const CarbonFootprint = ({ metricsUsage, timeSpan = 'year' }) => (
  <CardSectionColumn
    title={
      <FormattedMessage
        id="usageStatistics.carbonFootprint"
        defaultMessage="Carbon footprint"
      />
    }
  >
    <WithLoadingSpinner when="metricsUsage">
      {(metricsUsage && metricsUsage.carbonFootprint && (
        <div className="gtgCarbonFootprint">
          <div className="my-5">
            <div>
              <div>
                {IsY2Y(timeSpan)
                  ? 'N/A'
                  : parseFloat(
                      metricsUsage.carbonFootprint.toFixed(2)
                    ).toString()}
              </div>
              {!IsY2Y(timeSpan) && (
                <FormattedMessage
                  id={`usageStatistics.tonsPer${Capitalize(timeSpan)}`}
                  defaultMessage={`tons / ${timeSpan}`}
                />
              )}
            </div>
            <img src={Leaf} alt="" />
          </div>
          <FormattedMessage
            id="usageStatistics.valueBasedOnApproximation"
            defaultMessage="* this is calculated based on approximation"
          />
        </div>
      )) || <EmptyState />}
    </WithLoadingSpinner>
  </CardSectionColumn>
);

CarbonFootprint.propTypes = {
  metricsUsage: propTypes.shape({
    carbonFootprint: propTypes.number.isRequired
  })
};

export default CarbonFootprint;
