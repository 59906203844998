import { useRef } from 'react';
import { useSafeListener } from '..';

/**
 * If a click happens outside the returned element ref,
 * the callback onClickedOutside is triggered.
 *
 * @param {function} onClickedOutside
 */
export default function useDetectClickOutsideRef(onClickedOutside) {
  const ref = useRef(null);

  const handleClickOutside = event => {
    if (ref.current && !ref.current.contains(event.target)) {
      onClickedOutside && onClickedOutside();
    }
  };

  useSafeListener('mousedown', handleClickOutside);

  return { ref };
}
