import React from 'react';
import { List, ListItem, GradientBar, Icon, uuidv4 } from '../../components';
import propTypes from 'prop-types';
import { FormattedMessage, FormattedDate } from 'react-intl';
import get from 'lodash.get';
import './DeviceRotaflowBatteries.scss';

const DeviceRotaflowBatteries = ({ batteries = [] }) => {
  const mapStatusNumberToBarValue = statusNumber => {
    let barValue = null;
    if (statusNumber) {
      switch (statusNumber) {
        case 3:
          barValue = 100;
          break;
        case 2:
          barValue = 50;
          break;
        case 1:
          barValue = 0;
          break;
        default:
          break;
      }
    }
    return barValue;
  };

  const IconPlaceholder = () => <i className="gtg-icon-placeholder">-</i>;

  const mapBatteryStatusIcon = status => {
    let icon = null;
    if (status) {
      switch (status.toLowerCase()) {
        case 'alert':
          icon = 'error-outline';
          break;
        case 'warning':
          icon = 'warning-outline';
          break;
        case 'ok':
          icon = 'success-outline';
          break;
        default:
          break;
      }
    }
    return icon ? <Icon name={icon} /> : <IconPlaceholder />;
  };

  const mapBatteryStatusNumberToIcon = statusNumber => {
    let icon = null;
    if (statusNumber) {
      switch (statusNumber) {
        case 1:
          icon = 'error-outline';
          break;
        case 2:
          icon = 'warning-outline';
          break;
        case 3:
          icon = 'success-outline';
          break;
        default:
          break;
      }
    }
    return icon ? <Icon name={icon} /> : <IconPlaceholder />;
  };

  const batteryStatusTexts = (type, status) => {
    const statuses = {
      calibration: {
        alert: (
          <FormattedMessage
            id="deviceStatus.calibrationRequired"
            defaultMessage="Calibration required"
          />
        ),
        warning: (
          <FormattedMessage
            id="deviceStatus.prepareForCalibration"
            defaultMessage="Prepare for calibration"
          />
        ),
        ok: (
          <FormattedMessage
            id="deviceStatus.noCalibrationRequired"
            defaultMessage="No calibration required"
          />
        )
      },
      health: {
        alert: (
          <FormattedMessage
            id="deviceStatus.exchangeNow"
            defaultMessage="Exchange now!"
          />
        ),
        warning: (
          <FormattedMessage
            id="deviceStatus.prepareForExchange"
            defaultMessage="Prepare for exchange"
          />
        ),
        ok: (
          <FormattedMessage
            id="deviceStatus.noExchangeRequired"
            defaultMessage="No exchange required"
          />
        )
      }
    };
    return (
      (status &&
        statuses[type.toLowerCase()] &&
        statuses[type.toLowerCase()][status.toLowerCase()]) || (
        <FormattedMessage id="deviceStatus.unknown" defaultMessage="Unknown" />
      )
    );
  };

  const mappedBatteries = [
    ...(batteries || []).map((battery, i) => {
      return {
        name: (
          <>
            <FormattedMessage
              id="deviceStatus.battery"
              defaultMessage="Battery"
            />{' '}
            {i + 1}
          </>
        ),
        statusNumber: get(battery, 'status.value'),
        serialID: battery.serialID || (
          <FormattedMessage id="common.notAvailableAbbr" defaultMessage="N/A" />
        ),
        key: 'battery' + i,
        statusRows: [
          {
            label: (
              <FormattedMessage
                id="deviceStatus.productionDate"
                defaultMessage="Production date"
              />
            ),
            value: (battery.manufacturingDate && (
              <FormattedDate value={battery.manufacturingDate} />
            )) || (
              <FormattedMessage
                id="common.notAvailableAbbr"
                defaultMessage="N/A"
              />
            )
          },
          battery.charge && {
            label: (
              <FormattedMessage
                id="deviceStatus.chargeLevel"
                defaultMessage="Charge level"
              />
            ),
            bar: (
              <GradientBar
                value={battery.charge.value}
                gradientStops={[0, 8, 15, 25]}
              />
            ),
            value: battery.charge.value ? (
              battery.charge.value + ' %'
            ) : (
              <FormattedMessage
                id="deviceStatus.unknown"
                defaultMessage="Unknown"
              />
            ),
            // only show alert status icon if value is not 0/undefined
            icon: mapBatteryStatusIcon(
              battery.charge.value && battery.charge.status
            )
          },
          battery.health && {
            label: (
              <FormattedMessage
                id="deviceStatus.healthStatus"
                defaultMessage="Health status"
              />
            ),
            bar: (
              <GradientBar
                value={mapStatusNumberToBarValue(battery.health.value)}
              />
            ),
            // only show alert status text if value is not 0/undefined
            value: batteryStatusTexts(
              'health',
              battery.health.value && battery.health.status
            ),
            icon: mapBatteryStatusNumberToIcon(battery.health.value)
          },
          battery.calibration && {
            label: (
              <FormattedMessage
                id="deviceStatus.calibrationStatus"
                defaultMessage="Calibration status"
              />
            ),
            bar: (
              <GradientBar
                value={mapStatusNumberToBarValue(battery.calibration.value)}
              />
            ),
            // only show alert status text if value is not 0/undefined
            value: batteryStatusTexts(
              'calibration',
              battery.calibration.value && battery.calibration.status
            ),
            icon: mapBatteryStatusNumberToIcon(battery.calibration.value)
          }
        ].filter(e => e)
      };
    })
  ];

  return (
    <div className="row">
      {mappedBatteries.map((battery, i) => {
        return (
          <div
            className="gtg-device-rotaflow-battery-status col-12 col-md-6 mb-4"
            key={battery.key}
          >
            <div className="mb-3 text-primary gtg-text-sm">
              <strong>{battery.name}:</strong>
              <span className="ml-2">
                {battery.serialID}
                <span className="ml-3">
                  {mapBatteryStatusNumberToIcon(battery.statusNumber)}
                </span>
              </span>
            </div>
            <List>
              {battery.statusRows &&
                battery.statusRows.map(statusRow => {
                  return (
                    <ListItem className="py-1" key={uuidv4()}>
                      <label>
                        <strong>{statusRow.label}</strong>
                      </label>
                      <span className="ml-1 col row align-items-center">
                        {statusRow.bar && <div>{statusRow.bar}</div>}
                        <div className="col d-inline-flex align-items-center text-center">
                          {statusRow.value}
                        </div>
                        {statusRow.icon && (
                          <div className="p-0 d-inline-flex justify-content-center align-items-center">
                            {statusRow.icon}
                          </div>
                        )}
                      </span>
                    </ListItem>
                  );
                })}
            </List>
          </div>
        );
      })}
    </div>
  );
};

DeviceRotaflowBatteries.propTypes = {
  batteries: propTypes.arrayOf(
    propTypes.shape({
      calibration: propTypes.shape({
        status: propTypes.oneOf(['OK', 'WARNING', 'ALERT']),
        value: propTypes.oneOf([0, 1, 2, 3])
      }),
      charge: propTypes.shape({
        status: propTypes.oneOf(['OK', 'WARNING', 'ALERT']),
        value: propTypes.number
      }),
      health: propTypes.shape({
        status: propTypes.oneOf(['OK', 'WARNING', 'ALERT']),
        value: propTypes.oneOf([0, 1, 2, 3])
      }),
      status: propTypes.shape({
        status: propTypes.oneOf(['OK', 'WARNING', 'ALERT']),
        value: propTypes.oneOf([0, 1, 2, 3])
      }),
      manufacturingDate: propTypes.string,
      serialID: propTypes.string
    })
  )
};

export default DeviceRotaflowBatteries;
