import './tableHeaderTooltip.scss';

export const applyTooltipForColHeader = () => {
  let ellipsisActive = (e, headerRef) => {
    if (e) {
      let w = headerRef.getBoundingClientRect().width - 24;
      const els = document.createElement('span');
      els.innerText = e.innerText;
      els.className = 'gtg-dummy-span-to-check-width';
      els.style.cssText += `visibility: hidden; font-weight: bold; font-size: 0.875rem;`;
      let b = document.getElementsByTagName('body');
      b[0].appendChild(els);
      let res = els.getBoundingClientRect().width > w;
      document
        .querySelectorAll('.gtg-dummy-span-to-check-width')
        .forEach(e => e.remove());
      return res;
    }
    return false;
  };

  let tables = document.getElementsByClassName('ReactTable');
  [...tables].forEach(table => {
    let tableHeaders = table.getElementsByClassName('rt-th');
    [...tableHeaders].forEach(header => {
      let spanHeaderText = header.querySelector('span');
      let spanHeaderTextBounds;

      spanHeaderText &&
        spanHeaderText.addEventListener('mouseover', function() {
          spanHeaderTextBounds = spanHeaderText.getBoundingClientRect();
          const el = document.createElement('div');
          el.className = 'gtg-table-header-tooltip';
          el.innerText = header.innerText;
          let b = document.getElementsByTagName('body');
          if (
            document.querySelectorAll('.gtg-table-header-tooltip').length ===
              0 &&
            ellipsisActive(spanHeaderText, header)
          ) {
            b[0].appendChild(el);
          }
          el.style.cssText += `left:${spanHeaderTextBounds.left -
            el.getBoundingClientRect().width / 2 +
            spanHeaderTextBounds.width / 2}px; top: ${spanHeaderTextBounds.top -
            55}px; display: block;`;
        });

      spanHeaderText &&
        spanHeaderText.addEventListener('mouseout', function() {
          document
            .querySelectorAll('.gtg-table-header-tooltip')
            .forEach(e => e.remove());
        });
    });
  });
};
