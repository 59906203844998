import { setLogs, FETCH_LOGS, entity, DOWNLOAD_LOGS } from '../actions/logs';
import { apiRequest, API_SUCCESS } from '../actions/api';
import { API_URLS } from './urls';
import { fetchBlob } from '../actions/blobDownload';

export const logsMdl = ({ dispatch }) => next => action => {
  next(action);

  const { type, pagination, sorting } = action;

  switch (type) {
    case FETCH_LOGS:
      // Use high end date and low start date since there are not time filters yet.
      const QUERY_PARAMS =
        `?start=2000-01-01T00%3A00%3A00Z&end=2050-09-25T11%3A46%3A35.388Z` +
        '&page=' +
        (pagination.page + 1) +
        '&pageSize=' +
        pagination.pageSize +
        ((sorting && '&sort=' + sorting) || '');
      dispatch(
        apiRequest({
          method: 'GET',
          url: `${API_URLS.Logs(action.id, action.logType).url}${QUERY_PARAMS}`,
          entity: action.logType + ' ' + entity,
          requestSignature: 'logs'
        })
      );
      break;
    case DOWNLOAD_LOGS:
      const { deviceId, logType } = action.payload;
      const url = API_URLS.LogDownload(deviceId, logType);

      dispatch(fetchBlob(url.url));
      break;
    default:
      if (type.includes(`${entity} GET ${API_SUCCESS}`)) {
        const logType = type.replace(`${entity} GET ${API_SUCCESS}`, '').trim();
        if (
          action.payload.data &&
          action.payload.data.pagination &&
          action.payload.data.size
        ) {
          action.payload.data.pagination.page =
            action.payload.data.pagination.page - 1;
          action.payload.data.pagination.totalItems = action.payload.data.size;
        }
        dispatch(setLogs(logType, action.payload.data));
      }
      break;
  }
};
