import {
  FETCH_USAGE_STATISTICS,
  setUsageStatistics,
  entity
} from '../actions/usageStatistics';
import { apiRequest, API_SUCCESS } from '../actions/api';
import { MetricsTimeParams } from '../../components/';
import { API_URLS } from './urls';

const URL = API_URLS.UsageStatistics;

export const usageStatisticsMdl = ({ dispatch }) => next => action => {
  next(action);

  const { type } = action;

  switch (type) {
    case FETCH_USAGE_STATISTICS:
      const { count, deviceIds, timeSpan } = action.payload;
      const deviceIdsParams =
        (deviceIds &&
          deviceIds.length > 0 &&
          '&device_ids=' + deviceIds.join(',')) ||
        '';
      dispatch(
        apiRequest({
          method: 'GET',
          url:
            URL.url +
            MetricsTimeParams('usageStatistics', timeSpan, count) +
            deviceIdsParams,
          requestSignature: 'usageStatistics',
          entity: entity,
          filter: URL.filter
        })
      );
      break;
    case `${entity} GET ${API_SUCCESS}`:
      dispatch(setUsageStatistics(action.payload.data));
      break;
    // no default
  }
};
