import React from 'react';
import { List, ListItem, formatValueUnit } from '../../components';
import propTypes from 'prop-types';
import styles from './VaporizerInfo.module.scss';
import { FormattedMessage } from 'react-intl';

const VaporizerInfo = props => {
  const { vaporizerName, vaporizer } = props;
  const { name, agent, liquidLevel, serialID } = vaporizer || {};
  const naClassname = value => (value === 'N/A' ? 'text-light' : '');

  let v = <span className="ml-2">{name}</span>;
  if (serialID === 'N/A') {
    v = (
      <span className="text-light ml-2">
        <FormattedMessage
          id="common.notConnected"
          defaultMessage="Not connected"
        />
      </span>
    );
  }

  return (
    <>
      <div className="mb-3 text-primary">
        <strong>{vaporizerName}:</strong> {v}
      </div>
      <List dataCy="vaporizerInfoList">
        <ListItem>
          <label className={styles['label-col']}>
            <strong>
              <FormattedMessage id="vaporizer.Agent" defaultMessage="Agent" />
            </strong>
          </label>
          <span className={[naClassname(agent), styles['flex-1']].join(' ')}>
            {agent}
          </span>
        </ListItem>
        <ListItem>
          <label className={styles['label-col']}>
            <strong>
              <FormattedMessage
                id="vaporizer.LiquidLevel"
                defaultMessage="Liquid level"
              />
            </strong>
          </label>
          <span
            className={[naClassname(liquidLevel), styles['flex-1']].join(' ')}
          >
            {(liquidLevel &&
              liquidLevel !== 'N/A' &&
              liquidLevel.split(' ').length > 1 &&
              formatValueUnit(
                liquidLevel.split(' ')[0],
                liquidLevel.split(' ')[1]
              )) ||
              liquidLevel}
          </span>
        </ListItem>
        <ListItem>
          <label className={styles['label-col']}>
            <strong>
              <FormattedMessage
                id="vaporizer.serialNumber"
                defaultMessage="Serial number"
              />
            </strong>
          </label>
          <span className={[naClassname(serialID), styles['flex-1']].join(' ')}>
            {serialID}
          </span>
        </ListItem>
      </List>
    </>
  );
};

VaporizerInfo.propTypes = {
  vaporizer: propTypes.shape({
    name: propTypes.string.isRequired,
    agent: propTypes.string,
    serialID: propTypes.string,
    softwareVersions: propTypes.arrayOf(
      propTypes.shape({
        name: propTypes.string,
        version: propTypes.string
      })
    )
  }),
  vaporizerName: propTypes.shape({})
};

export default VaporizerInfo;
