import { defineAbility } from '@casl/ability';
import { userRoles, filterValidProductCertifications } from '../../components';

// Device class is used to simplify ability definitions on the Device object. Rules defined with
// this class should also be checked using the same class as the class name is checked. Using a
// class allows the user to pass in additional conditions, so the deviceType can be passed into
// the ability.
export class Device {
  constructor(deviceType) {
    this.deviceType = (deviceType && deviceType.toLowerCase()) || deviceType;
  }

  static VIEW_ALL_LOGS = 'view-all-logs';
  static VIEW_TEST_DETAILS = 'view-test-details';
}

const ruleBuilder = (() => {
  const f = (roles, product_certifications) => {
    const certifications = filterValidProductCertifications(
      product_certifications
    );
    // Always take the first role
    const role =
      roles && roles instanceof Array && roles.length > 0 ? roles[0] : roles;
    return defineAbility(can => {
      switch (role) {
        case userRoles.GetingeOnline_ServiceTechnician:
          // A service technician can see tests and test details for device types he is
          // certified for
          certifications.forEach(({ device_type }) => {
            can(Device.VIEW_ALL_LOGS, Device, {
              deviceType: device_type
            });
            can(Device.VIEW_TEST_DETAILS, Device, {
              deviceType: device_type
            });
          });
          break;
        case userRoles.GetingeOnline_Clinician:
          // A clinician has no special permissions
          break;
        case userRoles.GetingeOnline_ServiceAccess:
          can(Device.VIEW_ALL_LOGS, Device);
          can(Device.VIEW_TEST_DETAILS, Device);
          break;
        // no default
      }
    });
  };
  return f;
})();

export default ruleBuilder;
