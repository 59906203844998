import React, { useState, useCallback, useEffect, useRef } from 'react';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { Modal, Spinner, Scrollable } from '../..';
import './LegalNotice.scss';
import ReactMarkdown from 'react-markdown';

const LegalNotice = props => {
  const { legalNotice, approveHandler } = props;

  const [scrolledToBottom, setScrolledToBottom] = useState(false);
  const legalNoticeContainer = useRef(null);

  useEffect(() => {
    // enable button if content is short
    if (
      legalNoticeContainer.current &&
      legalNoticeContainer.current.osTargetRef.current.classList.contains(
        'os-host-scrollbar-vertical-hidden'
      )
    ) {
      setScrolledToBottom(true);
    }
  }, []);

  const handleScroll = useCallback(
    e => {
      if (!scrolledToBottom) {
        // to prevent issues with scaled displays, the scrolledToBottom check does not
        // compare the heights, but rather returns true when the user is in the last part
        // of the legal notice
        if (
          e.target.scrollHeight - e.target.clientHeight - e.target.scrollTop <
          35
        )
          setScrolledToBottom(true);
      }
    },
    [scrolledToBottom]
  );

  const acceptButton = (
    <button
      type="button"
      className={classnames('gtg-legal-notice-submit-btn', 'btn', 'btn-info', {
        disabled: !scrolledToBottom
      })}
      data-dismiss="modal"
      onClick={approveHandler}
    >
      <FormattedMessage
        id="legalNotice.acceptAndContinue"
        defaultMessage="Accept & continue"
      />
    </button>
  );

  return (
    <>
      {(legalNotice &&
        legalNotice.approval &&
        !legalNotice.approval.accepted &&
        legalNotice.content && (
          <Modal
            title={
              <FormattedMessage
                id="legalNotice.legalNotice"
                defaultMessage="Legal notice"
              />
            }
            buttons={acceptButton}
            className="gtg-legal-notice-modal"
          >
            <Scrollable
              minElementHeight={100}
              options={{
                callbacks: {
                  onScrollStop: handleScroll
                }
              }}
              ref={legalNoticeContainer}
            >
              <div className="gtg-legal-notice-content">
                <ReactMarkdown source={legalNotice.content} />
              </div>
            </Scrollable>
          </Modal>
        )) || (
        <div className="gtg-legal-notice-spinner">
          <Spinner />
        </div>
      )}
    </>
  );
};

export default LegalNotice;
