import React, { useState, useCallback, useRef, useEffect } from 'react';
import { FilterInput, Icon, uuidv4, Scrollable, Spinner } from '../..';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';
import './Filter.scss';
import propTypes from 'prop-types';
import get from 'lodash.get';

/**
 * Displays a single or multiselect filter with custom filter items
 * If onFilterInputUpdate method is passed, server-side search is assumed
 * Otherwise filter items are being filtered client-side
 *
 * @param filterItems Filter items that should be displayed in the filter
 * @param initialSelectedValue Initial selected value(s)
 * @param disabled Whether or not the filter should be disabled
 * @param icon Name of the icon from the icons sprite without the icon- prefix
 * @param placeHolder Placeholder to be displayed when no item(s) are selected
 * @param multiselect Whether or not multiselect is enabled
 * @param dataCy Used for e2e tests
 * @param emptyStateMsg Message to be displayed when no items are available or no items match search query
 * @param allItemsText Text to be displayed in the multiselect filter all items options/button
 * @param onFilterUpdate Method that is called with filter value(s) on item select (single-select mode) or filter dropdown closure (multiselect)
 * @param onFilterInputUpdate Method that is called on input field change with input's current value
 * @param queryInProgress Whether or not new filterItems are being fetched based on onFilterInputUpdate being triggered
 * @param filterWidth Custom filter dropdown width in px
 * @param searchPlaceholder Placeholder with instructions to the user what he can search by
 * @param searchMinStrLength Minimal input value length for onFilterInputUpdate to be called
 * @param customInputFilter Custom input filter function to use when user types into the dropdown. Only used when onFilterInputUpdate is not defined.
 */

const Filter = ({
  filterItems = [],
  initialSelectedValue,
  disabled,
  icon,
  placeHolder = '',
  multiselect,
  dataCy,
  emptyStateMsg = (
    <FormattedMessage
      id="common.noItemsFound"
      defaultMessage="No items found"
    />
  ),
  allItemsText = (
    <FormattedMessage id="common.allItems" defaultMessage="All items" />
  ),
  onFilterUpdate,
  onFilterInputUpdate,
  queryInProgress,
  filterWidth,
  searchPlaceholder,
  searchMinStrLength = 2,
  customInputFilter = undefined
}) => {
  const [inputVal, setInputval] = useState('');
  const [filterOpen, setFilterOpen] = useState(false);
  const [selectedItems, setSelectedItems] = useState(
    initialSelectedValue ||
      (!multiselect && filterItems.length > 0 && [filterItems[0]]) ||
      []
  );
  const [userTyping, setUserTyping] = useState(false);
  const typingTimer = useRef(false);
  const wrapperRef = useRef(null);
  const defaultSearchPlaceholder = (
    <FormattedMessage
      id="common.typeToSearch"
      defaultMessage="Type to start searching"
    />
  );
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (!selectedItems || selectedItems.length == 0) {
      setSelectedItems(initialSelectedValue);
    }
  }, [filterItems]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });

  useEffect(() => {
    // call onFilterUpdate when filter closes
    !filterOpen && onFilterUpdate && onFilterUpdate(selectedItems);
  }, [filterOpen]);

  function handleClickOutside(event) {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setFilterOpen(false);
    }
  }

  const currentValue = useCallback(() => {
    return (
      selectedItems &&
      selectedItems.length > 0 &&
      selectedItems.map(item => item.label).join(', ')
    );
  });

  const currentItemDescription = useCallback(() => {
    return get(selectedItems, '[0].description', undefined);
  });

  const getAvailableItems = useCallback(() => {
    const availableItems = filterItems.filter(
      item => !selectedItems.find(x => x.id === item.id)
    );

    // Only filter items if onFilterInputUpdate is not available
    if (onFilterInputUpdate && availableItems) {
      return availableItems;
    }

    if (customInputFilter) {
      return availableItems.filter(customInputFilter(inputVal));
    }

    return availableItems.filter(item =>
      item.label.toLowerCase().includes(inputVal.toLowerCase())
    );
  });

  const handleDropdownToggle = useCallback(() => {
    setFilterOpen(!filterOpen);
  }, [filterOpen]);

  const handleInput = useCallback(
    val => {
      // replace double spaces with single
      const sanitizedValue = val.replace(/ {2,}/g, ' ');
      setInputval(sanitizedValue);

      if (onFilterInputUpdate) {
        sanitizedValue.length >= searchMinStrLength && setUserTyping(true);
        // trigger onFilterInputUpdate only after typing stops
        clearTimeout(typingTimer.current);
        typingTimer.current = setTimeout(() => {
          setUserTyping(false);
          // send input value only if it meets length requirements
          onFilterInputUpdate &&
            onFilterInputUpdate(
              sanitizedValue.length >= searchMinStrLength && sanitizedValue
            );
        }, 400);
      }
    },
    [typingTimer, onFilterInputUpdate, searchMinStrLength]
  );

  const handleItemSelect = useCallback(
    item => {
      const newSelectedItems = multiselect
        ? (item.id === 'allitems' && filterItems) || [...selectedItems, item]
        : [item];
      setSelectedItems(newSelectedItems);
      !multiselect && handleDropdownToggle();
      setInputval('');
    },
    [filterItems, handleDropdownToggle, multiselect, selectedItems]
  );

  const handleItemDeselect = useCallback(
    item => {
      setSelectedItems(
        (item.id === 'allitems' && []) ||
          selectedItems.filter(selectedItem => selectedItem !== item)
      );
    },
    [selectedItems]
  );

  const handleInputClear = useCallback(() => {
    setInputval('');
  }, [setInputval]);

  const handleChange = useCallback(
    event => {
      const value = event.target.value;
      handleInput(value);
    },
    [handleInput]
  );

  const SelectedItems = () => (
    <div className="gtg-filter-selected-items">
      {(selectedItems === filterItems && (
        <SelectedItem
          key={uuidv4()}
          item={{ id: 'allitems', label: allItemsText }}
          onDeselect={handleItemDeselect}
        />
      )) ||
        selectedItems.map(item => (
          <SelectedItem
            key={item.id || uuidv4()}
            item={item}
            onDeselect={handleItemDeselect}
          />
        ))}
    </div>
  );

  const SelectedItem = ({ item, onDeselect }) => {
    const handleClick = useCallback(() => {
      onDeselect(item);
    }, [onDeselect, item]);
    return (
      <span className="gtg-filter-selected-item" onClick={handleClick}>
        <span>{item.label}</span>
        <Icon name="close" size="sm" />
      </span>
    );
  };

  const FilterOptions = ({ items }) => {
    return (
      <div
        className={classnames(
          'gtg-filter-items-list',
          multiselect && 'gtg-filter-items-list-selectable'
        )}
      >
        {(items && items.length && (
          <Scrollable minElementHeight={50} maxElementHeight={200}>
            {multiselect && (
              <FilterOption
                item={{ id: 'allitems', label: allItemsText }}
                onSelect={handleItemSelect}
                key={uuidv4()}
              />
            )}
            {items.map(item => (
              <FilterOption
                key={item.id || uuidv4()}
                item={item}
                onSelect={handleItemSelect}
              />
            ))}
          </Scrollable>
        )) || (
          <span className="gtg-filter-no-data disabled">{emptyStateMsg}</span>
        )}
      </div>
    );
  };

  const FilterOption = ({ item, onSelect }) => {
    const handleClick = useCallback(() => {
      onSelect(item);
    }, [onSelect, item]);
    return (
      <div className="gtg-filter-item" onClick={handleClick}>
        {item.label}
        {item.description && ', ' + item.description}
      </div>
    );
  };

  return (
    <div
      data-cy={dataCy}
      ref={wrapperRef}
      className={classnames(
        'gtg-filter',
        disabled && 'disabled',
        filterOpen && 'gtg-filter-open'
      )}
    >
      <span onClick={handleDropdownToggle}>
        {icon && <Icon name={icon} size="md" />}
        <div>
          <div className="gtg-filter-value">
            {currentValue() || placeHolder}
          </div>
          <div className="gtg-filter-description">
            {currentItemDescription()}
          </div>
        </div>
      </span>
      {filterOpen && (
        <div
          className="gtg-filter-dropdown"
          key="gtg-filter-dropdown"
          style={filterWidth && { width: filterWidth }}
        >
          <FilterInput
            value={inputVal}
            onChange={handleChange}
            onInputClear={handleInputClear}
          />
          {((userTyping || queryInProgress) && <Spinner></Spinner>) || (
            <>
              {multiselect && selectedItems && selectedItems.length > 0 && (
                <SelectedItems />
              )}
              {(selectedItems !== filterItems &&
                // show searchPlaceholder if onFilterInputUpdate is available but input value
                // doesn't meet length requirements
                ((onFilterInputUpdate &&
                  inputVal &&
                  inputVal.length >= searchMinStrLength) ||
                  !onFilterInputUpdate) && (
                  <FilterOptions items={getAvailableItems()} />
                )) || (
                <div className="gtg-filter-search-placeholder disabled">
                  {searchPlaceholder || defaultSearchPlaceholder}
                </div>
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
};

Filter.propTypes = {
  filterItems: propTypes.arrayOf(
    propTypes.shape({
      id: propTypes.string,
      label: propTypes.string
    })
  ),
  initialSelectedValue: propTypes.arrayOf(
    propTypes.shape({
      id: propTypes.string,
      label: propTypes.string
    })
  ),
  disabled: propTypes.bool,
  icon: propTypes.string,
  placeHolder: propTypes.oneOfType([propTypes.string, propTypes.object]),
  multiselect: propTypes.bool,
  emptyStateMsg: propTypes.oneOfType([propTypes.string, propTypes.object]),
  allItemsText: propTypes.oneOfType([propTypes.string, propTypes.object]),
  onFilterUpdate: propTypes.func,
  onFilterInputUpdate: propTypes.func,
  queryInProgress: propTypes.bool,
  filterWidth: propTypes.number,
  searchPlaceholder: propTypes.oneOfType([propTypes.string, propTypes.object]),
  searchMinStrLength: propTypes.number
};

export default Filter;
