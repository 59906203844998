function StatusToIcon(value) {
  let iconObj;
  switch (value) {
    case 'ERROR':
      iconObj = {
        color: 'red',
        icon: 'error-outline'
      };
      break;
    case 'WARNING':
      iconObj = {
        color: 'yellow',
        icon: 'warning-outline'
      };
      break;
    case 'UNKNOWN':
      iconObj = {
        color: 'gray',
        icon: 'neutral-outline'
      };
      break;
    default:
      iconObj = {
        color: 'green',
        icon: 'success-outline'
      };
      break;
  }
  return iconObj;
}

export default StatusToIcon;
